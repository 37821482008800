  import { decode } from "html-entities";
import { Text, View } from '@react-pdf/renderer';

const Address = ({ question }) => {

  const styles = {
    inputField: {
      border: "1px solid #ccc",
      borderRadius: 5,
      width: "50%",
      height: "30px",
      padding: "5px",
      paddingTop: "8px",
      marginBottom: "5px",
      color: "#333"
    }
  }
  return (
    <View >
      <Text style={{ marginBottom: "5px" }}>Address</Text>
      <Text style={[styles.inputField, {marginBottom: "5px"}]}>
        Street address, P.O. box etc
      </Text>
      <Text style={[styles.inputField, {marginBottom: "5px"}]}>
        Apartment, suit, unit, building, floor etc
      </Text>
      <View style={{ flexDirection: "row", marginTop: "5px" }}>
        <View style={{ width: "40%" }}>
          <Text style={{ marginBottom: "5px" }}>City</Text>
          <Text style={[styles.inputField]}>
            City
          </Text>
        </View>
        <View style={{ width: "40%" }}>
          <Text style={{ marginBottom: "5px" }}>State/Province/Region</Text>
          <Text style={[styles.inputField]}>
            State/Province/Region
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginTop: "5px" }}>
        <View style={{ width: "40%" }}>
          <Text style={{ marginBottom: "5px" }}>Zip/Postal Code</Text>
          <Text style={[styles.inputField]}>
            Zip/Postal Code
          </Text>
        </View>
        <View style={{ width: "40%" }}>
          <Text style={{ marginBottom: "5px" }}>Country</Text>
          <Text style={[styles.inputField]}>
            Country
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginTop: "5px" }}>
        <View style={{ width: "40%" }}>
          <Text style={{ marginBottom: "5px" }}>Phone Number</Text>
          <Text style={[styles.inputField]}>
            Phone #
          </Text>
        </View>
        <View style={{ width: "40%" }}>
          <Text style={{ marginBottom: "5px" }}>Phone Number Type</Text>
          <Text style={[styles.inputField]}>
            Cell, Home, Work etc.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Address;
