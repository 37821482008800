import React, { useState } from 'react';
import TimePicker from 'react-time-picker-input';

function TimePickerComp({ ...props }) {
  return (
    <div>
      <TimePicker
        hour12Format
        eachInputDropdown
        manuallyDisplayDropdown
        {...props}
      />
    </div>
  );

}

export default TimePickerComp;