//action types
export const GET_TAGS = 'GET_TAGS';
export const ADD_TAGS = 'ADD_TAGS';
export const VIEW_TAGS = 'VIEW_TAGS';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const DELETE_TAGS = 'DELETE_TAGS';

//get 
export function getTags(data, callback) {
  return {
    type: GET_TAGS,
    payload : {data, callback},
  };
}
//add 
export function addTags(data, callback) {
  return {
    type: ADD_TAGS,
    payload : {data, callback},
  };
}

//view 
export function viewTags(data, callback) {
  return {
    type: VIEW_TAGS,
    payload : {data, callback},
  };
}
//update 
export function updateTags(data, callback) {
    return {
      type: UPDATE_TAGS,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteTags(payload) {
  return {
    type: DELETE_TAGS,
    payload
  };
}
