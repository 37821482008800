//action types
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

//get 
export function getDocument(data, callback) {
  return {
    type: GET_DOCUMENT,
    payload : {data, callback},
  };
}
//add 
export function addDocument(data, callback) {
  return {
    type: ADD_DOCUMENT,
    payload : {data, callback},
  };
}
  
//delete 
export function deleteDocument(payload) {
  return {
    type: DELETE_DOCUMENT,
    payload
  };
}
