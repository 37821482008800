import { Formik } from "formik"
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap"
import * as Yup from 'yup';
import { isEmpty } from "helpers/common";

const RatingScaleFields = ({ columnData, setColumnData, isValid, setFieldValue, errors }) => {
  const [fields, setFields] = useState({
    scale: [],
    minNum: 0,
    maxNum: 10,
    minTxt: "",
    maxTxt: "",
  });

  useEffect(() => {
    if(!isEmpty(columnData)){
      setFields({
        minNum: columnData ? columnData.minNum : fields.minNum,
        maxNum: columnData ? columnData.maxNum : fields.maxNum,
        minTxt: columnData ? columnData.minTxt : "",
        maxTxt: columnData ? columnData.maxTxt : "",
      });
    } else {
      handleFormSubmit(fields);
    }
  }, [columnData])

  const handleFormSubmit = (values) => {
    try {
      setColumnData(values);
    } catch (err) {
      console.log("Error saving values", err);
    }
  }

  const handleInputChange = (e, fieldName) => {
    // update scale value
    const newFields = {
      ...fields,
      [fieldName]: e.target.value
    }
    newFields.minNum = newFields.minNum && parseInt(newFields.minNum);
    newFields.maxNum = newFields.maxNum && parseInt(newFields.maxNum);
    if(newFields.minNum && newFields.maxNum) {
      newFields.scale = range(newFields.minNum, newFields.maxNum);
    }
    setFields(newFields);
    handleFormSubmit(newFields);
  }

  const range = (min, max) => {
    try {
      return [...Array(max - min + 1).keys()].map(i => i + min)
    } catch(err) {
      return [];
    }
  };
  
  return (
    <Row>
      <Col lg="12" className="my-2">
        <h6>Allowed Number Range (required)</h6>
        <p className="m-0">The minimum and maximum numbers displayed to clients (minimum of 0; maximum of 10).</p>
      </Col>
      <Col lg="12" className="my-2">
        <Row >
          <Col lg="6">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Minimum Number:
            </label>
            <input
              type="number"
              className={`form-control ${errors.minNum && "is-invalid"}`}
              name="minNum"
              onChange={(e) => handleInputChange(e, "minNum")}
              value={fields.minNum}
              autoComplete="off"
            />
            {errors.minNum && (
              <p className="invalid-feedback">{errors.minNum}</p>
            )}
          </Col>
          <Col lg="6">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Maximum Number:
            </label>
            <input
              type="number"
              className={`form-control ${errors.maxNum && "is-invalid"}`}
              name="maxNum"
              onChange={(e) => handleInputChange(e, "maxNum")}
              value={fields.maxNum}
              autoComplete="off"
            />
            {errors.maxNum && (
              <p className="invalid-feedback">{errors.maxNum}</p>
            )}
          </Col>
        </Row>
      </Col>
      <Col lg="12" className="my-2">
        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
          Minimum Rating Text: (optional)
        </label>
        <input
          type="text"
          className={`form-control ${errors.minTxt && "is-invalid"}`}
          name="minTxt"
          onChange={(e) => handleInputChange(e, "minTxt")}
          value={fields.minTxt}
          autoComplete="off"
        />
        {errors.minTxt && (
          <p className="invalid-feedback">{errors.minTxt}</p>
        )}
      </Col>
      <Col lg="12" className="my-2">
        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
          Maximum Rating Text: (optional)
        </label>
        <input
          type="text"
          className={`form-control ${errors.maxTxt && "is-invalid"}`}
          name="maxTxt"
          onChange={(e) => handleInputChange(e, "maxTxt")}
          value={fields.maxTxt}
          autoComplete="off"
        />
        {errors.maxTxt && (
          <p className="invalid-feedback">{errors.maxTxt}</p>
        )}
      </Col>
    </Row>
  )
}

export default RatingScaleFields;