import React, { useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import {
	select,
	line,
	curveCardinal,
	scaleLinear,
	axisBottom,
	axisLeft,
	arc,
} from "d3";
import canvg from 'canvg';

//chart component
export const D3Chart = forwardRef(function Chart({ chartData, height, screenSize }, ref) {
	//refs
	const svgRef = useRef();
	const svgContainerRef = useRef();
	const imgRef = useRef();

	//draws chart
	useEffect(() => {
		if (chartData) {
			const svg = select(svgRef.current);
			svg.selectAll("*").remove();
			let totalHeight = 400,
				totalWidth = 300,
				windowWidth = svgContainerRef.current && svgContainerRef.current?.offsetWidth || 600,
				topMargin = 2,
				rightMargin = 2,
				bottomMargin = 2,
				leftMargin = 2,
				innerRadiusPct = 0.3,
				labelFontSize = 16,
				numSlices = 6,
				fullPie = Math.PI * 2,
				fullSlice = fullPie / numSlices;
			let centerX = totalWidth / 2;
			let centerY = totalHeight / 2;
			let totalCirHeight = totalHeight - topMargin - bottomMargin;
			let totalCirWidth = totalWidth - leftMargin - rightMargin;
			let maxOuterRadius = totalCirHeight * 0.5;
			let minInnerRadius = totalCirHeight * 0.5 * innerRadiusPct;
			if (totalCirWidth < totalCirHeight) {
				maxOuterRadius = totalCirWidth * 0.5;
				minInnerRadius = totalCirWidth * 0.5 * innerRadiusPct;
			}
			svg.attr('height', totalHeight);
			let rotateA = -360 / (numSlices * 2);
			let startA = 0;
			let endA = fullSlice;
			let sliceNum = 0;
			while (sliceNum < numSlices) {
				svg.append("path")
					.attr("transform", "translate(" + centerX + "," + centerY + ") rotate(" + rotateA + ")")
					.attr("d", arc()
						.innerRadius(minInnerRadius)
						.outerRadius(maxOuterRadius)
						.startAngle(startA)
						.endAngle(endA)
					)
					.attr('fill', chartData[sliceNum].bgColor);
				startA = endA;
				endA = startA + fullSlice;
				sliceNum++;
			}
			startA = 0;
			endA = fullSlice;
			sliceNum = 0;
			while (sliceNum < numSlices) {
				let pctFill = chartData[sliceNum].score / (chartData[sliceNum].maxNum - chartData[sliceNum].minNum);
				if (pctFill === 0) {
					pctFill = 0.015;
				}
				let outerR = minInnerRadius + (maxOuterRadius - minInnerRadius) * pctFill;
				svg.append("path")
					.attr("transform", "translate(" + centerX + "," + centerY + ") rotate(" + rotateA + ")")
					.attr("d", arc()
						.innerRadius(minInnerRadius)
						.outerRadius(outerR)
						.startAngle(startA)
						.endAngle(endA)
					)
					.attr('fill', chartData[sliceNum].color);
				startA = endA;
				endA = startA + fullSlice;
				sliceNum++;
			}
			sliceNum = 0;
			while (sliceNum < numSlices) {
				let sliceA = 30 + (360 / numSlices) * sliceNum;
				let lineX2 = maxOuterRadius * Math.sin(Math.PI * 2 * sliceA / 360) + centerX;
				let lineY2 = (maxOuterRadius * Math.cos(Math.PI * 2 * sliceA / 360)) * -1 + centerY;
				svg.append("line")
					.attr("x1", centerX)
					.attr("y1", centerY)
					.attr("x2", lineX2)
					.attr("y2", lineY2)
					.attr("stroke", "#fff")
					.attr("stroke-width", "4");
				sliceNum++;
			}
			sliceNum = 0;
			while (sliceNum < numSlices) {
				let sliceA = (360 / numSlices) * sliceNum;
				let labelRadius = minInnerRadius + (maxOuterRadius - minInnerRadius) * 0.5;
				let labelX = labelRadius * Math.sin(Math.PI * 2 * sliceA / 360) + centerX;
				let labelY = (labelRadius * Math.cos(Math.PI * 2 * sliceA / 360)) * -1 + centerY;
				svg.append("text")
					.attr("x", labelX)
					.attr("y", labelY)
					.attr("text-anchor", "middle")
					.attr("class", "robo-simple-bar-y-label")
					.attr("style", "fill: #fff; stroke: none; font-size: " + labelFontSize + "px; font-family: 'Open Sans', sans-serif;")
					.text(chartData[sliceNum].title);
				sliceNum++;
			}
		}
	}, [chartData]);

	useImperativeHandle(ref, () => {
		return {
			downloadChartImage() {
				if (svgRef.current) {
					const svgString = new XMLSerializer().serializeToString(svgRef.current);

					// Create a canvas element
					var canvas = document.createElement('canvas');

					canvas.width = 350;
					canvas.height = 350;

					// Use canvg library to render SVG onto the canvas synchronously
					canvg(canvas, svgString);

					// Convert the canvas to a PNG data URL
					var pngDataURL = canvas.toDataURL('image/png');

					return pngDataURL;
				}
			}
		}
	});

	return (
		<div ref={svgContainerRef} className="d-flex justify-content-center">
			<svg xmlns="http://www.w3.org/2000/svg" ref={svgRef}></svg>
			<img ref={imgRef} />
		</div>
	);
})

export default D3Chart;