import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

// icn
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

import { getDataAnalysisReportsV2 } from "store/services/clientDataReportsService";
import SelectNAQs from "../../SelectNAQs";
import SymptomBurdonReport from "./Components/SymptomBurdenReport";
import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";
import DownloadSBComparisonv2Report from "./Components/SBCompReportPDF";

const Naqv2SymptomReport = ({ dispatch, naqList, client }) => {
  const { clientId } = useParams();
  const [symptomBurdernData, setSymptomBurdernData] = useState(null);
  const [selectedNAQs, setSelectedNAQs] = useState([]);

  useEffect(() => {
    let todoIds = naqList && naqList.map(naq => naq.id);
    if (todoIds) {
      getNAQv2ReportsData(todoIds);
    }
  }, [naqList]);

  useEffect(() => {
    if (selectedNAQs && selectedNAQs.length > 1) {
      getNAQv2ReportsData(selectedNAQs);
    }
  }, [selectedNAQs])

  const getNAQv2ReportsData = async (todoIds) => {
    try {
      let data = {
        clientId,
        values: {
          todoIds
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      const response = await getDataAnalysisReportsV2(data);
      dispatch(actions.persist_store({ loader: false }));
      if (response.success === true && response.body.condition && response.body.condition.length > 0) {
        setSymptomBurdernData(response.body);
      }
    } catch (error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeSelectedNAQ = (selected) => {
    setSelectedNAQs(selected);
  }

  return (
    <>
      <div className="top d-flex align-items-start justify-content-between gap-10">
        <div className="left">
          <h6 className="m-0 fw-bold">Symptom Burden Comparison Report</h6>
          <p className="m-0">
            Compare Symptom Burden Reports from {FULL_NAME(client.first_name, client.last_name)}'s NAQs.
          </p>
        </div>
        <div className="right">
          <DownloadSBComparisonv2Report
            client={client}
            naqList={naqList}
            todoIds={selectedNAQs}
            label={"Download Report"}
          />
        </div>
      </div>
      <div className="CardBody p-3 bg-light my-2">
        <SelectNAQs naqList={naqList} onChange={handleChangeSelectedNAQ} />
        <SymptomBurdonReport symptomBurdernData={symptomBurdernData} naqList={naqList} selectedNAQs={selectedNAQs}/>
      </div>
      <p className="m-0 py-2 text-dark">
        *Looking for all the NAQ questions/answers your client submitted? All
        of the NAQ data is under the{" "}
        <Link to="" className="underline">
          Data & To-Do Items
        </Link>{" "}
        tab.
      </p>
    </>
  );
};

const formatData = (data) => {
  const foundations = data.foundations.map(foundation => foundation.data);
  const bodySystems = data.body_systems.map(bodySystem => bodySystem.data);

  return {
    foundations,
    bodySystems,
  }
}

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Naqv2SymptomReport);