import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AppointmentCalender from "../AppointmentSchedule/Index";
import { getAppointmentBookingList } from "store/services/appointmentService";
import * as actions from "store/actions";
import { FULL_NAME } from "helpers/common";
import moment from "moment";

// images
import bookApptImg from "Assets/Images/bookAppt.png";

const AppointmentCalendarStep = ({
  dispatch,
  nextStep,
  currentStep,
  scheduleSec,
  setScheduleSec,
  handleChangeBlock_,
  blockfields,
  submitBlockTimeData,
  setBlockAdd,
  BlockAdd,
  accountList,
  validationErrors,
  buttonDisable_,
  appointmentTypeList,
  timeZoneListing,
  appointmentBookingList,
  setEditValueSetting,
  setSchedulerType,
  setStepType,
  calendarBlocks,
  schedulerTimezone,
  schedulerBlockDatesSetting,
  accountSettingList,
  user,
}) => {

  const [bookApp, setBookApp] = useState(false)
  useEffect(() => {
    fetchAppointmentBookings();
    if(bookApp){
      fetchAppointmentBookings();
    }
  }, [bookApp])

  const fetchAppointmentBookings = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getAppointmentBookingList());
      setBookApp(false)
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error fetching booking list", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }
  
  return (
    <>
      <Col lg="12" className="my-2">
        <AppointmentCalender
          scheduleSec={scheduleSec}
          setSchedulerType={setSchedulerType}
          setScheduleSec={setScheduleSec}
          handleChangeBlock_={handleChangeBlock_}
          blockfields={blockfields}
          submitBlockTimeData={submitBlockTimeData}
          setBlockAdd={setBlockAdd}
          BlockAdd={BlockAdd}
          accountList={accountList}
          validationErrors={validationErrors}
          buttonDisable_={buttonDisable_}
          appointmentTypeList={appointmentTypeList}
          timeZoneListing={timeZoneListing}
          appointmentBookingList={appointmentBookingList}
          setEditValueSetting={setEditValueSetting}
          setStepType={setStepType}
          setBookApp={setBookApp}
          calendarBlocks={calendarBlocks}
          schedulerTimezone={schedulerTimezone}
          schedulerBlockDatesSetting={schedulerBlockDatesSetting}
          accountSettingList={accountSettingList}
        />
      </Col>
      <Col>
        <Row>
          <Col lg="6" className="my-2">
            <div
              className="CardCstm SubCardBox px-lg-4 p-3 pay-4 h-100"
              style={{ background: "#051C27" }}
            >
              <div className="content">
                <div className="px-2">
                  <h6 className="fw-sbold text-white m-0 pb-1">
                    Add a Scheduler link to your site
                  </h6>
                  <p className="">
                    Adding the following code to your website will
                    add a blue button with the text "Book an
                    Appointment" on it. When someone clicks this
                    button, it will open a new tab with your
                    Scheduler.
                  </p>
                </div>
                <div
                  className="p-3 innerCard"
                  style={{ background: "#31444c" }}
                >
                  <p className="text-white m-0">
                  {`<a href="${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}" target="_blank" alt="Book an Appointment"><img style="height:34px;width:181px;" height="34" width="181" src="${process.env.REACT_APP_URL}${bookApptImg}"></a>`}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" className="my-2">
            <div
              className="CardCstm SubCardBox px-lg-4 p-3 py-4 h-100"
              style={{ background: "#051C27" }}
            >
              <div className="content">
                <div className="px-2">
                  <h6 className="fw-sbold text-white m-0 pb-1">
                    Embed your Scheduler in your site
                  </h6>
                  <p className="">
                    This is a bit more advanced. Adding the
                    following code to your website will embed your
                    entire Scheduler into your site. This is a
                    nice option if you'd like clients to be able
                    to book an appointment without leaving your
                    site. Ideally you would put this code on a
                    page that doesn't have much other content on
                    it since the Scheduler interface can be large.
                  </p>
                </div>
                <div
                  className="p-3 innerCard"
                  style={{ background: "#31444c" }}
                >
                  <p className="text-white m-0">
                    {`<iframe id="nutri-q-appt-view" src="${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}" frameborder="no" style="width:1px;min-width:100%;height:"100vh"></iframe>`}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}

const mapStateToPros = (state) => {
  const appointments = state.Appointment.appointmentBookingList;
  let schedulerBlockDatesSetting;
  let calendarBlocks;
  try {
    let accountSettingList = state?.AccountSetting?.accountSettingList;
    if(accountSettingList && accountSettingList.length > 0){
      schedulerBlockDatesSetting = accountSettingList.find(setting => setting.data_key === "scheduler_blocked_dates");
      if(schedulerBlockDatesSetting) {
        calendarBlocks = JSON.parse(schedulerBlockDatesSetting?.data_set)
      }
    }
  } catch(error) {
    console.log("Error getting calendar blocks")
  }
  
  return {
    // events,
    appointmentBookingList: appointments || [],
    schedulerBlockDatesSetting,
    calendarBlocks,
    user: state.Profile?.user,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AppointmentCalendarStep);