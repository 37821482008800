import {Text, View } from '@react-pdf/renderer';
import { color } from 'd3';
import { decode } from 'html-entities';

const InputBoxes = (props) => {
  const textFields = ["textTiny",'textXShort','textShort']
  const textareaFields = ['textLong','textXLong','textXXLong']

  if(textFields.includes(props?.question?.question_type)){
    return (
      <View style={{flexDirection:"column"}}>
          <Text 
            style={{
                  border:"1px solid #ccc", 
                  borderRadius:5, 
                  width:"100%",
                  height:"25px"
               }}>              
          </Text>
          <Text 
            style={{
                  fontSize:10,
                  display:"flex",
                  alignSelf:"flex-start",
                  color:"#1f1e1e"
                }}>
                  {decode(props?.question?.question_help)}
          </Text>
       </View>
    )
   
  }else if(textareaFields.includes(props?.question?.question_type)){
    return (
      <View style={{flexDirection:"column"}}>
          <Text 
            style={{
                  border:"1px solid #ccc", 
                  borderRadius:5, 
                  width:"100%",
                  height:"80px"
               }}>              
          </Text>
          <Text 
            style={{
                  fontSize:10,
                  display:"flex",
                  alignSelf:"flex-start",
                  color:"#1f1e1e"
                }}>
                  {decode(props?.question?.question_help)}
          </Text>
       </View>
    )
  }else{
    return null;
  }
   
};

export default InputBoxes