import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dropzone from "components/shared/Dropzone";
import { Formik } from "formik";
import { formatBytes } from "helpers/common";

const maxUploadSize = 5e+6; // in bytes

const UploadDoc = ({
  UploadDocPop,
  setUploadDocPop,
  initialValues,
  submitData_,
}) => {
  const handleUploadDoc = () => setUploadDocPop(!UploadDocPop);

  const allowTypes = [
    "application/pdf",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const allowTypeError = ["PDF", "DOCX"];
  const extension = "";
  return (
    <>
      <Modal
        show={UploadDocPop}
        onHide={handleUploadDoc}
        backdrop="static"
        keyboard={false}
        centered
        className="UploadDocPop FormPop"
      >
        <Modal.Header className="text-center justify-content-center">
          <Modal.Title className="fw-bold">FILES TO UPLOAD</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            // validationSchema={validation}
            onSubmit={submitData_}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Row className="justify-content-center">
                  <Col lg="10" className="my-2">
                    <Dropzone
                      file={values.name} // Provide the file as an object
                      onBlur={handleBlur}
                      onChange={(file, url, extension, file_type, size) => {
                        setFieldValue("file", file);
                        setFieldValue("url", url);
                        setFieldValue("extension", extension);
                        setFieldValue("file_type", file_type);
                        setFieldValue("size", size);
                      }}
                      allowType={allowTypes}
                      allowTypeError={allowTypeError}
                      // extension_={extension}
                      imageRemoveButton={true}
                      maxFileSize={maxUploadSize}
                    />
                    {/* <div className="uploadFile position-relative py-3 px-lg-4 px-3 text-center bg-white"> */}
                    {/* <div className="content">
                      <div className="imgWrp my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="39"
                          height="39"
                          viewBox="0 0 39 39"
                          fill="none"
                        >
                          <g opacity="0.5">
                            <path
                              d="M19.2673 0.371094C8.74971 0.371094 0.194092 8.92671 0.194092 19.4443C0.194092 29.962 8.74971 38.5176 19.2673 38.5176C29.785 38.5176 38.3406 29.9605 38.3406 19.4443C38.3406 8.9282 29.785 0.371094 19.2673 0.371094ZM19.2673 35.5628C10.3808 35.5628 3.14888 28.3324 3.14888 19.4443C3.14888 10.5563 10.3808 3.32588 19.2673 3.32588C28.1539 3.32588 35.3858 10.5563 35.3858 19.4443C35.3858 28.3324 28.1554 35.5628 19.2673 35.5628Z"
                              fill="black"
                            />
                            <path
                              d="M26.6543 17.8343H20.7448V11.9247C20.7448 11.1092 20.0844 10.4473 19.2673 10.4473C18.4503 10.4473 17.7899 11.1092 17.7899 11.9247V17.8343H11.8803C11.0633 17.8343 10.4029 18.4962 10.4029 19.3117C10.4029 20.1272 11.0633 20.7891 11.8803 20.7891H17.7899V26.6987C17.7899 27.5142 18.4503 28.1761 19.2673 28.1761C20.0844 28.1761 20.7448 27.5142 20.7448 26.6987V20.7891H26.6543C27.4714 20.7891 28.1318 20.1272 28.1318 19.3117C28.1318 18.4962 27.4714 17.8343 26.6543 17.8343Z"
                              fill="black"
                            />
                          </g>
                        </svg>
                      </div>
                      <p className="m-0 py-2">
                        Drag & drop or click to choose files
                      </p>
                    </div> */}
                    {/* </div> */}
                    <p className="py-2 m-0 text-center">
                      <i>
                        Combined file upload size can't be larger than 5 MB
                      </i>
                    </p>
                    {/* </Dropzone> */}
                  </Col>
                  <Col lg="12">
                    <div className="d-flex align-items-center justify-content-center gap-10 FormbtnWrpper">
                      <Button
                        onClick={handleUploadDoc}
                        className="d-flex align-items-center justify-content-center commonBtn"
                      >
                        Cancel
                      </Button>
                      <Button
                        // onClick={handleUploadDoc}
                        type="submit"
                        // loading={loader}
                        disabled={isSubmitting}
                        className="d-flex align-items-center justify-content-center commonBtn"
                      >
                        { isSubmitting ? "Please wait..." : "Upload Docs"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadDoc;
