import React from 'react';
import { Dropdown } from 'react-bootstrap';

const PageSizeDropdown = ({ selectedPageSize, pageSizeOptions, onPageSizeChange }) => {

  const handlePageSizeChange = (pageSize) => {
    onPageSizeChange(pageSize);
  };

  return (
    <Dropdown className="page-size-dropdown">
      <Dropdown.Toggle
        variant="transparent"
        className=" btnSm text-uppercase border pe-4 position-relative "
        id="page-size-dropdown"
      >
          {selectedPageSize}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {pageSizeOptions.map((pageSize, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handlePageSizeChange(pageSize)}
          >
            {pageSize}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PageSizeDropdown;