import React from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
//icn
// import { ReactComponent as OTick } from "../../../Assets/Images/icn/OrangeTick.svg";

//imported
import validation from "./validation";
import LoadingButton from "components/shared/LoadingButton";

const Form_ = ({
  initialValues,
  submitting,
  updateData_,
  loader,
  handleNTAMember,
  step,
  setStep,
}) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validation}
        onSubmit={updateData_}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row>
              <Col lg="12" className="my-2">
                <p className="m-0">
                  Please provide the following information so we can confirm
                  your NTA membership.
                </p>
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 text-dark fw-sbold">
                  First Name
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.first_name && "is-invalid"
                  }`}
                  name="first_name"
                  onChange={handleChange}
                  placeholder="Your First Name"
                  onBlur={handleBlur}
                  value={values.first_name}
                  autoComplete="off"
                />
                {errors.first_name && touched.first_name && (
                  <p className="invalid-feedback">{errors.first_name}</p>
                )}
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 text-dark fw-sbold">
                  Last Name
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.last_name && "is-invalid"}`}
                  name="last_name"
                  onChange={handleChange}
                  placeholder="Your Last Name"
                  onBlur={handleBlur}
                  value={values.last_name}
                  autoComplete="off"
                />
                {errors.last_name && touched.last_name && (
                  <p className="invalid-feedback">{errors.last_name}</p>
                )}
              </Col>
              <Col lg="12" className="my-2">
                <div className="py-1">
                  <p className="m-0 py-1 fw-sbold text-dark">
                    <i>
                      Please check the following box to indicate your agreement:
                    </i>
                  </p>
                  <div className="d-flex align-items-start formCheck py-1">
                    <input
                      type="checkbox"
                      className="form-check me-2"
                      name="standard_account"
                      value="single_user"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <p className="text-muted m-0">
                      This account will only be used by a single practitioner.
                      (Sign up for the Standard Account if multiple
                      practitioners will be sharing this account.)
                    </p>
                  </div>
                </div>
                <div className="py-1">
                  <p className="m-0 py-1 fw-sbold text-dark">
                    <i>NTA Membership Status:</i>
                  </p>
                  <div className="d-flex align-items-start formCheck py-1">
                    <input
                      type="checkbox"
                      className="form-check me-2"
                      name="nta_member_status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="nta_member"
                    />
                    <p className="text-muted m-0">
                      I am currently an NTA member. If the NTA has any trouble
                      verifying my current membership status, please have them
                      contact me at the email below.
                    </p>
                  </div>
                  {/* <div className="d-flex align-items-start formCheck py-1">
                    <input
                      type="radio"
                      className="form-check me-2"
                      name="nta_member_status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="graduate"
                    />

                    <p className="text-muted m-0">
                      I am an NTA graduate and I would like to purchase an NTA
                      membership so that I can sign up for this discounted rate.
                      Please have the NTA contact me at the email below. (Sign
                      up for the Standard Account if you are not an NTA
                      graduate.)
                    </p>
                  </div> */}
                </div>
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 text-dark fw-sbold">
                  Contact email for any followup from the NTA
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email && "is-invalid"}`}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  onBlur={handleBlur}
                  value={values.email}
                  autoComplete="off"
                />
                {errors.email && touched.email && (
                  <p className="invalid-feedback">{errors.email}</p>
                )}
              </Col>
            </Row>
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-2">
                <LoadingButton
                  type="submit"
                  className="d-flex btn text-white align-items-center  commonBtn"
                  loading={loader}
                  disabled={submitting}
                  // onClick={() => setStep("2")}
                >
                  {isSubmitting ? "wait..." : "Submit"}
                </LoadingButton>
              </div>
              <div className="col-md-2">
                <Button
                  onClick={handleNTAMember}
                  className="d-flex align-items-center  commonBtn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Form_;
