import { GET_PRACTITIONER, ADD_PRACTITIONER, VIEW_PRACTITIONER, UPDATE_PRACTITIONER, DELETE_PRACTITIONER } from 'store/actions/practitioner';

const initialState = {
    isAuthenticated: false,
    practitioners: [],
    practitioner: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getPractitioner = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        practitioners:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addPractitioner = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      practitioner:data.body,
    };
    return stateObj;
  };

  //update 
const viewPractitioner = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      practitioner:data.body,
    };
    return stateObj;
};

  //update 
const updatePractitioner = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      practitioners:data.body,
    };
    return stateObj;
};

//delete 
const deletePractitioner = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioners:[],
  };
  return stateObj;
};

const Practitioner = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_PRACTITIONER:
          return getPractitioner(state, payload);
        case ADD_PRACTITIONER:
            return addPractitioner(state, payload);
        case VIEW_PRACTITIONER:
          return viewPractitioner(state, payload);
        case UPDATE_PRACTITIONER:
          return updatePractitioner(state, payload);
        case DELETE_PRACTITIONER:
          return deletePractitioner(state, payload);
        default:
        return state;
    };
}
export default Practitioner;