import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

// icn
import {
  addClientData,
  getClientCategoryList_,
} from "store/services/clientService";
import { getMyFormList } from "store/services/myFormService";
import * as actions from "store/actions";
import Form from "./Form";
import { DIRECT_ACCOUNT_TYPE, FULL_ACCOUNT_TYPE, PRACTITIONER_CAT_ID } from "common/constants";

const AddNewClient = ({
  submitting,
  dispatch,
  myFormListing,
  accountSettingDetail,
  clientCategory,
  userProfile,
}) => {
  const navigate = useNavigate();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [addClientData_, setAddClientData_] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(PRACTITIONER_CAT_ID);
  const [userAccountType, setUserAccountType] = useState(FULL_ACCOUNT_TYPE);
  const [emailInviteValue, setEmailInviteValue] = useState(false);
  const [addClientValues, setAddClientValues] = useState(null);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    birth_date: "",
    is_email_invitation: false,
    is_direct_account: userAccountType == DIRECT_ACCOUNT_TYPE ? "yes" : "no",
    client_category: selectedCategoryValue,
    todo_items: [],
    send_links_email: "yes",
  };

  //get client catgeory data
  async function getClientCategoryData() {
    try {
      await dispatch(getClientCategoryList_());
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getClientCategoryData();
  }, []);
  //get data
  async function getData() {
    try {
      if (selectedCategoryValue) {
        let query = {
          category_id: selectedCategoryValue,
          account_type: userAccountType,
          intent: "add_client",
        };
        await dispatch(getMyFormList(query));
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
    if (addClientData_) {
      setSelectedCheckboxes([]);
    }
  }, [selectedCategoryValue, userAccountType]);

  const handleCheckboxChange = (event, checkboxId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxId]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== checkboxId)
      );
    }
  };

  const submitData_ = async (values, { setSubmitting, resetForm }) => {
    try {
      const phoneNumberWithoutPlus = values.phone.substring(
        values?.country_code?.length
      );
      const codeWithoutPlus = values?.country_code;
      let address_ = {};
      if (
        values.street !== "" &&
        values.apartment !== "" &&
        values.city !== "" &&
        values.state !== "" &&
        values.zipcode !== "" &&
        values.country !== ""
      ) {
        address_ = {
          street: values.street,
          apartment: values.apartment,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
          country: values.country,
        };
      }

      let finalObj = {
        is_email_invitation: values.is_email_invitation,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        birth_date: values.birth_date,
        phone: phoneNumberWithoutPlus,
        country_code: codeWithoutPlus?.startsWith("+")
          ? codeWithoutPlus
          : "+" + codeWithoutPlus,
        is_direct_account: values.is_direct_account
          ? values.is_direct_account
          : "no",
        todo_items: values.todo_items,
        client_category: values.client_category,
        address: address_,
        send_links_email: values.send_links_email
      };
      finalObj = JSON.stringify(finalObj);
      setSubmitting(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(addClientData(finalObj));
      dispatch(actions.persist_store({ loader: false }));
      setAddClientData_(res.body);
      setAddClientValues(values);
      setSubmitting(false);
      setSelectedCheckboxes([]);
      setSelectedCategoryValue("");
      resetForm();
    } catch (err) {
      console.log(err, "==============================");
      dispatch(actions.persist_store({ loader: false }));
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="MyClients">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <Form
                initialValues={initialValues}
                submitting={submitting}
                updateData_={submitData_}
                myFormListing={myFormListing}
                handleCheckboxChange={handleCheckboxChange}
                selectedCheckboxes={selectedCheckboxes}
                addClientData_={addClientData_}
                accountSettingDetail={accountSettingDetail}
                clientCategory={clientCategory}
                setSelectedCategoryValue={setSelectedCategoryValue}
                emailInviteValue={emailInviteValue}
                setEmailInviteValue={setEmailInviteValue}
                addClientValues={addClientValues}
                setUserAccountType={setUserAccountType}
                practitionerInfo={userProfile}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    userProfile: state.Profile.user,
    myFormListing: state.MyForm.myForms,
    clientCategory: state.Client.client_category,
    accountSettingDetail: state.AccountSetting.accountSettingDetail_,
    loader: state.MyForm.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddNewClient);
