import { decode } from "html-entities";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion, Button, Form, Row, Col } from 'react-bootstrap';
import { getTodoResponse } from "store/services/clientService";
import { RenderAnswerField } from "components/shared/ToDoQuestionsAnswers/RenderAnswer/Index";
import { validatePageConditions, validateQuestionConditions } from "helpers/common";
import { NAQV1_ID } from "common/constants";
import { formatTodoResponse } from "helpers/dataFormat";

const AllQuestionAnswers = ({ eventKey, todoId, formId }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [todoDetails, setTodoDetails] = useState(null);
  // let questionCount = 0;
  let questionCount = formId === NAQV1_ID ? -1 : 0;

  useEffect(() => {
    if (isOpen && !todoDetails) {
      getTodoDetails();
    }
  }, [isOpen])

  const getTodoDetails = async () => {
    if (!todoId) {
      return;
    }
    try {
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      const response = await getTodoResponse(data);
      if (response.success === true) {
        const details = formatTodoResponse(response.body);
        const formattedData = validateConditions(details);
        setTodoDetails(formattedData);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  const validateConditions = (allPages) => {
    let allQuestionAnswers = [];
    for(let i = 0; i < allPages.length; i++) {
      if(allPages[i] && allPages[i].questions && allPages[i].questions.length > 0) {
        allQuestionAnswers= [...allPages[i].questions, ...allQuestionAnswers];
      }
    }
    return allPages.map(page => {
      page.visible = validatePageConditions(page, allQuestionAnswers);
      page.questions = page.questions.map(q => {
        q.visible = validateQuestionConditions(q, allQuestionAnswers);
        return q;
      })
      return page;
    })
  }

  // const validatePageConditions = (page, questionAnswers) => {
  //   try {
  //     let showPage = false;
  //     if(!page.page_depend_q_id || !page.page_depend_q_ans || !page.page_depend_q_ans?.length) {
  //       showPage = true;
  //     } else {
  //       let dependQuestionAns = JSON.parse(page.page_depend_q_ans);
  //       let questionIndex = questionAnswers.findIndex(q => ((q.id == page.page_depend_q_id) && q.visible));
  //       let foundQuestion = questionAnswers[questionIndex];
  //       let dependSourceAnswer = null;
  //       if(questionIndex !== -1 && foundQuestion) {
  //         dependSourceAnswer = foundQuestion.answer;
  //         // if answer is singular and not an array
  //         if(typeof dependSourceAnswer == "string") {
  //           if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
  //             showPage = true;
  //           }
  //         // if answer is an array, check if any of the given answers match the condition
  //         } else if(Array.isArray(dependSourceAnswer)) {
  //           let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
  //           if(findMatch) {
  //             showPage = true;
  //           }
  //         }
  //       }
  //     }
  //     return showPage;
  //   } catch(err) {
  //     console.log("Error validating page conditions", err);
  //     return true;
  //   }
  // }

  // const validateQuestionConditions = (question, questionAnswers) => {
  //   if(!question) {
  //     return false;
  //   }
  //   let showQuestion = false;
  //   if(!question.page_depend_q_id || !question.page_depend_q_ans || !question.page_depend_q_ans?.length) {
  //     showQuestion = true;
  //   } else {
  //     let dependQuestionAns = JSON.parse(question.page_depend_q_ans);
  //     let questionIndex = questionAnswers.findIndex(q => ((q.id == question.page_depend_q_id) && q.visible));
  //     let foundQuestion = questionAnswers[questionIndex];
  //     let dependSourceAnswer = null;
  //     if(questionIndex !== -1 && foundQuestion) {
  //       dependSourceAnswer = foundQuestion.answer;
  //       // if answer is singular and not an array
  //       if(typeof dependSourceAnswer == "string") {
  //         if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
  //           showQuestion = true;
  //         }
  //       // if answer is an array, check if any of the given answers match the condition
  //       } else if(Array.isArray(dependSourceAnswer)) {
  //         let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
  //         if(findMatch) {
  //           showQuestion = true;
  //         }
  //       }
  //     }
  //   }
  //   return showQuestion;
  // }

  return (
    <Accordion.Item eventKey={eventKey} className="priority-report my-2">
      <Accordion.Header>
        All Questions/Answers
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        {isLoading ?
          <p>Loading...</p>
          :
          <div className="accordion-inner">
          {todoDetails && todoDetails.map(page => {
            if(!page || page.visible === false) {
              return null;
            }
            return (
              <>
                <div className="py-2">
                  <div className="top py-2 commonContent">
                    <h3 className="m-0 py-1 text-uppercase">
                      {page.page_title}
                    </h3>
                  </div>
                  <div className="py-2">
                    <div className="commonCardBox">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="p-3 text-dark fw-sbold">Question</th>
                              <th className="p-3 text-dark fw-sbold">Answer Score</th>
                              <th className="p-3 text-dark fw-sbold">Answer</th>
                            </tr>
                          </thead>
                          <tbody>
                            {page.questions && page.questions.length > 0 &&
                              page.questions.map((question, i) => {
                                // console.log("question", question)
                                if(!question || question.visible === false) {
                                  return null;
                                }
                                questionCount++;
                                return (
                                  <tr>
                                    <th className="p-3 ws-normal">
                                      <span className="text-dark fw-sbold">
                                        {question.sorting}. {decode(question.question)}
                                      </span>
                                    </th>
                                    <td>
                                      <ul className="answer-score">
                                        {question.other_column_data && question.other_column_data.length > 0 &&
                                          question.other_column_data.map((item, i) => {
                                            return (
                                              <li 
                                                className={`${item.answer == question.answer ? "current-answer" : ""}`}
                                              >
                                                {i}
                                              </li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </td>
                                    <td className="p-3 ws-normal">
                                      <RenderAnswerField
                                        question={question}
                                      />
                                    </td>
                                  </tr>
                                )
                              })
                            }

                          </tbody>
                        </table>
                      </div>
                      {/* <div className="py-2 px-lg-4 px-3">
                      <p className="m-0 py-2">
                        My Notes: General Information & Goals (not visible to
                        clients)
                      </p>
                      <Button
                        className="border-0 p-0 text-dark d-flex align-items-center underline btnSm"
                        variant="transparent"
                      >
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="19"
                            viewBox="0 0 21 19"
                            fill="none"
                          >
                            <path
                              d="M17.891 0.820312H3.61682C2.12441 0.820312 0.910156 2.03432 0.910156 3.52697V12.2625C0.910156 13.7518 2.11909 14.9639 3.60722 14.9692V18.9332L9.30401 14.9691H17.891C19.3834 14.9691 20.5976 13.7549 20.5976 12.2625V3.52697C20.5976 2.03432 19.3834 0.820312 17.891 0.820312ZM19.4441 12.2624C19.4441 13.1187 18.7474 13.8156 17.891 13.8156H8.9421L4.76078 16.7252V13.8156H3.61682C2.76041 13.8156 2.06372 13.1188 2.06372 12.2625V3.52697C2.06372 2.67048 2.76041 1.97388 3.61682 1.97388H17.891C18.7474 1.97388 19.4441 2.67048 19.4441 3.52697V12.2624Z"
                              fill="#2B3674"
                            />
                            <path
                              d="M6.17944 4.89648H15.3284V6.05005H6.17944V4.89648ZM6.17944 7.35742H15.3284V8.51098H6.17944V7.35742ZM6.17944 9.81836H15.3284V10.9719H6.17944V9.81836Z"
                              fill="#2B3674"
                            />
                          </svg>
                        </span>
                        Add Notes
                      </Button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </>
            )
          })}
          </div>
        }
      </Accordion.Body>
      </Accordion.Item>
  )
}

export default AllQuestionAnswers;