import { FULL_NAME, UC_FIRST, convertTimezone } from "helpers/common";
import moment from "moment";
import React, {useState, useEffect} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import {currentDayFromMonth_, utcTimestamp_} from 'helpers/common'
import * as actions from "store/actions";
import { DELETE_ALERT, SUCCESS_MESSAGE } from "common/constantMessage";
import ReschedulePopup from "./ReschedulePopup"
import {
  cancelAppointment_,
  appointmentTypeView_,
  updateSchedulerSetting_,
} from "store/services/appointmentService";
import { getAccountSettingList_ } from "store/services/accountSettingService";

const BlockDetailsPopup = ({
  showPopup,
  setShowPopup,
  details,
  schedulerBlockDatesSetting,
  calendarBlocks,
  timezone,
  dispatch,
  accountSettingList,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const closePopup = () => {
    setShowPopup(false);
  }

  const handleDeleteBlock = async (key) => {
    try{
      let fieldId = schedulerBlockDatesSetting.id;
      let blocks = [...calendarBlocks];
      blocks.splice(key, 1);
      let obj = {
        data_set: JSON.stringify(blocks),
      };

      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            setIsDeleting(true);
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(updateSchedulerSetting_(fieldId, obj));
            await dispatch(getAccountSettingList_());
            dispatch(actions.persist_store({ loader: false }));
            setIsDeleting(false);
            SUCCESS_MESSAGE("Calendar Block deleted");
            closePopup();
            accountSettingList();
          } catch (err) {
            console.log(err);
            dispatch(actions.persist_store({ loader: false }));
            setIsDeleting(false);
          }
        }
      });
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  }

  if (!details) {
    return "";
  }

  return (
    <>
      <Form>
        <Modal
          show={showPopup}
          onHide={closePopup}
          backdrop="static"
          keyboard={false}
          centered
          className="FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title>Calendar Block</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="py-2">
              {/* <h5>{details.title}</h5> */}
              <p className="fw-bold">
                {/* {moment(details.start_time).format("dddd, MMMM d")} {` · `}
                {moment(details.start_time).format("H:mm A")} - {moment(details.end_time).format("H:mm A")} */}
                {convertTimezone(details?.start, timezone, "dddd MMMM DD, YYYY")} - {convertTimezone(details?.end, timezone, "dddd MMMM DD, YYYY")}
              </p>
              <p className="fw-bold">
                {!details.isAllDay &&
                  `${convertTimezone(details?.start, timezone, "HH:mm")} - ${convertTimezone(details?.end, timezone, "HH:mm")}`
                }
                
              </p>
          </div>
            
          </Modal.Body>
          
          <Modal.Footer className="FormbtnWrpper d-flex justify-content-between">
            <>
              <div className="d-flex gap-10">
                <Button
                  className="d-flex align-items-center justify-content-center btnSm btn-danger"
                  disabled={isDeleting}
                  onClick={() =>
                    handleDeleteBlock(details.key)
                  }
                >
                  {isDeleting ? "Please wait..." : "Delete"}
                </Button>
              </div>
              <div>
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={closePopup}
                >
                  Close
                </Button>
              </div>
            </>
          </Modal.Footer>
        </Modal>
      </Form >
      
    </>
  );
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BlockDetailsPopup);
