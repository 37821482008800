import React from "react";
import { Button } from "react-bootstrap";
import { RenderEvent } from "./EventTypes/Index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackUI from "components/shared/ErrorFallbackUI";

// css
import "./RecentSidebar.scss";

// icn
import { ReactComponent as CloseBtn } from "../../../../../Assets/Images/icn/close.svg";

const RecentSidebar = ({ RecentTab, setRecentTab, recentEvents }) => {
  const handleRecentTab = () => {
    setRecentTab(!RecentTab);
  };


  return (
    <>
      <div className={`RecentSidebar ${RecentTab && "active"}`}>
        <div className="top position-relative p-3 ">
          <Button
            onClick={handleRecentTab}
            className="border-0 p-0 position-absolute closeBtn d-none align-items-center justify-content-center rounded-0"
            style={{ left: "0", top: "0", background: "#000" }}
          >
            <CloseBtn />
          </Button>
          <h3 className="m-0 fw-bold">Recent Events</h3>
        </div>
        <div className="Inner">
          <ErrorBoundary fallback={<ErrorFallbackUI message={"Failed to load recent events"} />}>
            {(recentEvents && recentEvents?.data.length > 0) ?
              <ul
                className="list-unstyled ps-0 mb-0 ActivityList position-relative"
                style={{ zIndex: "9" }}
              >
                {recentEvents?.data?.map((event, index) => {
                  return (
                    <RenderEvent
                      key={index}
                      event={event}
                    />
                  )
                })
                }
              </ul>
              :
              <div className="px-3 my-3 d-flex align-itmes-start">
                <div className="content">
                  <p>No recent events to show</p>
                </div>
              </div>
            }
          </ErrorBoundary>
        </div>
      </div >
    </>
  );
};

export default RecentSidebar;
