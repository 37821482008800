import React, { useEffect, useState } from "react";
import { decode } from "html-entities";
import moment from "moment";

const PriorityReport = ({ priorityReport, naqList, selectedNAQs, selectedType }) => {
  const [naqs, setNaqs] = useState(naqList);
  useEffect(() => {
    if(naqList && selectedNAQs.length > 1) {
      let naqs = naqList.filter(naq => selectedNAQs.includes(naq.id));
      setNaqs(naqs);
    } else if(naqList) {
      setNaqs(naqList);
    }
  }, [naqList, selectedNAQs]);

  const handleSelectNaq = () => {

  }

  const QuetionsTable = ({ title, questions }) => {
    let naq1 = naqs && naqs.length > 1 && naqs[0];
    let naq2 = naqs && naqs.length > 1 && naqs[1];
    if(selectedNAQs.length > 0) {
      let shouldShowNaq1 = selectedNAQs.find(id => id === naq1.id);
      if(!shouldShowNaq1) {
        naq1 = null;
      }
      let shouldShowNaq2 = selectedNAQs.find(id => id === naq2.id);
      if(!shouldShowNaq2) {
        naq2 = null;
      }
    }
    return (
      <div className="py-3">
        <h6>{title}</h6>
        <div className="table-responsive">
          <table className="table priority-table table-striped">
            <thead>
              {" "}
              <tr>
                <th className="p-3 fw-normal text-dark fw-sbold">
                </th>
                { naq1 &&
                  <th
                    className="p-3 fw-normal text-dark fw-sbold"
                    style={{ borderBottom: `4px solid ${naq1 && naq1.color}` }}
                  >
                    {moment(naq1.updated_at).format("MMM DD, YYYY")}
                  </th>
                }
                { naq2 &&
                  <th
                    className="p-3 fw-normal text-dark fw-sbold"
                    style={{ borderBottom: `4px solid ${naq2 && naq2.color}` }}
                  >
                    {naq2 && moment(naq2.updated_at).format("MMM DD, YYYY")}
                  </th>
                }
                <th className="p-3 fw-normal text-dark fw-sbold">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="p-3 fw-normal text-dark fw-sbold">
                  Question
                </th>
                {naq1 && 
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Answer
                  </th>
                }
                {naq2 && 
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Answer
                  </th>
                }
                {selectedNAQs.length !== 1 &&
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Change
                  </th>
                }
              </tr>
              {questions && questions.length > 0 &&
                questions.map((question) => {
                  return (
                    <tr>
                      <td className="p-3 border-0">{decode(question.question)}</td>
                      {naq1 && <td className="p-3 border-0">{decode(question?.answer_current)}</td>}
                      {naq2 && <td className="p-3 border-0">{decode(question?.answer_previous)}</td>}
                      {selectedNAQs.length !== 1 && <td className="p-3 border-0">{question.score_change}</td>}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className="accordion-inner">
      {/* <div className="py-2">
        <div className="top d-flex align-items-center justify-content-end gap-10">
          <div className="left d-flex align-items-center gap-10">
            <p className="m-0 text-uppercase fw-bold">View Priority Report grouped by:</p>
            <Form.Select
              className="form-control fw-sbold btnSm"
              aria-label="Default select example"
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
            >
              <option value="questionnaireo">Questionnaire Section</option>
              <option value="SBG">SBG Section</option>
            </Form.Select>
          </div>
          <div className="right">
            <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
              <span className="icn me-2">
                <DownloadIcn />
              </span>
              Download Report
            </Button>
          </div>
        </div>
      </div> */}
      <div className="sections-outer">
        {priorityReport && priorityReport[selectedType]?.length > 0 &&
          priorityReport[selectedType].map((section) => {
            if (!section.title || section.title == "null") {
              return "";
            }
            return (
              <div className="section py-4">
                <h5>{section.title}</h5>
                <div className="commonCardBox p-2">

                  {section.improved && section.improved.length > 0 &&
                    <QuetionsTable title="Improved" questions={section.improved} />
                  }
                  {section.worsened && section.worsened.length > 0 &&
                    <QuetionsTable title="Worsened" questions={section.worsened} />
                  }
                  {section.unchanged && section.unchanged.length > 0 &&
                    <QuetionsTable title="Unchanged" questions={section.unchanged} />
                  }
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default PriorityReport;