import { decode } from "html-entities";
import {Text, StyleSheet, View } from '@react-pdf/renderer';

const CheckBoxes = (props) => {
  let checkOptions = null;
  try {
    checkOptions = JSON.parse(props.question.other_column_data);
  } catch (error) {
    console.log("Error while parsing radio options", props.question.question);
  }

  return (
    <ul className="list-unstyled ps-0 mb-0 py-2">
      {checkOptions &&
        checkOptions.length > 0 &&
        checkOptions.map((option, i) => {
          let optionValue = "";
          if (
            option !== null &&
            typeof option === "object" &&
            !Array.isArray(option) &&
            typeof option.answer !== "undefined"
          ) {
            optionValue = option.answer;
          } else {
            optionValue = option;
          }

          return (
            <View style={{flexDirection:"row", alignItems:"center"}}>
              <Text style={{backgroundColor:"#e3e3e3",width:10,height:10,marginRight:5}}>              
              </Text>
              <Text>{decode(optionValue)}</Text>
            </View>
          );
        })}
    </ul>
  );
};

export default CheckBoxes