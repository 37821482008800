import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";

import Header from "../../components/header/Header";
import Sidebar from "../../components/header/Sidebar";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

// css
import "./NonAuth.scss";

const NonAuthLayout = ({ loader }) => {
  const [showSidebar, setSidebar] = useState("");
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  let [color, setColor] = useState("#f4694b");

  return (
    <>
      <Sidebar showSidebar={showSidebar} setSidebar={setSidebar} />
      <main className="ms-auto">
        {loader == true && (
          <div className="loader-overlay">
            <div className="main-loader_">
              <FadeLoader
                color={color}
                loading={loader}
                css={override}
                size={300}
              />
            </div>
          </div>
        )}
        <Header showSidebar={showSidebar} setSidebar={setSidebar} />
        <Outlet />
      </main>
    </>
  );
};

// export default NonAuthLayout;

const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
    loader: state.persistStore.loader,
  };
};

export default connect(mapStateToPros)(NonAuthLayout);
