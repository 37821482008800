import { CONFIRM_POPUP } from "common/constantMessage";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { deleteRecommendationTemplate, updateRecommendationTemplate } from "store/services/clientService";

const RecommendationTempPop = ({ dispatch, showPopup, closePopup, template, getTemplates }) => {
  const [templateName, setTemplateName] = useState("");
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    if(template) {
      setTemplateName(template.template_name);
    }
  }, [template]);

  const handleClose = () => {
    closePopup();
  };

  const handleSave = async () => {
    try {
      setProcessing(true);
      dispatch(actions.persist_store({ loader: true }));
      let data = {
        templateId: template.id,
        values: {
          template_name: templateName
        }
      }
      await updateRecommendationTemplate(data);
      await getTemplates();
      handleClose();
      setProcessing(false);
    } catch(err) {
      console.log("Error saving recommendation template", err);
      dispatch(actions.persist_store({ loader: false }));
      setProcessing(false);
    }
  }

  const handleDelete = async () => {
    try {
      await CONFIRM_POPUP()
      .then(async (isConfirm) => {
        if(isConfirm) {
          setProcessing(true);
          dispatch(actions.persist_store({ loader: true }));
          await deleteRecommendationTemplate(template.id);
          await getTemplates();
          handleClose();
          setProcessing(false);
          dispatch(actions.persist_store({ loader: false }));
        }
      })
      .catch(err => {
        console.log("Error deleting  recommendation", err);
        throw err;
      })
      
    } catch(err) {
      console.log("Error deleting recommendation template", err);
      setProcessing(false);
    }
  }

  if(!template) {
    return null;
  }
  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Recommendation Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-2">
            <label htmlFor="" className="form-label m-0 theme-clr fw-sbold">
              Template Name
            </label>
            <input
              type="text"
              placeholder="Recommendation"
              className="form-control"
              value={templateName}
              onChange={(e) => {
                setTemplateName(e.target.value)
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleDelete}
            disabled={isProcessing}
          >
            Delete
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleSave}
            disabled={isProcessing}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RecommendationTempPop);