import { setAxiosAccessToken } from "helpers/Http";
import { useShowLoader } from "hooks/useShowLoader";
import SubmittedData from "pages/SideTabs/MyClients/Detail/component/TabContent/ToDoTab/Component/SubmittedData/Index";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useBeforeUnload, useParams } from "react-router-dom";
import { getAccountSettingList_ } from "store/services/accountSettingService";
import { getClientToDoList, viewClientData } from "store/services/clientService";

const TodoReports = ({ dispatch, client, completedTodoItems, accountSettings }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  
  const { clientId } = useParams();

  const showLoader = useShowLoader();

  useEffect(() => {
    async function init() {
      try {
        if(!localStorage.getItem("access_token")) {
          const queryParams = new URLSearchParams(window.location.search);
          const accessToken = queryParams.get("access_token");
          if(!accessToken) {
            setError("Authentication error!");
            return;
          }
          setAxiosAccessToken(accessToken);
          // return;
        }
        showLoader(true);
        
        // let profileResponse = await dispatch(profile());
        // if(profileResponse?.success == true && profileResponse?.body) {
        //   localStorage.setItem("user", JSON.stringify(profileResponse?.body));
        // }
        await Promise.all([
          fetchAccountSettings(),
          getClientTodo(),
          fetchClientDetails(),
        ])
        showLoader(false);
        setLoaded(true);
      } catch(err) {
        console.log("Failed to initialize", err);
        showLoader(false);
        setLoaded(false);
        setError("Failed to Initialize!");
      }
    }

    init();
  }, []);

  useBeforeUnload(
    useCallback(() => {
      localStorage.removeItem("access_token");
    }, [])
  );

  const fetchAccountSettings = async () => {
    try {
      await dispatch(getAccountSettingList_());
    } catch(err) {
      console.log("Failed to fetch account settings", err);
      throw err;
    }
  }

  const fetchClientDetails = async () => {
    try {
      let data = {
        clientId,
      };
      await dispatch(viewClientData(data));
    } catch(err) {
      console.log("Failed to fetch client details", err);
      throw err;
    }
  }

  // get currently editing todo list
  const getClientTodo = async () => {
    try {
      let data = {
        clientId,
      };
      await dispatch(getClientToDoList(data));
    } catch (err) {
      console.log("Error fetching todo list", err);
      throw err;
    }
  };
  return (
    <Container>
      <Row>
      {isLoaded ? (
        <Col lg="12">
          <SubmittedData
            completedTodoItems={completedTodoItems}
            client={client}
            multiReportDownload={
              accountSettings && accountSettings.find(setting => setting.data_key === "multi_report_download")?.data_set
            }
          />
        </Col>
      ):(
        <Col lg="6" className="mx-auto text-center my-5 py-5">
          {error ? (
            <h6>{error}</h6>
          ):(
            <h6>Loading...</h6>
          )}
        </Col>
      )}
    </Row>
    </Container>
  )
}

const mapStateToPros = (state) => {
  return {
    loader: state.persistStore.loader,
    client: state.Client.client.details,
    completedTodoItems: state.Client.client.todoItems.todo_completed,
    accountSettings: state.AccountSetting.accountSettingList,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TodoReports);