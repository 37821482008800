import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import TableData from "./Component/Table/TableData";
import { connect } from "react-redux";
// icn
import { ReactComponent as WPlus } from "../../../../Assets/Images/icn/plus.svg";
import { ReactComponent as DocVector } from "../../../../Assets/Images/icn/docVector.svg";
import { ReactComponent as DocUpload } from "../../../../Assets/Images/icn/UploadDoc.svg";
import UploadDoc from "./Component/Modal/UploadDoc";
//import
import { addDocumentData, getDocumentList } from "store/services/documentsService";
import * as actions from "store/actions";
import { IS_BASE64, bytesToMegabytes} from "helpers/common";
import { UploadMediaFile } from "helpers/UploadFile";
import { SUCCESS_MESSAGE } from "common/constantMessage";

const MyDocs = ({ dispatch }) => {
  const [UploadDocPop, setUploadDocPop] = useState();
  const handleUploadDoc = () => setUploadDocPop(!UploadDocPop);

  const initialValues = {
    file: "",
    url: "",
    size: "",
    size_bytes: "",
  };
  //get data
  async function getData() {
    try {
      let filter = {};
      dispatch(actions.persist_store({ loader:true}));
      await dispatch(getDocumentList(filter));
      dispatch(actions.persist_store({ loader:false}));
    } catch (err) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(err);
    }
  }

  const submitData_ = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      dispatch(actions.persist_store({ loader: true }));
      const fileSizeInBytes = values?.url?.size;
      const fileSizeInMB = bytesToMegabytes(fileSizeInBytes);
      let fileUploadResult = null;
      if(IS_BASE64(values.file)){
        fileUploadResult = await UploadMediaFile(dispatch, values.file, values.extension, values.file_type, 'documents');
      }

      if(fileUploadResult && fileUploadResult.success === true && fileUploadResult.body.url) {
        let fileUrl = fileUploadResult.body.url;
        let postData = {
          name : values?.url.name,
          url: fileUrl,
          size : fileSizeInMB,
          size_bytes : fileSizeInBytes
        }
        let response = await dispatch(addDocumentData(postData));
        if(response.success === true && response.message) {
          setUploadDocPop(false);
          SUCCESS_MESSAGE(response.message)
        }
        await getData();
      }
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  return (
    <>
      <UploadDoc
        UploadDocPop={UploadDocPop}
        setUploadDocPop={setUploadDocPop}
        initialValues={initialValues}
        submitData_={submitData_}
      />
      <section className="DocsForms position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div
                className="commonCardBox d-flex border-0 align-items-start justify-content-between gap-10 p-lg-4 p-3 ps-lg-5 pe-3 DocFormBox"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(241,141,6,1) 6%, rgba(254,250,224,1) 57%, rgba(255,254,247,1) 100%)",
                }}
              >
                <div className="left d-flex align-items-start gap-10 ">
                  <div className="imgWrp me-2">
                    <DocVector />
                  </div>
                  <div className="content">
                    <h6 className="fw-bold m-0 text-uppercase">Documents</h6>
                    <p className="m-0 py-1">
                      Upload and provide your clients with easy links to your
                      all your documents and files. All URLs are publicly
                      available so do not include any client specific personal
                      information or data in documents that you upload. All file
                      types are accepted.
                    </p>
                  </div>
                </div>
                <div className="right">
                  <Button
                    onClick={handleUploadDoc}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    <span className="icn me-2">
                      <DocUpload />
                    </span>
                    Upload new docs
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="commonCardBox">
                <TableData
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const mapStateToPros = (state) => {
  // console.log("state", state);
  return {
    loader: state.Documents.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyDocs);
