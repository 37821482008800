import { decode } from "html-entities";
import { Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

const Disclaimer = ({ question }) => {
  let fields = {};
  try {
    let parsedData = JSON.parse(question.other_column_data);
    if (parsedData) {
      fields = parsedData;
    }
  } catch (error) {
    console.log("Error while parsing disclaimer fields", question.question);
  }

  const CustomRenderer = (props) => {
    return (
      <View>
        <Text {...props}>{props.children}</Text>
      </View>
    )
  }

  return (
    <View>
      {fields?.disclaimer_title && (
        <Text>
          Disclaimer Title: {fields?.disclaimer_title} {`\n`}
        </Text>
      )}
      {fields?.disclaimer_text && (
        <>
          <Text>
            Disclaimer Content: {`\n`}
          </Text>
          <Html
            style={{ fontSize: 9 }}
            renderers={{
              "p": (props) => <CustomRenderer {...props} />,
              "h1": (props) => <CustomRenderer {...props} />,
              "h2": (props) => <CustomRenderer {...props} />,
              "h3": (props) => <CustomRenderer {...props} />,
              "h4": (props) => <CustomRenderer {...props} />,
              "h5": (props) => <CustomRenderer {...props} />,
              "h6": (props) => <CustomRenderer {...props} />,
              "br": (props) => `\n`,
            }}
          >
            {fields?.disclaimer_text}
          </Html>
          {`\n`}
        </>
      )}
      <View style={{ flexDirection: "row", alignItems: "center", marginBottom: "10px" }}>
        <Text style={{ backgroundColor: "#e3e3e3", width: 10, height: 10, marginRight: 5 }}>
        </Text>
        <Text>{decode(fields.checkbox_text)}</Text>
      </View>
      {fields && fields.require_name_date === "yes" && (
        <View style={{ marginBottom: "10px" }}>
          <Text style={{ marginBottom: "10px" }}>
            Please type in your full name and today's date to electronically confirm your agreement
          </Text>
          <Text
            style={{
              border: "1px solid #ccc",
              borderRadius: 5,
              width: "50%",
              height: "30px",
              padding: "5px",
              paddingTop: "8px",
              marginBottom: "5px",
              color: "#333"
            }}>
            Your Full Name
          </Text>
          <Text
            style={{
              border: "1px solid #ccc",
              borderRadius: 5,
              width: "50%",
              height: "30px",
              padding: "5px",
              paddingTop: "8px",
              marginBottom: "5px",
              color: "#333"
            }}>
            Today's Date
          </Text>
        </View>
      )}
    </View>
  );
};

export default Disclaimer;
