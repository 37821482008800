import { GET_TAGS, ADD_TAGS, VIEW_TAGS, UPDATE_TAGS, DELETE_TAGS } from 'store/actions/tags';

const initialState = {
    isAuthenticated: false,
    tags: [],
    tag: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getTags = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        tags:data.body,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addTags = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      // tags:data.body,
    };
    return stateObj;
};

  //update 
const viewTags = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      tag:data.body,
    };
    return stateObj;
};

  //update 
const updateTags = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      // tags:data.body,
    };
    return stateObj;
};

//delete 
const deleteTags = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    tag:[],
  };
  return stateObj;
};

const Tags = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_TAGS:
          return getTags(state, payload);
        case ADD_TAGS:
            return addTags(state, payload);
        case VIEW_TAGS:
          return viewTags(state, payload);
        case UPDATE_TAGS:
          return updateTags(state, payload);
        case DELETE_TAGS:
          return deleteTags(state, payload);
        default:
        return state;
    };
}
export default Tags;