import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, CONTENT_PAGE_LIST,CONTENT_PAGE_ADD, CONTENT_PAGE_UPDATE, CONTENT_PAGE_VIEW, CONTENT_PAGE_DELETE  } from './apiPath'
import { getContentPage, addContentPage, viewContentPage, updateContentPage, deleteContentPage} from 'store/actions/contentPage';

//get
export function getContentPageList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${CONTENT_PAGE_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getContentPage(data, callback));
      return data;
    });
}

//add
export function addContentPageData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CONTENT_PAGE_ADD}`, value)
    .then((data) => {
      dispatch(addContentPage(data, callback));
      return data;
    });
}

//view
export function viewContentPageData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${CONTENT_PAGE_VIEW}${value.slug}`)
    .then((data) => {
      dispatch(viewContentPage(data, callback));
      return data;
    });
}

//edit
export function updateContentPageData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${CONTENT_PAGE_UPDATE}`, value)
    .then((data) => {
      dispatch(updateContentPage(data, callback));
      return data;
    });
}

//delete
export function deleteContentPageData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${CONTENT_PAGE_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteContentPage(data));
      return data;
    });
}

