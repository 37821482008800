import { FMJ_NOTES_MAX_CHAR, GENERIC_FORM_VALIDATION_ERR } from "common/constants";
import useFieldValidation from "hooks/useFieldValidation";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const noteColors = ["#595959", "#a94442", "#3c763d", "#2f6f9f", "#893563"];

export const singleNoteField = {
  note: "",
  color: noteColors[0],
};


const validationSchema = Yup.object().shape({
  note: Yup.string()
    .optional()
    .max(FMJ_NOTES_MAX_CHAR, `Entry should be within ${FMJ_NOTES_MAX_CHAR} characters`),
  color: Yup.string().optional(),
});

const AddNotesPopup = ({ showPopup, closePopup, notesData, handleSave }) => {
  const [noteFields, setNoteFields] = useState([{ ...singleNoteField }]);
  const [isSaving, setSaving] = useState(false);
  const [colorSwatchIdx, setColorSwatchIdx] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (notesData && notesData.notes?.length) {
      let notes = cloneDeep(notesData.notes);
      setNoteFields(notes);
    }
  }, [notesData]);

  useEffect(() => {
    const validateFields = async () => {
      const newErrors = [];
      let isValid = true;
      for (let i = 0; i < noteFields.length; i++) {
        try {
          await validationSchema.validate(noteFields[i], { abortEarly: false });
          newErrors[i] = { note: '', color: '' };
        } catch (err) {
          isValid = false;
          const errorObject = {};
          err.inner.forEach((error) => {
            errorObject[error.path] = error.message;
          });
          newErrors[i] = errorObject;
        }
      }
      setIsValid(isValid);
      setErrors(newErrors);
    };

    validateFields();
  }, [noteFields]);

  const hidePopup = () => {
    setNoteFields([{ ...singleNoteField }]);
    setColorSwatchIdx(null);
    closePopup();
  };

  const addNoteField = () => {
    let newField = {
      ...singleNoteField,
    };
    let newNoteFields = [...noteFields];
    newNoteFields.push(newField);
    setNoteFields(newNoteFields);
  };

  const removeNoteField = (index) => {
    let newFields = [...noteFields];
    newFields.splice(index, 1);
    setNoteFields(newFields);
  };

  const handleNoteValueChange = (value, index) => {
    let newFields = [...noteFields];
    if (newFields[index]) {
      newFields[index].note = value;
    }
    setNoteFields(newFields);
  };

  const handleNoteColorChange = (color, index) => {
    let newFields = [...noteFields];
    if (newFields[index]) {
      newFields[index].color = color;
    }
    setNoteFields(newFields);
  };

  const handleSaveNotes = async () => {
    try {
      const fieldErrors = errors.filter(error => 
        Object.values(error).some(value => value !== '')
      );
      if(fieldErrors?.length) {
        return;
      }

      setSaving(true);
      /**
       * Filter empty rows
       */
      let filteredData = noteFields.filter((noteField) => !!noteField.note);
      if(!filteredData?.length) {
        filteredData = null;
      }
      await handleSave(filteredData);
      hidePopup();
      setSaving(false);
    } catch (err) {
      console.log("Error saving notes", err);
      setSaving(false);
    }
  };

  const toggleColorSwatch = (index) => {
    if (colorSwatchIdx == index) {
      setColorSwatchIdx(null);
    } else {
      setColorSwatchIdx(index);
    }
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={hidePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="journalPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Food & Mood Journal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="fm-note-fields py-2">
            {noteFields &&
              noteFields.length > 0 &&
              noteFields.map((field, index) => {
                const noteError = errors[index]?.note;
                return (
                  <div className="py-2" key={index}>
                    <div className="input-group" style={{ height: "125px" }}>
                      <div className="input-group-prepend">
                        <div className="input-group-text h-100">
                          <Button
                            variant="transparent"
                            className="color-btn h-100"
                            style={{
                              backgroundColor: `${field.color ? field.color : noteColors[0]}`,
                            }}
                            onClick={() => {
                              toggleColorSwatch(index);
                            }}
                          ></Button>
                          {colorSwatchIdx == index && (
                            <div className="color-swatch">
                              <ul>
                                {noteColors &&
                                  noteColors.length > 0 &&
                                  noteColors.map((color) => (
                                    <li>
                                      <Button
                                        variant="transparent"
                                        className="p-2"
                                        style={{ backgroundColor: `${color}` }}
                                        onClick={() => {
                                          handleNoteColorChange(color, index);
                                        }}
                                      ></Button>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <textarea
                        className={`form-control ${noteError ? "is-invalid" : ""}`}
                        aria-label="With textarea"
                        rows={4}
                        value={field.note || ""}
                        onChange={(e) => {
                          handleNoteValueChange(e.target.value, index);
                        }}
                      ></textarea>
                      <div className="input-group-append">
                        <div className="input-group-text h-100">
                          <Button
                            variant="transparent"
                            className="remove-btn h-100"
                            onClick={() => {
                              removeNoteField(index);
                            }}
                          >
                            <i className="fa fa-remove"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="invalid-feedback d-block w-auto">{noteError || ""}</p>
                      <p>
                        <span className="me-1">Characters used: {field?.note?.length} / {FMJ_NOTES_MAX_CHAR}</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              id="fmj-limit-tooltip"
                            >
                              If you need to add more content than the specified limit, please add another note.
                            </Tooltip>
                          }
                        >
                          <a href="#"><i className="fa fa-info-circle"></i></a>
                        </OverlayTrigger>
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="py-2 d-flex justify-content-center">
            <Link>
              <Button variant="trasparent" onClick={addNoteField}>
                <i className="fa fa-plus"></i> Add Note
              </Button>
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={hidePopup}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveNotes}
            className="d-flex align-items-center justify-content-center commonBtn"
            disabled={isSaving || !isValid}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNotesPopup;
