import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as OCopy } from "../../../../../../Assets/Images/icn/Copy.svg";
//imported
import { getSelfRegisteration_, addSelfRegisteration_ } from "store/services/accountSettingService";
import * as Path from "Routes/paths";
import * as actions from "store/actions";

const ClientSelfRegisteration = ({
  dispatch,
  submitting,
  defaultFormList,
  selfCheckbox1,
  setSelfCheckbox1,
  selfCheckbox2,
  setSelfCheckbox2,
  selfRegisterationLink,
  showMessage,
  setShowMessage,
}) => {
  const [listData, setListData] = useState([]);
  const [currentType, setCurrentType] = useState(null);
  const [linkOne, setLinkOne] = useState(null);
  const [linkSecond, setLinkSecond] = useState(null);
  const [showMessage1, setShowMessage1] = useState(false);
  //copy link
  const [copied, setCopied] = React.useState(false);
  const link = `${process.env.REACT_APP_CLIENT_PORTAL_URL}/signup?signup=1&ssk=${
    listData && listData[0]?.uuid
  }`;
  const [copied_, setCopied_] = React.useState(false);
  const link_ = `${process.env.REACT_APP_CLIENT_PORTAL_URL}/signup?signup=1&ssk=${
    listData && listData[1]?.uuid
  }`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  const copyToClipboard_ = async () => {
    try {
      await navigator.clipboard.writeText(link_);
      setCopied_(true);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  // checkbox
  const handleCheckboxChange_ = (event, checkboxId, type) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelfCheckbox1((prevSelected) => [...prevSelected, checkboxId]);
      setCurrentType(type);
    } else {
      setSelfCheckbox1((prevSelected) => prevSelected.filter((id) => id !== checkboxId));
      setCurrentType(type);
    }
  };
  const handleCheckboxChange2_ = (event, checkboxId, type) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelfCheckbox2((prevSelected) => [...prevSelected, checkboxId]);
      setCurrentType(type);
    } else {
      setSelfCheckbox2((prevSelected) => prevSelected.filter((id) => id !== checkboxId));
      setCurrentType(type);
    }
  };
  //self registeration list
  async function getSelfRegisterationList() {
    try {
      let res = await dispatch(getSelfRegisteration_());
      setListData(res?.body);
      setLinkOne(res?.body[0]);
      setLinkSecond(res?.body[1]);

      if (res?.body?.length > 0) {
        let defaultFormsValue = [];
        let defaultFormsValue1 = [];
        for (let val of res?.body) {
          if (res?.body[0]?.uuid === val.uuid && res?.body[0]?.forms?.length > 0) {
            for (let j of res?.body[0]?.forms) {
              if (j.selected === true && !defaultFormsValue.includes(j.form_id)) {
                defaultFormsValue.push(j.form_id);
              }
            }
          } else if (res?.body[1]?.uuid === val.uuid && res?.body[1]?.forms?.length > 0) {
            for (let j of res?.body[1]?.forms) {
              if (j.selected === true && !defaultFormsValue1.includes(j.form_id)) {
                defaultFormsValue1.push(j.form_id);
              }
            }
          }
        }
        setSelfCheckbox1(defaultFormsValue);
        setSelfCheckbox2(defaultFormsValue1);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getSelfRegisterationList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
      setCopied_(false);
    }, 2000);
  }, [copied, copied_]);

  const selfRegisrationForm_ = async () => {
    try {
      let data = currentType === "one" ? selfCheckbox1 : selfCheckbox2;
      let final_obj = {
        attached_forms: data,
      };
      let fieldsId = currentType === "one" ? linkOne?.id : linkSecond?.id;
      if (fieldsId) {
        let res = await dispatch(addSelfRegisteration_(fieldsId, final_obj));
        if (res.success === true) {
          if (currentType === "one") {
            setShowMessage(true);
          } else {
            setShowMessage1(true);
          }
          setCurrentType(null);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (currentType) {
      selfRegisrationForm_();
    }
    setTimeout(() => {
      setShowMessage(false);
      setShowMessage1(false);
    }, 2000);
  }, [selfCheckbox1, selfCheckbox2]);

  return (
    <>
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">Client Self-Registration</h3>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">
              One link for your clients to sign-up and complete questionnaires.
            </h6>
            <p className="m-0 py-1">
              When clients click on your Client Self-Registration Link they are able to
              self-register to the Nutri-Q site and will be asked to fill out the following checked
              form(s):
            </p>
          </div>
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Self-Registration Link #1</h6>
            <div className="commonCardBox p-3 my-2">
              <div className="top mb-2">
                <h3 className="m-0 fw-bold">Default Forms</h3>
                <p className="m-0 py-1">
                  The following checked forms are shown when you select a new To-Do item for an
                  existing or new client. If you no longer wish to see a particular form listed when
                  choosing a new To-Do item then you can uncheck the box next to the form name.
                </p>
              </div>
              <div className="CardBody">
                <Row>
                  {listData.length > 0 && listData[0]?.forms?.length > 0 &&
                    listData[0]?.forms?.map((form, i) => {
                      return (
                        <Col lg="6" className="my-2" key={i}>
                          <ul className="list-unstyled p-0 mb-0">
                            <li className="py-1">
                              <div className="py-2 d-flex align-items-start">
                                <div className="cstmInputCheckWrp position-relative me-2">
                                  <input
                                    type="checkbox"
                                    name="c-ToDo"
                                    className="position-absolute w-100 h-100"
                                    checked={selfCheckbox1?.includes(form.form_id)}
                                    onChange={(e) => handleCheckboxChange_(e, form.form_id, "one")}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                    <span className="icn d-none">
                                      <OTick />
                                    </span>
                                  </div>
                                </div>
                                <p className=" m-0 ">{form.form_name}</p>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      );
                    })}
                </Row>
                {showMessage && <p className="save_content">Saved!...</p>}
                {listData.length > 0 && (
                  <div className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap bg-light">
                    <p className="m-0">{link}</p>
                    <Button
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      onClick={copyToClipboard}
                    >
                      <span className="icn me-2">
                        <OCopy />
                      </span>
                      {copied ? "Copied!" : "Copy Link"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Self-Registration Link #2</h6>
            <div className="commonCardBox p-3 my-2">
              <div className="top mb-2">
                <h3 className="m-0 fw-bold">Default Forms</h3>
                <p className="m-0 py-1">
                  The following checked forms are shown when you select a new To-Do item for an
                  existing or new client. If you no longer wish to see a particular form listed when
                  choosing a new To-Do item then you can uncheck the box next to the form name.
                </p>
              </div>
              <div className="CardBody">
                <Row>
                  {listData.length > 0 && listData[1]?.forms?.length > 0 &&
                    listData[1]?.forms?.map((form_, j) => {
                      return (
                        <Col lg="6" className="my-2" key={j}>
                          <ul className="list-unstyled p-0 mb-0">
                            <li className="py-1">
                              <div className="py-2 d-flex align-items-start">
                                <div className="cstmInputCheckWrp position-relative me-2">
                                  <input
                                    type="checkbox"
                                    name="c-ToDo"
                                    className="position-absolute w-100 h-100"
                                    checked={selfCheckbox2?.includes(form_.form_id)}
                                    onChange={(e) => handleCheckboxChange2_(e, form_.form_id, "two")}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                    <span className="icn d-none">
                                      <OTick />
                                    </span>
                                  </div>
                                </div>
                                <p className=" m-0 ">{form_.form_name}</p>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      );
                    })}
                </Row>
                {showMessage1 && <p className="save_content">Saved!...</p>}
                {listData.length > 0 && (
                  <div className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap bg-light">
                    <p className="m-0">{link_}</p>
                    <Button
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      onClick={copyToClipboard_}
                    >
                      <span className="icn me-2">
                        <OCopy />
                      </span>
                      {copied_ ? "Copied!" : "Copy Link"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    selfRegisterationLink: state.AccountSetting.self_registeration_list,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ClientSelfRegisteration);
