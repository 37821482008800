import React, {useEffect} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";

const AddBlockPop = ({ BlockAdd, setBlockAdd,  handleChangeBlock_, blockfields, submitBlockTimeData, accountList, validationErrors, buttonDisable_}) => {
  const handleBlockAdd = () => setBlockAdd(!BlockAdd);
  return (
    <>
      <Form>
      {accountList.length>0 && accountList.map((blockTime, i) => {
        if(blockTime.data_key === "scheduler_blocked_dates"){
          return(
            <Modal
              show={BlockAdd}
              onHide={handleBlockAdd}
              backdrop="static"
              keyboard={false}
              centered
              className="BlockAdd FormPop"
            >
              <Modal.Header closeButton>
                <Modal.Title className="fw-sbold">Add Block</Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-lg-4">
                <div className="commonContent">
                    <Row className="align-items-center">
                      <Col lg="12" className="my-2">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              className="position-absolute w-100 h-100"
                              name="block"
                              onChange={handleChangeBlock_("full_day_block")}
                              checked={blockfields.full_day_block}
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">Full day block ? </p>
                        </div>
                      </Col>
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                          From
                        </label>
                        <input type="date" name="from_date" className="form-control" onChange={handleChangeBlock_("from_date")}/>
                        {validationErrors.from_date && (
                          <div className="text-danger">{validationErrors.from_date}</div>
                        )}
                      </Col>
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                          To
                        </label>
                        <input type="date"  name="to_date" className="form-control" onChange={handleChangeBlock_("to_date")}/>
                        {validationErrors.to_date && (
                          <div className="text-danger">{validationErrors.to_date}</div>
                        )}
                      </Col>
                      {!blockfields.full_day_block &&
                      <>
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                          {/* From */}
                        </label>
                        <input type="time" name="from_time" className="form-control" onChange={handleChangeBlock_("from_time")}/>
                      </Col>
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                          {/* To */}
                        </label>
                        <input type="time"  name="to_time" className="form-control" onChange={handleChangeBlock_("to_time")}/>
                      </Col>
                      </>
                      }
                    </Row>
                </div>
              </Modal.Body>
              <Modal.Footer className="FormbtnWrpper">
                <Button
                  // onClick={handleBlockAdd}
                  type="submit"
                  onClick={() => submitBlockTimeData(blockTime?.id, "13")}
                  disabled={buttonDisable_ ? true : false}
                  className="d-flex align-items-center justify-content-center commonBtn"
                >
                  {buttonDisable_ ? 'Wait...' : 'Add Block'}
                </Button>
                <Button
                  onClick={handleBlockAdd}
                  className="d-flex align-items-center justify-content-center commonBtn"
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          )
        }
      })}
      </Form>
    </>
  );
};

export default AddBlockPop;
