//action types
export const GET_FAQ = 'GET_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
export const VIEW_FAQ = 'VIEW_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';

//get 
export function getFAQ(data, callback) {
  return {
    type: GET_FAQ,
    payload : {data, callback},
  };
}
//add 
export function addFAQ(data, callback) {
  return {
    type: ADD_FAQ,
    payload : {data, callback},
  };
}

//view 
export function viewFAQ(data, callback) {
  return {
    type: VIEW_FAQ,
    payload : {data, callback},
  };
}
//update 
export function updateFAQ(data, callback) {
    return {
      type: UPDATE_FAQ,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteFAQ(payload) {
  return {
    type: DELETE_FAQ,
    payload
  };
}
