import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";

// icn
import { ReactComponent as WPlus } from "../../../../Assets/Images/icn/plus.svg";
import { ReactComponent as StarIcn } from "../../../../Assets/Images/icn/circleStar.svg";
import { ReactComponent as AddIcn } from "../../../../Assets/Images/icn/AddCircle.svg";
import { ReactComponent as DelIcn } from "Assets/Images/icn/delete.svg";
import ToDoTab from "./component/TabContent/ToDoTab/Index";
import ClientPrioritiesPop from "./component/Modals/ClientPrioritiesPop/ClientPrioritiesPop";
import DataAnalysis from "./component/TabContent/DataAnalysis/Index";
import RecommendationTab from "./component/TabContent/Recommendation/Index";
import AddDataTab from "./component/TabContent/AddData/Index";
import InfoSettingTab from "./component/TabContent/InfoSetting/Index";
import FoodMoodLogs from "./component/TabContent/FoodMoodLogs/Index";
import {
  viewClientData,
  getClientSettings,
  assignTagsToClient_,
  updateClientSettings,
  addUpdateFavorite_,
  removeTagsFromClient,
} from "store/services/clientService";
import * as actions from "store/actions";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import AddTagPop from "../Component/Modal/AddTagPop";
import AddTagPopAssign from "../Component/Modal/AddTagPopAssign";
import { getTagsList } from "store/services/tagsService";
import ClientPriorities from "./component/ClientPriorities";
import { FULL_NAME, UC_FIRST } from "helpers/common";
import StarRatings from "react-star-ratings";
import { clearClientPageData, setActiveTab } from "store/actions/client";
import AddTagPopupContainer from "./component/AddTagPopupContainer";
import { HEALTH_COATCH_CAT_ID } from "common/constants";
import { currentPageTitle } from "store/actions/misc";

const ClientDetail = ({
  dispatch,
  client,
  loadingClientDetails,
  tagList,
  clientPriorities,
  activeTab,
  clientSettings,
  pageTitle
}) => {
  const defaultTab = "ToDo";
  const [showClientPrioritiesPopup, setShowClientPrioritiesPopup] = useState();
  const { clientId } = useParams();
  const [showAssignTagPopup, setShowAssignTagPopup] = useState(false);
  const [showAddTagPopup, setShowAddTagPopup] = useState(false);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let activeTab = params.get("tab");
    if (activeTab) {
      updateActiveTab(activeTab);
    }

    return () => {
      updateActiveTab(defaultTab);
    }
  }, []);

  /**
   * Update current page title
   */
  useEffect(() => {
    dispatch(currentPageTitle(pageTitle));

    return () => {
      dispatch(currentPageTitle(""));
    }
  }, []);

  useEffect(() => {
    getTagsList_();
    getClientDetails();
    getAllClientSettings();

    return () => {
      dispatch(clearClientPageData());
    }
  }, []);

  const updateActiveTab = (tabName) => {
    dispatch(setActiveTab(tabName));
  }

  const togglePrioritesPopup = () => {
    setShowClientPrioritiesPopup(!showClientPrioritiesPopup);
  };

  const getClientDetails = async () => {
    try {
      let data = {
        clientId,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewClientData(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  const getAllClientSettings = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getClientSettings(clientId));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  async function getTagsList_() {
    try {
      await dispatch(getTagsList());
    } catch (err) {
      console.log(err);
    }
  }

  const assignTagsClient = async (tags) => {
    try {
      let arr = []
      arr.push(tags.id);
      let obj = {
        client_id: client.id,
        assign_tags: arr
      }
      obj = JSON.stringify(obj)
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(assignTagsToClient_(obj));
      await getClientDetails();
      setShowAssignTagPopup(false)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  const removeTagsClient = async (tag) => {
    try {
      await CONFIRM_POPUP()
        .then(async (isConfirm) => {
          if (isConfirm) {
            let arr = []
            arr.push(tag.id);
            let obj = {
              client_id: client.id,
              remove_tags: arr
            }
            obj = JSON.stringify(obj)
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(removeTagsFromClient(obj));
            if (res.success === true) {
              SUCCESS_MESSAGE("Tag removed")
              await getClientDetails();
            }
            dispatch(actions.persist_store({ loader: false }));
          }
        })
        .catch(err => {
          console.log("Error removing tag", err);
          throw err;
        })
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  async function updatePrioritiesForClient(values, { setSubmitting }) {
    try {
      setSubmitting(true);
      let data = {
        clientId: client.id,
        values,
      };
      await dispatch(updateClientSettings(data));
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      throw error;
    }
  };

  const toggleMarkClientFavorite = async () => {
    try {
      let obj = {
        mark_favorite: client.is_favorite === "no" ? "yes" : client.is_favorite === "yes" ? "no" : "yes"
      }
      obj = JSON.stringify(obj)
      let clientId = client?.id;
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(addUpdateFavorite_(clientId, obj));
      await getClientDetails();
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error toggling client favorite status", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  return (
    <>
      <section
        className="ClientDetail position-relative"
        style={{ overflow: "hidden" }}
      >
        <AddTagPopupContainer
          showPopup={showAddTagPopup}
          setShowPopup={setShowAddTagPopup}
          closePopup={() => {
            setShowAddTagPopup(true);
          }}
        />
        <AddTagPopAssign
          tagPopAssign={showAssignTagPopup}
          setTagPopAssign={setShowAssignTagPopup}
          assignTagsClient={assignTagsClient}
          setCurrentClient={() => { }}
          currentTags={(function () {
            let assignedTags = JSON.parse(client?.tags_assigned || "[]");
            return tagList?.filter(tag => !assignedTags.includes(tag.id));
          })()}
          setTagPop={setShowAddTagPopup}
        />
        <ClientPrioritiesPop
          showClientPrioritiesPopup={showClientPrioritiesPopup}
          togglePrioritesPopup={togglePrioritesPopup}
          clientPriorities={clientPriorities}
          updatePrioritiesForClient={updatePrioritiesForClient}
        />
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <Row>
                <Col lg="3" className="my-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    <li className="d-flex align-items-start gap-10 profile py-2">
                      <div className="imgWrp">
                        <StarRatings
                          rating={client.is_favorite === 'yes' ? 1 : 0}
                          starRatedColor="#e79d46"
                          changeRating={() => toggleMarkClientFavorite()}
                          starDimension="20px"
                          starSpacing="15px"
                          numberOfStars={1}
                          name="is_favorite"
                        />
                      </div>
                      <div className="content">
                        <h6 className="m-0 fw-bold">
                          {FULL_NAME(client.first_name, client.last_name)}
                          {client.status && (
                            <span className="GreenLabel CstmLabel fw-normal text-white ms-2">
                              {`${UC_FIRST(client.status)} Client`}
                            </span>
                          )}
                        </h6>
                        {client.email && (
                          <p className="m-0 text-muted py-1">
                            {`(${client.email})`}
                          </p>
                        )}
                      </div>
                    </li>
                    <li className="py-2 mt-3">
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10 flex-wrap">
                        {tagList.length > 0 && tagList.map((tag, i) => {
                          let assignedTags = JSON.parse(client?.tags_assigned || "[]");
                          if (assignedTags.length > 0 && assignedTags.includes(tag?.id)) {
                            return (
                              <li className="single-tag" key={i}>
                                <span
                                  className="CstmLabel"
                                  style={{
                                    borderColor: tag.color_code,
                                    color: "#fff",
                                    background: tag.color_code,
                                  }}
                                >
                                  {tag.name}
                                  <Link className="btn p-1" onClick={() => removeTagsClient(tag)}>
                                    <DelIcn className="delete-icon" />
                                  </Link>
                                </span>
                              </li>
                            );
                          }
                        })}
                      </ul>
                      <Button
                        className="border-0 py-2 d-flex align-items-center fw-sbold gap-10"
                        variant="transparent"
                        onClick={setShowAssignTagPopup}
                      >
                        <span className="icn">
                          <AddIcn />
                        </span>{" "}
                        Add Tag
                      </Button>
                    </li>
                  </ul>
                </Col>
                <Col lg="9" className="my-2">
                  <ClientPriorities
                    clientPriorities={clientPriorities}
                    togglePrioritesPopup={togglePrioritesPopup}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="my-2">
              <div className="commonCardBox">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="ToDo"
                  activeKey={activeTab}
                  onSelect={(tabName) => {
                    updateActiveTab(tabName);
                  }}
                >
                  <div className="top border-bottom px-lg-4 py-3">
                    <Nav variant="pills" className="commonBtnTabs">
                      {clientSettings && clientSettings["client_anytime_logging"] === "yes" && (
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="fw-sbold text-uppercase py-2"
                            eventKey="FMLogs"
                          >
                            Food & Feeling Log
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="fw-sbold text-uppercase py-2"
                          eventKey="ToDo"
                        >
                          Data & To-Do Items
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="fw-sbold text-uppercase py-2"
                          eventKey="DataAnalysis"
                        >
                          Data Analysis
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="fw-sbold text-uppercase py-2"
                          eventKey="Recommendations"
                        >
                          {client?.client_category === HEALTH_COATCH_CAT_ID ? "Action Plans" : "Recommendations"}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="fw-sbold text-uppercase py-2"
                          eventKey="AddData"
                        >
                          Add Data
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="fw-sbold text-uppercase py-2"
                          eventKey="InfoSettings"
                        >
                          Info & Settings
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="CardBody p-3 px-lg-4">
                    <Tab.Content>
                      {clientSettings && clientSettings["client_anytime_logging"] === "yes" && (
                        <Tab.Pane eventKey="FMLogs" mountOnEnter={true}>
                          <FoodMoodLogs
                            loadingClientDetails={loadingClientDetails}
                            clientDetails={client}
                          />
                        </Tab.Pane>
                      )}
                      <Tab.Pane eventKey="ToDo" mountOnEnter={true}>
                        <ToDoTab
                          loadingClientDetails={loadingClientDetails}
                          clientDetails={client}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="DataAnalysis" mountOnEnter={true}>
                        <DataAnalysis />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Recommendations" mountOnEnter={true} unmountOnExit={true}>
                        <RecommendationTab />
                      </Tab.Pane>
                      <Tab.Pane eventKey="AddData" mountOnEnter={true} unmountOnExit={true}>
                        <AddDataTab />
                      </Tab.Pane>
                      <Tab.Pane eventKey="InfoSettings" mountOnEnter={true}>
                        <InfoSettingTab />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  let clientPriorities = getClientPrioritiesData(state.Client.client.settings);
  return {
    client: state.Client.client.details,
    loadingClientDetails: state.Client.loadingClientDetails,
    tagList: state.Tags.tags,
    clientPriorities,
    activeTab: state.Client.activeTab,
    clientSettings: state.Client?.client?.settings,
  };
};

function getClientPrioritiesData(settings) {
  let data = {
    currentClientConcerns: [],
    currentPriorites: [],
    shortTermGoals: [],
    medications: [],
    foodAllergies: [],
  }

  for (let i = 1; i <= 5; i++) {
    if (typeof settings[`client_current_concerns_${i}`] !== "undefined" && settings[`client_current_concerns_${i}`]) {
      data.currentClientConcerns.push(settings[`client_current_concerns_${i}`]);
    }
    if (typeof settings[`client_current_priorities_${i}`] !== "undefined" && settings[`client_current_priorities_${i}`]) {
      data.currentPriorites.push(settings[`client_current_priorities_${i}`]);
    }
    if (typeof settings[`client_short_term_goals_${i}`] !== "undefined" && settings[`client_short_term_goals_${i}`]) {
      data.shortTermGoals.push(settings[`client_short_term_goals_${i}`]);
    }
    if (typeof settings[`client_medications_${i}`] !== "undefined" && settings[`client_medications_${i}`]) {
      data.medications.push(settings[`client_medications_${i}`]);
    }
    if (typeof settings[`client_food_allergies_${i}`] !== "undefined" && settings[`client_food_allergies_${i}`]) {
      data.foodAllergies.push(settings[`client_food_allergies_${i}`]);
    }
  }

  return data;
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ClientDetail);
