import * as Yup from 'yup';
import { PASSWORD_VALIDATION_MSG } from 'common/strMessage';
import { PASSWORD_VALIDATE_REGEX } from 'common/constants';

const validation = Yup.object({
    first_name: Yup.string()
        .trim()
        .required("Required")
        .max("50", "First Name must be at most 50 characters"),
    email: Yup.string()
        .trim()
        .required("Email Required")
        .email("Invalid email address"),
    password: Yup.string()
        .required("Password Required")
        .min(8, PASSWORD_VALIDATION_MSG)
        .matches(PASSWORD_VALIDATE_REGEX, PASSWORD_VALIDATION_MSG),
    confirm_password: Yup.string()
        .trim()
        .required("Please confirm your password")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default validation;
