import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import FormListing from "./Component/FormListing/Index";

// icn
import { ReactComponent as WPlus } from "../../../../Assets/Images/icn/plus.svg";
import { ReactComponent as FormVector } from "../../../../Assets/Images/icn/FormVector.svg";
import { ReactComponent as PencilIcn } from "../../../../Assets/Images/icn/pencil.svg";
import CreateFormPop from "./Component/Modal/CreateFormPop";
import ReactPagination from "components/shared/ReactPagination";
import { connect } from "react-redux";
import { duplicateForm, getFormListing, getMyFormList } from "store/services/myFormService";
import * as actions from 'store/actions';
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";

let columns = [
  {
    key: "form_name",
    sortable: true,
    name: "Form Name",
  },
  {
    key: "form_type",
    sortable: true,
    name: "Form Type",
  },
  {
    key: "created_at",
    sortable: true,
    name: "Last Updated",
  },
  {
    key: "action",
    sortable: false,
    name: "Action",
  },
];

const MyForm = ({ dispatch, defaultForms }) => {
  const [forms, setForms] = useState([]);
  const [showCreateFormPopup, setShowCreateFormPopup] = useState();

  const [activePage, setActivePage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0)

  useEffect(() => {
    fetchFormList();
  }, [activePage, sortBy, sortOrder]);

  const handleCreateFormPop = () => setShowCreateFormPopup(!showCreateFormPopup);

  const hanldeDuplicateForm = async (formId, callback) => {
    try {
      let data = {
        formId
      }
      await CONFIRM_POPUP("Are you sure you want to duplicate this form?")
        .then(async (isConfirmed) => {
          if(isConfirmed) {
            dispatch(actions.persist_store({ loader:true}));
            let res = await duplicateForm(data);
            if(res.success === true) {
              if(typeof callback == "function") {
                callback(res);
              }
              await fetchFormList();
              SUCCESS_MESSAGE("Form duplicated successfully");
            }
            dispatch(actions.persist_store({ loader:false}));
          }
        }).catch(err => {
          throw err
        })
    } catch(error) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(error);
    }
  }

  const fetchFormList = async () => {
    try {
      let filter = {
        page: activePage,
        orderBy: sortBy,
        order: sortOrder
      };
      dispatch(actions.persist_store({ loader:true}));
      let res = await getFormListing(filter);
      if(res.success == true && res?.body?.data?.length > 0) {
        setForms(res.body.data);
        setItemsCountPerPage(res.body.per_page);
        setTotalItemsCount(res.body.total_count);
      }
      dispatch(actions.persist_store({ loader:false}));
    } catch(error) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(error);
    }
  }

  const handlePageChange = (page) => {
    setActivePage(page);
  }

  const SortableColumns = () => {
    return columns.map((column, index) => {
      let sortClassName = "fa-sort";
      let sortClass = "";
      if(sortBy == column.key) {
        sortClass = (sortOrder == "asc") ? "fa-sort-up" : "fa-sort-down";
      }
      return (
        <th key={index} className="p-3 fw-normal text-dark fw-sbold text-muted">
          {column.sortable ?
            <Link
              onClick={() => {
                let order = sortOrder === "asc" ? "desc" : "asc";
                setSortBy(column.key);
                setSortOrder(order);
              }}
            >
              {column.name}
              <i class={`fa ${sortClassName} ${sortClass} px-2`}></i>
            </Link>
            :
            <>
              {column.name}
            </>
          }
          {column.desc && <sub>({column.desc})</sub> }
        </th>
      )
    })
  }

  return (
    <>
      <CreateFormPop
        CreateFormPopup={showCreateFormPopup}
        setCreateFormPopup={setShowCreateFormPopup}
        defaultForms={forms}
        handleDuplicate={hanldeDuplicateForm}
      />
      <section className="DocsForms position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div
                className="commonCardBox d-flex border-0 justify-content-between align-items-start gap-10 p-lg-4 p-3 ps-lg-5 pe-3 DocFormBox"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(241,141,6,1) 6%, rgba(254,250,224,1) 57%, rgba(255,254,247,1) 100%)",
                }}
              >
                <div className="left d-flex align-items-start gap-10 ">
                  <div className="imgWrp me-2">
                    <FormVector />
                  </div>
                  <div className="content">
                    <h6 className="fw-bold m-0 text-uppercase">My Forms</h6>
                    <p className="m-0 py-1">
                      Create and manage your own custom forms/questionnaires
                      that you can send out to your clients.
                    </p>
                  </div>
                </div>
                <div className="right">
                  <Button
                    onClick={handleCreateFormPop}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    <span className="icn me-2">
                      <PencilIcn />
                    </span>
                    Create a new form
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="commonCardBox">
                <FormListing 
                  handleDuplicate={hanldeDuplicateForm}
                  forms={forms}
                  Columns={SortableColumns}
                  fetchFormList={fetchFormList}
                />
                {forms && forms.length > 0 &&
                  <ReactPagination 
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                  />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};


const mapStateToPros = (state) => {
  const userId = state.Auth.user.id;
  // const defaultForms = state.MyForm.myForms.filter(form => form.created_by !== userId)
  return {
    // defaultForms,
    // forms: state.MyForm.myForms,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyForm);