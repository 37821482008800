import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useInstancePermissions = () => {
  const user = useSelector((state) => state.Profile.user);

  // Function to check if the user is allowed to see a section
  const isAllowedToViewSection = (sectionName) => {
    try {
      if(!user) {
        return;
      }
      const permissions = user.permissions;
      const permission = permissions.find(p => p.constant === sectionName);
      return permission ? ( permission.is_allowed === "no" ? false : true ) : true;
    } catch(err) {
      console.warn("Error checking user permission: ", err);
      return true;
    }
  };

  return isAllowedToViewSection;
};

export default useInstancePermissions;