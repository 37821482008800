// import { renderToString } from 'react-dom/server';
import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import {Table, TR, TH, TD} from '@ag-media/react-pdf-table';
import { FULL_NAME, showDateInUtc } from 'helpers/common';
import moment from 'moment';
import { saveAs } from "helpers/common";
import { Link } from 'react-router-dom';
import { viewFoodMoodSubmittedData } from 'store/services/clientService';
import { transformFMJData } from "helpers/dataFormat";
import { TIME_FORMAT_12, TIME_FORMAT_24 } from "common/constants";

export const fmFields = {
  foods: "Foods",
  drinks: "Drinks",
  supplements: "Supps, Herbs & Meds",
  feeling: "Energy & Mood",
  movement: "Movement & Relaxation",
  digestion: "Digestion & Reactions"
}

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  foodLogTableDivider: {
    borderBottom: "4px solid #08415c",
    margin: "20px 0",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    paddingTop: "5px",
    // flexDirection: "row",
    // flexWrap: "wrap",
    display: "table",
    width: "auto",
    // border: "1px solid #ccc",
  },
  row: {
    // flexDirection: "row",
    // alignItems: "baseline",
    // margin: "auto",
    // border: "1px solid #ccc",
    // borderTop: "1px solid #ccc",
    // borderBottom: "1px solid #ccc",
    // padding: "10px"
    borderBottom: "1px solid #ccc",
  },
  notesRow: {
    backgroundColor: "#fff"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    // width: "15%",
    padding: "5px",
    // minHeight: "33px",
    // height: "100%",
    paddingBottom: "1px",
    border: "none",
    // flexWrap: "wrap",
    // alignSelf: "flex-end",
    // borderBottom: "2px solid #ccc",
    // borderTop: "none",
    // borderBottom: "none",
  },
  cell: {
    color: "#000",
    fontSize: "9px",
    // width: "auto",
    // width: "15%",
    // height: "100%",
    padding: "5px",
    border: "none",
    // flexWrap: "wrap",
    // borderTop: "1px solid #ccc",
    // minHeight: "50px",
    // border: "1px solid #ccc",
    // borderTop: "none",
    // borderBottom: "none",
  },
  notes: {
    color: "#555",
  },
  noteCell: {
    color: "#000",
    fontSize: "9px",
    // width: "15%",
    // height: "100%",
    padding: "5px",
    border: "none",
    // flexWrap: "wrap",
    // borderTop: "1px solid #eee",
  }
});

const FoodMoodJournalPdf = ({ client, date, todoId, label, showNotes, showLoader, hideLoader }) => {

  const downloadPDFReport = async () => {
    try {
      showLoader();
      const journalDetails = await getFoodMoodJournalData(todoId, client);
      const doc = <MyDoc
        client={client}
        date={date}
        journalDetails={journalDetails}
        showNotes={showNotes}
      />;

      // const html = renderToString(doc);
      // console.log("PDF html", html)

      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Food & Mood Journal.pdf');
      hideLoader();
    } catch (err) {
      console.log("Error downloading report", err);
      hideLoader();
    }
  }

  return (
    <Link
      onClick={downloadPDFReport}
    >
      {label}
    </Link>
  )
}

export const getFoodMoodJournalData = async (todoId) => {
  try {
    const response = await viewFoodMoodSubmittedData(todoId);
    if (response.success === true) {
      let data = transformFMJData(response.body);
      return data;
    }

    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const MyDoc = ({ client, date, journalDetails, showNotes }) => {
  return (
    <Document>
      <Page style={styles.page} orientation="landscape" >

        <View style={styles.pageHeader} fixed>
          <Text style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Food & Mood Journal
          </Text>
          <Text style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName} fixed>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        {journalDetails && journalDetails.length > 0 &&
          journalDetails.map((data, i) => {
            return (
                <View style={{ marginTop: "25px" }}>
                  <View style={{ display: "flex", flexDirection: "row", paddingBottom: "5px" }}>
                    <Text style={{ width: "25%", fontWeight: "bold", fontFamily: "Helvetica-Bold", fontSize: "12px" }}>
                      {showDateInUtc(data.log_date)}
                    </Text>
                    <View style={{ width: "25%", display: "flex", flexDirection: "row", gap: "5px" }}>
                      <Text>
                        Wake Time:
                      </Text>
                      <Text style={{ width: "30%", borderBottom: "1px solid #000" }}>
                        {moment(data.wake_time, TIME_FORMAT_24).format(TIME_FORMAT_12)}
                      </Text>
                    </View>
                    <View style={{ width: "25%", display: "flex", flexDirection: "row", gap: "5px" }}>
                      <Text>
                        Sleep Time:
                      </Text>
                      <Text style={{ width: "30%", borderBottom: "1px solid #000" }}>
                        {moment(data.sleep_time, TIME_FORMAT_24).format(TIME_FORMAT_12)}
                      </Text>
                    </View>
                  </View>
                  <FoodMoodTable
                    dataId={data.id}
                    itemKey={`table_${i}`}
                    foodServed={data.food_served}
                    additionalNotes={data.additional_notes}
                    showNotes={showNotes}
                  />
                </View>
            )
          })
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner.
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const FoodMoodTable = ({ dataId, foodServed, additionalNotes, showNotes, itemKey }) => {
  const rows = foodServed?.map((timeRow, idx) => {
    return (
      <>
        <TR style={tableStyles.row} key={`${dataId}_${idx}`}>
          <TD style={tableStyles.cell}>
            <Text style={{ flexDirection: "column" }}>
              <Text>Start: {timeRow.start_time} {`\n`}</Text>
              <Text>End: {timeRow.end_time}</Text>
            </Text>
          </TD>
          {fmFields && Object.keys(fmFields)?.map((fieldKey, fieldIndex) => {
            return (
              <TD key={fieldIndex} style={tableStyles.cell}>
                <Text>{timeRow[fieldKey].value}</Text>
              </TD>
            )
          })}
        </TR>
        {showNotes && (
          <TR
            key={`notes_${idx}`}
            style={[tableStyles.row, tableStyles.notesRow]}
          >
            <TD
              style={tableStyles.cell}
            >
              Notes:
            </TD>
            {fmFields && Object.keys(fmFields)?.map((fieldKey, fieldIndex) => {
              return (
                <TD
                  style={[tableStyles.cell, { flexDirection: "column", alignItems: "start" }]}
                  key={fieldIndex}
                >
                  {timeRow[fieldKey]?.notes && timeRow[fieldKey]?.notes.length > 0 ? (
                    timeRow[fieldKey]?.notes?.map((row, idx) => {
                      return (
                        <Text
                          style={tableStyles.notes}
                        >
                          {row.note}
                        </Text>
                      )
                    })
                  ):(
                    ""
                  )}
                </TD>
              )
            })}
          </TR>
        )}
        {/* {showNotes && (
          <TR key={`${dataId}_${idx}_notes`}>
            <TD>
              Notes:
            </TD>
            {fmFields && Object.keys(fmFields)?.map((fieldKey, fieldIndex) => {
              return (
                <TD key={fieldIndex}>
                  {timeRow[fieldKey]?.notes && timeRow[fieldKey]?.notes.length > 0 ? (
                    timeRow[fieldKey]?.notes?.map((row, idx) => {
                      return (
                        <Text style={tableStyles.notes}>
                          {`\n`}
                          {row.note}
                        </Text>
                      )
                    })
                  ):(
                    ""
                  )}
                </TD>
              )
            })}
          </TR>
        )} */}
      </>
    )
  })

  return (
    <View style={{ border: "1px solid #ccc" }} key={`main_table_${itemKey}_${dataId}`}>
      <Table>
        <TH style={[tableStyles.row, {borderBottom: "2px solid #ccc"}]}>
          <TD style={[tableStyles.headerCell]}>Meal Time</TD>
          <TD style={[tableStyles.headerCell]}>Foods</TD>
          <TD style={[tableStyles.headerCell]}>Drinks</TD>
          <TD style={[tableStyles.headerCell]}>Supps, Herbs & Meds</TD>
          <TD style={[tableStyles.headerCell]}>Energy & Mood</TD>
          <TD style={[tableStyles.headerCell]}>Movement & Relaxation</TD>
          <TD style={[tableStyles.headerCell]}>Digestion & Reactions</TD>
        </TH>
        {rows}

      </Table>
      {showNotes && additionalNotes && additionalNotes.length > 0 && (
        <View style={[{ padding: "5px", backgroundColor: "#eee" }]} key={`main_notes_${itemKey}_${dataId}`}>
          <View style={[{ width: "100%" }]}>
            {additionalNotes?.map((row, i) => {
              return (
                <Text
                  key={i}
                >
                  <Text style={tableStyles.notes}>{row.note}</Text>
                  {`\n`}
                </Text>
              )
            })}
          </View>
        </View>
      )}
    </View>
  )
};

export default FoodMoodJournalPdf;