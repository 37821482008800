import { decode } from "html-entities"
import { ErrorBoundary } from "react-error-boundary";

import Address from "./Fields/Address"
import Height from "./Fields/Height";
import Weight from "./Fields/Weight";
import Disclaimer from "./Fields/Disclaimer";
import Checkbox from "./Fields/Checkbox";
import DateField from "./Fields/DateField";

export const RenderAnswerField = ({ question }) => {
  if(!question.answer) {
    return (
      <p><i>Nothing Entered</i></p>
    )
  }
  return (
    <ErrorBoundary fallback={<div>(Unable to Parse Response)</div>}>
      {question.question_type == "address" ?
        <Address 
          question={question}
        />
        :
        question.question_type == "height" ?
          <Height 
            question={question}
          />
        :
        question.question_type == "weight" ?
          <Weight 
            question={question}
          />
        :
        question.question_type == "disclaimer" ?
          <Disclaimer 
            question={question}
          />
        :
        question.question_type == "checkBoxes" ?
          <Checkbox 
            question={question}
          />
        :
        question.question_type == "date" ?
          <DateField
            question={question}
          />
        :
        <p style={{ whiteSpace: "pre-wrap"}}>
          {decode(question.answer)}
        </p>
      }
    </ErrorBoundary>
  )
}