import React, { useState } from "react";
import { Accordion, Button, Col, Dropdown, Row, Form } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Formik } from "formik";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "common/constantMessage";
import { getClientCategoryList_, getClientSettings, updateClientSettings, viewClientData } from "store/services/clientService";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { PERMISSION_CONSTANTS } from "common/constants";
import useInstancePermissions from "hooks/useInstancePermissions";

const ClientSettings = ({
  dispatch,
  client,
  clientCategories,
  settings,
  toggleSettingsScreen,
  saveSettingsCallback,
  // userProfile
}) => {
  const isAllowedToViewSection = useInstancePermissions();

  let initialValues = {};
  if (client?.is_direct_account === "yes") {
    initialValues = {
      "category": settings.category || "",
    }
  } else {
    initialValues = {
      // "client_show_meal_summary": settings.client_show_meal_summary || "no",
      "client_show_naq_burden_graph": settings.client_show_naq_burden_graph || "no",
      // "client_anytime_logging": settings.client_anytime_logging || "no",
      "category": settings.category || "",
    }
  }
  
  if(client?.status !== "active") {
    initialValues["is_direct_account"] = client?.is_direct_account || "no";
  }

  let oldSettings = cloneDeep(initialValues);

  const handleSaveSettings = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        if (response.success === true) {
          SUCCESS_MESSAGE(response.message);
        }
      };
      let data = {
        clientId: client.id,
        values,
      };
      await dispatch(updateClientSettings(data, callback));
      await dispatch(getClientSettings(client.id));
      await dispatch(viewClientData({ clientId: client.id }));
      saveSettingsCallback(oldSettings, values);
      setSubmitting(false);
      toggleSettingsScreen();
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
      ERROR_MESSAGE(err.message);
    }
  };

  

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        // validationSchema={validation}
        onSubmit={handleSaveSettings}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              {/* <Col lg="12" className="my-2"> */}
              <div className="commonContent">
                <div className="top py-2">
                  <h3 className="m-0 fw-bold m-0">Edit Client Settings</h3>
                  <p className="m-0">Change your client settings </p>
                </div>
                <div className="CardBody py-2">
                  <div className="py-2">
                    {/* <Row> */}
                    {/* <Col lg="8" className="my-2"> */}
                    {values.client_show_meal_summary && (
                      <Row className="py-2">
                        <Col lg="6">
                          <h6 className="m-0 fw-sbold">
                            Show meal summary to client:
                          </h6>
                        </Col>
                        <Col lg="6">
                          <div className="cstmSwitch">
                            <BootstrapSwitchButton
                              className="rounded-pill"
                              checked={values.client_show_meal_summary === "yes"}
                              onlabel="Yes"
                              offlabel="No"
                              width="80"
                              onChange={(isChecked) => {
                                let value = isChecked === true ? "yes" : "no"
                                setFieldValue("client_show_meal_summary", value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {values.client_show_naq_burden_graph && (
                      <Row className="py-2">
                        <Col lg="6">
                          <h6 className="m-0 fw-sbold">
                            Show NAQ Symptom Burden Graph to client:
                          </h6>
                        </Col>
                        <Col lg="6">
                          <div className="cstmSwitch">
                            <BootstrapSwitchButton
                              className="rounded-pill"
                              checked={values.client_show_naq_burden_graph === "yes"}
                              onlabel="Yes"
                              offlabel="No"
                              width="80"
                              onChange={(isChecked) => {
                                let value = isChecked === true ? "yes" : "no"
                                setFieldValue("client_show_naq_burden_graph", value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {values.client_anytime_logging && (
                      <Row className="py-2">
                        <Col lg="6">
                          <h6 className="m-0 fw-sbold">
                            Allow Anytime Logging:
                          </h6>
                        </Col>
                        <Col lg="6">
                          <div className="cstmSwitch">
                            <BootstrapSwitchButton
                              className="rounded-pill"
                              checked={values.client_anytime_logging === "yes"}
                              onlabel="Yes"
                              offlabel="No"
                              width="80"
                              onChange={(isChecked) => {
                                let value = isChecked === true ? "yes" : "no"
                                setFieldValue("client_anytime_logging", value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {values.is_direct_account && (
                      <Row className="py-2">
                        <Col lg="6">
                          <h6 className="m-0 fw-sbold">
                            Client Type:
                          </h6>
                        </Col>
                        <Col lg="6">
                          <Row className={`${errors.is_direct_account && "is-invalid"}`}>
                            <Col lg="6" sm="6" className="my-2">
                              <div className="py-1 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    className="position-absolute w-100 h-100"
                                    type="radio"
                                    name="is_direct_account"
                                    value="no"
                                    onChange={(e) =>
                                      setFieldValue("is_direct_account", "no")
                                    }
                                    checked={values.is_direct_account == "no"}
                                    style={{ top: "2px" }}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <label
                                  htmlFor=""
                                  className="form-label theme-clr m-0"
                                >
                                  Full Account
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" sm="6" className="my-2">
                              <div className="py-1 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    className="position-absolute w-100 h-100"
                                    type="radio"
                                    name="is_direct_account"
                                    value="yes"
                                    onChange={(e) =>
                                      setFieldValue("is_direct_account", "yes")
                                    }
                                    checked={values.is_direct_account == "yes"}
                                    style={{ top: "2px" }}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <label
                                  htmlFor=""
                                  className="form-label theme-clr m-0"
                                >
                                  Direct Client
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {isAllowedToViewSection(PERMISSION_CONSTANTS.SHOW_CLIENT_CATEGORIES) &&
                      typeof values.category !== "undefined" && (
                        <Row className="py-2">
                          <Col lg="6">
                            <h6 className="m-0 fw-sbold">
                              Client Category:
                            </h6>
                          </Col>
                          <Col lg="6">
                            <Row className={`${errors.category && "is-invalid"}`}>
                              {clientCategories.map((category, i) => {
                                return (
                                  <Col lg="6" sm="6" className="my-2" key={i}>
                                    <div className="py-1 d-flex align-items-start gap-10">
                                      <div className="cstmInputCheckWrp radio position-relative ">
                                        <input
                                          className="position-absolute w-100 h-100"
                                          type="radio"
                                          name="category"
                                          // onChange={handleChange}
                                          // onBlur={handleBlur}
                                          value={category.uid}
                                          onChange={(e) =>
                                            setFieldValue("category", e.target.value)
                                          }
                                          checked={values.category == category.uid}
                                          style={{ top: "2px" }}
                                        />
                                        <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                          <span className="icn d-none rounded-circle">
                                            {/* <OTick /> */}
                                          </span>
                                        </div>
                                      </div>
                                      <label
                                        htmlFor=""
                                        className="form-label theme-clr m-0"
                                      >
                                        {category.category_name}
                                      </label>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                    )}
                    {/* </Col> */}
                    {/* </Row> */}
                  </div>
                  <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-3">
                    <Button
                      onClick={toggleSettingsScreen}
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
              {/* </Col> */}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    // userProfile: state.Profile.user,
    client: state.Client?.client?.details || {},
    clientSettings: state.Client?.client.settings || {},
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ClientSettings);
