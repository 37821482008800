import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ChooseClientPopup from "./ChooseClientPopup";
import CreateTemplatePopup from "./CreateTemplatePopup";

const DuplicateRecomendationPop = ({ showPopup, togglePopup, clients, handleDuplicateForSameClient, handleChooseClient, createTemplate, isDuplicating }) => {
  const [showChooseClientPopup, setShowChooseClientPopup] = useState(false);
  const [showCreateTemplatePopup, setShowCreateTemplatePopup] = useState(false);

  const openChooseClientPopup = () => {
    setShowChooseClientPopup(true);
    togglePopup();
  }

  const openCreateTemplatePopup = () => {
    setShowCreateTemplatePopup(true);
    togglePopup();
  }

  return (
    <>
      <ChooseClientPopup 
        showPopup={showChooseClientPopup}
        closePopup={() => {
          setShowChooseClientPopup(false);
        }}
        onChooseClient={handleChooseClient}
        clients={clients}
      />
      <CreateTemplatePopup 
        showPopup={showCreateTemplatePopup}
        closePopup={() => {
          setShowCreateTemplatePopup(false);
        }}
        onSubmit={createTemplate}
        clients={clients}
      />
      <Modal
        show={showPopup}
        onHide={togglePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Recommendation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg="8" className="my-2">
              <div className="py-1">
                <Button
                  onClick={handleDuplicateForSameClient}
                  disabled={isDuplicating}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                >
                  Duplicate Recommendation for this client
                </Button>
              </div>
              <div className="py-1">
                <Button 
                  onClick={openChooseClientPopup}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                >
                  Duplicate Recommendation for a different client
                </Button>
              </div>
              <div className="py-1">
                <Button
                  onClick={openCreateTemplatePopup}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                >
                  Create a new Recommendation Template
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={togglePopup}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DuplicateRecomendationPop;
