import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
//imported
import { register_, sendOtp } from 'store/services/authService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import Form from './Form'
import { hasActivePlan } from "helpers/common";

const SignUp = ({ dispatch, submitting }) => {
  const navigate = useNavigate();
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: ""
  };
  const submitData_ = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        const { body } = response;
        if (body.terms_accepted === 'no') {
          navigate(Path.dashboardTermsCondition)
        }
        if (!hasActivePlan(body)) {
          navigate(Path.dashboardSubscription)
        }
        if (body.is_plan_active === 'yes' || body.is_free_account === 'yes') {
          navigate(Path.dashboard)
        }
      }

      // values = JSON.stringify(values);
      // localStorage.setItem("registration_data", values);
      // await dispatch(register_(values, callback));
      await dispatch(sendOtp(values, "register"));
      setSubmitting(false);
      navigate(Path.verifyOTP);
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  }
  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <label className="m-0 form-label">Welcome back</label>
          <h2 className="m-0 fw-bold">Practitioner Sign Up</h2>
        </div>
        <div className="FormBody">
          <Form
            initialValues={initialValues}
            submitting={submitting}
            updateData_={submitData_}
          />
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};


const mapStateToProps = (state) => {
  return {
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);