import { SUCCESS_MESSAGE } from "common/constantMessage";
import { FULL_NAME, UC_FIRST } from "helpers/common";
import { useShowLoader } from "hooks/useShowLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientMergeStatsList, mergeClients } from "store/services/clientService";
import * as Path from "Routes/paths";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";

const MergeClientPop = ({ mergeClient, setMergeClient, client }) => {

  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [clientsStatsList, setClientsStatsList] = useState(null);
  const [currentClientStats, setCurrentClientStats] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [finalClient, setFinalClient] = useState(null);
  const [clientToRemove, setClientToRemove] = useState(null);

  useEffect(() => {
    let clientToRemove = (finalClient?.id == client?.id) ? selectedClient : client;
    setClientToRemove(clientToRemove);
  }, [finalClient]); 

  const showLoader = useShowLoader();
  const navigate = useNavigate();

  const closePopup = () => {
    resetPopup();
    setMergeClient(!mergeClient);
  };

  useEffect(() => {
    if (mergeClient && !clientsStatsList && client?.id) {
      getClientStatsList(client?.id);
    }
  }, [mergeClient, client]);

  const resetPopup = () => {
    setStep(1);
    setClientsStatsList(null);
    setCurrentClientStats(null);
    setSelectedClient(null);
    setFinalClient(null);
    setClientToRemove(null);
  }

  const getClientStatsList = async (clientId) => {
    try {
      if (!clientId) {
        throw "Client ID not found";
      }
      // showLoader(true);
      setLoading(true);
      let response = await clientMergeStatsList(clientId);
      if (response?.success === true) {
        if(response?.body?.others_clients?.length > 0) setClientsStatsList(response?.body?.others_clients);
        if(response?.body?.current_client) setCurrentClientStats(response?.body?.current_client);
      }
      // showLoader(false);
      setLoading(false);
    } catch (err) {
      console.log("Error getting client list for merging", err);
      // showLoader(false);
      setLoading(false);
    }
  }

  const handleMerge = async () => {
    try {
      showLoader(true);
      let data = {
        from_client: (finalClient?.id == client?.id) ? selectedClient?.id : client?.id,
        merge_client: finalClient?.id
      }
      if(!data.from_client || !data.merge_client) {
        throw "Invalid form or merge client";
      }
      let response = await mergeClients(data);
      if (response?.success === true) {
        SUCCESS_MESSAGE("Clients merged successfully");
        closePopup();
        if (finalClient?.id !== client?.id) {
          navigate(Path.myClients);
        }
      }
      showLoader(false);
    } catch (err) {
      console.log("Error merging clients", err);
      showLoader(false);
    }
  }

  const ClientStats = ({ clientId, clientStats }) => {
    if (!clientId || clientsStatsList?.length === 0) {
      return null;
    }
    clientStats = clientStats || clientsStatsList.find(clientUser => clientUser.id == clientId);
    return (
      <>
        <p
          className="m-0"
          style={{ fontSize: "12px", lineHeight: "18px" }}
        >
          {clientStats?.created_at && (
            <><span>Added on {moment(clientStats?.created_at).format("MMM DD, YYYY")}</span> <br /></>
          )}
          {clientStats?.completed_todo_counts || 0} Completed To-Do Items <br />
          {clientStats?.log_counts || 0} Log Entries
        </p>{" "}
      </>
    )
  }

  if (!client) {
    return "";
  }

  return (
    <>
      <Modal
        show={mergeClient}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="FormPop"
        scrollable="true"
      >
        <Modal.Header closeButton>
          <Modal.Title>Merge Clients</Modal.Title>
        </Modal.Header>
        {isLoading && (
          <div className="p-2 mt-2">
            <LoadingSkeleton
              itemCount={4}
            />
          </div>
        )}
        {!isLoading && step === 1 ? (
          <>
            <Modal.Body>
              <div className="commonContent">
                <div className="top">
                  <h4 className="m-0 fw-sbold">Merge {FULL_NAME(client?.first_name, client?.last_name)} with:</h4>
                </div>
                <div
                  className="commonCardBox p-3 mt-3"
                  style={{ background: "#fafafa" }}
                >
                  {clientsStatsList && clientsStatsList.length > 0 && (
                    <ul className="list-unstyled ps-0 mb-0">
                      {clientsStatsList.map((client) => {
                        let clientStatus = (function () {
                          switch (client?.status) {
                            case "linked":
                              return "Active";
                            case "unlinked":
                              return "Unlinked client";
                            case "direct_account":
                              return "Direct client";
                          }
                        })()
                        return (
                          <li className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp radio position-relative ">
                              <input
                                type="radio"
                                name="selected_client"
                                className={`position-absolute w-100 h-100 `}
                                onChange={(e) => {
                                  setSelectedClient(client);
                                }}
                              />
                              <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                <span className="icn d-none rounded-circle">
                                  {/* <OTick /> */}
                                </span>
                              </div>
                            </div>
                            <div className="content">
                              <p className=" m-0 fw-sbold text-dark">{FULL_NAME(client?.first_name, client?.last_name)}</p>
                              <p
                                className="m-0"
                                style={{ fontSize: "12px", lineHeight: "18px" }}
                              >
                                {client?.status && (
                                  <><span>{clientStatus}</span> <br /></>
                                )}
                                {client?.created_at && (
                                  <><span>Added on {moment(client?.created_at).format("MMM DD, YYYY")}</span> <br /></>
                                )}
                                {client?.completed_todo_counts || 0} Completed To-Do Items <br />
                                {client?.log_counts || 0} Log Entries
                              </p>{" "}
                              <br />
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="gap-10 FormbtnWrpper">
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={closePopup}
              >
                Cancel
              </Button>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => (
                  setStep(2)
                )}
                disabled={!selectedClient}
              >
                Next
              </Button>
            </Modal.Footer>
          </>
        ) : step === 2 ? (
          <>
            <Modal.Body>
              <div className="commonContent">
                <div className="top">
                  <h4 className="m-0 fw-sbold">Select the client which you want to keep after the merge is complete.</h4>
                </div>
                <div
                  className="commonCardBox p-3 mt-3"
                  style={{ background: "#fafafa" }}
                >
                  <ul className="list-unstyled ps-0 mb-0">
                    <li className="py-2 d-flex align-items-start gap-10">
                      <div className="cstmInputCheckWrp radio position-relative ">
                        <input
                          type="radio"
                          name="merge_client"
                          className={`position-absolute w-100 h-100 `}
                          onChange={(e) => {
                            setFinalClient(client);
                          }}
                          checked={finalClient?.id == client?.id}
                        />
                        <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                          <span className="icn d-none rounded-circle">
                            {/* <OTick /> */}
                          </span>
                        </div>
                      </div>
                      <div className="content">
                        <p className=" m-0 fw-sbold text-dark">{FULL_NAME(client?.first_name, client?.last_name)}</p>
                        <p className=" m-0">Email: {client?.email}</p>
                        <ClientStats
                          clientId={client?.id}
                          clientStats={currentClientStats}
                        />
                      </div>
                    </li>
                    <li className="py-2 d-flex align-items-start gap-10">
                      <div className="cstmInputCheckWrp radio position-relative ">
                        <input
                          type="radio"
                          name="merge_client"
                          className={`position-absolute w-100 h-100 `}
                          onChange={(e) => {
                            setFinalClient(selectedClient);
                          }}
                          checked={finalClient?.id == selectedClient?.id}
                        />
                        <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                          <span className="icn d-none rounded-circle">
                            {/* <OTick /> */}
                          </span>
                        </div>
                      </div>
                      <div className="content">
                        <p className=" m-0 fw-sbold text-dark">{FULL_NAME(selectedClient?.first_name, selectedClient?.last_name)}</p>
                        <p className=" m-0">Email: {selectedClient?.email}</p>
                        <ClientStats
                          clientId={selectedClient?.id}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="gap-10 FormbtnWrpper">
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => (
                  setStep(1)
                )}
              >
                Back
              </Button>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => {
                  setStep(3);
                }}
                disabled={!finalClient}
              >
                Next
              </Button>
            </Modal.Footer>
          </>
        ) : step === 3 ? (
          <>
            <Modal.Body>
              <div className="commonContent">
                <div className="top">
                  <h4 className="m-0 fw-sbold">
                    Confirm Client Merge and Removal
                  </h4>
                  <p>
                    Please review the client below.
                    If correct, click <strong>Confirm Merge</strong> to merge.
                    This client will be removed.
                    To make changes, go back and edit.
                  </p>
                </div>
                <div
                  className="commonCardBox p-3 mt-3"
                  style={{ background: "#fafafa" }}
                >
                  <div className="content">
                    <p className=" m-0 fw-sbold text-dark">{FULL_NAME(clientToRemove?.first_name, clientToRemove?.last_name)}</p>
                    <p className=" m-0">Email: {clientToRemove?.email}</p>
                    <ClientStats
                      clientId={clientToRemove?.id}
                      clientStats={clientToRemove?.id === client?.id ? currentClientStats : null}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="gap-10 FormbtnWrpper">
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => (
                  setStep(2)
                )}
              >
                Back
              </Button>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={handleMerge}
                disabled={!finalClient}
              >
                Confirm Merge
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client?.client?.details || {},
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MergeClientPop);
