import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, MY_FORM_LIST,MY_FORM_ADD, MY_FORM_UPDATE, MY_FORM_VIEW, MY_FORM_DELETE, FORM_PAGE_ADD, FORM_PAGE_DELETE, FORM_PAGE_EDIT, GET_FORM_QUESTION_TYPES, FORM_QUESTION_ADD, FORM_QUESTION_EDIT, FORM_QUESTION_DELETE, DUPLICATE_FORM, GET_FORM_LISTING, FORM_PAGE_SORTING  } from './apiPath'
import { getMyForm, addMyForm, viewMyForm, updateMyForm, deleteMyForm, addNewFormPage, deleteMyFormPage, editPage, getMyFormQuestionTypes, addNewFormQuestion, editFormQuestion, deleteMyFormQuestion } from 'store/actions/myForm';

// currently being used
// get forms list
export function getFormsForClient(value, callback) {
  let query = new URLSearchParams(value);
  let queryString = query.toString();
  let url = `${MAIN_PATH}${MY_FORM_LIST}?${queryString}`;
  // let url = `http://localhost:3000/dev/forms/list?${queryString}`;
  return getData(url, value);
}

//get
// used in add client
export function getMyFormList(value, callback) {
  // const { category_id } = value || {};
  let query = new URLSearchParams(value);
  let queryString = query.toString();
  let url = `${MAIN_PATH}${MY_FORM_LIST}?${queryString}`;
  // let url = `http://localhost:3000/dev/forms/list?${queryString}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getMyForm(data, callback));
      return data;
    });
}

// form listing
export function getFormListing(value, callback) {
  const { category_id } = value || {};
  let url = `${MAIN_PATH}${GET_FORM_LISTING}`;
  if (category_id !== undefined) {
    url += `?category_id=${category_id}`;
  }
  return postData(url, value)
}

//add
export function addMyFormData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${MY_FORM_ADD}`, value)
    .then((data) => {
      dispatch(addMyForm(data, callback));
      return data;
    });
}

//view
export function viewMyFormData(id, callback) {
  let url = `${MAIN_PATH}${MY_FORM_VIEW}${id}/details`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(viewMyForm(data, callback));
      return data;
    });
}


//edit
export function updateMyFormData(value, formId, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${MY_FORM_UPDATE}${formId}/edit`, value)
    .then((data) => {
      dispatch(updateMyForm(data, callback));
      return data;
    });
}


//delete
export function deleteMyFormData(value) {
  let { formId } = value;
  return (dispatch) => deleteData(`${MAIN_PATH}${MY_FORM_DELETE}${formId}`)
    .then((data) => {
       dispatch(deleteMyForm(value));
      return data;
    });
}

// pages
// add new page
export function addFormPage(value, callback) {
  let url = `${MAIN_PATH}${FORM_PAGE_ADD}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(addNewFormPage(data, callback));
      return data;
    });
}

// edit form page
export function editFormPage(value, pageId, callback) {
  let url = `${MAIN_PATH}${FORM_PAGE_EDIT}${pageId}/edit`;
  return (dispatch) => putData(url, value)
    .then((data) => {
      dispatch(editPage(data, callback));
      return data;
    });
}

// delete page
export function deleteFormPage(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${FORM_PAGE_DELETE}${value.pageId}`)
    .then((data) => {
       dispatch(deleteMyFormPage(value));
      return data;
    });
}

// form question types
export function getFormQuestionTypes() {
  return (dispatch) => getData(`${MAIN_PATH}${GET_FORM_QUESTION_TYPES}`)
    .then((data) => {
      dispatch(getMyFormQuestionTypes(data));
      return data;
    });
}

// add question
export function addFormPageQuestion(value, callback) {
  let url = `${MAIN_PATH}${FORM_QUESTION_ADD}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(addNewFormQuestion(data, callback));
      return data;
    });
}

// edit question
export function editFormPageQuestion(value, callback) {
  let { editQuestionId, values } = value;
  let url = `${MAIN_PATH}${FORM_QUESTION_EDIT}${editQuestionId}/edit`;
  return (dispatch) => putData(url, values)
    .then((data) => {
      dispatch(editFormQuestion(data, callback));
      return data;
    });
}

// delete question
export function deleteFormQuestion(data, callback) {
  let {questionId, values} = data;
  return (dispatch) => postData(`${MAIN_PATH}${FORM_QUESTION_DELETE}${questionId}/remove`, values)
    .then((data) => {
      let formId = values.formId;
      dispatch(viewMyFormData(formId, callback));
      return data;
    });
}

// duplicate form
export function duplicateForm(values, callback) {
  let {formId} = values;
  return postData(`${MAIN_PATH}${DUPLICATE_FORM}${formId}`, values);
}

// save pages order
export function savePagesOrder(values, callback) {
  let {formId, pages} = values;
  let url = `${MAIN_PATH}${FORM_PAGE_SORTING}${formId}`;
  return postData(url, pages);
}

// get form details
export function viewMyFormDetails(id, callback) {
  let url = `${MAIN_PATH}${MY_FORM_VIEW}${id}/details`;
  return getData(url);
}