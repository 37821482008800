import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addMyFormData,
  updateMyFormData,
  viewMyFormData,
} from "store/services/myFormService";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "common/constantMessage";

import "./EditForm.scss";

// icn
import { ReactComponent as OTick } from "../../../../../Assets/Images/icn/OrangeTick.svg";
import NewQuestionPopup from "./Component/Modal/NewQuestion/NewQuestionPopup";
import NewPagePopup from "./Component/Modal/AddPage/NewPagePop";
import ManagePageQuestions from "./Component/ManagePageQuestions/Index";
import {
  clearFormDetailsData,
  toggleAddPagePopup,
  toggleAddQuestionPopup,
} from "store/actions/myForm";
import * as actions from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { login } from "Routes/paths";
import { myForms, editForm } from "Routes/paths";

const EditForm = ({
  dispatch,
  pages,
  formDetails,
  showAddPagePopup,
  showAddQuestionPopup,
  isEditMode,
}) => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const handleNewForm = () => setNewFormPop(!NewFormPop);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (formId) {
      getFormDetails();
      // dispatch(actions.persist_store({ loader: true }));
    }
  }, [formId]);

  useEffect(() => {
    return () => {
      dispatch(clearFormDetailsData());
    }
  }, []);

  const getFormDetails = async () => {
    try {
      if(!formId) {
        throw new Error("invalid form ID");
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewMyFormData(formId));
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error fetching form details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }
  
  const openPageEditPopup = (pageId = null) =>
    dispatch(
      toggleAddPagePopup({
        visible: true,
        editPageId: pageId,
      })
    );
  const openQuestionEditPopup = (payload) =>
    dispatch(
      toggleAddQuestionPopup({
        visible: true,
        pageId: payload?.pageId || null,
        editQuestionId: payload?.editQuestionId,
      })
    );

  const saveFormDetails = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        if (response.success === true && response.message) {
          SUCCESS_MESSAGE(response.message);
        }
      };
      if (isEditMode === true) {
        const id = formId || formDetails.id;
        // update the form
        await dispatch(updateMyFormData(values, id, callback));
      } else {
        // Add new form
        let response = await dispatch(addMyFormData(values, callback));

        // redirect to edit page
        if(isEditMode === false && response?.body?.id) {
          navigate(editForm.replace(":formId", response?.body?.id));
        }
      }

      if(shouldRedirect) {
        navigate(myForms);
      }

      setSubmitting(false);
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  };

  return (
    <>
      <NewQuestionPopup
        showPopup={showAddQuestionPopup}
        formId={formDetails.id}
        getFormDetails={getFormDetails}
      />
      <NewPagePopup
        showPopup={showAddPagePopup}
        formId={formDetails.id}
        getFormDetails={getFormDetails}
      />
      <section className="DocsForms position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <Formik
                initialValues={{
                  form_name: isEditMode ? formDetails.form_name : "",
                  form_desc: isEditMode ? formDetails.form_desc : "",
                  form_type: isEditMode ? formDetails.form_type : "",
                  show_question_no: isEditMode
                    ? formDetails.show_question_no
                    : "no",
                }}
                enableReinitialize
                validationSchema={validation}
                onSubmit={saveFormDetails}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  submitForm,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <div className="personalInfo py-3 border-bottom">
                        <Row>
                          <Col lg="8" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold m-0"
                            >
                              Form Title (required)
                            </label>
                            <p className="formText m-0">
                              The title of your form/questionnaire that your
                              clients will see.
                            </p>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.form_name && "is-invalid"
                              }`}
                              name="form_name"
                              onChange={handleChange}
                              placeholder="Form Title"
                              onBlur={handleBlur}
                              value={values.form_name}
                              autoComplete="off"
                            />
                            {errors.form_name && touched.form_name && (
                              <p className="invalid-feedback">
                                {errors.form_name}
                              </p>
                            )}
                          </Col>
                          <Col lg="8" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold m-0"
                            >
                              Form Description (optional)
                            </label>
                            <p className="formText m-0">
                              Provide a short description of your form or
                              instructions for your clients to read before they
                              start.
                            </p>
                            <textarea
                              className={`form-control ${
                                errors.form_desc && "is-invalid"
                              }`}
                              name="form_desc"
                              onChange={handleChange}
                              placeholder="Form Description"
                              onBlur={handleBlur}
                              value={values.form_desc}
                              autoComplete="off"
                              rows={5}
                            ></textarea>
                            {errors.form_desc && touched.form_desc && (
                              <p className="invalid-feedback">
                                {errors.form_desc}
                              </p>
                            )}
                          </Col>
                          <Col lg="8" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold m-0"
                            >
                              Form Question Numbers
                            </label>
                            <div className="py-2 d-flex align-items-start">
                              <div className="cstmInputCheckWrp position-relative me-2">
                                {/* <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            /> */}
                                <input
                                  type="checkbox"
                                  className={`position-absolute w-100 h-100 ${
                                    errors.show_question_no && "is-invalid"
                                  }`}
                                  name="show_question_no"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "show_question_no",
                                      e.target.checked ? "yes" : "no"
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  checked={values.show_question_no === "yes"}
                                  value={values.show_question_no}
                                  autoComplete="off"
                                />
                                <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                  <span className="icn d-none">
                                    <OTick />
                                  </span>
                                </div>
                              </div>
                              <p className=" m-0 ">
                                Show the question numbers when a client is
                                filling out this form
                              </p>
                            </div>
                            {/* <p className=" m-0 ">
                            Show the question numbers when a client is filling out
                            this form
                          </p> */}
                          </Col>
                          <Col lg="8" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label fw-sbold m-0"
                            >
                              Form Visibility (required)
                            </label>
                            <div className="d-flex justify-content-start gap-10">
                              <div className="py-2 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    name="form_type"
                                    type="radio"
                                    className={`position-absolute w-100 h-100`}
                                    value={"practitioner"}
                                    onChange={handleChange}
                                    checked={values.form_type == "practitioner"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <p className="m-0">Practitioner Facing</p>
                              </div>
                              <div className="py-2 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    name="form_type"
                                    type="radio"
                                    className={`position-absolute w-100 h-100`}
                                    value={"client"}
                                    onChange={handleChange}
                                    checked={values.form_type == "client"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <p className="m-0">Client Facing</p>
                              </div>
                              <div className="py-2 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp radio position-relative ">
                                  <input
                                    name="form_type"
                                    type="radio"
                                    className={`position-absolute w-100 h-100`}
                                    value={"both"}
                                    onChange={handleChange}
                                    checked={values.form_type == "both"}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                    <span className="icn d-none rounded-circle">
                                      {/* <OTick /> */}
                                    </span>
                                  </div>
                                </div>
                                <p className="m-0">Both</p>
                              </div>
                            </div>
                            {errors.form_type && touched.form_type && (
                              <p className="invalid-feedback d-block">
                                {errors.form_type}
                              </p>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <ManagePageQuestions
                        pages={pages}
                        formDetails={formDetails}
                        openPageEditPopup={openPageEditPopup}
                        openQuestionEditPopup={openQuestionEditPopup}
                      />
                      <div className="btnWrpper mt-3 d-flex align-items-center gap-10 flex-wrap">
                        <div className="left d-flex align-items-center gap-10">
                          <Button
                            type="submit"
                            className="d-flex align-items-center justify-content-center commonBtn btnSm px-lg-4"
                            disabled={isSubmitting}
                            onClick={(e) => {
                              e.preventDefault();
                              setShouldRedirect(true);
                              submitForm()
                            }}
                          >
                            {isSubmitting ? "Saving..." : "Save & Close"}
                          </Button>
                          <Button
                            type="submit"
                            className="d-flex align-items-center justify-content-center commonBtn btnSm px-lg-4"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Saving..." : "Save"}
                          </Button>
                        </div>
                        <div className="right ms-lg-3">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn btnSm px-lg-4"
                            onClick={() => {
                              navigate(myForms);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const validation = Yup.object({
  form_name: Yup.string().trim().required("Title is Required"),
  description: Yup.string().trim().optional(),
  form_type: Yup.string().required("This Field is Required"),
});

const mapStateToPros = (state) => {
  return {
    isEditMode: !!state?.MyForm?.formDetails?.details.id,
    pages: state.MyForm.formDetails.pages,
    formDetails: state.MyForm.formDetails.details,
    showAddPagePopup: state.MyForm.showAddPagePopup,
    showAddQuestionPopup: state.MyForm.addQuestionPopup.visible,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditForm);
