import { FULL_NAME } from "helpers/common";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import NewRecommSuccessPopup from "./NewRecommSuccessPopup";

const ChooseTemplatePopup = ({ showPopup, closePopup, recommendationTemplates, handleTemplateSelect, viewRecommendation }) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [newRecommendationId, setNewRecommendationId] = useState(null);

  const validation = Yup.object({
    selected_template: Yup.string().required("Please select a template"),
  });

  const openRecommendation = () => {
    viewRecommendation(newRecommendationId);
    setShowSuccessPopup(false);
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        let { body } = response;
        if(response.success === true && body.todo_id) {
          setNewRecommendationId(body.todo_id);
        }
      }
      
      await handleTemplateSelect(values.selected_template, callback);
      setSubmitting(false);
      closePopup();
      setShowSuccessPopup(true);
    } catch (error) {
      console.log("Error duplicating recommendation", error);
      setSubmitting(false);
    }
  }

  return (
    <>
      <NewRecommSuccessPopup
        showPopup={showSuccessPopup}
        handleClose={() => {
          setShowSuccessPopup(false);
        }}
        viewRecommendation={openRecommendation}
      />
      <Formik
        initialValues={{
          selected_template: ""
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            show={showPopup}
            onHide={closePopup}
            backdrop="static"
            keyboard={false}
            centered
            className="FormPop"
          >
            <Modal.Header closeButton>
              <Modal.Title>Choose Template</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-lg-5 pt-0">
              <Form onSubmit={handleSubmit}>
                <Row className="">
                  <Col lg="8" className="my-4">
                    <label htmlFor="" className="form-label m-0 fw-sbold">
                      Which template do you want to use?
                    </label>
                    <Form.Select
                      aria-label="Default select example"
                      name="selected_template"
                      className={`form-control ${errors.selected_template && "is-invalid"}`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="">Select Template</option>
                      {recommendationTemplates && recommendationTemplates.length > 0 &&
                        recommendationTemplates.map(template => {
                          return (
                            <option value={template.id}>{template.template_name}</option>
                          )
                        })
                      }
                    </Form.Select>
                    {errors.selected_template && touched.selected_template && (
                      <p className="invalid-feedback">{errors.selected_template}</p>
                    )}
                  </Col>
                  <Col lg="12" className="FormbtnWrpper justify-content-start d-flex gap-10">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    >
                      { isSubmitting ? "Please wait..." : "Submit" }
                    </Button>
                    <Button
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      onClick={closePopup}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
    </>
  );
};



export default ChooseTemplatePopup;
