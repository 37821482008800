import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import persistStore from './persistStore';
//imported
import Auth from '../reducers/Auth';
import Profile from '../reducers/Profile';
import File from '../reducers/File';
import Subscription from '../reducers/Subscription';
import Client from '../reducers/Client';
import MyForm from '../reducers/MyForm';
import Resource from '../reducers/Resource';
import Faq from '../reducers/Faq';
import ContentPage from '../reducers/ContentPage';
import Practitioner from '../reducers/Practitioner';
import Documents from '../reducers/Documents';
import Configuration from '../reducers/Configuration';
import AccountSetting from '../reducers/AccountSetting';
import Tags from '../reducers/Tags';
import Package from '../reducers/Package';
import Appointment from '../reducers/Appointment';
import Misc from './Misc';

const RootReducer = combineReducers({
		Auth,
		Profile,
		File,
		Subscription,
		Client,
		MyForm,
		Resource,
		Faq,
		ContentPage,
		Practitioner,
		Documents,
		Configuration,
		AccountSetting,
		Tags,
		Package,
		Appointment,
		Misc,
		persistStore,
		form: formReducer,
	});
export default RootReducer;
