import React, {useState, useEffect, useCallback} from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import AuthLayout from "../../../layout/AuthLayout/AuthLayout";
import { forgotPassword } from 'store/services/authService';
import { Link } from "react-router-dom";
import * as actions from 'store/actions';
import { toast } from 'react-toastify';

const ForgotPassword = ({dispatch}) => {
  const [sending, setSending] = useState(false)
  const [fields, setfields] = useState({
    email: "",
  })

  const handleChange = useCallback(
    name => evt => {
      setfields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    },
    []
  )

  //submit  data
  const handleSubmit = async (evt) => {
    try{
      evt.preventDefault();
      let obj = JSON.stringify(fields)
      setSending(true)
      let res = await dispatch(forgotPassword(obj));
      toast.success(res?.message);
      setSending(false)
      setfields({email:""})
    } catch (err) {
        console.log(err);
        setSending(false)
    }
  }
  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <h2 className="m-0 fw-bold">Forgot Password</h2>
        </div>
        <div className="FormBody">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  onChange={handleChange("email")}
                  value={fields.email}
                  required
                />
              </Col>
              <Col lg="12" className="my-2">
                <Button type="submit" className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                  {sending ? 'wait...' : 'Submit'}
                </Button>
                <div className="mt-4">
                  <p className="formText m-0 text-center text-muted py-1">
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className=" form-link fw-sbold underline"
                    >
                      Log In here
                    </Link>
                  </p>
                  <p className="formText m-0 text-center text-muted py-1">
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      className=" form-link fw-sbold underline"
                    >
                      Sign Up here
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return{
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
