import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ResendPackageInvitePopup = ({ showPopup, setShowPopup, handleResendInvite }) => {
  const [isSendingInvite, setSendingInvite] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  }

  const handleClickResendInvite = () => {
    handleResendInvite(setSendingInvite);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Resend Invite Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12" className="my-2">
              <p className="m-0 fw-sbold pb-1">
                Are you sure you want to resend this invite?
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClickResendInvite}
            disabled={isSendingInvite}
          >
            {isSendingInvite ? "Please wait..." : "Yes, Resend Invite"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResendPackageInvitePopup;
