import React from "react";
import {
  Accordion,
} from "react-bootstrap";
import { connect } from "react-redux";

// icons
import SingleActionPlan from "./Components/SingleActionPlan";

const ActiveActionPlans = ({
  actionPlans,
  handleEdit,
  togglePublish
}) => {

  return (
    <>
      <Accordion className="my-3 ColoredAcorrdion">
        {actionPlans && actionPlans.length > 0 &&
          actionPlans.map(actionPlan => {
            return (
              <SingleActionPlan
                actionPlan={actionPlan}
                handleEdit={handleEdit}
                togglePublish={togglePublish}
              />
            )
          })
        }
      </Accordion>
    </>
  )
}



const mapStateToPros = (state) => {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ActiveActionPlans);