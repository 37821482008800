
import { uploadImage_ } from 'store/services/fileService';
import * as actions from 'store/actions';

export async function  UploadMediaFile (dispatch, file, extension, file_type, folder){
    try{
        let query = {
            file:file,
            extension : `.${extension}`,
            file_type : file_type,
            folder:folder,
        }
        query = JSON.stringify(query);
        let response = await uploadImage_(query);
        return response;

    }catch(err) {
        console.log(err);
        dispatch(actions.persist_store({ loader: false }));
    }
}


