import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

export const WheelChart = forwardRef(function Chart({ chartData, height, screenSize }, ref) {
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState({
    // plugins: [ChartDataLabels],
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false
      },
      datalabels: {
        color: "#ffffff",
        formatter: (_, context) => {
          return `${context.chart.data.labels[context.dataIndex]}`
        },
        font: { size: 12 },
      },
      legend: {
        display: false
      },
    }
  });

  const chartRef = useRef();

  useEffect(() => {
    if(!chartData || chartData.length == 0) {
      return;
    }
    const labels = [];
    const data = [];
    const bgColors = [];

    for(let i = 0; i < chartData.length; i++ ) {
      let row = chartData[i];
      labels.push(`${row.title} ${row.score}`);
      data.push(10);
      bgColors.push(row.color);
    }

    const formattedData = {
      labels: labels,
      datasets: [{
        label: '',
        data: data,
        backgroundColor: bgColors,
        hoverOffset: 4
      }]
    };

    setFormattedData(formattedData);
    setLoading(false);
  }, [chartData]);

  useImperativeHandle(ref, () => {
    return {
      downloadChartImage() {
        if(chartRef.current) {
          const base64Image = chartRef.current.toBase64Image('image/png', 1);
          return base64Image;
        }
      }
    }
  });

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Pie
          ref={chartRef}
          options={options}
          data={formattedData}
          height={height}
          plugins={[ChartDataLabels]}
        />
      }
    </>
  )
})