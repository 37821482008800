import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";

const SubscriptionSuccess = ({ }) => {
  return (
    <>
      <section className="subscription position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContent">
                <h3 className="m-0 fw-bold py-1">Success!</h3>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="my-2">
                Thank you, your subscription was successfully created.
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    profileDetail: state.Profile.user,
    subscriptionList: state.Subscription.subscriptions,
    loader: state.Subscription.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionSuccess);
