import React, { useEffect, useState } from "react";
import { decode } from "html-entities";
import moment from "moment";

const PriorityReport = ({ priorityReport, naqList, selectedNAQs, selectedType }) => {
  const [dataPart1, setDataPart1] = useState(null);
  const [dataPart2, setDataPart2] = useState(null);
  const [naqs, setNaqs] = useState(naqList);

  useEffect(() => {
    if (priorityReport && priorityReport.data) {
      setDataPart1(priorityReport.data.part1);
      setDataPart2(priorityReport.data.part2);
    }
  }, [priorityReport])

  useEffect(() => {
    if (naqList && selectedNAQs.length > 1) {
      let naqs = naqList.filter(naq => selectedNAQs.includes(naq.id));
      setNaqs(naqs);
    } else if (naqList) {
      setNaqs(naqList);
    }
  }, [naqList, selectedNAQs]);

  const QuetionsTable = ({ title, questions }) => {
    let naq1 = priorityReport?.prevTodoId;
    let naq2 = priorityReport?.currentTodoId;
    if (selectedNAQs.length > 0) {
      let shouldShowNaq1 = selectedNAQs.find(id => id === naq1);
      if (!shouldShowNaq1) {
        naq1 = null;
      }
      let shouldShowNaq2 = selectedNAQs.find(id => id === naq2);
      if (!shouldShowNaq2) {
        naq2 = null;
      }
    }
    return (
      <div className="py-3">
        <h6>{title} ({questions.length})</h6>
        <div className="table-responsive">
          <table className="table priority-table table-striped">
            <thead>
              {" "}
              <tr>
                <th className="p-3 fw-normal text-dark fw-sbold">
                </th>
                <th className="p-3 fw-normal text-dark fw-sbold">
                </th>
                {naq1 &&
                  <th
                    className="p-3 fw-normal text-dark fw-sbold"
                    style={{ borderBottom: `4px solid ${naq1 && naqs?.find(naq => naq.id == naq1).color}` }}
                  >
                    {moment(priorityReport.prevCompletedAt).format("MMM DD, YYYY")}
                  </th>
                }
                {naq2 &&
                  <th
                    className="p-3 fw-normal text-dark fw-sbold"
                    style={{ borderBottom: `4px solid ${naq2 && naqs?.find(naq => naq.id == naq2).color}` }}
                  >
                    {moment(priorityReport.currentCompletedAt).format("MMM DD, YYYY")}
                  </th>
                }
                <th className="p-3 fw-normal text-dark fw-sbold">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="p-3 fw-normal text-dark fw-sbold">
                  Section
                </th>
                <th className="p-3 fw-normal text-dark fw-sbold">
                  Question
                </th>
                {naq1 &&
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Answer
                  </th>
                }
                {naq2 &&
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Answer
                  </th>
                }
                {selectedNAQs.length !== 1 &&
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Change
                  </th>
                }
              </tr>
              {questions && questions.length > 0 &&
                questions.map((question) => {
                  return (
                    <tr>
                      <td className="p-3 border-0">{decode(question.page_title)}</td>
                      <td className="p-3 border-0">{decode(question.question)}</td>
                      {naq1 && <td className="p-3 border-0">{decode(question?.answer_prev)}</td>}
                      {naq2 && <td className="p-3 border-0">{decode(question?.answer_current)}</td>}
                      {selectedNAQs.length !== 1 && <td className="p-3 border-0">{(question.scoreChange <= 0 ? "" : "+") + question.scoreChange}</td>}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className="accordion-inner">
      {/* <div className="py-2">
        <div className="top d-flex align-items-center justify-content-end gap-10">
          <div className="left d-flex align-items-center gap-10">
            <p className="m-0 text-uppercase fw-bold">View Priority Report grouped by:</p>
            <Form.Select
              className="form-control fw-sbold btnSm"
              aria-label="Default select example"
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
            >
              <option value="questionnaireo">Questionnaire Section</option>
              <option value="SBG">SBG Section</option>
            </Form.Select>
          </div>
          <div className="right">
            <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
              <span className="icn me-2">
                <DownloadIcn />
              </span>
              Download Report
            </Button>
          </div>
        </div>
      </div> */}
      <div className="sections-outer">
        {dataPart1 &&
          <div className="section py-4">
            <h5>Part 1</h5>
            <div className="commonCardBox p-2 mb-3">
              <QuetionsTable
                title="Improved"
                questions={dataPart1.improved || []}
              />
            </div>
            <div className="commonCardBox p-2 mb-3">
              <QuetionsTable
                title="Worsened"
                questions={dataPart1.worsened || []}
              />
            </div>
            <div className="commonCardBox p-2 mb-3">
              <QuetionsTable
                title="Unchanged"
                questions={dataPart1.unchanged || []}
              />
            </div>
          </div>
        }
      </div>
      <div className="sections-outer">
        {dataPart2 &&
          <div className="section py-4">
            <h5>Part 2</h5>
            <div className="commonCardBox p-2">
              <QuetionsTable
                title="Improved"
                questions={dataPart2.improved || []}
              />
              <QuetionsTable
                title="Worsened"
                questions={dataPart2.worsened || []}
              />
              <QuetionsTable
                title="Unchanged"
                questions={dataPart2.unchanged || []}
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PriorityReport;