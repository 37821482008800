import { GET_MY_FORM, ADD_MY_FORM, VIEW_MY_FORM, UPDATE_MY_FORM, DELETE_MY_FORM, ADD_NEW_FORM_PAGE, EDIT_FORM_PAGE, DELETE_MY_FORM_PAGE, TOGGLE_ADD_PAGE_POPUP, TOGGLE_ADD_QUESTION_POPUP, GET_FORM_QUESTION_TYPES, ADD_NEW_FORM_QUESTION, EDIT_FORM_QUESTION, DELETE_MY_FORM_QUESTION, TOGGLE_DELETE_FORM_POPUP, CLEAR_FORM_DETAILS_DATA } from 'store/actions/myForm';

const initialState = {
    isAuthenticated: false,
    showAddPagePopup: false,
    addQuestionPopup: {
      visible: false,
      pageId: null,
      editQuestionId: null
    },
    deleteFormPopup: {
      visible: false,
      formId: null
    },
    formQuestionTypes: [],
    myForms: [],
    formDetails: {
      pages: [],
      details: {
        // form_name: "test",
        // form_desc: "test",
        // id: 28
      },
    },
    editMode: false,
    loading: true,
    activePage:1,
    totalItemsCount: 0,
    itemsCountPerPage: 10,
};

//get 
const getMyForm = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        myForms: data.body,
        itemsCountPerPage: data.body.per_page,
        totalItemsCount: data.body.total_count
    };
  return stateObj;
};

//add 
const addMyForm = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(data);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      formDetails: {
        ...state.formDetails,
        details: data.body
      },
      loading: false,
    };
    return stateObj;
  };

  //update 
const viewMyForm = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      formDetails: data.body,
      editMode: true,
    };
    return stateObj;
};

  //update 
const updateMyForm = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(data);
    }
    const stateObj = {
      ...state,
      formDetails: {
        ...state.formDetails,
        details: data.body
      },
      loading: false,
    };
    return stateObj;
};

//delete 
const deleteMyForm = (state, payload) => {
  let deletedFormId = payload.formId;
  let updatedForms = state.myForms.filter((form) => form.id !== deletedFormId );
  const stateObj = {
    ...state,
    myForms:updatedForms,
  };
  return stateObj;
};

//add 
const addNewPage = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(data);
  }
  
  return state;
};

const editFormPage = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(data);
  }
  
  return state;
};

const toggleNewPagePopup = (state, payload) => {
  const stateObj = {
    ...state,
    showAddPagePopup: payload.visible || false,
    editPageId: ( typeof payload.editPageId === "number" && payload.editPageId ) || null,
    };
  return stateObj;
}

const toggleNewQuestionPopup = (state, payload) => {
  const stateObj = {
    ...state,
    addQuestionPopup: {
      visible: payload.visible || false,
      pageId: payload?.pageId || null,
      editQuestionId: payload?.editQuestionId || null,
    }
    };
  return stateObj;
}

const toggleDeleteFormPopup = (state, payload) => {
  const stateObj = {
    ...state,
    deleteFormPopup: {
      visible: payload.visible || false,
      formId: payload?.formId || null
    }
    };
  return stateObj;
}

const deleteFormPage = (state, payload) => {

  // let pagesAfterDeleting = state.formDetails.pages.filter((page) => {
  //   return page.id !== payload.pageId
  // })
  // const stateObj = {
  //   ...state,
  //   formDetails: {
  //     ...state.formDetails,
  //     pages: pagesAfterDeleting
  //   },
  // };
  return state;
};

const getFormQuestionTypes = (state, payload) => {
  const stateObj = {
      ...state,
      formQuestionTypes: payload.body
  };
return stateObj;
};

const addNewQuestion = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(data);
  }

  // let pageId = data.body.form_page_id;
  // let pagesWithNewQuestion = state.formDetails.pages.map((page) => {
  //   if(page.id == pageId) {
  //     page.questions.push(data.body);
  //   }
  //   return page;
  // })

  // const stateObj = {
  //   ...state,
  //   formDetails: {
  //     ...state.formDetails,
  //     pages: pagesWithNewQuestion
  //   },
  // };
  return state;
};

const editFormQuestion = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(data);
  }

  // let pageId = data.body.form_page_id;
  // let editedQuestionId = data.body.id;
  // let pagesWithUpdatedQuestion = state.formDetails.pages.map((page) => {
  //   if(page.id == pageId) {
  //     page.questions = page.questions.map((question) => {
  //       if(question.id == editedQuestionId) {
  //         return data.body
  //       }
  //       return question;
  //     })
  //   }

  //   return page;
  // })
  
  // const stateObj = {
  //   ...state,
  //   formDetails: {
  //     ...state.formDetails,
  //     pages: pagesWithUpdatedQuestion
  //   },
  // };
  return state;
};

// delete question
const deleteFormQuestion = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(data);
  }

  // let pageId = data.pageId;
  // let deletedQuestionId = data.questionId;
  // let pagesAfterDeletingQuestion = state.formDetails.pages.map((page) => {
  //   if(page.id == pageId) {
  //     page.questions = page.questions.filter((question) => question.id !== deletedQuestionId )
  //   }
  //   return page;
  // })
  // const stateObj = {
  //   ...state,
  //   formDetails: {
  //     ...state.formDetails,
  //     pages: pagesAfterDeletingQuestion
  //   },
  // };
  return state;
};

const clearFormDetails = (state, payload) => {
  const stateObj = {
    ...state,
    formDetails: initialState.formDetails,
  };
  return stateObj;
};

const MyForm = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_MY_FORM:
          return getMyForm(state, payload);
        case ADD_MY_FORM:
            return addMyForm(state, payload);
        case VIEW_MY_FORM:
          return viewMyForm(state, payload);
        case UPDATE_MY_FORM:
          return updateMyForm(state, payload);
        case DELETE_MY_FORM:
          return deleteMyForm(state, payload);
        case ADD_NEW_FORM_PAGE:
          return addNewPage(state, payload);
        case EDIT_FORM_PAGE:
          return editFormPage(state, payload);
        case TOGGLE_ADD_PAGE_POPUP:
          return toggleNewPagePopup(state, payload);
        case TOGGLE_ADD_QUESTION_POPUP:
          return toggleNewQuestionPopup(state, payload)
        case DELETE_MY_FORM_PAGE:
          return deleteFormPage(state, payload);
        case GET_FORM_QUESTION_TYPES:
          return getFormQuestionTypes(state, payload);
        case ADD_NEW_FORM_QUESTION:
          return addNewQuestion(state, payload);
        case EDIT_FORM_QUESTION:
          return editFormQuestion(state, payload);
        case DELETE_MY_FORM_QUESTION:
          return deleteFormQuestion(state, payload);
        case TOGGLE_DELETE_FORM_POPUP:
          return toggleDeleteFormPopup(state, payload);
        case CLEAR_FORM_DETAILS_DATA:
          return clearFormDetails(state, payload);
        default:
        return state;
    };
}
export default MyForm;