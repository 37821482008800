import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from "react-router-dom";
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
} from 'chart.js';
import { decode } from "html-entities";
import { getChartHeightByScreenSize, getScreenSize } from "components/shared/ChartConfig";
import { connect } from "react-redux";
import * as actions from "store/actions"
import { getBodySymptomBurdenGraphV1, getWellnessAssessmentData } from "store/services/clientDataReportsService";
import { WheelChart } from "./Components/WheelChart";
import { BarChart } from "./Components/BarChart";
import { BarWheelChart } from "./Components/BarWheelChart";
import { setActiveTab } from "store/actions/client";
import D3Chart from "./Components/D3Chart";

ChartJS.register(annotationPlugin);

const AssessmentCharts = ({ dispatch, eventKey, todoId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChartType, setSelectedChartType] = useState("bar");
  const [chartHeight, setChartHeight] = useState(150);
  const [screenSize, setScreenSize] = useState("extra-large");
  const [isLoading, setIsLoading] = useState(false);
  const [wellnessAssessData, setWellnessAssessData] = useState(null);
  const { clientId } = useParams();

  useEffect(() => {
    let size = getScreenSize(screenSize);
    setScreenSize(size);
  }, []);

  useEffect(() => {
    let height = getChartHeightByScreenSize(screenSize, chartHeight);
    setChartHeight(height);
  }, [screenSize]);

  useEffect(() => {
    if (isOpen && !wellnessAssessData) {
      getWellnessGraphData();
    }
  }, [isOpen]);

  const getWellnessGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      const response = await getWellnessAssessmentData(data);
      if (response.success === true) {
        const data = response.body;
        setWellnessAssessData(data);
      }
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  return (
    <Accordion.Item eventKey={eventKey} className="symptom-burden-graph my-2">
      <Accordion.Header>
        Wellness Assessment Chart 
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <div className="py-2">
          <Row>
            <Col lg="2">
              <Form.Label>
                Chart view:
              </Form.Label>
              <Form.Select
                onChange={(e) => {
                  setSelectedChartType(e.target.value);
                }}
                value={selectedChartType}
              >
                <option value="wheel">Wheel Chart</option>
                <option value="bar">Bar Chart</option>
                <option value="wheel_bar">Wheel & Bar Chart</option>
              </Form.Select>
            </Col>
            {isLoading ?
              <div className="p-2">
                <p>Loading...</p>
              </div>
              :
              <Col lg="12">
                <div className="wellness-chart-container py-2 my-2">
                  {selectedChartType == "wheel" &&
                  <>
                    {/* <WheelChart
                      chartData={wellnessAssessData}
                      height={chartHeight}
                      screenSize={screenSize}
                    /> */}
                    <D3Chart
                      chartData={wellnessAssessData}
                    />
                    </>
                  }
                  {selectedChartType == "bar" &&
                    <BarChart
                      chartData={wellnessAssessData}
                      height={chartHeight}
                      screenSize={screenSize}
                    />
                  }
                  {selectedChartType == "wheel_bar" &&
                    <BarWheelChart
                      chartData={wellnessAssessData}
                      height={chartHeight}
                      screenSize={screenSize}
                    />
                  }
                </div>
              </Col>
            }
            
          </Row>
          <Link
            onClick={() => {
              dispatch(setActiveTab("DataAnalysis"));
            }}
          >
            View Comparison Graph
          </Link>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AssessmentCharts);