import React, { useEffect, useRef, useState } from "react";
import {
  Accordion, Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import SymptomBurdonReport from "./Component/SymptomBurdonReport/Index";
import RecommendationReport from "./Component/RecommendationReport/Index";
import AllQuestionAnswers from "./Component/AllQuestionAnswers";
import PriorityReport from "./Component/PriorityReport/Index";
// import LinePlot from "./Component/TestChart";
import { getBodySymptomBurdenGraphV1, getFoundationSymptomBurdenGraph } from "store/services/clientDataReportsService";
import { Link, useParams } from "react-router-dom";

import NAQBodySymptomsGraph from "./Component/NAQBodySymptomsGraph/Index";
import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import * as actions from "store/actions";

import DownloadSBLineGraph from "./Component/PDFReportComponents/SBLineGraphPDF";
import DownloadSBReportLink from "./Component/PDFReportComponents/SBReportPDF";
import SeverityPartOnePDF from "./Component/PDFReportComponents/SeverityPartOnePDF";
import SeverityPartTwoPDF from "./Component/PDFReportComponents/SeverityPartTwoPDF";
import AllQuestionAnswersPDF from "./Component/PDFReportComponents/AllQuestionAnswersPDF";
import DownloadSBBarGraph from "./Component/PDFReportComponents/SBBarGraphPDF";
import DownloadToDoNAQRecommPDF from "./Component/PDFReportComponents/ToDoNAQRecommReportPDF";
import SBComparisionPopup from "./Component/Modals/SBComparisionPopup";
import { setActiveTab } from "store/actions/client";
import SendTodoToClient from "components/shared/SendTodoToClient";

const foundationGroups = [
  {
    "Digestion": [
      "upperGI",
      "liverGallbladder",
      "smallIntestine",
      "largeIntestine",
    ]
  },
  "Blood Sugar Regulation",
  "Stress",
  "Sleep",
  {
    "Nutrient Dense Diet": [
      "mineralNeeds",
      "vitaminNeed",
      "essentialFattyAcids"
    ]
  }
]

const NutritionalAssessmentV1 = ({ dispatch, todoId, todoDetails, formId, formName, submittedOn, client, color }) => {
  const [showSBComparisionPopup, setShowSBComparisionPopup] = useState(false);

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }

  return (
    <Accordion.Item
      eventKey={todoId}
      className=" my-2"
      style={{
        border: `1px solid ${color ? color : "#2B3674"}`,
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#F9FAFF",
          // border: "1px solid #2B3674",
          color: `${color ? color : "#2B3674"}`,
        }}
      >
        <span className="toggleBtn position-absolute"></span>

        <div className="d-flex align-items-center gap-10">
          <span className="icn me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                fill="currentColor"
              />
              <path
                d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                fill="currentColor"
              />
              <path
                d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {formName}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {submittedOn && (
              <>
                {todoDetails?.owned_by !== client.id ? (
                  <span>{`Data entered by you on ${moment(submittedOn).format("MMM DD, YYYY")}`}</span>
                ) : (
                  <span>{`Submitted on ${moment(submittedOn).format("MMM DD, YYYY")}`}</span>
                )}
              </>
            )}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
      >
        <SBComparisionPopup
          handleLinkClick={() => {
            setShowSBComparisionPopup(false);
            window.scrollTo(0, 0);
            dispatch(setActiveTab("DataAnalysis"));
          }}
          showPopup={showSBComparisionPopup}
          handleClose={() => {
            setShowSBComparisionPopup(false);
          }}
        />
        <div className="AccordionInner">
          <div className="top d-flex align-items-start justify-content-between gap-10">
            <div className="left">
            </div>
            <div className="right">
              <div className="d-flex gap-10">
                <SendTodoToClient
                  clientId={client.id}
                  todoId={todoId}
                  todoDetails={todoDetails}
                />
                <Dropdown className="download-pdf-dropdown">
                  <Dropdown.Toggle
                    className=" commonBtn btnSm text-uppercase fw-sbold border pe-5 position-relative"
                    id="dropdown-basic"
                  >
                    <span className="icn me-2">
                      <DownloadIcon />
                    </span>
                    Download Report
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    <Dropdown.Item as={"div"}>
                      <DownloadSBLineGraph
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadSBBarGraph
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <Link
                        onClick={() => {
                          setShowSBComparisionPopup(true);
                        }}
                      >
                        Symptom Burden Comparision Graph
                      </Link>

                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadSBReportLink
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                        label="Symptom Burden Report"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadToDoNAQRecommPDF
                        date={submittedOn}
                        manufacturer={"generic"}
                        client={client}
                        todoId={todoId}
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                        label="Client Recommendation Report"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <SeverityPartOnePDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        label="Severity Report: Part I"
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <SeverityPartOnePDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showNotes={true}
                        label="Severity Report: Part I w/ Notes"
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <SeverityPartTwoPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        label="Severity Report: Part II"
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <SeverityPartTwoPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showNotes={true}
                        label="Severity Report: Part II w/ Notes"
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <AllQuestionAnswersPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                      />
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="inner">
            <Accordion alwaysOpen>
              <NAQBodySymptomsGraph
                eventKey="0"
                todoId={todoId}
              />
              <SymptomBurdonReport
                eventKey="1"
                todoId={todoId}
                submittedOn={submittedOn}
                client={client}
                showLoader={showLoader}
                hideLoader={hideLoader}
              />
              <RecommendationReport
                eventKey="2"
                todoId={todoId}
                submittedOn={submittedOn}
                client={client}
                showLoader={showLoader}
                hideLoader={hideLoader}
              />
              <PriorityReport
                eventKey="3"
                todoId={todoId}
                submittedOn={submittedOn}
                client={client}
                showLoader={showLoader}
                hideLoader={hideLoader}
              />
              <AllQuestionAnswers
                eventKey="4"
                todoId={todoId}
                formId={formId}
              />
            </Accordion>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NutritionalAssessmentV1);