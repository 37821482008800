import { GET_FAQ, ADD_FAQ, VIEW_FAQ, UPDATE_FAQ, DELETE_FAQ } from 'store/actions/faq';

const initialState = {
    isAuthenticated: false,
    faqs: [],
    faq: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getFAQ = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        faqs:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addFAQ = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      faqs:data.body,
    };
    return stateObj;
};

  //update 
const viewFAQ = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      faq:data.body,
    };
    return stateObj;
};

  //update 
const updateFAQ = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      faqs:data.body,
    };
    return stateObj;
};

//delete 
const deleteFAQ = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    faqs:[],
  };
  return stateObj;
};

const FAQ = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_FAQ:
          return getFAQ(state, payload);
        case ADD_FAQ:
            return addFAQ(state, payload);
        case VIEW_FAQ:
          return viewFAQ(state, payload);
        case UPDATE_FAQ:
          return updateFAQ(state, payload);
        case DELETE_FAQ:
          return deleteFAQ(state, payload);
        default:
        return state;
    };
}
export default FAQ;