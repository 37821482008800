//action types
export const GET_MY_FORM = 'GET_MY_FORM';
export const ADD_MY_FORM = 'ADD_MY_FORM';
export const VIEW_MY_FORM = 'VIEW_MY_FORM';
export const UPDATE_MY_FORM = 'UPDATE_MY_FORM';
export const DELETE_MY_FORM = 'DELETE_MY_FORM';
export const TOGGLE_ADD_PAGE_POPUP = "TOGGLE_ADD_PAGE_POPUP";
export const TOGGLE_ADD_QUESTION_POPUP = "TOGGLE_ADD_QUESTION_POPUP";
export const TOGGLE_DELETE_FORM_POPUP = "TOGGLE_DELETE_FORM_POPUP";
export const ADD_NEW_FORM_PAGE = "ADD_NEW_FORM_PAGE";
export const EDIT_FORM_PAGE = "EDIT_FORM_PAGE";
export const DELETE_MY_FORM_PAGE = "DELETE_MY_FORM_PAGE";
export const GET_FORM_QUESTION_TYPES = "GET_FORM_QUESTION_TYPES";
export const ADD_NEW_FORM_QUESTION = "ADD_NEW_FORM_QUESTION";
export const EDIT_FORM_QUESTION = "EDIT_FORM_QUESTION";
export const DELETE_MY_FORM_QUESTION = "DELETE_MY_FORM_QUESTION";
export const CLEAR_FORM_DETAILS_DATA = "CLEAR_FORM_DETAILS_DATA";


//get 
export function getMyForm(data, callback) {
  return {
    type: GET_MY_FORM,
    payload : {data, callback},
  };
}
//add 
export function addMyForm(data, callback) {
  return {
    type: ADD_MY_FORM,
    payload : {data, callback},
  };
}

//view 
export function viewMyForm(data, callback) {
  return {
    type: VIEW_MY_FORM,
    payload : {data, callback},
  };
}
//update 
export function updateMyForm(data, callback) {
    return {
      type: UPDATE_MY_FORM,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteMyForm(payload) {
  return {
    type: DELETE_MY_FORM,
    payload
  };
}

// Add new page
export function addNewFormPage(data, callback) {
  return {
    type: ADD_NEW_FORM_PAGE,
    payload : {data, callback},
  };
}

// Edit page
export function editPage(data, callback) {
  return {
    type: EDIT_FORM_PAGE,
    payload : {data, callback},
  };
}

// delete page
export function deleteMyFormPage(payload) {
  return {
    type: DELETE_MY_FORM_PAGE,
    payload
  };
}

// get form column types
export function getMyFormQuestionTypes(payload) {
  return {
    type: GET_FORM_QUESTION_TYPES,
    payload : payload
  };
}

// Add new question
export function addNewFormQuestion(data, callback) {
  return {
    type: ADD_NEW_FORM_QUESTION,
    payload : {data, callback},
  };
}

// Edit question
export function editFormQuestion(data, callback) {
  return {
    type: EDIT_FORM_QUESTION,
    payload: {data, callback},
  };
}

// Delete question
export function deleteMyFormQuestion(data, callback) {
  return {
    type: DELETE_MY_FORM_QUESTION,
    payload: {data, callback},
  };
}



// toggle add page popup
export function toggleAddPagePopup(payload) {
  return {
    type: TOGGLE_ADD_PAGE_POPUP,
    payload
  };
}

// toggle add page popup
export function toggleAddQuestionPopup(payload) {
  return {
    type: TOGGLE_ADD_QUESTION_POPUP,
    payload
  };
}

// toggle delete form popup
export function toggleDeleteFormPopup(payload) {
  console.log("payload", payload);
  return {
    type: TOGGLE_DELETE_FORM_POPUP,
    payload
  };
}

// clear data for form details page
export function clearFormDetailsData(payload) {
  return {
    type: CLEAR_FORM_DETAILS_DATA,
    payload
  };
}