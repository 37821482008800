import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { addTagsData, getTagsList, updateTagsData } from "store/services/tagsService";

const { default: AddTagPop } = require("../../Component/Modal/AddTagPop");

const AddTagPopupContainer = ({ dispatch, showPopup, closePopup, setShowPopup }) => {
  const [sending, setSending] = useState(false);
  const [colorCode, setColorCode] = useState("");
  const [sateCurrentValue, setSateCurrentValue] = useState(null);
  const [fields, setfields] = useState({
    name: "",
    color_code: "#FFFFFF",
  });

  useEffect(() => {
    if (sateCurrentValue) {
      setfields((prevState) => ({
        ...prevState,
        ...sateCurrentValue,
      }));
      setColorCode(sateCurrentValue.color_code);
    }
    if (sateCurrentValue == null) {
      setfields({});
    }
  }, [sateCurrentValue]);

  const onChangeColorHandleChange = async (color) => {
    setColorCode(color.hex);
  };

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const submitData_ = async (evt) => {
    try {
      evt.preventDefault();
      let obj = {
        name: fields.name,
        color_code: colorCode,
      };
      obj = JSON.stringify(obj);
      setSending(true);
      if (sateCurrentValue) {
        let fieldId = sateCurrentValue.id;
        await dispatch(updateTagsData(fieldId, obj));
      } else {
        await dispatch(addTagsData(obj));
      }
      setfields({});
      setSending(false);
      setSateCurrentValue(null);
      setShowPopup(false);
      getTagsList_();
    } catch (err) {
      console.log(err, "==============================");
      setSending(false);
    }
  };

  async function getTagsList_() {
    try {
      await dispatch(getTagsList());
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AddTagPop
      tagPop={showPopup}
      setTagPop={setShowPopup}
      fields={fields}
      submitData_={submitData_}
      onChangeColorHandleChange={onChangeColorHandleChange}
      handleChange={handleChange}
      colorCode={colorCode}
      sending={sending}
      setSending={setSending}
      sateCurrentValue={sateCurrentValue}
      setSateCurrentValue={setSateCurrentValue}
    />
  )
}

const mapStateToPros = (state) => {
  return state;
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddTagPopupContainer);