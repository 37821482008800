//action types
export const TIMEZONE_LIST = 'TIMEZONE_LIST';
export const UPDATE_SCHEDULER_SETTING = 'UPDATE_SCHEDULER_SETTING';
export const WORKING_HOURS_LIST = 'WORKING_HOURS_LIST';
export const ADD_WORKING_HOURS = 'ADD_WORKING_HOURS';
export const APPOINTMENT_TYPE_LIST = 'APPOINTMENT_TYPE_LIST';
export const APPOINTMENT_TYPE_ADD = 'APPOINTMENT_TYPE_ADD';
export const APPOINTMENT_TYPE_REMOVED = 'APPOINTMENT_TYPE_REMOVED';
export const APPOINTMENT_TYPE_VIEW = 'APPOINTMENT_TYPE_VIEW';
export const APPOINTMENT_CLIENT_LIST = 'APPOINTMENT_CLIENT_LIST';
export const APPOINTMENT_SLOTS = 'APPOINTMENT_SLOTS';
export const APPOINTMENT_BOOK = 'APPOINTMENT_BOOK';
export const SAVE_STEPS_TRACKING_DATA = "SAVE_STEPS_TRACKING_DATA";
export const GET_APPOINTMENT_BOOKING_LIST = "GET_APPOINTMENT_BOOKING_LIST";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const RESCHEDULE_APPOINTMENT = "RESCHEDULE_APPOINTMENT";

//get 
export function timeZoneList(data, callback) {
    return {
      type: TIMEZONE_LIST,
      payload : {data, callback},
    };
}
//update scheduler setting
export function updateSchedulerSetting(data, callback) {
    return {
      type: UPDATE_SCHEDULER_SETTING,
      payload : {data, callback},
    };
}
//working hours list
export function workingHourList(data, callback) {
    return {
      type: WORKING_HOURS_LIST,
      payload : {data, callback},
    };
}

//add working hours 
export function addWorkingHours(data, callback) {
  return {
    type: ADD_WORKING_HOURS,
    payload : {data, callback},
  };
}

//appointment type list
export function appointmentTypeList(data, callback) {
  return {
    type: APPOINTMENT_TYPE_LIST,
    payload : {data, callback},
  };
}

//appointment type add
export function appointmentTypeAdd(data, callback) {
  return {
    type: APPOINTMENT_TYPE_ADD,
    payload : {data, callback},
  };
}
//appointment type remove
export function appointmentTypeView(data, callback) {
  return {
    type: APPOINTMENT_TYPE_VIEW,
    payload : {data, callback},
  };
}
//appointment type remove
export function appointmentTypeRemove(data, callback) {
  return {
    type: APPOINTMENT_TYPE_REMOVED,
    payload : {data, callback},
  };
}

//appointment client list
export function appointmentClientList(data, callback) {
  return {
    type: APPOINTMENT_CLIENT_LIST,
    payload : {data, callback},
  };
}

//appointment slots
export function appointmentSlots(data, callback) {
  return {
    type: APPOINTMENT_SLOTS,
    payload : {data, callback},
  };
}

//appointment book
export function appointmentBook(data, callback) {
  return {
    type: APPOINTMENT_BOOK,
    payload : {data, callback},
  };
}

// save steps tracking data
export function saveStepsTrackingData(data, callback) {
  return {
    type: SAVE_STEPS_TRACKING_DATA,
    payload : {data, callback},
  };
}

// get appointment list
export function appointmentBookingList(data, callback) {
  return {
    type: GET_APPOINTMENT_BOOKING_LIST,
    payload : {data, callback},
  };
}

// cancel appointment 
export function cancelAppointment(data, callback) {
  return {
    type: CANCEL_APPOINTMENT,
    payload : {data, callback},
  };
}
//reschedule
export function rescheduleAppointment(data, callback) {
  return {
    type: RESCHEDULE_APPOINTMENT,
    payload : {data, callback},
  };
}