import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const GetStarted = ({ nextStep, currentStep, handleNext, setStep}) => {
  return (
    <>
      <div
        className="commonContent p-3 gettingStarted"
        style={{ background: "#fffef7", zIndex: "9" }}
      >
        <div className="top py-2 border-bottom">
          <h3 className="m-0 fw-bold py-2 text-uppercase">
            Setting up your Calendar/Appointments
          </h3>
        </div>
        <div className="CardBody py-2">
          <div className="py-2">
            <h4 className="m-0 fw-bold">
              Let's get started setting up your calendar and
              appointments!
            </h4>
            <p className="py-2 m-0">
              First we need a little information to setup your
              calendar and appointments. We'll walk you through
              the steps and don't worry you can always change your
              settings later.
            </p>
            <div className="FormbtnWrpper mt-2">
              <Button
                onClick={() => {
                  handleNext("get_started");
                  setStep('2')
                }}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                Next
                <span className="icn ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.707 6.29318C7.89447 6.48071 7.99979 6.73502 7.99979 7.00018C7.99979 7.26534 7.89447 7.51965 7.707 7.70718L2.05 13.3642C1.95775 13.4597 1.84741 13.5359 1.7254 13.5883C1.6034 13.6407 1.47218 13.6683 1.3394 13.6694C1.20662 13.6706 1.07494 13.6453 0.952046 13.595C0.82915 13.5447 0.717497 13.4705 0.623604 13.3766C0.529712 13.2827 0.455459 13.171 0.405178 13.0481C0.354897 12.9252 0.329595 12.7936 0.330749 12.6608C0.331903 12.528 0.359489 12.3968 0.411898 12.2748C0.464307 12.1528 0.540489 12.0424 0.635999 11.9502L5.586 7.00018L0.635999 2.05018C0.453841 1.86158 0.353047 1.60898 0.355325 1.34678C0.357604 1.08458 0.462772 0.83377 0.648181 0.648362C0.833589 0.462954 1.0844 0.357785 1.3466 0.355507C1.6088 0.353228 1.8614 0.454022 2.05 0.636181L7.707 6.29318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GetStarted;