import { GET_CLIENT, ADD_CLIENT, VIEW_CLIENT, UPDATE_CLIENT, DELETE_CLIENT, GET_CLIENT_CATEGORY_LIST, ADD_UPDATE_FAVORITE, CLIENT_PAGINATION, ASSIGN_TAGS_TO_CLIENT, GET_CLIENT_TODO_DETAILS, SAVE_CLIENT_TODO_RESPONSE, EDIT_TODO_FOR_CLIENT, CREATE_TODO_FOR_CLIENT, DELETE_CLIENT_TODO, GET_CLIENT_TODO_LIST, GET_CLIENT_SETTINGS, UPDATE_CLIENT_SETTINGS, UPDATE_CLIENT_PRIORITIES, ADD_CLIENT_RECOMMENDATION, GET_CLIENT_RECOMMENDATION_DETAILS, UPDATE_CLIENT_RECOMMENDATION_DETAILS, DELETE_CLIENT_RECOMMENDATION, CREATE_RECOMMENDATION_TEMPLATE, GET_RECOMMENDATION_TEMPLATE_LIST, GET_GRAPH_DATA, GET_PACKAGE_INVITAION_LIST, CANCEL_PACKAGE_INVITATION, DELETE_PACKAGE_INVITATION, GET_CLIENT_RECOMMENDATION_REPORTS_LIST, GET_FULLSCRIPT_RECOMMENDATIONS, REMOVE_CLIENT_TAGS, CLEAR_CLIENT_PAGE_DATA, SET_ACTIVE_TAB, GET_CLIENT_ACTION_PLANS_LISTING, GET_FOOD_MOOD_LOGS, TODO_AUTOSAVE_IN_PROGRESS } from 'store/actions/client';

const initialState = {
  isAuthenticated: false,
  clients: [],
  tags: [],
  client: {
    details: {},
    settings: {},
    todoItems: [],
    packageInvitations: [],
    recommendations: {
      data: [],
      totalCount: null,
      page: null,
      limit: null,
      nextPage: null
    },
    recommendationReports: {},
    fullscriptRecommendations: [],
    actionPlans: [],
    foodMoodLogs: {
      comments: [],
      log_data: {},
    },
    todoAutoSaveInProgress: false,
  },
  client_category: [],
  add_favorite: {},
  loader: false,
  currentTodoData: {
    formDetails: {},
    questions: [],
    allQuestionAnswers: [],
  },
  recommendationTemplates: [],
  currentRecommendationData: {},
  graphsData: {
    NAQ: [],
  },
  // currentTodoId: null,
  loadingClientDetails: true,
  activePage: 1,
  totalItemsCount: 0,
  itemsCountPerPage: 10,
  activeTab: "ToDo",
};

//get 
const getClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    clients: data.body?.clients?.data,
    tags: data.body?.tagsAssignedStates,
    itemsCountPerPage: data.body?.clients?.per_page,
    totalItemsCount: data.body?.clients?.total_count
  };
  return stateObj;
};

//add 
const addClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    clients: [
      ...state.clients,
      data.body
    ],
  };
  return stateObj;
};

//update 
const viewClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    client: {
      ...state.client,
      details: data.body,
    },
    loadingClientDetails: false,
  };
  return stateObj;
};

//update 
const updateClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    client: {
      ...state.client,
      details: data.body
    },
  };
  return stateObj;
};

//delete 
const deleteClient = (state, payload) => {
  const { clientId, callback } = payload;
  if (typeof callback === 'function') {
    callback(clientId);
  }

  let clients = state.clients.filter(client => client.id !== clientId);

  const stateObj = {
    ...state,
    isAuthenticated: true,
    clients: clients,
    client: {
      details: {},
      settings: {},
      todoItems: [],
    },
  };
  return stateObj;
};

//get client category
const getClientCategoryList = (state, payload) => {
  const { body, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    client_category: body,
  };
  return stateObj;
};

//add update favorite
const addUpdateFavorite = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    add_favorite: data.body,
  };
  return stateObj;
};
//assign tags to client
const assignTagsToClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const removeTagsFromClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

//client pagination
const clientPagination = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    activePage: data,
  };
  return stateObj;
};

// edit client todo
// const editToDoForClient = (state, payload) => {
//   const { data, callback } = payload;
//   if (typeof callback === 'function') {
//       callback(payload);
//   }
//   const stateObj = {
//       ...state,
//       currentTodoId: data.todoId
//   };
//   return stateObj;
// }

// get client todo details for form filling
const getClientToDoDetails = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }

  let allQuestionAnswers = [];
  if(data.body.questions && data.body.questions.length > 0) {
    for(let i = 0; i < data.body.questions.length; i++){
      let questions = data.body.questions[i]?.questions?.map(q => ({
        id: q.id,
        answer: q.response.client_response && JSON.parse(q.response.client_response).response,
        visible: true,
      }));
      allQuestionAnswers = [...allQuestionAnswers, ...questions];
    }
  }

  const stateObj = {
    ...state,
    currentTodoData: {
      ...data.body,
      allQuestionAnswers
    }
  };
  return stateObj;
};

// save todo form response
const saveClientToDoResponse = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state
  };
  return stateObj;
};

// create a todo for client
const createClientToDo = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  const stateObj = {
    ...state,
    // client: {
    //   ...state.client,
    //   todoItems: [
    //     ...state.client.todoItems,
    //     data.body
    //   ]
    // },
  };
  return stateObj;
};

// delete client todo
const deleteClientToDo = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  // let deletedTodoId = data.todoId;
  // let newTodoItems = state.client.todoItems.filter(todo => todo.id !== deletedTodoId);
  const stateObj = {
    ...state,
    // currentTodoData: initialState.currentTodoData,
    // client: {
    //   ...state.client,
    //   todoItems: newTodoItems
    // },
  };
  return stateObj;
}

// get client todo list
const getClientToDoList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      todoItems: data.body
    }
  };
  return stateObj;
};

// get client settings
function getClientSettings(state, payload) {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  let clientSettings = {};
  data.body.forEach(setting => {
    clientSettings[setting.data_key] = setting.data_set;
  })

  const stateObj = {
    ...state,
    client: {
      ...state.client,
      settings: clientSettings
    }
  };
  return stateObj;
}

// update client settings
function updateClientSettings(state, payload) {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      settings: {
        ...state.client.settings,
        ...data.body
      }
    }
  };
  return stateObj;
}

// get client recommendation list
// const getClientRecommendationList = (state, payload) => {
//   const { data, callback } = payload;
//   if (typeof callback === 'function') {
//     callback(data);
//   }
//   const stateObj = {
//     ...state,
//     client: {
//       ...state.client,
//       recommendations: data.body
//     }
//   };
//   return stateObj;
// };

// get client recommendation reports list
const getClientRecommendationReportsList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      recommendationReports: data.body
    }
  };
  return stateObj;
};

// add client recommendation
function addClientRecommendation(state, payload) {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  return state;
}

// edit client recommendation
const getClientRecommendationDetails = (state, payload) => {
  const { data, callback } = payload;
  console.log("data.body", data.body)
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    currentRecommendationData: data.body
  };
  return stateObj;
};

// update client recommendation
function updateClientRecommendation(state, payload) {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  const stateObj = {
    ...state,
    // currentRecommendationData: data.body,
    currentRecommendationData: {},
  };

  return state;
}

// delete client recommendation
const deleteClientRecommendation = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    currentRecommendationData: {},
  };
  return stateObj;
}

// create recommendation template
function createRecommendationTemplate(state, payload) {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  let newTemplates = [...state.recommendationTemplates];
  newTemplates.push(data.body);

  const stateObj = {
    ...state,
    recommendationTemplates: newTemplates
  };

  return stateObj;
}

// get recommendation template list
const getRecommendationTemplateList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    recommendationTemplates: data.body
  };
  return stateObj;
};

// get package invitation list
const getPackageInvitationList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      packageInvitations: data.body,
    }
  };
  return stateObj;
};

const cancelPackageInvitation = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const updatedInvitaions = state.client.packageInvitations.map(inv => {
    if (inv.id === data.body.id) {
      inv.status = data.body.status;
      inv.cancel_at = data.body.cancel_at;
    }
    return inv;
  });
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      packageInvitations: updatedInvitaions,
    }
  };
  return stateObj;
}

const deletePackageInvitation = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const updatedInvitaions = state.client.packageInvitations.filter(inv => inv.id !== data.invitationId);
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      packageInvitations: updatedInvitaions,
    }
  };
  return stateObj;
}

// get package invitation list
const getFullscriptRecommendations = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      fullscriptRecommendations: data.body,
    }
  };
  return stateObj;
};

// clear client page details
const clearClientPageData = (state) => {
  const stateObj = {
    ...state,
    client: initialState.client,
  };
  return stateObj;
};

// set active tab
const setActiveTab = (state, payload) => {
  const stateObj = {
    ...state,
    activeTab: payload
  }

  return stateObj
}

// client action plans listing
const getClientActionPlans = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      actionPlans: data.body
    }
  }

  return stateObj
}

// client food mood logs
const getClientFoodMoodLogs = (state, payload) => {
  console.log("getClientFoodMoodLogs")
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      foodMoodLogs: data.body
    }
  }

  return stateObj
}

const setToDoAutoSaveState = (state, payload) => {
  const stateObj = {
    ...state,
    client: {
      ...state.client,
      todoAutoSaveInProgress: payload
    }
  }

  return stateObj;
}

const Client = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_CLIENT:
      return getClient(state, payload);
    case ADD_CLIENT:
      return addClient(state, payload);
    case VIEW_CLIENT:
      return viewClient(state, payload);
    case UPDATE_CLIENT:
      return updateClient(state, payload);
    case DELETE_CLIENT:
      return deleteClient(state, payload);
    case GET_CLIENT_CATEGORY_LIST:
      return getClientCategoryList(state, payload);
    case ADD_UPDATE_FAVORITE:
      return addUpdateFavorite(state, payload);
    case CLIENT_PAGINATION:
      return clientPagination(state, payload);
    case ASSIGN_TAGS_TO_CLIENT:
      return assignTagsToClient(state, payload);
    case REMOVE_CLIENT_TAGS:
      return removeTagsFromClient(state, payload);
    case GET_CLIENT_TODO_LIST:
      return getClientToDoList(state, payload);
    // case EDIT_TODO_FOR_CLIENT:
    //   return editToDoForClient(state, payload);
    case GET_CLIENT_TODO_DETAILS:
      return getClientToDoDetails(state, payload);
    case SAVE_CLIENT_TODO_RESPONSE:
      return saveClientToDoResponse(state, payload);
    case CREATE_TODO_FOR_CLIENT:
      return createClientToDo(state, payload);
    case DELETE_CLIENT_TODO:
      return deleteClientToDo(state, payload);
    case GET_CLIENT_SETTINGS:
      return getClientSettings(state, payload);
    case UPDATE_CLIENT_SETTINGS:
      return updateClientSettings(state, payload);
    // case GET_CLIENT_RECOMMENDATION_LIST:
    //   return getClientRecommendationList(state, payload);
    case GET_CLIENT_RECOMMENDATION_REPORTS_LIST:
      return getClientRecommendationReportsList(state, payload);
    case ADD_CLIENT_RECOMMENDATION:
      return addClientRecommendation(state, payload);
    case GET_CLIENT_RECOMMENDATION_DETAILS:
      return getClientRecommendationDetails(state, payload);
    case UPDATE_CLIENT_RECOMMENDATION_DETAILS:
      return updateClientRecommendation(state, payload);
    case DELETE_CLIENT_RECOMMENDATION:
      return deleteClientRecommendation(state, payload);
    case CREATE_RECOMMENDATION_TEMPLATE:
      return createRecommendationTemplate(state, payload);
    case GET_RECOMMENDATION_TEMPLATE_LIST:
      return getRecommendationTemplateList(state, payload);
    case GET_PACKAGE_INVITAION_LIST:
      return getPackageInvitationList(state, payload);
    case CANCEL_PACKAGE_INVITATION:
      return cancelPackageInvitation(state, payload);
    case DELETE_PACKAGE_INVITATION:
      return deletePackageInvitation(state, payload);
    case GET_FULLSCRIPT_RECOMMENDATIONS:
      return getFullscriptRecommendations(state, payload);
    case CLEAR_CLIENT_PAGE_DATA:
      return clearClientPageData(state);
    case SET_ACTIVE_TAB:
      return setActiveTab(state, payload);
    case GET_CLIENT_ACTION_PLANS_LISTING:
      return getClientActionPlans(state, payload);
    case GET_FOOD_MOOD_LOGS:
      return getClientFoodMoodLogs(state, payload);
    case TODO_AUTOSAVE_IN_PROGRESS:
      return setToDoAutoSaveState(state, payload);
    default:
      return state;
  };
}
export default Client;