export const chartMax = 100;
export const chartMaxSuper = chartMax + 40;

export const chartAreaBorderPlugin = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const {ctx, chartArea: {left, top, width, height}} = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
}

export const lineAnnotation = {
  type: 'label',
  xValue: 0,
  xAdjust: 0,
  yValue: 0,
  content: [''],
  font: {
    size: 15
  },
  rotation: 270,
  position: "center",
  color: "#333",
}

export const dangerSignAnnotation = (x, y) => {
  return {
    type: 'label',
    xValue: x,
    yValue: y,
    content: ["!"],
    font: {
      size: 20,
    },
    // rotation: 270,
    position: "center",
    align: "left",
    padding: {
      bottom: 30
    },
    color: "#d9534f"
  }
}

export const chartOptions = {
  layout: {
    padding: {
      left: 40,
      top: 0,
    }
  },
  scales: {
    y: {
      display: false,
      max: chartMax + 10,
    },
    x: {
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    annotation: {
      clip: false,
      annotations: {
        line1: {
          type: 'line',
          yMin: chartMax / 3,
          yMax: chartMax / 3,
          borderColor: '#333',
          borderWidth: 1,
        },
        line2: {
          type: 'line',
          yMin: chartMax - (chartMax / 3),
          yMax: chartMax - (chartMax / 3),
          borderColor: '#333',
          borderWidth: 1,
        },
        line3: {
          type: 'line',
          yMin: chartMax,
          yMax: chartMax,
          borderColor: '#333',
          borderWidth: 1,
        },
      }
    },
  },
}

export const getScreenSize = (defaultSize = "large") => {
  let size = defaultSize;
  if(window.matchMedia("(max-width: 475px)").matches) {
    size = "extra-small";
  } else if(window.matchMedia("(max-width: 850px)").matches) {
    size = "small";
  } else if(window.matchMedia("(max-width: 1150px)").matches) {
    size = "medium";
  } else if(window.matchMedia("(max-width: 1365px)").matches) {
    size = "large";
  }

  return size;
}

export const getChartHeightByScreenSize = (screenSize, defaultHeight = 150) => {
  let height = defaultHeight;
  if(screenSize == "extra-small") {
    height = 550;
  } else if(screenSize == "small") {
    height = 325;
  } else if(screenSize == "medium") {
    height = 225;
  } else if(screenSize == "large") {
    height = 160;
  }
  return height;
}