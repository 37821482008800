import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import CustomBarChart from "./CustomChart";

// icn
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

import { getDataAnalysisGraphsV2 } from "store/services/clientDataReportsService";
import SelectNAQs from "../../SelectNAQs";
import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";
import DownloadComparisionGraph from "./ComparisionGraphPDF";

const Naqv2SymptomGraph = ({ dispatch, naqList, client }) => {
  const { clientId } = useParams();
  const [ foundationsData, setFoundationsData ] = useState(null);
  const [ bodySystemsData, setBodySystemsData ] = useState(null);
  const [ selectedNAQs, setSelectedNAQs ] = useState([]);

  const fsbGraphRef = useRef(null);
  const bsGraphRef = useRef(null);

  useEffect(() => {
    let todoIds = naqList && naqList.map(naq => naq.id);
    if(todoIds) {
      getNAQv2GraphData(todoIds);
    }
  }, [naqList]);

  useEffect(() => {
    if(selectedNAQs && selectedNAQs.length > 1) {
      getNAQv2GraphData(selectedNAQs);
    }
  }, [selectedNAQs]);

  const getNAQv2GraphData = async (todoIds) => {
    try {
      let data = {
        clientId,
        values: {
          todoIds
        }
      } 
      dispatch(actions.persist_store({ loader: true }));
      const response = await getDataAnalysisGraphsV2(data);
      dispatch(actions.persist_store({ loader: false }));
      if( response.success === true &&
        ( response.body?.body_systems?.length && response.body?.foundations?.length )
      ) {
        const { foundations, body_systems: bodySystems } = response.body;
        setFoundationsData(foundations);
        setBodySystemsData(bodySystems);
      }
    } catch(error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeSelectedNAQ = (selected) => {
    setSelectedNAQs(selected);
  }
  
  return (
    <>
      <div className="top d-flex align-items-start justify-content-between gap-10">
        <div className="left">
          <h6 className="m-0 fw-bold">Symptom Burden Comparison Graph</h6>
          <p className="m-0">
            Compare Symptom Burden Graphs from {FULL_NAME(client.first_name, client.last_name)}'s NAQs.
          </p>
        </div>
        <div className="right">
          <DownloadComparisionGraph
            client={client}
            selectedNAQs={naqList && naqList.filter(naq => selectedNAQs.includes(naq.id))}
            chartRef={fsbGraphRef}
            pdfLabel={"Symptom Burden Comparison Graph"}
          />
        </div>
      </div>
      <div className="CardBody p-3 bg-light my-2">
        <SelectNAQs naqList={naqList} onChange={handleChangeSelectedNAQ} />
        <div className="GraphWrpper my-2 py-2">
          <div className="foundation-comparision">
            <div className="top d-flex align-items-start justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">Foundations Comparison</h6>
              </div>
              <div className="right">
                {/* <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                >
                  <span className="icn me-2">
                    <DownloadIcn />
                  </span>
                  Download Report
                </Button> */}
              </div>
            </div>
            <CustomBarChart
              innerRef={fsbGraphRef}
              dataset={foundationsData}
              naqList={naqList}
              selectedNAQs={selectedNAQs}
            />
          </div>
          <div className="body-systems-comparision py-3">
            <div className="top d-flex align-items-start justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">Body Systems Comparison</h6>
              </div>
              <div className="right">
              <DownloadComparisionGraph
                client={client}
                selectedNAQs={naqList && naqList.filter(naq => selectedNAQs.includes(naq.id))}
                chartRef={bsGraphRef}
                pdfLabel={"Body Systems Comparison"}
              />
              </div>
            </div>
            <CustomBarChart
              innerRef={bsGraphRef}
              dataset={bodySystemsData}
              naqList={naqList}
              selectedNAQs={selectedNAQs}
            />
          </div>
        </div>
        <p className="m-0 py-2 text-dark">
          *Looking for all the NAQ questions/answers your client submitted? All
          of the NAQ data is under the{" "}
          <Link to="" className="underline">
            Data & To-Do Items
          </Link>{" "}
          tab.
        </p>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Naqv2SymptomGraph);