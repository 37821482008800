import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";

import "./deletePop.scss";
import { toggleDeleteFormPopup } from "store/actions/myForm";
import { deleteMyFormData } from "store/services/myFormService";
import { SUCCESS_MESSAGE } from "common/constantMessage";

const DeletePopup = ({ dispatch, showPopup, formId, fetchFormList }) => {
  const handleClose = () => dispatch(toggleDeleteFormPopup(false));
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteForm = async () => {
    setIsDeleting(true);
    try {
      if(formId) {
        await dispatch(deleteMyFormData({
          formId
        }));
        setIsDeleting(false);
        SUCCESS_MESSAGE("Form deleted successfully.")
        fetchFormList();
        handleClose();
      }
    } catch(error) {
      console.error("Error deleting form: ", error);
      setIsDeleting(false);
    }
  }

  return (
    <div>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="delete-pop FormPop"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="py-0">
          <div className="text-center commonContent py-2">
            <div className="icn my-3">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256    C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216    c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
                  fill="#f8c886"
                ></path>
                <path
                  d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877    C276,137.831,267.046,128.877,256,128.877z"
                  fill="#f8c886"
                ></path>
                <circle cx="256" cy="349.16" r="27" fill="#f8c886"></circle>{" "}
              </svg>
            </div>
            <h4 className="m-0 py-2 fw-bold">Are You Sure ?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper  border-0">
          <div className="justify-content-center gap-10 d-flex align-items-center w-100">
            <Button
              className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteForm}
              className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
              disabled={isDeleting}
            >
              { isDeleting ? "Deleting..." : "Yes, Delete it" }
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToPros(state) {
  return {
    
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeletePopup);
