import { APPOINTMENT_DATE_FORMAT } from "common/constants";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import { UC_FIRST } from "helpers/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getClientAppointments } from "store/services/clientService";

const ViewAllAppointmentsPopup = ({ clientId, status, showPopup, setShowPopup }) => {
  const limit = 10;
  const [apptList, setApptList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (showPopup) {
      resetAndFetchAppointments();
    }
  }, [showPopup]);

  const resetAndFetchAppointments = () => {
    setApptList([]);
    setPage(1);
    setHasMore(true);
    fetchAppointments(1);
  };

  const fetchAppointments = async (pageToFetch) => {
    try {
      setLoading(true);
      const res = await getClientAppointments({
        id: clientId,
        limit,
        page: pageToFetch,
        status,
      });
      const newAppointments = res?.body?.data || [];
      setApptList((prev) => [...prev, ...newAppointments]);
      
      // Calculate hasMore using total_count and per_page
      const currentPage = pageToFetch;
      const totalPages = res?.body?.total_pages;
      setHasMore(currentPage < totalPages); // hasMore is true if there are more pages

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching appointments:", err);
    }
  };

  const loadMoreAppointments = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchAppointments(nextPage);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <Modal
      show={showPopup}
      onHide={togglePopup}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="FormPop"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{UC_FIRST(status)} Appointments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="commonContent">
          <div className="client-appointment-list">
            {isLoading && apptList.length === 0 ? (
              <LoadingSkeleton itemCount={5} />
            ) : (
              <>
                {apptList?.length > 0 ? (
                  <div className="upcoming-appointments py-2">
                    <ListGroup>
                      {apptList.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Link
                            target="_blank"
                            className="underline"
                            to={`/appointment?apptId=${item.id}`}
                          >
                            {item.title}
                          </Link>
                          <p className="m-0">
                            <span className="date">
                              {item.start_time_tz &&
                                moment(item.start_time_tz).format(APPOINTMENT_DATE_FORMAT)}{" "}
                              to{" "}
                              {item.end_time_tz &&
                                moment(item.end_time_tz).format(APPOINTMENT_DATE_FORMAT)}
                            </span>
                          </p>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {hasMore && (
                      <div className="text-center mt-3">
                        <Button
                          onClick={loadMoreAppointments}
                          className="d-inline-flex btn text-white align-items-center justify-content-center commonBtn btnSm"
                        >
                          {isLoading ? "Loading..." : "Load More"}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <p>No appointments found.</p>
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="gap-10 FormbtnWrpper">
        <Button
          className="d-flex align-items-center justify-content-center commonBtn btnSm"
          onClick={togglePopup}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewAllAppointmentsPopup;
