import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";

const ScheduleLearnMorePop = ({ contentPop, setContentPop }) => {
  const handleContentPop = () => setContentPop(!contentPop);
  return (
    <>
      <Modal
        show={contentPop}
        onHide={handleContentPop}
        backdrop="static"
        keyboard={false}
        scrollable="true"
        centered
        className="contentPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Scheduler Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <div className="py-2">
              <p className="m-0">
                Here's a quick summary of each of the settings. When you click
                the 'edit' link next to each setting you'll also get more
                details on how that particular setting impacts your calendar and
                appointments.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark"> Scheduler ID:</b> This is your unique
                Scheduler ID and identifies your Scheduler (you'll see it on the
                end of your Scheduler URL).
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark"> Scheduler Status:</b> If set to
                'Active' clients can view your scheduler and book appointments.
                If set to 'Disabled' clients cannot view your scheduler or book
                appointments.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Time Zone:</b> This is the time zone
                that is used to display your dates/times (usually it's the time
                zone where you live/work). Don't worry if your clients live in a
                different time zone. They'll see everything displayed in their
                own time zone and we'll take care of all the time conversions.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Display Name:</b> This is the name your
                clients will see at the top of your Scheduler.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Contact Email:</b> This is the email
                where we'll send appointment notifications and if you choose to
                display your contact email (see below) this is the email that
                will be shown.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Contact Email Display:</b> If set to
                'Yes' we'll show the above email on your Scheduler so clients
                can contact you if they have questions. If set to 'No' your
                email will not be shown.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Same Day Appointments:</b> If set to
                'Yes' clients are allowed to schedule appointments on the same
                day they book them (appointments still must be at least 2 hours
                in the future). If set to 'No' then the earliest clients can
                book an appointment is the next day.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Allow Cancellations:</b> Allow client's
                to cancel appointments at any time or only when the appointment
                is not within the next 24 or 48 hours.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Cancellation Policy Text:</b> This text
                is shown to clients when they sign-up for an appointment and
                also when they cancel an appointment.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Working Hours:</b> Days and times
                during the week that you allow people to book appointments with
                you.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Appointment Types:</b> The different
                types of appointments clients can sign up for.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Max Scheduling Date:</b> How far into
                the future clients can schedule an appointment.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">
                  Schedule Exceptions (vacations, holidays, etc):
                </b>{" "}
                Future dates you wish to block so no appointments can be
                scheduled on these dates.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Scheduler URL:</b> The URL that shows
                your Scheduler. Your clients can visit this URL to sign up for
                appointments with you.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Scheduler Link:</b> Code that you can
                put on your personal website that shows a button that links to
                your Scheduler URL.
              </p>
            </div>
            <div className="py-2">
              <p className="m-0">
                <b className="text-dark">Scheduler Embed:</b> Code that you can
                put on your personal website that shows your entire Scheduler
                embedded on your site.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={handleContentPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleLearnMorePop;
