import { FULL_NAME } from "helpers/common";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';

const ChooseClientPopup = ({ showPopup, closePopup, clients, onChooseClient }) => {

  const validation = Yup.object({
    selected_client: Yup.string().required("Please select a client"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await onChooseClient(values.selected_client);
      setSubmitting(false);
      closePopup();
    } catch(error) {
      console.log("Error duplicating recommendation", error);
      setSubmitting(false);
    }
  }

  return (
    <>
    <Formik
      initialValues={{
        selected_client: ""
      }}
      enableReinitialize
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-5 pt-0">
          <Form onSubmit={handleSubmit}>
          <Row className="">
            <Col lg="8" className="my-2">              
              <label htmlFor="" className="form-label m-0 fw-sbold">
                Duplicate this Recommendation for the following client:
              </label>
              <Form.Select
                aria-label="Default select example"
                name="selected_client"
                className={`form-control ${errors.selected_client && "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Select a client</option>
                {clients && clients.length > 0 &&
                  clients.map(client => {
                    return (
                      <option value={client.id}>{`${FULL_NAME(client.first_name, client.last_name)} (${client.email})`}</option>
                    )
                  })
                }
              </Form.Select>
              {errors.selected_client && touched.selected_client && (
                <p className="invalid-feedback">{errors.selected_client}</p>
              )}
            </Col>
            <Col lg="12" className="FormbtnWrpper justify-content-start d-flex gap-10">
              <Button
                type="submit"
                disabled={isSubmitting}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                {isSubmitting ? "Please wait..." : "Submit"}
              </Button>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={closePopup}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          </Form>
        </Modal.Body>
      </Modal>
      )}
      </Formik>
    </>
  );
};



export default ChooseClientPopup;
