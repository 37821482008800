import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcn } from "Assets/Images/icn/delete.svg";

const RadioOptions = ({ columnData, setColumnData, isValid }) => {
  const [fields, setFields] = useState([
    {
      label: "",
    },
    {
      label: "",
    }
  ]);

  useEffect(() => {
    let data = columnData;
    if(data && data.length > 0) {
      const fields = data.map((label, i) => ({
        key: i + 1,
        label,
      }))
      setFields(fields);
    }
  }, [columnData])

  const handleAddField = () => {
    const newFields = [...fields];
    newFields.push({
      key: fields.length + 1,
      label: "",
    })
    setFields(newFields);
  }

  const onInputChange = (e, fieldIndex) => {
    let inputVal = e.target.value;
    let newFields = [...fields];
    newFields[fieldIndex].label = inputVal;
    setFields(newFields);
    processColumnData(newFields);
  }

  const processColumnData = (fields) => {
    let labels = fields.map(field => field.label);
    setColumnData(labels);
  }

  const removeField = (fieldIndex) => {
    let newFields = [...fields];
    newFields.splice(fieldIndex, 1);
    setFields(newFields);
    processColumnData(newFields);
  }

  return (
    <div className={`${!isValid && 'is-invalid'}`}>
      <label
        htmlFor=""
        className="form-label pb-1 m-0"
      >
        Radio Button Options
      </label>
      <span>{` `}(at least 2 buttons are required)</span>
      {fields &&
        fields.map((field, i) => (
          <div
            className="py-2 d-flex align-items-center gap-10"
            key={i}
          >
            <label
              htmlFor=""
              className="form-label pb-1 m-0 fw-sbold text-dark"
            >
              Button {i + 1}:
            </label>
            <input
              type="text"
              className={`form-control w-75 ${!isValid && 'is-invalid'}`}
              value={field.label}
              onChange={(e) => {
                onInputChange(e, i)
              }}
            />
            {i > 1 &&
              <Link
                onClick={() => {
                  removeField(i)
                }}
              >
                <DeleteIcn />
              </Link>
            }
          </div>
        ))
      }
      <Link
        onClick={handleAddField}
      >
        Add Radio Button
      </Link>
    </div>
  )
}

export default RadioOptions;