import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from 'yup';

// css
import "./ToDoPopup.scss";

// icn
import { ReactComponent as OTick } from "../../../../../../../Assets/Images/icn/OrangeTick.svg";
import { FOOD_JOURNAL_FORM_ID, QUESTIONS_PDF_LINK } from "common/constants";
import { connect } from "react-redux";
import TodoAddedPopup from "../TodoAddedPopup";

const AddToDoPop = ({
  toDoPop,
  setToDoPop,
  formList,
  assignToDoToClient,
  clientFullName,
  clientTodoItems,
  client,
}) => {
  const [showToDoAddedPopup, setShowTodoAddedPopup] = useState(false);
  const [todoEmailInviteSent, setTodoEmailInviteSent] = useState(false);

  const handleToDoPopup = () => {
    setToDoPop(!toDoPop);
  };

  const handleCreateTodo = async (values, actions) => {
    try {
      await assignToDoToClient(values, actions);
      setTodoEmailInviteSent(values.send_invite_email === "yes")
      handleToDoPopup();
      setShowTodoAddedPopup(true);
    } catch (error) {
      console.log("Error creating todo", error);
    }
  }

  const closeTodoAddedPopup = () => {
    setShowTodoAddedPopup(false);
    setTodoEmailInviteSent(false);
  }

  const isFormValid = (formId) => {
    let result = {
      valid: true,
      reason: null
    }
    try {
      switch (formId) {
        case FOOD_JOURNAL_FORM_ID:
          if (clientTodoItems?.client_owned && clientTodoItems?.client_owned?.length > 0) {
            let existingFoodMoodJournal = clientTodoItems.client_owned.find(todo => todo.form_id == FOOD_JOURNAL_FORM_ID);
            if (existingFoodMoodJournal) {
              result.valid = false;
              result.reason = "This client already has a Food & Mood Journal in progress";
            }
          }
      }
      return result;
    } catch (err) {
      console.log("Error validating todo form", err);
      return result;
    }
  }

  const isFormDisabled = () => {

  }

  const getFormDisabledReason = () => {

  }

  return (
    <>
      <TodoAddedPopup
        showPopup={showToDoAddedPopup}
        closePopup={closeTodoAddedPopup}
        client={client}
        inviteSent={todoEmailInviteSent}
      />
      <Modal
        show={toDoPop}
        onHide={handleToDoPopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop ToDoPopup"
      >
        <Modal.Header className="border-0 pb-0 px-lg-5">
          <Modal.Title className="fw-sbold">
            Request {clientFullName} Complete a To-Do Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-5 pt-0">
          {client && (
            <p className="m-0 py-2 text-dark fw-sbold">
              {client.is_direct_account === "yes" ? (
                `Check the forms that you'd like your client to fill out. For the email requesting that your client fill out these forms, you'll have the option to choose whether we send it or you send it yourself with the links provided.`
              ) : !client.last_password_updated ? (
                `Check the forms that you'd like your client to fill out. Your client hasn't signed into the site yet, so you'll have the option to choose whether or not we send them an email reminding them to sign up.`
              ) : (
                "Selecting one or more of the below options and clicking 'Submit' will email your client requesting them to complete the selected to-do item(s). Once they complete it we'll email you and the data will appear here."
              )}
            </p>
          )}
          {/* <Link to={QUESTIONS_PDF_LINK} target="_blank" className="fw-sbold underline">
            View what questions are included in each option.
          </Link> */}
          <Form_
            handleSubmit={handleCreateTodo}
            formList={formList}
            handleToDoPopup={handleToDoPopup}
            isFormValid={isFormValid}
            client={client}
          />
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-start border-0 px-lg-5">

        </Modal.Footer>
      </Modal>
    </>
  );
};

const validation = Yup.object({
  selected_form: Yup.array().of(Yup.string()).min(1, "Please select a form").required("Please select a form")
});

const Form_ = ({
  handleSubmit,
  formList,
  handleToDoPopup,
  isFormValid,
  client,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          selected_form: "",
          send_invite_email: "no",
          send_todo_email: "yes",
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <Col lg="12" className="my-2">
                  <ul className="list-unstyled p-0 mb-0 ">
                    {formList && formList.length > 0 &&
                      formList.map(form => {
                        let { valid, reason } = isFormValid(form.id);
                        return (
                          <li key={form.id} className={`d-flex ${!valid ? "form-disabled" : ""}`}>
                            <div className="py-2 d-flex align-items-start gap-10">
                              <div className="cstmInputCheckWrp position-relative checkbox">
                                <input
                                  type="checkbox"
                                  name="selected_form"
                                  className="position-absolute w-100 h-100"
                                  onBlur={handleBlur}
                                  value={form.id}
                                  checked={values.selected_form.includes(form.id.toString())}
                                  onChange={handleChange}
                                  disabled={!valid}
                                />
                                <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                                  <span className="icn d-none ">
                                    <OTick />
                                  </span>
                                </div>
                              </div>
                              <p className={`form-name m-0`}>{form.form_name}</p>
                              {!valid && reason && (
                                <p className="disabled-note m-0">({reason})</p>
                              )}
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                  {errors.selected_form && touched.selected_form && (
                    <p className="invalid-feedback d-block">{errors.selected_form}</p>
                  )}
                </Col>
                {values?.selected_form?.length > 0 && (
                  <>
                    {client?.is_direct_account === "yes" ? (
                      <Col lg="12" className="py-2">
                        <div className="py-2">
                          <h6 className="m-0 fw-sbold">
                            Client Notification
                          </h6>
                          <p className="m-0 py-1">
                            How do you want to notify your client about these To-Do items? We can either send them an email with links to the forms or we can provide the links so you can send them yourself.
                          </p>
                        </div>
                        {client?.email && (
                          <div className="pb-2">
                            <div className="py-1 d-flex align-items-start gap-10">
                              <div className="cstmInputCheckWrp radio position-relative ">
                                <input
                                  className="position-absolute w-100 h-100"
                                  type="radio"
                                  name="send_todo_email"
                                  value="yes"
                                  onChange={(e) =>
                                    setFieldValue("send_todo_email", "yes")
                                  }
                                  checked={values.send_todo_email == "yes"}
                                  style={{ top: "2px" }}
                                />
                                <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                  <span className="icn d-none rounded-circle">
                                    {/* <OTick /> */}
                                  </span>
                                </div>
                              </div>
                              <label
                                htmlFor=""
                                className="form-label theme-clr m-0"
                              >
                                Have Nutri-Q email my client with links to these forms.
                              </label>
                            </div>

                            <div className="py-1 d-flex align-items-start gap-10">
                              <div className="cstmInputCheckWrp radio position-relative ">
                                <input
                                  className="position-absolute w-100 h-100"
                                  type="radio"
                                  name="send_todo_email"
                                  value="no"
                                  onChange={(e) =>
                                    setFieldValue("send_todo_email", "no")
                                  }
                                  checked={values.send_todo_email == "no"}
                                  style={{ top: "2px" }}
                                />
                                <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                  <span className="icn d-none rounded-circle">
                                    {/* <OTick /> */}
                                  </span>
                                </div>
                              </div>
                              <label
                                htmlFor=""
                                className="form-label theme-clr m-0"
                              >
                                I'll send the links myself. Please give me the form links.
                              </label>
                            </div>
                          </div>
                        )}
                      </Col>
                    ):(client && !client.last_password_updated) ? (
                      <Col lg="12" className="py-2">
                        <div className="py-2">
                          <h6 className="m-0 fw-sbold">
                            Client Notification
                          </h6>
                          <p className="m-0 py-1">
                            {client?.email ?
                              "Do you want us to send a reminder to your client to sign up for the site? If not then they won't be emailed in regards to these new To-Do Items."
                              :
                              "We don't have an email for test client no email so they will not be notified about this new To-Do item. You can add an email for this client by going to their 'Info & Settings' tab and clicking on the 'Edit Client Information' button."
                            }
                          </p>
                        </div>
                        {client?.email && (
                          <div className="py-2">
                            <div className="py-1 d-flex align-items-start gap-10">
                              <div className="cstmInputCheckWrp radio position-relative ">
                                <input
                                  className="position-absolute w-100 h-100"
                                  type="radio"
                                  name="send_invite_email"
                                  value="yes"
                                  onChange={(e) =>
                                    setFieldValue("send_invite_email", "yes")
                                  }
                                  checked={values.send_invite_email == "yes"}
                                  style={{ top: "2px" }}
                                />
                                <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                  <span className="icn d-none rounded-circle">
                                    {/* <OTick /> */}
                                  </span>
                                </div>
                              </div>
                              <label
                                htmlFor=""
                                className="form-label theme-clr m-0"
                              >
                                Have Nutri-Q email my client with a reminder to sign up.
                              </label>
                            </div>

                            <div className="py-1 d-flex align-items-start gap-10">
                              <div className="cstmInputCheckWrp radio position-relative ">
                                <input
                                  className="position-absolute w-100 h-100"
                                  type="radio"
                                  name="send_invite_email"
                                  value="no"
                                  onChange={(e) =>
                                    setFieldValue("send_invite_email", "no")
                                  }
                                  checked={values.send_invite_email == "no"}
                                  style={{ top: "2px" }}
                                />
                                <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                  <span className="icn d-none rounded-circle">
                                    {/* <OTick /> */}
                                  </span>
                                </div>
                              </div>
                              <label
                                htmlFor=""
                                className="form-label theme-clr m-0"
                              >
                                Please do not send my client another invite email.
                              </label>
                            </div>
                          </div>
                        )}
                      </Col>
                    ):(
                      ""
                    )}
                  </>
                )}
                <Col lg="12" className="FormbtnWrpper justify-content-start border-0 d-flex gap-10">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="d-flex btn text-white align-items-center justify-content-center commonBtn"
                  >
                    {isSubmitting ? "Please wait..." : "Submit"}
                  </Button>
                  <Button
                    onClick={handleToDoPopup}
                    className="d-flex align-items-center justify-content-center commonBtn"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log("state", state.Client.client);
  return {
    clientTodoItems: state.Client.client.todoItems,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToDoPop);