import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const NewRecommSuccessPopup = ({ showPopup, handleClose, viewRecommendation }) => {

  const handleViewRecommendation = () => {
    viewRecommendation();
    handleClose();
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12" className="">
              <p>Recommendation created successfully</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-start d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleViewRecommendation}
          >
            View Recommendation
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewRecommSuccessPopup;
