import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";
import moment from "moment";
import { createRef, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import PDFMerger from 'pdf-merger-js/browser';
import { pdf } from "@react-pdf/renderer";

import { getClientRecommendationReportsList, getRecommendationReportDetails } from "store/services/clientService";
import * as actions from "store/actions";


import { FOOD_JOURNAL_FORM_ID, HEALTH_ASSESSMENT_FORM_ID, NAQV1_ID, NAQV2_ID, RECOMMENDATION_FORM_ID } from "common/constants";
import { ComparisonLineChartPDF, LineChartPDF } from "./Components/NAQGraphs";
import { ConverPDFDoc } from "components/shared/PDFComponents/CoverPage";
import { MyDoc as RecommendationTemplate } from "components/shared/PDFComponents/Recommendations/RecommendationReportPDF";
import { MyDoc as AllQuestionAnswersTemplate, getTodoDetails, getNotes } from "components/shared/PDFComponents/AllQuestionAnswers/Index";
import { MyDoc as SymptomBurdenGraph } from "../AccordionTabs/NutritionalAssessmentV1/Component/PDFReportComponents/SBLineGraphPDF";
import { MyDoc as SymptomBurdenReport, getSymptomBurdenData } from "../AccordionTabs/NutritionalAssessmentV1/Component/PDFReportComponents/SBReportPDF";
import { MyDoc as NAQRecommendationReport, getRecommendationReportData } from "../AccordionTabs/NutritionalAssessmentV1/Component/PDFReportComponents/ToDoNAQRecommReportPDF";
import { MyDoc as SeverityReportPartOne, getPriorityReportData } from "../AccordionTabs/NutritionalAssessmentV1/Component/PDFReportComponents/SeverityPartOnePDF";
import { MyDoc as SeverityReportPartTwo } from "../AccordionTabs/NutritionalAssessmentV1/Component/PDFReportComponents/SeverityPartTwoPDF";
import { MyDoc as NAQQuestionsAnswers, getNAQTodoDetails } from "../AccordionTabs/NutritionalAssessmentV1/Component/PDFReportComponents/AllQuestionAnswersPDF";
import { MyDoc as SBComparisionGraph } from "../../../../../DataAnalysis/NAQv1/Component/TabContent/NAQGraph/Components/ComparisionGraphPDF";
import { MyDoc as FoodMoodJournalPdf, getFoodMoodJournalData } from "components/shared/PDFComponents/FoodMoodJournal/FoodMoodJournalPdf";
import { getCompletedTodoListV1, getCompletedTodoListV2 } from "store/services/clientDataReportsService";
import { NAQ1SBComparison, getNAQReportsData } from "components/shared/PDFComponents/NAQv1DataAnalysis/SBCompReportPDF";
import HealthWellnessGraphs from "./Components/HealthWellnessGraphs";
import { MyDoc as HealthWellnessQAPdf } from "components/shared/PDFComponents/HealthWellnessQAPdf";
import { BodySymptomGraphPdf, FoundationSymptomGraphPdf, NAQv2ComparisionGraphPDF } from "./Components/NAQv2Graphs";
import html2canvas from "html2canvas";
import { FoundationSymptomGraphDoc } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/FoundationSymptomGraphPdf";
import { BodySymptomGraphDoc } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/BodySymptomGraphPdf";
import { SBReportDoc, getNAQv2SymptomBurdenData } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/SBReportPDF";
import { NAQ2RecommendationReportDoc, getNAQ2RecommendationReportData } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/ToDoNAQRecommReportPDF";
import { NAQ2PriorityReportDoc, getNAQv2PriorityReportData } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/PriorityReportPDF";
import { NAQ2PriorityReportSBGDoc } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/PriorityReportSBGPDF";
import { NAQ2QeuAnsDoc, getNAQv2TodoDetails } from "../AccordionTabs/NutritionalAssessmentV2/Component/PDFReportComponents/AllQuestionAnswersPDF";
import { NAQv2ComparisionGraphDoc } from "../../../../../DataAnalysis/NAQv2/Component/TabContent/NAQGraph/ComparisionGraphPDF";
import { NAQ2SBComparison, getNAQv2SBReportsData } from "../../../../../DataAnalysis/NAQv2/Component/TabContent/NAQReport/Components/SBCompReportPDF";
import { NAQ2PriorityCompDoc, getNAQv2PriorityReportCompData } from "../../../../../DataAnalysis/NAQv2/Component/TabContent/PriorityComparsionReport/Components/PriorityCompReportPDF";

const NAQ_COMPONENTS = [
  "Symptom Burden Graph",
  "Symptom Burden Report",
  "Recommendation Report",
  "Severity Report Part I",
  "Severity Report Part II",
  "Questions/Answers",
]

const NAQ2_COMPONENTS = [
  "Foundations Symptom Burden Graph",
  "Body Systems Symptom Burden Graph",
  "Symptom Burden Report",
  "Recommendation Report",
  "Priority Report",
  "Priority Report: SBG",
  "Questions/Answers",
  // "Symptom Burden Comparison Graph",
  // "Symptom Burden Comparison Report",
  // "Priority Comparison Report",
]

const MultiReportDownloadOptions = ({ dispatch, completedToDos, activeRecommendations, client }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedNAQs, setSelectedNAQs] = useState(null);
  const [selectedNAQv2Forms, setSelectedNAQv2Forms] = useState(null);
  const [selectedHealthAssessForms, setSelectedHealthAssessForms] = useState(null);
  const [selNAQsForAnalysis, setSelNAQsForAnalysis] = useState(null);
  const [selNAQv2FormsForAnalysis, setSelNAQv2FormsForAnalysis] = useState(null);
  const [selectedNAQComponents, setselectedNAQComponents] = useState([]);
  const [selectedNAQv2Components, setselectedNAQv2Components] = useState([]);
  const [includeReportCover, setIncludeReportCover] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [healthAssesmentRefs, setHealthAssesmentRefs] = useState(null); // Health Coach Form Graph Refs
  const [naqv2Refs, setNAQv2Refs] = useState(null); // NAQv2 Graph Refs

  // naq refs
  const chartRef = useRef(null);
  const comparisonChartRef = useRef(null);

  // naqv2 refs
  const fsbGraphRef = useRef(null);
  const bsGraphRef = useRef(null);

  useEffect(() => {
    getClientRecommendations();
  }, [])

  useEffect(() => {
    let naqs = [];
    let naqV2Forms = [];
    let healthAssessmentForms = [];
    selectedItems.forEach(item => {
      if(item.type == "todo") {
        let todoDetails = completedToDos.find(todo => todo.id == item.id);
        if(todoDetails.form_id == NAQV1_ID) {
          naqs.push(item);
        } else if(todoDetails.form_id == NAQV2_ID) {
          naqV2Forms.push(item);
        } else if(todoDetails.form_id == HEALTH_ASSESSMENT_FORM_ID) {
          healthAssessmentForms.push(item);
        }
      }
    })

    setSelectedNAQs(naqs);
    setSelectedNAQv2Forms(naqV2Forms);
    setSelectedHealthAssessForms(healthAssessmentForms);
    setSelNAQsForAnalysis(naqs && naqs?.map(naq => naq.id));
    setSelNAQv2FormsForAnalysis(naqV2Forms && naqV2Forms?.map(naq2 => naq2.id));
  }, [selectedItems])

  useEffect(() => {
    dispatch(actions.persist_store({ loader: isLoading }));
  }, [isLoading])

  useEffect(() => {
    if(selectedHealthAssessForms) {
      const refs = selectedHealthAssessForms.map((todo) => ({
        todoId: todo.id,
        ref: createRef()
      }));
      setHealthAssesmentRefs(refs);
    }
  }, [selectedHealthAssessForms]);

  useEffect(() => {
    if(selectedNAQv2Forms) {
      const refs = selectedNAQv2Forms.map((todo) => ({
        todoId: todo.id,
        fsbGraphRef: createRef(),
        bsGraphRef: createRef(),
      }));
      setNAQv2Refs(refs);
    }
  }, [selectedNAQv2Forms]);

  const handleSelectItem = (e, type) => {
    let itemId = e.target.value;
    let newSelectedItems = [...selectedItems];
    let existingItemIdx = newSelectedItems.findIndex(item => item.type == type && item.id == itemId);
    if(existingItemIdx !== -1) {
      newSelectedItems.splice(existingItemIdx, 1);
    } else {
      let newItem = {
        type,
        id: itemId
      }
      newSelectedItems.push(newItem);
    }

    setSelectedItems(newSelectedItems);
  }

  const handleSelectNAQComponent = (e) => {
    let componentName = e.target.value;
    let newSelectedComponents = [...selectedNAQComponents];
    let existingCompoIdx = newSelectedComponents.findIndex(component => component == componentName);
    if(existingCompoIdx !== -1) {
      newSelectedComponents.splice(existingCompoIdx, 1);
    } else {
      newSelectedComponents.push(componentName);
    }

    setselectedNAQComponents(newSelectedComponents);
  }

  const handleSelectNAQ2Component = (e) => {
    let componentName = e.target.value;
    let newSelectedComponents = [...selectedNAQv2Components];
    let existingCompoIdx = newSelectedComponents.findIndex(component => component == componentName);
    if(existingCompoIdx !== -1) {
      newSelectedComponents.splice(existingCompoIdx, 1);
    } else {
      newSelectedComponents.push(componentName);
    }

    setselectedNAQv2Components(newSelectedComponents);
  }

  const getClientRecommendations = async () => {
    try {
      let data = {
        clientId: client.id
      }
      setLoading(true);
      await dispatch(getClientRecommendationReportsList(data));
      setLoading(false);
    } catch(error) {
      console.log("Error fetching recommendations", error);
      setLoading(false);
    }
  }

  const downloadCombinedPDF = async () => {
    try {
      setLoading(true);
      let blobs = [];

      /**
       * If include cover is checked then add the cover page to the combined pdf
       */
      if(includeReportCover) {
        const doc = 
          <ConverPDFDoc 
            client={client}
        />

        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        blobs.push(blob);
      }

      /**
       * Iterate over all selected items and generate PDFs for all 
       */
      let itemBlobs = await Promise.all(selectedItems.map(async (item) => {
        try {
          
          /**
           * Generate PDF for ToDo Items
           */
          if(item.type == "todo")
          {
            let formDetails = completedToDos && completedToDos.find(todo => todo.id == item.id);
            if(!formDetails) {
              return null;
            }
            
            /**
             * If selected ToDo is for a NAQ, then generate the PDF for the selected NAQ components
             */
            if(formDetails.form_id == NAQV1_ID) {
              if(!selectedNAQComponents || selectedNAQComponents.length == 0) {
                return null;
              }

              let naqCombinedBlob = await getNAQv1ComponentBlobs(item, formDetails);
              return naqCombinedBlob;
            }

            if(formDetails.form_id == NAQV2_ID) {
              if(!selectedNAQv2Components || selectedNAQv2Components.length == 0) {
                return null;
              }

              let naq2CombinedBlob = await getNAQv2ComponentBlobs(item, formDetails);
              return naq2CombinedBlob;
            }

            /**
             * If selected ToDo is for a HealthCoachWellnessForm
             */
            if(formDetails.form_id == HEALTH_ASSESSMENT_FORM_ID) {
              let currentTodoRef = healthAssesmentRefs?.find(ref => ref.todoId == item.id);
              currentTodoRef = currentTodoRef?.ref;
              if(!currentTodoRef || !currentTodoRef.current) {
                return null;
              }

              let {barChartImage, wheelChartImage} = await currentTodoRef.current.downloadChartImage();
              const todoDetails = await getTodoDetails(item.id, client);
              const notesData = await getNotes(item.id);

              // wheelChartRef.current.downloadChartImage(async (wheelChartImage) => {
              const doc =
              <HealthWellnessQAPdf
                client={client}
                date={formDetails.updated_at}
                todoDetails={todoDetails}
                pageTitle={formDetails?.form_name}
                notesData={notesData || []}
                showNotes={true}
                barChartImage={barChartImage}
                wheelChartImage={wheelChartImage}
              />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              return blob;
            }

            /**
             * If select ToDo is an recommendation then download pdf for recommendation
             * Note: Need to check if it's being used, there is a new block for generating
             * the recommendation pdf reports
             */
            if(formDetails.form_id == RECOMMENDATION_FORM_ID) {
              let recommendationDetails = await getRecommendationDetails(item.id);
              if(!recommendationDetails) {
                return null;
              }

              // parse the recommendation JSON
              if(recommendationDetails?.recommendation) {
                recommendationDetails.recommendation = JSON.parse(recommendationDetails.recommendation);
              }
              if(recommendationDetails?.additional_recommendation) {
                recommendationDetails.additional_recommendation = JSON.parse(recommendationDetails.additional_recommendation);
              }
  
              let rows = recommendationDetails.recommendation;
              let additional_recommendation = recommendationDetails.additional_recommendation;
              
              const doc = 
                <RecommendationTemplate 
                  nutritionalRows={rows.nutritional}
                  lifestyleRows={rows.lifestyle}
                  details={recommendationDetails}
                  additionalDetails={additional_recommendation}
                  client={client}
                  date={recommendationDetails.created_at}
              />
  
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              return blob;
  
            }

            if(formDetails.form_id == FOOD_JOURNAL_FORM_ID) {
              const journalDetails = await getFoodMoodJournalData(item.id, client);
              const doc = <FoodMoodJournalPdf
                client={client}
                date={formDetails.updated_at}
                journalDetails={journalDetails}
                showNotes={true}
              />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              return blob;
            }

            /**
             * If ToDo is for any form other than the above forms, then generate the All Questions/Answers PDF
             */
            else
            {
              let todoDetails = await getTodoDetails(item.id, client);
              let notesData = await getNotes(item.id);

              if(!todoDetails) {
                return null;
              }

              const doc = 
              <AllQuestionAnswersTemplate
                client={client}
                date={formDetails.updated_at}
                todoDetails={todoDetails}
                pageTitle={formDetails.form_name}
                showNotes={true}
                notesData={notesData}
              />

              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              return blob;
            }
          }

          /**
           * Generate PDF for recommendations
           */
          else if(item.type == "recommendation") {
            let recommendationDetails = await getRecommendationDetails(item.id);
              if(!recommendationDetails) {
                return null;
              }

              // parse the recommendation JSON
              if(recommendationDetails?.recommendation) {
                recommendationDetails.recommendation = JSON.parse(recommendationDetails.recommendation);
              }
              if(recommendationDetails?.additional_recommendation) {
                recommendationDetails.additional_recommendation = JSON.parse(recommendationDetails.additional_recommendation);
              }
  
              let rows = recommendationDetails.recommendation;
              let additional_recommendation = recommendationDetails.additional_recommendation;
              
              const doc = 
                <RecommendationTemplate 
                  nutritionalRows={rows.nutritional}
                  lifestyleRows={rows.lifestyle}
                  supplementRows={rows.supplement}
                  details={recommendationDetails}
                  additionalDetails={additional_recommendation}
                  client={client}
                  date={recommendationDetails.created_at}
                />
  
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              return blob;
          }

          return null;
        } catch(err) {
          console.log("Failed to generate PDF for this item", err, item);
          return null;
        }
      }))

      /**
       * NAQ comparison Graph blob
       */
      if(selectedNAQs && selectedNAQs.length > 1) {
        if(selectedNAQComponents.includes("Symptom Burden Comparison Graph")) {
          const comparisionGraphBlob = await getNAQComparisonGraph();
          if(comparisionGraphBlob) {
            blobs.push(comparisionGraphBlob);
          }
        }
        
        if(selectedNAQComponents.includes("Symptom Burden Comparison Report")) {
          const comparisionReport = await getNAQComparisonReport();
          if(comparisionReport) {
            blobs.push(comparisionReport);
          }
        }
      }

      /**
       * NAQ v2 Comparision reports blob
       */
      if(selectedNAQv2Forms && selectedNAQv2Forms.length > 1) {
        if(selectedNAQv2Components.includes("Symptom Burden Comparison Graph")) {
          const comparisionGraphBlobs = await getNAQv2ComparisonGraph();
          if(comparisionGraphBlobs && comparisionGraphBlobs.length > 0) {
            blobs = [...blobs, ...comparisionGraphBlobs];
          }
        }
        
        if(selectedNAQv2Components.includes("Symptom Burden Comparison Report")) {
          const comparisionReport = await getNAQv2SBComparisonReport();
          if(comparisionReport) {
            blobs.push(comparisionReport);
          }
        }

        if(selectedNAQv2Components.includes("Priority Comparison Report")) {
          const priorityCompReport = await getNAQv2PriorityCompReport();
          if(priorityCompReport) {
            blobs.push(priorityCompReport);
          }
        }
      }

      blobs = [...blobs, ...itemBlobs ];

      blobs = blobs.filter(blob => !!blob);
      
      let combinedBlob = await combineBlobs(blobs);

      saveAs(combinedBlob, `${client.first_name}_${client.last_name}.pdf`);
      
      setLoading(false);
    } catch(err) {
      console.log("Error generating combined PDF", err);
      setLoading(false);
    }
  }

  const combineBlobs = async (blobs) => {
    const merger = new PDFMerger();

    for(const blob of blobs) {
      await merger.add(blob);
    }

    await merger.setMetadata({
      producer: "pdf-merger-js based script"
    });

    const mergedBlob = await merger.saveAsBlob();
    return mergedBlob;
  }

  /**
   * Function to generate the PDFs for selected NAQ Components
   */
  const getNAQv1ComponentBlobs = async (item, todoFormDetails) => {
    let blobs = await Promise.all(selectedNAQComponents.map( async (componentName) => {
      try {
        /**
         * Generate PDF for SB Graph
         */
        if(componentName == "Symptom Burden Graph")
        {
          const base64Image = chartRef.current && chartRef.current.downloadChartImage();
          const doc = 
          <SymptomBurdenGraph
            base64Image={base64Image}
            client={client}
            date={todoFormDetails.updated_at}
          />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }
        /**
         * Generate PDF for SB Report
         */
        else if(componentName == "Symptom Burden Report")
        {
          const symptomBurdernData = await getSymptomBurdenData(item.id, client);
          if(!symptomBurdernData) {
            return null;
          }
          let totalSymptomBurden = 0;
          symptomBurdernData.potential_conditions.map((condition) => {
            if (condition.conditionScore) {
              totalSymptomBurden += condition.conditionScore
            }
          })
          symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
            if (deficiency.conditionScore) {
              totalSymptomBurden += deficiency.conditionScore
            }
          })

          const doc =
            <SymptomBurdenReport
              symptomBurdernData={symptomBurdernData}
              totalSymptomBurden={totalSymptomBurden}
              client={client}
              date={todoFormDetails.updated_at}
            />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for NAQ Recommendation
         */
        else if(componentName == "Recommendation Report")
        {
          let manufacturer = "generic";
          const recommendationData = await getRecommendationReportData(item.id, client);
          if(!recommendationData) {
            return null;
          }
          let nutritionalRows = recommendationData && recommendationData[manufacturer];
          const doc = 
            <NAQRecommendationReport
              nutritionalRows={nutritionalRows}
              manufacturer={manufacturer}
              client={client}
              date={todoFormDetails.updated_at}
            />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for Severity Report Part I
         */
        else if(componentName == "Severity Report Part I")
        {
          const priorityReport = await getPriorityReportData(item.id, client);
          const todoNotes = await getNotes(item.id);
          const doc =
            <SeverityReportPartOne
              priorityReport={priorityReport?.part1 || []}
              showNotes={true}
              notes={todoNotes}
              client={client}
              date={todoFormDetails.updated_at}
            />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for Severity Report Part II
         */
        else if(componentName == "Severity Report Part II")
        {
          const priorityReport = await getPriorityReportData(item.id, client);
          const todoNotes = await getNotes(item.id);
          const doc =
            <SeverityReportPartTwo
              priorityReport={priorityReport?.part2 || []}
              showNotes={true}
              notes={todoNotes}
              client={client}
              date={todoFormDetails.updated_at}
            />;
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for NAQ All Questions/Answers with scores
         */
        else if(componentName == "Questions/Answers")
        {
          const todoDetails = await getNAQTodoDetails(item.id, client);
          const doc =
            <NAQQuestionsAnswers
              client={client}
              date={todoFormDetails.updated_at}
              todoDetails={todoDetails}
            />;
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        } else {
          return null;
        }

      } catch(err) {
        console.log("Error generating NAQ component PDF", err, componentName)
        return null;
      }
    }));

    blobs = blobs.filter(blob => !!blob);

    if(blobs.length == 0) {
      return null;
    }

    let combinedNAQBlobs = await combineBlobs(blobs);
    return combinedNAQBlobs;
  }

  /**
   * Function to generate the PDFs for selected NAQ Components
   */
  const getNAQv2ComponentBlobs = async (item, todoFormDetails) => {
    let blobs = await Promise.all(selectedNAQv2Components.map( async (componentName) => {
      try {
        /**
         * Generate PDF for FSB Graph
         */
        if(componentName == "Foundations Symptom Burden Graph")
        {
          let currentToDoRef = naqv2Refs?.find(ref => ref.todoId == item.id);
          let fsbGraphRef = currentToDoRef?.fsbGraphRef;
          if(!fsbGraphRef.current) {
            return null;
          }
          const canvas = await html2canvas(fsbGraphRef.current, { scale: 3 })
          const base64Image = canvas.toDataURL('image/png');
          const doc = 
          <FoundationSymptomGraphDoc
            base64Image={base64Image}
            client={client}
            date={todoFormDetails.updated_at}
          />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for BS Graph
         */
        else if(componentName == "Body Systems Symptom Burden Graph")
        {
          let currentToDoRef = naqv2Refs?.find(ref => ref.todoId == item.id);
          let bsGraphRef = currentToDoRef?.bsGraphRef;
          if(!bsGraphRef.current) {
            return null;
          }
          const canvas = await html2canvas(bsGraphRef.current, { scale: 3 })
          const base64Image = canvas.toDataURL('image/png');
          const doc = 
          <BodySymptomGraphDoc
            base64Image={base64Image}
            client={client}
            date={todoFormDetails.updated_at}
          />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for SB Report
         */
        else if(componentName == "Symptom Burden Report")
        {
          const symptomBurdernData = await getNAQv2SymptomBurdenData(item.id, client);
          let totalSymptomBurden = 0;
          symptomBurdernData.potential_conditions.map((condition) => {
            if (condition.conditionScore) {
              totalSymptomBurden += parseInt(condition.conditionScore)
            }
          })
          symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
            if (deficiency.conditionScore) {
              totalSymptomBurden += parseInt(deficiency.conditionScore)
            }
          })

          const doc = 
            <SBReportDoc
              symptomBurdernData={symptomBurdernData}
              totalSymptomBurden={totalSymptomBurden}
              client={client}
              date={todoFormDetails.updated_at}
            />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for NAQv2 Recommendation
         */
        else if(componentName == "Recommendation Report")
        {
          let manufacturer = "generic";
          const recommendationData = await getNAQ2RecommendationReportData(item.id, client);
          if(!recommendationData) {
            return null;
          }
          let nutritionalRows = recommendationData && recommendationData[manufacturer];
          const doc = 
            <NAQ2RecommendationReportDoc
              nutritionalRows={nutritionalRows}
              manufacturer={manufacturer}
              topPotentialSupports={recommendationData?.topPotentialSupports}
              client={client}
              date={todoFormDetails.updated_at}
            />
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for Priority Report
         */
        else if(componentName == "Priority Report")
        {
          const type = "questionnaireo";
          const priorityReport = await getNAQv2PriorityReportData(item.id, client);
          const todoNotes = await getNotes(item.id);
          const doc =
            <NAQ2PriorityReportDoc
              priorityReport={priorityReport[type] || []}
              notes={todoNotes || []}
              client={client}
              date={todoFormDetails.updated_at}
              showNotes={true}
            />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for Priority Report: SBG
         */
        else if(componentName == "Priority Report: SBG")
        {
          const type = "SBG";
          const priorityReport = await getNAQv2PriorityReportData(item.id, client);
          const todoNotes = await getNotes(item.id);
          const doc =
            <NAQ2PriorityReportSBGDoc
              priorityReport={priorityReport[type] || []}
              notes={todoNotes || []}
              client={client}
              date={todoFormDetails.updated_at}
              showNotes={true}
            />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for All Questions/Answers
         */
        else if(componentName == "Questions/Answers")
        {
          const todoDetails = await getNAQv2TodoDetails(item.id, client);
          const doc = 
            <NAQ2QeuAnsDoc
              client={client}
              date={todoFormDetails.updated_at}
              todoDetails={todoDetails}
            />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        }

        /**
         * Generate PDF for Severity Report Part II
         */
        // else if(componentName == "Severity Report Part II")
        // {
        //   const priorityReport = await getPriorityReportData(item.id, client);
        //   const todoNotes = await getNotes(item.id);
        //   const doc =
        //     <SeverityReportPartTwo
        //       priorityReport={priorityReport?.part2 || []}
        //       showNotes={true}
        //       notes={todoNotes}
        //       client={client}
        //       date={todoFormDetails.updated_at}
        //     />;
        //   const asPdf = pdf([]);
        //   asPdf.updateContainer(doc);
        //   const blob = await asPdf.toBlob();
        //   return blob;
        // }

        /**
         * Generate PDF for NAQ All Questions/Answers with scores
         */
        // else if(componentName == "Questions/Answers")
        // {
        //   const todoDetails = await getNAQTodoDetails(item.id, client);
        //   const doc =
        //     <NAQQuestionsAnswers
        //       client={client}
        //       date={todoFormDetails.updated_at}
        //       todoDetails={todoDetails}
        //     />;
        //   const asPdf = pdf([]);
        //   asPdf.updateContainer(doc);
        //   const blob = await asPdf.toBlob();
        //   return blob;
        // } 
        
        else
        {
          return null;
        }

      } catch(err) {
        console.log("Error generating NAQ component PDF", err, componentName)
        return null;
      }
    }));

    blobs = blobs.filter(blob => !!blob);

    if(blobs.length == 0) {
      return null;
    }

    let combinedNAQBlobs = await combineBlobs(blobs);
    return combinedNAQBlobs;
  }

  /**
   * Get NAQ v1 Comparison graph blob
   */
  const getNAQComparisonGraph = async () => {
    try {
      const naqList = await getCompletedTodoListV1({clientId: client.id});
      let selectedNAQs = naqList?.body?.filter(naq => selNAQsForAnalysis.includes(naq?.id?.toString()))
      const base64Image = comparisonChartRef.current.toBase64Image('image/png', 1);
      const doc =
        <SBComparisionGraph
          base64Image={base64Image}
          client={client}
          selectedNAQs={selectedNAQs}
        />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    } catch(err) {
      console.log("Error generating comparision graph pdf", err);
      return null;
    }
  }

  /**
   * Get NAQ v1 Comparison report blob
   */
  const getNAQComparisonReport = async () => {
    try {
      let naqList = await getCompletedTodoListV1({clientId: client.id});
      naqList = naqList.body;
      const symptomBurdernData = await getNAQReportsData(client.id, selNAQsForAnalysis);
      let totalSymptomBurdenOld = 0;
      let totalSymptomBurdenNew = 0;
      symptomBurdernData.condition[0].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenNew += condition.conditionScore
        }
      })
      symptomBurdernData.condition[1].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenOld += condition.conditionScore
        }
      })
      symptomBurdernData.nutritional[0].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenNew += deficiency.conditionScore
        }
      })
      symptomBurdernData.nutritional[1].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenOld += deficiency.conditionScore
        }
      })

      let colors = [];
      colors.push(naqList.find(naq => naq.id == symptomBurdernData.condition[0].to_do_id)?.color);
      colors.push(naqList.find(naq => naq.id == symptomBurdernData.condition[1].to_do_id)?.color);

      const doc = 
        <NAQ1SBComparison
          symptomBurdernData={symptomBurdernData}
          totalSymptomBurdenNew={totalSymptomBurdenNew}
          totalSymptomBurdenOld={totalSymptomBurdenOld}
          client={client}
          colors={colors}
        />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    } catch(err) {
      console.log("Error generating comparision graph pdf", err);
      return null;
    }
  }

  const getRecommendationDetails = async (id) => {
    try {
      let response = await getRecommendationReportDetails({recommendationId: id});
      if (response.success === true && response.body) {
        return response.body;
      }
      return null
    } catch(err) {
      console.log("Failed to get recommendation details", err);
      return null;
    }
  }

  /**
   * Get NAQ v2 Comparison graph blob
   */
  const getNAQv2ComparisonGraph = async () => {
    try {
      const blobs = [];
      const naqList = await getCompletedTodoListV2({clientId: client.id});
      let selectedNAQs = naqList?.body?.filter(naq => selNAQv2FormsForAnalysis.includes(naq?.id?.toString()))

      /**
       * FSB Comparision Graph
       */
      if(fsbGraphRef?.current) {
        const canvas = await html2canvas(fsbGraphRef?.current, { scale: 3 })
        const base64Image = canvas.toDataURL('image/png');
        const doc =
          <NAQv2ComparisionGraphDoc
            base64Image={base64Image}
            client={client}
            selectedNAQs={selectedNAQs}
            pdfLabel={"Symptom Burden Comparison Graph"}
          />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        blobs.push(blob);
      }

      /**
       * SB Comparision Graph
       */
      if(bsGraphRef?.current) {
        const canvas = await html2canvas(bsGraphRef?.current, { scale: 3 })
        const base64Image = canvas.toDataURL('image/png');
        const doc =
          <NAQv2ComparisionGraphDoc
            base64Image={base64Image}
            client={client}
            selectedNAQs={selectedNAQs}
            pdfLabel={"Body Systems Comparison"}
          />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        blobs.push(blob);
      }
      
      return blobs;
    } catch(err) {
      console.log("Error generating comparision graph pdf", err);
      return null;
    }
  }

  /**
   * Get NAQ v2 SB Comparison report blob
   */
  const getNAQv2SBComparisonReport = async () => {
    try {
      let naqList = await getCompletedTodoListV2({clientId: client.id});
      naqList = naqList.body;
      const symptomBurdernData = await getNAQv2SBReportsData(client.id, selNAQv2FormsForAnalysis);
      let totalSymptomBurdenOld = 0;
      let totalSymptomBurdenNew = 0;
      symptomBurdernData.condition[0].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenNew += parseInt(condition.conditionScore)
        }
      })
      symptomBurdernData.condition[1].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenOld += parseInt(condition.conditionScore)
        }
      })
      symptomBurdernData.nutritional[0].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenNew += parseInt(deficiency.conditionScore)
        }
      })
      symptomBurdernData.nutritional[1].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenOld += parseInt(deficiency.conditionScore)
        }
      })

      let colors = [];
      colors.push(naqList.find(naq => naq.id == symptomBurdernData.condition[0].to_do_id)?.color);
      colors.push(naqList.find(naq => naq.id == symptomBurdernData.condition[1].to_do_id)?.color);

      const doc =
        <NAQ2SBComparison
          symptomBurdernData={symptomBurdernData}
          totalSymptomBurdenNew={totalSymptomBurdenNew}
          totalSymptomBurdenOld={totalSymptomBurdenOld}
          client={client}
          colors={colors}
        />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    } catch(err) {
      console.log("Error generating comparision graph pdf", err);
      return null;
    }
  }

  /**
   * Get NAQ v2 Priority Comparison report blob
   */
  const getNAQv2PriorityCompReport = async () => {
    try {
      let type = "questionnaireo";
      let naqList = await getCompletedTodoListV2({clientId: client.id});
      naqList = naqList.body;
      const priorityReport = await getNAQv2PriorityReportCompData(selNAQv2FormsForAnalysis, client);
      // const todoNotes = await getNotes(todoId);

      let colors = [];
      colors.push(naqList.find(naq => naq.id == priorityReport.current_todo)?.color);
      colors.push(naqList.find(naq => naq.id == priorityReport.prev_todo)?.color);

      const doc = 
        <NAQ2PriorityCompDoc
          priorityReport={priorityReport}
          client={client}
          date={new Date()}
          type={type}
          colors={colors}
        />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    } catch(err) {
      console.log("Error generating comparision graph pdf", err);
      return null;
    }
  }

  return (
    <div className="my-2 p-3 bg-light">
      <h6 className="m-0 fw-bold">
        Multi-Report Download
      </h6>
      <p className="m-0">
        Select the reports below to download as one PDF:
      </p>
      <div className="py-2">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp position-relative checkbox">
            <input
              type="checkbox"
              name="include_cover"
              className="position-absolute w-100 h-100"
              checked={includeReportCover}
              onChange={(e) => {
                setIncludeReportCover(e.target.checked);
              }}
            />
            <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
              <span className="icn d-none ">
                <OTick />
              </span>
            </div>
          </div>
          <p className="m-0 text-muted">Cover page</p>
        </div>
        {completedToDos && completedToDos.length > 0 &&
          completedToDos.map(todo => {
            // if(todo.form_id == NAQV2_ID) {
            //   return null;
            // }
            let selectedIndex = selectedItems.findIndex(item => item.type == "todo" && item.id == todo.id);            return (
              <div className="py-2 d-flex align-items-start gap-10">
                <div className="cstmInputCheckWrp position-relative checkbox">
                  <input
                    type="checkbox"
                    name="selected_todos"
                    className="position-absolute w-100 h-100"
                    value={todo.id}
                    // checked={selectedItems.includes(todo.id.toString())}
                    onChange={(e) => {
                      handleSelectItem(e, "todo");
                    }}
                  />
                  <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                    <span className="icn d-none ">
                      <OTick />
                    </span>
                  </div>
                </div>
                <p className="m-0 text-muted">
                  {todo.form_name}
                  {` `}
                  <span className="small">from {moment(todo.updated_at).format("MMM DD, YYYY")}</span>
                  {selectedIndex !== -1 && (
                    <span className="">{` `}(PDF order: {selectedIndex + 1})</span>
                  )}
                </p>
              </div>
            )
          })
        }
        {activeRecommendations && activeRecommendations.length > 0 &&
          activeRecommendations.map(recomm => {
            let selectedIndex = selectedItems.findIndex(item => item.type == "recommendation" && item.id == recomm.todo_id);
            return (
              <div className="py-2 d-flex align-items-start gap-10">
                <div className="cstmInputCheckWrp position-relative checkbox">
                  <input
                    type="checkbox"
                    name="selected_todos"
                    className="position-absolute w-100 h-100"
                    value={recomm.todo_id}
                    // checked={selectedItems.includes(recomm.recommendation_id.toString())}
                    onChange={(e) => {
                      handleSelectItem(e, "recommendation");
                    }}
                  />
                  <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                    <span className="icn d-none ">
                      <OTick />
                    </span>
                  </div>
                </div>
                <p className="m-0 text-muted">
                  Recommendation (Published)
                  {` `}
                  <span className="small">from {moment(recomm.created_at).format("MMM DD, YYYY")}</span>
                  {selectedIndex !== -1 && (
                    <span className="">{` `}(PDF order: {selectedIndex + 1})</span>
                  )}
                </p>
              </div>
            )
          })
        }

        {/* NAQ v1 Form */}
        {selectedNAQs && selectedNAQs.length > 0 && (
          <div className="naq-components p-3" style={{ borderTop: "1px solid #ccc" }}>
            <p className="m-0">
              Which NAQ reports should be included?
            </p>
            {NAQ_COMPONENTS.map(componentName => {
              return (
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={componentName}
                      checked={selectedNAQComponents.includes(componentName)}
                      onChange={handleSelectNAQComponent}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                    {componentName}
                  </p>
                </div>
              )
            })}
            {selectedNAQs.length > 1 &&
              <>
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={"Symptom Burden Comparison Graph"}
                      checked={selectedNAQComponents.includes("Symptom Burden Comparison Graph")}
                      onChange={handleSelectNAQComponent}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                    Symptom Burden Comparison Graph
                  </p>
                </div>
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={"Symptom Burden Comparison Report"}
                      checked={selectedNAQComponents.includes("Symptom Burden Comparison Report")}
                      onChange={handleSelectNAQComponent}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                    Symptom Burden Comparison Report
                  </p>
                </div>
              </>
            }
          </div>
        )}
        {selectedNAQComponents && selectedNAQComponents.length > 0 &&
          // <div>
          <div style={{position: "fixed", right: "-99999px", width: "1000px", height: "1000px"}}>
            {selectedNAQComponents.includes("Symptom Burden Graph") &&
              <LineChartPDF 
                innerRef={chartRef}
                todoId={selectedNAQs && selectedNAQs[0]?.id}
                client={client}
                setLoading={setLoading}
              />
            }
            {selectedNAQs && selectedNAQs.length > 1 && selectedNAQComponents.includes("Symptom Burden Comparison Graph") &&
              <ComparisonLineChartPDF
                innerRef={comparisonChartRef}
                selectedNAQs={selNAQsForAnalysis}
                client={client}
                setLoading={setLoading}
              />
            }
          </div>
        }

        {/* NAQ v2 Form */}
        {selectedNAQv2Forms && selectedNAQv2Forms.length > 0 && (
          <div className="naq-components p-3" style={{ borderTop: "1px solid #ccc" }}>
            <p className="m-0">
              Which NAQ v2 reports should be included?
            </p>
            {NAQ2_COMPONENTS.map(componentName => {
              return (
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={componentName}
                      checked={selectedNAQv2Components.includes(componentName)}
                      onChange={handleSelectNAQ2Component}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                    {componentName}
                  </p>
                </div>
              )
            })}
            {selectedNAQv2Forms.length > 1 &&
              <>
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={"Symptom Burden Comparison Graph"}
                      checked={selectedNAQv2Components.includes("Symptom Burden Comparison Graph")}
                      onChange={handleSelectNAQ2Component}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                    Symptom Burden Comparison Graph
                  </p>
                </div>
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={"Symptom Burden Comparison Report"}
                      checked={selectedNAQv2Components.includes("Symptom Burden Comparison Report")}
                      onChange={handleSelectNAQ2Component}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                    Symptom Burden Comparison Report
                  </p>
                </div>
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative checkbox">
                    <input
                      type="checkbox"
                      name="selected_todos"
                      className="position-absolute w-100 h-100"
                      value={"Priority Comparison Report"}
                      checked={selectedNAQv2Components.includes("Priority Comparison Report")}
                      onChange={handleSelectNAQ2Component}
                    />
                    <div className="cstmInputCheck h-100 w-100  d-flex align-items-center justify-content-center">
                      <span className="icn d-none ">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 text-muted">
                  Priority Comparison Report
                  </p>
                </div>
              </>
            }
          </div>
        )}
        {selectedNAQv2Forms && selectedNAQv2Forms.length > 0 && selectedNAQv2Forms.map((todo) => {
          let todoRef = naqv2Refs?.length > 0 && naqv2Refs.find(ref => ref.todoId == todo.id);
          if(!todoRef) {
            console.log("NAQv2 Ref not found");
            return null;
          }
          return (
            <div style={{position: "fixed", right: "-99999px", width: "1000px", height: "1000px"}}>
              {selectedNAQv2Components.includes("Foundations Symptom Burden Graph") &&
                <FoundationSymptomGraphPdf 
                  innerRef={todoRef && todoRef?.fsbGraphRef}
                  todoId={todo?.id}
                  client={client}
                  setLoading={setLoading}
                />
              }
              {selectedNAQv2Components.includes("Body Systems Symptom Burden Graph") &&
                <BodySymptomGraphPdf 
                  innerRef={todoRef && todoRef?.bsGraphRef}
                  todoId={todo?.id}
                  client={client}
                  setLoading={setLoading}
                />
              }
            </div>
          )
        })}
        {selectedNAQv2Forms && selectedNAQv2Forms.length > 1 && (
          <>
            <div style={{position: "fixed", right: "-99999px", width: "1000px", height: "1000px"}}>
              {selectedNAQv2Components.includes("Symptom Burden Comparison Graph") &&
                <NAQv2ComparisionGraphPDF
                  fsbGraphRef={fsbGraphRef}
                  bsGraphRef={bsGraphRef}
                  selectedNAQs={selNAQv2FormsForAnalysis}
                  client={client}
                  setLoading={setLoading}
                />
              }
              {/* {selectedNAQv2Components.includes("Body Systems Symptom Burden Graph") &&
                <BodySymptomGraphPdf 
                  innerRef={todoRef && todoRef?.bsGraphRef}
                  todoId={todo?.id}
                  client={client}
                  setLoading={setLoading}
                />
              } */}
            </div>
          </>
        )}

        {/* Health & Wellness Assessment Form */}
        {selectedHealthAssessForms && selectedHealthAssessForms?.length > 0 && selectedHealthAssessForms.map((todo, i) => {
          let todoRef = healthAssesmentRefs?.length > 0 && healthAssesmentRefs.find(ref => ref.todoId == todo.id);
          return (
            <HealthWellnessGraphs
              client={client}
              ref={todoRef?.ref || null}
              todoId={todo.id}
              setLoading={setLoading}
            />
          )
        })}
        <div className="py-2">
          <Button
            className="d-flex align-items-center btnSm px-4 justify-content-center commonBtn"
            onClick={downloadCombinedPDF}
            disabled={isLoading}
          >
            {isLoading ? "Please wait..." : "Download Combined PDF"}
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToPros = (state) => {
  let recommendations = state.Client?.client?.recommendationReports?.customRecList || [];
  let activeRecommendations = 
    recommendations?.length > 0 &&
    recommendations?.filter(recomm => recomm.status === "completed" && recomm.for_client === "publish");
  return {
    activeRecommendations
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MultiReportDownloadOptions);