import { ACTION_PLAN_FORM_ID, FOOD_JOURNAL_FORM_ID, RECOMMENDATION_FORM_ID } from "common/constants";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const FormSubmittedPopup = ({ showPopup, handleClose, formId }) => {

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-start">
            <Col lg="12" className="">
              {function(){
                switch(formId) {
                  case ACTION_PLAN_FORM_ID:
                    return (
                      <p>You can find your completed form on the 'Action Plans' tab.</p>
                    )

                  case FOOD_JOURNAL_FORM_ID:
                    return (
                      <p>You can find your completed form on the 'Data & To-Do Items' tab.</p>
                    )
                  
                  case RECOMMENDATION_FORM_ID:
                    return (
                      <p>You can find your completed form on the 'Recommendations' tab.</p>
                    )

                  default:
                    return null;
                }
              }()}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormSubmittedPopup;
