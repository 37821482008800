import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

const ActionPlansListing = ({
  actionPlans,
  onClickOpen,
  removeActionPlan,
}) => {
  const { clientId } = useParams();

  const data = [
    {
      item: "Nutritional Assessment Questionnaire",
      status: `in progress`,
      AddedOn: "Sep 9, 2022 7:40 PM",
    },
    {
      item: "test - ignore",
      status: `not complete`,
      AddedOn: "Sep 9, 2022 7:40 PM",
    },
    {
      item: "Nutritional Assessment Questionnaire",
      status: `paused`,
      AddedOn: "Sep 9, 2022 7:40 PM",
    },
  ];

  

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                Item Name
              </th>
              <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                Status
              </th>
              <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                Item Added On
              </th>
              <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {actionPlans && actionPlans.length > 0 ?
              actionPlans.map((actionPlan, idx) => {
                return (
                    <tr key={idx}>
                      <td className="px-3 py-2 border-0">
                        <p className="theme-clr m-0 ws-normal fw-bold">
                          Client Action Plan
                        </p>
                      </td>
                      <td className="px-3 py-2 border-0">
                        <p
                          className={
                            actionPlan.status == "in progress"
                              ? "SuccessText text-capitalize fw-sbold m-0"
                              : data.status == "not complete"
                              ? "DangerText text-capitalize fw-sbold m-0"
                              : data.status == "paused"
                              ? "WarningText text-capitalize fw-sbold m-0"
                              : ""
                          }
                        >
                          <span className="icn me-2">
                            {data.status == "in progress" ? (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_0_3440)">
                                    <path
                                      d="M12.6694 2.49609C7.14943 2.49609 2.66943 6.97609 2.66943 12.4961C2.66943 18.0161 7.14943 22.4961 12.6694 22.4961C18.1894 22.4961 22.6694 18.0161 22.6694 12.4961C22.6694 6.97609 18.1894 2.49609 12.6694 2.49609ZM9.95943 16.7861L6.36943 13.1961C5.97943 12.8061 5.97943 12.1761 6.36943 11.7861C6.75943 11.3961 7.38943 11.3961 7.77943 11.7861L10.6694 14.6661L17.5494 7.78609C17.9394 7.39609 18.5694 7.39609 18.9594 7.78609C19.3494 8.17609 19.3494 8.80609 18.9594 9.19609L11.3694 16.7861C10.9894 17.1761 10.3494 17.1761 9.95943 16.7861Z"
                                      fill="#05CD99"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_0_3440">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0.669434 0.496094)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            ) : data.status == "not complete" ? (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_0_3453)">
                                    <path
                                      d="M12.6694 2.54492C7.13943 2.54492 2.66943 7.01492 2.66943 12.5449C2.66943 18.0749 7.13943 22.5449 12.6694 22.5449C18.1994 22.5449 22.6694 18.0749 22.6694 12.5449C22.6694 7.01492 18.1994 2.54492 12.6694 2.54492ZM16.9694 16.8449C16.5794 17.2349 15.9494 17.2349 15.5594 16.8449L12.6694 13.9549L9.77943 16.8449C9.38943 17.2349 8.75943 17.2349 8.36943 16.8449C7.97943 16.4549 7.97943 15.8249 8.36943 15.4349L11.2594 12.5449L8.36943 9.65492C7.97943 9.26492 7.97943 8.63492 8.36943 8.24492C8.75943 7.85492 9.38943 7.85492 9.77943 8.24492L12.6694 11.1349L15.5594 8.24492C15.9494 7.85492 16.5794 7.85492 16.9694 8.24492C17.3594 8.63492 17.3594 9.26492 16.9694 9.65492L14.0794 12.5449L16.9694 15.4349C17.3494 15.8149 17.3494 16.4549 16.9694 16.8449Z"
                                      fill="#EE5D50"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_0_3453">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0.669434 0.544922)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            ) : data.status == "paused" ? (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                >
                                  <path
                                    d="M12.6694 2.5957C7.14943 2.5957 2.66943 7.0757 2.66943 12.5957C2.66943 18.1157 7.14943 22.5957 12.6694 22.5957C18.1894 22.5957 22.6694 18.1157 22.6694 12.5957C22.6694 7.0757 18.1894 2.5957 12.6694 2.5957ZM13.6694 17.5957H11.6694V15.5957H13.6694V17.5957ZM13.6694 13.5957H11.6694V7.5957H13.6694V13.5957Z"
                                    fill="#FFCE20"
                                  />
                                </svg>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          Paused
                        </p>
                      </td>
                      <td className="px-3 py-2 border-0">
                        <p className="theme-clr m-0">{moment(actionPlan.created_at).format("MMM DD, YYYY H:mm A")}</p>
                      </td>
                      <td className="px-3 py-2 border-0">
                        <Dropdown>
                          <Dropdown.Toggle
                            className=" bg-white btnSm text-uppercase text-dark fw-sbold border pe-5 position-relative"
                            id="dropdown-basic"
                          >
                            Options
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <ul className="list-unstyled ps-0 mb-0 noLastBorder">
                              <li>
                                <Link
                                  onClick={() => {
                                    onClickOpen(actionPlan.id);
                                  }}
                                  className="text-dark text-uppercase fw-sbold text-dark px-3 py-1 d-block"
                                >
                                  Open
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    removeActionPlan(actionPlan.id);
                                  }}
                                  className="text-dark text-uppercase fw-sbold text-dark px-3 py-1 d-block"
                                >
                                  Remove
                                </Link>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                );
              })
              :
                <tr>
                  <td className="text-center" colSpan={4}>No data found</td>
                </tr>
              }
          </tbody>
        </table>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ActionPlansListing);