import * as d3 from "d3";
import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const SymptomGraph = ({ dataset, title }) => {
  const [showPriorityMaxes, setShowPriorityMaxes] = useState(true);
  // const dataset = [
  //   {
  //     "name": "cardio",
  //     "abbr": "Cardio",
  //     "abs_max": 31,
  //     "max": 2,
  //     "min": 2,
  //     "priorityMaxes": {
  //       "lowMax": 5,
  //       "medMax": 8,
  //       "highMax": 11
  //     },
  //     "color": "#2496F1",
  //     "rawTotal": 5
  //   },
  //   {
  //     "name": "endocrine",
  //     "abbr": "Endocrine",
  //     "abs_max": 217,
  //     "max": 3,
  //     "min": 3,
  //     "priorityMaxes": {
  //       "lowMax": 33,
  //       "medMax": 54,
  //       "highMax": 76
  //     },
  //     "color": "#2496F1",
  //     "rawTotal": 40
  //   },
  //   {
  //     "name": "immune",
  //     "abbr": "Immune",
  //     "abs_max": 120,
  //     "max": 2,
  //     "min": 2,
  //     "priorityMaxes": {
  //       "lowMax": 18,
  //       "medMax": 30,
  //       "highMax": 42
  //     },
  //     "color": "#2496F1",
  //     "rawTotal": 18
  //   }
  // ]

  const toggleShowPriorityMaxes = (e) => {
    setShowPriorityMaxes(e.target.checked);
  }

  if (!dataset) {
    return "";
  }
  const svgWidth = 1000;
  const svgHeight = dataset.length * 63;
  const labelStartX = 200;
  const valueStartX = 250;
  const labelStartY = 60;
  const barStartY = 40;
  const barGap = 30;
  const barHeight = 30
  const barStart = valueStartX;
  const graphWidth = svgWidth - valueStartX;
  const dividerX = graphWidth / 3;
  // console.log("graphWidth", graphWidth, dividerX);

  return (
    <div className="body-systems-graph py-2">
      <h4>{title}</h4>
      <Row className="py-2">
        <Col lg="12" className="py-2">
          <svg width={svgWidth} height={svgHeight}>
            {dataset && dataset.map((row, i) => {
              const dividers = {
                low: {
                  value: row.priorityMaxes.lowMax,
                  x: (dividerX) + valueStartX
                },
                med: {
                  value: row.priorityMaxes.medMax,
                  x: (dividerX * 2) + valueStartX
                },
                high: {
                  value: row.priorityMaxes.highMax,
                  x: (dividerX * 3) + valueStartX
                }
              }
              // console.log("dividers", dividers);

              let barWidthNew = 0;
              if (row.rawTotal <= row.priorityMaxes.lowMax) {
                let diff = (row.rawTotal * 100) / row.priorityMaxes.lowMax;
                barWidthNew += (((dividers.low.x - barStart) * diff) / 100);
              } else if (row.rawTotal > row.priorityMaxes.lowMax && row.rawTotal <= row.priorityMaxes.medMax) {
                let diff = (row.rawTotal * 100) / row.priorityMaxes.medMax;
                barWidthNew += (((dividers.med.x - barStart) * diff) / 100);
              } else if (row.rawTotal <= row.priorityMaxes.highMax) {
                let diff = (row.rawTotal * 100) / row.priorityMaxes.highMax;
                barWidthNew += (((dividers.high.x - barStart) * diff) / 100);
              }
              // console.log("barWidthNew", barWidthNew, dividers.low.x)

              let barYPos = barStartY;
              if (i > 0) {
                barYPos = (barStartY) + (barGap * i) + (barHeight * i);
              }

              // Graph values
              return (
                <>
                  <rect height="31" width={barWidthNew - 10} y={barYPos} x={barStart +10} fill="#2496F1"></rect>
                  <text x={labelStartX} y={labelStartY * (i + 1)} text-anchor="end" style={{ fontSize: 14, fontWeight: 600 }}>
                    {row.abbr}
                  </text>
                  <text x={valueStartX} y={labelStartY * (i + 1)} text-anchor="middle">
                    {row.rawTotal}
                  </text>
                  { showPriorityMaxes &&
                    <>
                      <text x={dividers.low.x - 10} y={labelStartY * (i + 1)} text-anchor="end">
                        {row.priorityMaxes.lowMax}
                      </text>
                      <text x={dividers.med.x - 10} y={labelStartY * (i + 1)} text-anchor="end">
                        {row.priorityMaxes.medMax}
                      </text>
                      <text x={dividers.high.x - 10} y={labelStartY * (i + 1)} text-anchor="end">
                        {row.priorityMaxes.highMax}
                      </text>
                    </>
                  }
                </>
              )
            })}

            {/* Dividers */}
            <rect height={svgHeight} x={barStart + 10} width={1} fill="black" opacity={0.25}>
            </rect>
            {[1, 2, 3].map((key) => {
              return (
                <rect height={svgHeight} x={(dividerX * key) + valueStartX} width={1} fill="black" opacity={0.25}>
                </rect>
              )
            })}

            {/* SVG Top Priority Text */}
            <g>
              <text y="10" x={(dividerX) + (valueStartX / 2)} text-anchor="middle" style={{ fontSize: 11 }}>Low Priority</text>
            </g>
            <g>
              <text y="10" x={(dividerX * 2)  + (valueStartX / 2)} text-anchor="middle" style={{ fontSize: 11 }}>Medium Priority</text>
            </g>
            <g>
              <text y="10" x={(dividerX * 3)  + (valueStartX / 2)} text-anchor="middle" style={{ fontSize: 11 }}>High Priority</text>
            </g>
          </svg>
        </Col>
        <Col lg="12" className="py-2">
          <input
            id="show_priority_maxes"
            name="show_priority_maxes"
            type="checkbox"
            onChange={toggleShowPriorityMaxes}
            checked={showPriorityMaxes}
          />
          <label className="px-2" for="show_priority_maxes">Show priority level maxes on chart</label>
        </Col>
        <hr></hr>
        <Col lg="12" className="py-2">
          <h6>Body Systems Totals</h6>
          <ul className="body-systems-list">
            {dataset && dataset.length > 0 &&
              dataset.map(row => {
                return (
                  <li>
                    <strong>{`${row.abbr}: ${row.rawTotal}`}</strong>
                    {` chart max of ${row.priorityMaxes.highMax}`}
                  </li>
                )
              })
            }
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default SymptomGraph;