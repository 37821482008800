import { FULL_NAME } from "helpers/common";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const TodoAddedPopup = ({ client, showPopup, closePopup, inviteSent }) => {

  useEffect(() => {
  }, []);

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12" className="my-2">
              {client?.is_direct_account === "yes" ? (
                <p>A new to-do item has been added for {FULL_NAME(client.first_name, client.last_name)}.</p>
              ):(client && !client.last_password_updated) ? (
                <>
                  {inviteSent ? (
                    <p>A new to-do item has been added for {FULL_NAME(client.first_name, client.last_name)} and we sent them an email reminder on how to sign up for Nutri-Q.</p>
                  ):(
                    <>
                      <p>A new to-do item has been added for {FULL_NAME(client.first_name, client.last_name)}.</p>
                      <p>Please remember that {FULL_NAME(client.first_name, client.last_name)} is not yet an active client. You can send them an invite or reminder to sign up by going to their 'Info & Settings' tab and clicking on the 'Invite Options' button.</p>
                    </>
                  )}
                </>
              ):(
                <p>A new to-do item has been added for {FULL_NAME(client.first_name, client.last_name)} and we've notified them via email.</p>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TodoAddedPopup;
