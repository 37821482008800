import * as Yup from 'yup';

export const validation = Yup.object({
  form_id: Yup.number().required(),
  form_column_id: Yup.number().required("Question Type is Required"),
  question: Yup.string().trim().required("Question Text is Required"),
  question_help: Yup.string().trim().optional(),
  dependentQuestion: Yup.boolean(),
  depend_q_id: Yup
    .number()
    .when("dependentQuestion", {
      is: true,
      then: schema => schema.required("Please select the question that will determine whether this question is shown."),
      otherwise: schema => schema.nullable(),
    }),
  depend_q_ans: Yup
    .array()
    .of(Yup.string())
    // .compact()
    .when("dependentQuestion", {
      is: true,
      then: schema => schema.min(1, "Please select at least one answer to the dependency question."),
      otherwise: schema => schema.nullable(),
    }),
  form_column_id: Yup.number().required(),
  other_column_data: Yup.lazy(value => {
    // if value is an array
    if(Array.isArray(value)) {
      return Yup
        .array()
        .of(Yup.string())
        .compact()
        .nullable()
        .when("form_column_id", {
          is: 3,
          then: schema => schema.min(1, "One or more Checkbox options are required to save.")
        })
        .when("form_column_id", {
          is: 7,
          then: schema => schema.min(2, "Two or more Radio Button options are required to save.")
        })
    }
    // if value is an object
    else if(value && typeof value == "object" && Object.keys(value).length) {
      return Yup
        .object()
        .when("form_column_id", {
          is: 4,
          then: schema => schema.shape({
            disclaimer_title: Yup.string().required("Disclaimer title is required"),
            disclaimer_text: Yup.string().required("Disclaimer text is required"),
            checkbox_text: Yup.string().required("\"Agree To\" Checkbox Text is required"),
          })
        })
        .when("form_column_id", {
          is: 6,
          then: schema => schema.shape({
            minNum: Yup.number()
              .transform(value => parseInt(value))
              .required("Minimum number is required"),
            maxNum: Yup.number()
              .transform(value => parseInt(value))
              .required("Maximum number is required")
              .when('minNum', (minNum, schema) => {
                return schema.test({
                  test: maxNum => !!minNum && maxNum > minNum,
                  message: "Maximum number must be greater than Minimum number"
                })
              }),
          })
        })
        .when("form_column_id", {
          is: 8,
          then: schema => schema.shape({
            minNum: Yup.number()
              .required("Minimum number is required")
              .transform(value => {
                console.log("transform value", value)
                return value 
              })
              .min("0", "Minimum number must be between 0-9")
              .max("9", "Minimum number must be between 0-9"),
            maxNum: Yup.number()
              .required("Maximum number is required")
              .transform(value => value && parseInt(value))
              .min("1", "Maximum number must be between 1-10")
              .max("10", "Maximum number must be between 1-10")
              .when('minNum', (minNum, schema) => {
                return schema.test({
                  test: maxNum => !!minNum && maxNum > minNum,
                  message: "Maximum number must be greater than Minimum number"
                })
              }),
            minTxt: Yup.string().optional(),
            maxTxt: Yup.string().optional(),
          })
        })
        
    }
    // in any other case
    else {
      return Yup.string().nullable();
    }
  })
});