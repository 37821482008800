import {postData, getData, getDataAuth} from 'helpers/apiHelper'
import { MAIN_PATH, PROFILE, UPDATE_PROFILE, ACCPET_TERMS, PLAN_ACTIVE_API } from './apiPath'
import { getProfile, updateProfile, accpetPolicy, activePlanStatus} from 'store/actions/profile';
//get profile
export function profile(value, callback) {
  return (dispatch) => getDataAuth(`${MAIN_PATH}${PROFILE}`)
    .then((data) => {
      dispatch(getProfile(data, callback));
      return data;
    });
}

//update profile
export function updateProfile_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${UPDATE_PROFILE}`, value)
    .then((data) => {
      dispatch(updateProfile(data, callback));
      dispatch(profile(data, callback));
      return data;
    });
}

//accept terms condition
export function accpetPolicy_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${ACCPET_TERMS}`, value)
    .then((data) => {
      dispatch(profile(data, callback));
      return data;
    });
}

//active plan api
export function activePlanStatus_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${PLAN_ACTIVE_API}`, value)
    .then((data) => {
      dispatch(activePlanStatus(data, callback));
      return data;
    });
}



