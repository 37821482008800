import { decode } from "html-entities";

const TextInput = ({ question }) => {
  return (
    <>
      <input className="border rounded w-100 p-1" type="text" disabled />
      <p
        style={{
          fontSize: 10,
          display: "flex",
          alignSelf: "flex-start",
          color: "#1f1e1e",
        }}
      >
        {decode(question?.question_help)}
      </p>
    </>
  );
};

export default TextInput;
