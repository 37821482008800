import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { decode } from "html-entities"
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import Html from 'react-pdf-html';

const styles = StyleSheet.create({
  fontBold: {
    color: "#000",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  }
});

export const RenderAnswerField = ({ question }) => {

  if(!question.answer) {
    return <Text style={{ opacity: "0.7" }}>Nothing Entered</Text>
  }

  return (
    <ErrorBoundary fallback={<Text>(Unable to Parse Response)</Text>}>
      {question.question_type == "address" ? (
        <Address 
          question={question}
        />
      ) : question.question_type == "height" ? (
        <Height 
          question={question}
        />
      ) : question.question_type == "weight" ? (
        <Weight 
          question={question}
        />
      ) : question.question_type == "disclaimer" ? (
        <Disclaimer 
          question={question}
        />
      ) : question.question_type == "date" ? (
        <DateField
          question={question}
        />
      ) : (
        <Text>
          {decode(question.answer)}
        </Text>
      )}
    </ErrorBoundary>
  )
}

const DateField = ({ question }) => {
  return (
    <Text>
      {moment(question.answer).format("MM-DD-YYYY")}
    </Text>
  )
}

const Address = ({ question }) => {
  // let answer = question.answer && JSON.parse(question.answer);
  let answer = question.answer;
  const { address_1 = '', address_2 = '', city = '', state = '', country = '', zip_code = '', phone = '', phone_type = '' } = answer;
  return (
    <View style={{ flexDirection: "column" }}>
      <Text style={{ display: "block" }}>
        {address_1}{address_2 ? ', ' + address_2 : ''}
        {`\n`}
      </Text>
      <Text style={{ display: "block" }}>
        {city}{state ? ', ' + state : ''}{zip_code ? ', ' + zip_code : ''}{country ? ', ' + country : ''}
      </Text>
      <Text style={{ display: "block" }}>
        {`\n`}
        {phone && (
          <Text>
            <Text style={styles.fontBold}>Phone: </Text>
            {phone}
          </Text>
        )}
        {`\n`}
        {phone_type && (
          <Text>
            <Text style={styles.fontBold}>Phone Type: </Text>
            {phone_type}
          </Text>
        )}
      </Text>
    </View>
  )
}

const Height = ({ question }) => {
  // let answer = question.answer && JSON.parse(question.answer);
  let answer = question.answer;
  let unit = answer?.unit;
  let value = answer?.value;
  let ftVal = value && value[0];
  let inchesVal = value && value.slice(1, value?.length);
  console.log(value, ftVal, inchesVal);
  return (
    <>
      {unit == "ft_in" ? (
        <Text>
          {ftVal && `${ftVal} ft`}
          {inchesVal && ` ${inchesVal} in`}
        </Text>
      ): unit == "cm" ? (
        <Text>
          {value && `${value} ${unit}`}
        </Text>
      ):(
        ""
      )}
    </>
  )
}

const Weight = ({ question }) => {
  // let answer = question.answer && JSON.parse(question.answer);
  let answer = question.answer;
  let unit = answer?.unit;
  let value = answer?.value;
  if(!value) {
    return <Text style={{ opacity: "0.7" }}>Nothing Entered</Text>
  }

  return (
    <>
      {value &&
        <Text>
          {value}{` `}
          {unit}
        </Text>
      }
    </>
  )
}

const Disclaimer = ({ question }) => {
  let fieldData = question.other_column_data;
  // let answer = question.answer && JSON.parse(question.answer);
  let answer = question.answer;
  let agreed = answer?.agreed;
  let typedName = answer?.full_name;
  let typedDate = answer?.date;

  const CustomRenderer = (props) => {
    return (
      <View>
        <Text {...props}>{props.children} {"\n".repeat(props.newLines || 2)}</Text>
      </View>
    )
  }

  if(!agreed) {
    return <Text style={{ opacity: "0.7" }}>Nothing Entered</Text>
  }

  return (
    <>
      {fieldData?.disclaimer_title && (
        <Text>
          Disclaimer Title: {fieldData?.disclaimer_title} {`\n`}
        </Text>
      )}
      {fieldData?.disclaimer_text && (
        <>
          <Text>
            Disclaimer Content: {`\n`}
          </Text>
          <Html
            style={{ fontSize: 9 }}
            renderers={{
              "p": (props) => <CustomRenderer {...props} />,
              "h1": (props) => <CustomRenderer {...props} />,
              "h2": (props) => <CustomRenderer {...props} />,
              "h3": (props) => <CustomRenderer {...props} />,
              "h4": (props) => <CustomRenderer {...props} />,
              "h5": (props) => <CustomRenderer {...props} />,
              "h6": (props) => <CustomRenderer {...props} />,
              "br": (props) => `\n`,
            }}
          >
            {fieldData?.disclaimer_text}
          </Html>
          {`\n`}
        </>
      )}
      <Text style={{ display: "block" }}>
        {fieldData && decode(fieldData?.checkbox_text)} {`\n`}
      </Text>
      {fieldData && fieldData.require_name_date &&
        <Text style={{ flexDirection: "column" }}>
          <Text style={{ display: "block" }}>
            Typed Name: {typedName} {`\n`}
          </Text>
          <Text style={{ display: "block" }}>
          Typed Date: {typedDate}
          </Text>
        </Text>
      }
    </>
  )
}