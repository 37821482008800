import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

// icon
import { ReactComponent as OTick } from "../../../../../../../Assets/Images/icn/OrangeTick.svg";
import { FULL_NAME } from "helpers/common";

const SendPackageInvitePop = ({
  packageInvitePop,
  togglePackageInvitePopup,
  handleClickSendPackage,
  clients
}) => {

  const [selectedClient, setSelectedClient] = useState(null);
  const [dontSendEmailInvite, setDontSendEmailInvite] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);

  const toggleEmailInvite = () => {
    setDontSendEmailInvite(!dontSendEmailInvite);
  }

  const sendPackageInvite = async () => {
    await handleClickSendPackage(selectedClient, dontSendEmailInvite, setSendingInvite);
    handleClose();
  }

  const handleClose = () => {
    setSelectedClient(null);
    setDontSendEmailInvite(false);
    togglePackageInvitePopup();
  }

  const handleSelectClient = (clientId) => {
    let selectedClient = clients?.length && clients.find(client => client.id == clientId);
    setSelectedClient(selectedClient);
  }

  return (
    <>
      <Modal
        show={packageInvitePop}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Package Invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg="12" className="my-2">
                <p className="m-0 fw-sbold pb-1">
                  Select a client below and we'll send them a link to purchase this package
                </p>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={(e) => {
                    handleSelectClient(e.target.value);
                  }}
                >
                  <option value="">Select a client</option>
                  {clients && clients.length > 0 &&
                    clients.map(client => {
                      return (
                        <option value={client.id}>{`${FULL_NAME(client.first_name, client.last_name)} (${client.email})`}</option>
                      )
                    })
                  }
                </Form.Select>
              </Col>
              <Col lg="12" className="my-2">
                <p className="m-0 fw-sbold pt-1">
                  If you wish to send the package invite yourself then check this box to generate the invite link only.
                </p>
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative">
                    <input
                      type="checkbox"
                      className={`position-absolute w-100 h-100`}
                      name={`question`}
                      onChange={toggleEmailInvite}
                      checked={dontSendEmailInvite}
                    />
                    <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                      <span className="icn d-none">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 fw-sbold text-dark">Do not email the package invite</p>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={sendPackageInvite}
            disabled={!selectedClient || sendingInvite}
          >
            { dontSendEmailInvite === true ? 
              "Generate Package Link" : "Send Package"
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendPackageInvitePop;
