import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthLayout from "../../../layout/AuthLayout/AuthLayout";
import { resetPassword } from 'store/services/authService';
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { passwordStrength } from 'check-password-strength'
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

//imported
import validation from "./validation";
const ResetPassword = ({submitting, dispatch, match}) => {
  const { token } = useParams();
  const navigate = useNavigate();
  //values
  const initialValues = {
    password: "",
    confirmPassword:"",
  };
  //submit data
    const handleSubmit_ = async (values, {setSubmitting, resetForm}) => {
      try{
          values  = JSON.stringify(values)
          setSubmitting(true);
          let res = await dispatch(resetPassword(token, values));
          toast.success(res?.message);
          resetForm();
          setSubmitting(false);
          navigate('/login')
      }catch(err) {
          console.log(err);
          setSubmitting(false);
        }
    }

  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <h2 className="m-0 fw-bold">Reset Password</h2>
        </div>
        <div className="FormBody">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={handleSubmit_}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    New Password
                  </label>
                  <input
                    type="password"
                    className={`form-control ${errors.password && "is-invalid"}`}
                    name="password"
                    onChange={handleChange}
                    placeholder="***********"
                    onBlur={handleBlur}
                    value={values.password}
                    autoComplete="off"
                  />
                  {values?.password && (
                    <p className="m-0 py-1 password-strength">Password Strength: <span className={`strength strength-${passwordStrength(values.password).id}`}>{passwordStrength(values.password)?.value}</span></p>
                  )}
                  {errors.password && touched.password && (
                    <p className="invalid-feedback">{errors.password}</p>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className={`form-control ${errors.confirmPassword && "is-invalid"}`}
                    name="confirmPassword"
                    onChange={handleChange}
                    placeholder="***********"
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    autoComplete="off"
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className="invalid-feedback">{errors.confirmPassword}</p>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <Button type="submit" className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                  {isSubmitting ? "wait..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);