import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";

import Form from "./Component/EditClientForm";
import moment from "moment";

const ResendInvitePopup = ({ showPopup, isResendIntent, togglePopup, client, sendInvitationEmail, updateClientDetails }) => {
  const [isSending, setIsSending] = useState(false);
  const [showClientEditForm, setShowClientEditForm] = useState(false);
  // const closePopup = () => {
  //   setShowPopup(!showPopup);
  // };

  const editFormInitialValues = {
    first_name: client.first_name || "",
    last_name: client.last_name || "",
    email: client.email || "",
    phone: client.phone || "",
    street: client.street || "",
    apartment: client.apartment || "",
    city: client.city || "",
    state: client.state || "",
    zipcode: client.zipcode || "",
    country: client.country || "",
    birth_date: client.birth_date && moment(client.birth_date).format("YYYY-MM-DD") || "",
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={togglePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="FormPop editClient"
        scrollable="true"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isResendIntent ? "Resend Invite" : "Invite Client"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { showClientEditForm ?
            <Form 
              initialValues={editFormInitialValues}
              handleSubmit={updateClientDetails}
              handleClose={() => setShowClientEditForm(false)}
            />
            :
            <Row>
              <Col lg="12" className="my-2">
                <p className="m-0 fw-sbold pb-1">
                  By inviting this client they'll be able to log into the site and fill out more questionnaires and use all the other tools. We'll send them an email with instructions for how to log in and we'll CC you as well in case they have issues.
                </p>
              </Col>
              <Col lg="12" className="my-2">
                <h6>Client's name & email address:</h6>
                <ul>
                  <li>First Name: <strong>{client.first_name}</strong></li>
                  <li>Last Name: <strong>{client.last_name}</strong></li>
                  <li>Email: <strong>{client.email}</strong></li>
                </ul>
              </Col>
              <Col>
                <h6>Is the client's first name and email above correct?</h6>
                <div className="d-flex gap-10 FormbtnWrpper">
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={togglePopup}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    type="submit"
                    onClick={() => {
                      setShowClientEditForm(true);
                    }}
                  >
                    No, edit info
                  </Button>
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    type="submit"
                    onClick={() => {
                      sendInvitationEmail(setIsSending)
                    }}
                    disabled={isSending}
                  >
                    { isSending ? "Please wait..." : "Yes, send invite" }
                  </Button>
                </div>
              </Col>
            </Row>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResendInvitePopup;
