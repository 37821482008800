import { FULL_NAME } from "helpers/common";
import { Button } from "react-bootstrap";

const StartStep = ({ clientInfo, nextStep, previousStep, currentStep, totalSteps }) => {
  return (
    <>
      <div className="py-2">
        <h5 className="m-0 fw-bold">Hello {clientInfo?.client_name}</h5>
        <div className="disclaimer py-2">
          <p>
            You can enter historical NAQ data for your client here so that you can view NAQ comparisons on the symptom burden chart.
            Questions follow the standard NAQ order and pages are grouped by section for easier entry.
            We'll save each page as you go along so feel free to take your time or take a break in the middle.
          </p>
        </div>
        <Button
          onClick={nextStep}
          className="d-flex align-items-center justify-content-center commonBtn btnSm"
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default StartStep;