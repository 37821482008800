import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { CONFIRM_POPUP } from "common/constantMessage";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "store/actions"
import { getClientToDoList, updateTodoStatus } from "store/services/clientService";

const SendTodoToClient = ({ dispatch, clientId, todoId, todoDetails }) => {

  const sendToClient = async () => {
    try {
      let confirmMessage = todoDetails?.owned_by !== clientId
        ? "Are you sure you want to unpublish the form?"
        : "Are you sure you want to send this form back to client?"

      await CONFIRM_POPUP(confirmMessage)
        .then(async (isConfirmed) => {
          if(isConfirmed) {
            dispatch(actions.persist_store({ loader: true }));
            let data = {
              client_id: clientId,
              status: "in-process",
            }
            await updateTodoStatus(todoId, data);
            await dispatch(getClientToDoList({clientId}));
            dispatch(actions.persist_store({ loader: false }));
          }
        }).catch(err => {
          throw err
        })
    } catch(err) {
      console.log("Error sending todo back to client", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <Button
      className="d-flex align-items-center justify-content-center commonBtn btnSm pdf-download-btn"
      onClick={sendToClient}  
    >
      <span className="icn me-2" style={{ fontSize: "14px" }}>
        <i className="fa fa-arrow-left"></i>
      </span>
      {todoDetails?.owned_by !== clientId ? "Unpublish" : "Send back to client"}
    </Button>
  )
}

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SendTodoToClient);