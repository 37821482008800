import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";

import * as actions from "store/actions";

// img
import GoogleCalIcn from "../../../../../../Assets/Images/icn/googleCal.svg";
import FScriptIcn from "../../../../../../Assets/Images/icn/fullScript.svg";
import PBetterIcn from "../../../../../../Assets/Images/icn/PracticeBetter.svg";

// icn
import { ReactComponent as StripeIcn } from "../../../../../../Assets/Images/icn/stripe.svg";
import { ReactComponent as LinkIcn } from "../../../../../../Assets/Images/icn/Link.svg";
import { connectFullscriptAccount, connectGoogleCalendar, connectStripeAccount, disconnectFullscriptAccount, disconnectGoogleCalendar, disconnectPracticeBetterAccount, disconnectStripeAccount, generatePracticeBetterKey, getIntegrationStatus, getStripeLoginLink } from "store/services/accountSettingService";
import { HelpGoogleLinkAccount, HelpStripeLinkAccount, HelpFullScriptLinkAccount, HelpPracticeLinkAccount, HelpAcceptPayment, HelpPackagesCreate } from 'Routes/paths';

const ThirdPartyIntegeration = ({ dispatch, stripeConnection, googleCalendarConnection, fullscriptConnection, practiceBetterConnection }) => {
  const [linkStripeDisabled, setLinkStripeDisabled] = useState(false);
  const [linkGoogleDisabled, setLinkGoogleDisabled] = useState(false);

  // fullscript states
  const [showFullscriptCountries, setShowFullscriptCountries] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  // practice better states
  const [practiceBetterApiKey, setPracticeBetterApiKey] = useState(null);
  const [pbKeyCopied, setPbKeyCopied] = useState(false);

  useEffect(() => {
    getThirdPartyIntegrations();
  }, [])

  let timeoutId = null;
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      setPbKeyCopied(false);
    }
  }, []);

  const handleSelectFullscriptCountry = (country) => {
    setSelectedCountry(country);
    linkFullscript(country);
  }

  const getThirdPartyIntegrations = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getIntegrationStatus());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const linkStripe = async () => {
    try {
      setLinkStripeDisabled(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await connectStripeAccount();
      if (res.success === true && res.body.url) {
        window.location = res.body.url;
      }
      // dispatch(actions.persist_store({ loader: false }));
      // setLinkStripeDisabled(false);
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
      setLinkStripeDisabled(false);
    }
  }

  const unlinkStripe = async () => {
    try {
      setLinkStripeDisabled(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(disconnectStripeAccount());
      dispatch(actions.persist_store({ loader: false }));
      setLinkStripeDisabled(false);
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
      setLinkStripeDisabled(false);
    }
  }

  const openStripeDashboard = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await getStripeLoginLink();
      if (res.success === true && res.body.url) {
        window.location = res.body.url;
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const linkGoogle = async () => {
    try {
      setLinkGoogleDisabled(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await connectGoogleCalendar();
      if (res.success === true && res.body.url) {
        window.location = res.body.url;
      }
      dispatch(actions.persist_store({ loader: false }));
      setLinkGoogleDisabled(false);
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
      setLinkGoogleDisabled(false);
    }
  }

  const unlinkGoogle = async () => {
    try {
      setLinkGoogleDisabled(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(disconnectGoogleCalendar());
      dispatch(actions.persist_store({ loader: false }));
      setLinkGoogleDisabled(false);
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
      setLinkGoogleDisabled(false);
    }
  }

  const linkFullscript = async (country) => {
    try {
      let data = {
        country
      }
      dispatch(actions.persist_store({ loader: true }));
      let res = await connectFullscriptAccount(data);
      if (res.success === true && res.body.url) {
        window.location = res.body.url;
      }
      // if (res.success === true && res.body.url) {
      //   window.open(res.body.url);
      // }
      // dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const unlinkFullscript = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(disconnectFullscriptAccount());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const generatePBKey = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await generatePracticeBetterKey();
      console.log("response", response);
      if (response?.success === true && response?.body?.apiKey) {
        setPracticeBetterApiKey(response?.body?.apiKey);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const unlinkPracticeBetter = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(disconnectPracticeBetterAccount());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">3rd Party Integrations</h3>
      </div>
      <div className="TabBody py-3">
        <div className="py-2">
          <ul className="list-unstyled ps-0 mb-0 pt-2">
            <li className="d-flex align-items-start py-3 position-relative">
              {stripeConnection && stripeConnection.connection_response.status === true &&
                <Button
                  className="d-flex align-items-center CstmLabel justify-content-center position-absolute"
                  style={{
                    right: "10px",
                    top: "10px",
                    background: "#F1FFF1",
                    color: "#548B54",
                    borderColor: "#548B54",
                  }}
                >
                  {" "}
                  Linked
                </Button>
              }
              <div className="imgWrp me-2">
                <StripeIcn />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Stripe</h6>
                {stripeConnection && stripeConnection.connection_response.status === true ?
                  <>
                    <p className="m-0">Your Stripe account is linked.</p>
                    <p className="m-0">See how to accept payments for Appointments & Packages:</p>
                    <ul className="m-0">
                      <li className="py-1"><Link to={HelpAcceptPayment} target="_blank" className=" underline">Accept Appointment Payments</Link></li>
                      <li className="py-1"><Link to={HelpPackagesCreate} target="_blank" className=" underline">Create Client Packages</Link></li>
                      <li className="py-1"><Link onClick={openStripeDashboard} className=" underline">Open Stripe Portal</Link></li>
                    </ul>
                    <p className="m-0">
                      <Link onClick={unlinkStripe} className="underline">
                        Unlink your Stripe account
                      </Link>
                    </p>
                  </>
                  :
                  <>
                    {(!stripeConnection || stripeConnection.connection_response.status !== true) &&
                      <Button
                        className="d-flex fw-sbold my-2 align-items-center justify-content-center commonBtn btnSm"
                        onClick={linkStripe}
                        disabled={linkStripeDisabled}
                      >
                        <span className="icn me-2">
                          <LinkIcn />
                        </span>{" "}
                        {stripeConnection && stripeConnection.connection_response.status !== true ?
                          "Finish linking with Stripe"
                          :
                          "Link your Stripe account"
                        }
                      </Button>
                    }
                    <p className="m-0">
                      Not familiar with Stripe?{" "}
                      <Link
                        to={HelpStripeLinkAccount}
                        target="_blank"
                        className="underline"
                      >
                        Learn more about Stripe here.
                      </Link>{" "}
                    </p>
                  </>
                }
              </div>
            </li>
            <li className="d-flex align-items-start py-3 position-relative">
              {googleCalendarConnection && googleCalendarConnection.connected === true &&
                <Button
                  className="d-flex align-items-center CstmLabel justify-content-center position-absolute"
                  style={{
                    right: "10px",
                    top: "10px",
                    background: "#F1FFF1",
                    color: "#548B54",
                    borderColor: "#548B54",
                  }}
                >
                  {" "}
                  Linked
                </Button>
              }
              <div className="imgWrp me-2 GOOGLE">
                {/* <GoogleCalIcn /> */}
                <img src={GoogleCalIcn} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-sbold m-0 pb-1">Google Calendar</h6>
                </div>
                <p className="m-0">
                  Sync your Scheduler Appointments with your Google Calendar.
                  View the{" "}
                  <Link
                    to={HelpGoogleLinkAccount}
                    target="_blank"
                    className="underline"
                  >
                    Google Calendar Integration Guide
                  </Link>{" "}
                  for step by step instructions.
                </p>
                {googleCalendarConnection && googleCalendarConnection.connected === true &&
                  <p className="m-0">
                    <Link onClick={unlinkGoogle} className="underline">
                      Unlink your Google account
                    </Link>
                  </p>
                }
                {(!googleCalendarConnection || !googleCalendarConnection.connected) &&
                  <Button
                    className="d-flex fw-sbold my-2 align-items-center justify-content-center btnSm commonBtn"
                    onClick={linkGoogle}
                    disabled={linkGoogleDisabled}
                  >
                    <span className="icn me-2">
                      <LinkIcn />
                    </span>{" "}
                    Link your Google account
                  </Button>
                }
              </div>
            </li>
            <li className="d-flex align-items-start py-3 position-relative">
              {practiceBetterConnection &&
                <Button
                  className="d-flex align-items-center CstmLabel justify-content-center position-absolute"
                  style={{
                    right: "10px",
                    top: "10px",
                    background: "#F1FFF1",
                    color: "#548B54",
                    borderColor: "#548B54",
                  }}
                >
                  {" "}
                  Linked
                </Button>
              }
              <div className="imgWrp me-2 GOOGLE">
                <img src={PBetterIcn} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Practice Better</h6>
                {practiceBetterConnection ? (
                  <>
                    <p className="m-0">
                      Your Practice Better account is now linked
                    </p>
                    <p className="m-0 py-1">
                      <Link
                        className="underline d-block"
                        onClick={unlinkPracticeBetter}
                      >
                        Unlink your Practice Better account
                      </Link>{" "}
                    </p>
                  </>
                ):(
                  <>
                  {practiceBetterApiKey ? (
                  <>
                    <p className="m-0">
                      To link your Practice Better account, copy the Key below and then{" "}
                      <a href="#">
                        click here
                      </a>
                      {" "}to be taken to the
                      3rd Party Integrations section of your Practice Better account. Once on that page, scroll down
                      to the Nutri-Q section and follow the instructions, pasting in the Key from Nutri-Q when asked.
                      Once that's complete,{" "}
                      <Link
                        onClick={getThirdPartyIntegrations}
                      >
                        click here to refresh the status
                      </Link>
                      {" "}and verify that the accounts are now linked.
                    </p>
                    <Row className="py-2 align-items-center">
                      <Col lg="10">
                        <input
                          className="form-control"
                          value={practiceBetterApiKey}
                          disabled
                        />
                        </Col>
                        <Col lg="2">
                        <Button
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                          onClick={() => {
                            try {
                              navigator.clipboard.writeText(practiceBetterApiKey)
                              setPbKeyCopied(true);
                              timeoutId = setTimeout(() => {
                                setPbKeyCopied(false);
                              }, 3000)
                            } catch (err) {
                              console.log("Failed to copy to the clipboard");
                            }
                          }}
                        >
                          { pbKeyCopied ? "Copied!" : "Copy Key" }
                        </Button>
                        </Col>
                    </Row>
                    <p className="m-0">
                      View the{" "}
                      <Link
                        className="underline"
                        to={HelpPracticeLinkAccount}
                        target="_blank"
                      >
                        Practice Better Integration Guide
                      </Link>{" "}
                      for step by step instructions.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      Link with your Practice Better account to send NAQs and
                      receive results from within Practice Better. View the{" "}
                      <Link
                        className="underline"
                        to={HelpPracticeLinkAccount}
                        target="_blank"
                      >
                        Practice Better Integration Guide
                      </Link>{" "}
                      for step by step instructions.
                    </p>
                    <Button
                      className="d-flex fw-sbold my-2 align-items-center justify-content-center commonBtn btnSm"
                      onClick={generatePBKey}
                    >
                      <span className="icn me-2">
                        <LinkIcn />
                      </span>{" "}
                      Link your Practice Better account
                    </Button>
                  </>
                )}
                </>
                )}
                
              </div>
            </li>
            <li className="d-flex align-items-start py-3 position-relative">
              {fullscriptConnection &&
                <Button
                  className="d-flex align-items-center CstmLabel justify-content-center position-absolute"
                  style={{
                    right: "10px",
                    top: "10px",
                    background: "#F1FFF1",
                    color: "#548B54",
                    borderColor: "#548B54",
                  }}
                >
                  {" "}
                  Linked
                </Button>
              }
              <div className="imgWrp me-2 GOOGLE">
                {/* <FScriptIcn /> */}
                <img src={FScriptIcn} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Full Script</h6>
                {fullscriptConnection ?
                  <>
                    <p className="m-0">
                      Your Fullscript account is linked. <br />
                      View the{" "}
                      <Link
                        to={HelpFullScriptLinkAccount}
                        className="underline"
                        target="_blank"
                      >
                        Fullscript Integration Guide
                      </Link>{" "}
                      to see how to:
                    </p>
                    <ul className="m-0">
                      <li className="py-1">Link Clients</li>
                      <li className="py-1">Create Recommendations</li>
                      <li className="py-1">Generate Recommendations from a NAQ</li>
                      <li className="py-1">View Client Orders</li>
                    </ul>
                    <p className="m-0 py-1">
                      {/* <b>Linked Fullscript Practitioner:</b> Practitioner Ln
                      (fsprac@mailinator.com) */}
                      <Link
                        className="underline d-block"
                        onClick={unlinkFullscript}
                      >
                        Unlink your Fullscript account
                      </Link>{" "}
                    </p>

                  </>
                  :
                  <>
                    {showFullscriptCountries ?
                      <>
                        <p className="m-0 fw-bold">
                          In what country do you practice?
                        </p>
                        <ul className="d-flex p-0 py-2 gap-10">
                          <li
                            className={`btn btn-transparent border-1 border-black ${selectedCountry == "us" ? "active" : ""}`}
                            onClick={() => {
                              handleSelectFullscriptCountry("us");
                            }}
                          >
                            United States
                          </li>
                          <li
                            className={`btn btn-transparent border-1 border-black ${selectedCountry == "ca" ? "active" : ""}`}
                            onClick={() => {
                              handleSelectFullscriptCountry("ca");
                            }}
                          >
                            Canada
                          </li>
                        </ul>
                      </>
                      :
                      <>
                        <p className="m-0">
                          Link your Fullscript account to create recommendations using products from your Fullscript dispensary.
                          View the{" "}
                          <Link
                            to={HelpFullScriptLinkAccount}
                            target="_blank"
                            className="underline"
                          >
                            Fullscript Integration Guide
                          </Link>{" "}
                          for step by step instructions.
                        </p>
                        <Button
                          className="d-flex fw-sbold my-2 align-items-center justify-content-center commonBtn btnSm"
                          onClick={() => {
                            setShowFullscriptCountries(true);
                          }}
                        >
                          <span className="icn me-2">
                            <LinkIcn />
                          </span>{" "}
                          Link your Fullscript account
                        </Button>
                      </>
                    }
                  </>
                }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  let stripeConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "stripe");
  let googleCalendarConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "google");
  let fullscriptConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "full_script");
  let practiceBetterConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "practice_better");
  if (googleCalendarConnection) {
    googleCalendarConnection.connected = Boolean(googleCalendarConnection?.connection_response.access_token);
  }
  return {
    stripeConnection,
    googleCalendarConnection,
    fullscriptConnection,
    practiceBetterConnection
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ThirdPartyIntegeration);
