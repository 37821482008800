import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from 'yup';


const validation = Yup.object({
  selected_form: Yup.number().required("Please select a form")
});

const Form_ = ({
  handleSubmit,
  formList
}) => {
  return (
    <>
      <Formik
        initialValues={{
          selected_form: ""
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row>
              <Col lg="4" sm="6" className="my-2">
                <ul className="list-unstyled p-0 mb-0 ">
                  {formList && formList.length > 0 &&
                    formList.map(form => {
                      return (
                        <li key={form.id} className="">
                          <div className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp radio position-relative ">
                              <input
                                type="radio"
                                name="selected_form"
                                className="position-absolute w-100 h-100"
                                onBlur={handleBlur}
                                value={form.id}
                                onChange={e => {
                                  setFieldValue("selected_form", e.target.value);
                                }}
                              />
                              <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                <span className="icn d-none rounded-circle">
                                  {/* <OTick /> */}
                                </span>
                              </div>
                            </div>
                            <p className=" m-0 ">{form.form_name}</p>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
                {errors.selected_form && touched.selected_form && (
                  <p className="invalid-feedback d-block">{errors.selected_form}</p>
                )}
              </Col>
              <Col lg="12" className="my-2">
                <Button
                  type="submit"
                  className="d-flex align-items-center btnSm px-4 justify-content-center commonBtn"
                  disabled={isSubmitting}
                >
                  { isSubmitting ? "Please Wait..." : "Start" }
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Form_;
