import { Document, Page, Text, StyleSheet, View, Image, pdf } from '@react-pdf/renderer';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { useShowLoader } from 'hooks/useShowLoader';
import html2canvas from 'html2canvas';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  chartImage: {
    width: "100%",
    height: "auto"
  },
  naqList: {
    flexDirection: "row",
    gap: "10px",
  },
  naqItem: {
    borderBottom: "4px solid #000",
  },
  chartContainer: {
    marginTop: "15px",
    // border: "1px solid #ccc",
  }
});

export const NAQv2ComparisionGraphDoc = ({ base64Image, client, selectedNAQs, pdfLabel }) => {

  return (
    <Document>
      <Page orientation="landscape" style={styles.page} wrap={false}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            {pdfLabel}
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          {/* <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text> */}
        </View>
        <View style={styles.naqList}>
          {selectedNAQs && selectedNAQs.length > 0 &&
            selectedNAQs.map(naq => (
              <Text style={[styles.naqItem, { borderColor: `${naq.color}` }]}>
                {moment(naq.updated_at).format("MMM DD, YYYY")}
              </Text>
            ))
          }
        </View>
        <View style={styles.chartContainer}>
          <Image 
            style={styles.chartImage}
            src={base64Image}
          />
        </View>

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const DownloadComparisionGraph = ({ client, selectedNAQs, chartRef, pdfLabel }) => {

  const showLoader = useShowLoader();

  const downloadChartPdf = async () => {
    try {
      if(chartRef.current) {
        showLoader(true);
        const canvas = await html2canvas(chartRef.current, { scale: 3 })
        const base64Image = canvas.toDataURL('image/png');
        const doc = <NAQv2ComparisionGraphDoc base64Image={base64Image} client={client} selectedNAQs={selectedNAQs} pdfLabel={pdfLabel}/>;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `${pdfLabel.replaceAll(" ", "-")}.pdf`);
        showLoader(false);
      }
    } catch(err) {
      console.log("Error downloading graph", err);
      showLoader(false);
    }
  }

  return (
    <>
      <Button
        onClick={downloadChartPdf}
        className="d-flex align-items-center justify-content-center commonBtn btnSm"
      >
        <span className="icn me-2">
          <DownloadIcn />
        </span>
        Download Graph
      </Button>
    </>
  )
}

export default DownloadComparisionGraph;