import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";

//imported
import { addSubscriptionData } from "store/services/subscriptionService";
import * as Path from "Routes/paths";
import * as actions from "store/actions";
import Form from "./Form";

const NTAMemberInfo = ({
  dispatch,
  submitting,
  NTAMember,
  setNTAMember,
  packageDetail,
}) => {
  const handleNTAMember = () => setNTAMember(!NTAMember);
  const [step, setStep] = useState("1");
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    standard_account: "",
    nta_member_status: "",
  };

  const submitData_ = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        const { data } = response;
        if (data?.body?.url) {
          const isValidUrl =
            data.body.url.startsWith("http") ||
            data.body.url.startsWith("https");

          if (isValidUrl) {
            // Redirect to the URL
            window.location.href = data.body.url;
          }
        }
      };
      let final_res = {
        package_id: packageDetail?.pacakage_detail?.id,
        is_nta_package: "yes",
        nta_data : values
      };
      // console.log(final_res, "final_res")
      final_res = JSON.stringify(final_res);
      await dispatch(addSubscriptionData(final_res, callback));
      setNTAMember(false);
      setSubmitting(false);
      // setStep("2")
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        show={NTAMember}
        onHide={handleNTAMember}
        backdrop="static"
        keyboard={false}
        centered
        className="NTAMember FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">
            NTA Membership Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          {step == "1" ? (
            <>
              {" "}
              <Form
                step={step}
                // setStep={setStep}
                initialValues={initialValues}
                submitting={submitting}
                updateData_={submitData_}
                handleNTAMember={handleNTAMember}
              />
            </>
          ) : step == "2" ? (
            <>
              <div className="commonContent">
                <h3 className="m-0 py-2 fw-bold">Success!</h3>
                <p className="m-0 py-2">
                  You will now find a "Choose" button under the NTA Members plan
                  when you close this window. Click on that "Choose" button
                  under the NTA Members plan to continue the sign-up process.
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        {/* <Modal.Footer className="FormbtnWrpper">
          <Link
            to="/dashboard"
            className="d-flex btn text-white align-items-center justify-content-center commonBtn"
          >
            Submit
          </Link>
          <Button
            onClick={handleNTAMember}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    profileDetail: state.Profile.user,
    subscriptionList: state.Subscription.subscriptions,
    loader: state.Subscription.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NTAMemberInfo);
