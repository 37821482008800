import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
} from "react-bootstrap";
import StepWizard from "react-step-wizard";
import AddNewRecommendation from "./Component/AddNewRecommendation/Index";
import { addClientRecommendation, deleteRecommendation, getClientRecommendationList, getClientRecommendationReportsList, getRecommendationDetails, updateRecommendation, updateTodoStatus } from "store/services/clientService";
import { Link, useParams } from "react-router-dom";
import RecommSuccessPopup from "./Component/Modal/RecommSuccessPopup";
import { FULL_NAME } from "helpers/common";
import { CONFIRM_POPUP } from "common/constantMessage";
import { useShowLoader } from "hooks/useShowLoader";

const NewRecommendationWizard = ({ dispatch, recommendationId, data, handleClose, client, formId, onCompleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recommendationData, setRecommendationData] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const { clientId } = useParams();

  const showLoader = useShowLoader();

  let noTransitions = {
    enterRight: "",
    enterLeft: "",
    exitRight: "",
    exitLeft: "",
  };

  useEffect(() => {
    if(recommendationId) {
      getRecommendationData(recommendationId);
    }
  }, [recommendationId])
  
  const getRecommendationData = async (id) => {
    try {
      showLoader(true);
      setIsLoading(true);
      let data = {
        id,
      } 
      let response = await dispatch(getRecommendationDetails(data));
      if(response?.success) {
        setRecommendationData(response?.body);
      }
      showLoader(false);
      setIsLoading(false);
    } catch(error) {
      console.log("Error getting recommendation data", error);
      showLoader(false);
      setIsLoading(false);
    }
  }

  const saveRecommendation = async (data) => {
    if (data) {
      try {
        let postData = {
          recommendationId,
          values: {
            ...data,
            client_id: clientId,
          }
        }

        await dispatch(updateRecommendation(postData));
        // await dispatch(getClientRecommendationReportsList({ clientId }));
      } catch (error) {
        throw error;
      }
    }
  }

  const handleFinalSubmit = async (setSubmitting) => {
    try {
      setSubmitting(true);
      let data = {
        status: "completed",
        client_id: clientId,
      }
      await updateTodoStatus(recommendationId, data);
      await dispatch(getClientRecommendationReportsList({ clientId }));
      setSubmitting(false);
      onCompleted(formId);
    } catch (error) {
      console.log("Error in final submit", error);
      setSubmitting(false);
    }
  }

  const deleteCurrentAndGoBack = async () => {
    try {
      await CONFIRM_POPUP()
      .then(async (isConfirm) => {
        if(isConfirm) {
          if (recommendationId) {
            let data = {
              id: recommendationId
            }
            await dispatch(deleteRecommendation(data));
          }
          handleClose();
        }
      })
      .catch(err => {
        console.log("Error deleting  recommendation", err);
        throw err;
      })
      
    } catch(err) {
      console.log("Error deleting recommendation", err);
      setProcessing(false);
    }
  }

  return (
    <>
      <RecommSuccessPopup
        showPopup={showSuccessPopup}
        setShowPopup={setShowSuccessPopup}
        closePage={handleClose}
      />
      <section className="myClients">
        <Row className="align-items-start">
          <Col lg="12" className="my-2">
            {isLoading === true ?
              <p>Loading...</p>
              :
              <StepWizard transitions={noTransitions}>
                <Start
                  client={client}  
                />
                <AddNewRecommendation
                  data={recommendationData}
                  saveData={saveRecommendation}
                />
                <Finish
                  recommendationId={recommendationId}
                  handleSubmit={handleFinalSubmit}
                  client={client}
                />
              </StepWizard>
            }
          </Col>
        </Row>
        {/* <Row>
          <div className="py-2">
            <p className="m-0 text-dark">
              Pause this to-do item and go back to the Add Data page by{" "}
              <Link
                onClick={handleClose}
                to=""
                className="underline"
              >
                clicking here
              </Link>{" "}
              .
            </p>
            <p className="m-0 text-dark">
              Delete this to-do item and go back to the Add Data page by{" "}
              <Link onClick={deleteCurrentAndGoBack} to="" className="underline">
                clicking here
              </Link>{" "}
              .
            </p>
          </div>
        </Row> */}
      </section>
    </>
  );
};

const Start = ({ nextStep, previousStep, currentStep, totalSteps, client }) => {
  return (
    <section className="recommendation-step">
      <Row className="align-items-start">
        <Col lg="7" className="my-2">
          <div className="intro">
            <h2 className="m-0 fw-bold">Hello</h2>
            <p className="m-0 py-2">Fill out Nutritional and Lifestyle Recommendations for {FULL_NAME(client.first_name, client.last_name)}. Once completed, this form is not visible to {FULL_NAME(client.first_name, client.last_name)} until you publish it.</p>
          </div>
        </Col>
        <Col lg="12" className="d-flex gap-10">
          <Button
            onClick={nextStep}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            Next
          </Button>
        </Col>
      </Row>
    </section>
  )
}

const Finish = ({ handleSubmit, nextStep, previousStep, currentStep, totalSteps }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  return (
    <section className="recommendation-step">
      <Row className="align-items-start">
        <Col lg="7" className="my-2">
          <div className="intro">
            <h2 className="m-0 fw-bold">Almost Done!</h2>
            <p className="m-0 py-2">All of your data looks good. Please click 'Submit' to save everything off and exit. You can find the submitted information on the 'Recommendations' tab once you click 'Submit'.</p>
          </div>
        </Col>
        <Col lg="12" className="d-flex gap-10">
          <Button
            onClick={previousStep}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              handleSubmit(setSubmitting);
            }}
            disabled={isSubmitting}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            {isSubmitting ? "Saving..." : "Submit"}
          </Button>
        </Col>
      </Row>
    </section>
  )
}

const mapStateToPros = (state, props) => {
  let recommendationData = null;
  if (props.recommendationId) {
    recommendationData = state.Client.currentRecommendationData;
    if (
      (recommendationData.additional_recommendation && typeof recommendationData.additional_recommendation == "string")
      &&
      (recommendationData.recommendation && typeof recommendationData.recommendation == "string")
    ) {
      try {
        recommendationData.additional_recommendation = JSON.parse(recommendationData.additional_recommendation);
        recommendationData.recommendation = JSON.parse(recommendationData.recommendation);
      } catch (error) {
        console.log("Error parsing JSON data", error);
      }
    }
  } else {
    recommendationData = {}
  }

  return {
    data: recommendationData,
    client: state.Client.client.details,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NewRecommendationWizard);