import { secondsToMinutesAndSeconds } from 'helpers/common';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const timerDuration = 30; // 30 seconds - 0.5 minutes
const OTPTimer = ({ onResend }) => {
  const [timeLeft, setTimeLeft] = useState(timerDuration);
  const [isTimerRunning, setTimeRunning] = useState(true);

  const { minutes, seconds } = secondsToMinutesAndSeconds(timeLeft);

  useEffect(() => {
    let interval;
    if(isTimerRunning) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if(timeLeft === 0) {
            setTimeRunning(false);
            setTimeLeft(0);
            return 0;
          } else {
            return prevTime - 1;
          }
        })
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    }
  }, [])

  const restartTimer = () => {
    setTimeLeft(timerDuration);
    setTimeRunning(true);
  }

  const handleClickResend = async () => {
    onResend(restartTimer);
  }

  return (
    <div className="resend-otp-timer">
      {(isTimerRunning && timeLeft > 0) ? (
        <p className='m-0'>
          Resend OTP in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{' '}
          seconds
        </p>
      ):(
        <Link
          onClick={handleClickResend}
        >
          Resend OTP
        </Link>
      )}
    </div>
  )
};

export default OTPTimer;