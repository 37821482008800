import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../../Assets/Images/Ss_ap1.png";
import s2 from "../../../../Assets/Images/Ss_ap2.png";
import s3 from "../../../../Assets/Images/Ss_ap3.png";
import s4 from "../../../../Assets/Images/Ss_ap4.png";
import s5 from "../../../../Assets/Images/Ss_ap5.png";
import s6 from "../../../../Assets/Images/Ss_ap6.png";
import s7 from "../../../../Assets/Images/Ss_ap7.png";
import s8 from "../../../../Assets/Images/Ss_ap8.png";
import s9 from "../../../../Assets/Images/Ss_ap9.png";
import s10 from "../../../../Assets/Images/Ss_ap10.png";
import s11 from "../../../../Assets/Images/Ss_ap11.png";
import s12 from "../../../../Assets/Images/Ss_ap12.png";
import s13 from "../../../../Assets/Images/Ss_ap13.png";
import s14 from "../../../../Assets/Images/Ss_ap14.png";
import s15 from "../../../../Assets/Images/Ss_ap15.png";
import s16 from "../../../../Assets/Images/Ss_ap16.png";
import s17 from "../../../../Assets/Images/Ss_ap17.png";
import s18 from "../../../../Assets/Images/Ss_ap18.png";
import s19 from "../../../../Assets/Images/Ss_ap19.png";

const HelpAcceptPayment = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContnet py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Accepting Appointment Payments{" "}
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                </div>
                <div className="py-2 my-2">
                  <p class="m-0">
                    If you've already
                    <Link to="" className="themeClr">
                      linked your Stripe Account
                    </Link>{" "}
                    , you can add prices and require payment at the time of
                    appointment bookings. To set this up, we'll head over to the
                    'Appointments' section of the site by clicking on
                    'Appointments' in the main navigation:
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                  <p className="m-0 py-2">
                    If you haven't set up your Appointment Scheduler yet, pause
                    here and head over to the Appointment Scheduler Guide to do
                    that first.
                  </p>
                  <p className="m-0 py-2">
                    For the rest of this article, we'll assume you've already
                    set up your Appointment Scheduler. So from the main
                    Appointment page, we'll go ahead and click on the 'Scheduler
                    Settings' link in the top right corner:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s15} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    We'll scroll down the Scheduler Settings page until we find
                    the 'Appointment Types' section that lists our existing
                    appointment types. Here we'll click the 'edit' link next to
                    any of our Appointment Types to reach the edit screen.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s15} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    In our example, we'll update our existing '60 Minute
                    Session' appointment type to require payment by first
                    clicking on the 'Edit/Update' button for that appointment
                    type. The same following steps will apply if you are
                    creating a new appointment type using the green 'Add New
                    Appointment Type' button.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    In our example, we'll update our existing '60 Minute
                    Session' appointment type to require payment by first
                    clicking on the 'Edit/Update' button for that appointment
                    type. The same following steps will apply if you are
                    creating a new appointment type using the green 'Add New
                    Appointment Type' button.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    In the edit view, we'll see a new section titled 'Payment':
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Check the 'Require payment to book this appointment'
                    checkbox and add the price that you'd like to charge. We'll
                    choose $250 (USD) and the text under the entry then lets us
                    know that "A payment of $250.00 (USD) will be required to
                    book this appointment type." Other currencies are also
                    available in the dropdown.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    We aren't going to change anything else with this
                    appointment type so we'll scroll down to the bottom and
                    click 'Save'.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    That's it. Now clients will be required to pay when they
                    book this type of appointment. In the next section, we'll
                    show what that process looks like.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpAcceptPayment;
