import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import { connect } from "react-redux";
import { toggleAddQuestionPopup } from "store/actions/myForm";

// css
import "./NewQuestion.scss";

// icn
import { ReactComponent as OTick } from "../../../../../../../../Assets/Images/icn/OrangeTick.svg";
import { addFormPage, addFormPageQuestion, editFormPageQuestion, getFormQuestionTypes } from "store/services/myFormService";
import { SUCCESS_MESSAGE } from "common/constantMessage";
import CheckboxOptions from "./Components/CheckboxOptions";
import RadioOptions from "./Components/RadioOptions";
import QuestionFields from "./Components/Index";
import { validation } from "./NewQuestionValidation";

const NewQuestionPopup = ({
  dispatch,
  showPopup,
  isEditMode,
  questionTypes,
  formId,
  pageId,
  editQuestionId,
  questionDetails,
  formDetails,
  getFormDetails
}) => {
  const [dependentSourceQId, setDependentSourceQId] = useState(questionDetails?.id);
  const [dependableQuestions, setDependableQuestions] = useState(null);
  const [otherColumnData, setOtherColumnData] = useState([]);
  const [showNoDependentMessage, setShowNoDependentMessage] = useState(false);
  
  const formRef = useRef();
  const handleClose = () => dispatch(toggleAddQuestionPopup(false));

  useEffect(() => {
      setDependentSourceQId(questionDetails?.id);
      setOtherColumnData(
        questionDetails?.other_column_data &&
        JSON.parse(questionDetails?.other_column_data)
      );
  }, [editQuestionId, questionDetails])

  useEffect(() => {
    try {
      dispatch(getFormQuestionTypes());
    } catch(error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    // get the questions that can be selected for dependency
    if(showPopup) {
      let allQuestions = [];
      for(let i = 0; i < formDetails?.pages?.length; i++) {
        let questions = formDetails.pages[i].questions;
        allQuestions = [...allQuestions, ...questions];
      }

      if(dependentSourceQId) {
        let currentQuestionIndex = allQuestions.findIndex(q => q.id === editQuestionId);
        allQuestions = allQuestions.splice(0, currentQuestionIndex);
      }
      let questionsEligible = allQuestions.filter(q => q.form_column_id == 3 || q.form_column_id == 7);
      setDependableQuestions(questionsEligible);
    }
  }, [showPopup, dependentSourceQId])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      // if there is no page created then first create a new page to add the question to
      if(!values.form_page_id) {
        let createPageData = {
          form_id: values.form_id,
        }
        let res = await dispatch(addFormPage(createPageData));
        values.form_page_id = res.body.id;
      }

      const callback = async (response) => {
        if (response.success === true && response.message) {
          SUCCESS_MESSAGE(response.message);
        }
      }

      // stringify columns
      if(Array.isArray(values.depend_q_ans)) {
        values.depend_q_ans = JSON.stringify(values.depend_q_ans);
      }

      if(
        values.other_column_data &&
        typeof values.other_column_data == "object" &&
        (Array.isArray(values.other_column_data) || Object.keys(values.other_column_data).length > 0)
      ) {
        values.other_column_data = JSON.stringify(values.other_column_data);
      }
      
      // prevent unwanted values from being submitted
      delete(values.dependentQuestion);

      if (isEditMode == true) {
        // update the question
        let postData = {
          editQuestionId,
          values
        }
        await dispatch(editFormPageQuestion(postData, callback));
      } else {
        // create question
        let data = [
          values
        ]
        data = JSON.stringify(data);
        await dispatch(addFormPageQuestion(data, callback));
      }

      await getFormDetails();
      setSubmitting(false);
      handleClose();
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  }

  const getQuestionSorting = () => {
    let allQuestions = [];
    for(let i = 0; i < formDetails?.pages?.length; i++) {
      let questions = formDetails.pages[i].questions;
      allQuestions = [...allQuestions, ...questions];
    }

    return allQuestions.length + 1;
  }
  
  return (
    <>
      <Formik
        initialValues={{
          question: isEditMode ? questionDetails.question : "",
          question_help: isEditMode ? questionDetails.question_help : "",
          is_required: isEditMode ? questionDetails.is_required : "no",
          form_id: formId,
          form_page_id: pageId,
          form_column_id: isEditMode ? questionDetails.form_column_id : questionTypes[0]?.id || "",
          other_column_data: isEditMode ? 
            (questionDetails.other_column_data && JSON.parse(questionDetails.other_column_data)) : null,
          sorting: isEditMode ? questionDetails.sorting : getQuestionSorting(),
          dependentQuestion: isEditMode ? !!questionDetails.depend_q_id : false,
          depend_q_id: isEditMode ? questionDetails.depend_q_id : null,
          depend_q_ans: isEditMode ?
            (questionDetails.depend_q_ans && JSON.parse(questionDetails.depend_q_ans)) : "",
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
          <Modal
            show={showPopup}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            scrollable={true}
            className="NewFormPop FormPop"
          >
              <Modal.Header className="">
                <Modal.Title className="fw-bold">{isEditMode ? "Update Question" : "New Question"} </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-lg-4">
                
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Row className="">
                  <Col lg="4" className="my-2">
                    <label
                      htmlFor=""
                      className="form-label pb-1 m-0 fw-sbold text-dark"
                    >
                      Question Type (required)
                    </label>
                    <Form.Select
                      className={`form-control text-muted ${errors.form_column_id && 'is-invalid'}`}
                      aria-label="Default select example"
                      name="form_column_id"
                      onChange={(e) => {
                        setFieldValue("form_column_id", e.target.value);
                        setFieldValue("other_column_data", []);
                        setOtherColumnData([]);
                      }}
                      placeholder="Page Title"
                      onBlur={handleBlur}
                      value={values.form_column_id}
                    >
                      { questionTypes.length &&
                        questionTypes.map((type) => {
                          return <option value={type.id}>{type.column_label}</option>
                        })
                      }
                    </Form.Select>
                    {(errors.form_column_id && touched.form_column_id) &&
                      <p className="invalid-feedback">{errors.form_column_id}</p>
                    }
                    {/* <p className="formText m-0 py-1">
                      Address: Formatted entry fields for address.
                    </p> */}
                  </Col>
                  {values.form_column_id != 4 &&
                    <>
                      <Col lg="12" className="my-2">
                        <label
                          htmlFor=""
                          className="form-label pb-1 m-0 fw-sbold text-dark"
                        >
                          Question Text (required)
                        </label>
                        <p className="m-0 FormText">The question you want answered.</p>
                        <input
                          type="text"
                          className={`form-control ${errors.question && 'is-invalid'}`}
                          name="question"
                          onChange={handleChange}
                          placeholder=""
                          onBlur={handleBlur}
                          value={values.question}
                          autoComplete="off"
                        />
                        {(errors.question && touched.question) &&
                          <p className="invalid-feedback">{errors.question}</p>
                        }
                      </Col>
                      <Col lg="12" className="my-2">
                        <label
                          htmlFor=""
                          className="form-label pb-1 m-0 fw-sbold text-dark"
                        >
                          Help Text (optional)
                        </label>
                        <p className="m-0 FormText">
                          Any additional information or clarification that might help
                          answer the question.
                        </p>
                        <input
                          type="text"
                          className={`form-control ${errors.question_help && 'is-invalid'}`}
                          name="question_help"
                          onChange={handleChange}
                          placeholder=""
                          onBlur={handleBlur}
                          value={values.question_help}
                          autoComplete="off"
                        />
                        {(errors.question_help && touched.question_help) &&
                          <p className="invalid-feedback">{errors.question_help}</p>
                        }
                      </Col>
                    </>
                  }
                  <Col lg="12" className="my-2">
                    <QuestionFields
                      questionTypeId={values.form_column_id}
                      columnData={otherColumnData}
                      setColumnData={(data) => {
                        setOtherColumnData(data);
                        setFieldValue("other_column_data", data)
                      }}
                      isValid={!errors.other_column_data}
                      errors={errors.other_column_data || {}}
                      setFieldValue={setFieldValue}
                    />
                    <hr></hr>
                    {/* {values.form_column_id == 3 &&
                      <CheckboxOptions
                        columnData={otherColumnData}
                        setColumnData={(data) => {
                          setOtherColumnData(data);
                          setFieldValue("other_column_data", data)
                        }}
                        isValid={!errors.other_column_data}
                      />
                    }
                    {values.form_column_id == 7 &&
                      <RadioOptions
                        columnData={otherColumnData}
                        setColumnData={(data) => {
                          setOtherColumnData(data);
                          setFieldValue("other_column_data", data)
                        }}
                        isValid={!errors.other_column_data}
                      />
                    } */}
                    {(errors.other_column_data && touched.other_column_data) && (typeof errors.other_column_data == "string") &&
                      <p className="invalid-feedback">{errors.other_column_data}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    {values.form_column_id != 4 &&
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            className={`position-absolute w-100 h-100 ${errors.is_required && 'is-invalid'}`}
                            name="is_required"
                            onChange={(e) => {
                              setFieldValue("is_required", e.target.checked ? "yes" : "no");
                            }}
                            onBlur={handleBlur}
                            checked={values.is_required === "yes"}
                            value={values.is_required}
                            autoComplete="off"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">Require clients answer this question</p>
                      </div>
                    }
                    <div className="py-2 d-flex align-items-start gap-10">
                      <div className="cstmInputCheckWrp position-relative ">
                        <input
                          type="checkbox"
                          name="c-ToDo"
                          className="position-absolute w-100 h-100"
                          onChange={(e) => {
                            setFieldValue("dependentQuestion", e.target.checked);
                            setFieldValue("depend_q_id", null);
                            setFieldValue("depend_q_ans", "");
                          }}
                          checked={values.dependentQuestion}
                          disabled={!dependableQuestions || !dependableQuestions?.length}
                        />
                        <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                          <span className="icn d-none">
                            <OTick />
                          </span>
                        </div>
                      </div>
                      <p className=" m-0 ">
                        Make showing this question dependent on the answer to a
                        previous question{" "}
                        {!dependableQuestions || !dependableQuestions?.length &&
                          <Link
                            className="underline"
                            onClick={() => setShowNoDependentMessage(!showNoDependentMessage)}
                          >
                            Why can't I select this?
                          </Link>
                        }
                      </p>
                    </div>
                    {showNoDependentMessage &&
                      <p className="m-0">To make a question dependent on another question, you must have at least one previous Radio Button or Checkbox style question.</p>
                    }
                  </Col>
                  {values.dependentQuestion && 
                    <Col lg="12" className="my-2">
                      <label
                        htmlFor=""
                        className="form-label pb-1 m-0 fw-sbold text-dark"
                      >
                        Dependent Question
                      </label>
                      <p className="m-0">
                        Select the question used to determine if this question is displayed or not. Only radio button or checkbox questions prior to this question can be selected.
                      </p>
                      <Form.Select
                        className={`form-control text-muted ${errors.depend_q_id && 'is-invalid'}`}
                        aria-label="Default select example"
                        name="depend_q_id"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("depend_q_ans", "");
                        }}
                        placeholder="Page Title"
                        onBlur={handleBlur}
                        value={values.depend_q_id}
                      >
                        <option value="">Select Question</option>
                        {dependableQuestions && dependableQuestions.length > 0 &&
                          dependableQuestions.map(question => (
                            <option value={question.id}>{question.question}</option>
                          ))
                        }
                      </Form.Select>
                      {(errors.depend_q_id && touched.depend_q_id) &&
                        <p className="invalid-feedback">{errors.depend_q_id}</p>
                      }
                    </Col>
                  }
                  {values.dependentQuestion && dependableQuestions && dependableQuestions.length > 0 &&
                    dependableQuestions.map(question => {
                      if(values.depend_q_id != question.id) {
                        return "";
                      }

                      let options = question.other_column_data && JSON.parse(question.other_column_data);

                      return (
                        <Col lg="12" className="my-2">
                          <p className={`m-0 ${errors.depend_q_ans && 'is-invalid'}`}>
                            Which of the following answer(s) should result in this question being displayed?
                          </p>
                          {options && options.length > 0 &&
                            options.map(option => (
                              <div className="py-2 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp position-relative">
                                  <input
                                    type="checkbox"
                                    className={`position-absolute w-100 h-100`}
                                    name="depend_q_ans"
                                    onChange={handleChange}
                                    value={option}
                                    checked={values.depend_q_ans && values.depend_q_ans.includes(option)}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                    <span className="icn d-none">
                                      <OTick />
                                    </span>
                                  </div>
                                </div>
                                <p className=" m-0 ">{option}</p>
                              </div>
                            ))
                          }
                          {(errors.depend_q_ans && touched.depend_q_ans) &&
                            <p className="invalid-feedback">{errors.depend_q_ans}</p>
                          }
                        </Col>
                      )
                    })
                  }
                  <Col lg="12" className="my-2 FormbtnWrpper justify-content-start d-flex">
                   <Button
                      type="submit"
                      className="d-flex align-items-center justify-content-center commonBtn"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving..." : isEditMode ? "Save" : "Add"}
                    </Button>
                    <Button
                      onClick={handleClose}
                      className="d-flex align-items-center justify-content-center commonBtn"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                </Form>
              </Modal.Body>
              {/* <Modal.Footer className="FormbtnWrpper justify-content-start">
              </Modal.Footer> */}
          </Modal>
        )
        }}
      </Formik>
    </>
  );
};

const mapStateToPros = (state) => {
  let newState = {
    formDetails: state.MyForm.formDetails,
    questionTypes: state.MyForm.formQuestionTypes,
    pageId: state.MyForm.addQuestionPopup?.pageId,
    editQuestionId: state.MyForm?.addQuestionPopup?.editQuestionId,
    questionDetails: {},
  }
  if(newState.editQuestionId ){
    let formDetails = state.MyForm.formDetails;
    let editQuestionId = newState.editQuestionId;
    let pageDetails = newState.pageId && formDetails.pages.find((page) => page.id === newState.pageId );
    let questionDetails = null;
    if(pageDetails) {
      questionDetails = pageDetails?.questions.find((question) => question.id === editQuestionId );
    } else {
      questionDetails = formDetails.pages[0].questions.find((question) => question.id === editQuestionId );
    }

    newState.isEditMode = true;
    newState.editQuestionId = editQuestionId;
    newState.questionDetails = questionDetails;
  }

  return newState
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NewQuestionPopup);