import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Tooltip } from 'react-tooltip'

// css
import "./Filter.scss";

// icn
import { ReactComponent as SearchIcn } from "../../../../../Assets/Images/icn/search.svg";
import { ReactComponent as OTick } from "../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as CloseBtn } from "../../../../../Assets/Images/icn/close.svg";
import { ReactComponent as PencilIcn } from "../../../../../Assets/Images/icn/pencil.svg";
import { ReactComponent as DeleteIcn } from "../../../../../Assets/Images/icn/delete.svg";

import AddTagPop from "../Modal/AddTagPop";
import AddTagPopAssign from "../Modal/AddTagPopAssign";
import {
  addTagsData,
  getTagsList,
  updateTagsData,
} from "store/services/tagsService";

const Filter = ({
  FilterSidebar,
  setFilterSidebar,
  tagPop,
  setTagPop,
  tagPopAssign,
  setTagPopAssign,
  handleAddTag,
  dispatch,
  tagList,
  assignTagsClient,
  setCurrentClient,
  currentClient,
  setCurrentTags,
  currentTags,
  currentGroup,
  setCurrentGroup,
  archivedGroup,
  setArchivedGroup,
  activeType,
  setActiveType,
  linkedType,
  setLinkedType,
  unlinkedType,
  setUnlinkedType,
  activePackage,
  setActivePackage,
  selectedCheckboxes,
  setSelectedCheckboxes,
  searchTerm,
  setSearchTerm,
  onlyFavorites,
  setOnlyFavorites,
  clearAllfiltr,
  clientTagCounts,
  handlePageChange
}) => {
  const [sending, setSending] = useState(false);
  const [colorCode, setColorCode] = useState("");
  const [sateCurrentValue, setSateCurrentValue] = useState(null);

  const [fields, setfields] = useState({
    name: "",
    color_code: "#FFFFFF",
  });

  const handleFilterSidebar = () => {
    setFilterSidebar(!FilterSidebar);
  };
  const onChangeColorHandleChange = async (color) => {
    setColorCode(color.hex);
  };
  //get tag list
  async function getTagsList_() {
    try {
      await dispatch(getTagsList());
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (sateCurrentValue) {
      setfields((prevState) => ({
        ...prevState,
        ...sateCurrentValue,
      }));
      setColorCode(sateCurrentValue.color_code);
    }
    if (sateCurrentValue == null) {
      setfields({});
    }
  }, [sateCurrentValue]);

  useEffect(() => {
    getTagsList_();
  }, [colorCode]);

  //handle changes
  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );
  const submitData_ = async (evt) => {
    try {
      evt.preventDefault();
      let obj = {
        name: fields.name,
        color_code: colorCode,
      };
      obj = JSON.stringify(obj);
      setSending(true);
      if (sateCurrentValue) {
        let fieldId = sateCurrentValue.id;
        await dispatch(updateTagsData(fieldId, obj));
      } else {
        await dispatch(addTagsData(obj));
      }
      setfields({});
      setSending(false);
      setSateCurrentValue(null);
      setTagPop(false);
      getTagsList_();
    } catch (err) {
      console.log(err, "==============================");
      setSending(false);
    }
  };
  //filter group
  const currentResult = () => {
    setCurrentGroup(!currentGroup);
  };
  const archivedGroupResult = () => {
    setArchivedGroup(!archivedGroup);
  };

  //filter types
  const activeTypes_ = () => {
    setActiveType(!activeType);
  };
  const linkedType_ = () => {
    setLinkedType(!linkedType);
  };
  const unlinkedType_ = () => {
    setUnlinkedType(!unlinkedType);
  };
  //active package
  const packageActive_ = () => {
    setActivePackage(!activePackage);
  };

  //tags filter
  const handleCheckboxChange = (event, checkboxId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxId]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== checkboxId)
      );
    }
  };

  return (
    <>
      <AddTagPop
        tagPop={tagPop}
        setTagPop={setTagPop}
        fields={fields}
        submitData_={submitData_}
        onChangeColorHandleChange={onChangeColorHandleChange}
        handleChange={handleChange}
        colorCode={colorCode}
        sending={sending}
        setSending={setSending}
        sateCurrentValue={sateCurrentValue}
        setSateCurrentValue={setSateCurrentValue}
      />

      <AddTagPopAssign
        tagPopAssign={tagPopAssign}
        setTagPopAssign={setTagPopAssign}
        tagList={tagList}
        assignTagsClient={assignTagsClient}
        setCurrentClient={setCurrentClient}
        currentClient={currentClient}
        setCurrentTags={setCurrentTags}
        currentTags={currentTags}
        setTagPop={setTagPop}
      />

      <Col lg="3" className={`my-2 filterWrpper ${FilterSidebar && "active"}`}>
        <div className="top position-relative p-3 d-lg-none">
          <Button
            onClick={handleFilterSidebar}
            className="border-0 p-0 position-absolute closeBtn align-items-center justify-content-center rounded-0"
            style={{ right: "0", top: "0", background: "#000" }}
          >
            <CloseBtn />
          </Button>
        </div>
        <div className="FilterWrp">
          <div className="SectionHeader">
            <h3 className="pagetitle m-0 py-2 text-uppercase fw-bold">
              Filters
            </h3>
          </div>
          <Form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <div className="position-relative searchForm">
              <div className="iconWithText position-relative">
                <span className="icn position-absolute">
                  <SearchIcn />
                </span>
                <input
                  type="search"
                  placeholder="Search Client"
                  className="form-control"
                  name="search"
                  autoComplete="off"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <Button
                className="p-0  border-0 underline fw-sbold mt-2"
                variant={"transparent"}
                type={"reset"}
                onClick={() => clearAllfiltr()}
              >
                Reset All Filters
              </Button>
            </div>
            <div className="py-2">
              <div className="cstmCard p-3 p-lg-4 border">
                <div className="top mb-2 d-flex gap-10">
                  <h6 className="fw-sbold m-0 text-uppercase">Group</h6>
                  <div className="tooltip-container">
                    <a
                      className="tooltip-trigger"
                      data-tooltip-id="group-filter-tooltip"
                      data-tooltip-content="Filter by group: Choose specific groups to organize and categorize your result."
                      data-tooltip-place="right"
                    >
                      <i className="fa fa-info-circle"></i>
                    </a>
                    <Tooltip id="group-filter-tooltip" />
                  </div>
                </div>
                <div className="list">
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="current"
                            className="position-absolute w-100 h-100"
                            onClick={() => currentResult()}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Current</p>
                      </div>
                    </li>
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="archived"
                            className="position-absolute w-100 h-100"
                            onClick={() => archivedGroupResult()}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Archived</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="cstmCard p-3 p-lg-4 border">
                <div className="top mb-2 d-flex gap-10">
                  <h6 className="fw-sbold m-0 text-uppercase">Type</h6>
                  <div className="tooltip-container">
                    <a
                      className="tooltip-trigger"
                      data-tooltip-id="type-filter-tooltip"
                      data-tooltip-content="Filter by type: Select specific types to narrow down your search."
                      data-tooltip-place="right"
                    >
                      <i className="fa fa-info-circle"></i>
                    </a>
                    <Tooltip id="type-filter-tooltip" />
                  </div>
                </div>
                <div className="list">
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="active"
                            className="position-absolute w-100 h-100"
                            onClick={() => activeTypes_()}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Active</p>
                      </div>
                    </li>
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="direct_link"
                            className="position-absolute w-100 h-100"
                            onClick={() => linkedType_()}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Direct Link</p>
                      </div>
                    </li>
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="unlinked"
                            className="position-absolute w-100 h-100"
                            onClick={() => unlinkedType_()}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Unlinked</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="cstmCard p-3 p-lg-4 border">
                <div className="top mb-2 d-flex gap-10">
                  <h6 className="fw-sbold m-0 text-uppercase">Favorites</h6>
                  <div className="tooltip-container">
                    <a
                      className="tooltip-trigger"
                      data-tooltip-id="type-filter-tooltip"
                      data-tooltip-content="Filter by favourite: Show only items marked as favourite."
                      data-tooltip-place="right"
                    >
                      <i className="fa fa-info-circle"></i>
                    </a>
                    <Tooltip id="favorite-filter-tooltip" />
                  </div>
                </div>
                <div className="list">
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="only_favorites"
                            className="position-absolute w-100 h-100"
                            onClick={(e) => {
                              setOnlyFavorites(e.target.checked ? "yes": "no");
                            }}
                            value="yes"
                            checked={onlyFavorites === "yes"}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Only Favorites</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="cstmCard p-3 p-lg-4 border">
                <div className="top mb-2 d-flex gap-10">
                  <h6 className="fw-sbold m-0 text-uppercase">Package</h6>
                  <div className="tooltip-container">
                    <a
                      className="tooltip-trigger"
                      data-tooltip-id="package-filter-tooltip"
                      data-tooltip-content="Filter by user package: Select specific user packages to refine your search results."
                      data-tooltip-place="right"
                    >
                      <i className="fa fa-info-circle"></i>
                    </a>
                    <Tooltip id="package-filter-tooltip" />
                  </div>
                </div>
                <div className="list">
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="">
                      <div className="py-2 d-flex align-items-start">
                        <div className="cstmInputCheckWrp position-relative me-2">
                          <input
                            type="checkbox"
                            name="package"
                            className="position-absolute w-100 h-100"
                            onClick={() => packageActive_()}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 fw-sbold">Active Package</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="cstmCard p-3 p-lg-4 border">
                <div className="top mb-2 d-flex gap-10">
                  <h6 className="fw-sbold m-0 text-uppercase">Tag</h6>
                  <div className="tooltip-container">
                    <a
                      className="tooltip-trigger"
                      data-tooltip-id="tag-filter-tooltip"
                      data-tooltip-content="Filter by user tags: Choose specific user tags to categorize and organize your items based on user-defined labels."
                      data-tooltip-place="right"
                    >
                      <i className="fa fa-info-circle"></i>
                    </a>
                    <Tooltip id="tag-filter-tooltip" />
                  </div>
                </div>
                <div className="list">
                  <ul className="list-unstyled p-0 mb-0">
                    {tagList.length > 0 &&
                      tagList.map((val, i) => {
                        let tagDetails = clientTagCounts.find(tag => tag.tag_name == val.name);
                        return (
                          <li className="" key={i}>
                            <div className="py-2 d-flex align-items-start">
                              <div className="cstmInputCheckWrp position-relative me-2">
                                <input
                                  type="checkbox"
                                  name="tags"
                                  className="position-absolute w-100 h-100"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, val.id)
                                  }
                                />
                                <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                  <span className="icn d-none">
                                    <OTick />
                                  </span>
                                </div>
                              </div>
                              <span
                                className="CstmLabel fw-sbold"
                                style={{
                                  borderColor: val.color_code,
                                  color: "#fff",
                                  background: val.color_code,
                                }}
                              >
                                {val.name} ({tagDetails?.count || 0})
                              </span>
                              {/* <span
                                className="px-2"
                              >
                                <Link
                                  to=""
                                >
                                  <PencilIcn />
                                </Link>
                              </span>
                              <span
                                className="px-2"
                              >
                                <Link
                                  to=""
                                >
                                  <DeleteIcn />
                                </Link>
                              </span> */}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="btnWrp mt-3">
                    <Button
                      onClick={handleAddTag}
                      className="d-flex w-100 align-items-center justify-content-center commonBtn btnSm"
                    >
                      + Add Tag
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    tagList: state.Tags.tags,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Filter);
