import { Button, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from 'yup';

const Form_ = ({
    initialValues,
    handleSubmit,
    handleClose
  }) => {

    const handleUpdateUserDetails = async (values, actions) => {
      try {
        await handleSubmit(values, actions);
        handleClose();
      } catch(err) {
        console.log("Error saving user details", err);
      }
    }

    return (
      <>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation}
          onSubmit={handleUpdateUserDetails}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    Email*
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                    name="email"
                    onChange={handleChange}
                    placeholder="Email"
                    onBlur={handleBlur}
                    value={values.email}
                    autoComplete="off"
                  />
                  {errors.email && touched.email && (
                    <p className="invalid-feedback">{errors.email}</p>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label fw-sbold m-0">
                    First Name
                  </label>
                  {/* <input type="text" placeholder="" className="form-control" value={client.first_name}/> */}
                  <input
                    className={`form-control ${errors.first_name && "is-invalid"}`}
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    autoComplete="off"
                  />
                  {errors.first_name && touched.first_name && (
                    <p className="invalid-feedback">{errors.first_name}</p>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label fw-sbold m-0">
                    Last Name
                  </label>
                  <input
                    className={`form-control ${errors.last_name && "is-invalid"}`}
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    autoComplete="off"
                  />
                  {errors.last_name && touched.last_name && (
                    <p className="invalid-feedback">{errors.last_name}</p>
                  )}
                </Col>
                <Col lg="12" className="my-2 gap-10 FormbtnWrpper d-flex">
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {/* <Button className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                          Login Now
                        </Button> */}
                  {/* <Link
                        to="/dashboard"
                        className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                      >
                        Login Now
                      </Link> */}
                  <Button
                    type="submit"
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  const validation = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[^0-9]+$/, 'Must not contain numbers')
      .required('First Name is required'),
    last_name: Yup.string()
      .matches(/^[^0-9]+$/, 'Must not contain numbers')
      .optional(),
    email: Yup.string().email('Invalid email address').required("Email is required")
  });

  export default Form_;