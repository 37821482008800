import { convertTo12HourFormat } from "helpers/common";
import React, { useState, useEffect } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { postAppointmentOrders } from "store/services/clientService";

const AppointmentTypesSettings = ({
  handleAppointmentPop,
  appointmentTypeList,
  setAppointmentTypeList,
  handleAppointmentLinkPop,
  handleNext,
  deleteAppointmentType_,
  editAppointmentType,
  typeFields,
  setSchedulerType,
  settingListStep,
  step_name,
  setStep,
  setStepType,
  stepType,
  saveStepTrackings_,
  schedulerTypes,
  buttonDisable
}) => {
  const [stepToCheck, setStepToCheck] = useState(false)

  useEffect(() => {
    let settingListSteps = settingListStep?.data_set ?  JSON.parse(settingListStep?.data_set) : [];
    let isStepExists = settingListSteps.some(step => step.step_name === step_name);
      if (isStepExists) {
        setStepToCheck(true)
      } else {
        setStepToCheck(false)
      }
  }, [stepToCheck])

  const backStep_  = () => {
    if(stepType){
      setStepToCheck(false)
      setSchedulerType(true)
    }else{
      setStep(9)
    }
  }

  const nextStepType_  = () => {
    if(stepType){
      setStepToCheck(false)
      setSchedulerType(true)
    }if(schedulerTypes){
      setStep(13)
    }else{
      setStep(11)
    }
  }

  const backSteps_  = (step) => {
    console.log("hete")
      setStep(step)
  
  }

  const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));


  const moveCardUp = async(index) => {
    try{
      if (index === 0) return; // Prevent moving up the first card
      const updatedCards = [...appointmentTypeList];
      const temp = updatedCards[index];
      updatedCards[index] = updatedCards[index - 1];
      updatedCards[index - 1] = temp;
      const movedPositionId = updatedCards[index - 1]?.id;
      console.log("updatedCards[index + 1]?.id",updatedCards[index - 1]?.id,"tempID",temp?.id)
      setAppointmentTypeList(updatedCards);
       
      const sorted = updatedCards.map((item,index)=> ({id:item.id,sorting: index + 1}))
      postAppointmentOrdering({
        orders : sorted
      });
      await delay(500);
      for(let i = 0; i< updatedCards.length; i++){
        const element = document.getElementById(updatedCards[i]?.id);
        const shiftedId = updatedCards[i]?.id === movedPositionId;
        console.log("shiftedId",shiftedId);
        console.log("updatedCards[i]?.id === movedPositionId",updatedCards[i]?.id === movedPositionId)
        if(element && shiftedId){
        
          element.classList.add("zoomEffect");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          setTimeout(()=>{
            element.classList.remove("zoomEffect");
          },3000)
        }else{
          element && element.classList.remove("zoomEffect");
        }
      }
    }catch(err){
      console.log("error",err);
    }
   
  };

  const moveCardDown = async(index) => {
    try{
      if (index === appointmentTypeList.length - 1) return; // Prevent moving down the last card
      const updatedCards = [...appointmentTypeList];
      const temp = updatedCards[index];
      updatedCards[index] = updatedCards[index + 1];
      updatedCards[index + 1] = temp;
      const movedPositionId = updatedCards[index + 1]?.id;
      console.log("updatedCards[index + 1]?.id",updatedCards[index + 1]?.id,"tempID",temp?.id)
      setAppointmentTypeList(updatedCards);
      const sorted = updatedCards.map((item,index)=> ({id:item.id,sorting: index + 1}))
      postAppointmentOrdering({
        orders : sorted
      });
      await delay(500);
      for(let i = 0; i< updatedCards.length; i++){
        const element = document.getElementById(updatedCards[i]?.id);
        const shiftedId = updatedCards[i]?.id === movedPositionId;
        console.log("shiftedId",shiftedId);
        console.log("updatedCards[i]?.id === movedPositionId",updatedCards[i]?.id === movedPositionId)
        if(element && shiftedId){
         
            element.classList.add("zoomEffect");
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
         
          setTimeout(()=>{
            element.classList.remove("zoomEffect");
          },3000)
        }else{
          element && element.classList.remove("zoomEffect");
        }
      }
    }catch(err){
      console.log("error",err)
    }
    
  };

  const postAppointmentOrdering = async (data) =>{
      try{
         const res = await postAppointmentOrders(data);
         console.log("appointment orders response",res);
      }catch(error){
        console.log("error while order setting",error);
      }
  }

  return (
    <>
      <div
        className="commonContent p-3 gettingStarted"
        style={{ background: "#fffef7", zIndex: "9" }}
      >
        <div className="top py-2 border-bottom">
          <h3 className="m-0 fw-bold py-2 text-uppercase">
            Appointment Types Settings
          </h3>
        </div>
        <div className="CardBody py-2">
          <div className="py-2">
            <p className="py-2 m-0">
              Your Appointment Types represent the different kinds
              of appointments that your clients can book with you.
              For example, if you wanted to allow your clients or
              potential clients to be able to book a 30 minute
              "Initial Call" with you, this would represent an
              Appointment Type.
            </p>
            <div className="py-2">
              <div className="d-flex align-items-center justify-content-between gap-10">
                <div className="left">
                  <h4 className="m-0 fw-bold">
                    Update Your Appointment Types:{" "}
                  </h4>
                </div>
                <div className="right">
                  <Button
                    onClick={handleAppointmentPop}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    <span className="icn me-2 fw-sold">+</span>{" "}
                    Add New Appointment Type
                  </Button>
                </div>
              </div>
              {appointmentTypeList.length > 0 &&
                appointmentTypeList.map((type, i) => {
                  let timeArray = type?.available_working_hours;
                  timeArray = type?.available_working_hours
                    ? JSON.parse(timeArray)
                    : [];
                  return (
                    <>
                      <div
                        className="commonCardBox p-3 mt-3 bg-white"
                        key={i}
                        id={type.id}
                      >
                        <div className="py-2">
                          <h4 className="m-0 fw-bold">
                            {type?.title}{" "}
                            <p className="fw-normal d-inline-block text-muted m-0">
                              (duration: {type?.duration} minutes)
                            </p>
                          </h4>
                        </div>
                        {type?.is_paid_appointment === 'yes' &&
                          <div className="py-2">
                            <h4 className="m-0 fw-bold">
                              Price:  {type?.currency_sign}{type?.price}
                            </h4>
                          </div>
                        }
                        <div className="py-2">
                          <h4 className="m-0 fw-bold">
                            Description
                          </h4>
                          <p className="fw-normal m-0">
                            {type?.description}
                          </p>
                        </div>
                        {type?.location && (
                          <div className="py-2">
                            <h4 className="m-0 fw-bold">
                              Location
                            </h4>
                            <ul className="mb-0 py-2">
                              {type?.location === "in_person" ? (
                                <li className="">In-Person</li>
                              ) : (
                                ""
                              )}
                              {type?.location === "by_phone" ? (
                                <li className="">By Phone</li>
                              ) : (
                                ""
                              )}
                              {type?.location === "video_zoom" ? (
                                <li className="">Video/Zoom</li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        )}
                        <div className="py-2">
                          {timeArray.length > 0 && (
                            <>
                              <h4 className="m-0 fw-bold">
                                Availability
                              </h4>
                              <ul className="mb-0 py-2">
                                {timeArray.map((time, j) => {
                                  let finalTimeArry = time?.time;
                                  return (
                                    <>
                                      {finalTimeArry.length > 0 &&
                                        finalTimeArry.map(
                                          (val, j) => {
                                            return (
                                              <li
                                                className=""
                                                key={j}
                                              >
                                                {time?.day
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  time.day?.slice(
                                                    1
                                                  )}{" "}
                                                {convertTo12HourFormat(val.start_time)} -{" "}
                                                {convertTo12HourFormat(val.end_time)}
                                              </li>
                                            );
                                          }
                                        )}
                                    </>
                                  );
                                })}
                              </ul>
                            </>
                          )}
                        </div>
                        {type?.location_notes && (
                          <div className="py-2">
                            <h4 className="m-0 fw-bold">
                              {type?.location === "in_person"
                                ? "In-person"
                                : type?.location === "by_phone"
                                  ? "By Phone"
                                  : "Video/Zoom"}{" "}
                              Instructions
                            </h4>
                            <p className="fw-normal m-0">
                              {type?.location_notes}
                            </p>
                          </div>
                        )}
                        {type?.video_url && (
                          <div className="py-2">
                            <h4 className="m-0 fw-bold">
                              Video/Zoom URL
                            </h4>
                            <p className="fw-normal m-0">
                              {type?.video_url}
                            </p>
                          </div>
                        )}
                        {type?.additonal_notes && (
                          <div className="py-2">
                            <h4 className="m-0 fw-bold">
                              Additional Notes
                            </h4>
                            <p className="fw-normal m-0">
                              {type?.additonal_notes}
                            </p>
                          </div>
                        )}
                        {type?.buffer_before &&
                          type?.buffer_after && (
                            <div className="py-2">
                              <h4 className="m-0 fw-bold">
                                Time Buffers
                              </h4>
                              {type?.buffer_before && (
                                <p className="fw-normal m-0">
                                  Before: {type?.buffer_before}{" "}
                                  minutes
                                </p>
                              )}
                              {type?.buffer_after && (
                                <p className="fw-normal m-0">
                                  After: {type?.buffer_after}{" "}
                                  minutes
                                </p>
                              )}
                            </div>
                          )}
                      </div>
                      <div className="py-2 my-2">
                        <div className="FormBtnWrpper d-flex align-items-center justify-content-between gap-10">
                          <div className="left">
                            <Button
                              onClick={() => {
                                handleAppointmentLinkPop(type?.id);
                              }}
                              className="p-0 fw-sbold themeLink border-0 h-auto"
                              variant="transparent"
                            >
                              Appointment Link
                            </Button>
                          </div>
                          <div className="right d-flex align-items-center gap-10">
                          <Button 
                           className="commonBtn btnSm" 
                           onClick={() => moveCardUp(i)} 
                           disabled={i === 0}>
                            Move 
                            <span className="icn ms-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="#888888" d="M104.704 685.248a64 64 0 0 0 90.496 0l316.8-316.8l316.8 316.8a64 64 0 0 0 90.496-90.496L557.248 232.704a64 64 0 0 0-90.496 0L104.704 594.752a64 64 0 0 0 0 90.496"/></svg>
                            </span>
                          </Button>
                          <Button 
                            className="commonBtn btnSm" 
                            onClick={() => moveCardDown(i)} 
                            disabled={i === appointmentTypeList.length - 1}>
                              Move 
                              <span className="icn ms-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="#888888" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8l316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496"/></svg>
                              </span>
                          </Button>
                            <Button
                              onClick={() =>
                                deleteAppointmentType_(type?.id)
                              }
                              className="d-flex align-items-center justify-content-center commonBtn btnSm"
                            >
                              Delete
                            </Button>
                            <Button
                              // onClick={handleAppointmentPop}
                              onClick={() =>
                                editAppointmentType(type)
                              }
                              className="d-flex align-items-center justify-content-center commonBtn btnSm"
                            >
                              Edit / Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {!stepToCheck ? (
            <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
              <Button
                onClick={() => backSteps_(9)}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                <span className="icn me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.623498 7.73221C0.436027 7.54468 0.330712 7.29037 0.330712 7.02521C0.330712 6.76005 0.436027 6.50574 0.623498 6.31821L6.2805 0.66121C6.37274 0.5657 6.48309 0.489517 6.60509 0.437108C6.7271 0.384699 6.85832 0.357114 6.9911 0.35596C7.12388 0.354806 7.25556 0.380108 7.37845 0.430388C7.50135 0.480669 7.613 0.554923 7.70689 0.648816C7.80079 0.742709 7.87504 0.854359 7.92532 0.977256C7.9756 1.10015 8.0009 1.23183 7.99975 1.36461C7.99859 1.49739 7.97101 1.62861 7.9186 1.75062C7.86619 1.87262 7.79001 1.98296 7.6945 2.07521L2.7445 7.02521L7.6945 11.9752C7.87666 12.1638 7.97745 12.4164 7.97517 12.6786C7.97289 12.9408 7.86773 13.1916 7.68232 13.377C7.49691 13.5624 7.2461 13.6676 6.9839 13.6699C6.7217 13.6722 6.4691 13.5714 6.2805 13.3892L0.623498 7.73221Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Cancel
              </Button>
              <Button
                onClick={() => saveStepTrackings_('appointment_type_setting')}
                disabled={buttonDisable ? true : false}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                {buttonDisable ? "Wait.." : "Next"}
                <span className="icn ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.707 6.29318C7.89447 6.48071 7.99979 6.73502 7.99979 7.00018C7.99979 7.26534 7.89447 7.51965 7.707 7.70718L2.05 13.3642C1.95775 13.4597 1.84741 13.5359 1.7254 13.5883C1.6034 13.6407 1.47218 13.6683 1.3394 13.6694C1.20662 13.6706 1.07494 13.6453 0.952046 13.595C0.82915 13.5447 0.717497 13.4705 0.623604 13.3766C0.529712 13.2827 0.455459 13.171 0.405178 13.0481C0.354897 12.9252 0.329595 12.7936 0.330749 12.6608C0.331903 12.528 0.359489 12.3968 0.411898 12.2748C0.464307 12.1528 0.540489 12.0424 0.635999 11.9502L5.586 7.00018L0.635999 2.05018C0.453841 1.86158 0.353047 1.60898 0.355325 1.34678C0.357604 1.08458 0.462772 0.83377 0.648181 0.648362C0.833589 0.462954 1.0844 0.357785 1.3466 0.355507C1.6088 0.353228 1.8614 0.454022 2.05 0.636181L7.707 6.29318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Button>
            </div>
            ):(
              <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
              <Button
                onClick={() => backStep_()}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                <span className="icn me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.623498 7.73221C0.436027 7.54468 0.330712 7.29037 0.330712 7.02521C0.330712 6.76005 0.436027 6.50574 0.623498 6.31821L6.2805 0.66121C6.37274 0.5657 6.48309 0.489517 6.60509 0.437108C6.7271 0.384699 6.85832 0.357114 6.9911 0.35596C7.12388 0.354806 7.25556 0.380108 7.37845 0.430388C7.50135 0.480669 7.613 0.554923 7.70689 0.648816C7.80079 0.742709 7.87504 0.854359 7.92532 0.977256C7.9756 1.10015 8.0009 1.23183 7.99975 1.36461C7.99859 1.49739 7.97101 1.62861 7.9186 1.75062C7.86619 1.87262 7.79001 1.98296 7.6945 2.07521L2.7445 7.02521L7.6945 11.9752C7.87666 12.1638 7.97745 12.4164 7.97517 12.6786C7.97289 12.9408 7.86773 13.1916 7.68232 13.377C7.49691 13.5624 7.2461 13.6676 6.9839 13.6699C6.7217 13.6722 6.4691 13.5714 6.2805 13.3892L0.623498 7.73221Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Back
              </Button>
              <Button
                 onClick={() => nextStepType_()}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                Save
                <span className="icn ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.707 6.29318C7.89447 6.48071 7.99979 6.73502 7.99979 7.00018C7.99979 7.26534 7.89447 7.51965 7.707 7.70718L2.05 13.3642C1.95775 13.4597 1.84741 13.5359 1.7254 13.5883C1.6034 13.6407 1.47218 13.6683 1.3394 13.6694C1.20662 13.6706 1.07494 13.6453 0.952046 13.595C0.82915 13.5447 0.717497 13.4705 0.623604 13.3766C0.529712 13.2827 0.455459 13.171 0.405178 13.0481C0.354897 12.9252 0.329595 12.7936 0.330749 12.6608C0.331903 12.528 0.359489 12.3968 0.411898 12.2748C0.464307 12.1528 0.540489 12.0424 0.635999 11.9502L5.586 7.00018L0.635999 2.05018C0.453841 1.86158 0.353047 1.60898 0.355325 1.34678C0.357604 1.08458 0.462772 0.83377 0.648181 0.648362C0.833589 0.462954 1.0844 0.357785 1.3466 0.355507C1.6088 0.353228 1.8614 0.454022 2.05 0.636181L7.707 6.29318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Button>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentTypesSettings;