import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { decode } from 'html-entities';
import { chartOptions, dangerSignAnnotation, chartMax, chartMaxSuper, lineAnnotation } from 'components/shared/ChartConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const LineChart = forwardRef(function Chart({ 
  chartData,
  height,
  screenSize,
  selectedNAQs,
  getColor,
  innerRef,
}, ref) {
// export function LineChart({
//   chartData,
//   height,
//   screenSize,
//   selectedNAQs,
//   getColor,
//   innerRef,
// }) {
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState(chartOptions);

  let datasetCount = selectedNAQs?.length || chartData?.length;

  let chartHeight = (datasetCount && ((15) * datasetCount) + height) || height;

  useEffect(() => {
    if(chartData) {
      let labels = [];
      const chartLabels = {};
      const datasetLines = {}
      for(let i = 0; i < chartData[0]?.data?.line?.length; i++) {
        let label = chartData[0]?.data.line[i].title;
        labels.push(decode(label));
        let loopIndex = 0;
        for(let j = loopIndex; j < chartData.length; j++ ) {
          if(selectedNAQs.length && !selectedNAQs.includes(chartData[j].todo_id)) {
            continue;
          }
          let row = chartData[j]?.data.line[i];
          let total = row?.rawTotal || 0;
          if(!row){
            // continue;
          }
          let yAdjust = ((loopIndex + 1 ) * 16);

          chartLabels[`chartLabel_${i}_${loopIndex}`] = {
            type: 'label',
            xValue: i,
            yValue: 0,
            yAdjust: yAdjust,
            content: [total],
            textDecoration: 'underline',
            font: {
              size: 15,
            },
            // rotation: 270,
            position: "center",
            align: "left",
            // color: getColor(chartData[j].todo_id),
          }
          loopIndex++;
        }
      }

      let dangerLabels = {};
      let datasets = chartData.map((data, index) => {
          let dataset = [];
          let bgColors = [];
          for(let i = 0; i < data?.data?.line?.length; i++ ) {
            if(selectedNAQs.length && !selectedNAQs.includes(data.todo_id)) {
              continue;
            }
            let row = data?.data.line[i];
            let precentOfMax = row.percentOfMax;
            let origValue = precentOfMax * chartMax;
            let value = origValue;
            // keeping a reserved space for the danger sign so adding +5
            if((origValue + 5) > chartMaxSuper) {
              value = chartMaxSuper;
            }
            dataset.push(value);
            bgColors.push(getColor(data.todo_id));
            if(precentOfMax == 0.98) {
              dangerLabels[`danger_${i}_${index}`] = dangerSignAnnotation(dataset.length - 1, (origValue + 5 > chartMaxSuper ? chartMaxSuper - 15 : value))
            }
          }
          return (
            {
              label: '',
              data: dataset,
              backgroundColor: bgColors,
              pointRadius: 6,
              pointHoverRadius: 8,
              skipNull: false,
            }
          )
        })

        const data = {
          labels: labels,
          datasets: datasets,
        };
        
        // console.log("data", data);
      let xAdjustValue = (function (){
        switch(screenSize) {
          case "extra-small":
            return -15;
          case "small":
            return -18;
          case "medium":
            return -20;
          case "large":
            return -25;
          default:
            return -25;
        }
      })();
      
      let newOptions = {
        ...chartOptions,
        scales: {
          ...chartOptions.scales,
          x: {
            ...chartOptions.scales.x,
            // display: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
              padding: datasetCount ? datasetCount * 20 : 10,
              color: "#000",
              font: {
                size: 15,
              }
            },
            grid: {
              display: true
            }
          }
        },
        plugins: {
          ...chartOptions.plugins,
          tooltip: {
            enabled: false,
            callbacks: {
                // label: function(context) {
                //   if(chartData) {
                //     let label = chartData.line[context.dataIndex]?.rawTotal;
                //     return label;
                //   }
                // },
                // title: function(context) {
                //   if(chartData) {
                //     let title = chartData.line[context[0].dataIndex]?.title;
                //     return decode(title);
                //   }
                // }
            }
          },
          annotation: {
            ...chartOptions.plugins.annotation,
            annotations: {
              ...chartOptions.plugins.annotation.annotations,
              ...chartLabels,
              ...datasetLines,
              ...dangerLabels,
              label1: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 1.2),
                content: ['Low Priority'],
              },
              label2: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 2),
                content: ['Medium Priority'],
              },
              label3: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax / 1.2,
                content: ['High Priority'],
              }, 
            }
          }
        }
      }

      setFormattedData(data);
      setLoading(false);
      setOptions(newOptions);
    }
  }, [chartData, screenSize, selectedNAQs]);

  useImperativeHandle(ref, () => {
    return {
      downloadChartImage() {
        if(innerRef.current) {
          const base64Image = innerRef.current.toBase64Image('image/png', 1);
          return base64Image;
        }
      }
    }
  });

  if(!chartData) {
    return ""
  }

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Line
          key={datasetCount}
          ref={innerRef}
          options={options}
          data={formattedData}
          height={chartHeight}
        />
      }
    </>
  );
})