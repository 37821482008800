import { TIMEZONE_LIST, UPDATE_SCHEDULER_SETTING, WORKING_HOURS_LIST, ADD_WORKING_HOURS,APPOINTMENT_TYPE_LIST, APPOINTMENT_TYPE_ADD, APPOINTMENT_TYPE_REMOVED, APPOINTMENT_TYPE_VIEW, APPOINTMENT_CLIENT_LIST, APPOINTMENT_SLOTS, APPOINTMENT_BOOK, SAVE_STEPS_TRACKING_DATA, GET_APPOINTMENT_BOOKING_LIST, RESCHEDULE_APPOINTMENT, CANCEL_APPOINTMENT} from 'store/actions/appointment';

const initialState = {
    isAuthenticated: false,
    timezone_listing: [],
    hoursList : [],
    appointmentList:[],
    appointmentTypeDetail:{},
    appointmentclientlist:[],
    appointmentslotlist:[],
    appointmentBook:{},
    appointmentBookingList:[],
    schedulerSetting: {},
    loader:false,
};

//get 
const timeZoneList = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        timezone_listing:data.body,
    };
  return stateObj;
};

//update scheduler setting
const updateSchedulerSetting = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    //   timezone_listing:[],
    };
    return stateObj;
};
//working hours list
const workingHourList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    hoursList:data.body,
  };
  return stateObj;
};
// add working hours
const addWorkingHours = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    hoursList:data.body,
  };
  return stateObj;
};

//appointment type list
const appointmentTypeList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    appointmentList:data.body,
  };
  return stateObj;
};

//appointment type add
const appointmentTypeAdd = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    // appointmentTypeList:data.body,
  };
  return stateObj;
};
//appointment type remove
const appointmentTypeRemove = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    // appointmentTypeList:data.body,
  };
  return stateObj;
};
//appointment type VIEW
const appointmentTypeView = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    appointmentTypeDetail:data.body,
  };
  return stateObj;
};

//appointment client list
const appointmentClientList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    appointmentclientlist:data.body,
  };
  return stateObj;
};
//appointment slots
const appointmentSlots = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    appointmentslotlist:data.body,
  };
  return stateObj;
};
//appointment book
const appointmentBook = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    appointmentBook:data.body,
  };
  return stateObj;
};

const saveStepsTrackingData = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
  };
  return stateObj;
};

const getAppointmentBookingList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    appointmentBookingList: data.body,
  };
  return stateObj;
};

const cancelAppointment = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
  };
  return stateObj;
};


const rescheduleAppointment = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
  };
  return stateObj;
};


const Appointment = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case TIMEZONE_LIST:
          return timeZoneList(state, payload);
        case UPDATE_SCHEDULER_SETTING:
          return updateSchedulerSetting(state, payload);
        case WORKING_HOURS_LIST:
          return workingHourList(state, payload);
        case ADD_WORKING_HOURS:
          return addWorkingHours(state, payload);
        case APPOINTMENT_TYPE_LIST:
          return appointmentTypeList(state, payload);
        case APPOINTMENT_TYPE_ADD:
          return appointmentTypeAdd(state, payload);
        case APPOINTMENT_TYPE_REMOVED:
          return appointmentTypeRemove(state, payload);
        case APPOINTMENT_TYPE_VIEW:
          return appointmentTypeView(state, payload);
        case APPOINTMENT_CLIENT_LIST:
          return appointmentClientList(state, payload);
        case APPOINTMENT_SLOTS:
          return appointmentSlots(state, payload);
        case APPOINTMENT_BOOK:
          return appointmentBook(state, payload);
        case SAVE_STEPS_TRACKING_DATA:
          return saveStepsTrackingData(state, payload);
        case GET_APPOINTMENT_BOOKING_LIST:
          return getAppointmentBookingList(state, payload);
        case CANCEL_APPOINTMENT:
          return cancelAppointment(state, payload);
        case RESCHEDULE_APPOINTMENT:
          return rescheduleAppointment(state, payload);
        default:
        return state;
    };
}
export default Appointment;