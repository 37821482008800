import { UC_FIRST } from "helpers/common";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ConfirmSendRecommPopup = ({ showPopup, handleClose, SendRecommendationLink, client }) => {

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Recommendation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12" className="">
              <p>Once this Recommendation has been sent to {UC_FIRST(client?.first_name)} it can't be unsent.</p>
              <p>Are you sure you want to send this Recommendation?</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
          {SendRecommendationLink}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmSendRecommPopup;
