import React from "react";

const Weight = ({ question, errors = {} }) => {
  return (
    <div key={`question-${question.id}`}>
      <div className="d-flex justify-content-start gap-10">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp previewFormField radio position-relative ">
            <input type="radio" className={`position-absolute w-100 h-100`} disabled />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">{/* <OTick /> */}</span>
            </div>
          </div>
          <p className="m-0">Pounds</p>
        </div>
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp previewFormField radio position-relative ">
            <input type="radio" className={`position-absolute w-100 h-100`} disabled />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">{/* <OTick /> */}</span>
            </div>
          </div>
          <p className="m-0">Kilograms</p>
        </div>
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp previewFormField radio position-relative ">
            <input type="radio" className={`position-absolute w-100 h-100`} disabled />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">{/* <OTick /> */}</span>
            </div>
          </div>
          <p className="m-0">Stones</p>
        </div>
      </div>
      <div className="py-2 w-100">
        <div class="input-group">
          <input
            type="number"
            className="border"
            style={{ borderRadius: "6px 0 0 6px", width: "calc(100% - 50px)" }}
            disabled
          />
          <div class="input-group-append rounded">
            <span
              class="input-group-text"
              style={{ height: "50px", borderRadius: "0 6px 6px 0", width: 50 }}
            >
              lbs
            </span>
          </div>
        </div>
        {errors[`question-${question.id}`] && (
          <p className="invalid-feedback">{errors[`question-${question.id}`].value}</p>
        )}
      </div>
    </div>
  );
};

export default Weight;
