import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import EnableDisablePop from "components/Modals/EnableDisablePop";
import {DATE_FORMAT, TIMESTAMP_FORMAT_TODATE}from "helpers/common"
import * as actions from 'store/actions';
import parse from "html-react-parser"

//imported
import {
  activeSubscription_,
  stripeUpdatePlanPortalUrl,
  billingStripeUrl_,
  getSubscriptionPackages
} from "store/services/subscriptionService";
import { addUpdateAccountSetting_ } from 'store/services/accountSettingService';
import { fa } from "@faker-js/faker";
import { helpEmail } from "common/constants";


const SubscriptionInfo = ({dispatch, subscriptionHistory, accountSettingList, currentTabValue, setCurrentTabValue,  sending, setSending, setSuccessResponse, profileDetails}) => {
  const [onOffPop, setOnOffPop] = useState();
  const [popHeading, setPopHeading] = useState("");
  const [fields, setfields] = useState({
    data_set : currentTabValue ? currentTabValue.data_set : ""
  })
  const [packageList, setPackageList] = useState(null);
  const [isUpgradeCancelAllowed, setIsUpgradeCancelAllowed] = useState(true);

  useEffect(() => {
    getData();
    getPackagesList();
  }, []);

  //get data
  async function getData() {
    try {
      await dispatch(activeSubscription_());
    } catch (err) {
      console.log(err);
    }
  }


  async function getPackagesList() {
    try {
      let response = await getSubscriptionPackages();
      if(response?.success === true && response?.body?.length > 0) {
        setPackageList(response.body);
      } else if(response?.success === true && response?.body?.price_migrated === false) {
        setIsUpgradeCancelAllowed(false);
      }
    } catch(err) {
      console.log("Error fetching packages list", err);
    }
  }

  //strip connect link
  async function stripeConnectBilling() {
    try {
      dispatch(actions.persist_store({ loader:true}));
      let res = await dispatch(billingStripeUrl_());
      dispatch(actions.persist_store({ loader:false}));
      let urlLink = res?.body?.url
      if(urlLink){
        const isValidUrl =
        urlLink.startsWith("http") ||
        urlLink.startsWith("https");
        if (isValidUrl) {
          // Redirect to the URL
          window.location.href = urlLink;
        }
      }
    } catch (err) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(err);
    }
  }

  // stripe update plan portal page
  async function stripeUpdatePlanPortal(packageId) {
    try {
      dispatch(actions.persist_store({ loader:true}));
      let postData = {
        packageId
      }
      let res = await stripeUpdatePlanPortalUrl(postData);
      dispatch(actions.persist_store({ loader:false}));
      let urlLink = res?.body?.url
      if(urlLink){
        const isValidUrl =
        urlLink.startsWith("http") ||
        urlLink.startsWith("https");
        if (isValidUrl) {
          // Redirect to the URL
          window.location.href = urlLink;
        }
      }
    } catch (err) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(err);
    }
  }

  useEffect(() => {
    if (currentTabValue) {
      setfields(prevState => ({
        ...prevState,
        ...currentTabValue,
      }))
    }
  }, [currentTabValue, accountSettingList])


  const handleOnOff = (heading, value) => {
    setOnOffPop(!onOffPop);
    setPopHeading(heading);
    setCurrentTabValue(value)
  };

  //submit setting data
  const handleSubmit = async (evt) => {
    try{
      evt.preventDefault();
        let final_obj = {
          data_set : fields.data_set
        }
        let fieldsId = fields.id
        // return
        final_obj = JSON.stringify(final_obj)
        setSending(true)
        setSuccessResponse(false)
        await dispatch(addUpdateAccountSetting_(fieldsId, final_obj));
        setSending(false)
        setSuccessResponse(true)
        setOnOffPop(false)
        setCurrentTabValue(null)
    } catch (err) {
        console.log(err);
        setSending(false)
        setSuccessResponse(false)
    }
  }

  //handle changes
  const handleChange = useCallback(
    name => evt => {
      setfields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    },
    []
  )
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  }
  return (
    <>
      <EnableDisablePop
        heading={popHeading}
        onOffPop={onOffPop}
        setOnOffPop={setOnOffPop}
        currentTabValue={currentTabValue}
        setCurrentTabValue={setCurrentTabValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange('data_set')}
        sending={sending}
      />

      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">Subscription Information</h3>
        {profileDetails && profileDetails?.is_free_account === "yes" && (
          <p className="m-0 py-1 text-muted">
            Enjoy your free access to Nutri-Q until {DATE_FORMAT(profileDetails?.free_account_expiry)};
            after that, a subscription will be required to continue using the platform.
          </p>
        )}
      </div>
      <div className="TabBody subscription-info py-3">
        {profileDetails?.is_free_account === "no" && subscriptionHistory &&
        <div className="commonContent">
          <div className="top mb-2">
            <h4 className="m-0 py-2">Your Plan</h4>
          </div>
          <Row className="my-2 mb-4">
            <Col lg="6" className="my-2">
              <div
                className="commonCardBox PricingCard current-plan h-100 p-3 px-lg-5"
                // style={{ background: "var(--bs-button)" }}
              >
                <div className="top text-center">
                  <h3 className="m-0 py-2 fw-bold">
                    {subscriptionHistory.package_name}
                  </h3>
                  <h2 className="m-0 py-2 priceHead fw-bold">
                    ${subscriptionHistory.package_price}
                    <span className="">/{subscriptionHistory.package_type}</span>
                  </h2>
                  <p className="py-2 m-0">
                    {subscriptionHistory.package_desc}
                  </p>
                  <p className="py-2 m-0">
                    Subscription Status: {subscriptionHistory.plan_status && capitalizeFirstLetter(subscriptionHistory.plan_status)}
                  </p>
                  <p className="py-2 m-0">
                    Last Payment Date: {TIMESTAMP_FORMAT_TODATE(subscriptionHistory.last_charge_date)}
                  </p>
                  {["pending","canceled"].includes(subscriptionHistory?.cancellation_status) && !!subscriptionHistory.cancel_at && (
                    <p className="py-2 m-0">
                      Cancel At: {TIMESTAMP_FORMAT_TODATE(subscriptionHistory.cancel_at)}
                    </p>  
                  )}
                  {
                    (!subscriptionHistory.cancel_at ||
                    (
                      subscriptionHistory.cancel_at !== subscriptionHistory.current_period_end &&
                      !["canceled"].includes(subscriptionHistory?.cancellation_status)
                    )) && (
                    <>
                      <p className="py-2 m-0">
                        Next Payment Date: {TIMESTAMP_FORMAT_TODATE(subscriptionHistory.next_charge_date)}
                      </p>
                      <p className="py-2 m-0">
                        Next Payment Amount:  ${subscriptionHistory.package_price}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Col>

            {packageList && packageList?.length > 0 && (
              packageList.map((plan) => {
                return (
                  <Col lg="6" className="my-2">
                    <div
                      className="commonCardBox PricingCard h-100 p-3 px-lg-5"
                      style={{ background: "var(--bs-button)" }}
                    >
                      <div className="top text-center">
                        <h3 className="m-0 py-2 fw-bold text-white">
                          {plan.package_name}
                        </h3>
                        <h2 className="m-0 py-2 priceHead fw-bold text-white">
                          ${plan.package_price}
                          <span className="">/{plan.package_type}</span>
                        </h2>
                        <p className="py-2 m-0 text-white">
                          {plan.package_desc}
                        </p>
                        <p className="py-2 m-0 text-white text-start">
                          {parse(plan.package_included)}
                        </p>
                        <div className="py-1 d-flex justify-content-center">
                          <Button
                            onClick={() =>{
                              stripeUpdatePlanPortal(plan.id)
                            }}
                            className="d-flex align-items-center fw-bold secondaryBtn btnSm justify-content-center py-2 px-3"
                          >
                            {`${plan.package_price >= subscriptionHistory.package_price ? "Upgrade" : "Downgrade"} Plan`}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })
            )}
          </Row>
          {isUpgradeCancelAllowed === false && (
            <Row>
              <Col lg="12">
                <p className="py-2 m-0">
                  For questions, upgrades, or cancellations, please email us at{" "}
                  <a href={`mailto:${helpEmail}`}>{helpEmail}</a>
                  </p>
              </Col>
            </Row>
          )}
          <Row className="my-2 mb-4">
            <Col lg="12" className="my-2">
              <Link to="#" onClick={() => stripeConnectBilling()}>Update subscription billing info</Link>
            </Col>
            <Col lg="12" className="my-2">
              <Link to="#" onClick={() => stripeConnectBilling()}>View payment history</Link>
            </Col>
            {accountSettingList?.length > 0 && accountSettingList?.map((settingList, idx) => {
              if(settingList.data_key === 'payment_email_notifications'){ 
              return(
                    <Col lg="12" className="my-2" key={idx}>
                      <Link to="#" onClick={() => handleOnOff("Payment Email Notifications", settingList)}>Update payment notifications</Link>
                    </Col>
                )
              }
            })}
            <Col lg="12" className="my-2">
              <Link to="#" onClick={() => stripeConnectBilling()}>Cancel subscription</Link>
            </Col>
          </Row>
        </div>
        }


        {/* <div className="commonContent">
          <div className="commonCardBox">
            <div className="top px-3 py-2">
              <h4 className="m-0 py-2">Subscription History</h4>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="p-3 text-dark fw-sbold bg-transparent">
                      Plan
                    </th>
                    <th className="p-3 text-dark fw-sbold bg-transparent">
                      Subscription Date
                    </th>
                    <th className="p-3 text-dark fw-sbold bg-transparent">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-3 border-0">
                      <Link to="" className="fw-sbold m-0">
                        Premium Plan{" "}
                      </Link>
                    </td>
                    <td className="p-3 border-0">23/10/23</td>
                    <td className="p-3 border-0">Expired</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return{
    subscriptionHistory : state.Subscription.activeSubscription,
    profileDetails: state.Profile.user,
  }
};

function mapDispatchToProps(dispatch) {
return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(SubscriptionInfo);
