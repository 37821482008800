import { HelpStripePaymentMethod, myClientDetails } from "Routes/paths";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SubscriptionInactivePopup = ({ showPopup, handleClose, billingPortalUrl }) => {
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      let url = await billingPortalUrl();
      if(url) {
        window.location = url
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      console.log("Error redirecting user to billing portal: ", err);
    }
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop subscription-inactive-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscription Inactive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg="12" className="main-content">
              <p>
                Your subscription is currently inactive, possibly due to a failed payment or another issue.
                Please click the button below to review and update your account details for uninterrupted platform access.
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={handleClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Please wait..." : "Review & Update"}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default SubscriptionInactivePopup;
