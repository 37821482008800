import {postData, getData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, DOCUMENT_LIST,DOCUMENT_ADD, DOCUMENT_DELETE  } from './apiPath'
import { getDocument, addDocument, deleteDocument} from 'store/actions/documents';

//get
export function getDocumentList(value, callback) {
  let url = `${MAIN_PATH}${DOCUMENT_LIST}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(getDocument(data, callback));
      return data;
    });
}

//add
export function addDocumentData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${DOCUMENT_ADD}`, value)
    .then((data) => {
      dispatch(addDocument(data, callback));
      return data;
    });
}


//delete
export function deleteDocumentData(value) {
  return (dispatch) => postData(`${MAIN_PATH}${DOCUMENT_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteDocument(data));
      return data;
    });
}

