import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const RemovePackageInvitePopup = ({ showPopup, setShowPopup, handleRemoveInvite }) => {
  const [isRemovingInvite, setRemovingInvite] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  }

  const handleClickRemoveInvite = () => {
    handleRemoveInvite(setRemovingInvite);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12" className="my-2">
              <p className="m-0 fw-sbold pb-1">
                Are you sure you want to remove this package from the list?
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClickRemoveInvite}
            disabled={isRemovingInvite}
          >
            {isRemovingInvite ? "Please wait..." : "Yes, Remove Invite"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemovePackageInvitePopup;
