import { Document, Page, Text, StyleSheet, View, pdf, Image } from '@react-pdf/renderer';
import { useEffect, useRef, useState } from 'react';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs, validatePageConditions, validateQuestionConditions } from 'helpers/common';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getTodoResponse } from 'store/services/clientService';
import { RenderAnswerField } from './AllQuestionAnswers/Components/RenderAnswer';
import { getTodoNotes, getWellnessAssessmentData } from 'store/services/clientDataReportsService';
import { BarChart } from 'pages/SideTabs/MyClients/Detail/component/TabContent/ToDoTab/Component/SubmittedData/Component/AccordionTabs/HealthWellnessAssessment/Components/AssessmentCharts/Components/BarChart';
import D3Chart from 'pages/SideTabs/MyClients/Detail/component/TabContent/ToDoTab/Component/SubmittedData/Component/AccordionTabs/HealthWellnessAssessment/Components/AssessmentCharts/Components/D3Chart';
import { formatTodoResponse } from "helpers/dataFormat";

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
  },
  notesContainer: {
    padding: "10px",
    backgroundColor: "#eee",
    fontSize: "9px",
  },
  chartImage: {
    // width: "50%",
    height: "auto"
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #ccc",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "8%",
    padding: "2px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
  },
  cell: {
    color: "#000",
    fontSize: "9px",
    width: "8%",
    padding: "2px",
    flexWrap: "wrap",
    alignContent: "center",
  },
});

const HealthWellnessQAPdf = ({ client, date, todoId, formName, label, showNotes, showLoader, hideLoader }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [wellnessAssessData, setWellnessAssessData] = useState(null);

  const wheelChartRef = useRef(null);
  const barChartRef = useRef(null);

  useEffect(() => {
    if(!wellnessAssessData) {
      getWellnessGraphData();
    }
  }, [])

  const getWellnessGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId: client.id
      };
      typeof showLoader == "function" && showLoader();
      const response = await getWellnessAssessmentData(data);
      if (response.success === true) {
        const data = response.body;
        setWellnessAssessData(data);
      }
      setLoaded(true);
      typeof hideLoader == "function" && hideLoader();
    } catch (err) {
      console.log(err);
      typeof hideLoader == "function" && hideLoader();
    }
  }

  const downloadPDFReport = async () => {
    try {
      typeof showLoader == "function" && showLoader();
      const todoDetails = await getTodoDetails(todoId, client);
      const notesData = await getNotes(todoId);
      const barChartImage = barChartRef.current.downloadChartImage();
      const wheelChartImage = wheelChartRef.current.downloadChartImage();

      // wheelChartRef.current.downloadChartImage(async (wheelChartImage) => {
        const doc =
        <MyDoc
          client={client}
          date={date}
          todoDetails={todoDetails}
          pageTitle={formName}
          notesData={notesData || []}
          showNotes={showNotes}
          barChartImage={barChartImage}
          wheelChartImage={wheelChartImage}
        />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${formName.replaceAll(" ", "-")}.pdf`);
      typeof hideLoader == "function" && hideLoader();
      // });
    } catch(err) {
      console.log("Error downloading report", err);
      typeof hideLoader == "function" && hideLoader();
    }
  }

  return (
    <>
      <Link
        onClick={downloadPDFReport}
        disabled={true}
      >
        {label}
      </Link>
      <div style={{position: "fixed", right: "-99999px"}}>
        <div style={{ width: "400px", height: "400px" }}>
          {/* <WheelChart
            ref={wheelChartRef}
            chartData={wellnessAssessData}
            // height={250}
            // screenSize={screenSize}
          /> */}
          <D3Chart
            ref={wheelChartRef}
            chartData={wellnessAssessData}
          />
        </div>
        <div style={{ width: "600px", height: "600px" }}>
          <BarChart
            ref={barChartRef}
            chartData={wellnessAssessData}
            fontSize={20}
            // height={250}
            // screenSize={screenSize}
          />
        </div>
      </div>
    </>
  )
}

const QuestionsTable = ({ items }) => {
  const rows = items.map((question, i) => {
    if(!question || question.visible === false) {
      return null;
    }
    return (
      <View style={[tableStyles.row]} key={i} wrap={false}>
        <Text style={[tableStyles.cell, { width: "40%" }]}>{decode(question.question)}</Text>
        <Text style={[tableStyles.cell, { width: "60%" }]}>
          <RenderAnswerField
            question={question}
          />
        </Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={[tableStyles.row, { borderBottom: "2px solid #ccc", }]}>
        <Text style={[tableStyles.headerCell, { width: "40%", }]}>Question</Text>
        <Text style={[tableStyles.headerCell, { width: "60%", }]}>Answer</Text>
      </View>
      {rows}
    </View>
  )
};

export const MyDoc = ({ client, date, todoDetails, pageTitle, showNotes, notesData, wheelChartImage, barChartImage }) => {
  let questionCount = 0;
  return (
    <Document>
      <Page style={styles.page}>

        <View style={styles.pageHeader} fixed>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            {pageTitle}
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName} fixed>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        <View style={{ marginTop: "15px" }}>
          <View>
            <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
              Wellness Assessment Charts
            </Text>
          </View>
          <View style={{ marginTop: "5px", display: "flex", justifyContent: "space-evenly", flexDirection: "row" }}>
            <Image 
              style={styles.chartImage}
              src={wheelChartImage}
            />
            <Image 
              style={styles.chartImage}
              src={barChartImage}
            />
          </View>
        </View>

        {todoDetails && todoDetails.map(page => {
          if(!page || page.visible === false) {
            return null;
          }
          page.questions = page.questions.map((q) => {
            q.index = ++questionCount;
            return q;
          })

          let existingNoteValue = notesData && notesData.find(note => note.page_id == page.form_page_id)?.general_notes;
          return (
            <View style={{ marginTop: "15px" }} key={page.form_page_id}>
              <View>
                <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
                  {decode(page.page_title)}
                </Text>
              </View>
              {page.questions && page.questions.length > 0 &&
                <View style={styles.tableContainer}>
                  <QuestionsTable
                    items={page.questions}
                  />
                </View>
              }
              {showNotes && 
                <View style={styles.notesContainer}>
                  <Text style={{ textDecoration: "underline" }}>
                    My Notes: {decode(page.page_title)}
                  </Text>
                  <Text style={{ paddingTop: "2px" }}>
                    {existingNoteValue}
                  </Text>
                </View>
              }
            </View>
          )
        })}
        

        {/* Disclaimer */}
        <View style={styles.disclaimer} fixed>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getTodoDetails = async (todoId, client) => {
  if (!todoId) {
    return;
  }
  try {
    let data = {
      todoId,
      clientId: client.id
    };
    const response = await getTodoResponse(data);
    if (response.success === true) {
      const details = validateConditions(response.body);
      const formattedData = formatTodoResponse(details);
      return formattedData;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const getNotes = async (todoId) => {
  try {
    if (!todoId) {
      throw new Error("Invalid ToDo ID");
    }
    const response = await getTodoNotes(todoId);
    if (response.success === true && response.body.length > 0) {
      return response.body;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const validateConditions = (allPages) => {
  let allQuestionAnswers = [];
  for(let i = 0; i < allPages.length; i++) {
    if(allPages[i] && allPages[i].questions && allPages[i].questions.length > 0) {
      allQuestionAnswers= [...allPages[i].questions, ...allQuestionAnswers];
    }
  }
  return allPages.map(page => {
    page.visible = validatePageConditions(page, allQuestionAnswers);
    page.questions = page.questions.map(q => {
      q.visible = validateQuestionConditions(q, allQuestionAnswers);
      return q;
    })
    return page;
  })
}

// const validatePageConditions = (page, questionAnswers) => {
//   try {
//     let showPage = false;
//     if(!page.page_depend_q_id || !page.page_depend_q_ans || !page.page_depend_q_ans?.length) {
//       showPage = true;
//     } else {
//       let dependQuestionAns = JSON.parse(page.page_depend_q_ans);
//       let questionIndex = questionAnswers.findIndex(q => ((q.id == page.page_depend_q_id) && q.visible));
//       let foundQuestion = questionAnswers[questionIndex];
//       let dependSourceAnswer = null;
//       if(questionIndex !== -1 && foundQuestion) {
//         dependSourceAnswer = foundQuestion.answer;
//         // if answer is singular and not an array
//         if(typeof dependSourceAnswer == "string") {
//           if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
//             showPage = true;
//           }
//         // if answer is an array, check if any of the given answers match the condition
//         } else if(Array.isArray(dependSourceAnswer)) {
//           let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
//           if(findMatch) {
//             showPage = true;
//           }
//         }
//       }
//     }
//     return showPage;
//   } catch(err) {
//     console.log("Error validating page conditions", err);
//     return true;
//   }
// }

// const validateQuestionConditions = (question, questionAnswers) => {
//   if(!question) {
//     return false;
//   }
//   let showQuestion = false;
//   if(!question.depend_q_id || !question.depend_q_ans || !question.depend_q_ans?.length) {
//     showQuestion = true;
//   } else {
//     let dependQuestionAns = JSON.parse(question.depend_q_ans);
//     let questionIndex = questionAnswers.findIndex(q => ((q.id == question.depend_q_id) && q.visible));
//     let foundQuestion = questionAnswers[questionIndex];
//     let dependSourceAnswer = null;
//     if(questionIndex !== -1 && foundQuestion) {
//       dependSourceAnswer = foundQuestion.answer;
//       // if answer is singular and not an array
//       if(typeof dependSourceAnswer == "string") {
//         if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
//           showQuestion = true;
//         }
//       // if answer is an array, check if any of the given answers match the condition
//       } else if(Array.isArray(dependSourceAnswer)) {
//         let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
//         if(findMatch) {
//           showQuestion = true;
//         }
//       }
//     }
//   }
//   return showQuestion;
// }

export default HealthWellnessQAPdf;