import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { decode } from 'html-entities';
import { chartOptions, dangerSignAnnotation, chartMaxSuper, lineAnnotation } from 'components/shared/ChartConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart = ({ innerRef, chartData, height, screenSize, getColor }) => {
  const chartMax = 10;
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState({
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 40,
        right: 40,
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        display: false,
        max: chartMax
        
      },
      y: {
        grid: {
          display: true,
        }
      }
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
  });

  console.log("formattedData", isLoading, formattedData)

  useEffect(() => {
    if(!chartData || chartData.length == 0) {
      return;
    }
    const labels = [];
    const data = [];
    const bgColors = [];

    for(let i = 0; i < chartData[0].data.length; i++ ) {
      for(let j = 0; j < chartData.length; j++) {
        let row = chartData[j].data[i];
        labels.push(`${row.title} ${row.score}`);
        data.push(row.score);
        bgColors.push(getColor(chartData[j].todo_id));
      }
      
    }

    const formattedData = {
      labels: labels,
      datasets: [
        {
          label: '',
          data: data,
          // borderColor: 'rgb(255, 99, 132)',
          backgroundColor: bgColors,
        },
      ]
    }
    
    let xAdjustValue = (function (){
      switch(screenSize) {
        case "extra-small":
          return -20;
        case "small":
          return -20;
        case "medium":
          return -30;
        case "large":
          return -40;
        default:
          return -40;
      }
    })();
          
    let newOptions = {
      ...options,
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          ticks: {
            color: "#000",
          }
        }
      },
      plugins: {
        tooltip: {
          display: false
        },
        legend: {
          display: false
        },
        datalabels: {
          display: false
        },
        title: {
          // display: true,
          text: 'Wellness Assessment Chart',
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              xMin: chartMax / 3,
              xMax: chartMax / 3,
              borderColor: '#333',
              borderWidth: 0.5,
              rotation: 90,
            },
            line2: {
              type: 'line',
              xMin: chartMax - (chartMax / 3),
              xMax: chartMax - (chartMax / 3),
              borderColor: '#999',
              borderWidth: 1,
              rotation: 180,
            },
            line3: {
              type: 'line',
              xMin: chartMax,
              xMax: chartMax,
              borderColor: '#999',
              borderWidth: 1,
              rotation: 90,
            },
          }
        }
      }
    }

    setFormattedData(formattedData);
    setLoading(false);
    setOptions(newOptions);
  }, [chartData, screenSize]);

  useEffect(() => {

  }, [screenSize])

  // useImperativeHandle(ref, () => {
  //   return {
  //     downloadChartImage() {
  //       if(chartRef.current) {
  //         const base64Image = chartRef.current.toBase64Image('image/png', 1);
  //         return base64Image;
  //       }
  //     }
  //   }
  // });

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Bar
          ref={innerRef}
          options={options}
          data={formattedData}
          // height={height}
        />
      }
    </>
  );
}