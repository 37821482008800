//action types
export const GET_DASHBOARD_CONTENT = 'GET_DASHBOARD_CONTENT';
export const GETTING_STARTED_PROGRESS = 'GETTING_STARTED_PROGRESS';
export const CURRENT_PAGE_TITLE = 'CURRENT_PAGE_TITLE';
export const GET_BASIC_SETTINGS = 'GET_BASIC_SETTINGS';
export const GET_BANNER_SETTINGS = "GET_BANNER_SETTINGS";

export function dashboardContent(data, callback) {
    return {
      type: GET_DASHBOARD_CONTENT,
      payload: {data, callback}
    };
}

export function getStartedProgress(data, callback) {
  return {
    type: GETTING_STARTED_PROGRESS,
    payload: {data, callback}
  }; 
}

export function currentPageTitle(title) {
  return {
    type: CURRENT_PAGE_TITLE,
    payload: title,
  }; 
}

export function basicSettings(data, callback) {
  return {
    type: GET_BASIC_SETTINGS,
    payload: {data, callback},
  }; 
}

export function bannerSettings(data, callback) {
  return {
    type: GET_BANNER_SETTINGS,
    payload: {data, callback},
  }; 
}