import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { decode } from 'html-entities';
import { chartOptions, dangerSignAnnotation, chartMax, chartMaxSuper, lineAnnotation } from 'components/shared/ChartConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart = forwardRef(function Chart({ chartData, height, screenSize, isPdf }, ref) {
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState(chartOptions)

  const chartRef = useRef();

  useEffect(() => {
    if(chartData) {
      const labels = chartData?.bar?.map(row => {
        return `${row.rawTotal}`;
      })

      let dangerLabels = {};
      const dataset = chartData?.bar?.map((row, i) => {
        let percentOfMax = row.percentOfMax;
        let origValue = (percentOfMax * chartMax);
        let barValue = origValue;

        // keeping a reserved space for the danger sign so adding +5
        if((origValue + 5) > chartMaxSuper) {
          barValue = chartMaxSuper;
        }
        if(percentOfMax == 1) {
          // if the bar value is higher than chartMax than the danger sign should not follow the bar value
          dangerLabels[`danger_${i}`] = dangerSignAnnotation(i, (origValue + 5 > chartMaxSuper ? chartMaxSuper - 15 : barValue));
        }
        return barValue;
      })

      const chartLabels = {};
      for(let i = 0; i < labels.length; i++) {
        chartLabels[`chartLabel${i}`] = {
          type: 'label',
          xValue: i,
          yValue: 17,
          content: [decode(chartData?.bar[i]?.title)],
          font: {
            size: 15
          },
          rotation: 270,
          position: "center",
          align: "left",
          color: "#000",
        }
      }

      const data = {
        labels: labels,
        datasets: [
          {
            label: '',
            data: dataset,
            backgroundColor: chartData?.bar && chartData?.bar[0].color,
          }
        ],
      };
      
      let xAdjustValue = (function (){
        switch(screenSize) {
          case "extra-small":
            return -20;
          case "small":
            return -20;
          case "medium":
            return -30;
          case "large":
            return -40;
          default:
            return -40;
        }
      })();
            
      let newOptions = {
        ...options,
        scales: {
          ...options.scales,
          x: {
            ...options.scales.x,
            ticks: {
              color: "#000",
              font: {
                size: 15,
              }
            }
          }
        },
        plugins: {
          ...options.plugins,
          tooltip: {
            callbacks: {
                label: function(context) {
                  if(chartData) {
                    let label = chartData.bar[context.dataIndex]?.rawTotal;
                    return label;
                  }
                },
                title: function(context) {
                  if(chartData) {
                    let title = chartData.bar[context[0].dataIndex]?.title;
                    return decode(title);
                  }
                }
            }
          },
          annotation: {
            ...options.plugins.annotation,
            annotations: {
              ...options.plugins.annotation.annotations,
              ...chartLabels,
              ...dangerLabels,
              label1: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 1.2),
                content: ['Low Priority'],
              },
              label2: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 2),
                content: ['Medium Priority'],
              },
              label3: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax / 1.2,
                content: ['High Priority'],
              },  
            }
          }
        }
      }

      if(isPdf) {
        newOptions.devicePixelRatio = 3;
      }

      setFormattedData(data);
      setLoading(false);
      setOptions(newOptions);
    }
  }, [chartData, screenSize]);

  useEffect(() => {

  }, [screenSize])

  useImperativeHandle(ref, () => {
    return {
      downloadChartImage() {
        if(chartRef.current) {
          const base64Image = chartRef.current.toBase64Image('image/png', 1);
          return base64Image;
        }
      }
    }
  });

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Bar
          ref={chartRef}
          options={options}
          data={formattedData}
          height={height}
        />
      }
    </>
  );
})