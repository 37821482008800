//action types
export const GET_RESOURCE = 'GET_RESOURCE';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const VIEW_RESOURCE = 'VIEW_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const RESOURCE_PAGINATION = 'RESOURCE_PAGINATION';
export const RESOURCE_CATEGORIES = "RESOURCE_CATEGORIES";

//get 
export function getResource(data, callback) {
  return {
    type: GET_RESOURCE,
    payload : {data, callback},
  };
}
//add 
export function addResource(data, callback) {
  return {
    type: ADD_RESOURCE,
    payload : {data, callback},
  };
}

//view 
export function viewResource(data, callback) {
  return {
    type: VIEW_RESOURCE,
    payload : {data, callback},
  };
}
//update 
export function updateResource(data, callback) {
    return {
      type: UPDATE_RESOURCE,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteResource(payload) {
  return {
    type: DELETE_RESOURCE,
    payload
  };
}
//pagination
export function resourcePagination(data, callback) {
  return {
    type: RESOURCE_PAGINATION,
    payload : {data, callback},
  };
}

export function resourceCategories(data, callback) {
  return {
    type: RESOURCE_CATEGORIES,
    payload : {data, callback},
  }; 
}