import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const HelpClientSettingsPopup = ({ showPopup, closePopup }) => {

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="FormPop"
        scrollable="true"
      >
        <Modal.Header closeButton>
          <Modal.Title>Client Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="commonContent">
            {/* <h6>Show meal summary to client?</h6>
            <p>
              For Food & Feeling Log users, when set to <span className="fw-bold">Yes</span> a meal summary is shown to your client after they submit a new meal. The summary highlights if their meal was High, Medium or Low in Fructose, Lactose, Fructans, Galactans, Polyols, Histamines or Salicylates and also tells them if their meal was legal on the diets we track (SCD, Paleo, Paleo AIP, Gluten-Free, Grain-Free). When set to <span className="fw-bold">No</span> then no meal summary is shown to the client. Defaults to No.
            </p> */}
            <h6>Show NAQ Symptom Burden Graph to client?</h6>
            <p>
              When set to <span className="fw-bold">Yes</span> your client is able to view the NAQ Symptom Burden Chart after submitting a NAQ. They also have access to the NAQ questions and answers they submitted. When set to <span className="fw-bold">No</span> the NAQ Symptom Burden Chart is not shown to your client and they only have access to the questions and answers they submitted. Defaults to No.
            </p>
            <h6>Client Type</h6>
            <p>
              When set to <span className="fw-bold">Unlinked Client</span> the clients will set up accounts on the site by specifying a login email and password. Once they've logged onto the site they will be able to log their foods/feelings and any To-Do items that you've assigned to them will also show up in their To-Do list. When set to <span className="fw-bold">Direct Link Client</span> allows you to send clients direct links to complete their To-Do Items. They will not create a Nutri-Q account and they will not have access to the site other than the To-Do items you send them.
            </p>
            <h6>Client Category</h6>
            <p>
              This setting aligns a client with a specific Professional Scope (Wellness Practitioner or Health Coach). If the practitioner only has one professional scope then this will always align with that scope. If the practitioner has multiple professional scopes then this can be used to impact the client experience in the following way. If this is set to Health Coach Client then the client experience and terminology will be tailored to reflect the health coaching relationship. And the built-in forms available (both for assigning to that client and filling out about that client) will be restricted to health coaching forms. If this is set to Wellness Practitioner Client then the appropriate terminology will be used and all built-in forms will be available.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HelpClientSettingsPopup;
