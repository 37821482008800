import { decode } from "html-entities";

const RadioButtons = ({ question }) => {
  let radioOptions = null;
  try {
    radioOptions = JSON.parse(question.other_column_data);
  } catch (error) {
    console.log("Error while parsing radio options", question.question);
  }

  return (
    <ul className="list-unstyled ps-0 mb-0 py-2">
      {radioOptions &&
        radioOptions.length > 0 &&
        radioOptions.map((option, i) => {
          let optionValue = "";
          if (
            option !== null &&
            typeof option === "object" &&
            !Array.isArray(option) &&
            typeof option.answer !== "undefined"
          ) {
            optionValue = option.answer;
          } else {
            optionValue = option;
          }

          return (
            <li>
              <div className="py-1 d-flex align-items-start gap-10">
                <div className="cstmInputCheckWrp previewFormField radio position-relative ">
                  <input type="radio" className={`position-absolute w-100 h-100`} disabled />
                  <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                    <span className="icn d-none rounded-circle">{/* <OTick /> */}</span>
                  </div>
                </div>
                <p className=" m-0 ">{decode(optionValue)}</p>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default RadioButtons;
