//action types
export const GET_CLIENT = 'GET_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const VIEW_CLIENT = 'VIEW_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_CLIENT_CATEGORY_LIST = 'GET_CLIENT_CATEGORY_LIST';
export const ADD_UPDATE_FAVORITE = 'ADD_UPDATE_FAVORITE';
export const CLIENT_PAGINATION = 'CLIENT_PAGINATION';
export const CLEAR_CLIENT_PAGE_DATA = "CLEAR_CLIENT_PAGE_DATA";

// ToDo Items
export const GET_CLIENT_TODO_LIST = "GET_CLIENT_TODO_LIST";
export const SAVE_CLIENT_TODO_RESPONSE = "SAVE_CLIENT_TODO_RESPONSE";
export const EDIT_TODO_FOR_CLIENT = "EDIT_TODO_FOR_CLIENT";
export const CREATE_TODO_FOR_CLIENT = "CREATE_TODO_FOR_CLIENT";
export const GET_CLIENT_TODO_DETAILS = "GET_CLIENT_TODO_DETAILS";
export const DELETE_CLIENT_TODO = "DELETE_CLIENT_TODO";
export const ASSIGN_TAGS_TO_CLIENT = 'ASSIGN_TAGS_TO_CLIENT';
export const REMOVE_CLIENT_TAGS = "REMOVE_CLIENT_TAGS";
export const TODO_AUTOSAVE_IN_PROGRESS = "TODO_AUTOSAVE_IN_PROGRESS";

// get graph data
export const GET_GRAPH_DATA = "GET_GRAPH_DATA";

// client settings
export const GET_CLIENT_SETTINGS = "GET_CLIENT_SETTINGS";
export const UPDATE_CLIENT_SETTINGS = "UPDATE_CLIENT_SETTINGS";

// client priorities
export const GET_CLIENT_PRIORITIES = "GET_CLIENT_PRIORITIES";
export const UPDATE_CLIENT_PRIORITIES = "UPDATE_CLIENT_PRIORITIES";

// client recommendations
export const GET_CLIENT_RECOMMENDATION_LIST = "GET_CLIENT_RECOMMENDATION_LIST";
export const GET_CLIENT_RECOMMENDATION_REPORTS_LIST = "GET_CLIENT_RECOMMENDATION_REPORTS_LIST";
export const GET_CLIENT_RECOMMENDATION_DETAILS = "GET_CLIENT_RECOMMENDATION_DETAILS";
export const UPDATE_CLIENT_RECOMMENDATION_DETAILS = "UPDATE_CLIENT_RECOMMENDATION_DETAILS";
export const ADD_CLIENT_RECOMMENDATION = "ADD_CLIENT_RECOMMENDATION";
export const DELETE_CLIENT_RECOMMENDATION = "DELETE_CLIENT_RECOMMENDATION";

// client recommendation templates
export const CREATE_RECOMMENDATION_TEMPLATE = "CREATE_RECOMMENDATION_TEMPLATE";
export const GET_RECOMMENDATION_TEMPLATE_LIST = "GET_RECOMMENDATION_TEMPLATE_LIST";

// client package invitation list
export const GET_PACKAGE_INVITAION_LIST = "GET_PACKAGE_INVITAION_LIST";
export const CANCEL_PACKAGE_INVITATION = "CANCEL_PACKAGE_INVITATION";
export const DELETE_PACKAGE_INVITATION = "DELETE_PACKAGE_INVITATION";

// Fullscript
export const GET_FULLSCRIPT_RECOMMENDATIONS = "GET_FULLSCRIPT_RECOMMENDATIONS";

// set active tab
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

// client action plans
export const GET_CLIENT_ACTION_PLANS_LISTING = "GET_CLIENT_ACTION_PLANS_LISTING";

export const GET_FOOD_MOOD_LOGS = "GET_FOOD_MOOD_LOGS";

// get client details
export function getClient(data, callback) {
  return {
    type: GET_CLIENT,
    payload : {data, callback},
  };
}
//add 
export function addClient(data, callback) {
  return {
    type: ADD_CLIENT,
    payload : {data, callback},
  };
}

//view 
export function viewClient(data, callback) {
  return {
    type: VIEW_CLIENT,
    payload : {data, callback},
  };
}
//update 
export function updateClient(data, callback) {
    return {
      type: UPDATE_CLIENT,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteClientData(data, callback) {
  return {
    type: DELETE_CLIENT,
    payload: {data, callback}
  };
}

export function clearClientPageData() {
  return {
    type: CLEAR_CLIENT_PAGE_DATA,
  };
}

//get client 
export function getClientCategoryList(payload) {
  return {
    type: GET_CLIENT_CATEGORY_LIST,
    payload
  };
}

//add update favorite
export function addUpdateFavorite(data, callback) {
  return {
    type: ADD_UPDATE_FAVORITE,
    payload : {data, callback},
  };
}

//assign tags
export function assignTagsToClient(data, callback) {
  return {
    type: ASSIGN_TAGS_TO_CLIENT,
    payload : {data, callback},
  };
}

// remove tag
export function removeClientTags(data, callback) {
  return {
    type: REMOVE_CLIENT_TAGS,
    payload : {data, callback},
  };
}

//client pagination
export function clientPagination(data, callback) {
  return {
    type: CLIENT_PAGINATION,
    payload : {data, callback},
  };
}

// get todo list
export function getClientToDoListing(data, callback) {
  return {
    type: GET_CLIENT_TODO_LIST,
    payload : {data, callback},
  };
}

// get ToDo details
export function getClientToDoDetails(data, callback) {
  return {
    type: GET_CLIENT_TODO_DETAILS,
    payload : {data, callback},
  };
}

export function saveClientTodoResponse(data, callback) {
  return {
    type: SAVE_CLIENT_TODO_RESPONSE,
    payload : {data, callback},
  };
}

export function editToDoForClient(data, callback) {
  return {
    type: EDIT_TODO_FOR_CLIENT,
    payload : {data, callback},
  };
}

export function createClientToDo(data, callback) {
  return {
    type: CREATE_TODO_FOR_CLIENT,
    payload : {data, callback},
  };
}

//delete 
export function deleteToDoItem(data, callback) {
  return {
    type: DELETE_CLIENT_TODO,
    payload: {data, callback}
  };
}

// get all client settings
export function getAllClientSettings(data, callback) {
  return {
    type: GET_CLIENT_SETTINGS,
    payload: {data, callback}
  };
}

// update client settings
export function updateSettingsForClient(data, callback) {
  return {
    type: UPDATE_CLIENT_SETTINGS,
    payload: {data, callback}
  };
}

// get recommendations list
// export function getClientRecommendationListing(data, callback) {
//   return {
//     type: GET_CLIENT_RECOMMENDATION_LIST,
//     payload : {data, callback},
//   };
// }

// get recommendations list
export function getClientRecommendationReportListing(data, callback) {
  return {
    type: GET_CLIENT_RECOMMENDATION_REPORTS_LIST,
    payload : {data, callback},
  };
}


// add client recommendation
export function addRecommendationForClient(data, callback) {
  return {
    type: ADD_CLIENT_RECOMMENDATION,
    payload: {data, callback}
  };
}

// get Recommendation details
export function getClientRecommendationDetails(data, callback) {
  return {
    type: GET_CLIENT_RECOMMENDATION_DETAILS,
    payload : {data, callback},
  };
}

// update client recommendation
export function updateClientRecommendation(data, callback) {
  return {
    type: UPDATE_CLIENT_RECOMMENDATION_DETAILS,
    payload: {data, callback}
  };
}

// delete recommendation
export function deleteClientRecommendation(data, callback) {
  return {
    type: DELETE_CLIENT_RECOMMENDATION,
    payload: {data, callback}
  };
}

// create recommendation template
export function createRecommendationTemplate_(data, callback) {
  return {
    type: CREATE_RECOMMENDATION_TEMPLATE,
    payload: {data, callback}
  };
}

// get recommendation templates list
export function getRecommendationTemplateListing(data, callback) {
  return {
    type: GET_RECOMMENDATION_TEMPLATE_LIST,
    payload : {data, callback},
  };
}

// get client graph data
export function getGraphData(data, callback) {
  return {
    type: GET_GRAPH_DATA,
    payload : {data, callback},
  };
}

// get recommendation templates list
export function getClientPackageInvitations(data, callback) {
  return {
    type: GET_PACKAGE_INVITAION_LIST,
    payload : {data, callback},
  };
}

// cancel client package invitation
export function cancelClientPackageInvitation(data, callback) {
  return {
    type: CANCEL_PACKAGE_INVITATION,
    payload : {data, callback},
  };
}

// remove client package invitation
export function removeClientPackageInvitation(data, callback) {
  return {
    type: DELETE_PACKAGE_INVITATION,
    payload : {data, callback},
  };
}

// get fullscript recommendations
export function getFullscriptRecommendations(data, callback) {
  return {
    type: GET_FULLSCRIPT_RECOMMENDATIONS,
    payload : {data, callback},
  };
}

// set client page active tab
export function setActiveTab(data) {
  return {
    type: SET_ACTIVE_TAB,
    payload : data,
  }; 
}

// get client action plans listing
export function getClientActionPlansListing(data, callback) {
  return {
    type: GET_CLIENT_ACTION_PLANS_LISTING,
    payload : {data, callback},
  };
}

export function getFoodMoodLogs(data, callback) {
  return {
    type: GET_FOOD_MOOD_LOGS,
    payload : {data, callback},
  }; 
}

// To-Do auto save state
export function setTodoAutoSaveState(data, callback) {
  return {
    type: TODO_AUTOSAVE_IN_PROGRESS,
    payload : data,
  };
}