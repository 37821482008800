import { Button } from "react-bootstrap";
import { MyDoc as RecommendationReport } from "./RecommendationReportPDF";
import { pdf } from '@react-pdf/renderer';

const RecommendationBlobProvider = ({ callback, nutritionalRows, lifestyleRows, details, additionalDetails, client, date }) => {
  
  const getPdfBlob = async () => {
      const doc = <RecommendationReport nutritionalRows={nutritionalRows} lifestyleRows={lifestyleRows} details={details} additionalDetails={additionalDetails} client={client} date={date} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const reader = new FileReader();
      reader.onloadend = () => callback(reader.result);
      reader.readAsDataURL(blob);
  }

  return (
    <Button
      onClick={() => {
        getPdfBlob()
      }}
      className="d-flex align-items-center justify-content-center commonBtn btnSm"
    >
      Yes, Send this Recommendation
    </Button>
  )
}

export default RecommendationBlobProvider;