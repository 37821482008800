import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { getDataAnalysisProrityReportsV2 } from 'store/services/clientDataReportsService';
import { useShowLoader } from 'hooks/useShowLoader';
import { Button } from 'react-bootstrap';

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "10px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "15px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
    // width: "100%"
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  tableOuter: {
    marginTop: "15px"
  },
  tableHeading: {
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: "#08415c",
    borderBottom: "2px solid #ccc"
  },
  sectionHeading: {
    fontSize: "18px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: "#08415c",
    marginTop: "15px",
    // borderBottom: "2px solid #ccc"
  },
  notesContainer: {
    padding: "0px 10px 5px 5px",
    marginBottom: "5px",
    fontSize: "9px",
    backgroundColor: "#eee",
    width: "100%"
  },
  noteHeading: {
    textDecoration: "underline",
    marginTop: "5px",
  },
  noteText: {
    padding: 0,
    margin: 0,
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "10px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "25%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "9px",
    // fontWeight: "bold",
    // fontFamily: "Helvetica-Bold",
    width: "25%",
    padding: "5px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});

const DownloadPriorityCompReportPDF = ({ client, date, naqList, label, type }) => {

  const showLoader = useShowLoader();

  const downloadPDFReport = async () => {
    try {
      showLoader(true);
      const priorityReport = await getNAQv2PriorityReportCompData(naqList, client);
      // const todoNotes = await getNotes(todoId);

      let colors = [];
      colors.push(naqList.find(naq => naq.id == priorityReport.current_todo)?.color);
      colors.push(naqList.find(naq => naq.id == priorityReport.prev_todo)?.color);

      const doc = 
        <NAQ2PriorityCompDoc
          priorityReport={priorityReport}
          client={client}
          date={date}
          type={type}
          colors={colors}
        />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Priority-Report.pdf');
      showLoader(false);
    } catch(err) {
      console.log("Error downloading report", err);
      showLoader(false);
    }
  }

  return (
    <Button
      onClick={downloadPDFReport}
      className="d-flex align-items-center justify-content-center commonBtn btnSm"
    >
      <span className="icn me-2">
        <DownloadIcn />
      </span>
      {label}
    </Button>
  )
}


export const NAQ2PriorityCompDoc = ({ priorityReport, notes, client, date, type, colors }) => {
  return (
    <Document>

      {/* PAGE 1 */}
      {/* Potential Conditions */}
      <Page style={styles.page}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Priority Comparision Report
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>
        {priorityReport && priorityReport[type] && priorityReport[type].map((section) => {
          if (!section.title || section.title == "null") {
            return "";
          }
          return (
            <View>
              <Text style={styles.sectionHeading}>
                {section.title}
              </Text>
              {section.improved && section.improved.length > 0 &&
                <View style={styles.tableOuter}>
                  <Text style={styles.tableHeading}>
                    Improved
                  </Text>
                  <View style={styles.tableContainer}>
                    <PriorityTable
                      questions={section.improved}
                      priorityReport={priorityReport}
                      colors={colors}
                    />
                  </View>
                </View>
              }
              {section.worsened && section.worsened.length > 0 &&
                <View style={styles.tableOuter}>
                  <Text style={styles.tableHeading}>
                  Worsened
                  </Text>
                  <View style={styles.tableContainer}>
                    <PriorityTable
                      questions={section.worsened}
                      priorityReport={priorityReport}
                      colors={colors}
                    />
                  </View>
                </View>
              }
              {section.unchanged && section.unchanged.length > 0 &&
                <View style={styles.tableOuter}>
                  <Text style={styles.tableHeading}>
                  Unchanged
                  </Text>
                  <View style={styles.tableContainer}>
                    <PriorityTable
                      questions={section.unchanged}
                      priorityReport={priorityReport}
                      colors={colors}
                    />
                  </View>
                </View>
              }
            </View>
          )
        })}

        {/* {(priorityReport && priorityReport?.length > 0) &&
          <View style={styles.tableContainer}>
            <PriorityTable items={priorityReport} />
          </View>
        } */}

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getNAQv2PriorityReportCompData = async (todoIds, client) => {
  try {
    if (!todoIds) {
      throw new Error("Invalid ToDo ID");
    }
    let data = {
      todoIds,
      clientId: client.id
    };
    const response = await getDataAnalysisProrityReportsV2(data);
    if (response.success === true) {
      return response.body;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const PriorityTable = ({ questions, priorityReport, colors }) => {
  const rows = questions && questions.map((question, i) => {
    if(!question) {
      return null;
    }
    return (
      <>
        <View style={[tableStyles.row]} key={i}>
          <Text style={[tableStyles.cell, {width: "30%"}]}>{`${decode(question.question)}`}</Text>
          <Text style={[tableStyles.cell, {width: "30%"}]}>{decode(question?.answer_current)}</Text>
          <Text style={[tableStyles.cell, {width: "30%"}]}>{decode(question?.answer_previous)}</Text>
          <Text style={[tableStyles.cell, {width: "10%"}]}>{question.score_change}</Text>
        </View>
      </>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, {width: "30%"}]}></Text>
        <Text style={[tableStyles.headerCell, {width: "30%", borderBottom: `4px solid ${colors && colors[0]}`}]}>{moment(priorityReport.currentCompletedAt).format("MMM DD, YYYY")}</Text>
        <Text style={[tableStyles.headerCell, {width: "30%", borderBottom: `4px solid ${colors && colors[1]}`}]}>{moment(priorityReport.prevCompletedAt).format("MMM DD, YYYY")}</Text>
        <Text style={[tableStyles.headerCell, {width: "10%"}]}></Text>
      </View>
      <View style={tableStyles.row}>
      {/* <Text style={[tableStyles.headerCell, {width: "15%"}]}>Priority</Text> */}
        <Text style={[tableStyles.headerCell, {width: "30%"}]}>Question</Text>
        <Text style={[tableStyles.headerCell, {width: "30%"}]}>Answer</Text>
        <Text style={[tableStyles.headerCell, {width: "30%"}]}>Answer</Text>
        <Text style={[tableStyles.headerCell, {width: "10%"}]}>Change</Text>
      </View>
      {rows}
    </View>
  )
};

export default DownloadPriorityCompReportPDF;