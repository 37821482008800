import { decode } from "html-entities";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const noteColors = ["#595959", "#a94442", "#3c763d", "#2f6f9f", "#893563"];

export const singleNoteField = {
  note: "",
  // color: noteColors[0],
};

const AddNotesPopup = ({ showPopup, closePopup, notesData, handleSave }) => {
  const [noteValue, setNoteValue] = useState("");
  const [noteFieldTouched, setNoteFieldTouched] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [colorSwatchIdx, setColorSwatchIdx] = useState(null);

  useEffect(() => {
    if (notesData && notesData.notes) {
      setNoteValue(notesData.notes);
    }
  }, [notesData]);

  useEffect(() => {
    if(notesData && notesData?.page && noteFieldTouched) {
      let timeout = setTimeout(async () => {
        try {
          setIsAutoSaving(true);
          await handleSave(noteValue, notesData.page);
          setIsAutoSaving(false);
        } catch(err) {
          console.log("Error while auto saving notes", err);
          setIsAutoSaving(false);
        }
      }, 600);

      return () => clearTimeout(timeout)
    }
  }, [noteValue]);

  const hidePopup = () => {
    setNoteFieldTouched(false);
    setNoteValue("");
    closePopup();
  };

  const handleNoteValueChange = (value) => {
    setNoteFieldTouched(true);
    setNoteValue(value);
  };

  const handleSaveNotes = async () => {
    try {
      setSaving(true);
      await handleSave(noteValue, notesData.page);
      hidePopup();
      setSaving(false);
    } catch (err) {
      console.log("Error saving notes", err);
      setSaving(false);
    }
  };

  const toggleColorSwatch = (index) => {
    if (colorSwatchIdx == index) {
      setColorSwatchIdx(null);
    } else {
      setColorSwatchIdx(index);
    }
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={hidePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="journalPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">
            Add Notes
            {(notesData?.page && notesData?.page.page_title) && `: ${decode(notesData?.page?.page_title)}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="fm-note-fields py-2">
            <div className="py-2">
              <div className="input-group">
                <textarea
                  className="form-control resizable"
                  aria-label="With textarea"
                  rows={4}
                  value={noteValue || ""}
                  onChange={(e) => {
                    handleNoteValueChange(e.target.value);
                  }}
                ></textarea>
              </div>
              {isAutoSaving && (
                <p className="text-muted m-0">Autosaving...</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={hidePopup}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveNotes}
            className="d-flex align-items-center justify-content-center commonBtn"
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNotesPopup;
