import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getWellnessAssessmentData } from 'store/services/clientDataReportsService';
import { BarChart } from 'pages/SideTabs/MyClients/Detail/component/TabContent/ToDoTab/Component/SubmittedData/Component/AccordionTabs/HealthWellnessAssessment/Components/AssessmentCharts/Components/BarChart';
import D3Chart from 'pages/SideTabs/MyClients/Detail/component/TabContent/ToDoTab/Component/SubmittedData/Component/AccordionTabs/HealthWellnessAssessment/Components/AssessmentCharts/Components/D3Chart';

export const HealthWellnessGraphs = forwardRef(function Graphs({ innerRef, client, todoId, setLoading }, ref) {
// const HealthWellnessGraphs = ({ innerRef, client, date, todoId, formName, label, showNotes, showLoader, hideLoader }) => {
  const [wellnessAssessData, setWellnessAssessData] = useState(null);

  const wheelChartRef = useRef(null);
  const barChartRef = useRef(null);

  useEffect(() => {
    if(!wellnessAssessData) {
      getWellnessGraphData();
    }
  }, [])

  const getWellnessGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId: client.id
      };
      setLoading(true);
      const response = await getWellnessAssessmentData(data);
      if (response.success === true) {
        const data = response.body;
        setWellnessAssessData(data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useImperativeHandle(ref, () => {
		return {
			downloadChartImage() {
        // console.log("wheelChartRef.current", wheelChartRef.current, barChartRef.current);
				if (wheelChartRef.current && barChartRef.current) {
					const barChartImage = barChartRef.current.downloadChartImage();
          const wheelChartImage = wheelChartRef.current.downloadChartImage();
          // console.log("barChartImage", barChartImage);
          // console.log("wheelChartImage", wheelChartImage);
          return {
            wheelChartImage,
            barChartImage
          }
				}
			}
		}
	});

  return (
    <div ref={ref}>
      <div style={{position: "fixed", right: "-99999px"}}>
        <div style={{ width: "400px", height: "400px" }}>
          <D3Chart
            ref={wheelChartRef}
            chartData={wellnessAssessData}
          />
        </div>
        <div style={{ width: "600px", height: "600px" }}>
          <BarChart
            ref={barChartRef}
            chartData={wellnessAssessData}
            fontSize={20}
          />
        </div>
      </div>
    </div>
  )
})

export default HealthWellnessGraphs;