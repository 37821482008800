import { FULL_NAME } from "helpers/common";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UnlinkClientConfirmPopup = ({
  showPopup,
  setShowPopup,
  handleUnlink,
  clientDetails,
}) => {

  const [isUnlinking, setIsUnlinking] = useState(false);

  const handleClose = () => {
    setShowPopup(false);
  }

  const onClickUnlink = () => {
    handleUnlink(setIsUnlinking);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Unlink the Fullscript client record?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg="12" className="">
              <p>If {FULL_NAME(clientDetails.first_name, clientDetails.last_name)} is linked to the wrong Fullscript client record, please unlink the client. After unlinking, you will be able to select the correct Fullscript client record.</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center btnSm btn-danger"
            onClick={onClickUnlink}
            disabled={isUnlinking}
          >
            {isUnlinking ? "Please wait..." : "Yes, unlink client record"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnlinkClientConfirmPopup;
