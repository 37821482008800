import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ViewDirectLinkPopup = ({ todo, showPopup, closePopup }) => {
  const todoLink = `${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/forms/${todo.uuid}`;
  const [textCopied, setTextCopied] = useState(false);
  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      setTextCopied(null);
    }
  }, []);

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Direct Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="10" className="my-2">
              <pre className="m-0 fw-sbold pb-1">
                { todoLink }
              </pre>
            </Col>
            <Col lg="2">
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => {
                  try {
                    navigator.clipboard.writeText(todoLink)
                    setTextCopied(true);
                    timeoutId = setTimeout(() => {
                      setTextCopied(false);
                    }, 3000)
                  } catch (err) {
                    console.log("Failed to copy to the clipboard");
                  }
                }}
              >
                { textCopied ? "Copied!" : "Copy" }
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewDirectLinkPopup;
