import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik  } from 'formik';
import { passwordStrength } from 'check-password-strength'

//imported
import validation from "./validation";
import LoadingButton from "components/shared/LoadingButton"

const Form_ = ({initialValues , submitting, updateData_, loader}) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

    return(
        <>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validation}
            onSubmit={updateData_}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Email*
                    </label>
                    <input
                        type="email"
                        className={`form-control ${errors.email && 'is-invalid'}`}
                        name="email"
                        onChange={handleChange}
                        placeholder="Email"
                        onBlur={handleBlur}
                        value={values.email}
                        autoComplete="off" 
                    />
                    {(errors.email && touched.email) &&
                        <p className="invalid-feedback">{errors.email}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    First Name*
                    </label>
                    <input
                        type="text"
                        className={`form-control ${errors.first_name && 'is-invalid'}`}
                        name="first_name"
                        onChange={handleChange}
                        placeholder="First Name"
                        onBlur={handleBlur}
                        value={values.first_name}
                        autoComplete="off" 
                    />
                    {(errors.first_name && touched.first_name) &&
                      <p className="invalid-feedback">{errors.first_name}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    Last Name*
                    </label>
                    <input
                        type="text"
                        className={`form-control ${errors.last_name && 'is-invalid'}`}
                        name="last_name"
                        onChange={handleChange}
                        placeholder="Last Name"
                        onBlur={handleBlur}
                        value={values.last_name}
                        autoComplete="off" 
                    />
                    {(errors.last_name && touched.last_name) &&
                      <p className="invalid-feedback">{errors.last_name}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Password*
                    </label>
                    <div style={{position: "relative"}}>
                      <input
                          type={`${showPassword ? "text" : "password"}`}
                          className={`form-control ${errors.password && 'is-invalid'}`}
                          name="password"
                          onChange={handleChange}
                          placeholder="********"
                          onBlur={handleBlur}
                          value={values.password}
                          autoComplete="off" 
                      />
                      {!errors.password &&
                        <span
                          onClick={togglePasswordVisibility}
                          className="password-eye-icon"
                        >
                          <i className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                        </span>
                      }
                    </div>
                    {values?.password && (
                      <p className="m-0 py-1 password-strength">Password Strength: <span className={`strength strength-${passwordStrength(values.password).id}`}>{passwordStrength(values.password)?.value}</span></p>
                    )}
                    {(errors.password && touched.password) &&
                        <p className="invalid-feedback d-block">{errors.password}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Re-enter Password*
                    </label>
                    <input
                        type={`${showPassword ? "text" : "password"}`}
                        className={`form-control ${errors.confirm_password && 'is-invalid'}`}
                        name="confirm_password"
                        onChange={handleChange}
                        placeholder="********"
                        onBlur={handleBlur}
                        value={values.confirm_password}
                        autoComplete="off" 
                    />
                    {(errors.confirm_password && touched.confirm_password) &&
                        <p className="invalid-feedback">{errors.confirm_password}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    {/* <Button className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                        Login Now
                      </Button> */}
                    {/* <Link
                      to="/dashboard"
                      className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                    >
                      Login Now
                    </Link> */}
                    <LoadingButton
                        type="submit"
                        className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                        loading={loader}
                        disabled={submitting}
                    >
                    {isSubmitting ? 'Loading...' : 'Sign Up'}
                    </LoadingButton>

                    <div className="mt-4">
                      <p className="formText m-0 text-center text-muted">
                        Already have an account?{" "}
                        <Link to="/login" className=" form-link fw-sbold underline">
                          Sign in here
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
        </Formik>
        </>
    )
}

export default Form_;