import React, { useState } from "react";
import { Modal} from "react-bootstrap";

//imported
import LoadingButton from "components/shared/LoadingButton";

import Form from "./Component/EditClientForm";
import moment from "moment";
import { convertTimezone } from "helpers/common";

const EditClientPopup = ({ showPopup, togglePopup, client, handleSubmit }) => {
  // const closePopup = () => {
  //   setShowPopup(!showPopup);
  // };

  const editFormInitialValues = {
    first_name: client.first_name || "",
    last_name: client.last_name || "",
    email: client.email || "",
    phone: (client.country_code ? `${client.country_code}${client.phone}` : client.phone) || "",
    street: client.street || "",
    apartment: client.apartment || "",
    city: client.city || "",
    state: client.state || "",
    zipcode: client.zipcode || "",
    country: client.country || "",
    country_code: client.country_code || "",
    birth_date: client.birth_date && convertTimezone(client.birth_date, moment.tz.guess(), "YYYY-MM-DD") || "",
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={togglePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="FormPop editClient"
        scrollable="true"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Client Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form 
            initialValues={editFormInitialValues}
            handleSubmit={handleSubmit}
            closePopup={togglePopup}
            client={client}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditClientPopup;
