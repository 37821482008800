import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, GET_PACKAGE,ADD_PACKAGE, UPDATE_PACKAGE, VIEW_PACKAGE, DELETE_PACKAGE, SEND_PACKAGE  } from './apiPath'
import { getPackage, addPackage, viewPackage, updatePackage, deletePackage} from 'store/actions/package';

//get
export function getPackage_(value, callback) {
  let url = `${MAIN_PATH}${GET_PACKAGE}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getPackage(data, callback));
      return data;
    });
}

//add
export function addPackage_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${ADD_PACKAGE}`, value)
    .then((data) => {
      dispatch(addPackage(data, callback));
      return data;
    });
}

//view
export function viewPackage_(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${VIEW_PACKAGE}${value}`)
    .then((data) => {
      dispatch(viewPackage(data, callback));
      return data;
  });
}

//edit
export function updatePackage_(id, value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${UPDATE_PACKAGE}${id}`, value)
    .then((data) => {
      dispatch(updatePackage(data, callback));
      return data;
    });
}

//delete
export function deletePackage_(id) {
  return (dispatch) => deleteData(`${MAIN_PATH}${DELETE_PACKAGE}${id}`)
    .then((data) => {
       dispatch(deletePackage(data));
      return data;
    });
}

// send package to client
export function sendPackage(data) {
  let { packageId, values } = data;
  let url = `${MAIN_PATH}${SEND_PACKAGE}${packageId}`;
  // let url = `http://localhost:3000/dev/clients/packages/invitation/send/${packageId}`
  return postData(url, values);
}