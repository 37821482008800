import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../Assets/Images/Ss_addc.png";
import s2 from "../../../Assets/Images/Ss_addcform.png";
import s3 from "../../../Assets/Images/Ss_clientToDo.png";
import s4 from "../../../Assets/Images/Ss_success.png";
import s5 from "../../../Assets/Images/Ss_emailT.png";
import s6 from "../../../Assets/Images/Ss_cInbox.png";
import s7 from "../../../Assets/Images/Ss_successLink.png";
import s8 from "../../../Assets/Images/Ss_cSignUp.png";
import s9 from "../../../Assets/Images/Ss_clientPass.png";
import s10 from "../../../Assets/Images/Ss_todoItems.png";
import s11 from "../../../Assets/Images/Ss_recentEvents.png";

const HelpAddFirstClient = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div class="commonContent py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Adding Your First Client
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                  <p class="m-0">
                    In this article we'll be adding a regular 'Full Account'
                    Client Type, which means that the client will create their
                    own client account with an email/password in the client
                    portal. They will log into the client portal to complete any
                    Forms/Questionnaires etc that you assign to them. The site
                    also allows you to set up 'Direct Link Clients', which don't
                    create accounts. You can learn more about the different
                    client types by{" "}
                    <Link className="fw-sbold">
                      viewing the FAQ on Client Types.
                    </Link>
                  </p>
                  <p className="m-0 py-2">
                    Start by logging into your Nutri-Q account, then click 'Add
                    New Client' in the top navigation on the right side:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Next we'll add our client's name, email etc. Many of the
                    fields are optional so fill in the information you would
                    like to include in your client's record:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s2} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Scrolling down the page, at the bottom we can choose to
                    assign our client one or more 'To-Do' items, which they will
                    be asked to complete after they log into the site for the
                    first time. So in our example below, our new client will
                    create their account and then once they are logged into the
                    client portal they will see that they have been assigned a
                    Nutritional Assessment Questionnaire (NAQ):
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s3} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Next we'll click the 'Submit' button and we'll see a success
                    message letting us know we have successfully added our new
                    client and they have been sent an invite to the site:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s4} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    The client will receive an email that looks like the below.
                    We called our practice 'Mojo Wellness' so that is the the
                    title shown in the email. Practitioners are CCed on all
                    client emails so if your client can't find the invite email
                    you can easily forward it to them from your inbox as well if
                    needed:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s5} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    If you'd like to send your clients a custom message in the
                    invite, we'd suggest checking the 'Do not email' checkbox
                    when adding a new client to the site:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s6} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Then after you have added the new client, you can copy the
                    invite link and send it to them from your own email with
                    your message:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s7} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Whichever way you choose to provide the invite link to your
                    client, the next step will be for the client to click on the
                    invite link. They are then taken to the client portal
                    sign-up page with their email already pre-populated. (They
                    are also free to update their login email to whatever they'd
                    like here as well). The client then clicks on the 'Next'
                    button:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s8} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    The client will then create a password and click 'Sign Up':
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s9} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    After agreeing to the terms, the client is then taken to
                    their Dashboard page where they will see any To-Do items you
                    have assigned to them listed at the top of the page:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s10} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    On the practitioner's side, we'll see our client's progress
                    as they're signing up for the site in the Recent Events
                    module of our Dashboard:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s11} alt="" className="img-fluid" />
                  </div>
                  <p className="m-0 py-2">
                    One excellent way to fully understand the workflow for
                    adding clients, completing to-do items and viewing results
                    is to add yourself as a practice client. You're free to use
                    the same email in the client portal as you do in the
                    practitioner portal or you can use a different email. Just
                    add yourself and fill out a few forms to get a feel for how
                    everything works. That way you'll be prepared to answer any
                    client questions that might come up.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpAddFirstClient;
