import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Path from "Routes/paths";
import * as actions from "store/actions";
import handGraphic from "Assets/Images/handGraphic.png";

import { ReactComponent as HArrow } from "Assets/Images/icn/handArrow.svg";
import { ReactComponent as CheckIcon } from "Assets/Images/icn/OrangeTick.svg";
import { addUpdateAccountSetting_, getAccountSettingList_ } from "store/services/accountSettingService";
import { useEffect, useState } from "react";
import { gettingStartedProgress } from "store/services/miscService";

const GettingStarted = ({ dispatch, accountSettings, stepsProgress }) => {
  const [settingData, setSettingData] = useState(null);
  const [dismissed, setDismissed] = useState(false);
  
  useEffect(() => {
    if(accountSettings) {
      let setting = accountSettings.find(setting => setting.data_key === "show_get_started");
      if(setting) {
        setSettingData(setting);
      }
    }
  }, [accountSettings]);

  useEffect(() => {
    if(settingData && settingData.data_set == "yes") {
      fetchGetStartedProgress();
    }
  }, [settingData])

  const fetchGetStartedProgress = async () => {
    try {
      await dispatch(gettingStartedProgress());
    } catch(err) {
      console.log("Error fetching get started progress", err);
    }
  }

  const dismissGettingStarted = async () => {
    if(!settingData) {
      return;
    }
    try {
      let data = {
        data_set : "no"
      }
      setDismissed(true);
      await dispatch(addUpdateAccountSetting_(settingData.id, data));
    } catch(err) {
      console.log("Error dismissing getting started", err);
    }
  }

  if(!settingData || settingData.data_set === "no" || dismissed) {
    return "";
  }

  return (
    <Col lg="12" className="my-2">
      <div
        className="position-relative p-3 p-lg-4 gettingStarted"
        style={{ background: "#fff", zIndex: "9" }}
      >
        <Link
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            fontSize: "18px"
          }}
          onClick={dismissGettingStarted}
        >
          <i className="fa fa-close"></i>
        </Link>
        <Row>
          <Col lg="4" className="my-2">
            <div className="commonContent py-2">
              <h3 className="m-0 fw-bold">Getting Started 👋</h3>
              <p className="m-0">
                Welcome to <b>Nutri-Q!</b> Here's a short list of
                resources to help you get your account all setup and
                running. Once an item is completed you'll see a check
                mark next to it!
              </p>
            </div>
          </Col>
          <Col lg="4" sm="6" className="my-2">
            <ul className="list-unstyled ps-0 mb-0 BulletList">
              <li className="py-2">
                <Link
                  to={Path.addClient}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.add_first_client === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Add your first client
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
              <li className="py-2">
                <Link
                  to={Path.appointment}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.setup_appointment_calender === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Setup your appointment scheduler
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
              <li className="py-2">
                <Link
                  to={`${Path.myAccount}?tab=3rdParty`}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.link_google_calendar === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Link your Google Calendar
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
              <li className="py-2">
                <Link
                  to={`${Path.myAccount}?tab=3rdParty`}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.link_stripe_account === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Link your Stripe account
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg="4" sm="6" className="my-2">
            <ul className="list-unstyled ps-0 mb-0 BulletList">
              <li className="py-2">
                <Link
                  to={`${Path.myAccount}?tab=3rdParty`}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.link_fullstrip_account === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Link your Fullscript account
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
              <li className="py-2">
                <Link
                  to={Path.addNewForm}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.create_custom_form === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Create your first custom form
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
              <li className="py-2">
                <Link
                  to={Path.myAccount}
                  className={`d-flex align-items-start fw-sbold step-link ${stepsProgress?.create_first_package === true ? "completed" : ""}`}
                >
                  <span className="icn me-2">
                    <HArrow />
                  </span>
                  Create your first package
                  <span className="icn ms-1 green-check">
                    <CheckIcon />
                  </span>
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <img
          src={handGraphic}
          alt=""
          style={{ right: "10px", bottom: "10px", zIndex: "-1" }}
          className="img-fluid graphic position-absolute"
        />
      </div>
    </Col>
  )
}

export default GettingStarted;