import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
//imported
import { login } from 'store/services/authService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import Form from './Form'
import { hasActivePlan } from "helpers/common";

const Login = ({dispatch, submitting}) => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [storedCredentials, setStoredCredentials] = useState(null);  

  const initialValues = {
    email : storedCredentials ? storedCredentials?.email : "",
    password : storedCredentials ? storedCredentials?.password : ""
  };

  // Handle changes in the "Remember Me" checkbox
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() =>{
      let values_ = localStorage.getItem('credentials_');
      values_ = JSON.parse(values_)
      if(values_){
        setStoredCredentials({email: values_?.email, password: values_?.password})
        setRememberMe(true)
      }
  }, [])

  const submitData_ = async (values, {setSubmitting}) => {
    try{
        setSubmitting(true);
        // const callback = (response) => {
        //     const { body } = response;
        //     // if(success == true){
        //       if(body.terms_accepted === 'no'){
        //         navigate(Path.dashboardTermsCondition)
        //       }
        //       if(body.is_plan_active === 'no' && body.is_free_account === 'no' ){
        //         navigate(Path.dashboardSubscription)
        //       }
        //       if(body.is_free_account === 'yes' ){
        //         navigate(Path.dashboard)
        //       }
        //     // }
        // }
        if (rememberMe) {
          let email = values.email
          let password = values.password
          const credentials = { email, password };
          localStorage.setItem('credentials_', JSON.stringify(credentials));
        }else{
          localStorage.removeItem('credentials_');
        }
        values = JSON.stringify(values)
        let res = await dispatch(login(values))
        if(res){
          const { body } = res;
          
          if(body.terms_accepted === 'no'){
            navigate(Path.dashboardTermsCondition)
          }
          if(!hasActivePlan(body)){
            navigate(Path.dashboardSubscription)
          }
        setSubmitting(false);
      }
    } catch (err) {
        console.log(err,  "==============================");
        setSubmitting(false);
    }
  }

  return (
    <>
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <label className="m-0 form-label">Welcome back</label>
          <h2 className="m-0 fw-bold">Sign in to your practitioner account</h2>
        </div>
        <div className="FormBody">
            <Form
                initialValues={initialValues}
                submitting={submitting}
                updateData_={submitData_}
                rememberMe={rememberMe}
                handleRememberMeChange={handleRememberMeChange}
            />
        </div>
      </div>
    </>
  );
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(Login);