import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from "react-router-dom";
import annotationPlugin from 'chartjs-plugin-annotation';
import { BarChart } from "./Components/BarChart";
import { LineChart } from "./Components/LineChart";

import {
  Chart as ChartJS,
} from 'chart.js';
import { decode } from "html-entities";
import { getChartHeightByScreenSize, getScreenSize } from "components/shared/ChartConfig";
import { connect } from "react-redux";
import * as actions from "store/actions"
import { getBodySymptomBurdenGraphV1 } from "store/services/clientDataReportsService";

ChartJS.register(annotationPlugin);

const NAQBodySymptomsGraph = ({ dispatch, eventKey, todoId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChartType, setSelectedChartType] = useState("bar");
  const [chartHeight, setChartHeight] = useState(175);
  const [screenSize, setScreenSize] = useState("extra-large");
  const [isLoading, setIsLoading] = useState(false);
  const [bodySymptomBurdenGraphData, setBodySymptomBurdenGraphData] = useState(null);
  const { clientId } = useParams();

  useEffect(() => {
    let size = getScreenSize(screenSize);
    setScreenSize(size);
  }, []);

  useEffect(() => {
    let height = getChartHeightByScreenSize(screenSize, chartHeight);
    setChartHeight(height);
  }, [screenSize]);

  useEffect(() => {
    if (isOpen && !bodySymptomBurdenGraphData) {
      getBodySymptomBurdenGraphData();
    }
  }, [isOpen]);

  const getBodySymptomBurdenGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      const response = await getBodySymptomBurdenGraphV1(data);
      if (response.success === true) {
        const data = response.body;
        setBodySymptomBurdenGraphData(data);
      }
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  return (
    <Accordion.Item eventKey={eventKey} className="symptom-burden-graph my-2">
      <Accordion.Header>
        Symptom Burden Graph
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <div className="py-2">
          <Row>
            <Col lg="2">
              <Form.Label>
                Chart type:
              </Form.Label>
              <Form.Select
                onChange={(e) => {
                  setSelectedChartType(e.target.value);
                }}
                value={selectedChartType}
              >
                <option value="bar">Bar Chart</option>
                <option value="line">Line Chart</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
        {isLoading ?
          <div className="p-2">
            <p>Loading...</p>
          </div>
          :
          <>
          {bodySymptomBurdenGraphData &&
            <>
              <div className="naq1-chart-container py-2 my-2">
                {selectedChartType == "bar" &&
                  <BarChart
                    chartData={bodySymptomBurdenGraphData}
                    height={chartHeight}
                    screenSize={screenSize}
                  />
                }
                {selectedChartType == "line" &&
                  <LineChart
                    chartData={bodySymptomBurdenGraphData}
                    height={chartHeight}
                    screenSize={screenSize}
                  />
                }
              </div>
              <div className="py-2 totals">
                <h6>Section Totals</h6>
                <ul className="naq1-symptom-totals">
                  {bodySymptomBurdenGraphData?.bar &&
                    bodySymptomBurdenGraphData?.bar?.length > 0 &&
                    bodySymptomBurdenGraphData?.bar?.map((row) => {
                      return (
                        <li>
                          <strong>{`${decode(row.title)}: ${row.rawTotal}`}</strong>
                          {` chart max of ${row.max}`}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </>
          }
          </>
        }
      </Accordion.Body>
    </Accordion.Item>
  )
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NAQBodySymptomsGraph);