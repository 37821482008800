import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

// icn
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

import { getDataAnalysisProrityReportsV1 } from "store/services/clientDataReportsService";
import SelectNAQs from "../../SelectNAQs";
import PriorityReport from "./Components/PriorityReport";
import DownloadPriorityCompReportPDF from "components/shared/PDFComponents/NAQv1DataAnalysis/SeverityCompReportPDF";
import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";

const PriorityComparisionReport = ({ dispatch, naqList, client }) => {
  const { clientId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [priorityReport, setPriorityReport] = useState(null);
  const [selectedNAQs, setSelectedNAQs] = useState([]);
  const [selectedType, setSelectedType] = useState("questionnaireo");

  useEffect(() => {
    let todoIds = naqList && naqList.map(naq => naq.id);
    if (todoIds) {
      getNAQPriorityComparisionData(todoIds);
    }
  }, [naqList]);

  useEffect(() => {
    if (selectedNAQs && selectedNAQs.length > 1) {
      getNAQPriorityComparisionData(selectedNAQs);
    }
  }, [selectedNAQs])

  const getNAQPriorityComparisionData = async (todoIds) => {
    try {
      setLoading(true);
      let data = {
        clientId,
        values: {
          todoIds
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      const response = await getDataAnalysisProrityReportsV1(data);
      dispatch(actions.persist_store({ loader: false }));
      if (response.success === true) {
        setPriorityReport(response.body);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    }
  }

  const handleChangeSelectedNAQ = (selected) => {
    setSelectedNAQs(selected);
  }

  return (
    <>
      <div className="top d-flex align-items-start justify-content-between gap-10">
        <div className="left">
          <h6 className="m-0 fw-bold">Severity Comparison Report</h6>
          <p className="m-0">
            Compare Priority Reports from {FULL_NAME(client.first_name, client.last_name)}'s NAQs to see what's changed.
          </p>
        </div>
        <div className="right">
          {/* <Button
            // onClick={handleToDoPopup}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            <span className="icn me-2">
              <DownloadIcn />
            </span>
            Download Report
          </Button> */}
          <DownloadPriorityCompReportPDF
            client={client}
            naqList={naqList}
            todoIds={selectedNAQs}
            label={"Download Report"}
            type={selectedType}
          />
        </div>
      </div>
      <div className="CardBody p-3 bg-light my-2">
        <Row>
          <Col lg="8">
            <SelectNAQs naqList={naqList} onChange={handleChangeSelectedNAQ} />
          </Col>
          <Col lg="4">
            {/* <div className="">
              <p className="m-0 text-uppercase fw-bold">View Priority Report grouped by:</p>
              <Form.Select
                className="form-control fw-sbold btnSm"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                <option value="questionnaireo">Questionnaire Section</option>
                <option value="SBG">SBG Section</option>
              </Form.Select>
            </div> */}
          </Col>
        </Row>
        <PriorityReport
          priorityReport={priorityReport}
          naqList={naqList}
          selectedNAQs={selectedNAQs}
          selectedType={selectedType}
        />
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PriorityComparisionReport);