import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { deleteFormPage, savePagesOrder } from "store/services/myFormService";
import SingleQuestion from "./SingleQuestion";
import * as actions from "store/actions";
import { postFormOrders } from "store/services/clientService";

const ManagePageQuestions = ({ dispatch, pages, openPageEditPopup, openQuestionEditPopup, formDetails }) => {
  const [ pagesData, setPagesData ] = useState(pages);

  useEffect(() => {
    setPagesData(pages);
  }, [pages])

  const onDragEnd = (result) => {
    if(result.source.index === result.destination.index) {
      console.log("Source and destination were same");
      return;
    }

    if (!result.destination) return; // Not a valid drop target

    const items = [...pagesData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const pagesWithSorting = items.map((page, i) => {
      page.sorting = i+1;
      return page
    })
    // console.log("pagesWithSorting", pagesWithSorting);
    setPagesData(pagesWithSorting);
    saveFormPagesOrder(pagesWithSorting);
  };

  const saveFormPagesOrder = async (pages) => {
    try {
      let formId = formDetails.id;
      let data = {
        formId,
        pages
      }
      dispatch(actions.persist_store({ loader: true }));
      await savePagesOrder(data);
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error deleting page", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const deletePage = async (pageId) => {
    try {
      // setDeletingPage(pageId);
      let formId = formDetails.id;
      let data = {
        pageId: pageId,
        formId: formId,
      }
      await dispatch(deleteFormPage(data))
    } catch(err) {
      console.log("Error deleting page", err);
      // setDeletingPage(null);
    }
  }

  const postFormOrderingReq = async (data) =>{
      try{
        console.log("data form orders set",data)
         const res = await postFormOrders(data);
         console.log("appointment orders response",res);
      }catch(error){
        console.log("error while order setting",error);
      }
  }
  
  // Function to move a question up
  const moveQuestionUp = (recordIndex, questionIndex) => {
    try{
      const updatedRecords = [...pagesData];
    const record = updatedRecords[recordIndex];
    if (questionIndex > 0) {
      const temp = record.questions[questionIndex];
      record.questions[questionIndex] = record.questions[questionIndex - 1];
      record.questions[questionIndex - 1] = temp;
      setPagesData(updatedRecords);
    }
    const sorted = updatedRecords[recordIndex].questions.map((item,index)=> ({id:item.id,sorting: index + 1}));
    postFormOrderingReq({
      orders : sorted
    });

    }catch(error){
      console.log("error question move up",error)
    }
  };

  // Function to move a question down
  const moveQuestionDown = (recordIndex, questionIndex) => {
    try{
      const updatedRecords = [...pagesData];
      const record = updatedRecords[recordIndex];
      if (questionIndex < record.questions.length - 1) {
        const temp = record.questions[questionIndex];
        record.questions[questionIndex] = record.questions[questionIndex + 1];
        record.questions[questionIndex + 1] = temp;
        setPagesData(updatedRecords);
        const sorted = updatedRecords[recordIndex].questions.map((item,index)=> ({id:item.id,sorting: index + 1}));
        postFormOrderingReq({
          orders : sorted
        });
       
      }
    }catch(error){
      console.log("error question move up",error)
    }
  
  };

  if(!formDetails || !formDetails?.id) {
    return "";
  }

  return (
    <div className="FormQuestion py-3 border-bottom">
      <div className="commonContent py-2 d-flex align-items-center justify-content-between gap-10">
        <div className="left">
          <h3 className="m-0 py-2">Form Question</h3>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              className="ps-0 list-unstyled mb-0 py-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {pagesData.map((page, index) => (
                <Draggable
                  key={page.id}
                  draggableId={`pages-#${page.id}`}
                  index={index}
                >
                  {(provided) => (
                    <li
                      className="py-2"
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div className="commonCardBox bg-white">
                        <div className="px-3 top py-3 border-bottom d-flex align-items-center justify-content-between gap-10">
                          <div className="left">
                            <h6 className="m-0 py-2">
                              Page{index + 1}: {page.title}
                            </h6>
                          </div>
                          {page.id && (
                            <div className="right">
                              <Button
                                onClick={() => {
                                  openPageEditPopup(page.id);
                                }}
                                className="d-flex align-items-center justify-content-center btnSm commonBtn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M15.5325 5.27992C15.825 4.98742 15.825 4.49992 15.5325 4.22242L13.7775 2.46742C13.5 2.17492 13.0125 2.17492 12.72 2.46742L11.34 3.83992L14.1525 6.65242M2.25 12.9374V15.7499H5.0625L13.3575 7.44742L10.545 4.63492L2.25 12.9374Z"
                                    fill="white"
                                  />
                                </svg>
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="CardBody py-2 px-3">
                          {( page.questions && page.questions.length > 0 ) &&
                            page.questions.map((question,Qindex) => {
                              return (
                                <SingleQuestion
                                  key={question.id}
                                  question={question}
                                  questionsLength={page.questions.length}
                                  openQuestionEditPopup={openQuestionEditPopup}
                                  // deletingPage={deletingPage}
                                  QIndex={Qindex}
                                  handleMoveQuestions={
                                    (movement,QIndex)=>{
                                        if(movement === "up"){
                                           moveQuestionUp(index,QIndex);     
                                        }else{
                                          moveQuestionDown(index,QIndex);
                                        }
                                    }
                                  }
                                />
                              )
                            })}
                        </div>
                        <div className="CardFooter py-3 px-3 border-top">
                          <div className="FormbtnWrpper d-flex align-items-center justify-content-between gap-10">
                            <div className="left d-flex align-items-center gap-10">
                              <Button
                                onClick={() => {
                                  openQuestionEditPopup({pageId: page.id})
                                }}
                                variant="transparent"
                                className="d-flex border-0 p-0 themeLink align-items-center justify-content-center h-auto fw-sbold underline"
                              >
                                Insert New Question
                              </Button>
                            </div>
                            {/* <div className="right d-flex align-items-center gap-10">
                              <Button
                                onClick={() => {
                                  deletePage(page.id);
                                }}
                                className="d-flex btnSm align-items-center justify-content-center commonBtn"
                              >
                                { deletingPage == page.id ? "Deleting..." : "Delete" }
                              </Button>
                              <Button
                                onClick={() => {
                                  openQuestionEditPopup({pageId: page.id})
                                }}
                                className="d-flex btnSm align-items-center justify-content-center commonBtn"
                              >
                                Edit/ Update Question
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="FormbtnWrpper mt-3 justify-content-center d-flex">
        {(pagesData && pagesData.length > 0) ?
          <Button
            onClick={openPageEditPopup}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            Add New Page
          </Button>
          :
          <Button
            onClick={openQuestionEditPopup}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            Add New Question
          </Button>
        }
      </div>
    </div>
  )
}

const mapStateToPros = (state) => {
  return {}
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ManagePageQuestions);