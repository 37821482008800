import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Dropdown
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
// import LinePlot from "./Component/TestChart";
import { useParams } from "react-router-dom";
import AllQuestionAnswers from "./Components/AllQuestionAnswers/Index";
import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import AllQuestionAnswersPDF from "components/shared/PDFComponents/AllQuestionAnswers/Index";
import SendTodoToClient from "components/shared/SendTodoToClient";

const ToDoAllQuestionAnswers = ({ dispatch, client, todoId, todoDetails, formId, formName, submittedOn }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion.Item
      eventKey={todoId}
      className=" my-2"
      style={{
        border: "1px solid #2B3674",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#F9FAFF",
          // border: "1px solid #2B3674",
          color: "#2B3674",
        }}
      >
        <span className="toggleBtn position-absolute"></span>

        <div className="d-flex align-items-center gap-10">
          <span className="icn me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                fill="currentColor"
              />
              <path
                d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                fill="currentColor"
              />
              <path
                d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {formName}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {submittedOn && (
              <>
                {todoDetails?.owned_by !== client.id ? (
                  <span>{`Data entered by you on ${moment(submittedOn).format("MMM DD, YYYY")}`}</span>
                ) : (
                  <span>{`Submitted on ${moment(submittedOn).format("MMM DD, YYYY")}`}</span>
                )}
              </>
            )}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <div className="AccordionInner">
          <div className="py-2">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left d-flex align-items-center gap-10">

              </div>
              <div className="right d-flex gap-10">
                <SendTodoToClient
                  clientId={client.id}
                  todoId={todoId}
                  todoDetails={todoDetails}
                />
                <Dropdown className="download-pdf-dropdown">
                  <Dropdown.Toggle
                    className=" commonBtn btnSm text-uppercase fw-sbold border pe-5 position-relative"
                    id="dropdown-basic"
                  >
                    <span className="icn me-2">
                      <DownloadIcon />
                    </span>
                    Download Report
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    <Dropdown.Item as={"div"}>
                      <AllQuestionAnswersPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        formName={formName}
                        label="Report Without Notes"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <AllQuestionAnswersPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        formName={formName}
                        showNotes={true}
                        label="Report With Notes"
                      />
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="inner">
            <AllQuestionAnswers
              todoId={todoId}
              formId={formId}
              isOpen={isOpen}
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ToDoAllQuestionAnswers);