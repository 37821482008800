import React, { useEffect, useState } from "react";
import { UC_FIRST } from "helpers/common";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteFormQuestion } from "store/services/myFormService";
import { SUCCESS_MESSAGE } from "common/constantMessage";

const SingleQuestion = ({ dispatch, question, openQuestionEditPopup,handleMoveQuestions,QIndex,questionsLength }) => {
  const [isDeleing, setIsDeleting ] = useState(false);
  
  const deleteQuestion = async () => {
    try {
      setIsDeleting(true);

      const callback = (response) => {
        SUCCESS_MESSAGE("Question deleted successfully.");
      }

      let data = {
        questionId: question.id,
        values: {
          pageId: question.form_page_id,
          formId: question.form_id,
        }
      }
      await dispatch(deleteFormQuestion(data, callback))
    } catch(err) {
      console.log("Error deleting question", err);
      setIsDeleting(false);
    }
  }

  return (
    <div className="single-question-outer py-2">
      <ul className="list-unstyled ps-0 mb-0">
        <li className="py-2 d-flex align-items-start gap-10">
          <div className="left">
            <p className="m-0 text-dark fw-sbold">
              Question Text:
            </p>
          </div>
          <div className="right">
            <p className="m-0">
              {question.question}
            </p>
          </div>
        </li>
        {question.question_help && 
          <li className="py-2 d-flex align-items-start gap-10">
            <div className="left">
              <p className="m-0 text-dark fw-sbold">
                Help Text:
              </p>
            </div>
            <div className="right">
              <p className="m-0">
                {question.question_help}
              </p>
            </div>
          </li>
        }
        <li className="py-2 d-flex align-items-start gap-10">
          <div className="left">
            <p className="m-0 text-dark fw-sbold">
              Answer Required:
            </p>
          </div>
          <div className="right">
            <p className="m-0">{UC_FIRST(question.is_required)}</p>
          </div>
        </li>
        <li className="py-2 d-flex align-items-start gap-10">
          <div className="left">
            <p className="m-0 text-dark fw-sbold">
              Answer type:
            </p>
          </div>
          <div className="right">
            <p className="m-0">
              {question.question_type_label}
            </p>
          </div>
        </li>
      </ul>
    <div className="FormbtnWrpper d-flex align-items-center justify-content-between gap-10">
      <div className="left d-flex align-items-center gap-10">
        {/* <Button
          onClick={() => {
            openQuestionEditPopup({pageId: page.id})
          }}
          variant="transparent"
          className="d-flex border-0 p-0 themeLink align-items-center justify-content-center h-auto fw-sbold underline"
        >
          Insert New Question
        </Button> */}
      </div>
      <div className="right d-flex align-items-center gap-10">
      <Button 
          className="commonBtn btnSm" 
          onClick={() => handleMoveQuestions("up",QIndex)} 
          disabled={QIndex === 0}>
          Move 
          <span className="icn ms-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="#888888" d="M104.704 685.248a64 64 0 0 0 90.496 0l316.8-316.8l316.8 316.8a64 64 0 0 0 90.496-90.496L557.248 232.704a64 64 0 0 0-90.496 0L104.704 594.752a64 64 0 0 0 0 90.496"/></svg>
          </span>
        </Button>
        <Button 
          className="commonBtn btnSm" 
          onClick={() => handleMoveQuestions("down",QIndex)}
          disabled={QIndex === questionsLength - 1}>
            Move 
            <span className="icn ms-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="#888888" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8l316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496"/></svg>
            </span>
        </Button>
        <Button
          onClick={deleteQuestion}
          className="d-flex btnSm align-items-center justify-content-center commonBtn"
          disabled={isDeleing}
        >
          { isDeleing ? "Deleting..." : "Delete" }
        </Button>
        <Button
          onClick={() => {
            openQuestionEditPopup({
              pageId: question.form_page_id,
              editQuestionId: question.id,
            })
          }}
          className="d-flex btnSm align-items-center justify-content-center commonBtn"
        >
          Edit/ Update Question
        </Button>
      </div>
    </div>
    </div>
  )
}

const mapStateToPros = (state) => {
  return {}
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleQuestion);