import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
// import { BarChart } from "./Components/BarChart";

// icn
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";
import { getChartHeightByScreenSize, getScreenSize } from "components/shared/ChartConfig";
// import DownloadComparisionGraph from "./Components/ComparisionGraphPDF";
import SelectForms from "./Components/SelectForms";
import { getWellnessAssessCompData, getWellnessAssessCompletedList } from "store/services/clientDataReportsService";
import { BarChart } from "./Components/BarChart";
import WellnessCompGraphPDF from "./Components/WellnessCompGraphPDF";

const Naqv1SymptomGraph = ({ dispatch, client }) => {
  const { clientId } = useParams();
  const [chartData, setChartData] = useState(null);
  const [formList, setFormList] = useState(null);
  const [chartHeight, setChartHeight] = useState(550);
  const [screenSize, setScreenSize] = useState("extra-large");
  const [selectedForms, setSelectedForms] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);

  console.log("formList", formList, errorMsg)

  const barChartRef = useRef(null);
  const lineChartRef = useRef(null);

  useEffect(() => {
    if(selectedForms && selectedForms.length > 1) {
      getWellnessComparisonGraphData(selectedForms);
    }
  }, [selectedForms])

  useEffect(() => {
    let todoIds = formList && formList.slice(0, 2).map(form => form.id);
    if(todoIds) {
      setSelectedForms(todoIds);
    }
  }, [formList]);

  useEffect(() => {
    let size = getScreenSize(screenSize);
    setScreenSize(size);
  }, []);

  useEffect(() => {
    let height = getChartHeightByScreenSize(screenSize, chartHeight);
    setChartHeight(height);
  }, [screenSize]);

  useEffect(() => {
    getCompletedFormsList();
  }, [])

  const getCompletedFormsList = async () => {
    try {
      let data = {
        clientId,
      } 
      dispatch(actions.persist_store({ loader: true }));
      const response = await getWellnessAssessCompletedList(data);
      dispatch(actions.persist_store({ loader: false }));
      if( response.success === true && response.body.length > 0) {
        setFormList(response.body);
      } else if(response.message) {
        setErrorMsg(response.message);
      }
    } catch(error) {
      console.log("Error getting forms list", error);
      setErrorMsg("Insufficient submissions. Compare feature requires two or more submissions for analysis.")
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const getWellnessComparisonGraphData = async (todoIds) => {
    try {
      let data = {
        clientId,
        values: {
          todoIds
        }
      } 
      dispatch(actions.persist_store({ loader: true }));
      const response = await getWellnessAssessCompData(data);
      dispatch(actions.persist_store({ loader: false }));
      if( response.success === true && response.body.length > 0) {
        setChartData(response.body);
      }
    } catch(error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const getFormColor = (id) => {
    let form = formList.find(form => form.id === id);
    return form && form.color;
  }

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }
  
  return (
    <>
      {!formList && errorMsg ?
        <p className="m-0">
          {errorMsg}
        </p>
        :
        <>
          <div className="top d-flex align-items-start justify-content-between gap-10">
            <div className="left">
              <h6 className="m-0 fw-bold">Wellness Wheel Comparison Graphs</h6>
              <p className="m-0">
                Compare Wellness Wheel Comparison Graphs from {FULL_NAME(client.first_name, client.last_name)}'s submitted Health and Wellness Wheel Assessments.
              </p>
            </div>
            <div className="right">
              <WellnessCompGraphPDF
                client={client}
                selectedForms={formList && formList.filter(form => selectedForms.includes(form.id))}
                chartRef={barChartRef}
                showLoader={showLoader}
                hideLoader={hideLoader}
              />
            </div>
          </div>
          <div className="CardBody p-3 bg-light my-2">
            <Row>
              <Col lg="12">
                <SelectForms
                  formList={formList}
                  selectedForms={selectedForms}
                  onChange={setSelectedForms}
                />
              </Col>
              {/* <Col lg="2">
                <Form.Label>
                  Chart type:
                </Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setSelectedChartType(e.target.value);
                  }}
                  value={selectedChartType}
                >
                  <option value="bar">Bar Chart</option>
                  <option value="line">Line Chart</option>
                </Form.Select>
              </Col> */}
            </Row>
            <div className="GraphWrpper my-2 py-2">
              <div className="wellness-chart-container">
                {chartData &&
                  <BarChart
                    innerRef={barChartRef}
                    chartData={chartData}
                    height={chartHeight}
                    screenSize={screenSize}
                    selectedForms={selectedForms}
                    getColor={getFormColor}
                  />
                }
              </div>
            </div>
            <p className="m-0 py-2 text-dark">
              *Looking for all the Health and Wellness Wheel Assessment questions/answers your client submitted?
              All of the Health and Wellness Wheel Assessment data is under the{" "}
              <Link to="" className="underline">
                Data & To-Do Items
              </Link>{" "}
              tab.
            </p>
          </div>
        </>
      }
      
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Naqv1SymptomGraph);