import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import { FULL_NAME, UC_FIRST } from "helpers/common";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import parse from 'html-react-parser';
import { createRecommendationTemplate, duplicateRecommendation, getClientList, getClientRecommendationList, updateRecommendation } from "store/services/clientService";
import * as actions from "store/actions";

// icons
import { ReactComponent as CopyIcn } from "Assets/Images/icn/Copy.svg";
import { ReactComponent as EditIcon } from "Assets/Images/icn/pencil.svg";
import { cancelFullscriptRecommendation, getFullscriptClientRecommendations } from "store/services/fullscriptService";
import { CONFIRM_POPUP } from "common/constantMessage";

const SingleFullscriptRecommendation = ({
  dispatch,
  fullscriptConnection,
  recommendation,
  getDetails,
  getRecommendations,
}) => {
  const [recommendationDetails, setRecommendationDetails] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      setLinkCopied(false);
    }
  }, []);

  // useEffect(() => {
  //   if (recommendationDetails) {
  //   }
  // }, [recommendationDetails])

  useEffect(() => {
    if (isOpen) {
      fetchRecommendationDetails();
    }
  }, [isOpen])

  const fetchRecommendationDetails = async () => {
    try {
      if (!recommendation.id) {
        throw new Error("Invalid recommendation ID");
      }
      setLoading(true);
      let response = await getDetails(recommendation.id);
      if (response.success === true && response.body) {
        setRecommendationDetails(response.body);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error occurred while fetching recommendation details", err);
      setLoading(false);
    }
  }

  const cancelRecommendation = async (id) => {
    try {
      let data = {
        id,
      }
      await CONFIRM_POPUP("Are you sure you want to cancel this recommendation?")
        .then(async (isConfirmed) => {
          if(isConfirmed) {
            dispatch(actions.persist_store({ loader:true}));
            let res = await cancelFullscriptRecommendation(data);
            if(res.success === true) {
              setRecommendationDetails(null);
              await getRecommendations();
            }
            dispatch(actions.persist_store({ loader:false}));
          }
        }).catch(err => {
          dispatch(actions.persist_store({ loader:false}));
          throw err
        })
      
      return res;
    } catch(error) {
      console.log("Error cancelling fullscript recommendation", error);
    }
  }

  if(!recommendation) {
    return "";
  }

  return (
    <>
      <Accordion.Item eventKey={recommendation.id} className="my-2">
        <Accordion.Header
          className=""
          style={{
            background: "#FFFDFA",
            border: "1px solid #405C40",
            color: "#405C40",
          }}
          onClick={() => {
            setOpen(true)
          }}
        >
          <span className="toggleBtn position-absolute"></span>
          <div className="d-flex align-items-center gap-10">
            <span className="icn me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                  fill="currentColor"
                />
                <path
                  d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                  fill="currentColor"
                />
                <path
                  d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Fullscript Recommendation ({UC_FIRST(recommendation.state)})
            {recommendation.for_client === "publish" && ` (Published)`}
          </div>
          <div className="right d-flex align-items-center gap-10">
            <p className="m-0 text-dark text-lowercase fw-sbold">
              {`from ${moment(recommendation.created_at).format("MMM DD, YYYY")}`}
            </p>
            {/* <span className="icn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_0_3353)">
                              <path
                                d="M14.5024 2.16927C16.2504 2.16927 17.6719 3.58952 17.6719 5.33594V14.8359C17.6719 16.5824 16.2504 18.0026 14.5024 18.0026H4.99379C3.2458 18.0026 1.82427 16.5824 1.82427 14.8359V5.33594C1.82427 3.58952 3.2458 2.16927 4.99379 2.16927H14.5024ZM14.5024 0.585938H4.99379C3.73288 0.585938 2.52361 1.08638 1.632 1.97718C0.740399 2.86798 0.239502 4.07616 0.239502 5.33594L0.239502 14.8359C0.239502 16.0957 0.740399 17.3039 1.632 18.1947C2.52361 19.0855 3.73288 19.5859 4.99379 19.5859H14.5024C15.7633 19.5859 16.9726 19.0855 17.8642 18.1947C18.7558 17.3039 19.2567 16.0957 19.2567 14.8359V5.33594C19.2567 4.07616 18.7558 2.86798 17.8642 1.97718C16.9726 1.08638 15.7633 0.585938 14.5024 0.585938Z"
                                fill="black"
                              />
                              <path
                                d="M4.99377 10.0846C4.99377 9.87467 5.07726 9.67331 5.22586 9.52484C5.37446 9.37638 5.576 9.29297 5.78616 9.29297H13.71C13.9201 9.29297 14.1217 9.37638 14.2703 9.52484C14.4189 9.67331 14.5024 9.87467 14.5024 10.0846C14.5024 10.2946 14.4189 10.496 14.2703 10.6444C14.1217 10.7929 13.9201 10.8763 13.71 10.8763H5.78616C5.576 10.8763 5.37446 10.7929 5.22586 10.6444C5.07726 10.496 4.99377 10.2946 4.99377 10.0846Z"
                                fill="black"
                              />
                              <path
                                d="M9.74821 14.8398C9.53825 14.8398 9.33688 14.7564 9.18842 14.6078C9.03995 14.4592 8.95654 14.2576 8.95654 14.0475L8.95654 6.12364C8.95654 5.91349 9.03995 5.71194 9.18842 5.56334C9.33688 5.41474 9.53825 5.33126 9.74821 5.33126C9.95817 5.33126 10.1595 5.41474 10.308 5.56334C10.4565 5.71194 10.5399 5.91349 10.5399 6.12364L10.5399 14.0475C10.5399 14.2576 10.4565 14.4592 10.308 14.6078C10.1595 14.7564 9.95817 14.8398 9.74821 14.8398Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_0_3353">
                                <rect
                                  width="19.0172"
                                  height="19"
                                  fill="white"
                                  transform="translate(0.239502 0.585938)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span> */}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {isLoading ?
            <p>Loading...</p>
            :
            <>
            { recommendationDetails &&
              <div className="py-2 AccordionInner">
                <div className="inner">
                  
                  <div className="py-2">
                    <h6>
                      Invitation URL
                    </h6>
                    <p>
                      {recommendationDetails.invitation_url}{" "}
                      <Link
                        onClick={() => {
                          try {
                            navigator.clipboard.writeText(recommendationDetails.invitation_url)
                            setLinkCopied(true);
                            timeoutId = setTimeout(() => {
                              setLinkCopied(false);
                            }, 3000)
                          } catch (err) {
                            console.log("Failed to copy to the clipboard");
                          }
                        }}
                      >
                        {linkCopied ? "Copied!" : "Copy Link"}
                      </Link>
                    </p>
                  </div>
                  <hr></hr>
                  <div className="py-2">
                    <h6>
                      Message
                    </h6>
                    <p>
                      { recommendationDetails.personal_message ?
                        parse(recommendationDetails.personal_message)
                        :
                        "No message for this recommendation."
                      }
                    </p>
                  </div>
                  <hr></hr>
                  <div className="py-2">
                    <h6>
                      Products
                    </h6>
                    {recommendationDetails.recommendations && recommendationDetails.recommendations.length > 0 ? 
                      <ul style={{listStyleType: "none"}}>
                        {recommendationDetails.recommendations.map(recommendation => {
                          return (
                            <li className="py-2">
                              {recommendation.productDetails && 
                                <Row className="align-items-center">
                                  <Col lg="2">
                                    <img className="w-100" src={recommendation.productDetails.image_url_small} />
                                  </Col>
                                  <Col lg="6">
                                    <h6>
                                      {`${recommendation.productDetails.product.name} by ${recommendation.productDetails.product.brand.name} ${recommendation.units_to_purchase}x`}
                                    </h6>
                                    <p>
                                      {`${recommendation.dosage.amount} ${recommendation.dosage.format} ${recommendation.dosage.frequency}`}
                                    </p>
                                  </Col>
                                </Row>
                              }
                            </li>
                          )
                        })}
                      </ul>
                      :
                      <p>
                        No products for this recommendation.
                      </p>
                    }
                  </div>
                  <div className="d-flex align-items-start justify-content-between gap-10">
                    <div className="right">
                      <div className="d-flex gap-10">
                        <Link
                          className="d-flex align-items-center justify-content-center commonBtn btnSm btn text-white"
                          to={`https://api-${fullscriptConnection.connection_response.country}-snd.fullscript.io/o/plans/${recommendation.id}/edit`}
                          target="_blank"
                        >
                          <span className="icn me-2">
                            <EditIcon />
                          </span>
                          Edit/Update Recommendation
                        </Link>
                        {recommendationDetails.state == "active" &&
                          <Button
                            className="d-flex align-items-center justify-content-center btnSm btn-danger"
                            onClick={() => {
                              cancelRecommendation(recommendation.id)
                            }}
                          >
                            Cancel Recommendation
                          </Button>
                        }
                        {/* <Button
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        >
                          <span className="icn me-2">
                            <CopyIcn />
                          </span>
                          Duplicate
                        </Button> */}
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                </div>
              </div>
            }
          </>
          }

        </Accordion.Body>
      </Accordion.Item>
    </>
  )
}

const TableRows = ({ rows }) => {
  return (
    <>
      {rows && rows.length > 0 &&
        rows.map((row, index) => {
          return (
            <tr key={index}>
              <td className="p-3 border-0">
                <p className="m-0">{row.recommendation}</p>
              </td>
              <td className="p-3 border-0 ws-normal">
                <p className="m-0">
                  {row.benefit}
                </p>
              </td>
              <td className="p-3 border-0">{row.per_day}</td>
              <td className="p-3 border-0">{row.upon_waking}</td>
              <td className="p-3 border-0">{row.with_breakfast}</td>
              <td className="p-3 border-0">{row.midmorning}</td>
              <td className="p-3 border-0">{row.with_lunch}</td>
              <td className="p-3 border-0">{row.midafternoon}</td>
              <td className="p-3 border-0">{row.with_dinner}</td>
              <td className="p-3 border-0">{row.before_bed}</td>
            </tr>
          )
        })
      }
    </>
  )
}

const mapStateToPros = (state) => {
  let fullscriptConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "full_script");
  return {
    fullscriptConnection
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleFullscriptRecommendation);