//action types
export const ACCOUNT_SETTING_LIST = 'ACCOUNT_SETTING_LIST';
export const GET_DEFAULT_FORM_LIST = 'GET_DEFAULT_FORM_LIST';
export const ADD_UPDATE_ACCOUNT_SETTING = 'ADD_UPDATE_ACCOUNT_SETTING';
export const GET_SETTING_DETAIL = 'GET_SETTING_DETAIL';
export const GET_ACCOUNT_SETTING_DETAIL = 'GET_ACCOUNT_SETTING_DETAIL';
export const GET_SELF_REGISTERATION = 'GET_SELF_REGISTERATION';
export const ADD_SELF_REGISTERATION = 'ADD_SELF_REGISTERATION';
export const GET_THIRD_PARTY_INTEGRATION_STATUS = 'GET_THIRD_PARTY_INTEGRATION_STATUS';
export const CONNECT_STRIPE_ACCOUNT = 'CONNECT_STRIPE_ACCOUNT';
export const DISCONNECT_STRIPE_ACCOUNT = 'DISCONNECT_STRIPE_ACCOUNT';

//get 
export function getAccountSettingList(data, callback) {
    return {
      type: ACCOUNT_SETTING_LIST,
      payload : {data, callback},
    };
}
//default form list
export function getDefaultFormList(data, callback) {
    return {
      type: GET_DEFAULT_FORM_LIST,
      payload : {data, callback},
    };
}

//add setting
export function addUpdateAccountSetting(data, callback) {
    return {
      type: ADD_UPDATE_ACCOUNT_SETTING,
      payload : {data, callback},
    };
}

//get detail setting
export function getSettingDetail(data, callback) {
  return {
    type: GET_SETTING_DETAIL,
    payload : {data, callback},
  };
}
//get accoun detail with single detail
export function getAccountSettingDetail(data, callback) {
  return {
    type: GET_ACCOUNT_SETTING_DETAIL,
    payload : {data, callback},
  };
}

//self registeration
export function getSelfRegisteration(data, callback) {
  return {
    type: GET_SELF_REGISTERATION,
    payload : {data, callback},
  };
}
//add self registeration
export function addSelfRegisteration(data, callback) {
  return {
    type: ADD_SELF_REGISTERATION,
    payload : {data, callback},
  };
}

// get third party integration status
export function getThirdPartyIntegrationStatus(data, callback) {
  return {
    type: GET_THIRD_PARTY_INTEGRATION_STATUS,
    payload : {data, callback},
  };
}

// unlink stripe account
export function unlinkStripe(data, callback) {
  return {
    type: DISCONNECT_STRIPE_ACCOUNT,
    payload : {data, callback},
  };
}