import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Dropdown
} from "react-bootstrap";
import { connect } from "react-redux";
import { decode } from "html-entities";
import moment from "moment";
import AccordionContext from 'react-bootstrap/AccordionContext';
import { getTodoResponse } from "store/services/clientService";
import SymptomBurdonReport from "./Component/SymptomBurdonReport/Index";
import RecommendationReport from "./Component/RecommendationReport/Index";
import AllQuestionAnswers from "./Component/AllQuestionAnswers";
import PriorityReport from "./Component/PriorityReport/Index";
import CustomBarChart from "./Component/CustomChart";
import SymptomGraph from "./Component/SymptomGraph";
// import LinePlot from "./Component/TestChart";
import { getBodySymptomBurdenGraph, getBodySymptomBurdenGraphV2, getFoundationSymptomBurdenGraph } from "store/services/clientDataReportsService";
import { useParams } from "react-router-dom";
import * as actions from "store/actions"
import FoundationSymptomGraph from "./Component/FoundationSymptomGraph/Index";
import BodySymptomGraph from "./Component/BodySymptomGraph/Index";
import SendTodoToClient from "components/shared/SendTodoToClient";

import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import DownloadFSBurdenGraph from "./Component/PDFReportComponents/FoundationSymptomGraphPdf";
import DownloadBSBurdenGraph from "./Component/PDFReportComponents/BodySymptomGraphPdf";
import DownloadSBReportLink from "./Component/PDFReportComponents/SBReportPDF";
import DownloadToDoNAQRecommPDF from "./Component/PDFReportComponents/ToDoNAQRecommReportPDF";
import DownloadPriorityReportPDF from "./Component/PDFReportComponents/PriorityReportPDF";
import DownloadPriorityReportSBGPDF from "./Component/PDFReportComponents/PriorityReportSBGPDF";
import AllQuestionAnswersPDF from "./Component/PDFReportComponents/AllQuestionAnswersPDF";

const NutritionalAssessmentV2 = ({ dispatch, client, todoId, todoDetails, formId, formName, submittedOn }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Accordion.Item
      eventKey={todoId}
      className=" my-2"
      style={{
        border: "1px solid #2B3674",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#F9FAFF",
          // border: "1px solid #2B3674",
          color: "#2B3674",
        }}
      >
        <span className="toggleBtn position-absolute"></span>

        <div className="d-flex align-items-center gap-10">
          <span className="icn me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                fill="currentColor"
              />
              <path
                d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                fill="currentColor"
              />
              <path
                d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {formName}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {submittedOn && (
              <>
                {todoDetails?.owned_by !== client.id ? (
                  <span>{`Data entered by you on ${moment(submittedOn).format("MMM DD, YYYY")}`}</span>
                ):(
                  <span>{`Submitted on ${moment(submittedOn).format("MMM DD, YYYY")}`}</span>
                )}
              </>
            )}
          </p>
          {/* <span className="icn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_0_3353)">
                      <path
                        d="M14.5024 2.16927C16.2504 2.16927 17.6719 3.58952 17.6719 5.33594V14.8359C17.6719 16.5824 16.2504 18.0026 14.5024 18.0026H4.99379C3.2458 18.0026 1.82427 16.5824 1.82427 14.8359V5.33594C1.82427 3.58952 3.2458 2.16927 4.99379 2.16927H14.5024ZM14.5024 0.585938H4.99379C3.73288 0.585938 2.52361 1.08638 1.632 1.97718C0.740399 2.86798 0.239502 4.07616 0.239502 5.33594L0.239502 14.8359C0.239502 16.0957 0.740399 17.3039 1.632 18.1947C2.52361 19.0855 3.73288 19.5859 4.99379 19.5859H14.5024C15.7633 19.5859 16.9726 19.0855 17.8642 18.1947C18.7558 17.3039 19.2567 16.0957 19.2567 14.8359V5.33594C19.2567 4.07616 18.7558 2.86798 17.8642 1.97718C16.9726 1.08638 15.7633 0.585938 14.5024 0.585938Z"
                        fill="black"
                      />
                      <path
                        d="M4.99377 10.0846C4.99377 9.87467 5.07726 9.67331 5.22586 9.52484C5.37446 9.37638 5.576 9.29297 5.78616 9.29297H13.71C13.9201 9.29297 14.1217 9.37638 14.2703 9.52484C14.4189 9.67331 14.5024 9.87467 14.5024 10.0846C14.5024 10.2946 14.4189 10.496 14.2703 10.6444C14.1217 10.7929 13.9201 10.8763 13.71 10.8763H5.78616C5.576 10.8763 5.37446 10.7929 5.22586 10.6444C5.07726 10.496 4.99377 10.2946 4.99377 10.0846Z"
                        fill="black"
                      />
                      <path
                        d="M9.74821 14.8398C9.53825 14.8398 9.33688 14.7564 9.18842 14.6078C9.03995 14.4592 8.95654 14.2576 8.95654 14.0475L8.95654 6.12364C8.95654 5.91349 9.03995 5.71194 9.18842 5.56334C9.33688 5.41474 9.53825 5.33126 9.74821 5.33126C9.95817 5.33126 10.1595 5.41474 10.308 5.56334C10.4565 5.71194 10.5399 5.91349 10.5399 6.12364L10.5399 14.0475C10.5399 14.2576 10.4565 14.4592 10.308 14.6078C10.1595 14.7564 9.95817 14.8398 9.74821 14.8398Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_0_3353">
                        <rect
                          width="19.0172"
                          height="19"
                          fill="white"
                          transform="translate(0.239502 0.585938)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span> */}
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <div className="AccordionInner naqv2-reports">
        <div className="top d-flex align-items-start justify-content-between gap-10">
            <div className="left">
            </div>
            <div className="right">
              <div className="d-flex gap-10">
                <SendTodoToClient
                  clientId={client.id}
                  todoId={todoId}
                  todoDetails={todoDetails}
                />
                <Dropdown className="download-pdf-dropdown">
                  <Dropdown.Toggle
                    className=" commonBtn btnSm text-uppercase fw-sbold border pe-5 position-relative"
                    id="dropdown-basic"
                  >
                    <span className="icn me-2">
                      <DownloadIcon />
                    </span>
                    Download Report
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    <Dropdown.Item as={"div"}>
                      <DownloadFSBurdenGraph
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadBSBurdenGraph
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadSBReportLink
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        label="Symptom Burden Report"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadToDoNAQRecommPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        manufacturer={"biotics"}
                        label="Client Recommendation Report"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadPriorityReportPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        type={"questionnaireo"}
                        label="Priority Report"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadPriorityReportPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showNotes={true}
                        type={"questionnaireo"}
                        label="Priority Report w/ Notes"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadPriorityReportSBGPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        type={"SBG"}
                        label="Priority Report: SBG"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <DownloadPriorityReportSBGPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                        showNotes={true}
                        type={"SBG"}
                        label="Priority Report: SBG w/ Notes"
                      />
                    </Dropdown.Item>

                    <Dropdown.Item as={"div"}>
                      <AllQuestionAnswersPDF
                        date={submittedOn}
                        client={client}
                        todoId={todoId}
                      />
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="inner">
              {/* <SymptomGraph dataset={foundationSymptomBurdenGraphData} title="Foundations Symptom Burden Graph"/>
              <SymptomGraph dataset={bodySymptomBurdenGraphData} title="Body Systems Symptom Burden Graph"/> */}
              
            <Accordion alwaysOpen>
              <FoundationSymptomGraph
                eventKey="0"
                todoId={todoId}
                client={client}
                submittedOn={submittedOn}
              />
              <BodySymptomGraph
                eventKey="1"
                todoId={todoId}
                client={client}
                submittedOn={submittedOn}
              />
              <SymptomBurdonReport
                eventKey="2"
                todoId={todoId}
                client={client}
                submittedOn={submittedOn}
              />
              <RecommendationReport
                eventKey="3"
                todoId={todoId}
                client={client}
                submittedOn={submittedOn}
              />
              <PriorityReport
                eventKey="4"
                todoId={todoId}
                client={client}
                submittedOn={submittedOn}
              />
              <AllQuestionAnswers
                eventKey="5"
                todoId={todoId}
                formId={formId}
                client={client}
                submittedOn={submittedOn}
              />
            </Accordion>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NutritionalAssessmentV2);