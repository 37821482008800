import React, { useState } from "react";

const CustomBarChart = ({ innerRef, dataset, title, totalsTitle, defaultShowMaxes, isPdf = false }) => {
  const [showPriorityMaxes, setShowPriorityMaxes] = useState(!!defaultShowMaxes);

  const toggleShowPriorityMaxes = (e) => {
    setShowPriorityMaxes(e.target.checked);
  };

  const bars = (dataset) => {
    let count = dataset.length;
    let bars = dataset.map((row, i) => {
      return (
        <>
          <Bar data={row} showPriorityMaxes={showPriorityMaxes} isPdf={isPdf} />
        </>
      )
    })

    return bars;
  }

  if (!dataset || !dataset.length > 0) {
    return "Data not found";
  }

  return (
    <>
      <div className="naq2-barchart cstmBarChart single position-relative mt-4">
        <h6>{title}</h6>
        <div ref={innerRef} className="table-responsive">
          <table style={{ width: '100%', borderCollapse: 'collapse' }} className="table">
            <thead>
              <tr>
                <th width="22%" style={styles.tableHeading}></th>
                <th width="22%" style={{ ...styles.tableHeading, textAlign: "center" }}>
                  <span>
                    Low Priority
                  </span>
                </th>
                <th width="22%" style={{ ...styles.tableHeading, textAlign: "center" }}>
                  <span>
                    Medium Priority
                  </span>
                </th>
                <th width="22%" style={{ ...styles.tableHeading, textAlign: "center" }}>
                  <span>
                    High Priority
                  </span>
                </th>
                <th width="12%">
                </th>
              </tr>
            </thead>
            <tbody>
              {bars(dataset)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="py-2">
        <input
          name="show_priority_maxes"
          type="checkbox"
          onChange={toggleShowPriorityMaxes}
          checked={showPriorityMaxes}
        />
        <label className="px-2">Show priority level maxes on chart</label>
      </div>
      <hr></hr>
      <div className="py-2">
        <h6>{totalsTitle}</h6>
        <ul className="symptom-totals" style={{ listStyleType: "none" }}>
          {dataset &&
            dataset.length > 0 &&
            dataset.map((row) => {
              return (
                <li className={`${row.isChild === 1 ? "child-row" : ""}`}>
                  <strong>
                    {row.isChild === 1 ? "◦" : "•"}{" "}
                    {`${row.abbr}: ${row.rawTotal}`}
                  </strong>
                  {` chart max of ${row.chart_max}`}
                </li>
              );
            })}
        </ul>
      </div>
      <hr></hr>
    </>
  );
}

const Bar = ({ data, showPriorityMaxes, isPdf }) => {
  const isChild = data?.isChild;

  let bars = {
    low: {
      max: data.priorityMaxes?.low_max,
      width: 0,
    },
    med: {
      max: data.priorityMaxes?.med_max,
      width: 0,
    },
    high: {
      max: data.priorityMaxes?.high_max,
      width: 0,
    },
  };

  if (data.rawTotal <= bars.low.max) {
    bars.low.width = Math.min((data.rawTotal * 100) / bars.low.max, 100);
  } else if (data.rawTotal > bars.low.max && data.rawTotal <= bars.med.max) {
    bars.low.width = 100;
    bars.med.width =
      Math.min(((data.rawTotal - bars.low.max) * 100) / (bars.med.max - bars.low.max), 100);
  } else if (data.rawTotal > bars.med.max) {
    bars.low.width = 100;
    bars.med.width = 100;
    bars.high.width =
      Math.min(((data.rawTotal - bars.med.max) * 100) / (bars.high.max - bars.med.max), 100);
  }

  // console.log("data.rawTotal", data.rawTotal, bars.high.max, data.abbr);
  let showDangerSign = false;
  if (data.rawTotal > bars.high.max) {
    showDangerSign = true;
  }

  return (
    <tr style={styles.tableRow}>
      <td style={styles.tableColumn}>
        <span style={{ ...styles.abbrHeading }}>
          <span style={{ padding: `8px 0px`, fontSize: isChild ? "12px" : "14px" }}>{`${data.abbr} ${data.rawTotal}`}</span>
        </span>
      </td>
      {["low", "med", "high"].map((level, index) => {
        if (!isPdf) {
          return (
            <td key={index} style={styles.tableColumn} className="position-relative">
              {bars[level]?.width != 0 && (
                <span
                  // className="position-relative"
                  style={{
                    ...styles.barOuter,
                    "width": `${bars[level]?.width}%`,
                    backgroundColor: data.color,
                    padding: `${isChild ? "4px" : "8px"} 5px`,
                    height: `${isChild ? "25px" : "30px"}`,
                  }}
                >
                </span>
              )}
              <span
                className="position-absolute"
                style={{
                  ...styles.priorityMaxSpan,
                  opacity: showPriorityMaxes ? "1" : "0",
                  bottom: `${isChild ? "7px" : "10px"}`
                }}
              >
                {data.priorityMaxes?.[`${level}_max`]}
              </span>
            </td>
          )
        }

        const svgWidth = 180;
        const negativeMargin = parseFloat(((svgWidth * 10) / 100).toFixed(2));
        const barWidth = (svgWidth * bars[level]?.width) / 100;
        return (
          <td
            key={index}
            style={{ ...styles.tableColumn, padding: 0}}
          >
            <svg
              viewBox={`0 0 ${svgWidth} 20`}
              width={svgWidth} // Explicit numerical width
              height="40"
            >
              <rect
                x="0"
                y="0"
                width={isPdf ? barWidth - negativeMargin : barWidth}
                height={isChild ? 25 : 30}
                fill={data.color}
              />
              <text
                x="155"
                y={isChild ? 15 : 20}
                fill="#000"
                fontSize={`${isChild ? "10" : "12"}`}
                textAnchor="end"
                opacity={showPriorityMaxes ? 1 : 0}
              >
                {data.priorityMaxes?.[`${level}_max`] || 0}
              </text>
            </svg>
          </td>
        )
      })}
      <td style={{ ...styles.tableColumn, borderRight: 0 }}>

        {showDangerSign && (
          <span
            style={{ ...styles.barOuter, textAlign: "left" }}
          >
            <span style={styles.dangerSign}>!</span>
          </span>
        )}
      </td>
    </tr>
  );
};

const styles = {
  table: {
    borderSpacing: "0px",
    width: "100%",
    // display: "table",
    // tableLayout: "fixed",
    // border: "1px solid #000",
  },
  tableRow: {
    // maxHeight: "40px"
  },
  abbrHeading: {
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    color: "#1a202c",
    textAlign: "right",
    paddingRight: "10px",
    width: "100%",
    display: "block",
  },
  tableHeading: {
    // width: "24%",
    borderRight: "1px solid #dee2e6",
    borderBottom: "1px solid #dee2e6",
    padding: "5px 0",
    margin: 0,
    color: "#666",
  },
  tableColumn: {
    // width: "24%",
    padding: "5px 0",
    borderRight: "1px solid #dee2e6",
    borderBottom: 0,
    margin: 0,
  },
  barOuter: {
    zIndex: "9",
    textAlign: "right",
    width: "100%",
    margin: 0,
    padding: "8px 5px",
    width: "100%",
    display: "block",
    height: "30px",
  },
  priorityMaxSpan: {
    zIndex: "2",
    fontWeight: 700,
    color: "#1a202c",
    right: 5,
    bottom: "7px",
  },
  dangerSign: {
    color: "#ee5d50",
    fontWeight: 700,
    zIndex: "3",
    paddingLeft: "5px"
  }
}

export default CustomBarChart;
