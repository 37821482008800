import { Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { FULL_NAME } from 'helpers/common';
import moment from 'moment';
import { PDFDisclaimer } from './PDFDisclaimer';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "5px",
    borderBottom: "2px solid #bbb",
  },
  clientName: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "60px",
  }
});

export const ConverPDFDoc = ({ client }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text style={{fontSize: "24px", fontWeight: "bold", fontFamily: "Helvetica-Bold"}}>
            {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>

        {/* Disclaimer */}
        <PDFDisclaimer />
      </Page>
    </Document>
  )
}
