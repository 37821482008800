import { decode } from "html-entities";

const TextArea = ({ question }) => {
  return (
    <>
      <textarea
        className="border rounded w-100"
        rows={4}
        disabled
      ></textarea>
      {question?.question_help && (
        <p>
          {decode(question?.question_help)}
        </p>
      )}
    </>
  );
};

export default TextArea;
