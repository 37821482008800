import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import { getPriorityReportV1, getTodoNotes, saveTodoNotes } from "store/services/clientDataReportsService";
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { ReactComponent as EditIcn } from "Assets/Images/icn/pencil.svg";
import { ReactComponent as PlusIcn } from "Assets/Images/icn/plus.svg";
import { safelyParseJSON, UC_FIRST } from "helpers/common";
import { decode } from "html-entities";
import AddNotesPopup from "components/shared/AddNotesPopup/Index";
import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import SeverityPartOnePDF from "../PDFReportComponents/SeverityPartOnePDF";
import SeverityPartTwoPDF from "../PDFReportComponents/SeverityPartTwoPDF";

const PriorityReport = ({
  eventKey,
  todoId,
  client,
  submittedOn,
  showLoader,
  hideLoader
}) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priorityReport, setPriorityReportData] = useState(null);
  const [notesData, setNotesData] = useState(null);
  const [notesPopup, setNotesPopup] = useState({
    show: false,
    question: null,
    notes: null,
  });

  useEffect(() => {
    if (isOpen && !priorityReport) {
      getPriorityReportData();
      getNotes();
    }
  }, [isOpen]);

  const openNotesPopup = (question) => {
    let notes = notesData?.find(notes => notes.question_id === question.question_id && notes.page_id === question.page_id);
    setNotesPopup(prevState => ({
      ...prevState,
      show: true,
      question,
      notes: notes || null
    }));
  }

  const closeNotesPopup = () => {
    setNotesPopup(prevState => ({
      ...prevState,
      show: false,
      question: null,
      notes: null
    }));
  }

  const getNotes = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      const response = await getTodoNotes(todoId);
      if (response.success === true && response.body.length > 0) {
        setNotesData(response.body);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getPriorityReportData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      const response = await getPriorityReportV1(data);
      if (response.success === true) {
        const data = response.body;
        setPriorityReportData(data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  const handleSaveNotes = async (notes, question) => {
    try {
      let data = {
        todoId,
        values: {
          todo_id: todoId,
          page_id: question.page_id,
          question_id: question.question_id,
          general_notes: notes.general_notes,
          specific_notes: notes.specific_notes,
        }
      };
      await saveTodoNotes(data);
      await getNotes();
    } catch (err) {
      throw err;
    }
  }

  return (
    <Accordion.Item eventKey={eventKey} className="priority-report my-2">
      <Accordion.Header>
        Severity Report
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <AddNotesPopup
          showPopup={notesPopup.show}
          setShowPopup={setNotesPopup}
          closePopup={closeNotesPopup}
          question={notesPopup.question}
          notes={notesPopup.notes}
          saveNotes={handleSaveNotes}
        />
        {isLoading ?
          <p>Loading...</p>
          :
          priorityReport ?
            <div className="accordion-inner">
              <div className="py-2">
                <div className="top d-flex align-items-center justify-content-between gap-10">
                  <div className="left d-flex align-items-center gap-10">

                  </div>
                  <div className="right">
                    <Dropdown className="download-pdf-dropdown">
                      <Dropdown.Toggle
                        className=" commonBtn btnSm text-uppercase fw-sbold border pe-5 position-relative"
                        id="dropdown-basic"
                      >
                        <span className="icn me-2">
                          <DownloadIcon />
                        </span>
                        Download Report
                      </Dropdown.Toggle>

                      <Dropdown.Menu>

                        <Dropdown.Item as={"div"}>
                          <SeverityPartOnePDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            label="Severity Report: Part I"
                            showLoader={showLoader}
                            hideLoader={hideLoader}
                          />
                        </Dropdown.Item>

                        <Dropdown.Item as={"div"}>
                          <SeverityPartOnePDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            showNotes={true}
                            label="Severity Report: Part I w/ Notes"
                            showLoader={showLoader}
                            hideLoader={hideLoader}
                          />
                        </Dropdown.Item>

                        <Dropdown.Item as={"div"}>
                          <SeverityPartTwoPDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            label="Severity Report: Part II"
                            showLoader={showLoader}
                            hideLoader={hideLoader}
                          />
                        </Dropdown.Item>

                        <Dropdown.Item as={"div"}>
                          <SeverityPartTwoPDF
                            date={submittedOn}
                            client={client}
                            todoId={todoId}
                            showNotes={true}
                            label="Severity Report: Part II w/ Notes"
                            showLoader={showLoader}
                            hideLoader={hideLoader}
                          />
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="top">
                  <p className="m-0 text-uppercase fw-bold">Severity Report: Part I</p>
                  <p className="m-0">The questions highlighted below are colored and ranked based on the specific NAQ question the user answered. Severity categories used for ranking are:</p>
                  <ul>
                    <li>Severe: highlighted in red, these represent questions where the user chose the highest risk/severity answer.</li>
                    <li>Moderate: highlighted in yellow, these represent questions where the user chose the 2nd highest risk/severity answer.</li>
                    <li>Minor: not highlighted, these represent questions where the user chose the 3rd highest risk/severity answer.</li>
                  </ul>
                </div>
              </div>
              <div className="sections-outer">
                {(priorityReport && priorityReport.part1?.length > 0) ?
                  <div className="section py-4">
                    <div className="commonCardBox">
                      <div className="table-responsive">
                        <table className="table priority-table">
                          <thead>
                            {" "}
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Severity
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Section
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Question
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Answer
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Score vs Max
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <TableRows
                              questions={priorityReport.part1}
                              notesData={notesData}
                              openNotesPopup={openNotesPopup}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    <p>Data not available</p>
                  </>
                }
              </div>
              <div className="py-2">
                <div className="top">
                  <p className="m-0 text-uppercase fw-bold">Severity Report: Part II</p>
                  <p className="m-0">The questions highlighted below are colored and ranked based on the specific NAQ question the user answered. Severity categories used for ranking are:</p>
                  <ul>
                    <li>Severe: highlighted in red, these represent questions where the user chose the highest risk/severity answer.</li>
                    <li>Moderate: highlighted in yellow, these represent questions where the user chose the 2nd highest risk/severity answer.</li>
                    <li>Minor: not highlighted, these represent questions where the user chose the 3rd highest risk/severity answer.</li>
                  </ul>
                </div>
              </div>
              <div className="sections-outer">
                {(priorityReport && priorityReport.part2?.length > 0) ?
                  <div className="section py-4">
                    <div className="commonCardBox">
                      <div className="table-responsive">
                        <table className="table priority-table">
                          <thead>
                            {" "}
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Severity
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Section
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Question
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Answer
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Score vs Max
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <TableRows
                              questions={priorityReport.part2}
                              notesData={notesData}
                              openNotesPopup={openNotesPopup}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    <p>Data not available</p>
                  </>
                }

              </div>
            </div>
            :
            <>
              <p>Data not available</p>
            </>

        }
      </Accordion.Body>
    </Accordion.Item>
  )
}

const TableRows = ({ questions, notesData, openNotesPopup }) => {
  // let questions = section.questions.map()
  return (
    <>
      {questions && questions.length > 0 &&
        questions.map((question) => {
          let notes = notesData?.find(notes => notes.question_id === question.question_id && notes.page_id === question.page_id);
          if(typeof notes?.specific_notes === "string") {
            notes.specific_notes = safelyParseJSON(notes.specific_notes, []);
          }
          return (
            <>
              <tr>
                <td className={`p-3 border-0 ${question.category}-category`}>{UC_FIRST(question.category)}</td>
                <td className={`p-3 border-0 ${question.category}-category`}>{UC_FIRST(question.page_title_short_name)}</td>
                <td className={`p-3 border-0 ${question.category}-category`}>{decode(question.question)}</td>
                <td className={`p-3 border-0 ${question.category}-category`}>{decode(question.answer)}</td>
                <td className={`p-3 border-0 ${question.category}-category`}>{question.scoreVsMax}</td>
                <td className={`p-3 border-0 ${question.category}-category`}>
                  <Link
                    onClick={() => {
                      openNotesPopup(question)
                    }}
                  >
                    {notes ?
                      <EditIcn className="edit-icon" />
                      :
                      <PlusIcn className="add-icon" />
                    }
                  </Link>
                </td>
              </tr>
              {notes &&
                <tr class="severity-notes">
                  <td colSpan={5}>
                    <table class="severity-notes-table">
                      {notes.general_notes &&
                        <tr>
                          <td><strong>Notes</strong></td>
                          <td>{notes.general_notes}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Indicated Nutrients"]) &&
                        <tr>
                          <td><strong>Indicated Nutrients</strong></td>
                          <td>{notes.specific_notes["Indicated Nutrients"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Targeted Supplements"]) &&
                        <tr>
                          <td><strong>Targeted Supplements</strong></td>
                          <td>{notes.specific_notes["Targeted Supplements"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Food Support Sources"]) &&
                        <tr>
                          <td><strong>Food Support Sources</strong></td>
                          <td>{notes.specific_notes["Food Support Sources"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Lifestyle Support Ideas"]) &&
                        <tr>
                          <td><strong>Lifestyle Support Ideas</strong></td>
                          <td>{notes.specific_notes["Lifestyle Support Ideas"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Foundational Connections"]) &&
                        <tr>
                          <td><strong>Foundational Connections</strong></td>
                          <td>{notes.specific_notes["Foundational Connections"]}</td>
                        </tr>
                      }
                      {(notes.specific_notes && notes.specific_notes["Related Client Concern(s)"]) &&
                        <tr>
                          <td><strong>Related Client Concern(s)</strong></td>
                          <td>{notes.specific_notes["Related Client Concern(s)"]}</td>
                        </tr>
                      }
                    </table>
                  </td>
                </tr>
              }
            </>
          )
        })
      }
    </>
  )
}

export default PriorityReport;