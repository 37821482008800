import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DeleteAppointmentPop = ({
  appointmentDeletePop,
  setAppointmentDeletePop,
}) => {
  const handleDeleteAppointmentPop = () =>
    setAppointmentDeletePop(!appointmentDeletePop);

  return (
    <>
      <Modal
        show={appointmentDeletePop}
        onHide={handleDeleteAppointmentPop}
        backdrop="static"
        keyboard={false}
        centered
        className="appointmentDeletePop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">
            Delete Appointment Type?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <p className="m-0 py-1">
              Are you sure you want to delete this Appointment Type?
            </p>
            <p className="m-0 py-1">
              Any users that already booked this appointment type will still
              show it as booked but no new users will be able to book this
              Appointment Type.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={handleDeleteAppointmentPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAppointmentPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Yes, Delete the Appointment Type
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAppointmentPop;
