import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { FULL_NAME } from "helpers/common";

const ConfirmUnlinkClientPopup = ({ showPopup, closePopup, handleUnlink, client }) => {
  const [isUnlinking, setUnlinking] = useState(false);
  const onClickUnlink = () => {
    handleUnlink(setUnlinking);
  }
  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        scrollable="true"
        centered
        className="contentPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Unlink Appointment from Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <p className="m-0">
            Are you sure you want to unlink this appointment from <span className="fw-bold">{FULL_NAME(client?.first_name, client?.last_name)}</span>?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={closePopup}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn"
            onClick={onClickUnlink}
            disabled={isUnlinking}
          >
            {isUnlinking ? "Please wait..." : "Unlink"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmUnlinkClientPopup;
