import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../../Assets/Images/Ss_gc1.png";
import s2 from "../../../../Assets/Images/Ss_gc2.png";
import s3 from "../../../../Assets/Images/Ss_gc3.png";
import s4 from "../../../../Assets/Images/Ss_gc4.png";
import s5 from "../../../../Assets/Images/Ss_gc5.png";

const HelpGoogleLinkAccount = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContnet py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Google Calendar Integration{" "}
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                  <p className="m-0 py-2">
                    Link your Google Calendar to your Nutri-Q account so that
                    your Nutri-Q appointments will automatically show up on your
                    Google Calendar. If you haven't set up your Nutri-Q
                    Appointment Scheduler yet check out our guide:{" "}
                    <Link className="fw-sbold" to="">
                      Appointment Scheduler Set Up
                    </Link>
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 className="m-0 by-2">Linking your Google Calendar</h4>
                  <p className="m-0 py-2">
                    Start by logging into your Nutri-Q account. Then click on
                    the icon in the top right corner and choose 'My Account'
                    from the dropdown:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Once you're on the My Account page, click on the '3rd Party
                    Integrations' tab in the left column. Then locate the Google
                    Calendar section and click the 'Link your Google Calendar'
                    button:
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s2} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    You'll then be redirected to a Google page where you'll
                    choose which Google account you'd like to link:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s3} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    After you choose the Google account that you would like to
                    link (and log into that account if you aren't already logged
                    in) you'll need to grant Nutri-Q access to your Google
                    Calendar by checking the 2 boxes and clicking 'Continue' at
                    the bottom:
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s4} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    After clicking 'Continue', you will be redirected back to
                    the Nutri-Q site. On the '3rd Party Integrations' tab of
                    your 'My Account' page the Google Calendar section will now
                    show a green 'Linked' message, letting you know your account
                    was successfully linked!
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s5} alt="" className="img-fluid" />
                  </div>
                </div>
                {/* <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    You'll also see a new section with these additional
                    settings:
                  </p>
                  <ul className="ps-3 m-0">
                    <li className="">
                      <span className="fw-sbold">
                        Which of your Google Calendars should we send your
                        events to?
                      </span>
                      <ul className="ps-3 m-0">
                        <li className="">
                          This defaults to your main Google Calendar.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <span className="fw-sbold">
                        Should we include Client's names in meeting titles?
                      </span>
                      <ul className="ps-3 m-0">
                        <li className="">
                          This will determine how the client's name is displayed
                          in the Google Calendar event.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <span className="fw-sbold">
                        Your Google Calendar notifications for these meetings:
                      </span>
                      <ul className="ps-3 m-0">
                        <li className="">
                          These are Google reminders/notifications for the
                          Google Calendar event and are completely separate from
                          the Nutri-Q appointment reminder emails that are sent
                          to your clients the day before their appointments
                          based on the Nutri-Q Scheduler setting.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <span className="fw-sbold">
                        Only allow appointments if my Google Calendar is also
                        free:
                      </span>
                      <ul className="ps-3 m-0">
                        <li className="">
                          Allows clients to only book appointments with you when
                          your Google Calendar and Nutri-Q Appointments calendar
                          are both free. See Appointment Syncing below for more
                          details.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <span className="fw-sbold">
                        Show my 'busy' Google Calendar events on my Nutri-Q
                        Appointments calendar view:
                      </span>
                      <ul className="ps-3 m-0">
                        <li className="">
                          Allows you to show your Google Calendar events that
                          are categorized as 'busy' (not 'free') on your Nutri-Q
                          Appointments calendar. See Appointment Syncing below
                          for more details.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="imgWrp my-2">
                    <img src={s5} alt="" className="imgWrp" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    You can always update these settings or unlink your Google
                    Calendar in the future by clicking on the 'Edit Google
                    Calendar settings' link:
                  </p>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpGoogleLinkAccount;
