import { Button, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";

const Form_ = ({ initialValues, handleSubmit, closePopup, client }) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    Log In Email{" "}
                    {client && client.last_password_updated && (
                      <span className="text-muted">(only the client can change this)</span>
                    )}
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                    name="email"
                    onChange={handleChange}
                    placeholder="Email"
                    onBlur={handleBlur}
                    value={values.email}
                    autoComplete="off"
                    disabled={client && !!client.last_password_updated}
                  />
                  {errors.email && touched.email && (
                    <p className="invalid-feedback">{errors.email}</p>
                  )}
                </Col>
                <Row>
                  <Col lg="6" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      First Name
                    </label>
                    {/* <input type="text" placeholder="" className="form-control" value={client.first_name}/> */}
                    <input
                      className={`form-control ${errors.first_name && "is-invalid"}`}
                      name="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      autoComplete="off"
                    />
                    {errors.first_name && touched.first_name && (
                      <p className="invalid-feedback">{errors.first_name}</p>
                    )}
                  </Col>
                  <Col lg="6" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Last Name
                    </label>
                    {/* <input type="text" placeholder="" className="form-control" /> */}
                    <input
                      className={`form-control ${errors.last_name && "is-invalid"}`}
                      name="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      autoComplete="off"
                    />
                    {errors.last_name && touched.last_name && (
                      <p className="invalid-feedback">{errors.last_name}</p>
                    )}
                  </Col>
                  {/* <Col lg="6" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Log In Email{" "}
                      <span className="text-muted">
                        (only the client can change this)
                      </span>{" "}
                    </label>
                    <input
                      type="email"
                      placeholder=""
                      className="form-control"
                      readOnly
                    />
                  </Col> */}
                  <Col lg="6" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Phone <span className="text-muted">(Optional)</span>{" "}
                    </label>
                    {/* <input
                      type="tel"
                      placeholder=""
                      className="form-control"
                      readOnly
                    /> */}
                    <PhoneInput
                      style={{ width: "auto" }}
                      enableAreaCodes={true}
                      inputProps={{
                        name: "phone",
                        onBlur: handleBlur,
                      }}
                      // value={`${values.country_code}${values.phone}`}
                      value={`${values.phone}`}
                      onChange={(phone, country) => {
                        setFieldValue("phone", phone);
                        setFieldValue("country_code", country.dialCode);
                      }}
                      onBlur={handleBlur}
                      // className={`phone-input ${errors[field.name] && 'is-invalid'}`}
                    />
                  </Col>
                  {/* <Col lg="6" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Secondary Email
                    </label>
                    <input
                      type="email"
                      placeholder=""
                      className="form-control"
                      readOnly
                    />
                  </Col> */}
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Date of Birth <span className="text-muted">(Optional)</span>{" "}
                    </label>{" "}
                    <input
                      type="date"
                      className={`form-control ${errors.birth_date && "is-invalid"}`}
                      name="birth_date"
                      onChange={(e) => {
                        handleChange(e);
                        console.log("values", values, e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.birth_date}
                      autoComplete="off"
                    />
                    {errors.birth_date && touched.birth_date && (
                      <p className="invalid-feedback">{errors.birth_date}</p>
                    )}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Address <span className="text-muted">(optional)</span>{" "}
                    </label>
                    <div className="py-2">
                      {/* <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        readOnly
                      /> */}
                      <input
                        className={`form-control ${errors.street && "is-invalid"}`}
                        name="street"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street}
                        placeholder="Street address, P.O. box etc"
                        autoComplete="off"
                      />
                      {errors.street && touched.street && (
                        <p className="invalid-feedback">{errors.street}</p>
                      )}
                    </div>
                    <div className="py-2">
                      {/* <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        readOnly
                      /> */}
                      <input
                        className={`form-control ${errors.apartment && "is-invalid"}`}
                        name="apartment"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apartment}
                        placeholder="Apartment, suite, unit, building, floor etc"
                        autoComplete="off"
                      />
                      {errors.apartment && touched.apartment && (
                        <p className="invalid-feedback">{errors.apartment}</p>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" className="my-1">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      City
                    </label>
                    {/* <input type="text" className="form-control" /> */}
                    <input
                      className={`form-control ${errors.city && "is-invalid"}`}
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      placeholder="City"
                      autoComplete="off"
                    />
                    {errors.city && touched.city && (
                      <p className="invalid-feedback">{errors.city}</p>
                    )}
                  </Col>
                  <Col lg="6" className="my-1">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      State/Province/Region
                    </label>
                    {/* <input type="text" className="form-control" /> */}
                    <input
                      className={`form-control ${errors.state && "is-invalid"}`}
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      placeholder="State"
                      autoComplete="off"
                    />
                    {errors.state && touched.state && (
                      <p className="invalid-feedback">{errors.state}</p>
                    )}
                  </Col>
                  <Col lg="6" className="my-1">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Zip/Postal Code
                    </label>
                    {/* <input type="text" className="form-control" /> */}
                    <input
                      className={`form-control ${errors.zipcode && "is-invalid"}`}
                      name="zipcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zipcode}
                      placeholder="Zip/Postal Code"
                      autoComplete="off"
                    />
                    {errors.zipcode && touched.zipcode && (
                      <p className="invalid-feedback">{errors.zipcode}</p>
                    )}
                  </Col>
                  <Col lg="6" className="my-1">
                    <label htmlFor="" className="form-label fw-sbold m-0">
                      Country
                    </label>
                    {/* <input type="text" className="form-control" /> */}
                    <input
                      className={`form-control ${errors.country && "is-invalid"}`}
                      name="country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      placeholder="Country"
                      autoComplete="off"
                    />
                    {errors.country && touched.country && (
                      <p className="invalid-feedback">{errors.country}</p>
                    )}
                  </Col>
                </Row>
                <Col lg="12" className="my-2 gap-10 FormbtnWrpper d-flex">
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={closePopup}
                  >
                    Cancel
                  </Button>
                  {/* <Button className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                          Login Now
                        </Button> */}
                  {/* <Link
                        to="/dashboard"
                        className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                      >
                        Login Now
                      </Link> */}
                  <Button
                    type="submit"
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const today = new Date();
today.setHours(0, 0, 0, 0);
const validation = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[^0-9]+$/, "Must not contain numbers")
    .required("First Name is required"),
  last_name: Yup.string()
    .matches(/^[^0-9]+$/, "Must not contain numbers")
    .required("Last Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  birth_date: Yup.date().max(today, "Birth date cannot be in the future"),
});

export default Form_;
