import { Formik } from "formik"
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap"
import * as Yup from 'yup';
import { isEmpty } from "helpers/common";

const NumberFields = ({ columnData, setColumnData, isValid, setFieldValue, errors }) => {
  const [fields, setFields] = useState({
    minNum: 0,
    maxNum: 10,
  });

  useEffect(() => {
    if(!isEmpty(columnData)){
      setFields({
        minNum: columnData ? columnData.minNum : fields.minNum,
        maxNum: columnData ? columnData.maxNum : fields.maxNum,
      });
    } else {
      handleFormSubmit(fields);
    }
  }, [columnData])

  const handleFormSubmit = (values) => {
    try {
      setColumnData(values);
    } catch (err) {
      console.log("Error saving values", err);
    }
  }

  const handleInputChange = (e, fieldName) => {
    // handleChange(e);
    const newFields = {
      ...fields,
      [fieldName]: e.target.value
    }
    newFields.minNum = newFields.minNum && parseInt(newFields.minNum);
    newFields.maxNum = newFields.maxNum && parseInt(newFields.maxNum);
    setFields(newFields);
    handleFormSubmit(newFields);
  }
  
  return (
    <Row>
      <Col lg="12" className="my-2">
        <h6>Allowed Number Range (required)</h6>
        <p className="m-0">The minimum and maximum number that can be entered.</p>
      </Col>
      <Col lg="12" className="my-2">
        <Row>
          <Col lg="6">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Minimum Number:
            </label>
            <input
              type="number"
              className={`form-control ${errors.minNum && "is-invalid"}`}
              name="minNum"
              onChange={(e) => handleInputChange(e, "minNum")}
              value={fields.minNum}
              autoComplete="off"
            />
            {errors.minNum && (
              <p className="invalid-feedback">{errors.minNum}</p>
            )}
          </Col>
          <Col lg="6">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Maximum Number:
            </label>
            <input
              type="number"
              className={`form-control ${errors.maxNum && "is-invalid"}`}
              name="maxNum"
              onChange={(e) => handleInputChange(e, "maxNum")}
              value={fields.maxNum}
              autoComplete="off"
            />
            {errors.maxNum && (
              <p className="invalid-feedback">{errors.maxNum}</p>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default NumberFields;