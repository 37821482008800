import React, { useEffect, useState } from "react";
import TableData from "../../ToDoTable/Index";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import SelectForm from "./Component/SelectForm";
import AddDataForm from "./Component/AddDataForm/Index";

// icn
import {
  createClientToDoItem,
  deleteClientToDo,
  getClientToDoList,
  getTodoFormDetails,
} from "store/services/clientService";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import { getMyFormList } from "store/services/myFormService";
import FoodMoodJournalForm from "./Component/FoodMoodJournalForm/Index";
import {
  ACTION_PLAN_FORM_ID,
  DIRECT_ACCOUNT_TYPE,
  FOOD_JOURNAL_FORM_ID,
  FULL_ACCOUNT_TYPE,
  QUESTIONS_PDF_LINK,
  RECOMMENDATION_FORM_ID,
} from "common/constants";
import FormSubmittedPopup from "./Component/Modals/FormSubmittedPopup";
import { useShowLoader } from "hooks/useShowLoader";
import NewRecommendationWizard from "../Recommendation/Component/NewRecommendationWizard/Index";

const AddDataTab = ({
  dispatch,
  formList,
  todoPages,
  loader,
  currentlyEditingToDoItems,
  lastActiveStep,
  client,
}) => {
  const showLoader = useShowLoader();
  const [showSelectFormScreen, setShowSelectFormScreen] = useState(true);
  const [editScreen, setEditScreen] = useState({
    show: false,
    formId: null,
  });
  const [currentToDoId, setCurrentToDoId] = useState(null);
  const [showFormSuccessPopup, setShowFormSuccessPopup] = useState(false);
  const [submittedFormId, setSubmittedFormId] = useState(null);

  // client ID
  const { clientId } = useParams();
  let recentToDoKey = `recently_edited_todo_${clientId}`;

  useEffect(() => {
    // get last edited todo item ID
    let recentToDoId = localStorage.getItem(recentToDoKey);
    setCurrentToDoId(recentToDoId);
    return () => {
      setCurrentToDoId(null);
    };
  }, []);

  useEffect(() => {
    if (client && client.client_category) {
      // fetch default form list
      getDefautFormList(client.client_category);
    }
  }, [client]);

  useEffect(() => {
    if (currentToDoId) {
      openTodoAndStartEditing(currentToDoId);
    }
  }, [currentToDoId]);

  /**
   * open todo and start editing
   */
  const openTodoAndStartEditing = async (todoId) => {
    try {
      showLoader(true);
      let response = await getTodoFormDetails(todoId);
      if (response?.success === true && response?.body) {
        localStorage.setItem(recentToDoKey, todoId);
        setEditScreen({
          show: true,
          formId: response?.body?.form_id,
        });
        setShowSelectFormScreen(false);
        setCurrentToDoId(todoId);
      }
      showLoader(false);
    } catch (err) {
      console.log("Error opening todo", err);
      showLoader(false);
    }
  };

  /**
   * Get default forms list
   */
  const getDefautFormList = async (categoryId) => {
    try {
      let data = {
        category_id: categoryId,
        intent: "add_data",
        account_type: client?.is_direct_account === "yes" ? DIRECT_ACCOUNT_TYPE : FULL_ACCOUNT_TYPE,
      };
      showLoader(true);
      await dispatch(getMyFormList(data));
      showLoader(false);
    } catch (err) {
      console.log(err);
      showLoader(false);
    }
  };

  const createTodoAndStartEditing = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      // const callback = async (response) => {

      // };

      let data = {
        clientId: clientId,
        values: {
          forms: [values.selected_form],
          todo_owner: "pract",
        },
      };
      let response = await dispatch(createClientToDoItem(data));
      const { body } = response;
      const createdTodo = body?.length > 0 && body[0];
      if (createdTodo && typeof createdTodo.id !== "undefined") {
        setCurrentToDoId(createdTodo.id);
      }
      setSubmitting(false);
    } catch (error) {
      console.log("error", error);
      setSubmitting(false);
      setCurrentToDoId(null);
    }
  };

  const openToDo = async (todo) => {
    let todoId = todo?.id;
    let formId = todo?.form_id;
    setCurrentToDoId(todoId);
    setEditScreen({
      show: true,
      formId,
    });
  };

  const deleteToDo = async (todoId) => {
    try {
      
    } catch (error) {
      console.log("error", error);
      showLoader(false);
    }

    try {
      await CONFIRM_POPUP()
        .then(async (isConfirm) => {
          if (isConfirm) {
            let data = {
              todoId: todoId,
            };
            showLoader(true);
            await dispatch(deleteClientToDo(data));
            await dispatch(getClientToDoList({ clientId }));
            showLoader(false);
            SUCCESS_MESSAGE("To-Do deleted successfully");
          }
        })
        .catch(err => {
          showLoader(false);
          throw err;
        })
    } catch (err) {
      console.log("Error removing To-Do item", err);
    }
  };

  const deleteCurrentToDoAndGoBack = async () => {
    await deleteToDo(currentToDoId);
    goBackToFormSelectScreen();
  };

  const goBackToFormSelectScreen = async () => {
    try {
      localStorage.removeItem(recentToDoKey);
      setShowSelectFormScreen(true);
      setCurrentToDoId(null);
      setEditScreen({
        show: false,
        formId: null,
      });
    } catch(err) {
      console.log("Error pausing to-do item", err);
    }
  };

  const handleToDoCompleted = async (formId) => {
    try {
      switch (formId) {
        case ACTION_PLAN_FORM_ID:
        case FOOD_JOURNAL_FORM_ID:
        case RECOMMENDATION_FORM_ID:
          setSubmittedFormId(formId);
          setShowFormSuccessPopup(true);
          break;

        default:
          SUCCESS_MESSAGE("ToDo has been submitted successfully");
          break;
      }
      goBackToFormSelectScreen();
      let data = {
        clientId,
      };
      await dispatch(getClientToDoList(data));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <FormSubmittedPopup
        showPopup={showFormSuccessPopup}
        handleClose={() => {
          setShowFormSuccessPopup(false);
        }}
        formId={submittedFormId}
      />
      {showSelectFormScreen === true && (
        <>
          <div className="py-2">
            <div className="top d-flex align-items-start justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">Add Client Data</h6>
                <p className="m-0">
                  If you have a previous form from this client or other information that you'd like
                  to enter you can add it here by selecting one of the options below.{" "}
                  {/* <Link target="_blank" to={QUESTIONS_PDF_LINK} className="underline">
                    View what questions are included in each option.
                  </Link> */}
                </p>
              </div>
            </div>
            <div className="select-form">
              <SelectForm formList={formList} handleSubmit={createTodoAndStartEditing} />
            </div>
          </div>
          <div className="py-2">
            <div className="top d-flex align-items-start justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">Client's Data you're Currently Entering or Editing</h6>
                <p className="m-0">
                  Below are your in progress forms that you're currently entering or editing. These
                  are forms that you (and not your client) are filling out or editing.
                </p>
              </div>
            </div>
            <div className="commonCardBox my-3">
              <TableData
                todoItems={currentlyEditingToDoItems}
                onClickOpen={openToDo}
                onClickRemove={deleteToDo}
              />
            </div>
          </div>
        </>
      )}
      {showSelectFormScreen === false && editScreen.show && (
        <>
          <Link onClick={goBackToFormSelectScreen}>
            <i class="fa fa-arrow-left px-2" aria-hidden="true"></i>
            View All Forms
          </Link>
          {/* <DemoData /> */}
          {editScreen.formId == FOOD_JOURNAL_FORM_ID ? (
            <FoodMoodJournalForm
              todoId={currentToDoId}
              formId={editScreen.formId}
              onToDoCompleted={handleToDoCompleted}
            />
          ) : editScreen.formId == RECOMMENDATION_FORM_ID ? (
            <NewRecommendationWizard
              handleClose={goBackToFormSelectScreen}
              recommendationId={currentToDoId}
              formId={editScreen.formId}
              onCompleted={handleToDoCompleted}
            />
          ) : (
            <AddDataForm
              todoPages={todoPages}
              todoId={currentToDoId}
              formId={editScreen.formId}
              // handleSubmit={saveFormResponse}
              onToDoCompleted={handleToDoCompleted}
              initialStep={lastActiveStep}
              handleGoBack={goBackToFormSelectScreen}
              deleteTodoAndGoBack={deleteCurrentToDoAndGoBack}
            />
          )}
          {/* <div className="py-2">
            <p className="m-0 text-dark">
              Pause this to-do item and go back to the Add Data page by{" "}
              <Link onClick={goBackToFormSelectScreen} to="" className="underline">
                clicking here
              </Link>{" "}
              .
            </p>
            <p className="m-0 text-dark">
              Delete this to-do item and go back to the Add Data page by{" "}
              <Link onClick={deleteCurrentToDoAndGoBack} to="" className="underline">
                clicking here
              </Link>{" "}
              .
            </p>
          </div> */}
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => {
  let questionCount = 1;
  let todoPages = state.Client.currentTodoData.questions.map((page) => {
    let pageWithNumberedQuestions = page.questions.map((question) => {
      question.number = questionCount;
      questionCount++;
      return question;
    });
    page.questions = pageWithNumberedQuestions;
    return page;
  });

  let lastActiveStep = null;
  if (state.Client.currentTodoData.formDetails.additionalDetails) {
    let additionalDetails = state.Client.currentTodoData.formDetails.additionalDetails;
    let lastActivePage = additionalDetails.practitioner_last_page || 1;
    todoPages.forEach((page, i) => {
      if (Number(page.form_page_id) === Number(lastActivePage)) {
        lastActiveStep = typeof todoPages[i + 1] !== "undefined" ? i + 1 : i;
      }
    });
  }

  return {
    formList: state.MyForm.myForms,
    client: state.Client.client.details,
    todoPages: todoPages,
    loader: state.Client.loader,
    lastActiveStep,
    currentlyEditingToDoItems: state.Client?.client?.todoItems?.practitoner_owned,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddDataTab);
