import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";

// css
import "./Layout.scss";
import Sidebar from "components/header/Guide/Sidebar";
import Footer from "components/footer/Footer";

const Layout = () => {
  const [showSidebar, setSidebar] = useState();
  const handleSidebar = () => {
    setSidebar(!showSidebar);
  };
  return (
    <>
      <Sidebar showSidebar={showSidebar} setSidebar={setSidebar} />
      <main className="ms-auto guideMain">
        <Outlet />
        <Footer />
      </main>
    </>
  );
};

export default Layout;
