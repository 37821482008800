import React from 'react';
import './LoadingSkeleton.css'; // Import the CSS file

const LoadingSkeleton = (props) => {
  const skeletonItems = Array.from({ length: props.itemCount }, (_, index) => index);
  return (
    <div className={`loading-skeleton ${props.className}`} {...props}>
      {skeletonItems.map(index => (
        <div
          key={index}
          className="loading-skeleton-item"
        ></div>
      ))}
    </div>
  );
};

export default LoadingSkeleton;