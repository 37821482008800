import RadioOptions from "./RadioOptions";
import CheckboxOptions from "./CheckboxOptions";
import DisclaimerFields from "./DisclaimerFields";
import NumberFields from "./NumberFields";
import RatingScaleFields from "./RatingScaleFields";

const QuestionFields = (props) => {
  return (
    <>
      {props.questionTypeId == 3 ?
        <CheckboxOptions {...props} />
        :
        props.questionTypeId == 7 ?
          <RadioOptions {...props} />
          :
          props.questionTypeId == 4 ?
            <DisclaimerFields {...props} />
            :
            props.questionTypeId == 6 ?
              <NumberFields {...props} />
              :
              props.questionTypeId == 8 ?
                <RatingScaleFields {...props} />
                :
                ""

      }
    </>
  )
}

export default QuestionFields;