import { PASSWORD_VALIDATE_REGEX } from 'common/constants';
import { PASSWORD_VALIDATION_MSG } from 'common/strMessage';
import * as Yup from 'yup';

const validation = Yup.object({
  currentPassword: Yup.string().required("Please enter your current password"),
  newPassword: Yup.string()
    .required("Password Required")
    .min(8, PASSWORD_VALIDATION_MSG)
    .matches(PASSWORD_VALIDATE_REGEX, PASSWORD_VALIDATION_MSG)
    .notOneOf([Yup.ref("old_password")], "New password must be different from the old password"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),

});

export default validation;
