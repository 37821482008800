import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddCommentsPopup = ({ showPopup, closePopup, handleAddComment }) => {
  const [isSaving, setSaving] = useState(false);
  const [commentValue, setCommentValue] = useState("");

  const hidePopup = () => {
    setCommentValue("");
    closePopup();
  }

  const handleCommentChange = (e) => {
    setCommentValue(e.target.value);
  }

  const onSubmit = async () => {
    try {
      setSaving(true);
      await handleAddComment(commentValue);
      hidePopup();
      setSaving(false);
    } catch(err) {
      console.log("Error adding comment", err);
      setSaving(false);
    }
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={hidePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="journalPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="fm-comment-fields py-2">
            <textarea
              className="form-control"
              aria-label="With textarea"
              placeholder="Your comments..."
              rows={4}
              value={commentValue}
              onChange={handleCommentChange}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={hidePopup}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            className="d-flex align-items-center justify-content-center commonBtn"
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCommentsPopup;
