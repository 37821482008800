import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../../Assets/Images/Ss_ap1.png";
import s2 from "../../../../Assets/Images/Ss_ap2.png";
import s3 from "../../../../Assets/Images/Ss_ap3.png";
import s4 from "../../../../Assets/Images/Ss_ap4.png";
import s5 from "../../../../Assets/Images/Ss_ap5.png";
import s6 from "../../../../Assets/Images/Ss_ap6.png";
import s7 from "../../../../Assets/Images/Ss_ap7.png";
import s8 from "../../../../Assets/Images/Ss_ap8.png";
import s9 from "../../../../Assets/Images/Ss_ap9.png";
import s10 from "../../../../Assets/Images/Ss_ap10.png";
import s11 from "../../../../Assets/Images/Ss_ap11.png";
import s12 from "../../../../Assets/Images/Ss_ap12.png";
import s13 from "../../../../Assets/Images/Ss_ap13.png";
import s14 from "../../../../Assets/Images/Ss_ap14.png";
import s15 from "../../../../Assets/Images/Ss_ap15.png";
import s16 from "../../../../Assets/Images/Ss_ap16.png";
import s17 from "../../../../Assets/Images/Ss_ap17.png";
import s18 from "../../../../Assets/Images/Ss_ap18.png";
import s19 from "../../../../Assets/Images/Ss_ap19.png";

const HelpAppointmentPage = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div class="commonContent py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Appointments Page
                  </h2>
                  <p className="m-0 py-2">
                    Make sure you have completed the Appointment Scheduler Set
                    Up first.
                  </p>
                  <p className="m-0 py-2">
                    Once you have completed the setup, your Appointments page
                    should look something like the image below. In this article
                    we'll walk through the different sections.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s15} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Settings
                  </h2>
                  <p className="m-0 py-2">
                    If you ever need to view or edit any of your scheduler
                    settings, you can always click on the 'Scheduler Settings'
                    link in the top right corner:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s16} alt="" className="img-fluid" />
                  </div>
                  <p className="m-0 py-2">
                    This will display all of your settings in one view and allow
                    you to update/edit any of them:
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    My Schedule
                  </h2>
                  <p className="m-0 py-2">
                    The calendar schedule view at the top of the page shows your
                    past and future appointments, along with your working hours,
                    and it also allows you to add calendar blocks directly on
                    your calendar. See the below diagram for more details:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s16} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    Below your schedule view you'll find the 'Working Hours' and
                    'Appointment Types' modules. These give you quick access to
                    view and edit your working hours and appointment types:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s17} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    To the right of these modules, you'll find the 'Helpful
                    Information' module, which gives you access to your
                    Scheduler URL. (This is the link that you can send to
                    clients so that they can book an appointment with you.) This
                    module also as information on imbedding a calendar button or
                    the full scheduler in your own website:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s18} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpAppointmentPage;
