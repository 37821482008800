import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const WorkingHoursSetting = ({
  workingHours,
  buttonDisable,
  convertTo12HourFormat,
  handleAddTime,
  removeHours_,
  step_name,
  settingListStep,
  setStep,
  setSchedulerType,
  setStepType,
  stepType,
  saveStepTracking_,
  schedulerTypes,
}) => {
  const [stepToCheck, setStepToCheck] = useState(false)

  useEffect(() => {
    let settingListSteps = settingListStep?.data_set ?  JSON.parse(settingListStep?.data_set) : [];
    let isStepExists = settingListSteps.some(step => step.step_name === step_name);
      if (isStepExists) {
        setStepToCheck(true)
      } else {
        setStepToCheck(false)
      }
  }, [stepToCheck])

  const backStep_  = () => {
    if(stepType){
      setStepToCheck(false)
      setSchedulerType(true)
    }else{
      setStep(8)
    }
  }
  const nextStepType_  = () => {
    if(stepType){
      setStepToCheck(false)
      setSchedulerType(true)
    }if(schedulerTypes){
      setStep(13)
    }else{
      setStep(10)
    }
  }

  return (
    <>
      <div
        className="commonContent p-3 gettingStarted"
        style={{ background: "#fffef7", zIndex: "9" }}
      >
        <div className="top py-2 border-bottom">
          <h3 className="m-0 fw-bold py-2 text-uppercase">
            Working Hours Settings
          </h3>
        </div>
        <div className="CardBody py-2">
          <div className="py-2">
            <p className="py-2 m-0">
              Your Working Hours represent the days and hours that
              you are available for appointment bookings on a
              weekly basis. For example, if you only wanted to let
              clients book appointments on Mondays between 9:00am
              and 11:00am you would enter that information here.
              Only appointment times that fall on Mondays between
              9:00am and 11:00am would then be visible to clients
              as they're booking appointments. This represents
              your standard weekly schedule. In a later step you
              will handle any exceptions to this schedule on
              specific dates. Sticking with our previous example,
              if Mondays from 9:00am to 11:00am are your regular
              Working Hours, but 4 Mondays from now you'll be
              traveling and don't want any clients booking
              appointments, you will be able to block out that
              Monday in a future step.
            </p>
            <div className="py-2">
              <h4 className="m-0 fw-bold">
                Update Your Working Hours:
              </h4>
              <ul className="m-0 py-2">
                {workingHours?.length > 0 &&
                  workingHours.map((hours, i) => {
                    let dayTiming = hours.timings;
                    dayTiming = JSON.parse(dayTiming);
                    return (
                      <li className="py-1" key={i}>
                        <h6 className="m-0 fw-sbold">
                          {hours?.day.charAt(0).toUpperCase() +
                            hours.day?.slice(1)}
                        </h6>
                        <ul className="m-0 py-2">
                          {dayTiming.length > 0 && (
                            <li className="">
                              {dayTiming.length > 0 &&
                                dayTiming.map((time, j) => {
                                  return (
                                    <div key={j} className="d-flex align-items-start">
                                      <p
                                        className="m-0 fw-bold"
                                      >
                                        {convertTo12HourFormat(
                                          time.start_time
                                        )}{" "}
                                        to{" "}
                                        {convertTo12HourFormat(
                                          time.end_time
                                        )}
                                      </p>

                                      <Button
                                        className="border-0 p-0 btnSm h-auto ms-2"
                                        variant="transparent"
                                        onClick={() =>
                                          removeHours_(
                                            hours,
                                            time
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="8"
                                          height="8"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                        >
                                          <path
                                            d="M7.25 0.75L0.75 7.25M0.75 0.75L7.25 7.25"
                                            stroke="#FF0000"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </Button>
                                    </div>
                                  );
                                })}
                            </li>
                          )}
                          <li className="">
                            <Button
                              onClick={() => handleAddTime(hours)}
                              className="border-0 themeLink p-0 btnSm h-auto fw-sbold"
                              variant="transparent"
                            >
                              Add Time
                            </Button>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {!stepToCheck ? (
            <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
              <Button
                 onClick={() => setStep(8)}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                <span className="icn me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.623498 7.73221C0.436027 7.54468 0.330712 7.29037 0.330712 7.02521C0.330712 6.76005 0.436027 6.50574 0.623498 6.31821L6.2805 0.66121C6.37274 0.5657 6.48309 0.489517 6.60509 0.437108C6.7271 0.384699 6.85832 0.357114 6.9911 0.35596C7.12388 0.354806 7.25556 0.380108 7.37845 0.430388C7.50135 0.480669 7.613 0.554923 7.70689 0.648816C7.80079 0.742709 7.87504 0.854359 7.92532 0.977256C7.9756 1.10015 8.0009 1.23183 7.99975 1.36461C7.99859 1.49739 7.97101 1.62861 7.9186 1.75062C7.86619 1.87262 7.79001 1.98296 7.6945 2.07521L2.7445 7.02521L7.6945 11.9752C7.87666 12.1638 7.97745 12.4164 7.97517 12.6786C7.97289 12.9408 7.86773 13.1916 7.68232 13.377C7.49691 13.5624 7.2461 13.6676 6.9839 13.6699C6.7217 13.6722 6.4691 13.5714 6.2805 13.3892L0.623498 7.73221Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Back
              </Button>
              <Button
                onClick={() => saveStepTracking_("working_hours_setting")}
                disabled={buttonDisable ? true : false}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                {buttonDisable ? "Wait.." : "Next"}
                <span className="icn ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.707 6.29318C7.89447 6.48071 7.99979 6.73502 7.99979 7.00018C7.99979 7.26534 7.89447 7.51965 7.707 7.70718L2.05 13.3642C1.95775 13.4597 1.84741 13.5359 1.7254 13.5883C1.6034 13.6407 1.47218 13.6683 1.3394 13.6694C1.20662 13.6706 1.07494 13.6453 0.952046 13.595C0.82915 13.5447 0.717497 13.4705 0.623604 13.3766C0.529712 13.2827 0.455459 13.171 0.405178 13.0481C0.354897 12.9252 0.329595 12.7936 0.330749 12.6608C0.331903 12.528 0.359489 12.3968 0.411898 12.2748C0.464307 12.1528 0.540489 12.0424 0.635999 11.9502L5.586 7.00018L0.635999 2.05018C0.453841 1.86158 0.353047 1.60898 0.355325 1.34678C0.357604 1.08458 0.462772 0.83377 0.648181 0.648362C0.833589 0.462954 1.0844 0.357785 1.3466 0.355507C1.6088 0.353228 1.8614 0.454022 2.05 0.636181L7.707 6.29318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Button>
            </div>
            ):(
              <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
              <Button
               onClick={() => backStep_()}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                <span className="icn me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.623498 7.73221C0.436027 7.54468 0.330712 7.29037 0.330712 7.02521C0.330712 6.76005 0.436027 6.50574 0.623498 6.31821L6.2805 0.66121C6.37274 0.5657 6.48309 0.489517 6.60509 0.437108C6.7271 0.384699 6.85832 0.357114 6.9911 0.35596C7.12388 0.354806 7.25556 0.380108 7.37845 0.430388C7.50135 0.480669 7.613 0.554923 7.70689 0.648816C7.80079 0.742709 7.87504 0.854359 7.92532 0.977256C7.9756 1.10015 8.0009 1.23183 7.99975 1.36461C7.99859 1.49739 7.97101 1.62861 7.9186 1.75062C7.86619 1.87262 7.79001 1.98296 7.6945 2.07521L2.7445 7.02521L7.6945 11.9752C7.87666 12.1638 7.97745 12.4164 7.97517 12.6786C7.97289 12.9408 7.86773 13.1916 7.68232 13.377C7.49691 13.5624 7.2461 13.6676 6.9839 13.6699C6.7217 13.6722 6.4691 13.5714 6.2805 13.3892L0.623498 7.73221Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Back
              </Button>
              <Button
                onClick={() => nextStepType_()}
                disabled={buttonDisable ? true : false}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                {buttonDisable ? "Wait.." : "Save"}
                <span className="icn ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.707 6.29318C7.89447 6.48071 7.99979 6.73502 7.99979 7.00018C7.99979 7.26534 7.89447 7.51965 7.707 7.70718L2.05 13.3642C1.95775 13.4597 1.84741 13.5359 1.7254 13.5883C1.6034 13.6407 1.47218 13.6683 1.3394 13.6694C1.20662 13.6706 1.07494 13.6453 0.952046 13.595C0.82915 13.5447 0.717497 13.4705 0.623604 13.3766C0.529712 13.2827 0.455459 13.171 0.405178 13.0481C0.354897 12.9252 0.329595 12.7936 0.330749 12.6608C0.331903 12.528 0.359489 12.3968 0.411898 12.2748C0.464307 12.1528 0.540489 12.0424 0.635999 11.9502L5.586 7.00018L0.635999 2.05018C0.453841 1.86158 0.353047 1.60898 0.355325 1.34678C0.357604 1.08458 0.462772 0.83377 0.648181 0.648362C0.833589 0.462954 1.0844 0.357785 1.3466 0.355507C1.6088 0.353228 1.8614 0.454022 2.05 0.636181L7.707 6.29318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Button>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkingHoursSetting;