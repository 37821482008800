import React, { useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";
import TableData from "../../ToDoTable/Index";
import AddToDoPop from "../../Modals/AddToDoPop/Index";

// icn
import { ReactComponent as PlusIcn } from "../../../../../../../Assets/Images/icn/plus.svg";

import SubmittedData from "./Component/SubmittedData/Index";
import { FULL_NAME } from "helpers/common";
import { connect } from "react-redux";
import {
  getClientToDoList,
  deleteClientToDo,
  createClientToDoItem,
  sendToDoReminderToClient,
} from "store/services/clientService";
import * as actions from "store/actions";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import { Link, useParams } from "react-router-dom";
import { getFormsForClient } from "store/services/myFormService";
import { getAccountSettingList_ } from "store/services/accountSettingService";
import { setActiveTab } from "store/actions/client";
import TodoAddedPopup from "../../Modals/TodoAddedPopup";
import { DIRECT_ACCOUNT_TYPE, FULL_ACCOUNT_TYPE } from "common/constants";

const ToDoTab = ({
  dispatch,
  todoItems,
  clientDetails,
  completedTodoItems,
  loadingClientDetails,
  accountSettings,
  ownedByPract
}) => {
  const { clientId } = useParams();
  const [toDoPop, setToDoPop] = useState();
  const [formList, setFormList] = useState();

  useEffect(() => {
    getClientTodo();
    getAccountSettings();
  }, []);

  useEffect(() => {
    getClientForms();
  }, [clientDetails])

  const handleToDoPopup = () => {
    setToDoPop(!toDoPop);
  };

  let clientFullName = () => {
    return FULL_NAME(clientDetails.first_name, clientDetails.last_name);
  }

  async function assignToDoToClient(values, { setSubmitting }) {
    try {
      setSubmitting(true);
      let data = {
        clientId: clientDetails.id,
        values: {
          forms: values.selected_form,
          send_invite_email: values.send_invite_email,
          send_todo_email: values.send_todo_email,
        },
      };
      await dispatch(createClientToDoItem(data));
      await getClientTodo();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      throw error;
    }
  };

  const deleteToDo = async (todoId) => {
    try {
      await CONFIRM_POPUP()
        .then(async (isConfirm) => {
          if (isConfirm) {
            let data = {
              todoId: todoId,
            };
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(deleteClientToDo(data));
            await getClientTodo();
            dispatch(actions.persist_store({ loader: false }));
            SUCCESS_MESSAGE("To-Do deleted successfully");
          }
        })
        .catch(err => {
          dispatch(actions.persist_store({ loader: false }));
          throw err;
        })
    } catch (err) {
      console.log("Error removing To-Do item", err);
    }
  };

  // get currently editing todo list
  const getClientTodo = async () => {
    try {
      let data = {
        clientId,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getClientToDoList(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  const sendToDoReminder = async (todoId) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await sendToDoReminderToClient(todoId);
      if(res.success === true) {
        SUCCESS_MESSAGE("Reminder sent");
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const getClientForms = async () => {
    try {
      if(!clientDetails.client_category) {
        return;
      }
      dispatch(actions.persist_store({ loader: true }));
      let data = {
        category_id: clientDetails.client_category,
        intent: "todo",
        account_type: clientDetails?.is_direct_account === "yes" ? DIRECT_ACCOUNT_TYPE : FULL_ACCOUNT_TYPE,
      }
      let res = await getFormsForClient(data);
      if(res.success === true) {
        setFormList(res.body)
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const getAccountSettings = async () => {
    try {
      await dispatch(getAccountSettingList_());
    } catch (err) {
      console.log("Error fetching account settings", err);
    }
  }
  
  const openAddDataTab = () => {
    dispatch(setActiveTab("AddData"));
    window.scrollTo(0, 0);
  }

  const openPractOwnedTodom = (todo) => {
    localStorage.setItem(`recently_edited_todo_${clientId}`, todo.id);
    openAddDataTab();
  }

  return (
    <>
      <AddToDoPop 
        toDoPop={toDoPop}
        setToDoPop={setToDoPop}
        formList={formList}
        assignToDoToClient={assignToDoToClient}
        clientFullName={clientFullName()}
        client={clientDetails}
      />
      <div className="top d-flex align-items-start justify-content-between gap-10">
        {(!loadingClientDetails && clientDetails) &&
          <div className="left">
            <h6 className="m-0 fw-bold">
            {clientFullName()}'s To-Do Items
            </h6>
            <p className="m-0">
            Below are {clientFullName()}'s current uncompleted to-do items.
            </p>
          </div>
        }
        <div className="right">
          <Button
            onClick={handleToDoPopup}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            <span className="icn me-2">
              <PlusIcn />
            </span>
            Add a new to-do item
          </Button>
        </div>
      </div>
      <div className="CardBody py-3">
        <div className="commonCardBox">
          <TableData
            client={clientDetails}
            todoItems={todoItems}
            todoFor="client"
            onClickRemove={deleteToDo}
            onClickSendReminder={sendToDoReminder}
          />
          <p className="text-end m-0 py-2 text-dark fw-sbold">
            Do you have a previous form from this client that you'd like to
            manually enter? You can add it on the <Link onClick={openAddDataTab}>Add Data</Link> page.
          </p>
        </div>
        {/* Data entered by Practitioner */}
        <div className="mt-4">
          <div className="top d-flex align-items-start justify-content-between gap-10">
            <div className="left">
              <h6 className="m-0 fw-bold">
                Forms you're Currently Entering or Editing
              </h6>
              <p className="m-0">
                Below are your in progress forms that you're currently entering or editing. These are forms that you (and not your client) are filling out or editing.
              </p>
            </div>
          </div>
          <div className="commonCardBox">
            <TableData
              todoItems={ownedByPract}
              onClickOpen={openPractOwnedTodom}
              onClickRemove={deleteToDo}
            />
          </div>
        </div>
        <div className="mt-4">
          <SubmittedData
            completedTodoItems={completedTodoItems}
            client={clientDetails}
            multiReportDownload={
              accountSettings.find(setting => setting.data_key === "multi_report_download")?.data_set
            }
          />
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  let allTodoItems = state.Client.client.todoItems;

  return {
    client: state.Client.client.details,
    loadingClientDetails: state.Client.loadingClientDetails,
    todoItems: allTodoItems.client_owned,
    ownedByPract: allTodoItems.practitoner_owned,
    completedTodoItems: allTodoItems.todo_completed,
    accountSettings:state?.AccountSetting?.accountSettingList,
  };
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ToDoTab);