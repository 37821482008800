import { isEmpty } from 'lodash';
import { GET_SUBSCRIPTION, ADD_SUBSCRIPTION, VIEW_SUBSCRIPTION, UPDATE_SUBSCRIPTION, DELETE_SUBSCRIPTION, SUBSCRIPTION_HISTORY, BILLING_STRIPE_URL, ACTIVE_SUBSCRIPTION } from 'store/actions/subscription';

const initialState = {
    isAuthenticated: false,
    subscriptions: [],
    subscription_history: [],
    billing_info:{},
    subscription: {},
    activeSubscription: null,
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        subscriptions:data.body,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      subscription:data.body,
    };
    return stateObj;
  };

  //update 
const viewSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      subscription:data.body,
    };
    return stateObj;
};

  //update 
const updateSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      subscriptions:data.body,
    };
    return stateObj;
};

//delete 
const deleteSubscription = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subscriptions:[],
  };
  return stateObj;
};

//get subscription history
const getSubscriptionHistory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
      subscription_history:data.body,
  };
return stateObj;
};

//stripe connect link
const billingStripeUrl = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
      billing_info:data.body,
  };
return stateObj;
};

//active subscription
const activeSubscription = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
      activeSubscription: !isEmpty(data.body) ? data.body : null,
  };
return stateObj;
};


const Subscription = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_SUBSCRIPTION:
          return getSubscription(state, payload);
        case ADD_SUBSCRIPTION:
            return addSubscription(state, payload);
        case VIEW_SUBSCRIPTION:
          return viewSubscription(state, payload);
        case UPDATE_SUBSCRIPTION:
          return updateSubscription(state, payload);
        case DELETE_SUBSCRIPTION:
          return deleteSubscription(state, payload);
        case SUBSCRIPTION_HISTORY:
          return getSubscriptionHistory(state, payload);
        case BILLING_STRIPE_URL:
          return billingStripeUrl(state, payload);
        case ACTIVE_SUBSCRIPTION:
          return activeSubscription(state, payload);
        default:
        return state;
    };
}
export default Subscription;