//action types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SEND_OTP = "SEND_OTP";

//auth
export function authLogin(user, callback) {
  return {
    type: AUTH_LOGIN,
    payload : {user, callback},
  };
}
export function authRegister(user, callback) {
  return {
    type: AUTH_REGISTER,
    payload : {user, callback},
  };
}

//check auth
export function authCheck() {
    return {
      type: AUTH_CHECK,
    };
}

//auth logout
export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

//forgot password
export function forgotPassword(payload) {
  return {
    type: FORGOT_PASSWORD,
    payload
  };
}

//reset form password
export function resetPasswordForm(payload) {
  return {
    type: RESET_PASSWORD_FORM,
    payload
  };
}

//reset password
export function resetPassword(payload) {
  return {
    type: RESET_PASSWORD,
    payload
  };
}


//account activation
export function activateAccount(payload) {
  return {
    type: ACCOUNT_ACTIVATION,
    payload
  };
}

//change password
export function updatePassword(data, callback) {
  return {
    type: UPDATE_PASSWORD,
    payload : {data, callback},
  };
}
// Send Otp
export function authSendOtp(data, callback) {
  return {
    type: SEND_OTP,
    payload : {data, callback},
  };
}