import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, TAG_LIST,TAG_ADD, TAG_UPDATE, TAG_DETAIL, TAG_DELETE  } from './apiPath'
import { getTags, addTags, viewTags, updateTags, deleteTags} from 'store/actions/tags';

//get
export function getTagsList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${TAG_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getTags(data, callback));
      return data;
    });
}

//add
export function addTagsData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${TAG_ADD}`, value)
    .then((data) => {
      dispatch(addTags(data, callback));
      return data;
    });
}

//view
export function viewTagsData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${TAG_DETAIL}${value}`)
    .then((data) => {
      dispatch(viewTags(data, callback));
      return data;
    });
}

//edit
export function updateTagsData(id, value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${TAG_UPDATE}${id}`, value)
    .then((data) => {
      dispatch(updateTags(data, callback));
      return data;
    });
}

//delete
export function deleteTagsData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${TAG_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteTags(data));
      return data;
    });
}

