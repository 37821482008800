import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { getSymptomBurdenReportV2 } from 'store/services/clientDataReportsService';
import { Link } from 'react-router-dom';
import { useShowLoader } from 'hooks/useShowLoader';

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { Button } from 'react-bootstrap';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "15px",
    paddingBottom: "15px",
    borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "25%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "10px",
    width: "25%",
    padding: "5px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});


const DownloadSBReportLink = ({ client, date, todoId, label, linkType }) => {

  const showLoader = useShowLoader();

  const downloadPDFReport = async () => {
    try {
      showLoader(true);
      const symptomBurdernData = await getNAQv2SymptomBurdenData(todoId, client);
      let totalSymptomBurden = 0;
      symptomBurdernData.potential_conditions.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurden += parseInt(condition.conditionScore)
        }
      })
      symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurden += parseInt(deficiency.conditionScore)
        }
      })

      const doc = 
        <SBReportDoc
          symptomBurdernData={symptomBurdernData}
          totalSymptomBurden={totalSymptomBurden}
          client={client}
          date={date}
        />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Symptom-Burden-Report.pdf');
      showLoader(false);
    } catch(err) {
      console.log("Error downloading pdf report", err);
      showLoader(false);
    }
  }

  return (
    linkType === "button" ? (
      <Button
        className="d-flex align-items-center justify-content-center commonBtn btnSm download-pdf-btn"
        onClick={downloadPDFReport}
      >
        <span className="icn me-2">
          <DownloadIcn />
        </span>
          {label}
      </Button>  
    ):(
      <Link
        onClick={downloadPDFReport}
      >
        {label}
      </Link>
    )
  )
}

export const SBReportDoc = ({ symptomBurdernData, totalSymptomBurden, client, date }) => {
  return (
    <Document>

      {/* PAGE 1 */}
      {/* Potential Conditions */}
      <Page style={styles.page} wrap={false}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Symptom Burden Report
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        <View style={{ borderBottom: "1px solid #ccc", marginBottom: "15px" }}>
          <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Total Symptom Burden: {totalSymptomBurden}
          </Text>
        </View>
        
        <View>
          <View style={{ borderBottom: "1px solid #ccc" }}>
            <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
              Potential Conditions
            </Text>
          </View>
          <Text style={{ paddingTop: "5px" }}>
            Based on the results from the Nutritional Assessment Questionnaire, the following conditions may apply to your client.
          </Text>
        </View>

        {(symptomBurdernData && symptomBurdernData.potential_conditions.length > 0) &&
          <View style={styles.tableContainer}>
            <ConditionsTable items={symptomBurdernData.potential_conditions} />
          </View>
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
      
      {/* PAGE 2 */}
      {/* Potential Nutritional Deficiencies */}
      <Page style={styles.page} wrap={false}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Symptom Burden Report
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>
        
        <View>
          <View style={{ borderBottom: "1px solid #ccc" }}>
            <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
              Potential Nutritional Deficiencies
            </Text>
          </View>
          <Text style={{ paddingTop: "5px" }}>
            Based on the results from the Nutritional Assessment Questionnaire, your client may have the following nutritional deficiencies.
          </Text>
        </View>

        {(symptomBurdernData && symptomBurdernData.potential_nutritional_deficiencies.length > 0) &&
          <View style={styles.tableContainer}>
            <DeficienciesTable items={symptomBurdernData.potential_nutritional_deficiencies} />
          </View>
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getNAQv2SymptomBurdenData = async (todoId, client) => {
  try {
    if (!todoId) {
      throw new Error("Invalid ToDo ID");
    }
    let data = {
      todoId,
      clientId: client.id
    };
    // setIsLoading(true);
    const response = await getSymptomBurdenReportV2(data);
    if (response.success === true) {
      const data = response.body;
      return data;
    }
  } catch (err) {
    // setIsLoading(false);
    console.log(err);
    return false;
  }
}

const ConditionsTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row]} key={i}>
        <Text style={tableStyles.cell}>{row.conditionName}</Text>
        <Text style={tableStyles.cell}>{row.conditionScore}</Text>
        <Text style={tableStyles.cell}>{row.conditionTotalPossible}</Text>
        <Text style={tableStyles.cell}>{Math.round(row.conditionScorePercentage)}%</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell]}>Condition</Text>
        <Text style={tableStyles.headerCell}>Score</Text>
        <Text style={tableStyles.headerCell}>Total Possible</Text>
        <Text style={tableStyles.headerCell}>Percentage</Text>
      </View>
      {rows}
    </View>
  )
};

const DeficienciesTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row]} key={i}>
        <Text style={tableStyles.cell}>{row.conditionName}</Text>
        <Text style={tableStyles.cell}>{row.conditionScore}</Text>
        <Text style={tableStyles.cell}>{row.conditionTotalPossible}</Text>
        <Text style={tableStyles.cell}>{Math.round(row.conditionScorePercentage)}%</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell]}>Deficiency</Text>
        <Text style={tableStyles.headerCell}>Score</Text>
        <Text style={tableStyles.headerCell}>Total Possible</Text>
        <Text style={tableStyles.headerCell}>Percentage</Text>
      </View>
      {rows}
    </View>
  )
};

export default DownloadSBReportLink;