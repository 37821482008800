import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

// icon
import { ReactComponent as OTick } from "../../../../../../../../../Assets/Images/icn/OrangeTick.svg";

const PackageSentSuccessPop = ({ sentSuccess, setSentSuccess }) => {
  const handleSentSuccess = () => {
    setSentSuccess(!sentSuccess);
  };
  return (
    <>
      <Modal
        show={sentSuccess}
        onHide={handleSentSuccess}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Package Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg="12" className="my-2">
                <p className="m-0 fw-sbold pb-1">
                  Package invite was successfully sent! We'll notify you when
                  Tom Bombadill completes purchasing the package
                </p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleSentSuccess}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PackageSentSuccessPop;
