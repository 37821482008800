import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../Assets/Images/Ss_faq1.png";
import s2 from "../../../Assets/Images/Ss_faq2.png";
import s3 from "../../../Assets/Images/Ss_faq3.png";
import { helpEmail } from "common/constants";

const HelpFAQ = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContnet py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Frequently Asked Questions{" "}
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Is Nutri-Q HIPAA Compliant?
                  </h4>
                  <p className="m-0 py-2">
                    Yes! The site is HIPAA Compliant. Also we encrypt all
                    personally identifiable data at rest and during transfer
                    between the database and the website. Overall, any
                    information that your clients or patients enter is just
                    shared between you and them.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    What other languages are available? How do you send a NAQ in
                    Spanish or French?
                  </h4>
                  <p className="m-0 py-2">
                    The NAQ questionnaire is available in Spanish and French. To
                    send a Spanish or French NAQ, all you need to do is assign a
                    NAQ to a Direct Link Client. If the default language in
                    their web browser is set to either Spanish or French then
                    they will automatically see all of the NAQ questions, along
                    with all other text on the site, in the appropriate
                    language.
                  </p>
                  <p className="m-0 py-2">
                    If you haven't used Direct Link Clients before, you can
                    watch this video for an overview: Nutri-Q: Direct Link
                    Clients
                  </p>
                  <p className="m-0 py-2">
                    Our non-English-speaking clients will most likely already
                    have their browsers set to their preferred language. If not,
                    however, there is also a drop-down that allows them to
                    change the language when they first bring up a Direct Link
                    NAQ. (Using this drop-down is a great way for you to see the
                    NAQ in these other languages yourself as well if you don't
                    want to update the default language in your browser.)
                  </p>
                  <p className="m-0 py-2">
                    Currently, these language options just apply to the client's
                    view for Direct Link NAQs. The practitioner site, along with
                    the client site for non-Direct-Link clients, the Initial
                    Interview Questionnaire, and the Mini Questionnaire are all
                    only in English.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    What's the difference between a 'Full Account' client and a
                    'Direct Link' client?
                  </h4>
                  <p className="m-0 py-2">
                    <span className="fw-sbold d-block">
                      Full Account Clients
                    </span>
                    These clients will set up an account on the site by choosing
                    a login email and password. Once they've logged onto the
                    site they will be able to log their foods/feelings and
                    complete any To-Do items that you've assigned to them. They
                    will also have access to their completed To-Do items after
                    they have been submitted.
                  </p>
                  <p className="m-0 py-2">
                    <span className="fw-sbold d-block">
                      Direct Link Clients
                    </span>
                    These clients will not set up a Nutri-Q account. Instead you
                    will send them direct links to the To-Do items that you'd
                    like them to complete. They will not have access to the site
                    other than these To-Do items and they will not need to enter
                    any login information. This option is best for the following
                    situations:
                  </p>
                  <ul className="ps-3 m-0 py-2">
                    <li className="">
                      If you don't want your client to have access to the
                      food/feeling log.
                    </li>
                    <li className="">
                      If you want your clients to go directly to their To-Do
                      item(s) without having to create a login for the site.
                    </li>
                  </ul>
                  <Link className="fw-sbold" to={""}>
                    View the Direct Link Clients article for more details
                  </Link>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    How do I work with multiple people in a family that share
                    one email address?
                  </h4>
                  <p className="m-0 py-2">
                    This isn't a problem at all! You would just set up a
                    separate client account for each member of the family as you
                    normally would, only you would use the same exact email
                    address for each one of them. A signup link will be sent to
                    the email address for each family member. The first link
                    that they click on will have them choose a password as they
                    normally would. When they click on an invite link for
                    another family member it will just confirm that they want to
                    use that same email address (which they do) and then it will
                    have them log in using the same email/password combination
                    that was set up originally. Then every time they log in they
                    will just choose which profile they want to use (which
                    family member they are logging in for) and they can always
                    switch between the profiles by clicking on the icon in the
                    top right corner and choosing 'Switch Profile'.
                  </p>
                  <p className="m-0 py-2">
                    <span className="fw-sbold">
                      One important thing is that if your client is currently
                      logged into Nutri-Q then they need to log out before they
                      click on the new invite link from you. (They can log out
                      by clicking on the icon in the top right corner and then
                      choosing 'Log Out' in the drop down.)
                    </span>
                  </p>
                  <p className="m-0 py-2">
                    Note: This is all in regards to Active Clients that log into
                    Nutri-Q. Direct Link Clients simply receive emails with
                    links to To-Do Items so they never set up logins for the
                    site. There is also no issue at all with using the same
                    email for multiple Direct Link Clients.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    My client already has a Nutri-Q account with a different
                    practitioner. What should I do?
                  </h4>
                  <p className="m-0 py-2">
                    This isn't a problem at all! You would just set up an
                    account for this client as you normally would and it's
                    perfectly fine to use the exact same email that they used
                    with the other practitioner. A signup link will be sent to
                    your client as it normally would. When they click on your
                    invite link and enter the email that corresponds to their
                    existing Nutri-Q account the system will see that they
                    already have a client account tied to another practitioner.
                    It will then link their existing account to you as well by
                    creating a new profile. Then the client will be able to
                    toggle between their profiles on the site (one profile for
                    each practitioner that they're working with). And every time
                    they log in they will just choose which profile/practitioner
                    they want to see. They can always switch between the
                    profiles by clicking on the icon in the top right corner and
                    choosing 'Switch Profile'.
                  </p>
                  <p className="m-0 py-2">
                    <span className="fw-sbold">
                      One important thing is that if your client is currently
                      logged into Nutri-Q then they need to log out before they
                      click on the new invite link from you. (They can log out
                      by clicking on the icon in the top right corner and then
                      choosing 'Log Out' in the drop down.)
                    </span>
                  </p>
                  <p className="m-0 py-2">
                    Note: This is all in regards to Active Clients that log into
                    Nutri-Q. Direct Link Clients simply receive emails with
                    links to To-Do Items so they never set up logins for the
                    site. There is also no issue at all with Direct Link Clients
                    working with multiple Nutri-Q practitioners.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    What are bounced emails & how do I fix them?
                  </h4>
                  <p className="m-0 py-2">
                    Bounced emails are emails that we tried to send but that
                    were rejected by a mail server. This usually happens due to
                    an incorrect email address that doesn't exist. When an email
                    bounces we will notify you about it in the 'Recent Events'
                    module on the dashboard:
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    In the example above, the site tried to send an email to
                    'badaddress@hmail.com' and it bounced. To fix this, first
                    locate the client with this email. To do this, you'll go to
                    the 'My Clients' tab, click on 'Reset all filters' at the
                    bottom (to make sure you're are searching across all of your
                    clients) and then put the email address in the search box:
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s2} alt="" className="img-fluid" />
                  </div>
                  <p className="m-0 py-2">
                    Now you would update this client's email with the correct
                    email address. Updating a client's email is different
                    depending on the Client Type:
                  </p>
                  <p className="m-0 py-2">
                    <span className="fw-sbold d-block">
                      Unlinked & Direct Link Clients
                    </span>
                    If the client is an Unlinked or Direct Link client, you can
                    update their email by clicking on their name and then
                    clicking on the 'Info & Settings' tab. Then under the
                    'Client Information' section click the 'Edit Client
                    Information' button and you can change their email address.
                  </p>
                  <p className="m-0 py-2">
                    <span className="fw-sbold d-block">Active Clients</span>
                    For Active Clients, we send emails to the login email that
                    the client has chosen, so it's not something that the
                    Practitioner can update. You'll need to reach out to your
                    client and let them know they need to update their login
                    email. (There is most likely a typo in the login email that
                    they have chosen.) They can update their login email by
                    logging into the site, clicking on the icon in the top right
                    corner, and then choosing 'My Account'. Under 'Account
                    Information' they can click on the 'Update log in email or
                    password' link to update their login email.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    I use both Practice Better and Nutri-Q. How can I link the
                    two platforms?
                  </h4>
                  <p className="m-0 py-2">
                    If you have accounts with both Nutri-Q and Practice Better,
                    you can now link your accounts so that you have a more
                    seamless experience for you and your clients! Once you link
                    your Nutri-Q and Practice Better accounts, you'll be able to
                    assign NAQs to your clients from within Practice Better. And
                    when your client then completes the NAQ, the reports will
                    automatically be uploaded into Practice Better.
                  </p>
                  <Link className="fw-sbold" to={""}>
                    View the Practice Better Integration Guide
                  </Link>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    I'm a course mentor or group leader for the NTA. When will I
                    receive my free Nutri-Q benefit?
                  </h4>
                  <p className="m-0 py-2">
                    Once you complete your current course mentor or group leader
                    class and commitments, the NTA will provide us with a list
                    of all of the course mentors and we'll automatically add a
                    free year to your account at that time. (It can take about a
                    month after your class finishes for us to get the list since
                    classes will be completing at slightly different times.)
                    We'll be sending you an email to notify you after your free
                    year has been added.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    How do I create and assign a Disclaimer form to my client?
                  </h4>
                  <p className="m-0 py-2">
                    Our general recommendation is for practitioners to create
                    their own disclaimer form under the "My Forms" section and
                    then you'll be able to customize it and assign it to your
                    clients as a To-Do item.
                  </p>
                  <p className="m-0 py-2">
                    To do this, you would create a new custom form by clicking
                    on "Docs & Forms" in the main navigation and then choose the
                    "My Forms" option, pick "Create a new form" then "Create a
                    new blank form". Give the new form a title and click "Insert
                    a new question" and select "Disclaimer" as the question type
                    (see the image below). Then you can paste in the text you
                    see fit from the NTA's example disclaimer (
                    https://docs.wellnesstools.io/nutriqResources/disclaimer.pdf)
                    or a different disclaimer you feel more comfortable with.
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s3} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    How do I check when my trial ends or when my next payment
                    is?
                  </h4>
                  <p className="m-0 py-2">
                    You can always check on any account/subscription related
                    information on your "My Account" page. To find this page,
                    click on the icon in the top right corner and choose "My
                    Account". Then choose the "Subscription Information" link.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Is the Nutri-Q site mobile friendly?
                  </h4>
                  <p className="m-0 py-2">
                    Yes! We don't have an app right now, but the site is mobile
                    friendly so you can easily bring it up on your phone or
                    tablet.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    How do I change my login email?
                  </h4>
                  <p className="m-0 py-2">
                    To change your login email use the following steps:
                  </p>
                  <ol className="m-0 ps-3 py-2 m-0">
                    <li className="">
                      First click on the icon, which is located to the right of
                      the menu headers.
                    </li>
                    <li className="">Then choose the "My Account" option.</li>
                    <li className="">
                      You'll already be in the "Account Information" section,
                      which is the one you'll want.
                    </li>
                    <li className="">
                      Choose the "Update login email or password" link located
                      at the bottom of the "Account Information" section.
                    </li>
                    <li className="">
                      You can then update the email that you use when logging
                      into the site in the "Update Login Email" section.
                    </li>
                  </ol>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Why does the symptom burden chart sometimes go above the top
                    of the chart?
                  </h4>
                  <p className="m-0 py-2">
                    We've added functionality to provide a sense of how far
                    above the high priority section your client's value falls.
                    We now extend above that top line with an additional light
                    gray bar and an exclamation point. This way, even if your
                    clients symptoms are still numerous to the point where they
                    are off the chart, you will still be able to visually see
                    and show them their improvement and changes over time.
                  </p>
                  <p className="m-0 py-2">
                    Please note that we did not use the same scaling above the
                    chart that we did within the chart. In some cases the
                    possible score is so far above the chart that the chart
                    would potentially be very difficult to view and print. We
                    settled for a compromise where the area above the chart is a
                    scaled down representation of the extended value.
                  </p>
                </div>
                <div className="py-2 my-2">
                  <h4 class="m-0 fw-bold d-flex align-items-center pb-2">
                    This is great! But I really wish the site also did these
                    additional things or was changed in this way.
                  </h4>
                  <p className="m-0 py-2">
                    We love hearing feedback! There's a Feedback flyout in the
                    bottom left of the site that you can use and you can also
                    always email us at{" "}
                    <span className="fw-sbold">{helpEmail}</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpFAQ;
