import {postData, getData} from 'helpers/apiHelper'
import { MAIN_PATH, LOGIN,APP_PATH,REGISTER, LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD, RESET_PASSWORD_FORM, CHANGE_PASSWORD, ACCOUNT_ACTIVATION, SEND_OTP, RESEND_OTP } from './apiPath'
import { authLogin, authRegister, authLogout, updatePassword, authSendOtp} from 'store/actions/auth';
import { profile } from './profileService';

//login
export function login(credentials, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${LOGIN}`, credentials)
    .then((data) => {
      dispatch(authLogin(data, callback));
      return data;
    });
}

//register
export function register_(credentials, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${REGISTER}`, credentials)
    .then((data) => {
      dispatch(authRegister(data, callback));
      return data;
    });
}

//logout
export function logout(credentials) {
  return (dispatch) => postData(`${MAIN_PATH}${LOGOUT}`, credentials)
    .then((data) => {
      dispatch(authLogout(data));
      return data;
    });
}

//forgot passord
export function forgotPassword(value) {
  return (dispatch) => postData(`${MAIN_PATH}${FORGOT_PASSWORD}`, value)
    .then((data) => {
      return data;
    });
}


//forgot passord
export function resetPasswordForm(value) {
  return (dispatch) => getData(`${MAIN_PATH}${RESET_PASSWORD_FORM}`, value)
    .then((data) => {
      return data;
    });
}


//forgot passord
export function resetPassword(token, value) {
  console.log(token, "token")
  return (dispatch) => postData(`${MAIN_PATH}${RESET_PASSWORD}/${token}`, value)
    .then((data) => {
      return data;
    });
}


//account activation
export function activateAccount(value) {
  return (dispatch) => postData(`${APP_PATH}${ACCOUNT_ACTIVATION}`, value)
    .then((data) => {
      return data;
    });
}

//change password
export function changePassword(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CHANGE_PASSWORD}`, value)
    .then((data) => {
      dispatch(updatePassword(data, callback));
      dispatch(profile(data, callback))
      return data;
    });
}

// Send otp
export function sendOtp(values, action, callback) {
  let { email } = values;
  let url = `${MAIN_PATH}${SEND_OTP}`;
  // let url = `http://localhost:3000/dev/auth/otp/send`;
  return (dispatch) => postData(url, { email, action })
    .then((data) => {
      dispatch(authSendOtp(values, callback));
      return data;
    });
}

// Resend otp
export function resendOtp(email, action, callback) {
  let url = `${MAIN_PATH}${RESEND_OTP}`;
  // let url = `http://localhost:3000/dev/auth/otp/resend`;
  return postData(url, { email, action });
}