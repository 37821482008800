import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

// icon
import { ReactComponent as OTick } from "../../../../../../../../../Assets/Images/icn/OrangeTick.svg";

const InvitationResentSuccess = ({ showPopup, closePopup, isResendIntent }) => {
  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isResendIntent ? "Resend Invite" : "Invite Client"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col lg="12" className="my-2">
                <p className="m-0 fw-sbold pb-1">
                    The invite has been sent! You can now close this window.
                </p>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvitationResentSuccess;
