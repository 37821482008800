import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Dropdown, Row } from "react-bootstrap";
import { connect } from "react-redux";
import ViewInvitationLink from "./Components/Modals/ViewInvitationLinkPopup";
import ResendPackageInvitePopup from "./Components/Modals/ResendInvitePopup";
import {
  cancelPackageInvitation,
  getPackageInvitationsList,
  removePackageInvitation,
  resendPackageInvitation,
} from "store/services/clientService";
import { Link, useParams } from "react-router-dom";
import SendInviteSuccessPopup from "./Components/Modals/SendInviteSuccessPopup";
import CancelPackageInvitePopup from "./Components/Modals/CancelPackageInvitePopup";
import RemovePackageInvitePopup from "./Components/Modals/RemovePackageInvitePopup";
import SendPackageInvitePop from "./Components/Modals/SendPackageInvitePop";
import PackageLinkPopup from "./Components/Modals/PackageInviteLinkPopup";
import { getPackage_, sendPackage } from "store/services/packageService";
import * as actions from "store/actions";
import * as Path from "Routes/paths";
import { FULL_NAME } from "helpers/common";

const ClientPackageInvitations = ({ dispatch, invitations, packages, client }) => {
  const { clientId } = useParams();
  const [showInviteLinkPopup, setShowInviteLinkPopup] = useState(false);
  const [showResendInvitePopup, setShowResendInvitePopup] = useState(false);
  const [showPackageInviteSuccessPopup, setShowPackageInviteSuccessPopup] = useState(false);
  const [showCancelInvitePopup, setShowCancelInvitePopup] = useState(false);
  const [showRemoveInvitePopup, setShowRemoveInvitePopup] = useState(false);
  const [showPackageInvitePopup, setShowPackageInvitePopup] = useState(false);
  const [showPackageLinkPopup, setShowPackageLinkPopup] = useState(false);
  const [currentPackageInvitation, setCurrentPackageInvitation] =
    useState(null);
  const [generatedPackageLink, setGeneratedPackageLink] = useState(null);

  useEffect(() => {
    fetchClientPackages();
    getClientPackageInvitations();
  }, []);

  const openPackageInviteLinkPopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowInviteLinkPopup(true);
  };

  const openResendInvitePopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowResendInvitePopup(true);
  };

  const openCancelInvitePopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowCancelInvitePopup(true);
  };

  const openRemoveInvitePopup = (invitation) => {
    setCurrentPackageInvitation(invitation);
    setShowRemoveInvitePopup(true);
  };

  const fetchClientPackages = async () => {
    try {
      await dispatch(getPackage_());
    } catch (err) {
      console.log(err, "==============================");
    }
  };

  const resendInvite = async (setSendingInvite) => {
    try {
      let data = {
        invitationId: currentPackageInvitation.id,
        values: {
          client_id: clientId,
        },
      };
      setSendingInvite(true);
      await resendPackageInvitation(data);
      setSendingInvite(false);
      setShowResendInvitePopup(false);
      setShowPackageInviteSuccessPopup(true);
    } catch (err) {
      console.log(err, "==============================");
      setSendingInvite(false);
    }
  };

  const cancelInvite = async (setCancelingInvite) => {
    try {
      let data = {
        invitationId: currentPackageInvitation.id,
        values: {
          client_id: clientId,
        },
      };
      setCancelingInvite(true);
      await dispatch(cancelPackageInvitation(data));
      setCancelingInvite(false);
      setShowCancelInvitePopup(false);
    } catch (err) {
      console.log(err, "==============================");
      setCancelingInvite(false);
    }
  };

  const removeInvite = async (setRemovingInvite) => {
    try {
      let data = {
        invitationId: currentPackageInvitation.id,
        values: {
          client_id: clientId,
        },
      };
      setRemovingInvite(true);
      await dispatch(removePackageInvitation(data));
      setRemovingInvite(false);
      setShowRemoveInvitePopup(false);
    } catch (err) {
      console.log(err, "==============================");
      setRemovingInvite(false);
    }
  };

  const sendPackageToClient = async (packageId, dontSendEmail, setSending) => {
    try {
      let data = {
        packageId,
        values: {
          client_id: clientId,
          do_not_package_email_invite: dontSendEmail,
        },
      };
      setSending(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await sendPackage(data);
      dispatch(actions.persist_store({ loader: false }));
      setSending(false);
      setShowPackageInvitePopup(false);
      if (dontSendEmail && res.success === true && res.body.url) {
        setGeneratedPackageLink(res.body.url);
        setShowPackageLinkPopup(true);
      } else if (res.success === true) {
        setShowPackageInviteSuccessPopup(true);
      }
      getClientPackageInvitations();
    } catch (err) {
      console.log("Error sending package to client: ", err);
      setSending(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const getClientPackageInvitations = async () => {
    try {
      let data = {
        clientId,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPackageInvitationsList(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err, "==============================");
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  return (
    <>
      <ViewInvitationLink
        showPopup={showInviteLinkPopup}
        setShowPopup={setShowInviteLinkPopup}
        invitation={currentPackageInvitation}
        client={client}
      />
      <ResendPackageInvitePopup
        showPopup={showResendInvitePopup}
        setShowPopup={setShowResendInvitePopup}
        handleResendInvite={resendInvite}
      />
      <SendInviteSuccessPopup
        showPopup={showPackageInviteSuccessPopup}
        setShowPopup={setShowPackageInviteSuccessPopup}
        client={client}
      />
      <CancelPackageInvitePopup
        showPopup={showCancelInvitePopup}
        setShowPopup={setShowCancelInvitePopup}
        handleCancelInvite={cancelInvite}
      />
      <RemovePackageInvitePopup
        showPopup={showRemoveInvitePopup}
        setShowPopup={setShowRemoveInvitePopup}
        handleRemoveInvite={removeInvite}
      />
      <SendPackageInvitePop
        showPopup={showPackageInvitePopup}
        setShowPopup={setShowPackageInvitePopup}
        handleSendPackage={sendPackageToClient}
        packages={packages}
        client={client}
      />
      <PackageLinkPopup
        showPopup={showPackageLinkPopup}
        setShowPopup={setShowPackageLinkPopup}
        packageLink={generatedPackageLink}
        client={client}
      />

      <Accordion className="packages-accordion">
        { (invitations && invitations.length > 0) ?
          invitations.map((invitation) => {
            return (
              <Accordion.Item
                className="border-0 my-2 bg-transparent"
                eventKey={invitation.id}
              >
                <div className="d-flex align-items-center justify-content-between gap-10">
                  <Accordion.Header className="d-flex justify-content-between w-100">
                    <span className="fw-sbold">{invitation.package_name}</span>
                    <span className="fw-sbold">
                      {invitation.status == "invited" && "Invited"}
                      {invitation.status == "canceled" && "Invite Canceled"}
                      {invitation.status == "complete" && "Active"}
                    </span>
                  </Accordion.Header>
                  <Dropdown className="">
                    <Dropdown.Toggle
                      variant="transparent"
                      className="border-0 p-0 noAfter d-flex align-items-center justify-content-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="10"
                        viewBox="0 0 4 10"
                        fill="none"
                      >
                        <path
                          d="M2 9.2C2.66274 9.2 3.2 8.66274 3.2 8C3.2 7.33726 2.66274 6.8 2 6.8C1.33726 6.8 0.800003 7.33726 0.800003 8C0.800003 8.66274 1.33726 9.2 2 9.2Z"
                          fill="#111"
                        />
                        <path
                          d="M2 6.2C2.66274 6.2 3.2 5.66274 3.2 5C3.2 4.33726 2.66274 3.8 2 3.8C1.33726 3.8 0.800003 4.33726 0.800003 5C0.800003 5.66274 1.33726 6.2 2 6.2Z"
                          fill="#111"
                        />
                        <path
                          d="M2 3.2C2.66274 3.2 3.2 2.66274 3.2 2C3.2 1.33726 2.66274 0.800003 2 0.800003C1.33726 0.800003 0.800003 1.33726 0.800003 2C0.800003 2.66274 1.33726 3.2 2 3.2Z"
                          fill="#111"
                        />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {invitation && invitation.status == "invited" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => openResendInvitePopup(invitation)}
                          >
                            Resend Invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              openPackageInviteLinkPopup(invitation)
                            }
                          >
                            View Invite Link
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => openCancelInvitePopup(invitation)}
                          >
                            Cancel Invite
                          </Dropdown.Item>
                        </>
                          
                      ) : (
                        <Dropdown.Item
                          onClick={() => openRemoveInvitePopup(invitation)}
                        >
                          Remove
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <Accordion.Body className="p-3 rounded">
                  <ul className="ps-3 mb-0">
                    <li className="fw-sbold">
                      {`Invited on ${moment(invitation.created_at).format(
                        "MMM DD, YYYY h:mm A"
                      )}`}
                    </li>
                    {invitation && invitation.status == "canceled" && (
                      <li className="fw-sbold">
                        {`Invite canceled on ${moment(
                          invitation.cancel_at
                        ).format("MMM DD, YYYY h:mm A")}`}
                      </li>
                    )}
                    {invitation && invitation.status == "complete" && (
                      <li className="fw-sbold">
                        {`Invite completed on ${moment(
                          invitation.complete_at
                        ).format("MMM DD, YYYY h:mm A")}`}
                      </li>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            );
          })
          :
          <>
            { packages && packages.length > 0 ?
              <p className="m-0 text-dark">
                Invite {FULL_NAME(client.first_name, client.last_name)} to purchase a package.
              </p>
              :
              <p className="m-0 text-dark">
                <Link
                  className="underline"
                  to={Path.myAccount}
                >
                  Jump to the My Packages page
                </Link>{" "}
                to create your first client package!
              </p>
            }
          </>
        }
      </Accordion>
      <div className="btnWrp mt-2">
        <Button
          onClick={() => setShowPackageInvitePopup(true)}
          className="d-flex align-items-center justify-content-center commonBtn  btnSm"
        >
          Send Package Invite
        </Button>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client?.client?.details || {},
    invitations: state.Client.client.packageInvitations,
    packages: state.Package.packages,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ClientPackageInvitations);
