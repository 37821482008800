import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import { FULL_NAME, UC_FIRST } from "helpers/common";
import { useParams } from "react-router-dom";
import moment from "moment";
import SingleRecommendation from "./Components/SingleFullscriptRecommendation";
import * as actions from "store/actions";


const FullscriptRecommendations = ({
  dispatch,
  fullscriptConnection,
  fullscriptRecommendations,
  getFullscriptRecommendations,
  getRecommendationDetails,
}) => {
  // const [fullscriptRecommendations, setFullscriptRecommendations] = useState(null);
  const { clientId } = useParams();

  useEffect(() => {
    if(fullscriptConnection) {
      getFullscriptRecommendations();
    }
  }, [fullscriptConnection]);

  return (
    <>
      <Accordion className="my-3 ColoredAcorrdion">
        {fullscriptRecommendations && fullscriptRecommendations.length > 0 &&
          fullscriptRecommendations.map(recommendation => {
            return (
              <SingleRecommendation
                key={recommendation.id}
                recommendation={recommendation}
                getDetails={getRecommendationDetails}
                getRecommendations={getFullscriptRecommendations}
              />
            )
          })
        }
      </Accordion>
    </>
  )
}



const mapStateToPros = (state) => {
  let fullscriptConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "full_script");
  return {
    fullscriptConnection,
    fullscriptRecommendations: state.Client.client.fullscriptRecommendations,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FullscriptRecommendations);