import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";

const AddTagPop = ({ tagPop, setTagPop, fields, submitData_, onChangeColorHandleChange, handleChange, colorCode, sending, sateCurrentValue, setSateCurrentValue, deleteTags_}) => {
  const handleAddTag = () => {
    setTagPop(!tagPop);
    setSateCurrentValue(null)
  };
  return (
    <>
         <Form>
            <Modal
              show={tagPop}
              onHide={handleAddTag}
              backdrop="static"
              keyboard={false}
              centered
              className="FormPop"
            >
              <Modal.Header closeButton>
                <Modal.Title>My Tags</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="py-2">
                  <label htmlFor="" className="form-label m-0 theme-clr fw-sbold">
                    Tag Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={handleChange("name")}
                    placeholder="New Tag Name"
                    value={fields.name}
                    required
                  />
                </div>
                <SketchPicker className="W-100" color={colorCode} onChange={(color) => onChangeColorHandleChange(color)} />
              </Modal.Body>
              <Modal.Footer className="FormbtnWrpper">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={handleAddTag}
                >
                  Cancel
                </Button>
                {sateCurrentValue &&
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={() => deleteTags_(sateCurrentValue?.id)}
                  >
                    Delete
                  </Button>
                }
                <Button type="submit" onClick={submitData_} className="d-flex align-items-center justify-content-center commonBtn btnSm">
                  {sending ? 'Wait...' : 'Save'}
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(AddTagPop);
