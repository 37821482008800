import * as Yup from 'yup';
import { OTP_LENGTH } from 'common/constants';

const validation = Yup.object({
    otp: Yup.number()
        .required("Please enter OTP")
        .test(
            "len",
            `OTP must be of ${OTP_LENGTH} digits`,
            (val) => val && val.toString().length === OTP_LENGTH
        )
});

export default validation;