import { FULL_NAME, UC_FIRST, formatAppointmentDateTimezone, isPastDateTime, showDateInUtc } from "helpers/common";
import moment from "moment";
import React, {useState, useEffect} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import {utcTimestamp_} from 'helpers/common'
import * as actions from "store/actions";
import { DELETE_ALERT, SUCCESS_MESSAGE } from "common/constantMessage";
import ReschedulePopup from "./ReschedulePopup"
import {
  cancelAppointment_,
  appointmentTypeView_,
  updateAppointment,
  getAppointmentBookingList,
  unlinkClient,
  appointmentClientList_,
  linkClient,
} from "store/services/appointmentService";
import { Link } from "react-router-dom";
import ConfirmUnlinkClientPopup from "./ConfirmUnlinkClientPopup";
import LinkClientPopup from "./LinkClientPopup";
import { myClientDetails } from "Routes/paths";

const AppointmentDetailPopup = ({
  showPopup,
  setShowPopup,
  details,
  dispatch,
  setBookApp,
  schedulerTimezone
}) => {
  // const schedulerTimezone = "Asia/Kolkata";
  const [reschedulePop, setReschedulePop] = useState(false);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [currentTypeData, setCurrentTypeData] = useState(null);
  const [stepRes, setStepRes] = useState(0);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [clientList, setClientList] = useState(false);
  const [currentClientData, setCurrentClientData] = useState(null);
  const [showUnlinkClientPopup, setShowUnlinkClientPopup] = useState(false);
  const [showLinkClientPopup, setShowLinkClientPopup] = useState(false);
  useEffect(() => {
    getClientList()
  }, []);

  useEffect(() => {
      getClientDetails();
  }, [details, clientList])

  async function getClientList() {
    try{
      let res = await dispatch(appointmentClientList_());
      setClientList(res?.body)
    }catch(err) {
      console.log(err);
    }
  }

  async function getClientDetails() {
    try {
      if(!clientList || !details || !details.client_id) {
        return;
      }
      for(let client of clientList) {
        if(client.id == details.client_id) {
          setCurrentClientData(client);
        }
      }
    } catch(err) {
      console.log("Error getting client details", err);
    }
  }

  const closePopup = () => {
    setShowPopup(false);
  }

  const cancelAppointmentfunc_ = async (detail) => {
    try{
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          dispatch(actions.persist_store({ loader: true }));
          await dispatch(cancelAppointment_(detail?.id))
          setCancelStatus(true)
          dispatch(actions.persist_store({ loader: false }));
        }
      });
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  }

  const closePopup_ = () => {
    setCancelStatus(false);
    setBookApp(true)
    setShowPopup(false);
  }

  const editAppointment = async (data) => {
    try{
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(appointmentTypeView_(data?.appointment_type));
      setReschedulePop(true)
      setShowPopup(false);
      setCurrentTypeData(res?.body);
      setStepRes(1)
      setCurrentDetail(data)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  }

  const markAppointmentAsComplete = async (data) => {
    try {
      setIsUpdating(true);
      dispatch(actions.persist_store({ loader: true }));
      let updateData = {
        status: "completed"
      }
      let res = await updateAppointment(data?.id, updateData);
      if(res.success === true) {
        SUCCESS_MESSAGE("Appointment marked as complete");
      }
      await dispatch(getAppointmentBookingList());
      closePopup();
      dispatch(actions.persist_store({ loader: false }));
      setIsUpdating(false);
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      setIsUpdating(false);
      console.log(err, "==============================");
    }
  }

  const unlinkClientFromAppt = async (setUnlinking) => {
    try {
      setUnlinking(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await unlinkClient(details.id);
      if(res.success === true) {
        SUCCESS_MESSAGE("Client unlinked");
        setShowUnlinkClientPopup(false);
      }
      await dispatch(getAppointmentBookingList());
      dispatch(actions.persist_store({ loader: false }));
      setUnlinking(false);
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      setUnlinking(false);
      console.log(err, "==============================");
    }
  }

  const linkClientToAppt = async (clientId, setLinking) => {
    try {
      setLinking(true);
      dispatch(actions.persist_store({ loader: true }));
      let data = {
        id: details.id,
        value: {
          client_id: clientId
        }
      };
      let res = await linkClient(data);
      if(res.success === true) {
        SUCCESS_MESSAGE("Client linked");
        setShowLinkClientPopup(false);
      }
      await dispatch(getAppointmentBookingList());
      dispatch(actions.persist_store({ loader: false }));
      setLinking(false);
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      setLinking(false);
      console.log(err, "==============================");
    }
  }

  if (!details) {
    return "";
  }

  return (
    <>
      <ReschedulePopup 
        reschedulePop={reschedulePop}
        setReschedulePop={setReschedulePop}
        setStepRes={setStepRes}
        stepRes={stepRes}
        setShowPopup={setShowPopup}
        currentDetail={currentDetail}
        setCurrentDetail={setCurrentDetail}
        setCurrentTypeData={setCurrentTypeData}
        currentTypeData={currentTypeData}
        setBookApp={setBookApp}
      />
      <ConfirmUnlinkClientPopup
        client={currentClientData}
        handleUnlink={unlinkClientFromAppt}
        showParentPopup={setShowPopup}
        showPopup={showUnlinkClientPopup}
        closePopup={() => {
          setShowUnlinkClientPopup(false);
        }}
      />
      <LinkClientPopup
        details={details}
        clientList={clientList}
        handleLink={linkClientToAppt}
        showParentPopup={setShowPopup}
        showPopup={showLinkClientPopup}
        schedulerTimezone={schedulerTimezone}
        closePopup={() => {
          setShowLinkClientPopup(false);
        }}
      />
      <Form>
        <Modal
          show={showPopup}
          onHide={closePopup}
          backdrop="static"
          keyboard={false}
          centered
          className="FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title>{cancelStatus ? 'Success' : 'Appointment Details'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {cancelStatus ? (
                <div className="py-2">
                   <h5>This appointement has been successfully canceled and your client has been notified.</h5>
                </div>
            ) : (
              <div className="py-2">
                <h5>{details.title}</h5>
                <p className="fw-bold">
                  {/* {moment(details.start_time).format("dddd, MMMM d")} {` · `}
                  {moment(details.start_time).format("H:mm A")} - {moment(details.end_time).format("H:mm A")} */}
                  {formatAppointmentDateTimezone(details?.start_time, schedulerTimezone)},{" "}
                  {formatAppointmentDateTimezone(details?.start_time, schedulerTimezone, "h:mm A")} -{" "}
                  {formatAppointmentDateTimezone(details?.end_time, schedulerTimezone, "h:mm A")} 
                </p>
                <p>Name: <span>{FULL_NAME(details.first_name, details.last_name)}</span></p>
                {details.linked_first_name && details.client_id && (
                  <p>
                    Linked Name:{" "}
                    <Link
                      to={myClientDetails.replace(":clientId", details.client_id)}
                    >
                      {FULL_NAME(details.linked_first_name, details.linked_last_name)}
                    </Link>
                  </p>
                )}
                <p>Where: <span>{UC_FIRST(details.location.replace("_", " "))}</span></p>
                <p>Email: <span>{details.email}</span></p>
                <p>Phone #: <span>{details.phone}</span></p>
                <p>Client Time Zone: <span>{details.client_appt_tz}</span></p>
                <p>
                  Booked On:{` `}
                  <span>
                    {showDateInUtc(details?.booking_date, "dddd MMMM DD, YYYY")}
                  </span>
                </p>
                {details.uuid && 
                  <p>Confirmation #: <span>{details.uuid}</span></p>
                }
                {(details?.location === "video_zoom" && details?.video_url) && (
                  <p>
                    Video Link:{" "}
                    <a
                      href={details?.video_url}
                      target="_blank"
                    >
                      {details?.video_url}
                    </a>
                  </p>
                )}
                {/* {details.status === "completed" && (
                  <p>Status: <span className="fw-bold" style={{ color: "#198754" }}>{UC_FIRST(details.status)}</span></p>
                )} */}
                {details && details.client_id ?
                  <Link
                    className="underline"
                    onClick={() => {
                      setShowPopup(false);
                      setShowUnlinkClientPopup(true);
                    }}
                  >
                    Unlink client from appointment
                  </Link>
                  :
                  <Link
                  onClick={() => {
                    setShowPopup(false);
                    setShowLinkClientPopup(true);
                  }}
                  >
                    Link appointment to existing client
                  </Link>
                }
            </div>
            )}
            
          </Modal.Body>
          
          <Modal.Footer className="FormbtnWrpper d-flex justify-content-between">
          {!cancelStatus ? (
            <>
            <div className="d-flex gap-10">
              {/* {details.status != "completed" && isPastDateTime(details.start_time) &&
                <Button
                  className="d-flex align-items-center justify-content-center btnSm btn-success"
                  disabled={isUpdating}
                  onClick={() => {
                    markAppointmentAsComplete(details);
                  }}
                >
                  Mark as Complete
                </Button>
              } */}
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => editAppointment(details)}
              >
                Edit
              </Button>
              <Button
                className="d-flex align-items-center justify-content-center btnSm btn-danger"
                // onClick={closePopup}
                onClick={() =>
                  cancelAppointmentfunc_(details)
                }
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={closePopup}
              >
                Close
              </Button>
            </div>
            </>
          ) : (
            <div>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={closePopup_}
              >
                Close
              </Button>
            </div>
          )}
            
          </Modal.Footer>
        </Modal>
      </Form >
      
    </>
  );
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AppointmentDetailPopup);
