import moment from "moment-timezone";
import Maintenance from "pages/Maintenance";
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBannerSettings, getBasicSettings } from "store/services/miscService";
// import Maintenance from './Maintenance';

const WrappedApp = (WrappedComponent) => {
  return (props) => {
    const dispatch = useDispatch();
    const maintenanceConfig = useSelector((state) => state.Misc.basicSettings?.maintenance_schedule?.practitioner);

    useEffect(() => {
      async function init() {
        try {
          await Promise.all([
            dispatch(getBasicSettings()),
            dispatch(getBannerSettings())
          ])
        } catch(err) {
          console.error("Error fetching data WrappedApp:", err);
        }
      }

      init();
    }, []);
    
    if (
      maintenanceConfig?.enabled === "yes" &&
      maintenanceConfig?.start_date &&
      maintenanceConfig?.end_date &&
      moment().isBefore(moment(maintenanceConfig?.end_date)) &&
      moment().isAfter(moment(maintenanceConfig?.start_date))
    ) {
      return <Maintenance config={maintenanceConfig}/>;
    }

    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default WrappedApp;