import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const SpecificNotes = ({ notes, setNotesValue }) => {

  const handleChange = (fieldName, event) => {
    const notesData = {...notes};
    notesData[fieldName] = event.target.value;
    setNotesValue(notesData);
  }

  return (
    <div className="py-2">
      <Form autoComplete="off">
        <Row>
          <Col lg="12" className="my-2">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Indicated Nutrients
            </label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Add your notes here"
              autoComplete="off"
              onChange={(e) => {
                handleChange("Indicated Nutrients", e)
              }}
              value={notes["Indicated Nutrients"]}
            />
          </Col>
          <Col lg="12" className="my-2">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Targeted Supplements
            </label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Add your notes here"
              autoComplete="off"
              onChange={(e) => {
                handleChange("Targeted Supplements", e)
              }}
              value={notes["Targeted Supplements"]}
            />
          </Col>
          <Col lg="12" className="my-2">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Food Support Sources
            </label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Add your notes here"
              autoComplete="off"
              onChange={(e) => {
                handleChange("Food Support Sources", e)
              }}
              value={notes["Food Support Sources"]}
            />
          </Col>
          <Col lg="12" className="my-2">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Lifestyle Support Ideas
            </label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Add your notes here"
              autoComplete="off"
              onChange={(e) => {
                handleChange("Lifestyle Support Ideas", e)
              }}
              value={notes["Lifestyle Support Ideas"]}
            />
          </Col>
          <Col lg="12" className="my-2">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Related Client Concern(s)
            </label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Add your notes here"
              autoComplete="off"
              onChange={(e) => {
                handleChange("Related Client Concern(s)", e)
              }}
              value={notes["Related Client Concern(s)"]}
            />
          </Col>
          <Col lg="12" className="my-2">
            <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
              Foundational Connections
            </label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Add your notes here"
              autoComplete="off"
              onChange={(e) => {
                handleChange("Foundational Connections", e)
              }}
              value={notes["Foundational Connections"]}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SpecificNotes;