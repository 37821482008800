import React, { useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import TableData from "./Component/TableData/TableData";
import Filter from "./Component/Filter/Filter";

// icn
import { ReactComponent as Wfilter } from "../../../Assets/Images/icn/filter.svg";
import { getClientList, clientPagination_ , assignTagsToClient_, removeTagsFromClient} from 'store/services/clientService';
import * as actions from 'store/actions';
import { Field } from "formik";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import { debounce } from "lodash";

const MyClients = ({itemsCountPerPage, clientListing, dispatch, tagList, clientTagCounts}) => {
  const location = useLocation();
  const [tagPop, setTagPop] = useState("");
  const [currentClient, setCurrentClient] = useState("");
  const [currentTags, setCurrentTags] = useState([])

  const [ClientListRes, setClientListRes] = useState([])

  //current grp
  const [currentGroup , setCurrentGroup] = useState(false)
  //Archived
  const [archivedGroup , setArchivedGroup] = useState(false)
 
  //active types
  const [activeType , setActiveType] = useState(false)
  //linked type 
  const [linkedType , setLinkedType] = useState(false)
  //unlinked type
  const [unlinkedType , setUnlinkedType] = useState(false)
  
  //active package
  const [activePackage , setActivePackage] = useState(false)
 
  //tags
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  //search Field  
  const [searchTerm, setSearchTerm] = useState('');

  const [tagPopAssign, setTagPopAssign] = useState("");

  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [onlyFavorites, setOnlyFavorites] = useState("no");

  const [pageSize, setPageSize] = useState(20);
  const [activePage, setActivePage] = useState(1);

  const [favRating, setFavRating] = useState(false);
  const [updateFavStatus, setUpdateFavStatus] = useState(false);
  const [FilterSidebar, setFilterSidebar] = useState();
  const [isInitialized, setInitialized] = useState(false);

  /**
   * Select preferred sort by and order
   */
  useEffect(() => {
    try {
      let filter = localStorage.getItem("my_clients_filter");
      if(filter) {
        let { sortBy, order, pageSize } = JSON.parse(filter);
        sortBy && setSortBy(sortBy);
        order && setSortOrder(order);
        pageSize && setPageSize(pageSize);
      }
    } catch(err) {
      console.log("Error preselecting preferred filter", err);
    } finally {
      setInitialized(true);
    }
  }, []);

  const handleAddTag = (type) => {
    setTagPop(!tagPop);
  };
  const handleAddTagAssign =  (type, tagList) => {
      setTagPopAssign(!tagPopAssign);
      setCurrentClient(type)
  };

  const handleFilterSidebar = () => {
    setFilterSidebar(!FilterSidebar);
  };

  const assignTagsClient = async (tags) => {
    try{
      // return
      let arr = []
      arr.push(tags.id);
      let obj ={
        client_id : currentClient.id,
        assign_tags : arr
      }
      obj = JSON.stringify(obj)
      dispatch(actions.persist_store({ loader:true}));
      await dispatch(assignTagsToClient_(obj));
      setTagPopAssign(false)
      setCurrentClient(null)
      dispatch(actions.persist_store({ loader:false}));
      getData();
    }catch(err) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(err);
    }
  };

  //get data with filter
  const getData = debounce(async (currentPage) => {
      try{
        let filter = {
          "limit": pageSize,
          "page": currentPage || activePage,
          "orderBy": sortBy,
          "order": sortOrder,
          "search": searchTerm,
          "package" : activePackage,
          "type":{
            "active": activeType,
            "unlinked": unlinkedType,
            "direct_link": linkedType
          },
          "group": {
            "current": currentGroup,
            "archived": archivedGroup
          },
          "only_favorites": onlyFavorites,
          tags: selectedCheckboxes,
        }
        dispatch(actions.persist_store({ loader: true }));
        let res = await dispatch(getClientList(filter));
        setClientListRes(res?.body?.data)
        dispatch(actions.persist_store({ loader: false }));
      }catch(err) {
        console.log(err);
        dispatch(actions.persist_store({ loader: false }));
      }
  }, 500);

  //pagination
  const handlePageChange = async (page) => {
      // window.scrollTo(0,0);
      // await dispatch(clientPagination_(page));
      setActivePage(page);
   }
  
  useEffect(() => {
    if (!isInitialized) return;
    
    if (!activePage || activePage !== 1) setActivePage(1); // Reset to page 1
    else getData(1); // Directly call getData if already on the first page

    // save new sorting config
    let saveSortingConfig = {
      pageSize,
      sortBy,
      order: sortOrder,
    }
    localStorage.setItem("my_clients_filter", JSON.stringify(saveSortingConfig));
    return () => getData.cancel();
  }, [isInitialized, searchTerm, sortBy, sortOrder, currentGroup, archivedGroup, activeType, unlinkedType, linkedType, activePackage, selectedCheckboxes, onlyFavorites, pageSize]);

  useEffect(() => {
    if(!isInitialized) return;
    getData();
    
    return () => getData.cancel();
  }, [activePage])

  // get current client
  useEffect(() => { 
    if (isInitialized && currentClient) {
        let array = JSON.parse(currentClient?.tags_assigned || "[]");
        if (array.length === 0) {
            // If 'tags_assigned' is empty, set all tags from tagList to currentTags
            setCurrentTags(tagList);
        } else {
            // Filter the tags that are not in 'tags_assigned'
             let newArr = tagList?.filter(tag => !array.includes(tag.id));
             if(newArr.length==0){
              setTagPop(true);
              setTagPopAssign(false)
             }
             setCurrentTags(newArr);
        }
    }
}, [currentClient, tagList]);

//search filter
// useEffect(() => {
//   const filterResults = async () => {
//     const formattedSearchValue = searchTerm.toLowerCase().replace(/\s/g, '');

//     if (formattedSearchValue === '') {
//       getData();
//     } else {
//       const newFilteredResults = clientListing.filter((item) => {
//         const formattedFirstName = item.first_name?.toLowerCase().replace(/\s/g, '');
//         const formattedLastName = item.last_name?.toLowerCase().replace(/\s/g, '');
//         const formattedEmail = item.email?.toLowerCase().replace(/\s/g, '');

//         return (
//           formattedFirstName?.includes(formattedSearchValue) ||
//           formattedLastName?.includes(formattedSearchValue) ||
//           formattedEmail?.includes(formattedSearchValue)
//         );
//       });
//       setClientListRes(newFilteredResults)
//     }
//   };

//   filterResults();
// }, [searchTerm]);

const clearAllfiltr = () => {
  setCurrentGroup(false)
  setArchivedGroup(false)
  setActiveType(false)
  setLinkedType(false)
  setUnlinkedType(false)
  setActivePackage(false)
  setSelectedCheckboxes([])
  setSearchTerm('');
  setSortBy("created_at");
  setSortOrder("desc");
}

const removeTagsClient = async (clientId, tag) => {
  try {
    await CONFIRM_POPUP()
      .then(async (isConfirm) => {
        if(isConfirm) {
          let arr = []
          arr.push(tag.id);
          let obj = {
            client_id: clientId,
            remove_tags: arr
          }
          obj = JSON.stringify(obj)
          dispatch(actions.persist_store({ loader: true }));
          let res = await dispatch(removeTagsFromClient(obj));
          if(res.success === true) {
            SUCCESS_MESSAGE("Tag removed")
            await getData();
          }
          dispatch(actions.persist_store({ loader: false }));
        }
      })
      .catch(err => {
        console.log("Error removing tag", err);
        throw err;
      })
  } catch (err) {
    dispatch(actions.persist_store({ loader: false }));
    console.log(err);
  }
};

  return (
    <>
      <section className="muyClients">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="btnWrpper">
                  <Button
                    onClick={handleFilterSidebar}
                    className="d-flex align-items-center justify-cotent-center commonBtn btnSm d-xl-none"
                  >
                    <span className="icn">
                      <Wfilter />
                    </span>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Row className="align-items-start">
                <Filter
                  FilterSidebar={FilterSidebar}
                  setFilterSidebar={setFilterSidebar}
                  tagPop={tagPop}
                  setTagPop={setTagPop}
                  tagPopAssign={tagPopAssign}
                  setTagPopAssign={setTagPopAssign}
                  handleAddTag={handleAddTag}
                  handleAddTagAssign={handleAddTagAssign}
                  assignTagsClient={assignTagsClient}
                  setCurrentClient={setCurrentClient}
                  currentClient={currentClient}
                  setCurrentTags={setCurrentTags}
                  currentTags={currentTags}
                  currentGroup={currentGroup} 
                  setCurrentGroup={setCurrentGroup}
                  archivedGroup={archivedGroup}
                  setArchivedGroup={setArchivedGroup}
                  activeType={activeType}
                  setActiveType={setActiveType}
                  linkedType={linkedType}
                  setLinkedType={setLinkedType}
                  unlinkedType={unlinkedType}
                  setUnlinkedType={setUnlinkedType}
                  activePackage={activePackage}
                  setActivePackage={setActivePackage}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  clearAllfiltr={clearAllfiltr}
                  onlyFavorites={onlyFavorites}
                  setOnlyFavorites={setOnlyFavorites}
                  clientTagCounts={clientTagCounts}
                  handlePageChange={handlePageChange}
                />
                <Col lg="9" className="my-2">
                  <div className="commonCardBox">
                    <div className="top px-lg-4 px-3 py-2">
                      <h3 className="m-0 py-2 text-uppercase fw-bold">Lists</h3>
                    </div>
                    <div className="CardBody">
                      <TableData 
                        clientListing={clientListing} 
                        favRating={favRating} 
                        handleAddTagAssign={handleAddTagAssign}
                        setUpdateFavStatus={setUpdateFavStatus}
                        handlePageChange={handlePageChange}
                        assignTagsClient={assignTagsClient}
                        selectedCheckboxes={selectedCheckboxes}
                        ClientListRes={ClientListRes}
                        handleRemoveTag={removeTagsClient}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        getClientList={getData}
                        setPageSize={setPageSize}
                        activePage={activePage}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return{
    tagList : state.Tags.tags,
    // activePage : state.Client.activePage,
    itemsCountPerPage : state.Client.itemsCountPerPage,
    clientListing: state.Client.clients,
    clientTagCounts: state.Client.tags,
  }
};

function mapDispatchToProps(dispatch) {
return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(MyClients);
