import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

// css
import "./RightSidebar.scss";

// img
import GoogleCalIcn from "../../../../../Assets/Images/icn/googleCal.svg";

// icn
import { ReactComponent as PencilIcn } from "../../../../../Assets/Images/icn/pencil.svg";
import { ReactComponent as RecentIcn } from "../../../../../Assets/Images/icn/RecentBlack.svg";
import { ReactComponent as StripeIcn } from "../../../../../Assets/Images/icn/stripe.svg";
import { ReactComponent as CloseBtn } from "../../../../../Assets/Images/icn/close.svg";
import { convertTo12HourFormat, UC_FIRST } from "helpers/common";
// import { ReactComponent as GoogleCalIcn } from "../../../../../Assets/Images/icn/googleCal.svg";

import { Link } from "react-router-dom";
import * as Path from "Routes/paths";

const RightSidebar = ({
  AppointmentSidebar,
  setAppointmentSidebar,
  step,
  setStep,
  hoursLists,
  appointTypeList,
  setSchedulerTypes,
  setSchedulerType,
  setEditValueSetting,
  updateHours_,
  user
}) => {
  const [haveHoursAdded, setHaveHoursAdded] = useState(false);


  useEffect(() => {
    if (hoursLists) {
      let dayWithTimings = hoursLists.filter(day => {
        let dayTiming = JSON.parse(day.timings);
        if (!dayTiming || dayTiming.length == 0) {
          return false;
        }

        return true;
      })
      setHaveHoursAdded(!!dayWithTimings.length);
    }
  }, [hoursLists])

  const handleAppointmentSidebar = () => {
    setAppointmentSidebar(!AppointmentSidebar);
  };

  return (
    <>
      <div
        className={`RightSidebar sticky-top ${AppointmentSidebar && "active"}`}
      >
        <div className="top position-relative p-3 d-xl-none">
          <Button
            onClick={handleAppointmentSidebar}
            className="border-0 p-0 position-absolute closeBtn align-items-center justify-content-center rounded-0"
            style={{ left: "0", top: "0", background: "#000" }}
          >
            <CloseBtn />
          </Button>
        </div>
        <div className="Inner noLastBorder">
          <div className="">
            <div className="CardCstm">
              <div className="top border-bottom px-3 py-2 d-flex align-items-center justify-content-between gap-10">
                <h6 className="fw-bold m-0">Working Hours</h6>
                <Button
                  onClick={() => updateHours_("9")}
                  className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center "
                >
                  <span className="icn me-2">
                    <PencilIcn />
                  </span>
                  {haveHoursAdded ? "Edit" : "Add"}
                </Button>
              </div>
              <div className="cardBody px-3 py-2">
                <ul className="list-unstyled ps-0 mb-0">
                  {hoursLists?.length > 0 && hoursLists.map((hours, i) => {
                    let dayTiming = hours.timings;
                    dayTiming = JSON.parse(dayTiming);

                    if (dayTiming.length > 0) {
                      return (
                        <li className="d-flex align-items-center justify-content-between gap-10 py-1" key={i}>
                          <div className="d-flex align-items-center left">
                            <span className="icn me-1">
                              <RecentIcn />
                            </span>
                            <p className="m-0 fw-bold">{hours?.day.charAt(0).toUpperCase() + hours.day?.slice(1)}</p>
                          </div>
                          {dayTiming.length > 0 && dayTiming.map((time, j) => {
                            // Run the loop only once
                            if (j === 0) {
                              return (
                                <div className="right" key={j}>
                                  <p className="m-0">{convertTo12HourFormat(time.start_time)} to {convertTo12HourFormat(time.end_time)}</p>
                                </div>
                              );
                            }
                            return null; // Return null for subsequent iterations
                          })}
                        </li>
                      );
                    }
                    return null; // Return null if dayTiming.length is not greater than 0
                  })}
                </ul>

              </div>
            </div>
          </div>
          <div className="">
            <div className="CardCstm" style={{ background: "#fffef7" }}>
              <div className="top border-bottom px-3 py-2 d-flex align-items-center justify-content-between gap-10">
                <h6 className="fw-bold m-0">Appointment Types</h6>
                <Button
                  onClick={() => updateHours_(10)}
                  className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center"
                >
                  <span className="icn me-2">
                    <PencilIcn />
                  </span>
                  {(appointTypeList && appointTypeList.length > 0) ? "Edit" : "Add"}
                </Button>
              </div>
              <div className="cardBody px-3 py-2">
                <div className="py-2">
                  {appointTypeList?.length > 0 && appointTypeList.map((type, j) => {
                    let apptLink = `${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}?apptType=${type?.id}`
                    return (
                      <>
                        <h6 className="m-0 fw-bold">{UC_FIRST(type.title)}:</h6>
                        <ul className="list-unstyled mb-0 p-2">
                          <li className="d-flex align-items-center gap-10" key={j}>
                            <span className="text-muted fw-sbold d-flex align-items-center">Duration: </span>
                            <span className="text-muted fw-sbold d-flex align-items-center">
                              <span className="icn me-1">
                                <RecentIcn />
                              </span>
                              {type.duration} min
                            </span>
                          </li>
                          <li className="d-flex align-items-center gap-10" key={j}>
                            <span className="text-muted fw-sbold d-flex align-items-center">
                              <Link
                                to={`${apptLink}`}
                                target="_blank"
                                className=" fw-sbold d-block"
                              >
                                Appointment Link
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="CardCstm">

              <div className="cardBody px-3 py-2">
                <div className="py-2">
                  <ul className="list-unstyled ps-0 mb-0 pt-2">
                    <li className="d-flex align-items-start gap-10 py-3">
                      <div className="imgWrp">
                        <StripeIcn />
                      </div>
                      <div className="content">
                        <h6 className="fw-sbold m-0 pb-1">
                          Link your Stripe Account
                        </h6>
                        <p className="m-0">
                          Add prices and require payment to book an appointment
                          by linking your Stripe account. Check out the{" "}
                          <Link
                            className="underline"
                            to={Path.HelpStripeLinkAccount}
                            target="_blank"
                          >
                            Stripe Integration Guide
                          </Link>{" "}
                          to see how.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-start gap-10 py-3">
                      <div className="imgWrp GOOGLE">
                        {/* <GoogleCalIcn /> */}
                        <img src={GoogleCalIcn} alt="" className="img-fluid" />
                      </div>
                      <div className="content">
                        <h6 className="fw-sbold m-0 pb-1">
                          Sync with your Google Calendar
                        </h6>
                        <p className="m-0">
                          You can sync your appointments with your Google
                          Calendar by linking to your Google Calendar. Check out
                          the{" "}
                          <Link
                            className="underline"
                            to={Path.HelpGoogleLinkAccount}
                            target="_blank"
                          >
                            Google Calendar Integration Guide
                          </Link>{" "}
                          to see how.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="CardCstm">
              <div className="cardBody px-3 py-2">
                <div className="py-2">
                  <ul className="list-unstyled ps-0 mb-0 pt-2">
                    <li className="d-flex align-items-start gap-10 py-3">
                      <div className="content">
                        <h6 className="fw-sbold m-0 pb-1">Scheduler URL</h6>
                        <p className="m-0">
                          <Link
                            to={`${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}`}
                            className=" fw-sbold d-block"
                          >
                            {process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/{user?.uuid}
                          </Link>
                          You can send your clients and potential clients this
                          URL, and they can use it to schedule a new appointment
                          with you. Or if you have your own website, you can use
                          one of the options below to add your Scheduler to your
                          website.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
