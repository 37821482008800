import React from "react";
import { Form } from "react-bootstrap";

const Height = ({ question }) => {
  return (
    <div>
      <div className="d-flex justify-content-start gap-10">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp previewFormField radio position-relative ">
            <input type="radio" className={`position-absolute w-100 h-100`} disabled />
            <div className="cstmInputCheck  h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">{/* <OTick /> */}</span>
            </div>
          </div>
          <p className="m-0">Feet & Inches</p>
        </div>
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp previewFormField radio position-relative ">
            <input type="radio" className={`position-absolute w-100 h-100`} disabled />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">{/* <OTick /> */}</span>
            </div>
          </div>
          <p className="m-0">Centimeters</p>
        </div>
      </div>
      <div className="py-2 w-50">
        <Form.Select disabled>
          <option value="">Select Height</option>
        </Form.Select>
      </div>
    </div>
  );
};

export default Height;
