import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { getDataAnalysisReportsV2 } from "store/services/clientDataReportsService";
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { Button } from 'react-bootstrap';
import { useShowLoader } from 'hooks/useShowLoader';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "10px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "15px",
    paddingBottom: "15px",
    borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "25%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "10px",
    width: "15%",
    padding: "5px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});


const DownloadSBComparisonv2Report = ({ client, naqList, todoIds, label }) => {

  const showLoader = useShowLoader();

  const downloadPDFReport = async () => {
    try {
      if(todoIds && todoIds.length > 0 && todoIds.length < 2) {
        sweetAlert("Please select atleast two NAQs for comparision");
        return;
      }
      showLoader(true);
      const symptomBurdernData = await getNAQv2SBReportsData(client.id, todoIds);
      let totalSymptomBurdenOld = 0;
      let totalSymptomBurdenNew = 0;
      symptomBurdernData.condition[0].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenNew += parseInt(condition.conditionScore)
        }
      })
      symptomBurdernData.condition[1].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenOld += parseInt(condition.conditionScore)
        }
      })
      symptomBurdernData.nutritional[0].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenNew += parseInt(deficiency.conditionScore)
        }
      })
      symptomBurdernData.nutritional[1].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenOld += parseInt(deficiency.conditionScore)
        }
      })

      let colors = [];
      colors.push(naqList.find(naq => naq.id == symptomBurdernData.condition[0].to_do_id)?.color);
      colors.push(naqList.find(naq => naq.id == symptomBurdernData.condition[1].to_do_id)?.color);

      const doc =
        <NAQ2SBComparison
          symptomBurdernData={symptomBurdernData}
          totalSymptomBurdenNew={totalSymptomBurdenNew}
          totalSymptomBurdenOld={totalSymptomBurdenOld}
          client={client}
          colors={colors}
        />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Symptom-Burden-Report.pdf');
      showLoader(false);
    } catch (err) {
      console.log("Error downloading pdf report", err);
      showLoader(false);
    }
  }

  return (
    <Button
      onClick={downloadPDFReport}
      className="d-flex align-items-center justify-content-center commonBtn btnSm"
    >
      <span className="icn me-2">
        <DownloadIcn />
      </span>
      {label}
    </Button>
  )
}

export const NAQ2SBComparison = ({ symptomBurdernData, totalSymptomBurdenOld, totalSymptomBurdenNew, client, colors }) => {
  return (
    <Document>

      {/* PAGE 1 */}
      {/* Potential Conditions */}
      <Page style={styles.page} wrap={false}>

        <View style={styles.pageHeader}>
          <Text style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Symptom Burden Comparision Report
          </Text>
          <Text style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>

        <View style={{ marginBottom: "15px" }}>
          <Text style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c", borderBottom: "1px solid #aaa" }}>
            Total Symptom Burden
          </Text>
          <View style={styles.tableContainer}>
            <TotalSymptomBurdenTable symptomBurdernData={symptomBurdernData} oldTotal={totalSymptomBurdenOld} newTotal={totalSymptomBurdenNew} colors={colors} />
          </View>
        </View>

        <View>
          <View style={{ borderBottom: "1px solid #ccc" }}>
            <Text style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
              Potential Conditions
            </Text>
          </View>
          <Text style={{ paddingTop: "5px" }}>
            Based on the results from the Nutritional Assessment Questionnaire, the following conditions may apply to your client.
          </Text>
        </View>

        {(symptomBurdernData) &&
          <View style={styles.tableContainer}>
            <ConditionsTable symptomBurdernData={symptomBurdernData} colors={colors} />
          </View>
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner.
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>

      {/* PAGE 2 */}
      {/* Potential Nutritional Deficiencies */}
      <Page style={styles.page} wrap={false}>

        <View style={styles.pageHeader}>
          <Text style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Symptom Burden Report
          </Text>
          <Text style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>

        <View>
          <View style={{ borderBottom: "1px solid #ccc" }}>
            <Text style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
              Potential Nutritional Deficiencies
            </Text>
          </View>
          <Text style={{ paddingTop: "5px" }}>
            Based on the results from the Nutritional Assessment Questionnaire, your client may have the following nutritional deficiencies.
          </Text>
        </View>

        {(symptomBurdernData) &&
          <View style={styles.tableContainer}>
            <DeficienciesTable symptomBurdernData={symptomBurdernData} colors={colors} />
          </View>
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner.
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getNAQv2SBReportsData = async (clientId, todoIds) => {
  try {
    let data = {
      clientId,
      values: {
        todoIds
      }
    }
    const response = await getDataAnalysisReportsV2(data);
    if (response.success === true && response.body.condition && response.body.condition.length > 0) {
      return response.body;
    }
    return null;
  } catch (error) {
    console.log("Error getting graph data", error);
    return null;
  }
}

const TotalSymptomBurdenTable = ({ symptomBurdernData, newTotal, oldTotal, colors }) => {
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "50%" }]}></Text>
        <Text style={[tableStyles.headerCell, { width: "20%", borderBottom: `3px solid ${colors[0]}`, textAlign: "center" }]}>{moment(symptomBurdernData.condition[0].completed_at).format("MMM DD, YYYY")} {" "}</Text>
        <Text style={[tableStyles.headerCell, { width: "20%", borderBottom: `3px solid ${colors[1]}`, textAlign: "center" }]}>{moment(symptomBurdernData.condition[1].completed_at).format("MMM DD, YYYY")} {" "}</Text>
        <Text style={[tableStyles.headerCell, { width: "10%" }]}>Change</Text>
      </View>
      <View style={[tableStyles.row]}>
        <Text style={{ width: "50%" }}>Total Symptom Burden</Text>
        <Text style={{ width: "20%" }}>{newTotal}</Text>
        <Text style={{ width: "20%" }}>{oldTotal}</Text>
        <Text style={{ width: "10%" }}>{newTotal - oldTotal}</Text>
      </View>
    </View>
  )
};

const ConditionsTable = ({ symptomBurdernData, colors }) => {
  const conditionRows = symptomBurdernData.condition[0].data.map((condition, i) => {
    let newData = symptomBurdernData.condition[0].data[i] || {};
    let oldData = symptomBurdernData.condition[1].data[i] || {};
    return (
      <View style={[tableStyles.row]} key={i}>
        <Text style={tableStyles.cell}>{condition.conditionName}</Text>
        <Text style={tableStyles.cell}>{condition.conditionTotalPossible}</Text>
        <Text style={tableStyles.cell}>{newData.conditionScore}</Text>
        <Text style={tableStyles.cell}>{Math.round(newData.conditionScorePercentage)}%</Text>
        <Text style={tableStyles.cell}>{oldData.conditionScore}</Text>
        <Text style={tableStyles.cell}>{Math.round(oldData.conditionScorePercentage)}%</Text>
        <Text style={tableStyles.cell}>{newData.conditionScore - oldData.conditionScore}</Text>
      </View>
    )
  });

  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "30%" }]}></Text>
        <Text style={[tableStyles.headerCell, { width: "25%", textAlign: "center", borderBottom: `3px solid ${colors[0]}` }]}>{moment(symptomBurdernData.condition[0].completed_at).format("MMM DD, YYYY")} {" "}</Text>
        <Text style={[tableStyles.headerCell, { width: "25%", textAlign: "center", borderBottom: `3px solid ${colors[1]}` }]}>{moment(symptomBurdernData.condition[1].completed_at).format("MMM DD, YYYY")} {" "}</Text>
        <Text style={[tableStyles.headerCell, { width: "20%" }]}></Text>
      </View>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell]}>Condition</Text>
        <Text style={tableStyles.headerCell}>Total Possible</Text>
        <Text style={tableStyles.headerCell}>Score</Text>
        <Text style={tableStyles.headerCell}>Percentage</Text>
        <Text style={tableStyles.headerCell}>Score</Text>
        <Text style={tableStyles.headerCell}>Percentage</Text>
        <Text style={tableStyles.headerCell}>Change</Text>
      </View>
      {conditionRows}
    </View>
  )
};

const DeficienciesTable = ({ symptomBurdernData, colors }) => {
  const nutritionalRows = symptomBurdernData.nutritional[0].data.map((deficiency, i) => {
    let newData = symptomBurdernData.nutritional[0].data[i] || {};
    let oldData = symptomBurdernData.nutritional[1].data[i] || {};
    return (
      <View style={[tableStyles.row]} key={i}>
        <Text style={tableStyles.cell}>{deficiency.conditionName}</Text>
        <Text style={tableStyles.cell}>{deficiency.conditionTotalPossible}</Text>
        <Text style={tableStyles.cell}>{newData.conditionScore}</Text>
        <Text style={tableStyles.cell}>{Math.round(newData.conditionScorePercentage)}%</Text>
        <Text style={tableStyles.cell}>{oldData.conditionScore}</Text>
        <Text style={tableStyles.cell}>{Math.round(oldData.conditionScorePercentage)}%</Text>
        <Text style={tableStyles.cell}>{newData.conditionScore - oldData.conditionScore}</Text>
      </View>
    )
  });

  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "30%" }]}></Text>
        <Text style={[tableStyles.headerCell, { width: "25%", textAlign: "center", borderBottom: `3px solid ${colors[0]}` }]}>{moment(symptomBurdernData.condition[0].completed_at).format("MMM DD, YYYY")} {" "}</Text>
        <Text style={[tableStyles.headerCell, { width: "25%", textAlign: "center", borderBottom: `3px solid ${colors[1]}` }]}>{moment(symptomBurdernData.condition[1].completed_at).format("MMM DD, YYYY")} {" "}</Text>
        <Text style={[tableStyles.headerCell, { width: "20%" }]}></Text>
      </View>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell]}>Condition</Text>
        <Text style={tableStyles.headerCell}>Total Possible</Text>
        <Text style={tableStyles.headerCell}>Score</Text>
        <Text style={tableStyles.headerCell}>Percentage</Text>
        <Text style={tableStyles.headerCell}>Score</Text>
        <Text style={tableStyles.headerCell}>Percentage</Text>
        <Text style={tableStyles.headerCell}>Change</Text>
      </View>
      {nutritionalRows}
    </View>
  )
};

// const DeficienciesTable = ({ items }) => {
//   const rows = items.map((row, i) => {
//     return (
//       <View style={[tableStyles.row]} key={i}>
//         <Text style={tableStyles.cell}>{row.condition_name}</Text>
//         <Text style={tableStyles.cell}>{row.conditionScore}</Text>
//         <Text style={tableStyles.cell}>{row.conditionTotalPossible}</Text>
//         <Text style={tableStyles.cell}>{Math.round(row.conditionScorePercentage)}%</Text>
//       </View>
//     )
//   });

//   return (
//     <View style={tableStyles.table}>
//       <View style={tableStyles.row}>
//         <Text style={[tableStyles.headerCell]}>Deficiency</Text>
//         <Text style={tableStyles.headerCell}>Score</Text>
//         <Text style={tableStyles.headerCell}>Total Possible</Text>
//         <Text style={tableStyles.headerCell}>Percentage</Text>
//       </View>
//       {rows}
//     </View>
//   )
// };

export default DownloadSBComparisonv2Report;