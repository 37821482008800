import React, {useEffect, useState} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as WPlus } from "Assets/Images/icn/AddCircle.svg";

const AddTagPopAssign = ({
  tagPopAssign,
  setTagPopAssign,
  tagList,
  assignTagsClient,
  setCurrentClient,
  currentClient,
  setCurrentTags,
  currentTags,
  setTagPop
}) => {
  const handleAddTagAssign = () => {
    setTagPopAssign(!tagPopAssign);
    setCurrentClient(null)
  };

  return (
    <>
      <Form>
        <Modal
          show={tagPopAssign}
          onHide={handleAddTagAssign}
          backdrop="static"
          keyboard={false}
          centered
          className="FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title>My Tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="TableBtn d-flex align-items-center gap-10 flex-wrap">
              <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10 flex-wrap">
                {currentTags.length>0 && currentTags.map((tag, i) => {
                    return (
                      <li className="tags_list" key={i} onClick={() => assignTagsClient(tag)}>
                        <span
                          className="CstmLabel"
                          style={{
                            borderColor: tag.color_code,
                            color: "#fff",
                            background: tag.color_code,
                          }}
                        >
                          {tag.name}
                        </span>
                      </li>
                    );
                })}
                <li>
                  <Link
                    onClick={() => {
                      setTagPop(true);
                    }}
                  >
                    <WPlus />
                  </Link>
                </li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer className="FormbtnWrpper">
            <Button
              className="d-flex align-items-center justify-content-center commonBtn btnSm"
              onClick={handleAddTagAssign}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(AddTagPopAssign);
