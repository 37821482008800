import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, Row, Col } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getBodySymptomBurdenGraphV2 } from "store/services/clientDataReportsService";
import * as actions from "store/actions"
import CustomBarChart from "../CustomChart";
import DownloadBSBurdenGraph from "../PDFReportComponents/BodySymptomGraphPdf";

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

const BodySymptomGraph = ({ dispatch, eventKey, todoId, client, submittedOn }) => {
  const { clientId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bodySymptomBurdenGraphData, setBodySymptomBurdenGraphData] = useState(null);

  useEffect(() => {
    if (isOpen && !bodySymptomBurdenGraphData) {
      getBodySymptomBurdenGraphData();
    }
  }, [isOpen]);

  const getBodySymptomBurdenGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId
      };
      setIsLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      const response = await getBodySymptomBurdenGraphV2(data);
      if (response.success === true) {
        const data = response.body;
        setBodySymptomBurdenGraphData(data);
      }
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  

  return (
    <Accordion.Item eventKey={eventKey} className="priority-report my-2">
      <Accordion.Header>
        Body Systems Symptom Burden Graph
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        onEnter={() => {
          setIsOpen(true)
        }}
        onExit={() => {
          setIsOpen(false)
        }}
      >
        <div className="accordion-inner">
          {/* <div className="py-2">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
              </div>
              <div className="right">
                <DownloadBSBurdenGraph
                  todoId={todoId}
                  client={client}
                  date={submittedOn}
                  linkType={"button"}
                  label={"Download Graph"}
                />
              </div>
            </div>
          </div> */}
          {isLoading ?
            <p>Loading...</p>
            :
            bodySymptomBurdenGraphData ?
              <>
                <CustomBarChart
                  dataset={bodySymptomBurdenGraphData}
                  title="Body Systems Symptom Burden Graph"
                  totalsTitle="Body Systems Totals"
                  defaultShowMaxes={true}
                />
              </>
              :
              <>
                <p>Data not found</p>
              </>
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BodySymptomGraph);