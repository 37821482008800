import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

// icon
import { ReactComponent as OTick } from "../../../../../../../Assets/Images/icn/OrangeTick.svg";
import { Link } from "react-router-dom";
import { FULL_NAME } from "helpers/common";

const PackageLinkPopup = ({ showPopup, data, closePopup, packageLink }) => {
  const [textCopied, setTextCopied] = useState(false);
  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      setTextCopied(null);
    }
  }, []);
  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Package Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg="12" className="my-2">
                {data?.client && (
                  <p className="m-0 fw-sbold pb-1">
                    Below is the package invite link for {FULL_NAME(data?.client?.first_name, data?.client?.last_name)}:
                  </p>
                )}
                <div className="d-flex gap-10 justify-content-between">
                  <pre className="m-0 fw-sbold pb-1">{packageLink}</pre>
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(packageLink);
                        setTextCopied(true);
                        timeoutId = setTimeout(() => {
                          setTextCopied(false);
                        }, 3000)
                      } catch (err) {
                        console.log("Failed to copy to the clipboard");
                      }
                    }}
                  >
                    { textCopied ? "Copied!" : "Copy" }
                  </Button>
                </div>
                <p className="m-0 fw-sbold py-2">
                  If you need the link in the future it's also available on the
                  <Link>
                    {` `}client's page{` `}
                  </Link>
                  by clicking the triple dots next to the package name.
                </p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PackageLinkPopup;
