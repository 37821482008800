import { GET_DOCUMENT, ADD_DOCUMENT, DELETE_DOCUMENT } from 'store/actions/documents';

const initialState = {
    isAuthenticated: false,
    documents: [],
    document: {},
    loader:false,
    activePage:1,
    totalItemsCount:0,
    itemsCountPerPage:10,
};

//get 
const getDocument = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        documents:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addDocument = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      documents:data.body,
    };
    return stateObj;
  };


//delete 
const deleteDocument = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    documents:[],
  };
  return stateObj;
};

const SystemAccess = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_DOCUMENT:
          return getDocument(state, payload);
        case ADD_DOCUMENT:
            return addDocument(state, payload);
        case DELETE_DOCUMENT:
          return deleteDocument(state, payload);
        default:
        return state;
    };
}
export default SystemAccess;