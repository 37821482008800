import { Col, Row } from "react-bootstrap"
import { BarChart } from "./BarChart"
import { WheelChart } from "./WheelChart"
import D3Chart from "./D3Chart"

export const BarWheelChart = ({ chartData, height, screenSize }) => {
  return (
    <Row style={{ height: "100%" }}>
      <Col lg="5">
        {/* <WheelChart 
          chartData={chartData}
          // height={height}
          screenSize={screenSize}
        /> */}
        <D3Chart
          chartData={chartData}
        />
      </Col>
      <Col lg="7">
        <BarChart 
          chartData={chartData}
          // height={height}
          screenSize={screenSize}
        />
      </Col>
    </Row>
  )
}