import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { BarChart } from "./Components/BarChart";
import { LineChart } from "./Components/LineChart";

// icn
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

import { getDataAnalysisGraphsV1 } from "store/services/clientDataReportsService";
import SelectNAQs from "../../SelectNAQs";
import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";
import { getChartHeightByScreenSize, getScreenSize } from "components/shared/ChartConfig";
import DownloadComparisionGraph from "./Components/ComparisionGraphPDF";

const Naqv1SymptomGraph = ({ dispatch, naqList, client }) => {
  const { clientId } = useParams();
  const [chartData, setChartData] = useState(null);
  const [selectedNAQs, setSelectedNAQs] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState("bar");
  const [chartHeight, setChartHeight] = useState(150);
  const [screenSize, setScreenSize] = useState("extra-large");

  const barChartRef = useRef(null);
  const lineChartRef = useRef(null);

  useEffect(() => {
    let size = getScreenSize(screenSize);
    setScreenSize(size);
  }, []);

  useEffect(() => {
    let height = getChartHeightByScreenSize(screenSize, chartHeight);
    setChartHeight(height);
  }, [screenSize]);

  useEffect(() => {
    let todoIds = naqList && naqList.slice(0, 2).map(naq => naq.id);
    if(todoIds) {
      getNAQGraphData(todoIds);
    }
  }, [naqList]);

  useEffect(() => {
    if(selectedNAQs && selectedNAQs.length > 1) {
      getNAQGraphData(selectedNAQs);
    }
  }, [selectedNAQs]);

  const getNAQGraphData = async (todoIds) => {
    try {
      let data = {
        clientId,
        values: {
          todoIds
        }
      } 
      dispatch(actions.persist_store({ loader: true }));
      const response = await getDataAnalysisGraphsV1(data);
      dispatch(actions.persist_store({ loader: false }));
      if( response.success === true && response.body.length > 0) {
        setChartData(response.body);
      }
    } catch(error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeSelectedNAQ = (selected) => {
    if(selected.length === 1) {
      // proceed only if the select naq's graph data is present in the chartData state
      if(chartData && chartData.length > 0 && chartData?.find(todo => todo?.todo_id == selected[0])) {
        setSelectedNAQs(selected);
      }
    } else {
      setSelectedNAQs(selected);
    }
  }

  const getNAQColor = (naqId) => {
    let naq = naqList.find(naq => naq.id === naqId);
    return naq && naq.color;
  }

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }
  
  return (
    <>
      <div className="top d-flex align-items-start justify-content-between gap-10">
        <div className="left">
          <h6 className="m-0 fw-bold">Symptom Burden Comparison Graph</h6>
          <p className="m-0">
            Compare Symptom Burden Graphs from {FULL_NAME(client.first_name, client.last_name)}'s NAQs.
          </p>
        </div>
        <div className="right">
          <DownloadComparisionGraph
            client={client}
            selectedNAQs={naqList && naqList.filter(naq => selectedNAQs.includes(naq.id))}
            chartRef={selectedChartType === "bar" ? barChartRef : lineChartRef}
            showLoader={showLoader}
            hideLoader={hideLoader}
          />
        </div>
      </div>
      <div className="CardBody p-3 bg-light my-2">
        <Row>
          <Col lg="10">
            <SelectNAQs
              naqList={naqList}
              onChange={handleChangeSelectedNAQ}
              maxSelectable={4}
            />
          </Col>
          <Col lg="2">
            <Form.Label>
              Chart type:
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setSelectedChartType(e.target.value);
              }}
              value={selectedChartType}
            >
              <option value="bar">Bar Chart</option>
              <option value="line">Line Chart</option>
            </Form.Select>
          </Col>
        </Row>
        <div className="GraphWrpper my-2 py-2">
          <div className="naq1-chart-container">
            {chartData && selectedChartType == "bar" &&
              <BarChart
                innerRef={barChartRef}
                chartData={chartData}
                height={chartHeight}
                screenSize={screenSize}
                selectedNAQs={selectedNAQs}
                getColor={getNAQColor}
              />
            }
            {chartData && selectedChartType == "line" &&
              <LineChart 
                innerRef={lineChartRef}
                chartData={chartData}
                height={chartHeight}
                screenSize={screenSize}
                selectedNAQs={selectedNAQs}
                getColor={getNAQColor}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Naqv1SymptomGraph);