import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

const SymptomBurdonReport = ({ symptomBurdernData, naqList, selectedNAQs }) => {
  const [totalSymptomBurdenOld, setTotalSymptomBurdenOld] = useState(0);
  const [totalSymptomBurdenNew, setTotalSymptomBurdenNew] = useState(0);
  const [showDataset0, setShowDataset0] = useState(true);
  const [showDataset1, setShowDataset1] = useState(true);
  const [colors, setColors] = useState([]);
  
  useEffect(() => {
    if (symptomBurdernData) {
      const symptomData = { ...symptomBurdernData };
      let totalSymptomBurdenOld = 0;
      let totalSymptomBurdenNew = 0;
      symptomData.condition[0].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenNew += parseInt(condition.conditionScore)
        }
      })
      symptomData.condition[1].data.map((condition) => {
        if (condition.conditionScore) {
          totalSymptomBurdenOld += parseInt(condition.conditionScore)
        }
      })
      symptomData.nutritional[0].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenNew += parseInt(deficiency.conditionScore)
        }
      })
      symptomData.nutritional[1].data.map((deficiency) => {
        if (deficiency.conditionScore) {
          totalSymptomBurdenOld += parseInt(deficiency.conditionScore)
        }
      })
      
      setTotalSymptomBurdenOld(totalSymptomBurdenOld);
      setTotalSymptomBurdenNew(totalSymptomBurdenNew);

      let colors = [];
      colors.push(naqList.find(naq => naq.id == symptomData.condition[0].to_do_id)?.color);
      colors.push(naqList.find(naq => naq.id == symptomData.condition[1].to_do_id)?.color);
      setColors(colors);
    }
  }, [symptomBurdernData])

  useEffect(() => {
    if(selectedNAQs.length > 0 && symptomBurdernData) {
      let shouldShowData0 = selectedNAQs.find(naqId => symptomBurdernData.condition[0].to_do_id === naqId);
      let shouldShowData1 = selectedNAQs.find(naqId => symptomBurdernData.condition[1].to_do_id === naqId);
      setShowDataset0(shouldShowData0);
      setShowDataset1(shouldShowData1);
    } else {
      setShowDataset0(true);
      setShowDataset1(true);
    }
  }, [selectedNAQs, symptomBurdernData])

  if(!symptomBurdernData) {
    return "Data not found";
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="top">
            {/* <h6 className="m-0 text-uppercase">Total Symptom Burden: {totalSymptomBurden}</h6> */}
            <table className="table">
              <thead>
                <tr>
                  <th className="p-3 fw-normal text-dark fw-sbold">
                  </th>
                  { showDataset0 &&
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      {moment(symptomBurdernData.condition[0].completed_at).format("MMM DD, YYYY")}
                    </th>
                  }
                  { showDataset1 &&
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      {moment(symptomBurdernData.condition[1].completed_at).format("MMM DD, YYYY")}
                    </th>
                  }
                  { showDataset0 && showDataset1 &&
                    <th className="p-3 fw-normal text-dark fw-sbold">
                      Change
                    </th>
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 border-0">
                    <h6 className="m-0 text-uppercase">Total Symptom Burden: {totalSymptomBurdenOld}</h6>
                  </td>
                  { showDataset0 &&
                    <td className="p-2 border-0">{totalSymptomBurdenNew}</td>
                  }
                  { showDataset1 &&
                    <td className="p-2 border-0">{totalSymptomBurdenOld}</td>
                  }
                  { showDataset0 && showDataset1 &&
                    <td className="p-2 border-0">{totalSymptomBurdenNew - totalSymptomBurdenOld}</td>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Row className="InfoSetting">
        <Col lg="12" md="12" className="my-2">
          <div
            className="commonCardBox MainCard h-100"
            style={{ borderColor: "#405C40" }}
          >
            <div
              className="top px-lg-4 px-3 py-2 commonContent"
              style={{ background: "#405C40" }}
            >
              <h3 className="m-0 text-white">Conditions</h3>
            </div>
            <div className="CardBody noLastBorder">
              <div className="px-lg-4 p-3">
                <p className="m-0">
                  Based on the results from the Nutritional Assessment
                  Questionnaire, the following conditions may apply to your
                  client.
                </p>
              </div>
              <div className="">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan={2} className="p-3 fw-normal text-dark fw-sbold">
                          Conditions{" "}
                        </th>
                        { showDataset0 &&
                          <th
                            colSpan={2}
                            className="p-3 fw-normal text-dark fw-sbold text-center"
                            style={{borderBottom: `4px solid ${colors[0]}`}}
                          >
                            {moment(symptomBurdernData.condition[0].completed_at).format("MMM DD, YYYY")} {" "}
                          </th>
                        }
                        { showDataset1 &&
                          <th
                            colSpan={2}
                            className="p-3 fw-normal text-dark fw-sbold text-center"
                            style={{borderBottom: `4px solid ${colors[1]}`}}
                          >
                            {moment(symptomBurdernData.condition[1].completed_at).format("MMM DD, YYYY")} {" "}
                          </th>
                        }
                        <th className="p-3 fw-normal text-dark fw-sbold">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Condition{" "}
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Total Possible{" "}
                        </th>
                        { showDataset0 &&
                          <>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Score{" "}
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Percentage{" "}
                            </th> 
                          </>
                        }
                        { showDataset1 &&
                          <>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Score{" "}
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Percentage{" "}
                            </th> 
                          </>
                        }
                        { showDataset0 && showDataset1 &&
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Change{" "}
                          </th>
                        }
                      </tr>
                      {(symptomBurdernData && symptomBurdernData.condition.length > 0) ?
                        symptomBurdernData.condition[0].data.map((condition, i) => {
                          let newData = symptomBurdernData.condition[0].data[i];
                          let oldData = symptomBurdernData.condition[1].data[i];
                          return (
                            <tr>
                              <td className="p-2 border-0">
                                <span className="text-dark fw-sbold">
                                  {condition.conditionName}
                                </span>
                              </td>
                              <td className="p-2 border-0">{condition.conditionTotalPossible}</td>
                              { showDataset0 &&
                                <>
                                  <td className="p-2 border-0">{newData?.conditionScore || 0}</td>
                                  <td className="p-2 border-0">{Math.round(newData?.conditionScorePercentage || 0)}%</td>
                                </>
                              }
                              { showDataset1 &&
                                <>
                                  <td className="p-2 border-0">{oldData?.conditionScore || 0}</td>
                                  <td className="p-2 border-0">{Math.round(oldData?.conditionScorePercentage || 0)}%</td>
                                </>
                              }
                              { showDataset0 && showDataset1 &&
                                <td className="p-2 border-0">{(newData?.conditionScore - oldData?.conditionScore) || 0}</td>
                              }
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td className="text-center" colSpan={4}>Data not available</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="12" md="12" className="my-2">
          <div
            className="commonCardBox MainCard h-100"
            style={{ borderColor: "#405C40" }}
          >
            <div
              className="top px-lg-4 px-3 py-2 commonContent"
              style={{ background: "#405C40" }}
            >
              <h3 className="m-0 text-white">
                Nutritional Deficiencies
              </h3>
            </div>
            <div className="CardBody noLastBorder">
              <div className="px-lg-4 p-3">
                <p className="m-0">
                  Based on the results from this Nutritional Assessment
                  Questionnaire, your client may have the following
                  nutritional deficiencies.
                </p>
              </div>
              <div className="">
                <div className="table-responsive">
                <table className="table">
                    <thead>
                      <tr>
                        <th colSpan={2} className="p-3 fw-normal text-dark fw-sbold">
                          Conditions{" "}
                        </th>
                        { showDataset0 &&
                          <th
                            colSpan={2}
                            className="p-3 fw-normal text-dark fw-sbold text-center"
                            style={{borderBottom: `4px solid ${colors[0]}`}}
                          >
                            {moment(symptomBurdernData.condition[0].completed_at).format("MMM DD, YYYY")} {" "}
                          </th>
                        }
                        { showDataset1 &&
                          <th
                            colSpan={2}
                            className="p-3 fw-normal text-dark fw-sbold text-center"
                            style={{borderBottom: `4px solid ${colors[1]}`}}
                          >
                            {moment(symptomBurdernData.condition[1].completed_at).format("MMM DD, YYYY")} {" "}
                          </th>
                        }
                        <th className="p-3 fw-normal text-dark fw-sbold">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Condition{" "}
                        </th>
                        <th className="p-3 fw-normal text-dark fw-sbold">
                          Total Possible{" "}
                        </th>
                        { showDataset0 &&
                          <>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Score{" "}
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Percentage{" "}
                            </th> 
                          </>
                        }
                        { showDataset1 &&
                          <>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Score{" "}
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Percentage{" "}
                            </th> 
                          </>
                        }
                        { showDataset0 && showDataset1 &&
                          <th className="p-3 fw-normal text-dark fw-sbold">
                            Change{" "}
                          </th>
                        }
                      </tr>
                      {(symptomBurdernData && symptomBurdernData.nutritional.length > 0) ?
                        symptomBurdernData.nutritional[0].data.map((deficiency, i) => {
                          let newData = symptomBurdernData.nutritional[0].data[i];
                          let oldData = symptomBurdernData.nutritional[1].data[i];
                          return (
                            <tr>
                              <td className="p-2 border-0">
                                <span className="text-dark fw-sbold">
                                  {deficiency.conditionName}
                                </span>
                              </td>
                              <td className="p-2 border-0">{deficiency.conditionTotalPossible}</td>
                              { showDataset0 &&
                                <>
                                  <td className="p-2 border-0">{newData?.conditionScore || 0}</td>
                                  <td className="p-2 border-0">{Math.round(newData?.conditionScorePercentage || 0)}%</td>
                                </>
                              }
                              { showDataset1 &&
                                <>
                                  <td className="p-2 border-0">{oldData?.conditionScore || 0}</td>
                                  <td className="p-2 border-0">{Math.round(oldData?.conditionScorePercentage || 0)}%</td>
                                </>
                              }
                              { showDataset0 && showDataset1 &&
                                <td className="p-2 border-0">{(newData?.conditionScore - oldData?.conditionScore) || 0}</td>
                              }
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td className="text-center" colSpan={4}>Data not available</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
};

export default SymptomBurdonReport;
