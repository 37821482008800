import { decode } from "html-entities";
import { Text, View } from '@react-pdf/renderer';

const Weight = ({ question }) => {

  return (
    <View>
      <View style={{flexDirection:"row", gap: "10px", marginBottom: "5px"}}>
        <View style={{flexDirection:"row", alignItems:"center"}}>
          <Text style={{border:"1px solid #000", width:10, height:10, borderRadius:"50%", marginRight:5}}>              
          </Text>
          <Text>Pounds</Text>
        </View>
        <View style={{flexDirection:"row", alignItems:"center"}}>
          <Text style={{border:"1px solid #000", width:10, height:10, borderRadius:"50%", marginRight:5}}>              
          </Text>
          <Text>Kilograms</Text>
        </View>
        <View style={{flexDirection:"row", alignItems:"center"}}>
          <Text style={{border:"1px solid #000", width:10, height:10, borderRadius:"50%", marginRight:5}}>              
          </Text>
          <Text>Stones</Text>
        </View>
      </View>
      <Text
        style={{
          border: "1px solid #ccc",
          borderRadius: 5,
          width: "30%",
          height: "30px",
          padding: "5px",
          paddingTop: "8px",
          marginBottom: "5px",
          color: "#333"
        }}>
        Enter Weight
      </Text>
    </View>
  );
};

export default Weight;
