import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import NTAMemberInfo from "./Component/Modal/NTAMemberInfo";
import { Link, useNavigate } from "react-router-dom";
//imported
import {
  getSubscriptionList,
  addSubscriptionData,
} from "store/services/subscriptionService";
import * as Path from "Routes/paths";
import * as actions from "store/actions";
import { helpEmail } from "common/constants";
import { hasActivePlan } from "helpers/common";

const Subscription = ({ dispatch, subscriptionList, profileDetail }) => {
  const navigate = useNavigate();
  const [NTAMember, setNTAMember] = useState("");
  const [packageDetail, setPackageDetail] = useState({});
  const [sending, setSending] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    checkActivePlan();
  }, [profileDetail]);

  const checkActivePlan = async () => {
    if (profileDetail?.terms_accepted === 'no') {
      navigate(Path.dashboardTermsCondition)
    } else if(hasActivePlan(profileDetail)) {
      navigate(Path.dashboard);
    }
  }

  const handleNTAMember = (packDetail, ntaType) => {
    let final_res = {
      pacakage_detail: packDetail,
      nty_type: ntaType,
    };
    setNTAMember(!NTAMember);
    setPackageDetail(final_res);
  };

  //get data
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getSubscriptionList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //choose plan
  const buySubscription = async (packageId, ntaType) => {
    try {
      const callback = (response) => {
        const { data } = response;
        if (data?.body?.url) {
          const isValidUrl =
            data.body.url.startsWith("http") ||
            data.body.url.startsWith("https");

          if (isValidUrl) {
            // Redirect to the URL
            window.location.href = data.body.url;
          }
        }
      };
      let finalObj = {
        package_id: packageId,
        is_nta_package: ntaType,
      };
      finalObj = JSON.stringify(finalObj);
      dispatch(actions.persist_store({ loader: true }));
      setSending(true);
      await dispatch(addSubscriptionData(finalObj, callback));
      dispatch(actions.persist_store({ loader: false }));
      setSending(false);
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
      setSending(false);
    }
  };

  return (
    <>
      <section className="subscription position-relative">
        <Container>
          <Row>
            {/* <Col lg="12" className="my-2">
              <div className="commonContent">
                <h3 className="m-0 fw-bold py-1">Standard Account</h3>
              </div>
            </Col> */}
            <Col lg="12" className="my-2">
              {profileDetail ? (
                <>
                  <Row>
                    {subscriptionList.length > 0 &&
                      subscriptionList.map((package_, i) => {
                        return (
                          <Col lg="6" className="my-2" key={i}>
                            <div className="commonCardBox PricingCard h-100 p-3 px-lg-5 position-relative">
                              <div className="top text-center">
                                <h3 className="m-0 py-2 fw-bold">
                                  {package_.package_name}
                                </h3>
                                <h2 className="m-0 py-2 priceHead fw-bold">
                                  ${package_.package_price}{" "}
                                  <span className="">/{package_.package_type}</span>
                                </h2>
                                {package_.free_trial === "yes" && package_.trial_eligibility == 1 && (
                                  <h3 className="m-0 py-2 priceHead fw-bold">
                                    {package_.free_trial_duration} days free
                                  </h3>
                                )}
                                <p className="py-2 m-0">{package_.package_desc}</p>
                              </div>
                              <div className="CardBody py-3">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: package_?.package_included,
                                  }}
                                />
                              </div>
                              {package_.is_nta_package === "no" ? (
                                <div className="btnWrp mt-5">
                                  <Link
                                    to="#"
                                    /* // to="/dashboard/subscription-payment" */
                                    onClick={() =>
                                      buySubscription(package_.id, "no")
                                    }
                                    className="d-flex btn text-white w-100 fw-sbold align-items-center justify-content-center commonBtn subscribe-btn"
                                  >
                                    {sending ? "wait..." : "Subscribe Now"}
                                  </Link>
                                </div>
                              ) : (
                                <div className="btnWrp mt-5">
                                  <Button
                                    onClick={() => handleNTAMember(package_, "yes")}
                                    className="d-flex w-100 fw-sbold align-items-center justify-content-center commonBtn subscribe-btn"
                                  >
                                    Confirm Your NTA Membership
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                  <div className="py-2 my-2">
                    <p className="m-0">
                      <b>
                        Here are some additional important subscription details:{" "}
                      </b>
                      If your subscription includes a free trial period, unless you
                      cancel your subscription before the end of the free trial
                      period, you will automatically become a recurring subscriber
                      to the subscription plan above that you chose and the payment
                      method you provided will automatically be charged the monthly
                      or annual price depending on the plan. To cancel your
                      subscription, log into your Nutri-Q account, click on the icon
                      in the bottom left corner and then click 'My Account'. On that
                      page click 'Subscription Information' and then finally click on
                      the 'Cancel subscription' link and follow the
                      prompts to cancel your subscription. If your subscription
                      includes a free trial period, we will notify you via email 7
                      days prior to charging your card the first time to make sure
                      you know your first charge is coming up. Subscribers will be
                      billed on the same date each month for monthly plans and the
                      same month/date for annual plans. If you have questions please
                      email us at {helpEmail}
                    </p>
                  </div>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <NTAMemberInfo
        NTAMember={NTAMember}
        setNTAMember={setNTAMember}
        packageDetail={packageDetail}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    profileDetail: state.Profile.user,
    subscriptionList: state.Subscription.subscriptions,
    loader: state.Subscription.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Subscription);
