import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const RemoveClientPop = ({ removeClient, setRemoveClient }) => {
  const handleRemoveClient = () => {
    setRemoveClient(!removeClient);
  };

  return (
    <>
      <Modal
        show={removeClient}
        onHide={handleRemoveClient}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="FormPop"
        scrollable="true"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Merge Clients</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="commonContent">
            <p className="m-0">
              Are you sure you want to remove <b> Lucy Shaw</b> as a client?
              This will disable their access to the site and you will no longer
              be able to view their information.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleRemoveClient}
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleRemoveClient}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveClientPop;
