import { postData, getData, putData, deleteData } from 'helpers/apiHelper'
import { MAIN_PATH, GET_GRAPH_DATA, SYMPTOM_BURDEN_REPORT_V2, RECOMMENDATION_REPORT_V2, PRIORITY_REPORT_V2, BODY_SYMPTOM_BURDEN_GRAPH_V2, GET_FOUNDATION_SYMPTOM_BURDEN_GRAPH, GET_COMPLETED_TODO_LIST, RECOMMENDATION_REPORT_V1, SYMPTOM_BURDEN_REPORT_V1, PRIORITY_REPORT_V1, BODY_SYMPTOM_BURDEN_GRAPH_V1, CLIENT_DATA_ANALYSIS_GRAPHS_V2, CLIENT_DATA_ANALYSIS_REPORTS_V2, CLIENT_DATA_ANALYSIS_PRIORITY_REPORTS_V2, CLIENT_DATA_ANALYSIS_GRAPHS_V1, CLIENT_DATA_ANALYSIS_REPORTS_V1, CLIENT_DATA_ANALYSIS_PRIORITY_REPORTS_V1, COMPLETED_TODO_LIST, COMPLETED_TODO_LIST_V2, COMPLETED_TODO_LIST_V1, SAVE_NOTES, SAVE_TODO_NOTES, GET_TODO_NOTES, WELLNESS_GRAPH_DATA, COMPLETED_WELLNESS_FORMS_LIST, WELLNESS_COMPARISON_GRAPH_DATA } from './apiPath'
import { getGraphData } from 'store/actions/client';

// get graph data
export function getClientGraphData(data, callback) {
  const { graphType, clientId } = data;
  let url = `${MAIN_PATH}${GET_GRAPH_DATA}${clientId}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getGraphData(data, callback));
      return data;
    });
}

// get symptom burden report v1
export function getSymptomBurdenReportV1(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${SYMPTOM_BURDEN_REPORT_V1}${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get recommendation report v1
export function getRecommendationReportV1(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${RECOMMENDATION_REPORT_V1}${clientId}?todoId=${todoId}`;
  // let url = `http://localhost:3000/dev/naq/recommendation/reports/${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get priority report v1
export function getPriorityReportV1(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${PRIORITY_REPORT_V1}${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get body symptom burden graph data
export function getBodySymptomBurdenGraphV1(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${BODY_SYMPTOM_BURDEN_GRAPH_V1}${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get symptom burden report NAQ v2
export function getSymptomBurdenReportV2(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${SYMPTOM_BURDEN_REPORT_V2}${clientId}?todoId=${todoId}`;
  // let url = `http://localhost:3000/dev/naq2/symptom-burden/reports/${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get recommendation report v2
export function getRecommendationReportV2(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${RECOMMENDATION_REPORT_V2}${clientId}?todoId=${todoId}`;
  // let url = `http://localhost:3000/dev/naq2/recommendation/reports/${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get priority report v2
export function getPriorityReportV2(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${PRIORITY_REPORT_V2}${clientId}?todoId=${todoId}`;
  // let url = `http://localhost:3000/dev/naq2/priority/reports/${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get body symptom burden graph data
export function getBodySymptomBurdenGraphV2(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${BODY_SYMPTOM_BURDEN_GRAPH_V2}${clientId}?todoId=${todoId}`;
  // let url = `http://localhost:3000/dev/naq2/symptom-burden/graph/${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get foundation symptom burden graph data
export function getFoundationSymptomBurdenGraph(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${GET_FOUNDATION_SYMPTOM_BURDEN_GRAPH}${clientId}?todoId=${todoId}`;
  return getData(url)
}

// get completed todo list
export function getCompletedTodoListV2(data) {
  const { clientId } = data;
  let url = `${MAIN_PATH}${COMPLETED_TODO_LIST_V2}${clientId}`;
  return getData(url)
}

// get data analysis symptom burden graphs
export function getDataAnalysisGraphsV2(data) {
  const { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DATA_ANALYSIS_GRAPHS_V2}${clientId}`;
  return postData(url, values)
}

// get data analysis symptom burden reports
export function getDataAnalysisReportsV2(data) {
  const { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DATA_ANALYSIS_REPORTS_V2}${clientId}`;
  return postData(url, values)
}

// get data analysis priority comparision reports
export function getDataAnalysisProrityReportsV2(data) {
  const { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DATA_ANALYSIS_PRIORITY_REPORTS_V2}${clientId}`;
  return postData(url, values)
}

// get completed todo list
export function getCompletedTodoListV1(data) {
  const { clientId } = data;
  let url = `${MAIN_PATH}${COMPLETED_TODO_LIST_V1}${clientId}`;
  return getData(url)
}

// get data analysis symptom burden graphs
export function getDataAnalysisGraphsV1(data) {
  const { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DATA_ANALYSIS_GRAPHS_V1}${clientId}`;
  return postData(url, values)
}

// get data analysis symptom burden reports
export function getDataAnalysisReportsV1(data) {
  const { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DATA_ANALYSIS_REPORTS_V1}${clientId}`;
  return postData(url, values)
}

// get data analysis priority comparision reports
export function getDataAnalysisProrityReportsV1(data) {
  const { clientId, values } = data;
  let url = `${MAIN_PATH}${CLIENT_DATA_ANALYSIS_PRIORITY_REPORTS_V1}${clientId}`;
  return postData(url, values)
}

// save todo notes
export function saveTodoNotes(data) {
  const { todoId, values } = data;
  let url = `${MAIN_PATH}${SAVE_TODO_NOTES}`;
  return postData(url, values);
}

// get todo notes
export function getTodoNotes(todoId) {
  let url = `${MAIN_PATH}${GET_TODO_NOTES}${todoId}`;
  return getData(url);
}

// get wellness assessment graph data
export function getWellnessAssessmentData(data) {
  const { todoId, clientId } = data;
  let url = `${MAIN_PATH}${WELLNESS_GRAPH_DATA}${clientId}?todoId=${todoId}`;
  return postData(url);
}

// get completed wellness assessment forms list
export function getWellnessAssessCompletedList(data) {
  const { clientId } = data;
  let url = `${MAIN_PATH}${COMPLETED_WELLNESS_FORMS_LIST}${clientId}`;
  return getData(url)
}

// get wellness assessment comparison graph data
export function getWellnessAssessCompData(data) {
  const { values, clientId } = data;
  let url = `${MAIN_PATH}${WELLNESS_COMPARISON_GRAPH_DATA}${clientId}`;
  return postData(url, values);
}