import {postData, getData} from 'helpers/apiHelper';
import { DASHBOARD, FETCH_BANNER_SETTINGS, FETCH_BASIC_SETTINGS, GETTING_STARTED_PROGRESS, MAIN_PATH } from './apiPath';
import { bannerSettings, basicSettings, dashboardContent, getStartedProgress } from 'store/actions/misc';


// get dashboard content
export function getDashboardContent(callback) {
  let url = `${MAIN_PATH}${DASHBOARD}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(dashboardContent(data, callback));
      return data;
  });
}

// get dashboard content
export function gettingStartedProgress(callback) {
  let url = `${MAIN_PATH}${GETTING_STARTED_PROGRESS}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getStartedProgress(data, callback));
      return data;
  });
}

// get basic settings
export function getBasicSettings(callback) {
  let url = `${MAIN_PATH}${FETCH_BASIC_SETTINGS}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(basicSettings(data, callback));
      return data;
  });
}

// get banner settings
export function getBannerSettings(callback) {
  let url = `${MAIN_PATH}${FETCH_BANNER_SETTINGS}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(bannerSettings(data, callback));
      return data;
  });
} 