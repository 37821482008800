import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DuplicateRecomendationPop from "../Modal/DuplicateRecomendationPop";
import DuplicatedSuccessPopup from "../Modal/DuplicatedSuccessPopup";
import { createRecommendationTemplate, duplicateRecommendation, getClientList, getClientRecommendationList, getClientRecommendationReportsList, getRecommendationReportDetails, getTodoRecommendationDetails, sendRecommToClient, updateRecommendation } from "store/services/clientService";
import SingleRecommendation from "./Components/SingleRecommendation";
import * as actions from "store/actions";

// icons
import TodoAutomatedRecommendation from "./Components/TodoAutomatedRecommendation";

const SubmittedRecommendations = ({
  dispatch,
  customRecommendations,
  clients,
  handleEditRecommendation,
  todoRecommendationList,
  currentClient
}) => {
  const [isDuplicating, setDuplicating] = useState(false);
  const [currentRecommendationId, setCurrentRecommendationId] = useState(null);
  const [duplicatedRecommId, setDuplicatedRecommId] = useState(null);
  const { clientId } = useParams();

  // Modals
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  clients = clients.filter(client => client.id != clientId);

  useEffect(() => {
    getClientListing();
    return () => {
      setCurrentRecommendationId(null);
    }
  }, [])

  const toggleDuplicatePopup = () => {
    setShowDuplicatePopup(!showDuplicatePopup);
  }

  const getClientListing = async () => {
    try {
      let filter = {
        limit: 100,
        type: {
          active: true,
        },
        group: {},
      }
      await dispatch(getClientList(filter));
    } catch (error) {
      console.log("Error while fetching client list");
    }
  }

  const duplicateRecommentationForSameClient = async () => {
    try {
      setDuplicating(true);
      let data = {
        clientId,
        recommendationId: currentRecommendationId
      }
      let response = await dispatch(duplicateRecommendation(data));
      if(response?.success === true) {
        setDuplicatedRecommId(response?.body?.todo_id);
        setShowSuccessPopup(true);
      }
      await dispatch(getClientRecommendationReportsList({ clientId }));
      setDuplicating(false);
      toggleDuplicatePopup();
    } catch (error) {
      setDuplicating(false);
    }
  }

  const duplicateRecommentationForSelectedClient = async (clientId) => {
    try {
      let data = {
        clientId,
        recommendationId: currentRecommendationId
      }
      let response = await dispatch(duplicateRecommendation(data));
      if(response?.success === true) {
        // setDuplicatedRecommId(response?.body?.id);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      throw error;
    }
  }

  const createTemplate = async (templateName) => {
    try {
      let data = {
        recommendation_id: currentRecommendationId,
        template_name: templateName
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(createRecommendationTemplate(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      dispatch(actions.persist_store({ loader: false }));
      throw error;
    }
  }

  const togglePublishRecommendation = async (id, oldStatus) => {
    try {
      let newStatus = oldStatus === "publish" ? "unpublish" : "publish";
      
      let data = {
        recommendationId: id,
        values: {
          for_client: newStatus,
          client_id: clientId
        },
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(updateRecommendation(data));
      await dispatch(getClientRecommendationReportsList({ clientId }));
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("Error in final submit", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const viewRecommendationReport = async (recommendationId) => {
    try {
      let data = {
        recommendationId
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await getRecommendationReportDetails(data);
      dispatch(actions.persist_store({ loader: false }));
      return response;
    } catch (error) {
      dispatch(actions.persist_store({ loader: false }));
      throw error;
    }
  }

  const viewTodoRecommendationDetail = async (todoId) => {
    try {
      let data = {
        todoId
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await getTodoRecommendationDetails(data);
      dispatch(actions.persist_store({ loader: false }));
      return response;
    } catch (error) {
      dispatch(actions.persist_store({ loader: false }));
      throw error;
    }
  }

  const sendRecommEmailCallback = async (id, pdfContents, callback) => {
    try {
      let data = {
        client_id: clientId,
        recommendation_pdf: pdfContents
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await sendRecommToClient(id, data);
      if(response.success === true){
        typeof callback == "function" && callback();
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error occurred while fetching recommendation details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <DuplicateRecomendationPop
        showPopup={showDuplicatePopup}
        togglePopup={toggleDuplicatePopup}
        handleDuplicateForSameClient={duplicateRecommentationForSameClient}
        handleChooseClient={duplicateRecommentationForSelectedClient}
        createTemplate={createTemplate}
        isDuplicating={isDuplicating}
        clients={clients}
      />
      <DuplicatedSuccessPopup
        showPopup={showSuccessPopup}
        handleClose={() => {
          setShowSuccessPopup(false);
        }}
        recommendationId={duplicatedRecommId}
        viewRecommendation={handleEditRecommendation}
      />
      <Accordion className="my-3 ColoredAcorrdion">
        {customRecommendations && customRecommendations.length > 0 &&
          customRecommendations.map(recommendation => {
            return (
              <SingleRecommendation
                key={recommendation.todo_id}
                recommendation={recommendation}
                toggleDuplicatePopup={toggleDuplicatePopup}
                getDetails={viewRecommendationReport}
                handleEditRecommendation={handleEditRecommendation}
                togglePublishRecommendation={togglePublishRecommendation}
                setCurrentRecommId={setCurrentRecommendationId}
                sendEmailCallback={sendRecommEmailCallback}
              />
            )
          })
        }

        {todoRecommendationList && todoRecommendationList.length > 0 &&
          todoRecommendationList.map(todo => {
            return (
              <TodoAutomatedRecommendation
                key={todo.todo_id}
                todo={todo}
                toggleDuplicatePopup={toggleDuplicatePopup}
                getDetails={viewTodoRecommendationDetail}
                handleEditRecommendation={handleEditRecommendation}
                client={currentClient}
              />
            )
          })
        }
      </Accordion>
    </>
  )
}



const mapStateToPros = (state) => {
  let activeRecommendations = state.Client?.client?.recommendationReports?.customRecList || [];
  let todoRecommendationList = state.Client?.client?.recommendationReports?.baseTodoRecList || [];
  return {
    clients: state.Client.clients,
    currentClient: state.Client.client.details,
    customRecommendations: activeRecommendations,
    todoRecommendationList,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubmittedRecommendations);