import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, RESOURCE_LIST, RESOURCE_ADD, RESOURCE_UPDATE, RESOURCE_VIEW, RESOURCE_DELETE, RESOURCE_CATEGORIES  } from './apiPath'
import { getResource, addResource, viewResource, updateResource, deleteResource, resourcePagination, resourceCategories} from 'store/actions/resource';

//get
export function getResourceList(value, callback) {
  const { page, limit, orderBy, sortOrder, search } = value || {};
  let url = `${MAIN_PATH}${RESOURCE_LIST}`;
  // if (page !== undefined && limit !== undefined && orderBy !== undefined && sortOrder !== undefined && search !== undefined) {
  //   url += `?page=${page}&limit=${limit}&orderBy=${orderBy}&sortOrder=${sortOrder}&search=${search}`;
  // }
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(getResource(data, callback));
      return data;
    });
}

//add
export function addResourceData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${RESOURCE_ADD}`, value)
    .then((data) => {
      dispatch(addResource(data, callback));
      return data;
    });
}

//view
export function viewResourceData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${RESOURCE_VIEW}${value}`)
    .then((data) => {
      dispatch(viewResource(data, callback));
      return data;
    });
}


export function updateResourceData(value, callback) {
  return (dispatch) => {
    dispatch(updateResource(value));
    return value
  };
}


//delete
export function deleteResourceData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${RESOURCE_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteResource(data));
      return data;
    });
}

//pagination
export function resourcePagination_(data, callback) {
  return (dispatch) => {
    dispatch(resourcePagination(data, callback));
    return data;
  };
}

// get resource categories
export function getResourcesCategories() {
  return (dispatch) => getData(`${MAIN_PATH}${RESOURCE_CATEGORIES}`)
    .then((data) => {
       dispatch(resourceCategories(data));
      return data;
    });
}