import React, { useState } from "react";
import moment from "moment";

const SelectForms = ({ formList, selectedForms, onChange }) => {
  const handleChangeSelectedForm = (e) => {
    let targetValue = parseInt(e.target.value);
    const prevSelected = [...selectedForms];
    let isSelected = prevSelected.find(value => value == targetValue);
    let newValues = [];
    if(isSelected) {
      newValues = prevSelected.filter(value => value != targetValue);
    } else {
      prevSelected.push(targetValue);
      newValues = prevSelected;
    }

    // get the first last two values, can only select two items
    if(newValues.length > 2) {
      newValues = newValues.slice(-2);
    }

    onChange(newValues);
  }

  return (
    <div className="py-2">
      <h6>Select 2 forms to compare:</h6>
      {formList && formList.length > 0 &&
        formList.map(form => {
          return (
            <label
              className="mx-2"
              style={{ borderBottom: `4px solid ${form.color}` }}
            >
              <input
                className=""
                type="checkbox"
                value={form.id}
                onChange={handleChangeSelectedForm}
                checked={selectedForms.includes(form.id)}
              />
              <span className="px-2">{moment(form.updated_at).format("MMM DD, YYYY")}</span>
            </label>
          )
        })
      }
    </div>
  )
}

export default SelectForms;