const Scale = ({ question }) => {
  let scaleColors = [
    "#D92C27",
    "#D9534F",
    "#DF706D",
    "#E89996",
    "#F1C2C0",
    "#D9D9D9",
    "#DCEFDC",
    "#B8E0B8",
    "#95D095",
    "#71C171",
    "#5CB85C",
  ];

  let columnData = {
    scale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  };

  try {
    columnData = JSON.parse(question.other_column_data);
    columnData.scale =
      columnData.scale.length > 0 ? columnData.scale : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  } catch (error) {
    console.log("unable to parse column data for the question", question);
  }

  return (
    <div className="CstmScale position-relative py-2">
      <ul className="list-unstyled ps-0 mb-0 position-relative d-flex align-items-center">
        {columnData?.scale &&
          columnData?.scale?.length > 0 &&
          columnData.scale.map((scaleIndex) => {
            let color = scaleColors[scaleIndex];
            return (
              <li className="position-relative">
                <input type="radio" className="position-absolute w-100 h-100" disabled />
                <div
                  className="cstmBox p-1 d-flex align-items-center justify-content-center position-relative text-center"
                  style={{ background: color }}
                >
                  <p className="m-0 fw-sbold text-dark">{scaleIndex}</p>
                </div>
              </li>
            );
          })}
      </ul>
      <div class="CstmScale-information py-2">
        {columnData && columnData?.minTxt && (
          <p className="m-0 fw-normal text-muted">{`${columnData?.minNum}: ${columnData?.minTxt}`}</p>
        )}
        {columnData && columnData?.maxTxt && (
          <p className="m-0 fw-normal text-muted">{`${columnData?.maxNum}: ${columnData?.maxTxt}`}</p>
        )}
      </div>
    </div>
  );
};

export default Scale;
