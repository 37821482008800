import Pagination from "react-js-pagination";
import { Dropdown, Button } from 'react-bootstrap';
import PageSizeDropdown from "./PageSizeDropdown";

export default ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  handlePageChange,
  showPageSizeDropdown,
  pageSizeOptions,
  onPageSizeChange,
  ...props
}) => {
  const pageRange = 5;
  return (
    <div className="px-3 py-3 paginationWrpper d-flex">
      {itemsCountPerPage < totalItemsCount && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={(page) => handlePageChange(page)}
          itemClass="page-item"
          linkClass="page-link"
          innerClass="pagination m-0"
        />
      )}
      {showPageSizeDropdown === true && (
        <div className="ms-3 d-flex align-items-center">
          <p className="m-0 me-2">Page Size:</p>
          <PageSizeDropdown 
            selectedPageSize={itemsCountPerPage}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </div>
  );
};