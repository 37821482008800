import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ChooseTemplatePopup from "./ChooseTemplatePopup";
import { HEALTH_COATCH_CAT_ID, PRACTITIONER_CAT_ID } from "common/constants";

const NewRecomendationPop = ({
  recommendationPop,
  setRecommendationPop,
  handleNewRecommendatition,
  newRecommendationFromTemplate,
  recommendationTemplates,
  viewRecommendation,
  handleCreateFullscriptRecomm,
  fullscriptConnection,
  handleNewActionPlan,
  clientCategory,
  itemName,
}) => {
  const [showSelectTemplatePopup, setShowSelectTemplatePopup] = useState(false);
  const openSelectTemplatePopup = () => {
    setRecommendationPop(false);
    setShowSelectTemplatePopup(true);
  };

  const handleRecommendation = () => {
    setRecommendationPop(!recommendationPop);
  };

  return (
    <>
      <ChooseTemplatePopup
        showPopup={showSelectTemplatePopup}
        closePopup={() => {
          setShowSelectTemplatePopup(false);
        }}
        handleTemplateSelect={newRecommendationFromTemplate}
        recommendationTemplates={recommendationTemplates}
        viewRecommendation={viewRecommendation}
      />
      <Modal
        show={recommendationPop}
        onHide={handleRecommendation}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>New {itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg="8" className="my-2">
              {clientCategory === PRACTITIONER_CAT_ID && (
                <>
                  <div className="py-1">
                    {fullscriptConnection &&
                      <Button
                        className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                        onClick={handleCreateFullscriptRecomm}
                      >
                        New Fullscript Recommendation
                      </Button>
                    }
                  </div>
                  <div className="py-1">
                    <Button 
                      className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                      onClick={handleNewRecommendatition}
                    >
                      New Recommendation
                    </Button>
                  </div>
                  <div className="py-1">
                    <Button
                      onClick={openSelectTemplatePopup}
                      className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                    >
                      New Recommendation From Template
                    </Button>
                  </div>
                </>
              )}

              {clientCategory === HEALTH_COATCH_CAT_ID && (
                <div className="py-1">
                  <Button 
                    className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                    onClick={handleNewActionPlan}
                  >
                    New Action Plan
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleRecommendation}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewRecomendationPop;
