import { Text, StyleSheet, View } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  disclaimer: {
    position: "absolute",
    bottom: "20",
    left: "0",
    right: "0",
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
    padding: "20px",
  }
});

export const PDFDisclaimer = ({ fixed }) => {
  return (
    <View style={styles.disclaimer}>
      <Text style={{ width: "80%" }}>
        Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
        These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
      </Text>
      <Text render={({ pageNumber, totalPages }) => (
        `${moment().format("MMM DD, YYYY")} | ${pageNumber}`
      )} fixed={!!fixed} />
    </View>
  )
}
