import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

const MobileLayout = ({ loader }) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  let [color, setColor] = useState("#f4694b");

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const accessToken = queryParams.get("access_token");
  //   if(!accessToken) {
  //     setError("Authentication error!");
  //     return;
  //   }
  //   localStorage.setItem("access_token", accessToken);
  //   setIsAuthenticated(true);
  // }, []);

  return (
    <>
      <main className="mx-auto">
        {loader == true && (
          <div className="loader-overlay">
            <div className="main-loader_">
              <FadeLoader
                color={color}
                loading={loader}
                css={override}
                size={300}
              />
            </div>
          </div>
        )}
          <Outlet />
      </main>
    </>
  );
};

// export default NonAuthLayout;

const mapStateToPros = (state) => {
  return {
    loader: state.persistStore.loader,
  };
};

export default connect(mapStateToPros)(MobileLayout);
