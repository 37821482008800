import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { string } from "yup";

import Form from "./Form";

// css
import "./ClientPriorities.scss";

const ClientPrioritiesPop = ({ showClientPrioritiesPopup, togglePrioritesPopup, clientPriorities, updatePrioritiesForClient }) => {
  const getInitialValues = () => {
    let initialValues = [];
    const checkForBlankSpaces = (value) => {
      if(value) {
        return !value.match(/^\s+$/);
      }
      return true;
    }
    [1, 2, 3, 4, 5].map((index, i) => {
      let newValues = [
        {
          name: `client_current_concerns_${index}`,
          value: clientPriorities.currentClientConcerns[i] || "",
          type: string().test('check-spaces', "Invalid value", checkForBlankSpaces)
        },
        {
          name: `client_current_priorities_${index}`,
          value: clientPriorities.currentPriorites[i] || "",
          type: string().test('check-spaces', "Invalid value", checkForBlankSpaces)
        },
        {
          name: `client_short_term_goals_${index}`,
          value: clientPriorities.shortTermGoals[i] || "",
          type: string().test('check-spaces', "Invalid value", checkForBlankSpaces)
        },
        {
          name: `client_medications_${index}`,
          value: clientPriorities.medications[i] || "",
          type: string().test('check-spaces', "Invalid value", checkForBlankSpaces)
        },
        {
          name: `client_food_allergies_${index}`,
          value: clientPriorities.foodAllergies[i] || "",
          type: string().test('check-spaces', "Invalid value", checkForBlankSpaces)
        }
      ]
      initialValues = [...initialValues, ...newValues];
      // initialValues[`client_current_concerns_${index}`] = clientPriorities.currentClientConcerns[i] || "";
      // initialValues[`client_current_priorities_${index}`] = clientPriorities.currentPriorites[i] || "";
      // initialValues[`client_short_term_goals_${index}`] = clientPriorities.shortTermGoals[i] || "";
    })

    return initialValues;
  }

  const handleSubmit = async (values, actions) => {
    try {
      await updatePrioritiesForClient(values, actions);
      togglePrioritesPopup();
    } catch(error) {
      console.log("Error updating client priorities");
    }
  }

  return (
    <>
      <Modal
        show={showClientPrioritiesPopup}
        onHide={togglePrioritesPopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop ClientPriorities"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Client Priorites</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4 pt-0">
          <Form
            fields={getInitialValues()}
            handleSubmit={handleSubmit}
            togglePrioritesPopup={togglePrioritesPopup}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientPrioritiesPop;
