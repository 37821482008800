import React, { useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";

import { FULL_NAME } from "helpers/common";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { useParams } from "react-router-dom";
import { getFoodMoodLogsList } from "store/services/clientService";
import ViewLog from "./Components/ViewLog"

const FoodMoodLogs = ({
  dispatch,
  clientDetails,
  logList,
  comments
  // accountSettings,
}) => {
  const { clientId } = useParams();

  useEffect(() => {
    getFMLogs();
  }, []);

  const getFMLogs = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getFoodMoodLogsList(clientId));
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("Error loading food mood logs", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  let clientFullName = () => {
    return FULL_NAME(clientDetails.first_name, clientDetails.last_name);
  }

  return (
    <>
      <div className="CardBody py-3">
        <div className="commonCardBox">
          <ViewLog 
            logList={logList}
            comments={comments}
            getLogs={getFMLogs}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  console.log("state foodMoodLogs", state.Client.client.foodMoodLogs)
  return {
    client: state.Client.client.details,
    loadingClientDetails: state.Client.loadingClientDetails,
    logList: state.Client.client?.foodMoodLogs?.log_data,
    comments: state.Client.client?.foodMoodLogs?.comments,
  };
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FoodMoodLogs);