//action types
export const GET_PRACTITIONER = 'GET_PRACTITIONER';
export const ADD_PRACTITIONER = 'ADD_PRACTITIONER';
export const VIEW_PRACTITIONER = 'VIEW_PRACTITIONER';
export const UPDATE_PRACTITIONER = 'UPDATE_PRACTITIONER';
export const DELETE_PRACTITIONER = 'DELETE_PRACTITIONER';

//get 
export function getPractitioner(data, callback) {
  return {
    type: GET_PRACTITIONER,
    payload : {data, callback},
  };
}
//add 
export function addPractitioner(data, callback) {
  return {
    type: ADD_PRACTITIONER,
    payload : {data, callback},
  };
}

//view 
export function viewPractitioner(data, callback) {
  return {
    type: VIEW_PRACTITIONER,
    payload : {data, callback},
  };
}
//update 
export function updatePractitioner(data, callback) {
    return {
      type: UPDATE_PRACTITIONER,
      payload : {data, callback},
    };
}
  
//delete 
export function deletePractitioner(payload) {
  return {
    type: DELETE_PRACTITIONER,
    payload
  };
}
