import { GET_RESOURCE, ADD_RESOURCE, VIEW_RESOURCE, UPDATE_RESOURCE, DELETE_RESOURCE, RESOURCE_PAGINATION, RESOURCE_CATEGORIES } from 'store/actions/resource';

const initialState = {
    isAuthenticated: false,
    resources: [],
    resource: {},
    resourceCategories: [],
    loader:false,
    activePage:1,
    totalItemsCount:0,
    itemsCountPerPage:10,
};

//get 
const getResource = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        resources:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count,
        resource: {},
    };
  return stateObj;
};

//add 
const addResource = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      resources:data.body.data,
      resource: {},
    };
    return stateObj;
  };

  //view 
const viewResource = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      resource:data.body,
    };
    return stateObj;
};

  //update 
const updateResource = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      resources:data,
      resource: {},
    };
    return stateObj;
};

//delete 
const deleteResource = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    resources:[],
    resource: {},
  };
  return stateObj;
};

//pagination
const resourcePagination = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    activePage:data,
  };
  return stateObj;
};

// categories
const resourceCategories = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    resourceCategories: data?.body || [],
  };
  return stateObj;
};


const Resource = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_RESOURCE:
          return getResource(state, payload);
        case ADD_RESOURCE:
            return addResource(state, payload);
        case VIEW_RESOURCE:
          return viewResource(state, payload);
        case UPDATE_RESOURCE:
          return updateResource(state, payload);
        case DELETE_RESOURCE:
          return deleteResource(state, payload);
        case RESOURCE_PAGINATION:
          return resourcePagination(state, payload);
        case RESOURCE_CATEGORIES:
          return resourceCategories(state, payload);
        default:
        return state;
    };
}
export default Resource;