
//action types
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_UPLOAD_IMAGE = 'DELETE_UPLOAD_IMAGE';

//upload image
export function uploadImage(data, callback) {
    return {
      type: UPLOAD_IMAGE,
      payload : {data, callback},
    };
  }
  
  //delete image
  export function deleteUploadImage(data, callback) {
    return {
      type: DELETE_UPLOAD_IMAGE,
      payload : {data, callback},
    };
  }