import { decode } from "html-entities";
import { Text, View } from '@react-pdf/renderer';

const DatePicker = ({ question }) => {
  return (
    <View>
      <Text
        style={{
          border: "1px solid #ccc",
          borderRadius: 5,
          width: "50%",
          height: "30px",
          padding: "5px",
          paddingTop: "8px",
          marginBottom: "5px",
          color: "#333"
        }}>
        dd/mm/yyyy
      </Text>
    </View>
  );
};

export default DatePicker;
