import React from "react";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';

// ICN
import { ReactComponent as DownloadIcn } from "../../../../../Assets/Images/icn/download.svg";
import { ReactComponent as DeleteIcn } from "../../../../../Assets/Images/icn/delete.svg";
import { ReactComponent as CopyIcn } from "../../../../../Assets/Images/icn/Copy.svg";
import { FORMAT_TEXT } from 'helpers/common'

const TableData = ({filteredResults, Columns}) => {

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <Columns />
            </tr>
          </thead>
          {(filteredResults.length > 0) && 
          <tbody>
              {filteredResults.map((item, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <td className="p-3 border-0">
                        <b className="theme-clr"><a href={item.resource_media} target="_blank">{FORMAT_TEXT(item.resource_name)}</a></b>
                      </td>
                      <td className="p-3 border-0">
                        <p className="theme-clr m-0">{item.category_name}</p>
                      </td>
                      <td className="p-3 border-0">
                        <p className="theme-clr m-0">
                          {item.subcategory_name ? item.subcategory_name : "-"}
                        </p>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
          }
        </table>
      </div>
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    activePage : state.Client.activePage,
    itemsCountPerPage : state.Client.itemsCountPerPage,
    totalItemsCount : state.Client.totalItemsCount,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TableData);
