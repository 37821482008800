import * as Yup from 'yup';
import { EMAIL_REGEX } from 'common/constants';

const validation = Yup.object({
    first_name: Yup.string().trim().required("Required"),
    last_name: Yup.string().trim().required("Required"),
    // standard_account: Yup.string().required("Required"),
    // nta_member_status: Yup.string().required("Required"),
    email: Yup.string().trim().required("Email Required").matches(EMAIL_REGEX, "Invalid email address"),
});

export default validation;
