import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import parse from 'html-react-parser';

const FormDetailsPopup = ({
  showPopup,
  handleClose,
  content,
}) => {
  return (
    <>
      <Form>
        <Modal
          show={showPopup}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              { content && parse(content) }
            </div>
          </Modal.Body>
          <Modal.Footer className="FormbtnWrpper">
            <Button
              type="submit"
              onClick={handleClose}
              className="d-flex align-items-center justify-content-center commonBtn btnSm"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default FormDetailsPopup;
