import React, { useEffect } from "react";
import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//imported
import PrivateRoute from "./PrivateRoute";

import "../Assets/css/style.css";
import * as Path from "./paths";
import Login from "pages/AuthPages/Login/Index";
import AuthLayout from "layout/AuthLayout/AuthLayout";
import NonAuthLayout from "layout/NonAuthLayout/NonauthLayout";
import Dashboard from "pages/SideTabs/Dashboard/Index";
import MyClients from "pages/SideTabs/MyClients/Index";
import AddNewClient from "pages/SideTabs/AddNewClient/Index";
import ClientDetail from "pages/SideTabs/MyClients/Detail/Index";
import Research from "pages/SideTabs/Research/Index";
import Appointment from "pages/SideTabs/Appointment/Index";
import MyDocs from "pages/SideTabs/Docs&Forms/MyDocs/Index";
import MyForm from "pages/SideTabs/Docs&Forms/MyForms/Index";
import MyResources from "pages/SideTabs/Docs&Forms/Resources/Index";
import MyAccount from "pages/SideTabs/MyAccount/Index";
import SignUp from "pages/AuthPages/SignUp/Index";
import TermsCondition from "pages/SideTabs/TermsCondition/Index";
import TermsConditions from "pages/SideTabs/TermsConditions/Index";
import PrivacyPolicy from "pages/SideTabs/PrivacyPolicy/Index";
import Subscription from "pages/SideTabs/Subscription/Index";
import SubscriptionPayment from "pages/SideTabs/Subscription/Payment/Index";
import SubscriptionSuccess from "pages/SideTabs/SubscriptionSuccess/Index";
import EditForm from "pages/SideTabs/Docs&Forms/MyForms/Edit/Index";
import ForgotPassword from "pages/AuthPages/ForgotPassword/Index";
// import OTP from "pages/AuthPages/Otp/Index";
import VerifyOTP from "pages/AuthPages/VerifyOtp/Index";
import ResetPassword from "pages/AuthPages/ResetPassword/Index";
import Layout from "layout/Layout/Layout";

// Help pages
import HelpCreateAccount from "pages/Help/CreateAccount/Index";
import HelpAddFirstClient from "pages/Help/AddFirstClient/Index";
import HelpDirectLinkClient from "pages/Help/DirectClientLink";
import HelpAppointmentScheduler from "pages/Help/Scheduler/AppointmentScheduler";
import HelpAppointmentPage from "pages/Help/Scheduler/AppointmentPage";
import HelpScheduleSetup from "pages/Help/Scheduler/ScheduleSetUp";
import HelpBookAppointment from "pages/Help/Scheduler/BookingAppointment";
import HelpAcceptPayment from "pages/Help/Scheduler/AcceptPayment";
import HelpPayinlglAppointment from "pages/Help/Scheduler/PayingAppointment";
import HelpGoogleLinkAccount from "pages/Help/GoogleCalender/LinkAccount";
import HelpGoogleAppointmentSycing from "pages/Help/GoogleCalender/AppointmentSyncing";
import HelpFullScriptLinkingAccount from "pages/Help/ScriptIntegeration/LinkingYourAccount";
import HelpFullScriptLinkingClients from "pages/Help/ScriptIntegeration/LinkingClients";
import HelpFullScriptCreateRecommendation from "pages/Help/ScriptIntegeration/CreateRecommendation";
import HelpFullScriptNaqRecommendation from "pages/Help/ScriptIntegeration/NaqRecommendation";
import HelpFullScriptClientOrders from "pages/Help/ScriptIntegeration/ClientOrders";
import HelpStripeLinkAccount from "pages/Help/Stripe/LinkAccount";
import HelpStripePaymentMethod from "pages/Help/Stripe/PaymentMethod";
import HelpStripeIncompletePayment from "pages/Help/Stripe/IncompletePayment";
import HelpPracticeLinkAccount from "pages/Help/Practice/LinkAccount";
import HelpPracticeSendingViewingNaq from "pages/Help/Practice/SendingViewingNaq";
import HelpMyDoc from "pages/Help/MyDoc";
import HelpMyForm from "pages/Help/MyForm";
import HelpPackagesCreate from "pages/Help/Packages/Create";
import HelpPackagesSendingPurchasing from "pages/Help/Packages/SendingPurchasing";
import HelpPackagesStatus from "pages/Help/Packages/Status";
import HelpNaqIntroduction from "pages/Help/NaqIntroduction";
import HelpFoodFMJournal from "pages/Help/Food/FoodMoodJournal";
import HelpFoodFeelingLog from "pages/Help/Food/FoodFeelingLog";
import HelpFoodLoggingComparison from "pages/Help/Food/LoggingComparison";
import HelpClientSelfRegisteration from "pages/Help/ClientSelfRegisteration";
import HelpFAQ from "pages/Help/FAQs";
import MyFormPreview from "pages/SideTabs/Docs&Forms/MyForms/Component/FormListing/Components/formPreview";
import TodoReports from "pages/Mobile/TodoReports";
import MobileLayout from "layout/MobileLayout/MobileLayout";

// const Appointment = lazy(() => import("./pages/SideTabs/Appointment/Index"));

function routes({ isAuthenticated }) {
  return (
    <>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Routes>
        <Route element={isAuthenticated ? <NonAuthLayout /> : <AuthLayout />}>
          {isAuthenticated ? (
            <>
              <Route index element={<Navigate to={Path.dashboard} />} />
              <Route index path={Path.dashboard} element={<Dashboard />} />
              <Route path={Path.dashboardSubscription} element={<Subscription />} />
              <Route path={Path.subscriptionSuccess} element={<SubscriptionSuccess />} />
              <Route path={Path.dashboardSubscriptionPayment} element={<SubscriptionPayment />} />
              <Route path={Path.dashboardTermsCondition} element={<TermsCondition />} />
              <Route path={Path.privacyPolicy} element={<PrivacyPolicy />} />
              <Route path={Path.termsConditions} element={<TermsConditions />} />
              <Route path={Path.addClient} element={<AddNewClient />} />
              <Route path={Path.myClients} element={<MyClients />} />
              <Route path={Path.myClientDetails} element={<ClientDetail pageTitle="Client Details"/>} />
              <Route path={Path.foodResearch} element={<Research />} />
              <Route path={Path.appointment} element={<Appointment />} />
              <Route path={Path.myDocs} element={<MyDocs />} />
              <Route path={Path.myForms} element={<MyForm />} />
              <Route path={Path.addNewForm} element={<EditForm />} />
              <Route path={Path.editForm} element={<EditForm />} />
              <Route path={Path.resources} element={<MyResources />} />
              <Route path={Path.myAccount} element={<MyAccount />} />
              <Route path={Path.myFormsPreview} element={<MyFormPreview />} />

              {/* Default route */}
              <Route path="*" element={<Navigate to={Path.dashboard} />} />
            </>
          ) : (
            <>
              <Route index element={<Navigate to={Path.login} />} />
              <Route path={Path.login} element={<Login />} />
              <Route path={Path.signup} element={<SignUp />} />
              <Route path={Path.forgotPassword} element={<ForgotPassword />} />
              <Route path={Path.verifyOTP} element={<VerifyOTP />} />
              <Route path={Path.resetPassword} element={<ResetPassword />} />

              {/* Default route */}
              <Route path="*" element={<Navigate to={Path.login} />} />
            </>
          )}
        </Route>
        <Route element={<Layout />}>
          <Route path={Path.helpCreateAccount} element={<HelpCreateAccount />} />
          <Route path={Path.helpAddFirstClient} element={<HelpAddFirstClient />} />
          <Route path={Path.helpDirectLinkClient} element={<HelpDirectLinkClient />} />
          <Route path={Path.helpAppointmentScheduler} element={<HelpAppointmentScheduler />} />
          <Route path={Path.helpAppointmentPage} element={<HelpAppointmentPage />} />
          <Route path={Path.HelpScheduleSetup} element={<HelpScheduleSetup />} />
          <Route path={Path.HelpBookAppointment} element={<HelpBookAppointment />} />
          <Route path={Path.HelpAcceptPayment} element={<HelpAcceptPayment />} />
          <Route path={Path.HelpPayinlglAppointment} element={<HelpPayinlglAppointment />} />
          <Route path={Path.HelpGoogleLinkAccount} element={<HelpGoogleLinkAccount />} />
          <Route
            path={Path.HelpGoogleAppointmentSycing}
            element={<HelpGoogleAppointmentSycing />}
          />
          <Route path={Path.HelpFullScriptLinkAccount} element={<HelpFullScriptLinkingAccount />} />
          <Route path={Path.HelpFullScriptLinkClients} element={<HelpFullScriptLinkingClients />} />
          <Route
            path={Path.HelpFullScriptCreateRecommendation}
            element={<HelpFullScriptCreateRecommendation />}
          />
          <Route
            path={Path.HelpFullScriptNaqRecommendation}
            element={<HelpFullScriptNaqRecommendation />}
          />
          <Route path={Path.HelpFullScriptClientOrders} element={<HelpFullScriptClientOrders />} />
          <Route path={Path.HelpStripeLinkAccount} element={<HelpStripeLinkAccount />} />
          <Route path={Path.HelpStripePaymentMethod} element={<HelpStripePaymentMethod />} />
          <Route
            path={Path.HelpStripeIncompletePayment}
            element={<HelpStripeIncompletePayment />}
          />
          <Route path={Path.HelpPracticeLinkAccount} element={<HelpPracticeLinkAccount />} />
          <Route
            path={Path.HelpPracticeSendingViewingNaq}
            element={<HelpPracticeSendingViewingNaq />}
          />
          <Route path={Path.HelpMyDoc} element={<HelpMyDoc />} />
          <Route path={Path.HelpMyForm} element={<HelpMyForm />} />
          <Route path={Path.HelpPackagesCreate} element={<HelpPackagesCreate />} />
          <Route
            path={Path.HelpPackagesSendingPurchasing}
            element={<HelpPackagesSendingPurchasing />}
          />
          <Route path={Path.HelpPackagesStatus} element={<HelpPackagesStatus />} />
          <Route path={Path.HelpNaqIntroduction} element={<HelpNaqIntroduction />} />
          <Route path={Path.HelpFoodFMJournal} element={<HelpFoodFMJournal />} />
          <Route path={Path.HelpFoodFeelingLog} element={<HelpFoodFeelingLog />} />
          <Route path={Path.HelpFoodLoggingComparison} element={<HelpFoodLoggingComparison />} />
          <Route
            path={Path.HelpClientSelfRegisteration}
            element={<HelpClientSelfRegisteration />}
          />
          <Route path={Path.HelpFAQ} element={<HelpFAQ />} />
          <Route path={Path.PublicTermsConditions} element={<HelpFAQ />} />
        </Route>
        {/* Mobile App Routes */}
        <Route element={<MobileLayout />}>
          <Route path={Path.mobileTodoReports} element={<TodoReports />} />
        </Route>
        <Route path={Path.defaultRoute} element={<h1>Page not found:(</h1>} />
      </Routes>
      {/* </Suspense> */}
    </>
  );
}

const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(routes);
