import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { addFormPage, editFormPage } from "store/services/myFormService";
import { toggleAddPagePopup } from "store/actions/myForm";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "common/constantMessage";

// css
import "./NewPage.scss";

// icn
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";

const NewPagePopup = ({
  dispatch,
  showPopup,
  formId,
  isEditMode,
  editPageId,
  pageDetails,
  formDetails,
  getFormDetails
}) => {
  const [dependableQuestions, setDependableQuestions] = useState(null);
  const [showNoDependentMessage, setShowNoDependentMessage] = useState(false);

  const handleClose = () => dispatch(toggleAddPagePopup(false));

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try{
      setSubmitting(true);
      const callback = async (response) => {
        if(response.success === true && response.message) {
          SUCCESS_MESSAGE(response.message);
        }
      }

      // stringify columns
      if(Array.isArray(values.depend_q_ans)) {
        values.depend_q_ans = JSON.stringify(values.depend_q_ans);
      }

      // prevent unwanted values from being submitted
      delete(values.dependentQuestion);

      if(isEditMode == true) {
        // update the page
        await dispatch(editFormPage(values, editPageId, callback))  
      } else {
        // save page
        await dispatch(addFormPage(values, callback))
      }
      await getFormDetails();
      setSubmitting(false);
      resetForm();
      handleClose();
    } catch (err) {
        console.log(err,  "==============================");
        setSubmitting(false);
    }
  }

  useEffect(() => {
    // get the questions that can be selected for dependency
    if(showPopup) {
      let prevPages = [...formDetails?.pages];
      if(editPageId) {
        let currentPageIndex = formDetails?.pages.findIndex(page => page.id === editPageId);
        prevPages = prevPages.splice(0, currentPageIndex);
      }

      let allQuestions = [];
      for(let i = 0; i < prevPages?.length; i++) {
        let questions = prevPages[i].questions;
        allQuestions = [...allQuestions, ...questions];
      }

      let questionsEligible = allQuestions.filter(q => q.form_column_id == 3 || q.form_column_id == 7);
      setDependableQuestions(questionsEligible);
    }
  }, [showPopup, editPageId])

  return (
    <>
    <Formik
        initialValues={{
          title: isEditMode ? pageDetails.title : "",
          form_id: formId,
          help_text: isEditMode ? (pageDetails.help_text || "") : "",
          dependentQuestion: isEditMode ? !!pageDetails.depend_q_id : false,
          depend_q_id: isEditMode ? pageDetails.depend_q_id : null,
          depend_q_ans: isEditMode ?
            (pageDetails.depend_q_ans && JSON.parse(pageDetails.depend_q_ans)) : "",
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Modal
              show={showPopup}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered
              scrollable={true}
              className="NewPagePop FormPop"
            >
              <Modal.Header className="">
                <Modal.Title className="fw-bold">{isEditMode ? "Update Page" : "New Page" }</Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-lg-4">
              <Form id="addPageForm" onSubmit={handleSubmit}>
                <Row className="py-2">
                  <Col lg="12" className="my-2">
                    <label
                      htmlFor=""
                      className="form-label pb-1 m-0 fw-sbold text-dark"
                    >
                      Page Title{" "}
                      {/* <span className="m-0 d-inline-block fw-normal">
                        (optional)
                      </span> */}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.title && 'is-invalid'}`}
                      name="title"
                      onChange={handleChange}
                      placeholder="Page Title"
                      onBlur={handleBlur}
                      value={values.title}
                      autoComplete="off"
                    />
                    {(errors.title && touched.title) &&
                      <p className="invalid-feedback">{errors.title}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <label
                      htmlFor=""
                      className="form-label pb-1 m-0 fw-sbold text-dark"
                    >
                      Page Help Text{" "}
                      <span className="m-0 d-inline-block fw-normal">
                        (optional)
                      </span>
                    </label>
                    <p className="m-0">
                      Any additional information or clarification about the page.
                    </p>
                    <input
                      type="text"
                      className={`form-control ${errors.help_text && 'is-invalid'}`}
                      name="help_text"
                      onChange={handleChange}
                      placeholder="Help Text"
                      onBlur={handleBlur}
                      value={values.help_text}
                      autoComplete="off"
                    />
                    {(errors.help_text && touched.help_text) &&
                      <p className="invalid-feedback">{errors.help_text}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <div className="py-2 d-flex align-items-start gap-10">
                      <div className="cstmInputCheckWrp position-relative ">
                        <input
                          type="checkbox"
                          name="c-ToDo"
                          className="position-absolute w-100 h-100"
                          onChange={(e) => {
                            setFieldValue("dependentQuestion", e.target.checked);
                            setFieldValue("depend_q_id", null);
                            setFieldValue("depend_q_ans", "");
                          }}
                          checked={values.dependentQuestion}
                          disabled={!dependableQuestions || !dependableQuestions?.length}
                        />
                        <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                          <span className="icn d-none">
                            <OTick />
                          </span>
                        </div>
                      </div>
                      <p className=" m-0 ">
                        Make showing this page dependent on the answer to a previous
                        question{" "}
                        {!dependableQuestions || !dependableQuestions?.length &&
                          <Link
                            className="underline"
                            onClick={() => setShowNoDependentMessage(!showNoDependentMessage)}
                          >
                            Why can't I select this?
                          </Link>
                        }
                      </p>
                    </div>
                    {showNoDependentMessage &&
                      <p className="m-0">To make a question dependent on another question, you must have at least one previous Radio Button or Checkbox style question.</p>
                    }
                  </Col>
                  {values.dependentQuestion && 
                    <Col lg="12" className="my-2">
                      <label
                        htmlFor=""
                        className="form-label pb-1 m-0 fw-sbold text-dark"
                      >
                        Dependent Question
                      </label>
                      <p className="m-0">
                        Select the question used to determine if this question is displayed or not. Only radio button or checkbox questions prior to this question can be selected.
                      </p>
                      <Form.Select
                        className={`form-control text-muted ${errors.depend_q_id && 'is-invalid'}`}
                        aria-label="Default select example"
                        name="depend_q_id"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("depend_q_ans", "");
                        }}
                        placeholder="Page Title"
                        onBlur={handleBlur}
                        value={values.depend_q_id}
                      >
                        <option value="">Select Question</option>
                        {dependableQuestions && dependableQuestions.length > 0 &&
                          dependableQuestions.map(question => (
                            <option value={question.id}>{question.question}</option>
                          ))
                        }
                      </Form.Select>
                      {(errors.depend_q_id && touched.depend_q_id) &&
                        <p className="invalid-feedback">{errors.depend_q_id}</p>
                      }
                    </Col>
                  }
                  {values.dependentQuestion && dependableQuestions && dependableQuestions.length > 0 &&
                    dependableQuestions.map(question => {
                      if(values.depend_q_id != question.id) {
                        return "";
                      }

                      let options = question.other_column_data && JSON.parse(question.other_column_data);

                      return (
                        <Col lg="12" className="my-2">
                          <p className={`m-0 ${errors.depend_q_ans && 'is-invalid'}`}>
                            Which of the following answer(s) should result in this question being displayed?
                          </p>
                          {options && options.length > 0 &&
                            options.map(option => (
                              <div className="py-2 d-flex align-items-start gap-10">
                                <div className="cstmInputCheckWrp position-relative">
                                  <input
                                    type="checkbox"
                                    className={`position-absolute w-100 h-100`}
                                    name="depend_q_ans"
                                    onChange={handleChange}
                                    value={option}
                                    checked={values.depend_q_ans && values.depend_q_ans.includes(option)}
                                  />
                                  <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                    <span className="icn d-none">
                                      <OTick />
                                    </span>
                                  </div>
                                </div>
                                <p className=" m-0 ">{option}</p>
                              </div>
                            ))
                          }
                          {(errors.depend_q_ans && touched.depend_q_ans) &&
                            <p className="invalid-feedback">{errors.depend_q_ans}</p>
                          }
                        </Col>
                      )
                    })
                  }
                  {/* <Col lg="12" className="my-2 btnWrp">
                    <Button
                      type="submit"
                      className="d-flex align-items-center justify-conten-center commonBtn btnSm"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Submit'}
                    </Button>
                  </Col> */}
                </Row>
                <Col lg="12" className="my-2 FormbtnWrpper justify-content-start d-flex">
                  <Button
                    type="submit"
                    className="d-flex align-items-center justify-content-center commonBtn"
                  >
                    { isSubmitting ? 
                      "Saving..." : 
                        isEditMode ? "Save" : "Add"
                    }
                  </Button>
                  <Button
                    onClick={handleClose}
                    className="d-flex align-items-center justify-content-center commonBtn"
                  >
                    Cancel
                  </Button>
                </Col>
                </Form>
              </Modal.Body>
              {/* <Modal.Footer className="FormbtnWrpper justify-content-start">
              </Modal.Footer> */}
            </Modal>
          )}
      </Formik>
    </>
  );
};

const validation = Yup.object({
  title: Yup.string().trim().required("Title is required"),
  help_text: Yup.string().trim().optional().nullable(),
});

const mapStateToPros = (state) => {
  let newState = {
    formDetails: state.MyForm.formDetails,
    isEditMode: false,
    editPageId: null,
    pageDetails: {},
  }

  let editPageId = state.MyForm.editPageId;

  if(editPageId) {
    newState.isEditMode = true;
    newState.editPageId = editPageId;
    newState.pageDetails = state.MyForm.formDetails.pages.find((page) => page.id === editPageId )
  }
  return newState
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NewPagePopup);