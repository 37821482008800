import Http from './Http';
import {SUCCESS_MESSAGE, ERROR_MESSAGE} from "../common/constantMessage";

//Post
export function postData(url, data) {
  return new Promise((resolve, reject) => {
    Http.post(url, data)
      .then((res) => {
        if (res.data && res.data.message) {
          // SUCCESS_MESSAGE(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}

//Get
export function getData(url, params) {
    return new Promise((resolve, reject) => {
      Http.get(url,  params)
        .then((res) => {
            return resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "=======================err")
          if (err.response) {
            const { status, data } = err.response;
            const res = {
              status,
              data,
            };
            if(res && res.data && res.data.message){
                // SUCCESS_MESSAGE(res.data.message)
            }
            return reject(res);
          } else {
            if (err && err.message) {
              // console.log(err.message)
              ERROR_MESSAGE(err.message)
            }
            return reject(err);
          }
        });
    });
  }

  export function getDataAuth(url, params) {
    return new Promise((resolve, reject) => {
      Http.get(url,  params)
        .then((res) => {
            return resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "=======================err")
          if (err.response) {
            const { status, data } = err.response;
            const res = {
              status,
              data,
            };
            if(res && res.data && res.data.message){
                // SUCCESS_MESSAGE(res.data.message)
            }
            return reject(res);
          } else {
            if (err && err.message) {
              // console.log(err.message)
              // ERROR_MESSAGE(err.message)
            }
            return reject(err);
          }
        });
    });
  }
  
  export function putData(url, data) {
    return new Promise((resolve, reject) => {
      Http.put(url, data)
        .then((res) => {
          if (res.data && res.data.message) {
            // SUCCESS_MESSAGE(res.data.message);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err, "=======================err");
          if (err.response) {
            const { status, data } = err.response;
            const res = { status, data };
            if (res.data && res.data.message) {
              ERROR_MESSAGE(res.data.message);
            }
            reject(res);
          } else {
            if (err.message) {
              ERROR_MESSAGE(err.message);
            }
            reject(err);
          }
        })
        .finally(() => {
          // You can uncomment these lines if needed
          // callback();
          // props.dispatch(actions.persist_store({ loader: false }));
        });
    });
  }
  //Delete
  export function deleteData(url, data) {
    return new Promise((resolve, reject) => {
      Http.delete(url, { data })
        .then((res) => {
          if (res.data && res.data.message) {
            // SUCCESS_MESSAGE(res.data.message);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err, "=======================err");
          if (err.response) {
            const { status, data } = err.response;
            const res = { status, data };
            if (res.data && res.data.message) {
              ERROR_MESSAGE(res.data.message);
            }
            reject(res);
          } else {
            if (err.message) {
              ERROR_MESSAGE(err.message);
            }
            reject(err);
          }
        })
        .finally(() => {
          // You can uncomment these lines if needed
          // callback();
          // props.dispatch(actions.persist_store({ loader: false }));
        });
    });
  }
  //for image
  export function postMediaData(url, data) {
    return new Promise((resolve, reject) => {
      Http.post(url, data)
        .then((res) => {
          if (res.data && res.data.message) {
            // SUCCESS_MESSAGE(res.data.message);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err, "=======================err");
          if (err.response) {
            const { status, data } = err.response;
            const res = { status, data };
            if (res.data && res.data.message) {
              ERROR_MESSAGE(res.data.message);
            }
            reject(res);
          } else {
            if (err.message) {
              ERROR_MESSAGE(err.message);
            }
            reject(err);
          }
        })
        .finally(() => {
          // You can uncomment these lines if needed
          // callback();
          // props.dispatch(actions.persist_store({ loader: false }));
        });
    });
  }
  
  //for common
  export function postData_(url, data) {
      return new Promise((resolve, reject) => {
        Http.post(url, data)
          .then((res) => {
            if (res.data && res.data.message) {
              // SUCCESS_MESSAGE(res.data.message);
            }
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err, "=======================err");
            if (err.response) {
              const { status, data } = err.response;
              const res = { status, data };
              if (res.data && res.data.message) {
                ERROR_MESSAGE(res.data.message);
              }
              reject(res);
            } else {
              if (err.message) {
                ERROR_MESSAGE(err.message);
              }
              reject(err);
            }
          })
          .finally(() => {
            // You can uncomment these lines if needed
            // callback();
            // props.dispatch(actions.persist_store({ loader: false }));
          });
      });
    }
    
  
  