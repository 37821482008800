import React from "react";
import { Col, Row } from "react-bootstrap";

// icns
import { ReactComponent as Doc } from "../../../../../Assets/Images/icn/doc.svg";
import { ReactComponent as GUsers } from "../../../../../Assets/Images/icn/GreenUsers.svg";
import { ReactComponent as PCalender } from "../../../../../Assets/Images/icn/PinkCalender.svg";

const ColorCards = ({
  completedForms,
  taggedClients,
  upcomingAppointments,
}) => {
  return (
    <>
      <Row className="colorCards">
        <Col xl="4" sm="6" className="my-2">
          <div
            className="cardCstm p-3 h-100 d-flex align-items-start"
            style={{ background: "#39796f" }}
          >
            <div
              className="imgWrp d-flex align-items-center justify-content-center rounded-circle me-2"
              style={{ background: "#FFE8CA" }}
            >
              <Doc />
            </div>
            <dic className="content">
              <h3 className="m-0 fw-sbold text-white">Newly Completed Forms</h3>
              <h4 className="count m-0 fw-bold" style={{ color: "#08415c" }}>
                {completedForms && completedForms.total_count}
              </h4>
            </dic>
          </div>
        </Col>
        <Col xl="4" sm="6" className="my-2">
          <div
            className="cardCstm p-3 h-100 d-flex align-items-start"
            style={{ background: "#fdc169" }}
          >
            <div
              className="imgWrp d-flex align-items-center justify-content-center rounded-circle me-2"
              style={{ background: "#ffe2e2" }}
            >
              <GUsers />
            </div>
            <dic className="content">
              <h3 className="m-0 fw-sbold text-white">Tagged Clients</h3>
              <h4 className="count m-0  fw-bold" style={{ color: "#08415c" }}>
              {taggedClients && taggedClients.total_count}
              </h4>
            </dic>
          </div>
        </Col>
        <Col xl="4" sm="6" className="my-2">
          <div
            className="cardCstm p-3 h-100 d-flex align-items-start"
            style={{ background: "#f4694b" }}
          >
            <div
              className="imgWrp d-flex align-items-center justify-content-center rounded-circle me-2"
              style={{ background: "#ebb3bd" }}
            >
              <PCalender />
            </div>
            <dic className="content">
              <h3 className="m-0 fw-sbold text-white">Upcoming Appointments</h3>
              <h4 className="count m-0 fw-bold " style={{ color: "#08415c" }}>
              {upcomingAppointments && upcomingAppointments.total_count}
              </h4>
            </dic>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ColorCards;
