import {postData, getData, deleteData} from 'helpers/apiHelper';
import { FIND_FULLSCRIPT_CLIENT, FULLSCRIPT_SESSION_GRANT, GET_FULLSCRIPT_CLIENT_LIST, FULLSCRIPT_UNLINK_CLIENT, LINK_CLIENT_TO_FULLSCRIPT, GET_FULLSCRIPT_RECOMMENDATIONS, GET_FULLSCRIPT_RECOMMENDATION_DETAILS, MAIN_PATH, CANCEL_FULLSCRIPT_RECOMMENDATION } from './apiPath';
import { getFullscriptRecommendations } from 'store/actions/client';

// get dashboard content
export function getFullscriptSessionGrant() {
  let url = `${MAIN_PATH}${FULLSCRIPT_SESSION_GRANT}`;
  return getData(url)
}

// find fullscript patient
export function findFullscriptPatient(data) {
  let { clientId } = data;
  let url = `${MAIN_PATH}${FIND_FULLSCRIPT_CLIENT}/${clientId}`;
  return getData(url)
}

// get fullscript patient list
export function fetchFullscriptPatientList() {
  let url = `${MAIN_PATH}${GET_FULLSCRIPT_CLIENT_LIST}`;
  return getData(url)
}

// unlink client from fullscript
export function unlinkClient(data) {
  let { clientId } = data;
  let url = `${MAIN_PATH}${FULLSCRIPT_UNLINK_CLIENT}/${clientId}`;
  return deleteData(url)
}

// link client to fullscript patient
export function linkClient(data) {
  let { clientId, values } = data;
  let url = `${MAIN_PATH}${LINK_CLIENT_TO_FULLSCRIPT}/${clientId}`;
  return postData(url, values)
}

// get client fullscript recommendations
export function getFullscriptClientRecommendations(data) {
  let { clientId } = data;
  let url = `${MAIN_PATH}${GET_FULLSCRIPT_RECOMMENDATIONS.replace(":id", clientId)}`;
  return (dispatch) => getData(url)
      .then((data) => {
        dispatch(getFullscriptRecommendations(data));
        return data;
      });
}

// get fullscript recommendation list
export function getFullscriptRecommendationDetails(data) {
  let { id } = data;
  let url = `${MAIN_PATH}${GET_FULLSCRIPT_RECOMMENDATION_DETAILS}/${id}`;
  return getData(url)
}

// get fullscript recommendation list
export function cancelFullscriptRecommendation(data) {
  let { id } = data;
  let url = `${MAIN_PATH}${CANCEL_FULLSCRIPT_RECOMMENDATION.replace(":id", id)}`;
  return deleteData(url)
}