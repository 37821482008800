import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, UC_FIRST, parseTimeString, saveAs } from 'helpers/common';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getTodoResponse } from 'store/services/clientService';
import { BAD_FEELINGS, FM_BAD_FEELING_REASONS } from 'common/constants';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
  },
  fontBold: {
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: "#08415c",
    marginTop: "8px",
    marginBottom: "2px",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "baseline",
    borderBottom: "1px solid #ccc",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "8%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "9px",
    width: "8%",
    padding: "2px",
    flexWrap: "wrap",
  },
});

const LogPDFReport = ({ client, date, logList, showLoader, hideLoader }) => {

  const downloadPDFReport = async () => {
    try {
      showLoader();
      const doc = <MyDoc client={client} date={date} logList={logList} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Food & Feeling Log.pdf');
      hideLoader();
    } catch (err) {
      console.log("Error downloading report", err);
      hideLoader();
    }
  }

  return (
    <Link
      onClick={downloadPDFReport}
    >
      Download PDF
    </Link>
  )
}

export const MyDoc = ({ client, date, logList }) => {
  let questionCount = 0;
  return (
    <Document>
      <Page style={styles.page}>

        <View style={styles.pageHeader} fixed>
          <Text style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Food & Feeling Log
          </Text>
          <Text style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName} fixed>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        {Object.keys(logList).map(date => {
          const logData = logList[date];
          return (
            <View>
              <View style={{ borderBottom: "1px solid #aaa" }}>
                <Text style={styles.fontBold}>
                  {date}
                </Text>
              </View>
              {logData && Object.keys(logData)?.map(time => {
                const parsedTime = parseTimeString(time);
                const rowData = logData[time];
                return (
                  <View style={{ marginLeft: "15px" }}>
                    <View>
                      <View style={{ borderBottom: "1px solid #aaa" }}>
                        <Text style={[styles.fontBold, { fontSize: "11px" }]}>
                          {parsedTime.format("hh:mm A")}
                        </Text>
                      </View>
                      <View>
                        {rowData && rowData.length > 0 && rowData.map(log => {
                          return log?.log_type == "meal" ? (
                            <View style={{ marginLeft: "15px" }}>
                              <Text style={[styles.fontBold, { fontSize: "10px" }]}>
                                {UC_FIRST(log?.meal_type)}
                              </Text>
                              {log?.food_served && (
                                <View style={{ marginLeft: "15px" }}>
                                  {log?.food_served?.map(food => {
                                    return (
                                      <Text style={{ marginBottom: "3px" }}>
                                        {UC_FIRST(food?.eaten)}{` `}
                                        Serving size: {food?.size}
                                      </Text>
                                    )
                                  })}
                                </View>
                              )}
                            </View>
                          ) : (
                            <View style={{ marginLeft: "15px" }}>
                              <Text style={[styles.fontBold, { fontSize: "10px" }]}>
                                Feeling: {UC_FIRST(log?.feelings)}
                              </Text>
                              {BAD_FEELINGS.includes(log?.feelings.toLowerCase()) && (
                                <Text style={{ fontSize: "9px", fontStyle: "italic", marginBottom: "3px" }}>Feeling {UC_FIRST(log?.feelings)} because: {FM_BAD_FEELING_REASONS[log?.bad_feelings_reason]}</Text>
                              )}
                              {log?.additional_notes && (
                                <View style={{ backgroundColor: "#eee", padding: "10px" }}>
                                  <Text>
                                    {log?.additional_notes}
                                  </Text>
                                </View>
                              )}
                            </View>
                          )
                        })}
                      </View>
                    </View>
                  </View>
                )
              })}
            </View>
          )
        })}


        {/* Disclaimer */}
        <View style={styles.disclaimer} fixed>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner.
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getNAQTodoDetails = async (todoId, client) => {
  if (!todoId) {
    return;
  }
  try {
    let data = {
      todoId,
      clientId: client.id
    };
    const response = await getTodoResponse(data);
    if (response.success === true) {
      const details = response.body;
      const formattedData = validateConditions(details);
      return formattedData;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const QuestionsTable = ({ items }) => {
  const rows = items.map((question, i) => {
    if (!question || question.visible === false) {
      return null;
    }
    return (
      <View style={[tableStyles.row]} key={i} wrap={false}>
        <View style={[tableStyles.cell, { width: "40%" }]}>
          <View style={[tableStyles.row, { borderBottom: "none" }]} key={i}>
            <Text
              style={[tableStyles.cell, {
                width: "15px",
                height: "15px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
              }]}
            >
              {`${question.index}.`}
            </Text>
            {question.other_column_data && question.other_column_data.length > 0 &&
              question.other_column_data.map((item, i) => {
                return (
                  <Text
                    style={[tableStyles.cell, {
                      width: "15px",
                      height: "15px",
                      borderBottom: "none",
                      border: `${item.answer == question.answer ? "2px solid red" : "none"}`,
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }]}
                  >
                    {i}
                  </Text>
                )
              })
            }
          </View>
        </View>
        <Text style={[tableStyles.cell, { width: "60%" }]}>{decode(question.question)}</Text>
      </View>
    )
  });

  return (
    <View style={tableStyles.table}>
      {/* <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Question</Text>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Answer Score</Text>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Answer</Text>
      </View> */}
      {rows}
    </View>
  )
};


export default LogPDFReport;