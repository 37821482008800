import React, { useState, useEffect} from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

// ICN
import { ReactComponent as DownloadIcn } from "../../../../../../Assets/Images/icn/download.svg";
import { ReactComponent as DeleteIcn } from "../../../../../../Assets/Images/icn/delete.svg";
import { ReactComponent as CopyIcn } from "../../../../../../Assets/Images/icn/Copy.svg";
import DeletePopup from "components/Modals/DeletePopup/DeletePop";
import {DATE_FORMAT, convertBytes, truncateText} from "helpers/common";
import { deleteDocumentData, getDocumentList } from "store/services/documentsService";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import ReactPagination from "components/shared/ReactPagination";
import { Link } from "react-router-dom";

const TableData = ({
  dispatch,
  getData,
  documentListing,
  setUpdateResponse,
  totalItemsCount,
  itemsCountPerPage,
}) => {

  const [deletePop, setDeletePop] = useState("");
  const [copied, setCopied] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");

  let columns = [
    {
      key: "name",
      sortable: true,
      name: "Document",
    },
    {
      key: "url",
      sortable: false,
      name: "Public URL",
      desc: "send this url to your clients",
    },
    {
      key: "created_at",
      sortable: true,
      name: "Modified",
    },
    {
      key: "size_bytes",
      sortable: true,
      name: "Size",
    },
    {
      key: "action",
      sortable: false,
      name: "Action",
    },
  ];

  useEffect(() => {
    getData();
  }, [activePage, sortBy, sortOrder]);
  
  const downloadLinkDoc = (filename, file) => {
    const link = document.createElement('a');
    link.href = file;
    link.download = filename;
    link.click();
  }

  useEffect(() => {
    if (copied.some(value => value)) {
      const timeout = setTimeout(() => {
        setCopied(new Array(copied.length).fill(false));
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const copyToClipboard = async (link, idx) => {
    try {
      await navigator.clipboard.writeText(link);
      const newCopied = [...copied];
      newCopied[idx] = true;
      setCopied(newCopied);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  const deleteItem_ = async (id) => {
    try {
      // setUpdateResponse(false)
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            dispatch(actions.persist_store({ loader:true}));
            await dispatch(deleteDocumentData(id));
            getData();
          } catch (err) {
            console.log(err);
          }
        }
      })
      .finally(() => {
        dispatch(actions.persist_store({ loader:false}));
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader:false}));
    }
  };
  
  async function getData() {
    try {
      let filter = {
        page: activePage,
        orderBy: sortBy,
        order: sortOrder
      };
      dispatch(actions.persist_store({ loader:true}));
      await dispatch(getDocumentList(filter));
      dispatch(actions.persist_store({ loader:false}));
    } catch (err) {
      dispatch(actions.persist_store({ loader:false}));
      console.log(err);
    }
  }

  const handlePageChange = (page) => {
    setActivePage(page);
  }

  const SortableColumns = () => {
    return columns.map(column => {
      let sortClassName = "fa-sort";
      let sortClass = "";
      if(sortBy == column.key) {
        sortClass = (sortOrder == "asc") ? "fa-sort-up" : "fa-sort-down";
      }
      return (
        <th className="p-3 fw-normal text-dark fw-sbold text-muted">
          {column.sortable ?
            <Link
              onClick={() => {
                let order = sortOrder === "asc" ? "desc" : "asc";
                setSortBy(column.key);
                setSortOrder(order);
              }}
            >
              {column.name}
              <i class={`fa ${sortClassName} ${sortClass} px-2`}></i>
            </Link>
            :
            <>
              {column.name}
            </>
          }
          {column.desc && <sub>({column.desc})</sub> }
        </th>
      )
    })
  }

  return (
    <>
      <DeletePopup deletePop={deletePop} setDeletePop={setDeletePop} />

      <div className="table-responsive">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <SortableColumns />
            </tr>
          </thead>
          <tbody>
            {documentListing && documentListing.length ?
              documentListing.map((data, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <td className="p-3 border-0">
                        <b className="theme-clr">{truncateText(data.name)}</b>
                      </td>
                      <td className="p-3 border-0">
                        <p
                          className="theme-clr m-0 ws-normal"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data.url}
                        </p>
                        <div className="btnWrp">
                          {/* <Button className="d-flex align-items-center justify-content-center theme2BorderedBtn btnSm">
                            <span className="icn me-2">
                              <CopyIcn />
                            </span>{" "}
                            Copy URL
                          </Button> */}
                          <Button className="d-flex align-items-center justify-content-center commonBtn btnSm" onClick={() => copyToClipboard(data.url, idx)}>
                            <span className="icn me-2">
                              <CopyIcn />
                            </span>{" "}
                            {copied[idx] ? 'Copied' : 'Copy URL'}
                          </Button>
                        </div>
                      </td>
                      <td className="p-3 border-0">
                        <p className="theme-clr m-0">{DATE_FORMAT(data.created_at)}</p>
                      </td>
                      <td className="p-3 border-0">
                        <p className="theme-clr m-0">{data.size == 0 ? convertBytes(data.size_bytes, "kb") + "  KB" : data.size + " MB"}</p>
                      </td>
                      <td className="p-3 border-0">
                        <div className="TableBtn d-flex align-items-center gap-10">
                          <Button
                            className="border-0 p-0 px-2"
                            variant="transparent"
                            onClick={() => downloadLinkDoc(data.name, data.url)}
                          >
                            <DownloadIcn />
                          </Button>
                          <Button
                            className="border-0 p-0 px-2"
                            variant="transparent"
                            onClick={() => deleteItem_(data.id)}
                          >
                            <DeleteIcn />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
              :
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                >
                  No data found
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      {documentListing.length>0 &&
        <ReactPagination 
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            handlePageChange={handlePageChange}
        />
      }
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    activePage : state.Documents.activePage,
    itemsCountPerPage : state.Documents.itemsCountPerPage,
    totalItemsCount : state.Documents.totalItemsCount,
    documentListing: state.Documents.documents,
  }

};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TableData);