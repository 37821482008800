import React, { useEffect, useCallback } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { cloneDeep, debounce } from "lodash";
import ToDoQuestion from "./ToDoQuestion";
import { getClientToDoList, saveTodoResponse } from "store/services/clientService";
import { decode } from "html-entities";
import { isEmpty } from "helpers/common";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ERROR_MESSAGE, PROMISE_TOAST, SUCCESS_MESSAGE } from "common/constantMessage";
import * as actions from "store/actions";
import { GENERIC_FORM_VALIDATION_ERR } from "common/constants";
import { setTodoAutoSaveState } from "store/actions/client";

const ToDoPage = ({
  pageDetails,
  todoFormDetails,
  onStepChange,
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  handleSubmit,
  onToDoCompleted,
  questionAnswers,
  setQuestionAnswers,
  handleGoBack,
  deleteTodoAndGoBack,
  isPageVisible,
}) => {
  const showQuestionNumbers = todoFormDetails.show_question_no === "yes";
  let isFinalStep = currentStep === totalSteps;

  const { clientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // check if question should be displayed or not
    const validateQuestionConditions = (question, deubg = false) => {
      if (!question) {
        if (deubg) {
          console.log("case 0");
        }
        return false;
      }
      let copyQuestionAnswers = [...questionAnswers];
      let showQuestion = false;
      if (!question.depend_q_id || !question.depend_q_ans || !question.depend_q_ans?.length) {
        if (deubg) {
          console.log("case 1");
        }
        showQuestion = true;
      } else {
        let dependQuestionAns = JSON.parse(question.depend_q_ans);
        let questionIndex = copyQuestionAnswers.findIndex(
          (q) => q.id == question.depend_q_id && q.visible
        );
        let foundQuestion = copyQuestionAnswers[questionIndex];
        let dependSourceAnswer = null;
        if (questionIndex !== -1 && foundQuestion) {
          dependSourceAnswer = foundQuestion.answer;
          // if answer is singular and not an array
          if (typeof dependSourceAnswer == "string") {
            if (dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
              if (deubg) {
                console.log("case 2");
              }
              showQuestion = true;
            }
            // if answer is an array, check if any of the given answers match the condition
          } else if (Array.isArray(dependSourceAnswer)) {
            let findMatch = dependSourceAnswer.find(
              (sourceAns) => !!dependQuestionAns.find((ans) => ans == sourceAns)
            );
            if (findMatch) {
              if (deubg) {
                console.log("case 3");
              }
              showQuestion = true;
            }
          }
        }
      }

      if (deubg) {
        console.log("showQuestion", showQuestion);
      }

      return showQuestion;
    };

    let copyQuestionAnswers = questionAnswers.map((q) => {
      let question = pageDetails.questions.find((question) => question.id == q.id);
      if (question) {
        q.visible = validateQuestionConditions(question);
      }
      return q;
    });
    setQuestionAnswers(copyQuestionAnswers);
  }, [questionAnswers]);

  useEffect(() => {
    setQuestionAnswers(questionAnswers);
  }, [questionAnswers]);

  // useEffect(() => {
  //   let initialValues = getInitialValues();
  //   setSubmittedQuestions({...submittedQuestions, ...initialValues});
  // }, []);

  const getInitialValues = () => {
    try {
      let values = {};
      for (let i = 0; i < pageDetails.questions.length; i++) {
        try {
          let question = pageDetails.questions[i];
          let questionResponse = null;
          if (question.response?.client_response) {
            questionResponse = JSON.parse(question.response.client_response)?.response;
          }
          if (question.question_type == "checkBoxes") {
            values[`question-${question.id}`] = questionResponse || [];
          } else if(question.question_type == "ratingScale") {
            values[`question-${question.id}`] = questionResponse || "0";
          } else {
            values[`question-${question.id}`] = questionResponse || "";
          }
        } catch (error) {
          console.log(
            "Error occurred while setting question default value: ",
            error,
            error.message
          );
          // values[`question-${question.id}`] = "";
        }
      }
      return values;
    } catch (err) {
      console.log("Error occurred", err);
    }
  };
  // console.log("getInitialValues", getInitialValues());

  const validationSchema = () => {
    let validation = {};
    for (let i = 0; i < pageDetails.questions.length; i++) {
      let question = pageDetails.questions[i];
      let validator = null;

      // validate only if the question is visible
      let findQuestion = questionAnswers.find((q) => q.id == question.id);
      if (findQuestion?.visible === false) {
        continue;
      }

      if (question.question_type == "checkBoxes") {
        validator = Yup.array();
        if (question.is_required === "yes") {
          validator = validator
            .min(1, "This Question is required")
            .required("This question is required");
        }
      } else if (question.question_type == "numberRange") {
        // defult values
        let columnData = {
          minNum: 0,
          maxNum: 10,
        };
        try {
          let parsedData = JSON.parse(question.other_column_data);
          if (parsedData) {
            columnData = parsedData;
          }
        } catch (error) {
          // console.log("unable to parse column data for the question", question.id);
        }
        let msg = `Answer must be between ${columnData?.minNum}-${columnData?.maxNum}`;
        validator = Yup.number().min(columnData.minNum, msg).max(columnData.maxNum, msg);
        if (question.is_required === "yes") {
          validator = validator.required("This Question is required");
        }
      } else if (question.question_type == "disclaimer") {
        let columnData = null;
        try {
          columnData = JSON.parse(question.other_column_data);
        } catch (error) {}
        console.log("columnData", columnData);
        validator = Yup.object();
        validator = validator.shape({
          agreed: Yup.string().required("This question is required"),
          full_name: Yup.lazy(() => {
            if (columnData && columnData.require_name_date == "yes") {
              return Yup.string().required("Full name is required");
            } else {
              return Yup.string().optional();
            }
          }),
          date: Yup.lazy(() => {
            if (columnData && columnData.require_name_date == "yes") {
              return Yup.string().required("Today's date is required");
            } else {
              return Yup.string().optional();
            }
          }),
        });
      } else if (question.question_type == "height") {
        validator = Yup.object();
        if (question.is_required === "yes") {
          validator = validator.shape({
            unit: Yup.string().required("Unit is required"),
            value: Yup.string().required("Height is required"),
          });
        } else {
          validator = validator.nullable();
        }
      } else if (question.question_type == "weight") {
        validator = Yup.object();
        if (question.is_required === "yes") {
          validator = validator.shape({
            unit: Yup.string().required("Unit is required"),
            value: Yup.string().required("Weight is required"),
          });
        } else {
          validator = validator.nullable();
        }
      } else if (question.question_type == "address") {
        validator = Yup.object();
        if (question.is_required === "yes") {
          validator = validator.shape({
            address_1: Yup.string().required("Address is required"),
          });
        } else {
          validator = validator.nullable();
        }
      } else {
        validator = Yup.string();
        if (question.is_required === "yes") {
          validator = validator.required("This question is required");
        }
      }

      validation[`question-${question.id}`] = validator;
    }
    let schema = Yup.object(validation);
    return schema;
  };

  const validationSchema_bak = () => {
    let validation = {};
    for (let i = 0; i < pageDetails.questions.length; i++) {
      let question = pageDetails.questions[i];
      if (question.is_required === "yes") {
        if (question.question_type == "checkBoxes") {
          validation[`question-${question.id}`] = Yup.array().min(1, "This Question is required");
        } else if (question.question_type == "numberRange") {
          let columnData = {
            minNum: 0,
            maxNum: 10,
          };
          try {
            columnData = JSON.parse(question.other_column_data);
            validation[`question-${question.id}`] = Yup.number()
              .required("This Question is required")
              .min(columnData.minNum)
              .max(columnData.maxNum);
          } catch (error) {
            // console.log("unable to parse column data for the question", question);
          }
        } else {
          validation[`question-${question.id}`] = Yup.string().required(
            "This Question is required"
          );
        }
      }
    }
    let schema = Yup.object(validation);
    return schema;
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      handleAutoSave.cancel();
      setSubmitting(true);
      
      // filter questions that are hidden
      values = filterHiddenQuestions(values);

      let isFinalSubmit = isFinalStep;
      let data = {
        pageId: pageDetails.form_page_id,
        values,
      };
      await handleSubmit(data, isFinalSubmit);
      setSubmitting(false);
      // const newSubmittedQuestions = {...submittedQuestions, ...values};
      // setSubmittedQuestions(newSubmittedQuestions);
      if (!isFinalStep) {
        onStepChange(currentStep + 1);
        nextStep();
      } else {
        onToDoCompleted(todoFormDetails.id);
      }
    } catch (error) {
      console.log("Error saving form response", error);
      setSubmitting(false);
    }
  };

  /**
   * Save form progress and go back
   */
  const saveProgressAndGoBack = async (values) => {
    try {
      // filter questions that are hidden
      values = filterHiddenQuestions(values);
      try {
        await validationSchema().validate(values);
      } catch(err) {
        ERROR_MESSAGE(GENERIC_FORM_VALIDATION_ERR);
        return;
      }
      
      let isFinalSubmit = false;
      let data = {
        pageId: pageDetails.form_page_id,
        values,
      }
      dispatch(actions.persist_store({ loader: true }));
      await handleSubmit(data, isFinalSubmit);
      dispatch(actions.persist_store({ loader: false }));
      SUCCESS_MESSAGE("Progress has been saved");
      await dispatch(getClientToDoList({ clientId }));
      handleGoBack();
      // navigate(todo);
    } catch (error) {
      console.log("Error saving form response", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  /**
   * filter questions that are hidden
   */
  const filterHiddenQuestions = (values) => {
    for (let question in values) {
      let questionId = question.replace("question-", "");
      let findQuestion = questionAnswers.find((q) => q.id == questionId);
      if (findQuestion && !findQuestion.visible) {
        delete values[question];
      }
    }

    return values;
  }

  /**
   * filter questions that are blank
   */
  const filterBlankQuestions = (values) => {
    for (let question in values) {
      let questionId = question.replace("question-", "");
      let findQuestion = questionAnswers.find((q) => q.id == questionId);
      if (findQuestion && isEmpty(findQuestion?.answer)) {
        delete values[question];
      }
    }

    return values;
  }

  const handleClickBack = () => {
    onStepChange(currentStep - 1);
    previousStep();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const addOrUpdateInput = (questionId, value) => {
    let copyQuestionAnswers = [...questionAnswers];
    let questionIndex = copyQuestionAnswers.findIndex((q) => q.id == questionId);
    let question = copyQuestionAnswers[questionIndex];

    // if question found then update the answer
    if (questionIndex !== -1 && question) {
      question.answer = value;
      // console.log("found", question);
    } else {
      // if question not found then add question to the global array
      let question = {
        id: questionId,
        ans: value,
      };
      copyQuestionAnswers.push(question);
    }
    setQuestionAnswers(copyQuestionAnswers);
  };

  const handleAutoSave = useCallback(debounce(async (values) => {
    try {
      dispatch(setTodoAutoSaveState(true));
      // filter questions that are hidden
      values = filterHiddenQuestions(values);
      values = filterBlankQuestions(values);
      let isFinalSubmit = false;
      let isAutoSave = true;
      let data = {
        pageId: pageDetails.form_page_id,
        values,
      }
      // await handleSubmit(data, isFinalSubmit, isAutoSave);
      await handleSubmit(data, isFinalSubmit, isAutoSave);
      dispatch(setTodoAutoSaveState(false));
    } catch(err) {
      console.log("Error auto saving form", err);
      dispatch(setTodoAutoSaveState(false));
    }
  }, 1200), []);

  if (!pageDetails) {
    return "";
  }

  return (
    <div className="commonContent">
      {!isEmpty(pageDetails.page_title) && (
        <h5 className="m-0 fw-bold">
          {decode(pageDetails.page_title)}{" "}
          <p className="m-0 fw-normal d-inline-block text-muted">
            (Section {currentStep} of {totalSteps})
          </p>
        </h5>
      )}
      {!isEmpty(pageDetails.page_help_text) && (
        <p className="py-2">{decode(pageDetails.page_help_text)}</p>
      )}

      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={validationSchema()}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const handleInputChange = (e) => {
            handleChange(e);
            let {name, value} = e.target;
            let cloneValues = cloneDeep(values);
            cloneValues = {
              ...cloneValues,
              [name]: value
            }
            handleAutoSave(cloneValues);
            // let questionId = e.target.name.replace("question-", "");
            // addOrUpdateInput(questionId, values[`question-${questionId}`]);
          };

          // console.log("values", values);
          // console.log("errors", errors);

          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <ul className="list-unstyled mb-0 py-2">
                {pageDetails.questions &&
                  pageDetails.questions.map((question, i) => {
                    let q = questionAnswers.find((q) => q.id == question.id);
                    // let showQuestion = validateQuestionConditions(question);
                    // console.log(question.question, showQuestion);
                    if (q && !q.visible) {
                      return null;
                    }
                    return (
                      <>
                        <ToDoQuestion
                          key={question.id}
                          question={question}
                          className={`form-control ${
                            errors[`question-${question.id}`] && "is-invalid"
                          }`}
                          name={`question-${question.id}`}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          value={values[`question-${question.id}`]}
                          setFieldValue={setFieldValue}
                          showQuestionNumbers={showQuestionNumbers}
                          onValueChange={addOrUpdateInput}
                          errors={errors}
                          formId={todoFormDetails?.id}
                        />
                      </>
                    );
                  })}
              </ul>
              {Object.keys(errors)?.length > 0 && (
                <div className="">
                  <p className="invalid-feedback d-block">
                    {GENERIC_FORM_VALIDATION_ERR}
                  </p>
                </div>
              )}
              <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-3">
                {currentStep !== 1 && (
                  <Button
                    onClick={handleClickBack}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.623498 7.73221C0.436027 7.54468 0.330712 7.29037 0.330712 7.02521C0.330712 6.76005 0.436027 6.50574 0.623498 6.31821L6.2805 0.66121C6.37274 0.5657 6.48309 0.489517 6.60509 0.437108C6.7271 0.384699 6.85832 0.357114 6.9911 0.35596C7.12388 0.354806 7.25556 0.380108 7.37845 0.430388C7.50135 0.480669 7.613 0.554923 7.70689 0.648816C7.80079 0.742709 7.87504 0.854359 7.92532 0.977256C7.9756 1.10015 8.0009 1.23183 7.99975 1.36461C7.99859 1.49739 7.97101 1.62861 7.9186 1.75062C7.86619 1.87262 7.79001 1.98296 7.6945 2.07521L2.7445 7.02521L7.6945 11.9752C7.87666 12.1638 7.97745 12.4164 7.97517 12.6786C7.97289 12.9408 7.86773 13.1916 7.68232 13.377C7.49691 13.5624 7.2461 13.6676 6.9839 13.6699C6.7217 13.6722 6.4691 13.5714 6.2805 13.3892L0.623498 7.73221Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    Back
                  </Button>
                )}
                {currentStep <= totalSteps && (
                  <Button
                    // onClick={() => setStep("2")}
                    type="submit"
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please Wait..." : isFinalStep ? "Submit" : "Next"}
                    <span className="icn ms-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.707 6.29318C7.89447 6.48071 7.99979 6.73502 7.99979 7.00018C7.99979 7.26534 7.89447 7.51965 7.707 7.70718L2.05 13.3642C1.95775 13.4597 1.84741 13.5359 1.7254 13.5883C1.6034 13.6407 1.47218 13.6683 1.3394 13.6694C1.20662 13.6706 1.07494 13.6453 0.952046 13.595C0.82915 13.5447 0.717497 13.4705 0.623604 13.3766C0.529712 13.2827 0.455459 13.171 0.405178 13.0481C0.354897 12.9252 0.329595 12.7936 0.330749 12.6608C0.331903 12.528 0.359489 12.3968 0.411898 12.2748C0.464307 12.1528 0.540489 12.0424 0.635999 11.9502L5.586 7.00018L0.635999 2.05018C0.453841 1.86158 0.353047 1.60898 0.355325 1.34678C0.357604 1.08458 0.462772 0.83377 0.648181 0.648362C0.833589 0.462954 1.0844 0.357785 1.3466 0.355507C1.6088 0.353228 1.8614 0.454022 2.05 0.636181L7.707 6.29318Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </Button>
                )}
              </div>
              <div className="py-2 mt-2">
                <p className="m-0 text-dark">
                  Pause this to-do item and go back to the Add Data page by{" "}
                  <Link
                    to=""
                    className="underline"
                    onClick={() => {
                      saveProgressAndGoBack(values);
                    }}
                  >
                    clicking here
                  </Link>{" "}
                  .
                </p>
                <p className="m-0 text-dark">
                  Delete this to-do item and go back to the Add Data page by{" "}
                  <Link onClick={deleteTodoAndGoBack} to="" className="underline">
                    clicking here
                  </Link>{" "}
                  .
                </p>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ToDoPage;
// const mapStateToPros = (state) => {
//   console.log("add todo data", state);
//   return {}
// };

// function mapDispatchToProps(dispatch) {
//   return { dispatch };
// }

// export default connect(
//   mapStateToPros,
//   mapDispatchToProps
// )(ToDoPage);
