import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, TIME_ZONE_LIST, SCHEDULER_SETTING_API, WORKING_LIST_API, ADD_HOURS, APPOINTMENT_TYPE_LIST, APPOINTMENT_TYPE_ADD, REMOVE_APPOINTMENT_API, APPOINTMENT_TYPE_VIEW_API, APPOINTMENT_CLEINT_API, APPOINTMENT_SLOT_API, APPOINTMENT_BOOK_API, APPOINTMENT_STEPS_SAVE_TRACKING_DATA, APPOINTMENT_GET_LIST, GET_APPOINTMENT_BOOKING_LIST, RESCHEDULE_APPOINTMENT, CANCEL_APPOINTMENT, UPDATE_APPOINTMENT, APPOINTMENT_UNLINK_CLIENT, APPOINTMENT_LINK_CLIENT} from './apiPath'
import { timeZoneList, updateSchedulerSetting, workingHourList, addWorkingHours, appointmentTypeList, appointmentTypeAdd, appointmentTypeRemove, appointmentTypeView, appointmentClientList, appointmentSlots, appointmentBook, saveStepsTrackingData, appointmentBookingList, cancelAppointment, rescheduleAppointment } from 'store/actions/appointment';
import { getAccountSettingList_ } from './accountSettingService';

//get
export function timeZoneList_(value, callback) {
    let url = `${MAIN_PATH}${TIME_ZONE_LIST}`;
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(timeZoneList(data, callback));
        return data;
    });
}
//update scheduler setting
export function updateSchedulerSetting_(id, value, callback) {
    let url = `${MAIN_PATH}${SCHEDULER_SETTING_API}${id}`;
    return (dispatch) => postData(url, value)
      .then((data) => {
        dispatch(updateSchedulerSetting(data, callback));
        return data;
    });
}
//working hours
export function workingHourList_(value, callback) {
    let url = `${MAIN_PATH}${WORKING_LIST_API}`;
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(workingHourList(data, callback));
        return data;
    });
}

//add working hours
export function addWorkingHours_(id, value, callback) {
  let url = `${MAIN_PATH}${ADD_HOURS}${id}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(addWorkingHours(data, callback));
      return data;
  });
}
//appointment type list
export function appointmentTypeList_(value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_TYPE_LIST}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(appointmentTypeList(data, callback));
      return data;
  });
}
//appointment type add
export function appointmentTypeAdd_(value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_TYPE_ADD}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(appointmentTypeAdd(data, callback));
      return data;
  });
}

//appointment type remove
export function appointmentTypeRemove_(id, value, callback) {
  let url = `${MAIN_PATH}${REMOVE_APPOINTMENT_API}${id}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(appointmentTypeRemove(data, callback));
      return data;
  });
}

//appointment type remove
export function appointmentTypeView_(id, value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_TYPE_VIEW_API}${id}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(appointmentTypeView(data, callback));
      return data;
  });
}

//appointment  client list
export function appointmentClientList_(value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_CLEINT_API}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(appointmentClientList(data, callback));
      return data;
  });
}


//appointment slots
export function appointmentSlots_(id, value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_SLOT_API}${id}`;
  // let url = `http://localhost:3000/dev/appointment/slot/${id}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(appointmentSlots(data, callback));
      return data;
  });
}

//appointment book
export function appointmentBook_(value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_BOOK_API}`;
  // let url = `http://localhost:3000/dev/appointment/book`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(appointmentBook(data, callback));
      return data;
  });
}

// appointment steps save tracking data
export function appointmentStepsSaveTrackingData(value, callback) {
  let url = `${MAIN_PATH}${APPOINTMENT_STEPS_SAVE_TRACKING_DATA}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(saveStepsTrackingData(data, callback));
      dispatch(workingHourList_(data, callback));
      dispatch(appointmentTypeList_(data, callback));
      dispatch(getAccountSettingList_());
      return data;
  });
}

// get appointment booking list
export function getAppointmentBookingList(value, callback) {
  let url = `${MAIN_PATH}${GET_APPOINTMENT_BOOKING_LIST}`;
  return (dispatch) => postData(url)
    .then((data) => {
      dispatch(appointmentBookingList(data, callback));
      return data;
  });
}
// cancel appointment
export function cancelAppointment_(id, value, callback) {
  let url = `${MAIN_PATH}${CANCEL_APPOINTMENT}${id}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(cancelAppointment(data, callback));
      return data;
  });
}
// reschedule appointment
export function rescheduleAppointment_(id, value, callback) {
  let url = `${MAIN_PATH}${RESCHEDULE_APPOINTMENT}${id}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(rescheduleAppointment(data, callback));
      return data;
  });
}

// update appointment status
export function updateAppointment(id, value, callback) {
  let url = `${MAIN_PATH}${UPDATE_APPOINTMENT}${id}`;
  return postData(url, value);
}

// link client to appointment
export function linkClient(data) {
  let {id, value} = data;
  let url = `${MAIN_PATH}${APPOINTMENT_LINK_CLIENT}${id}`;
  return postData(url, value);
}

// unlink client from appointment
export function unlinkClient(id) {
  let url = `${MAIN_PATH}${APPOINTMENT_UNLINK_CLIENT}${id}`;
  return postData(url);
}