import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { getRecommendationReportV1 } from 'store/services/clientDataReportsService';
import { Link } from 'react-router-dom';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  heading: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: "#08415c"
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "15px",
    paddingBottom: "15px",
    borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "8%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "10px",
    width: "8%",
    padding: "5px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});

export const MyDoc = ({ nutritionalRows, manufacturer, client, date }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>

        <View style={styles.pageHeader}>
          <Text style={[styles.heading, {fontSize: "18px", }]}>
            Client Recommendation Report
          </Text>
          <Text style={[styles.heading, {fontSize: "22px"}]}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>
        
        <View>
          <View style={{ borderBottom: "1px solid #ccc" }}>
            <Text style={[styles.heading, {fontSize: "14px"}]}>
              Recommendation
            </Text>
          </View>
          <Text style={{ paddingTop: "5px" }}>
            The following supplements are based on the potential conditions and nutritional deficiencies shown in the Symptom Burden Report.
          </Text>
          <View style={{ flexDirection: "row", paddingTop: "5px", gap: "5px", alignItems: "baseline" }}>
            <Text>
              Supplement Brand:
            </Text>
            <Text
              style={{fontSize: "12px", fontWeight: "bold", fontFamily: "Helvetica-Bold"}}
            >
              {manufacturer}
            </Text>
          </View>
        </View>
        
        {/* Nutritional recommendation details */}
        {nutritionalRows && nutritionalRows.length > 0 &&
          <View style={styles.tableContainer}>
            <NutriRecommTable items={nutritionalRows} />
          </View>
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getRecommendationReportData = async (todoId, client) => {
  try {
    if (!todoId) {
      throw new Error("Invalid ToDo ID");
    }
    let data = {
      todoId,
      clientId: client.id
    };
    const response = await getRecommendationReportV1(data);
    if (response.success === true) {
      return response.body;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const DownloadToDoNAQRecommPDF = ({ todoId, manufacturer, client, date, showLoader, hideLoader, label }) => {

  const downloadPDFReport = async () => {
    try {
      showLoader();
      const recommendationData = await getRecommendationReportData(todoId, client);
      let nutritionalRows = recommendationData && recommendationData[manufacturer];
      const doc = <MyDoc nutritionalRows={nutritionalRows} manufacturer={manufacturer} client={client} date={date} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Client-Recommendation-Report.pdf');
      hideLoader();
    } catch(err) {
      console.log("Error downloading report data", err);
      hideLoader();
    }
  }

  return (
    <Link
      onClick={downloadPDFReport}
      disabled={true}
    >
      {label}
    </Link>
  )
}

const NutriRecommTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row, { backgroundColor: (i%2 == 0) ? "#fff" : "#eee" }]} key={i}>
        <Text style={[tableStyles.cell, { width: "20%" }]}>{decode(row.productName)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageUponRising)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageBreakfast)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageMidMorning)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageLunch)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageMidAfternoon)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageDinner)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageBeforeBed)}</Text>
        <Text style={[tableStyles.cell, { width: "26%" }]}>{decode(row.instructions)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "20%", }]}>Product</Text>
        <Text style={tableStyles.headerCell}>Upon Waking</Text>
        <Text style={tableStyles.headerCell}>With Breakfast</Text>
        <Text style={tableStyles.headerCell}>Midmorning</Text>
        <Text style={tableStyles.headerCell}>With Lunch</Text>
        <Text style={tableStyles.headerCell}>Midafternoon</Text>
        <Text style={tableStyles.headerCell}>With Dinner</Text>
        <Text style={tableStyles.headerCell}>Before Bed</Text>
        <Text style={[tableStyles.headerCell, { width: "26%" }]}>Benefit / Instructions / Notes</Text>
      </View>
      {rows}
    </View>
  )
};

export default DownloadToDoNAQRecommPDF;