import { PROFILE, UPDATE_PROFILE, ACCEPT_POLICY, ACTIVE_PLAN_STATUS } from 'store/actions/profile';

const initialState = {
    isAuthenticated: false,
    user: null,
    loader:false,
};

//get profile
const getProfile = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:data.body,
  };
  return stateObj;
};

//update profile
const updateProfile = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:data.body,
  };
  return stateObj;
};

//accept terms condition
const accpetPolicy = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:data.body
  };
  return stateObj;
};
//accept terms condition
const activePlanStatus = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:data.body
  };
  return stateObj;
};


const Profile = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROFILE:
          return getProfile(state, payload);
        case UPDATE_PROFILE:
          return updateProfile(state, payload);
        case ACCEPT_POLICY:
          return accpetPolicy(state, payload);
        case ACTIVE_PLAN_STATUS:
          return activePlanStatus(state, payload);
        default:
        return state;
    };
}
export default Profile;