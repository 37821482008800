import { PASSWORD_VALIDATE_REGEX } from 'common/constants';
import { PASSWORD_VALIDATION_MSG } from 'common/strMessage';
import * as Yup from 'yup';

const validation = Yup.object({
  password: Yup.string().required("Password Required").min(8, PASSWORD_VALIDATION_MSG).matches(PASSWORD_VALIDATE_REGEX, PASSWORD_VALIDATION_MSG),
    confirmPassword: Yup.string()
  .required("Please confirm your password")
  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default validation;
