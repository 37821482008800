import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const SchedulerStatus = ({
  nextStep,
  previousStep,
  currentStep,
  handleNext,
  settingList,
  handleChange,
  handleScheduleSec,
  setSchedulerType,
  buttonDisable,
  schedulerType,
  submitData_,
  settingListStep,
  step_name,
  setStep,
  setStepType,
  stepType
}) => {
  const [stepToCheck, setStepToCheck] = useState(false)

  useEffect(() => {
    let settingListSteps = settingListStep.data_set ?  JSON.parse(settingListStep?.data_set) : [];
    let isStepExists = settingListSteps.some(step => step.step_name === step_name);
      if (isStepExists) {
        setStepToCheck(true)
      } else {
        setStepToCheck(false)
      }
  }, [stepToCheck])

  const backStep_  = () => {
    if(stepType){
      setStepToCheck(false)
      setSchedulerType(true)
    }else{
      setStep(11)
    }
  }
  return (
    <>
      {settingList.data_key === "scheduler_status" && (
        <div
          className="commonContent p-3 gettingStarted"
          style={{ background: "#fffef7", zIndex: "9" }}
        >
          <div className="top py-2 border-bottom">
            <h3 className="m-0 fw-bold py-2 text-uppercase">
              Scheduler Status
            </h3>
          </div>
          <div className="CardBody py-2">
            <div className="py-2">
              <p className="py-2 m-0">
                Update your Scheduler Status to either
                allow bookings (active) or completely
                disable your Scheduler. This can be useful
                if you are taking a break and don't want
                clients to be able to schedule any new
                appointments.
              </p>
              {/* <p className="py-2 m-0">
                                    Current Status:{" "}
                                      <b>
                                        If you have any questions, please contact Gaurav
                                        at{" "}
                                        <Link to="" className="underline">
                                          itaccounts@nutritionaltherapy.com
                                        </Link>
                                      </b>{" "}
                                    </p> */}
              <div className="py-2 d-flex align-items-center justify-content-between gap-10 flex-wrap">
                <div className="left">
                  <h4 className="m-0 fw-bold py-2">
                    Current Status:{" "}
                    <p className="fw-normal d-inline-block text-muted m-0">
                      Active, clients can view your
                      scheduler and book appointments
                    </p>
                  </h4>
                </div>
              </div>
              <>
                <div className="commonCardBox p-3 mt-3 bg-white">
                  <Form>
                    <Row>
                      <Col lg="4" className="my-2">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp radio position-relative ">
                              <input
                                type="radio"
                                name="scheduler_status"
                                className="position-absolute w-100 h-100"
                                value="enable"
                                onChange={handleChange(
                                  "scheduler_status"
                                )}
                                defaultChecked={
                                  settingList.data_set ===
                                    "enable"
                                    ? true
                                    : false
                                }
                              />
                            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                              <span className="icn d-none rounded-circle">
                                {/* <OTick /> */}
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Active, clients can view your
                            scheduler and book
                            appointments
                          </p>
                        </div>
                      </Col>
                      <Col lg="4" className="my-2">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp radio position-relative ">
                             <input
                                type="radio"
                                name="scheduler_status"
                                className="position-absolute w-100 h-100"
                                value="disable"
                                onChange={handleChange(
                                  "scheduler_status"
                                )}
                                defaultChecked={
                                  settingList.data_set ===
                                    "disable"
                                    ? true
                                    : false
                                }
                              />
                            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                              <span className="icn d-none rounded-circle">
                                {/* <OTick /> */}
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Disabled, clients cannot view
                            your scheduler or book
                            appointments
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </>
              {!stepToCheck ? (
              <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
                <Button
                  onClick={() => setStep(11)}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                >
                  Back
                </Button>
                <Button
                  // onClick={handleScheduleSec}
                  type="submit"
                  disabled={buttonDisable ? true : false}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={async () => {
                    await submitData_("14", settingList.id, nextStep, false);
                    handleNext("scheduler_status_");
                  }}
                >
                  {buttonDisable ? "Wait.." : "Save"}
                </Button>
              </div>
              ):(
                <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
                <Button
                  onClick={() => backStep_()}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                >
                  Back
                </Button>
                <Button
                  // onClick={handleScheduleSec}
                  type="submit"
                  disabled={buttonDisable ? true : false}
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={async () => {
                    await submitData_("14", settingList.id, nextStep, stepType ? true : false);
                    handleNext("scheduler_status_");
                  }}
                >
                  {buttonDisable ? "Wait.." : "Save"}
                </Button>
              </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SchedulerStatus;