import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const FinishReview = ({
  nextStep,
  previousStep,
  currentStep,
  handleNext,
  setStep
}) => {
  return (
    <>
      <div
        className="commonContent p-3 gettingStarted"
        style={{ background: "#fffef7", zIndex: "9" }}
      >
        <div className="top py-2 border-bottom">
          <h3 className="m-0 fw-bold py-2 text-uppercase">
            Done!
          </h3>
        </div>
        <div className="CardBody py-2">
          <div className="py-2">
            <p className="py-2 m-0">
              Now let's have a look at what your clients will see.
              Click the link below to open a new tab with the
              information that your clients will see when they
              schedule an appointment.
            </p>
            <p className="py-2 m-0">
              Take it for a spin and sign up for an appointment.
              If you want to change anything you can click the
              'Back' button below to edit your settings or you can
              always make changes later in the settings menu.
            </p>
            <div className="py-2">
              <Link to="" className="underline fw-sbold">
                View your scheduler
              </Link>
            </div>
            <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-4">
              <Button
                onClick={() => setStep(15)}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                <span className="icn me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.623498 7.73221C0.436027 7.54468 0.330712 7.29037 0.330712 7.02521C0.330712 6.76005 0.436027 6.50574 0.623498 6.31821L6.2805 0.66121C6.37274 0.5657 6.48309 0.489517 6.60509 0.437108C6.7271 0.384699 6.85832 0.357114 6.9911 0.35596C7.12388 0.354806 7.25556 0.380108 7.37845 0.430388C7.50135 0.480669 7.613 0.554923 7.70689 0.648816C7.80079 0.742709 7.87504 0.854359 7.92532 0.977256C7.9756 1.10015 8.0009 1.23183 7.99975 1.36461C7.99859 1.49739 7.97101 1.62861 7.9186 1.75062C7.86619 1.87262 7.79001 1.98296 7.6945 2.07521L2.7445 7.02521L7.6945 11.9752C7.87666 12.1638 7.97745 12.4164 7.97517 12.6786C7.97289 12.9408 7.86773 13.1916 7.68232 13.377C7.49691 13.5624 7.2461 13.6676 6.9839 13.6699C6.7217 13.6722 6.4691 13.5714 6.2805 13.3892L0.623498 7.73221Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Back
              </Button>
              <Button
                onClick={() => {
                  handleNext("finish_review");
                  setStep(13);
                }}
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
              >
                Finish
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinishReview;