import React, { useState } from "react";
import moment from "moment";

const SelectNAQs = ({ naqList, onChange }) => {
  const [ selectedNAQs, setSelectedNAQs ] = useState([]);

  const handleChangeSelectedNAQ = (e) => {
    let targetValue = parseInt(e.target.value);
    const prevSelected = [...selectedNAQs];
    let isSelected = prevSelected.find(value => value == targetValue);
    let newValues = [];
    if(isSelected) {
      newValues = prevSelected.filter(value => value != targetValue);
    } else {
      prevSelected.push(targetValue);
      newValues = prevSelected;
    }

    // get the first last two values, can only select two items
    if(newValues.length > 2) {
      newValues = newValues.slice(-2);
    }

    setSelectedNAQs(newValues);
    onChange(newValues);
  }

  return (
    <div className="py-2">
      <h6>Select 2 NAQs to compare:</h6>
      {naqList && naqList.length > 0 &&
        naqList.map(naq => {
          return (
            <label
              className="mx-2"
              style={{ borderBottom: `4px solid ${naq.color}` }}
            >
              <input
                className=""
                type="checkbox"
                value={naq.id}
                onChange={handleChangeSelectedNAQ}
                checked={selectedNAQs.includes(naq.id)}
              />
              <span className="px-2">{moment(naq.updated_at).format("MMM DD, YYYY")}</span>
            </label>
          )
        })
      }
    </div>
  )
}

export default SelectNAQs;