const GeneralNotes = ({ notes, setNotesValue }) => {

  const handleChange = (event) => {
    setNotesValue(event.target.value);
  }

  return (
    <div className="py-2">
      <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
        Notes
      </label>
      <textarea
        rows={4}
        className="form-control"
        placeholder="Add your notes here"
        autoComplete="off"
        onChange={handleChange}
        value={notes}
      />
    </div>
  )
}

export default GeneralNotes;