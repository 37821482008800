import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../../Assets/Images/Ss_f3.png";

const HelpFoodLoggingComparison = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContnet py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Food Logging Comparison{" "}
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                  <p className="m-0 py-2">
                    The Food & Mood Journal is a great option in the following
                    situations:
                  </p>
                  <ul className="ps-3 m-0 py-2">
                    <li className="">
                      You want to gather a wide range of logging data as part of
                      your initial intake process.
                    </li>
                    <li className="">
                      You want the extra information and clues that you can get
                      from free text entry, where your clients can write
                      anything.
                    </li>
                    <li className="">
                      You don't need to see the data they're logging until
                      they're finished.
                    </li>
                    <li className="">
                      You don't need to respond to their entries in realtime
                      while they are still logging.
                    </li>
                  </ul>
                  <p className="m-0 py-2">
                    The Food & Feelings Log is a great option in the following
                    situations:
                  </p>
                  <ul className="ps-3 m-0 py-2">
                    <li className="">
                      You want them to continue logging long-term and you want
                      to be able to see what they're logging whenever you would
                      like.
                    </li>
                    <li className="">
                      You want to see data such as their most frequently eaten
                      foods.
                    </li>
                    <li className="">
                      You're trying to find patterns with the types of whole
                      foods that are making them feel bad.
                    </li>
                  </ul>
                  <p className="m-0 py-2">
                    We recommend picking the logging method that fits best with
                    your workflow and client intake process and then making that
                    your default setting. You can do this by going to your 'My
                    Account' page and then to the 'Account Settings' tab. If you
                    are going to be using the Food & Feelings Log then set
                    'Client Anytime Logging' to enabled. If you're going to be
                    using the Food & Mood Journal then set this to be disabled.
                    These settings will not affect existing clients but they
                    will now be the default for all new clients.
                  </p>
                  <div className="imgWrp my-2">
                    <img src={s1} alt="" className="img-fluid" />
                  </div>
                  <p className="m-0 py-2">
                    The 'Default Forms' section lists the forms that will be
                    available in the list when you assign a new To-Do Item to
                    your clients. So if you're planning to use the Food & Mood
                    Journal, make sure the box next to that form is checked. (If
                    you're using the Food & Feeling Log, either as your main
                    food logging option or only with specific clients, we still
                    recommend just asking them to log their foods and feeling as
                    opposed to assigning it as a To-Do Item, so in that case it
                    makes sense to uncheck the box next to the 'Food & Feeling
                    Log' since you'll never be assigning it.) Now that your
                    defaults are set, if you want to use the other logging
                    option in a specific situation you can change the 'Client
                    Anytime Logging' setting for that individual client on the
                    'Client Info & Settings' tab for that client. For the Food &
                    Mood Journal, 'Anytime Logging' needs to be off. For the
                    Food & Feelings log, 'Anytime Logging' needs to be turned
                    on.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpFoodLoggingComparison;
