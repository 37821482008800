import { FULL_NAME } from "helpers/common";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const CloseFullscriptWidgetPopup = ({
  showPopup,
  setShowPopup,
  closeWidget,
}) => {

  const [isUnlinking, setIsUnlinking] = useState(false);

  const handleClose = () => {
    setShowPopup(false);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Close Fullscript View?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg="12" className="">
              <p>Are you sure you want to close the Fullscript view? Any unsaved updates will be lost.</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center btnSm btn-danger"
            onClick={closeWidget}
          >
            Yes, close the Fullscript view
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CloseFullscriptWidgetPopup;
