import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// img
import s1 from "../../../../Assets/Images/Ss_ap1.png";
import s2 from "../../../../Assets/Images/Ss_ap2.png";
import s3 from "../../../../Assets/Images/Ss_ap3.png";
import s4 from "../../../../Assets/Images/Ss_ap4.png";
import s5 from "../../../../Assets/Images/Ss_ap5.png";
import s6 from "../../../../Assets/Images/Ss_ap6.png";
import s7 from "../../../../Assets/Images/Ss_ap7.png";
import s8 from "../../../../Assets/Images/Ss_ap8.png";
import s9 from "../../../../Assets/Images/Ss_ap9.png";
import s10 from "../../../../Assets/Images/Ss_ap10.png";
import s11 from "../../../../Assets/Images/Ss_ap11.png";
import s12 from "../../../../Assets/Images/Ss_ap12.png";
import s13 from "../../../../Assets/Images/Ss_ap13.png";
import s14 from "../../../../Assets/Images/Ss_ap14.png";
import s15 from "../../../../Assets/Images/Ss_ap15.png";
import s16 from "../../../../Assets/Images/Ss_ap16.png";
import s17 from "../../../../Assets/Images/Ss_ap17.png";
import s18 from "../../../../Assets/Images/Ss_ap18.png";
import s19 from "../../../../Assets/Images/Ss_ap19.png";

const HelpScheduleSetup = () => {
  return (
    <>
      <section className="HelpSec position-relative py-3">
        <Container className="me-0" fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div class="commonContent py-2">
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Appointment Scheduler{" "}
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                  <div className="py-2 my-2">
                    <p class="m-0">
                      The Appointment Scheduler lets your clients easily book
                      appointments with you online. You can also accept payments
                      when appointments are booked when you{" "}
                      <Link to="" className="themeClr">
                        link your Stripe Account
                      </Link>{" "}
                      and you can{" "}
                      <Link to="" className="themeClr">
                        sync with your Google Calendar
                      </Link>{" "}
                      as well.
                    </p>
                  </div>
                </div>

                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Scheduler Set Up{" "}
                    <Button className="border-0 p-0 ms-2" variant="transparent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.7959 10.5459L17.2959 15.0459C17.0846 15.2573 16.7979 15.376 16.4991 15.376C16.2002 15.376 15.9135 15.2573 15.7022 15.0459C15.4908 14.8346 15.3721 14.5479 15.3721 14.2491C15.3721 13.9502 15.4908 13.6635 15.7022 13.4522L18.2812 10.875H15.4688C13.7225 10.8744 12.0255 11.4543 10.6448 12.5235C9.26403 13.5926 8.27782 15.0904 7.84125 16.7812C7.80432 16.9243 7.73957 17.0587 7.6507 17.1767C7.56183 17.2948 7.45058 17.3941 7.32331 17.4692C7.19603 17.5442 7.05522 17.5935 6.90892 17.6141C6.76262 17.6347 6.61368 17.6263 6.47062 17.5894C6.32757 17.5524 6.19318 17.4877 6.07515 17.3988C5.95711 17.3099 5.85774 17.1987 5.7827 17.0714C5.70766 16.9442 5.65842 16.8033 5.63779 16.657C5.61717 16.5107 5.62557 16.3618 5.6625 16.2187C6.22291 14.0444 7.49057 12.1182 9.2659 10.7435C11.0412 9.36868 13.2234 8.62342 15.4688 8.625H18.2812L15.7041 6.045C15.4927 5.83365 15.374 5.54701 15.374 5.24812C15.374 4.94924 15.4927 4.66259 15.7041 4.45125C15.9154 4.2399 16.2021 4.12117 16.5009 4.12117C16.7998 4.12117 17.0865 4.2399 17.2978 4.45125L21.7978 8.95125C21.9027 9.0559 21.9859 9.18025 22.0426 9.31715C22.0993 9.45405 22.1284 9.6008 22.1282 9.74898C22.1281 9.89716 22.0986 10.0438 22.0416 10.1806C21.9846 10.3174 21.9011 10.4415 21.7959 10.5459ZM18 19.125H4.125V8.25C4.125 7.95163 4.00647 7.66548 3.7955 7.4545C3.58452 7.24352 3.29837 7.125 3 7.125C2.70163 7.125 2.41548 7.24352 2.2045 7.4545C1.99353 7.66548 1.875 7.95163 1.875 8.25V19.5C1.875 19.9973 2.07254 20.4742 2.42417 20.8258C2.77581 21.1775 3.25272 21.375 3.75 21.375H18C18.2984 21.375 18.5845 21.2565 18.7955 21.0455C19.0065 20.8345 19.125 20.5484 19.125 20.25C19.125 19.9516 19.0065 19.6655 18.7955 19.4545C18.5845 19.2435 18.2984 19.125 18 19.125Z"
                          fill="#08415C"
                        />
                      </svg>
                    </Button>
                  </h2>
                  <p className="m-0 py-2">
                    To start, click on 'Appointments' in the top navigation and
                    you'll see the below page. To get our scheduler up and
                    running we'll click on the 'Setup My Appointment Scheduler'
                    button at the bottom of the page:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Time Zone
                  </h2>
                  <p className="m-0 py-2">
                    The site tries to guess which time zone you are currently in
                    based on your browser settings and it will make that your
                    time zone. If the time zone is incorrect or you'd like your
                    calendar to be in a different time zone you can change it
                    here. The scheduler handles all time zone conversions so
                    your clients will always see your scheduler in their local
                    time zone when booking an appointment. So if you have your
                    scheduler set to Mountain Time and a client in California
                    views your scheduler it will convert everything and display
                    it in Pacific Time for that client.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Display Name
                  </h2>
                  <p className="m-0 py-2">
                    This is the name that will be shown at the top of your
                    scheduler. For example ours will read "Book an Appointment
                    with Mojo Wellness".
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s3} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Contact Email
                  </h2>
                  <p className="m-0 py-2">
                    If you'd like to have your scheduler emails sent to a
                    different email address than your primary Nutri-Q login
                    email you can enter it here.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s4} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Show Contact Email
                  </h2>
                  <p className="m-0 py-2">
                    If set to 'Yes' we'll show a line at the bottom of your
                    scheduler that gives clients a way to contact you if they
                    have questions when booking an appointment.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s4} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Reminders
                  </h2>
                  <p className="m-0 py-2">
                    When set to 'Yes' we'll send your clients email reminders
                    the day before their appointment.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s5} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Same Day Appointments
                  </h2>
                  <p className="m-0 py-2">
                    This section lets you specify whether or not your clients
                    are allowed to book appointments on the current day,
                    following day or within the next week.
                  </p>
                  <ul className="ps-3 mb-0 py-2">
                    <li className="">
                      <p className="fw-sbold m-0">
                        Allow same day appointments
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          By allowing same day appointments, your clients will
                          be able to book any open appointment. For example, if
                          today is March 5th, clients will be allowed to book
                          appointments on March 5th.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <p className="fw-sbold m-0">
                        Don't let clients book same day appointments
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          By not allowing same day appointments, your clients
                          won't be able to book an appointment for today. For
                          example, if today is March 5th your clients will only
                          see openings on your calendar starting on March 6th.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <p className="fw-sbold m-0">
                        Don't let clients book same or following day
                        appointments
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          By not allowing same or following day appointments,
                          your clients won't be able to book an appointment for
                          today or tomorrow. For example, if today is March 5th
                          your clients will only see openings on your calendar
                          starting on March 7th.
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <p className="fw-sbold m-0">
                        Don't let clients book appointments within a week
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          By not allowing clients to book appointments within a
                          week, your clients won't be able to book an
                          appointment today and for the following 6 days. For
                          example, if today is Monday March 5th your clients
                          will only see openings on your calendar starting on
                          Monday March 12th.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="imgWrp py-2">
                    <img src={s6} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Cancellation Policy
                  </h2>
                  <p className="m-0 py-2">
                    There are 2 settings within the Cancellation Policy section:
                  </p>
                  <ul className="ps-3 mb-0 py-2">
                    <li className="">
                      <p className=" m-0">
                        {" "}
                        <span className="fw-sbold">
                          Allow Cancellations:
                        </span>{" "}
                        This lets you set when a client can cancel their
                        appointment. The options are:
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          Appointments can be canceled anytime prior to the
                          appointment start
                        </li>
                        <li className="">
                          Appointments can't be canceled within 24 hours of the
                          appointment start
                        </li>
                        <li className="">
                          Appointments can't be canceled within 48 hours of the
                          appointment start
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <p className=" m-0">
                        {" "}
                        <span className="fw-sbold">
                          Cancellation Policy Text:
                        </span>{" "}
                        If you have additional cancellation policy text that you
                        would like to include, you can add it here. Note that if
                        you select the 24-hour or 48-hour policy above,
                        appropriate text will already be displayed when the
                        client books their appointment, so there is no need to
                        repeat that information in the Cancellation Policy Text.
                      </p>
                    </li>
                  </ul>
                  <div className="imgWrp py-2">
                    <img src={s7} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Working Hours
                  </h2>
                  <p className="m-0 py-2">
                    These are the days/times clients are allowed to book
                    appointments with you. In our example we have clicked the
                    'Add time' link under Monday, Wednesday and Friday and said
                    that on those days we work from 9am to 5pm.
                  </p>
                  <ul className="ps-3 mb-0 py-2">
                    <li className="">
                      <p className=" m-0">
                        {" "}
                        <span className="fw-sbold">
                          Allow Cancellations:
                        </span>{" "}
                        This lets you set when a client can cancel their
                        appointment. The options are:
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          Appointments can be canceled anytime prior to the
                          appointment start
                        </li>
                        <li className="">
                          Appointments can't be canceled within 24 hours of the
                          appointment start
                        </li>
                        <li className="">
                          Appointments can't be canceled within 48 hours of the
                          appointment start
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <p className=" m-0">
                        {" "}
                        <span className="fw-sbold">
                          Cancellation Policy Text:
                        </span>{" "}
                        If you have additional cancellation policy text that you
                        would like to include, you can add it here. Note that if
                        you select the 24-hour or 48-hour policy above,
                        appropriate text will already be displayed when the
                        client books their appointment, so there is no need to
                        repeat that information in the Cancellation Policy Text.
                      </p>
                    </li>
                  </ul>
                  <div className="imgWrp py-2">
                    <img src={s8} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Appointment Types
                  </h2>
                  <p className="m-0 py-2">
                    This is where you'll create the actual appointment types
                    that your clients can select from. For example, you might
                    have 3 different appointment types: one for an initial call,
                    one for 60-minute sessions and one for 90-minute sessions.
                    Each practitioner is different so find what works best for
                    you.
                  </p>
                  <ul className="ps-3 mb-0 py-2">
                    <li className="">
                      <p className=" m-0">
                        {" "}
                        <span className="fw-sbold">
                          Allow Cancellations:
                        </span>{" "}
                        This lets you set when a client can cancel their
                        appointment. The options are:
                      </p>
                      <ul className="ps-3 mb-0 py-2">
                        <li className="">
                          Appointments can be canceled anytime prior to the
                          appointment start
                        </li>
                        <li className="">
                          Appointments can't be canceled within 24 hours of the
                          appointment start
                        </li>
                        <li className="">
                          Appointments can't be canceled within 48 hours of the
                          appointment start
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <p className=" m-0">
                        {" "}
                        <span className="fw-sbold">
                          Cancellation Policy Text:
                        </span>{" "}
                        If you have additional cancellation policy text that you
                        would like to include, you can add it here. Note that if
                        you select the 24-hour or 48-hour policy above,
                        appropriate text will already be displayed when the
                        client books their appointment, so there is no need to
                        repeat that information in the Cancellation Policy Text.
                      </p>
                    </li>
                  </ul>
                  <div className="imgWrp py-2">
                    <img src={s9} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Appointment Types
                  </h2>
                  <p className="m-0 py-2">
                    This is where you'll create the actual appointment types
                    that your clients can select from. For example, you might
                    have 3 different appointment types: one for an initial call,
                    one for 60-minute sessions and one for 90-minute sessions.
                    Each practitioner is different so find what works best for
                    you.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s9} alt="" className="img-fluid" />
                  </div>
                  <p className="m-0 py-2">
                    In our example, we'll go ahead and click on the green 'Add
                    New Appointment Type' button and create a simple Free
                    Initial Call appointment type. Note that we have already
                    <Link to="" className="fw-sbold">
                      linked our Stripe Account
                    </Link>{" "}
                    so we see the 'Payment' section where we can require payment
                    for this type of appointment. Check out the{" "}
                    <Link className="fw-sbold">
                      Stripe Integration Guide for more details.
                    </Link>
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s10} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <p className="m-0 py-2">
                    After we save our new appointment type, we get a summary
                    view of the appointment. If you have 2 or more appointment
                    types you can update the order that they'll be displayed to
                    the client by clicking on the two 'Move' buttons at the
                    bottom of each appointment type.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s11} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Blocks
                  </h2>
                  <p className="m-0 py-2">
                    If you know of future dates that you'd like to block out so
                    clients aren't able to book appointments during those times,
                    you can add them here. You can also easily add them later
                    once the setup is complete.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s12} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Setup is Done
                  </h2>
                  <p className="m-0 py-2">
                    We've now setup our scheduler! You can click on the 'View
                    your Scheduler' to preview your scheduler in a new tab.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s13} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Preview
                  </h2>
                  <p className="m-0 py-2">
                    Here's what our preview looks like. We only created one
                    appointment type so that is the only option we can select
                    from. We'll walk through a client selecting and booking an
                    appointment in subsequent articles.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s13} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Finish
                  </h2>
                  <p className="m-0 py-2">
                    We can now jump back to the setup view and click on the
                    'Finish' button:
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s13} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="py-2 my-2">
                  <h2 class="m-0 fw-bold d-flex align-items-center pb-2">
                    Updated Appointments View
                  </h2>
                  <p className="m-0 py-2">
                    We are now back on our 'Appointments' page and a lot has
                    changed since we have now setup our scheduler. Check out the
                    following articles to learn more about the Appointments page
                    and how clients book appointments.
                  </p>
                  <div className="imgWrp py-2">
                    <img src={s14} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpScheduleSetup;
