import React, { useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { decode } from 'html-entities';
import { chartOptions, dangerSignAnnotation, chartMax, chartMaxSuper, lineAnnotation } from 'components/shared/ChartConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export function BarChart({
  chartData,
  height,
  screenSize,
  selectedNAQs,
  getColor,
  innerRef,
}) {
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState(chartOptions)

  useEffect(() => {
    if(chartData && chartData?.length > 0) {
      
      let labels = [];
      const chartLabels = {};
      for(let i = 0; i < chartData[0]?.data?.bar?.length; i++) {
        for(let j = 0; j < chartData.length; j++ ) {
          if(selectedNAQs.length && !selectedNAQs.includes(chartData[j].todo_id)) {
            continue;
          }
          let row = chartData[j]?.data.bar[i];
          let label = row?.rawTotal;
          labels.push(label);

          chartLabels[`chartLabel_${i}_${j}`] = {
            type: 'label',
            xValue: labels.length - 1,
            yValue: 17,
            content: [decode(row?.title)],
            font: {
              size: 15
            },
            rotation: 270,
            position: "center",
            align: "left",
            color: "#000",
          }
        }
      }

      let dangerLabels = {};

      let dataset = [];
      let bgColors = [];
      for(let i = 0; i < chartData[0]?.data?.bar?.length; i++) {
        for(let j = 0; j < chartData.length; j++ ) {
          if(selectedNAQs.length && !selectedNAQs.includes(chartData[j].todo_id)) {
            continue;
          }
          let row = chartData[j]?.data.bar[i];
          if(!row) {
            continue;
          }
          
          let percentOfMax = row.percentOfMax;
          let origValue = percentOfMax * chartMax;
          let value = origValue;
          
          // keeping a reserved space for the danger sign so adding +5
          if((value + 5) > chartMaxSuper) {
            value = chartMaxSuper;
          }
          dataset.push(value);
          bgColors.push(getColor(chartData[j].todo_id));
          if(percentOfMax == 1) {
            // if the bar value is higher than chartMax than the danger sign should not follow the bar value
            dangerLabels[`danger_${i}_${j}`] = dangerSignAnnotation(dataset.length - 1, (origValue + 5 > chartMaxSuper ? chartMaxSuper - 15 : value))
          }
        }
      }

      const data = {
        labels: labels,
        datasets: [
          {
            label: '',
            data: dataset,
            backgroundColor: bgColors,
          }
        ],
      };
      
      let xAdjustValue = (function (){
        switch(screenSize) {
          case "extra-small":
            return -20;
          case "small":
            return -20;
          case "medium":
            return -30;
          case "large":
            return -40;
          default:
            return -40;
        }
      })();
            
      let newOptions = {
        ...chartOptions,
        scales: {
          ...chartOptions.scales,
          x: {
            ...chartOptions.scales.x,
            ticks: {
              color: "#000",
              font: {
                size: 15,
              }
            }
          }
        },
        plugins: {
          ...chartOptions.plugins,
          tooltip: {
            enabled: false,
            callbacks: {
                label: function(context) {
                  // if(chartData) {
                  //   let label = chartData.bar[context.dataIndex]?.rawTotal;
                  //   return label;
                  // }
                },
                // title: function(context) {
                //   if(chartData) {
                //     let title = chartData.bar[context[0].dataIndex]?.title;
                //     return decode(title);
                //   }
                // }
            }
          },
          annotation: {
            ...chartOptions.plugins.annotation,
            annotations: {
              ...chartOptions.plugins.annotation.annotations,
              ...chartLabels,
              ...dangerLabels,
              label1: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 1.2),
                content: ['Low Priority'],
              },
              label2: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 2),
                content: ['Medium Priority'],
              },
              label3: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax / 1.2,
                content: ['High Priority'],
              },  
            }
          }
        }
      }

      setFormattedData(data);
      setLoading(false);
      setOptions(newOptions);
    }
  }, [chartData, screenSize, selectedNAQs]);

  useEffect(() => {
    // console.log("chartRef", chartRef.current, chartRef.current.annotation)
    // if(chartRef.current) {
    //   console.log("annotations", chartRef.current?.annotation?.elements);
    //   chartRef.current.update();
    // }
  }, [options])

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Bar
          ref={innerRef}
          options={options}
          data={formattedData}
          height={height}
        />
      }
    </>
  );
}
