import { decode } from "html-entities";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const QuestionAnsPopup = ({ showPopup, closePopup, questionIds, answers, supportText }) => {

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="RecommReportQueAnsPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>{supportText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12">
              <p>
                The following questions/answers contributed to{" "}
                <i>{supportText}</i>{" "}
                being listed as a Top Potential Support.  
              </p>
            </Col>
            <Col lg="12" className="my-2">
            <table className="table table-striped suggestions-que-ans">
              <thead>
                {" "}
                <tr>
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Question
                  </th>
                  <th className="p-3 fw-normal text-dark fw-sbold">
                    Answer
                  </th>
                </tr>
              </thead>
              <tbody>
                {answers && answers.length > 0 ?
                  answers.map((question) => {
                    // let question = answers.find(ans => ans.question_id == questionId);
                    if(!question) {
                      return null;
                    }

                    return (
                      <tr>
                        <td className="p-3 border-0">
                            {question.question_num}. {decode(question.question)}
                        </td>
                        <td className="p-3 border-0">
                            {decode(question.answer)}
                        </td>
                      </tr>
                    )
                  })
                  :
                  <tr>
                    <td colSpan={2} className="p-3 border-0">Data not available</td>
                  </tr>
                }
              </tbody>
            </table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionAnsPopup;
