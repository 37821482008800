import React from "react";
import { Col, Row } from "react-bootstrap";

const Address = ({ question }) => {
  return (
    <div className="address-fields my-address-fields">
      <Row>
        <Col lg="12" className="my-2">
          <label htmlFor="" className="form-label fw-bold text-dark">
            Address
          </label>
          <input
            type="text"
            className={`form-control my-2`}
            placeholder="Street address, P.O. box etc"
            disabled
          />
          <input
            type="text"
            className={`form-control my-2`}
            name="address_2"
            placeholder="Apartment, suit, unit, building, floor etc"
            disabled
          />
        </Col>
        <Col lg="12" className="my-2">
          <Row>
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label fw-bold text-dark">
                City
              </label>
              <input type="text" className="form-control" placeholder="City" disabled />
            </Col>
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label fw-bold text-dark">
                State/Province/Region
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="State/Province/Region"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col lg="12" className="my-2">
          <Row>
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label fw-bold text-dark">
                Zip/Postal Code
              </label>
              <input type="text" className="form-control" placeholder="Zip Code" disabled />
            </Col>
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label fw-bold text-dark">
                Country
              </label>
              <input type="text" className="form-control" placeholder="Country" disabled />
            </Col>
          </Row>
        </Col>
        <Col lg="12" className="my-2">
          <Row>
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label fw-bold text-dark">
                Phone Number
              </label>
              <input type="text" className="form-control" placeholder="Phone #" disabled />
            </Col>
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label fw-bold text-dark">
                Phone Number Type
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Cell, Home, Work etc."
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Address;
