import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, SUBSCRIPTION_LIST,SUBSCRIPTION_ADD, SUBSCRIPTION_UPDATE, SUBSCRIPTION_VIEW, SUBSCRIPTION_DELETE, SUBSCRIPTION_HISTORY_, BILLING_INFO_URL, ACTIVE_PLAN_LIST, STRIPE_BILLING_PORTAL, GET_SUBSCRIPTION_PACAKGES_LIST } from './apiPath'
import { getSubscription, addSubscription, viewSubscription, updateSubscription, deleteSubscription, getSubscriptionHistory, billingStripeUrl, activeSubscription} from 'store/actions/subscription';


//get
export function getSubscriptionList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${SUBSCRIPTION_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getSubscription(data, callback));
      return data;
    });
}

//add
export function addSubscriptionData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${SUBSCRIPTION_ADD}`, value)
    .then((data) => {
      dispatch(addSubscription(data, callback));
      return data;
    });
}

//view
export function viewSubscriptionData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${SUBSCRIPTION_VIEW}${value}`)
    .then((data) => {
      dispatch(viewSubscription(data, callback));
      return data;
    });
}


//edit
export function updateSubscriptionData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${SUBSCRIPTION_UPDATE}`, value)
    .then((data) => {
      dispatch(updateSubscription(data, callback));
      return data;
    });
}


//delete
export function deleteSubscriptionData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${SUBSCRIPTION_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteSubscription(data));
      return data;
    });
}

//get subscription history
export function getSubscriptionHistory_(value, callback) {
  let url = `${MAIN_PATH}${SUBSCRIPTION_HISTORY_}`;
  return (dispatch) => getData(url, value)
  .then((data) => {
    dispatch(getSubscriptionHistory(data, callback));
    return data;
  });
}

//stripe connect link
export function billingStripeUrl_(value, callback) {
  let url = `${MAIN_PATH}${BILLING_INFO_URL}`;
  return (dispatch) => postData(url, value)
  .then((data) => {
    dispatch(billingStripeUrl(data, callback));
    return data;
  });
}

//stripe connect link
export function stripeUpdatePlanPortalUrl(value, callback) {
  let url = `${MAIN_PATH}${STRIPE_BILLING_PORTAL}`;
  // let url = `http://localhost:3000/dev/subscriptions/update/portal`;
  return postData(url, value);
}


//active subscription
export function activeSubscription_(value, callback) {
  let url = `${MAIN_PATH}${ACTIVE_PLAN_LIST}`;
  return (dispatch) => getData(url, value)
  .then((data) => {
    dispatch(activeSubscription(data, callback));
    return data;
  });
}

// get packages listing
export function getSubscriptionPackages(value, callback) {
  let url = `${MAIN_PATH}${GET_SUBSCRIPTION_PACAKGES_LIST}`;
  return getData(url);
}