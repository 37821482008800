import { Formik } from "formik"
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { isEmpty } from "helpers/common";
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";

const DisclaimerFields = ({ columnData, setColumnData, isValid, setFieldValue, errors }) => {
  const [fields, setFields] = useState({
    disclaimer_title: "",
    disclaimer_text: "",
    checkbox_text: "I understand that checking this box constitutes a legal signature confirming that I acknowledge and agree to the above Disclaimer.",
    require_name_date: "no"
  });

  useEffect(() => {
    // handleFormSubmit(fields);
  }, [fields])

  useEffect(() => {
    if(!isEmpty(columnData)){
      setFields({
        disclaimer_title: columnData ? columnData.disclaimer_title : "",
        disclaimer_text: columnData ? columnData.disclaimer_text : "",
        checkbox_text: columnData ? columnData.checkbox_text : fields.checkbox_text,
        require_name_date: columnData ? columnData.require_name_date : fields.require_name_date,
      });
    } else {
      handleFormSubmit(fields);
    }
  }, [columnData]);

  useEffect(() => {
    // make this question required
    setFieldValue("is_required", "yes");
  }, [])

  const handleFormSubmit = (values) => {
    try {
      setColumnData(values);
      setFieldValue("question", values.disclaimer_title);
    } catch (err) {
      console.log("Error saving values", err);
    }
  }

  const handleInputChange = (value, fieldName) => {
    // handleChange(e);
    setFields(prevState  => {
      let newState = {
        ...prevState,
        [fieldName]: value
      }
      handleFormSubmit(newState);
      return newState;
    });
  }
  
  return (
    <Row>
      <Col lg="12" className="my-2">
        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
          Disclaimer/Agreement Title*
        </label>
        <p className="m-0">The title of your disclaimer/agreement.</p>
        <input
          type="text"
          className={`form-control ${errors.disclaimer_title && "is-invalid"}`}
          name="disclaimer_title"
          onChange={(e) => handleInputChange(e.target.value, "disclaimer_title")}
          value={fields.disclaimer_title}
          autoComplete="off"
        />
        {errors.disclaimer_title && (
          <p className="invalid-feedback">{errors.disclaimer_title}</p>
        )}
      </Col>
      <Col lg="12" className="my-2">
        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
          Disclaimer/Agreement Text*
        </label>
        <p className="m-0">Your disclaimer/agreement text.</p>
        <div className="ck-editor disclaimer">
          <CKEditor
              editor={ Editor }
              data={fields.disclaimer_text}
              config={{
                // plugins: [FontColor],
                
                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'fontColor', 'fontBackgroundColor', 'fontSize', 'bulletedList', 'numberedList', 'blockQuote' ],
              }}
              onChange={(event, editor) => {
                  handleInputChange(editor.getData(), "disclaimer_text")
              }}
          />
        </div>
        {/* <textarea
          className={`form-control ${errors.disclaimer_text && "is-invalid"}`}
          name="disclaimer_text"
          onChange={(e) => handleInputChange(e.target.value, "disclaimer_text")}
          value={fields.disclaimer_text}
          autoComplete="off"
          rows={5}
        ></textarea> */}
        {errors.disclaimer_text && (
          <p className="invalid-feedback">{errors.disclaimer_text}</p>
        )}
      </Col>
      <Col lg="12" className="my-2">
        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
          "Agree To" Checkbox Text*
        </label>
        <p className="m-0">Under your disclaimer/agreement text there will be a checkbox your client clicks to confirm they have read and agree to the terms. This is the text shown next to that checkbox.</p>
        <input
          type="text"
          className={`form-control ${errors.checkbox_text && "is-invalid"}`}
          name="checkbox_text"
          onChange={(e) => handleInputChange(e.target.value, "checkbox_text")}
          value={fields.checkbox_text}
          autoComplete="off"
        />
        {errors.checkbox_text && (
          <p className="invalid-feedback">{errors.checkbox_text}</p>
        )}
      </Col>
      <Col lg="12" className="my-2">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp position-relative ">
            <input
              type="checkbox"
              className={`position-absolute w-100 h-100 ${errors.is_required && 'is-invalid'}`}
              name="require_name_date"
              onChange={(e) => handleInputChange(e.target.checked ? "yes" : "no", "require_name_date")}
              value={fields.require_name_date}
              checked={fields.require_name_date == "yes"}
              autoComplete="off"
            />
            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
              <span className="icn d-none">
                <OTick />
              </span>
            </div>
          </div>
          <p className=" m-0 ">
            In addition to the checkbox, require that clients type in their full name and date to confirm the agreement.
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default DisclaimerFields;