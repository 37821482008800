import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getDataAnalysisProrityReportsV2 } from "store/services/clientDataReportsService";
import SelectNAQs from "../../SelectNAQs";
import PriorityReport from "./Components/PriorityReport";
import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";
import DownloadPriorityCompReportPDF from "./Components/PriorityCompReportPDF";

const PriorityComparisionReport = ({ dispatch, naqList, client }) => {
  const { clientId } = useParams();
  const [priorityReport, setPriorityReport] = useState(null);
  const [selectedNAQs, setSelectedNAQs] = useState([]);
  const [selectedType, setSelectedType] = useState("questionnaireo");

  useEffect(() => {
    let todoIds = naqList && naqList.map(naq => naq.id);
    if (todoIds) {
      getNAQv2PriorityComparisionData(todoIds);
    }
  }, [naqList]);

  useEffect(() => {
    if (selectedNAQs && selectedNAQs.length > 1) {
      getNAQv2PriorityComparisionData(selectedNAQs);
    }
  }, [selectedNAQs])

  const getNAQv2PriorityComparisionData = async (todoIds) => {
    try {
      let data = {
        clientId,
        values: {
          todoIds
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      const response = await getDataAnalysisProrityReportsV2(data);
      dispatch(actions.persist_store({ loader: false }));
      if (response.success === true) {
        setPriorityReport(response.body);
      }
    } catch (error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeSelectedNAQ = (selected) => {
    setSelectedNAQs(selected);
  }

  return (
    <>
      <div className="top d-flex align-items-start justify-content-between gap-10">
        <div className="left">
          <h6 className="m-0 fw-bold">Priority Comparison Report</h6>
          <p className="m-0">
            Compare Priority Reports from {FULL_NAME(client.first_name, client.last_name)}'s NAQs to see what's changed.
          </p>
        </div>
        <div className="right">
          <DownloadPriorityCompReportPDF
            client={client}
            naqList={naqList}
            todoIds={selectedNAQs}
            label={"Download Report"}
            type={selectedType}
          />
        </div>
      </div>
      <div className="CardBody p-3 bg-light my-2">
        <Row>
          <Col lg="8">
            <SelectNAQs naqList={naqList} onChange={handleChangeSelectedNAQ} />
          </Col>
          <Col lg="4">
            <div className="">
              <p className="m-0 text-uppercase fw-bold">View Priority Report grouped by:</p>
              <Form.Select
                className="form-control fw-sbold btnSm"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                <option value="questionnaireo">Questionnaire Section</option>
                <option value="SBG">SBG Section</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <PriorityReport
          priorityReport={priorityReport}
          naqList={naqList}
          selectedNAQs={selectedNAQs}
          selectedType={selectedType}
        />
      </div>
      <p className="m-0 py-2 text-dark">
        *Looking for all the NAQ questions/answers your client submitted? All
        of the NAQ data is under the{" "}
        <Link to="" className="underline">
          Data & To-Do Items
        </Link>{" "}
        tab.
      </p>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PriorityComparisionReport);