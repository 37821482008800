import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import TimePicker from "components/shared/TimePicker/Index";
import { TIME_FORMAT_12, TIME_FORMAT_24 } from "common/constants";
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
// import 'rc-time-picker/assets/index.css';

const defaultTimeFields = {
  start_time: moment().format(TIME_FORMAT_12),
  end_time: moment().add(30, "minutes").format(TIME_FORMAT_12),
}


const AddTimeRowPopup = ({ showPopup, setShowPopup, closePopup, handleSubmit, editRowData }) => {
  // const [startTimeMeridian, setStartTimeMeridian] = useState(moment().format("A"));
  // const [endTimeMeridian, setEndTimeMeridian] = useState(moment().format("A"));

  const [initialValues, setInitialValues] = useState({
    ...defaultTimeFields
  });

  useEffect(() => {
    if (editRowData) {
      setInitialValues({
        start_time: editRowData ? editRowData.start_time : initialValues.start_time,
        end_time: editRowData ? editRowData.end_time : initialValues.end_time,
      });
    } else {

    }
  }, [editRowData]);

  const handleSubmitTime = (values, actions) => {
    try {
      let data = {
        start_time: values.start_time,
        end_time: values.end_time,
      }
      handleSubmit(data, actions);
      hidePopup();
    } catch (err) {
      console.log("Error occurred", err)
    }
  }

  const hidePopup = () => {
    closePopup();
    setInitialValues({
      ...defaultTimeFields
    });
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={hidePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="timeRowPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">{editRowData ? "Edit Row" : "Add Row"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            // validationSchema={validation}
            onSubmit={handleSubmitTime}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => {
              // console.log("values", values, errors);
              return (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Row className="align-items-center my-2">
                    <Col lg="3" className="my-2">
                      <div className="labelBox px-3 py-2 border bg-light rounded">
                        <h6 className="m-0 fw-bold">Start Time</h6>
                      </div>
                    </Col>
                    <Col lg="6" className="my-2">
                      {/* <input
                        className={`form-control ${errors.start_time && "is-invalid"}`}
                        type="time"
                        name="start_time"
                        value={values.start_time}
                        onChange={handleChange}
                      /> */}
                      {/* <TimePicker
                        disableClock
                        clearIcon={false}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"

                        // showSecond={false}
                        // defaultValue={moment(values.start_time).format(TIME_FORMAT_12)}
                        value={values.start_time ? moment(values.start_time, TIME_FORMAT_12).format(TIME_FORMAT_24) : ""}
                        className={`${errors.start_time && "is-invalid"}`}
                        onChange={(value) => {
                          if (!value) return;
                          console.log("time value", value, moment(value, TIME_FORMAT_12).format(TIME_FORMAT_12))
                          setFieldValue("start_time", moment(value, TIME_FORMAT_12).format(TIME_FORMAT_12));
                        }}
                        format={TIME_FORMAT_12}
                        // use12Hours
                        // inputReadOnly
                      /> */}
                      {/* <TimePicker
                        value={values.start_time ? moment(values.start_time, TIME_FORMAT_12).format(TIME_FORMAT_24) : ""}
                        className={`${errors.start_time && "is-invalid"}`}
                        onChange={(value) => {
                          if (!value) return;
                          setFieldValue("start_time", moment(value, TIME_FORMAT_12).format(TIME_FORMAT_12));
                        }}
                      /> */}
                      <DatePicker
                        selected={moment(values.start_time || "00:00", TIME_FORMAT_12).toDate()}
                        onChange={(value) => {
                          setFieldValue("start_time", moment(value).format(TIME_FORMAT_12));
                        }}
                        className="form-control h-auto"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      {errors.start_time && touched.start_time && (
                        <p className="invalid-feedback">{errors.start_time}</p>
                      )}
                    </Col>
                    {/* <Col lg="3" className="my-2">
                      <Button
                        variant="outline"
                        onClick={() => {
                          toggleMeridian(startTimeMeridian, setStartTimeMeridian);
                        }}
                      >
                        {startTimeMeridian}
                      </Button>
                    </Col> */}
                  </Row>
                  <Row className="align-items-center my-2">
                    <Col lg="3" className="my-2">
                      <div className="labelBox px-3 py-2 border bg-light rounded">
                        <h6 className="m-0 fw-bold">End Time</h6>
                      </div>
                    </Col>
                    <Col lg="6" className="my-2">
                      {/* <input
                        className={`form-control ${errors.end_time && "is-invalid"}`}
                        type="time"
                        name="end_time"
                        value={values.end_time}
                        onChange={handleChange}
                      /> */}
                      {/* <TimePicker
                        // showSecond={false}
                        clearIcon={false}
                        disableClock
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"

                        // defaultValue={values.end_time}
                        value={values.end_time ? moment(values.end_time, TIME_FORMAT_12).format(TIME_FORMAT_24) : ""}
                        className={`${errors.end_time && "is-invalid"}`}
                        onChange={(value) => {
                          if (!value) return;
                          console.log("time value", moment(value,   ).format(TIME_FORMAT_12))
                          setFieldValue("end_time", moment(value, TIME_FORMAT_12).format(TIME_FORMAT_12));
                        }}
                        format={TIME_FORMAT_12}
                      // use12Hours
                      // inputReadOnly
                      /> */}
                      {/* <TimePicker
                        value={values.end_time ? moment(values.end_time, TIME_FORMAT_12).format(TIME_FORMAT_24) : ""}
                        className={`${errors.end_time && "is-invalid"}`}
                        onChange={(value) => {
                          if (!value) return;
                          setFieldValue("end_time", moment(value, TIME_FORMAT_12).format(TIME_FORMAT_12));
                        }}
                      /> */}
                      <DatePicker
                        selected={moment(values.end_time || "00:00", TIME_FORMAT_12).toDate()}
                        onChange={(value) => {
                          setFieldValue("end_time", moment(value).format(TIME_FORMAT_12));
                        }}
                        className="form-control h-auto"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      {errors.end_time && touched.end_time && (
                        <p className="invalid-feedback">{errors.end_time}</p>
                      )}
                    </Col>
                    {/* <Col lg="3" className="my-2">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          toggleMeridian(endTimeMeridian, setEndTimeMeridian);
                        }}
                      >
                        {endTimeMeridian}
                      </Button>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div className="d-flex justify-content-end gap-10">
                        <Button
                          className="d-flex align-items-center justify-content-center commonBtn"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Please wait..." : editRowData ? "Edit Row" : "Add Row"}
                        </Button>
                        <Button
                          onClick={hidePopup}
                          className="d-flex align-items-center justify-content-center commonBtn"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const validation = Yup.object({
  email: Yup.string().required("Email is Required").email("Invalid email address"),
  password: Yup.string().required("Password is Required").min(8, "Password must be at least 8 characters long."),
});

export default AddTimeRowPopup;
