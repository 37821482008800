import React, { useState, useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddTagPop from "./Modal/AddTagPop";
import { connect } from "react-redux";
import { DELETE_ALERT } from "common/constantMessage";

// imported
import {
  addTagsData,
  getTagsList,
  updateTagsData,
  deleteTagsData,
} from "store/services/tagsService";

const MyTags = ({ dispatch, tagList }) => {
  const [sending, setSending] = useState(false);
  const [colorCode, setColorCode] = useState("");
  const [sateCurrentValue, setSateCurrentValue] = useState(null);
  const [tagsListing, setTagsListing] = useState([]);

  const [fields, setfields] = useState({
    name: "",
    color_code: "#FFFFFF",
  });
  const [tagPop, setTagPop] = useState("");
  const handleAddTag = () => {
    setTagPop(!tagPop);
  };

  const handleAddTagEdit = (val) => {
    setTagPop(true);
    setSateCurrentValue(val);
  };

  const onChangeColorHandleChange = async (color) => {
    setColorCode(color.hex);
  };

  //get tag list
  async function getTagsList_() {
    try {
      let res = await dispatch(getTagsList());
      if(res?.body){
        setTagsListing(res?.body)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (sateCurrentValue) {
      setfields((prevState) => ({
        ...prevState,
        ...sateCurrentValue,
      }));
      setColorCode(sateCurrentValue.color_code);
    }
    if (sateCurrentValue == null) {
      setfields({});
    }
  }, [sateCurrentValue]);

  useEffect(() => {
    getTagsList_();
  }, [colorCode]);

  //handle changes
  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );
  const submitData_ = async (evt) => {
    try {
      evt.preventDefault();
      let obj = {
        name: fields.name,
        color_code: colorCode,
      };
      obj = JSON.stringify(obj);
      setSending(true);
      if (sateCurrentValue) {
        let fieldId = sateCurrentValue.id;
        await dispatch(updateTagsData(fieldId, obj));
      } else {
        await dispatch(addTagsData(obj));
      }
      setTagPop(false);
      setfields({});
      setSending(false);
      setSateCurrentValue(null);
      getTagsList_();
    } catch (err) {
      console.log(err, "==============================");
      setSending(false);
    }
  };
  //delete
  const deleteTags_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteTagsData(id)).then(() => {
              setTagPop(false);
              getTagsList_();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <AddTagPop
        tagPop={tagPop}
        setTagPop={setTagPop}
        fields={fields}
        submitData_={submitData_}
        onChangeColorHandleChange={onChangeColorHandleChange}
        handleChange={handleChange}
        colorCode={colorCode}
        sending={sending}
        setSending={setSending}
        sateCurrentValue={sateCurrentValue}
        setSateCurrentValue={setSateCurrentValue}
        deleteTags_={deleteTags_}
      />
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">My Tags</h3>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <p className="m-0 text-dark py-1">
              {" "}
              Click on a tag to edit, update or delete it. The number next to
              the tag indicates how many clients have this tag.
            </p>
            <div className="btnWrp mt-3">
              <Button
                onClick={handleAddTag}
                className="d-inline-flex align-items-center justify-content-center commonBtn btnSm"
              >
                <span className="icn me-2 fw-bold">+</span>
                Add New Tag
              </Button>
              <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center mt-3">
                {tagsListing.length > 0 &&
                  tagsListing.map((val, i) => {
                    return (
                      <li
                        className="tags_list me-2"
                        key={i}
                        onClick={() => handleAddTagEdit(val)}
                      >
                        <span
                          className="CstmLabel"
                          style={{
                            borderColor: val?.color_code,
                            color: "#fff",
                            background: val?.color_code,
                          }}
                        >
                          {val.name} ({i})
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    tagList: state.Tags.tags,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyTags);
