import { helpEmail } from "common/constants";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer py-3" style={{ background: "#f2f2f2" }}>
        <Container className="me-0" fluid>
          <Row>
            <Col lg="4" className="my-2">
              <h4 className="m-0 fw-sbold fhead">Related Links</h4>
              <ul className="list-unstydled ps-3 mb-0 pt-3">
                <li className="">
                  <Link to="" className="fw-sbold">
                    Adding Your First Client
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg="4" className="my-2">
              <h4 className="m-0 fw-sbold fhead">Previous Article</h4>
              <ul className="list-unstydled ps-3 mb-0 pt-3">
                <li className="">
                  <Link to="" className="fw-sbold">
                    FAQs
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg="4" className="my-2">
              <h4 className="m-0 fw-sbold fhead">Next Article</h4>
              <ul className="list-unstydled ps-3 mb-0 pt-3">
                <li className="">
                  <Link to="" className="fw-sbold">
                    Adding Your First Client
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg="12" className="my-2 pt-4">
              <p className="m-0">
                Still need help? Email us at{" "}
                <span className="fw-sbold">{helpEmail}</span>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
