import { decode } from "html-entities";
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";
import parse from "html-react-parser";

const Disclaimer = ({ question }) => {
  let fields = {};
  try {
    let parsedData = JSON.parse(question.other_column_data);
    if (parsedData) {
      fields = parsedData;
    }
  } catch (error) {
    console.log("Error while parsing disclaimer fields", question.question);
  }

  return (
    <>
      <p className="mt-2 mb-0">
        <b>Disclaimer Title:</b> {parse(fields.disclaimer_title)}
      </p>
      <p className="">
        <b>Disclaimer Content:</b> {parse(fields.disclaimer_text)}
      </p>
      <div className="disclaimer-field">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp previewFormField position-relative">
            <input
              type="checkbox"
              className={`position-absolute w-100 h-100 `}
              name="agreed"
              disabled
            />
            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
              <span className="icn d-none">
                <OTick />
              </span>
            </div>
          </div>
          <p className="m-0 ">{decode(fields.checkbox_text)}</p>
        </div>
      </div>
      {fields && fields.require_name_date == "yes" && (
        <div className="py-2">
          <p className="m-0">
            Please type in your full name and today's date to electronically confirm your agreement
          </p>
          <input
            placeholder="Your Full Name"
            className={`form-control my-2`}
            type="text"
            disabled
          />
          <input
            placeholder="Today's Date"
            className={`form-control my-2`}
            type="date"
            disabled={true}
          />
        </div>
      )}
    </>
  );
};

export default Disclaimer;
