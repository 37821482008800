import React, {useEffect, useState} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// images
import bookApptImg from "Assets/Images/bookAppt.png";

const AppointmentLinkPopUp = ({
  appointmentLinkPop,
  setAppointmentLinkPop,
  apptTypeId,
  user
}) => {
  const handleAppointmentLinkPop = () =>
    setAppointmentLinkPop(!appointmentLinkPop);

    const [copied, setCopied] = useState(false);
    let link_ = `${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}?apptType=${apptTypeId}`;

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(link_);
        setCopied(true);
      } catch (err) {
        console.error('Failed to copy!', err);
      }
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 1000); 
    }, [copied])

  return (
    <>
      <Modal
        show={appointmentLinkPop}
        onHide={handleAppointmentLinkPop}
        backdrop="static"
        keyboard={false}
        centered
        className="appointmentLinkPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Appointment Link</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <p className="m-0 py-1">
              Here's the link to this specific appointment.
            </p>
            <Form>
              <Row>
                <Col lg="12" className="my-2">
                  <div className="iconWithText position-relative">
                    <input
                      type="text"
                      value={link_}
                      className="form-control"
                    />
                    <Button
                      style={{ left: "unset", right: "10px" }}
                      onClick={copyToClipboard}
                      className="d-flex align-items-center justify-content-center commonBtn btnSm mt-2 w-100"
                    >
                      {copied ? 'Copied!' : 'Copy Link'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={handleAppointmentLinkPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppointmentLinkPopUp;
