import React, { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { Link } from "react-router-dom";
import {convertTo12HourFormat} from 'helpers/common'
import { appointmentTypeAdd_ } from 'store/services/appointmentService';
// icn
import { ReactComponent as OTick } from "../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as StripeIcn } from "../../../../../Assets/Images/icn/stripe.svg";
import * as actions from 'store/actions';
import { getIntegrationStatus } from "store/services/accountSettingService";
import { type } from "@testing-library/user-event/dist/type";
import { appointmentTypeAdd } from "store/actions/appointment";
import { HelpStripeLinkAccount } from "Routes/paths";

const AppointmentTypePop = ({dispatch, appointmentPop, setAppointmentPop, workingHours, setUpdateAppType, currentAppType, buttonDisable, setButtonDisable, typeFields, setTypeFields, bufferType, setBufferType, availability, setAvailability, stripeConnected, handleAppointmentPop }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Required'),
    duration: Yup.string()
      .required('Required'),
    description: Yup.string()
      .required('Required')
  });

  const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

  // const handleAppointmentPop = () => setAppointmentPop(!appointmentPop);
  const [sending, setSending] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [availabileHours, setAvailabileHours] = useState([]);
  const [requirePayment, setRequirePayment] = useState(typeFields.is_paid_appointment === 'yes');

  //handleChange
  const handleChange = useCallback(
    name => evt => {
      const value = evt.target.value;
      setTypeFields(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  useEffect(() => {
    setTypeFields((prevState) => ({
      ...prevState,
      ...currentAppType,
    }));
    setRequirePayment(currentAppType?.is_paid_appointment === 'yes' ? true : false)
    setBufferType(currentAppType?.buffer_after || false);
    setAvailability(currentAppType?.available_working_hours || false);
    setAvailabileHours((prevHours) => {
      const hoursArray = currentAppType?.available_working_hours ? JSON.parse(currentAppType?.available_working_hours) : [];
      return hoursArray.flatMap(hr => hr);
    });
    
  }, [currentAppType]);

  useEffect(() => {
    getThirdPartyIntegrationStatus();
  }, [])
  
  //submit appointment types
  const submitTypeData_ = async (evt) => {
    try {
      evt.preventDefault();
      
      const validation = await validateData(typeFields);
  
      if (validation.isValid) {
        let obj = {
          title: typeFields.title,
          duration: typeFields.duration ? typeFields.duration : 15,
          buffer_before: bufferType ? typeFields.buffer_before : "",
          buffer_after: bufferType ? typeFields.buffer_after : "",
          description: typeFields.description,
          additonal_notes: typeFields.additonal_notes,
          available_working_hours: JSON.stringify(availabileHours),
          location: typeFields.location,
          location_notes:
            typeFields.location === 'in_person'
              ? typeFields.in_person
              : typeFields.location === 'by_phone'
              ? typeFields.by_phone
              : typeFields.location === 'video_zoom'
              ? typeFields.video_zoom
              : '',
          video_url: typeFields.location === 'video_zoom' ? typeFields.video_url : '',
          is_paid_appointment: 'no',
        };

        if (requirePayment) {
          obj = {
            ...obj,
            is_paid_appointment: 'yes',
            price: typeFields.price,
            currency_code: typeFields?.currency_code ? typeFields?.currency_code : 'USD',
            currency_sign: typeFields?.currency_sign ? typeFields?.currency_sign : '$',
          };
        }
  
        if (currentAppType) {
          obj = {
            ...obj,
            id: currentAppType.id,
          };
        }
        obj = JSON.stringify(obj);
  
        setSending(true);
        setButtonDisable(true);
        dispatch(actions.persist_store({ loader: true }));
  
        await dispatch(appointmentTypeAdd_(obj));
  
        setAppointmentPop(false);
        setButtonDisable(false);
        setSending(false);
        setUpdateAppType(true);
        dispatch(actions.persist_store({ loader: false }));
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setSending(false);
      setButtonDisable(false);
      dispatch(actions.persist_store({ loader: false }));
      console.error(err, "==============================");
    }
  };
  
  //avaiable hours 
  const handleCheckboxChange_ = (event, time, day) => {
    console.log(time, day);
    const isChecked = event.target.checked;
  
    if (isChecked) {
      // If checkbox is checked, add the time to the selected hours
      setAvailabileHours((prevSelected) => {
        const isDayPresent = prevSelected.some((item) => item.day === day.day);
  
        if (isDayPresent) {
          const updatedState = prevSelected.map((item) => {
            if (item.day === day.day) {
              // Update the time array for the specific day
              return {
                ...item,
                time: [...item.time, time],
              };
            }
            return item;
          });
  
          return updatedState;
        } else {
          // If the day is not present, add a new entry for that day
          return [...prevSelected, { day: day.day, time: [time] }];
        }
      });
    } else {
      // If checkbox is unchecked, remove the time from the selected hours
      setAvailabileHours((prevSelected) => {
        let updatedState = prevSelected.map((item) => {
          if (item.day === day.day) {
            // Filter out the specific time object from the time array
            return {
              ...item,
              time: item.time.filter(
                (t) =>
                  t.start_time !== time.start_time || t.end_time !== time.end_time
              ),
            };
          }

          return item;
        });

        updatedState = updatedState.filter(item => {
          return !!(item.time && item.time.length);
        })
  
        return updatedState;
      });
    }
  };

  const getThirdPartyIntegrationStatus = async () => {
    try {
      await dispatch(getIntegrationStatus());
    } catch(err) {
      console.log("Failed to fetch integrations list", err);
    }
  }
  

  return (
    <>
    <Form>
      <Modal
        show={appointmentPop}
        onHide={handleAppointmentPop}
        backdrop="static"
        keyboard={false}
        centered
        scrollable="true"
        className="appointmentPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">
            Edit / Add Appointment Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <Form>
              <Row className="align-items-center my-2">
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={handleChange("title")}
                    value={typeFields.title}
                    placeholder="Title of your Appointment Type"
                    required
                  />
                  {validationErrors.title && (
                    <div className="text-danger">{validationErrors.title}</div>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Duration
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control"
                    name="duration"
                    onChange={handleChange("duration")}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="15" selected={typeFields.duration === '15' ?  'selected' : ''}>15 minutes</option>
                    <option value="20" selected={typeFields.duration === '20' ?  'selected' : ''}>20 minutes</option>
                    <option value="30" selected={typeFields.duration === '30' ?  'selected' : ''}>30 minutes</option>
                    <option value="45" selected={typeFields.duration === '45' ?  'selected' : ''}>45 minutes</option>
                    <option value="60" selected={typeFields.duration === '60' ?  'selected' : ''}>1 hour</option>
                    <option value="75" selected={typeFields.duration === '75' ?  'selected' : ''}>1 hour & 15 minutes</option>
                    <option value="90" selected={typeFields.duration === '90' ?  'selected' : ''}>1 hour & 30 minutes</option>
                    <option value="105" selected={typeFields.duration === '105' ?  'selected' : ''}>1 hour & 45 minutes</option>
                    <option value="120" selected={typeFields.duration === '120' ?  'selected' : ''}>2 hour</option>
                    <option value="135" selected={typeFields.duration === '135' ?  'selected' : ''}>2 hour & 15 minutes</option>
                    <option value="150" selected={typeFields.duration === '150' ?  'selected' : ''}>2 hour & 30 minutes</option>
                    <option value="165" selected={typeFields.duration === '165' ?  'selected' : ''}>2 hour & 45 minutes</option>
                    <option value="180" selected={typeFields.duration === '180' ?  'selected' : ''}>3 hour</option>
                    <option value="0" selected={typeFields.duration === '0' ?  'selected' : ''}>other/not applicable</option>
                  </Form.Select>
                  {validationErrors.duration && (
                    <div className="text-danger">{validationErrors.duration}</div>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <div className="py-2 d-flex align-items-start gap-10">
                    <div className="cstmInputCheckWrp position-relative ">
                      <input
                        type="checkbox"
                        className="position-absolute w-100 h-100"
                        // onChange={() => setBufferType(!bufferType)}
                        onChange={() => setBufferType((prevBufferType) => !prevBufferType)}
                        checked={bufferType}
                      />
                      <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                        <span className="icn d-none">
                          <OTick />
                        </span>
                      </div>
                    </div>
                    <p className=" m-0 ">
                      Add a time buffer before or after this appointment?
                    </p>
                  </div>
                  <p className="m-0">
                    Adding a time buffer is useful if you need time to prepare
                    between appointments. We'll ensure no appointments are
                    booked during your buffer time periods.
                  </p>
                </Col>
                {bufferType &&
                <>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Buffer Before
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control"
                    name="buffer_before"
                    onChange={handleChange("buffer_before")}
                  >
                    <option value="" selected disabled>--select--</option>
                    <option value="5" selected={typeFields.buffer_before === '5' ? 'selected' :''}>5 minutes</option>
                    <option value="10" selected={typeFields.buffer_before === '10' ? 'selected' :''}>10 minutes</option>
                    <option value="15" selected={typeFields.buffer_before === '15' ? 'selected' :''}>15 minutes</option>
                    <option value="20" selected={typeFields.buffer_before === '20' ? 'selected' :''}>20 minutes</option>
                    <option value="30" selected={typeFields.buffer_before === '30' ? 'selected' :''}>30 minutes</option>
                    <option value="45" selected={typeFields.buffer_before === '45' ? 'selected' :''}>45 minutes</option>
                    <option value="60" selected={typeFields.buffer_before === '60' ? 'selected' :''}>1 hour</option>
                  </Form.Select>
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Buffer After
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control"
                    name="buffer_after"
                    onChange={handleChange("buffer_after")}
                  >
                    <option value="" selected disabled>--select--</option>
                    <option value="5" selected={typeFields.buffer_after === '5' ? 'selected' :''}>5 minutes</option>
                    <option value="10" selected={typeFields.buffer_after === '10' ? 'selected' :''}>10 minutes</option>
                    <option value="15" selected={typeFields.buffer_after === '15' ? 'selected' :''}>15 minutes</option>
                    <option value="20" selected={typeFields.buffer_after === '20' ? 'selected' :''}>20 minutes</option>
                    <option value="30" selected={typeFields.buffer_after === '30' ? 'selected' :''}>30 minutes</option>
                    <option value="45" selected={typeFields.buffer_after === '45' ? 'selected' :''}>45 minutes</option>
                    <option value="60" selected={typeFields.buffer_after === '60' ? 'selected' :''}>1 hour</option>
                  </Form.Select>
                </Col>
                </>
                }
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                      Payment <p className="m-0 d-inline-block"> (optional)</p>
                    </label>
                  { stripeConnected ?
                    <>
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            className="position-absolute w-100 h-100"
                            // onChange={() => setBufferType(!bufferType)}
                            onChange={(e) => {
                              console.log("checked", e.target.checked, requirePayment)
                              setRequirePayment(e.target.checked)
                            }}
                            checked={requirePayment}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Require payment to book this appointment
                        </p>
                      </div>
                      { (requirePayment) &&
                        <div className="py-2">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <Form.Select
                                className="form-control border-right-0"
                                name="currency_code"
                                onChange={(e) => {
                                  const selectedCurrency = e.target.value;
                                  let currencySign = selectedCurrency?.substring(0, 1);
                                  let currencyCode = selectedCurrency?.substring(1);
                                  setTypeFields(prevState => ({
                                    ...prevState,
                                    currency_code: currencyCode,
                                    currency_sign: currencySign,
                                  }))
                                }}
                              >
                                <option value="$USD" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '$USD' ? 'selected' : ''}>$USD</option>
                                <option value="$CAD" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '$CAD' ? 'selected' : ''}>$CAD</option>
                                <option value="$AUD" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '$AUD' ? 'selected' : ''}>$AUD</option>
                                <option value="$NZD" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '$NZD' ? 'selected' : ''}>$NZD</option>
                                <option value="£GBP" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '£GBP' ? 'selected' : ''}>£GBP</option>
                                <option value="€EUR" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '€EUR' ? 'selected' : ''}>€EUR</option>
                                <option value="¥JPY" selected={`${typeFields.currency_sign}${typeFields.currency_code}` === '¥JPY' ? 'selected' : ''}>¥JPY</option>
                              </Form.Select>
                            </div>
                            <input
                              type="number"
                              name="price"
                              class="form-control"
                              placeholder="Amount"
                              onChange={handleChange("price")}
                              value={typeFields.price}
                            />
                          </div>
                          { typeFields?.price &&
                            <p>
                              A payment of {typeFields?.currency_sign}{typeFields?.price} ({typeFields?.currency_code}) will be required to book this appointment type.
                            </p>
                          }

                        </div>
                      }
                    </>
                    :
                    <div className="py-2 d-flex align-items-start gap-10">
                      <div className="imgWrp">
                        <StripeIcn />
                      </div>
                      <p className=" m-0 ">
                        Add prices and require payment to book an appointment by
                        linking your Stripe account. Check out the{" "}
                        <Link
                          to={HelpStripeLinkAccount}
                          target="_blank"
                          className="underline"
                        >
                          Stripe Integration Guide
                        </Link>{" "}
                        to see how.
                      </p>
                    </div>
                  }
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id=""
                    rows="3"
                    className="form-control"
                    onChange={handleChange("description")}
                    value={typeFields.description}
                    placeholder="Description of your Appointment Type. Who would sign-up for this appointment? What would be covered? etc."
                    required
                  ></textarea>
                  {validationErrors.description && (
                    <div className="text-danger">{validationErrors.description}</div>
                  )}
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Location
                  </label>
                  <ul className="list-unstyled ps-0 mb-0">
                    <li className="">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="radio"
                            name="location"
                            className="position-absolute w-100 h-100"
                            value="in_person"
                            onChange={handleChange("location")}
                            checked={typeFields.location === 'in_person' ? true:false}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">In-person</p>
                      </div>
                    </li>
                    <li className="">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="radio"
                            name="location"
                            value="by_phone"
                            className="position-absolute w-100 h-100"
                            onChange={handleChange("location")}
                            checked={typeFields.location ==='by_phone' ? true:false}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">By Phone</p>
                      </div>
                    </li>
                    <li className="">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="radio"
                            name="location"
                            value="video_zoom"
                            onChange={handleChange("location")}
                            className="position-absolute w-100 h-100"
                            checked={typeFields.location ==='video_zoom' ? true:false}
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">Video/Zoom</p>
                      </div>
                    </li>
                  </ul>
                </Col>
                {(typeFields.location === 'in_person') &&
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    In-person Instructions {" "}
                    <p className="d-inline-block m-0"> (optional)</p>
                  </label>
                  <textarea
                    name="in_person"
                    id=""
                    rows="3"
                    value={typeFields.in_person  || typeFields.location_notes}
                    onChange={handleChange("in_person")}
                    className="form-control"
                    placeholder="In-person specific instructions or notes"
                  ></textarea>
                </Col>
                }
                {(typeFields.location === 'by_phone') &&
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                  Phone Instructions{" "}
                    <p className="d-inline-block m-0"> (optional)</p>
                  </label>
                  <textarea
                    name="by_phone"
                    id=""
                    rows="3"
                    value={typeFields.by_phone  || typeFields.location_notes}
                    onChange={handleChange("by_phone")}
                    className="form-control"
                    placeholder="Phone specific instructions or notes"
                  ></textarea>
                </Col>
                }
                {typeFields.location === 'video_zoom'  &&
                <>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Video/Zoom Instructions {" "}
                    <p className="d-inline-block m-0"> (optional)</p>
                  </label>
                  <textarea
                    name="video_zoom"
                    id=""
                    rows="3"
                    onChange={handleChange("video_zoom")}
                    value={typeFields.video_zoom || typeFields.location_notes}
                    className="form-control"
                    placeholder="Video/Zoom specific instructions or notes"
                  ></textarea>
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Video/Zoom URL{" "}
                    <p className="d-inline-block m-0"> (optional)</p>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="video_url"
                    value={typeFields.video_url}
                    onChange={handleChange("video_url")}
                    placeholder="Permanent link to to your personal meeting room"
                  />
                  <p className="m-0 py-1">
                    Permanent link to to your personal meeting room. In Zoom
                    this is your Personal Meeting ID link
                    (https://us04web.zoom.us/j/*****?pwd=*****) ensure you have
                    the 'Waiting Room' option enabled.
                  </p>
                </Col>
                </>
                }
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Additional Notes{" "}
                    <p className="d-inline-block m-0"> (optional)</p>
                  </label>
                  <textarea
                    id=""
                    name="additonal_notes"
                    onChange={handleChange("additonal_notes")}
                    value={typeFields.additonal_notes} 
                    rows="3"
                    className="form-control"
                    placeholder="Additional appointment instructions or notes"
                  ></textarea>
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Availability{" "}
                    <p className="d-inline-block m-0"> (optional)</p>
                  </label>
                  <div className="py-2 d-flex align-items-start gap-10">
                    <div className="cstmInputCheckWrp position-relative ">
                      <input
                        type="checkbox"
                        name="c-ToDo"
                        className="position-absolute w-100 h-100"
                        onChange={(e) => {
                          setAvailability((prevAvailability) => !prevAvailability);
                          if(!e.target.checked) {
                            setAvailabileHours([]);
                          }
                        }}
                        checked={availability}
                      />
                      <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                        <span className="icn d-none">
                          <OTick />
                        </span>
                      </div>
                    </div>
                    <div className="content">
                      <p className=" m-0 ">
                      Only offer this appointment type on specific days/times
                      </p>
                      {availability &&
                      <>
                        <p className=" m-0 ">
                          Select your working hours where this appointment should
                          be available:
                        </p>
                        <ul className="list-unstyled ps-0 mb-0 py-2">
                        {workingHours.length> 0 && workingHours.map((hours, i) => {
                          let dayTiming = hours.timings;
                          dayTiming = JSON.parse(dayTiming);
                          if(dayTiming.length>0){
                            return(
                              <>
                              {dayTiming.length>0 && dayTiming.map((time, j) => {
                              const objectExists = availabileHours.findIndex((obj) => {
                                return (
                                  obj.day === hours.day &&
                                  obj.time.findIndex((timeObj) => (
                                    timeObj.start_time === time.start_time && timeObj.end_time === time.end_time
                                  )) !== -1
                                );
                              }) !== -1;
                              
                                return(
                                  <li className=" py-1" key={j}>
                                    <div className="d-flex align-items-start gap-10">
                                      <div className="cstmInputCheckWrp position-relative ">
                                        <input
                                          type="checkbox"
                                          name="available_working_hours"
                                          onChange={(e) => handleCheckboxChange_(e, time, hours)}
                                          checked={objectExists}
                                          className="position-absolute w-100 h-100"
                                        />
                                        <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                          <span className="icn d-none">
                                            <OTick />
                                          </span>
                                        </div>
                                      </div>
                                      <p className="m-0">{hours?.day.charAt(0).toUpperCase() + hours.day?.slice(1)} {convertTo12HourFormat(time.start_time)} - {convertTo12HourFormat(time.end_time)}</p>
                                    </div>
                                  </li>
                                )
                              })}
                              </>
                            )
                          }
                        })}
                        </ul>
                      </>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            type="submit"
            onClick={submitTypeData_}
            className="d-flex align-items-center justify-content-center commonBtn"
            disabled={sending ? true : false}
          >
            {sending ? 'Wait...'  : 'Add'}
          </Button>
          <Button
            onClick={handleAppointmentPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      </Form>
    </>
  );
};

const mapStateToPros = (state) => {
  let stripeConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "stripe");
  let stripeConnected = stripeConnection?.connection_response?.status || false;
  return{
    // accountSettingDetail : state.AccountSetting.accountSettingDetail_
    stripeConnected
  }
};

function mapDispatchToProps(dispatch) {
return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(AppointmentTypePop);
