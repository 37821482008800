import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  visitNumber: {
    flexDirection: "row",
    marginBottom: "15px",
    paddingLeft: "15px",
  },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  additionalInfo: {
    marginTop: "10px",
    backgroundColor: "#eee",
    padding: "10px",
  },
  tableContainer: {
    marginTop: "15px",
    paddingBottom: "15px",
    borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "9px",
    width: "6%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "10px",
    width: "6%",
    padding: "5px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});

export const MyDoc = ({ nutritionalRows, lifestyleRows, supplementRows, details, additionalDetails, client, date }) => (
  <Document>
    <Page orientation="landscape" style={styles.page}>

      <View style={styles.pageHeader}>
        <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
          Recommendation
        </Text>
        <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
          Nutri-Q
        </Text>
      </View>

      <View style={styles.clientName}>
        <Text>
          Client: {FULL_NAME(client.first_name, client.last_name)}
        </Text>
        <Text>
          {moment(date).format("MMM DD, YYYY")}
        </Text>
      </View>

      {details?.visit_number &&
        <View style={styles.visitNumber}>
          <Text>Visit #</Text>
          <Text style={{ borderBottom: "1px solid #333", padding: "0px 10px", marginLeft: "10px" }}>{details?.visit_number}</Text>
        </View>
      }
      
      {/* Nutritional recommendation details */}
      {nutritionalRows && nutritionalRows.length > 0 &&
        <View style={styles.tableContainer}>
          <NutriRecommTable items={nutritionalRows} />
          {additionalDetails && additionalDetails.nutritional &&
            <View style={styles.additionalInfo}>
              <Text style={{ textDecoration: "underline" }}>
                Additional Nutritional Recommendations:
              </Text>
              <Text>
                {additionalDetails?.nutritional}
              </Text>
            </View>
          }
        </View>
      }

      {/* Lifestyle recommendation details */}
      {lifestyleRows && lifestyleRows.length > 0 &&
        <View style={styles.tableContainer}>
          <LifestyleRecommTable items={lifestyleRows} />
          {additionalDetails && additionalDetails.lifestyle &&
            <View style={styles.additionalInfo}>
              <Text style={{ textDecoration: "underline" }}>
                Additional Lifestyle Recommendations:
              </Text>
              <Text>
                {additionalDetails?.lifestyle}
              </Text>
            </View>
          }
        </View>
      }

      {/* Supplement recommendation details */}
      {supplementRows && supplementRows.length > 0 &&
        <View style={styles.tableContainer}>
          <SupplementRecommTable items={supplementRows} />
          {additionalDetails && additionalDetails.supplement &&
            <View style={styles.additionalInfo}>
              <Text style={{ textDecoration: "underline" }}>
                Additional Supplement Recommendations:
              </Text>
              <Text>
                {additionalDetails?.supplement}
              </Text>
            </View>
          }
        </View>
      }

      {/* Client Goals */}
      {details?.client_goals &&
        <View style={styles.additionalInfo}>
          <Text style={{ textDecoration: "underline" }}>
            Client Goals:
          </Text>
          <Text>
            {details?.client_goals}
          </Text>
        </View>
      }

      {/* Disclaimer */}
      <View style={styles.disclaimer}>
        <Text style={{ width: "90%" }}>
          Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
          These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
        </Text>
        <Text>
          {moment().format("MMM DD, YYYY")}
        </Text>
      </View>
    </Page>
  </Document>
);

const DownloadRecommendationPDF = ({ nutritionalRows, lifestyleRows, supplementRows, details, additionalDetails, client, date }) => {
  
  const downloadPDFReport = async () => {
    const doc = 
      <MyDoc
        nutritionalRows={nutritionalRows}
        lifestyleRows={lifestyleRows}
        supplementRows={supplementRows}
        details={details}
        additionalDetails={additionalDetails}
        client={client}
        date={date}
      />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'Recommendation.pdf');
  }

  return (
    <Button
      className="d-flex align-items-center justify-content-center commonBtn btnSm"
      onClick={downloadPDFReport}
    >
      <span className="icn me-2">
        <DownloadIcn />
      </span>
      Download Report
    </Button>
  )
}

const NutriRecommTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row, { backgroundColor: (i%2 == 0) ? "#fff" : "#eee" }]} key={i}>
        <Text style={[tableStyles.cell, { width: "24%" }]}>{decode(row.recommendation)}</Text>
        <Text style={[tableStyles.cell, { width: "28%" }]}>{decode(row.benefit)}</Text>
        <Text style={tableStyles.cell}>{decode(row.per_day)}</Text>
        <Text style={tableStyles.cell}>{decode(row.upon_waking)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_breakfast)}</Text>
        <Text style={tableStyles.cell}>{decode(row.midmorning)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_lunch)}</Text>
        <Text style={tableStyles.cell}>{decode(row.midafternoon)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_dinner)}</Text>
        <Text style={tableStyles.cell}>{decode(row.before_bed)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "24%", fontFamily: "Helvetica-Bold", fontWeight: "heavy", fontSize: "11px" }]}>Nutritional Recommendation</Text>
        <Text style={[tableStyles.headerCell, { width: "28%" }]}>Benefit / Instructions / Notes</Text>
        <Text style={tableStyles.headerCell}>Per Day</Text>
        <Text style={tableStyles.headerCell}>Upon Waking</Text>
        <Text style={tableStyles.headerCell}>With Breakfast</Text>
        <Text style={tableStyles.headerCell}>Midmorning</Text>
        <Text style={tableStyles.headerCell}>With Lunch</Text>
        <Text style={tableStyles.headerCell}>Midafternoon</Text>
        <Text style={tableStyles.headerCell}>With Dinner</Text>
        <Text style={tableStyles.headerCell}>Before Bed</Text>
      </View>
      {rows}
    </View>
  )
};

const LifestyleRecommTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row, { backgroundColor: (i%2 == 0) ? "#fff" : "#eee" }]} key={i}>
        <Text style={[tableStyles.cell, { width: "24%" }]}>{decode(row.recommendation)}</Text>
        <Text style={[tableStyles.cell, { width: "28%" }]}>{decode(row.benefit)}</Text>
        <Text style={tableStyles.cell}>{decode(row.per_day)}</Text>
        <Text style={tableStyles.cell}>{decode(row.upon_waking)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_breakfast)}</Text>
        <Text style={tableStyles.cell}>{decode(row.midmorning)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_lunch)}</Text>
        <Text style={tableStyles.cell}>{decode(row.midafternoon)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_dinner)}</Text>
        <Text style={tableStyles.cell}>{decode(row.before_bed)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "24%", fontFamily: "Helvetica-Bold", fontWeight: "heavy", fontSize: "11px" }]}>Lifestyle Recommendation</Text>
        <Text style={[tableStyles.headerCell, { width: "28%" }]}>Benefit / Instructions / Notes</Text>
        <Text style={tableStyles.headerCell}>Per Day</Text>
        <Text style={tableStyles.headerCell}>Upon Waking</Text>
        <Text style={tableStyles.headerCell}>With Breakfast</Text>
        <Text style={tableStyles.headerCell}>Midmorning</Text>
        <Text style={tableStyles.headerCell}>With Lunch</Text>
        <Text style={tableStyles.headerCell}>Midafternoon</Text>
        <Text style={tableStyles.headerCell}>With Dinner</Text>
        <Text style={tableStyles.headerCell}>Before Bed</Text>
      </View>
      {rows}
    </View>
  )
};

const SupplementRecommTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row, { backgroundColor: (i%2 == 0) ? "#fff" : "#eee" }]} key={i}>
        <Text style={[tableStyles.cell, { width: "24%" }]}>{decode(row.recommendation)}</Text>
        <Text style={[tableStyles.cell, { width: "28%" }]}>{decode(row.benefit)}</Text>
        <Text style={tableStyles.cell}>{decode(row.per_day)}</Text>
        <Text style={tableStyles.cell}>{decode(row.upon_waking)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_breakfast)}</Text>
        <Text style={tableStyles.cell}>{decode(row.midmorning)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_lunch)}</Text>
        <Text style={tableStyles.cell}>{decode(row.midafternoon)}</Text>
        <Text style={tableStyles.cell}>{decode(row.with_dinner)}</Text>
        <Text style={tableStyles.cell}>{decode(row.before_bed)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "24%", fontFamily: "Helvetica-Bold", fontWeight: "heavy", fontSize: "11px" }]}>Supplement Recommendation</Text>
        <Text style={[tableStyles.headerCell, { width: "28%" }]}>Benefit / Instructions / Notes</Text>
        <Text style={tableStyles.headerCell}>Per Day</Text>
        <Text style={tableStyles.headerCell}>Upon Waking</Text>
        <Text style={tableStyles.headerCell}>With Breakfast</Text>
        <Text style={tableStyles.headerCell}>Midmorning</Text>
        <Text style={tableStyles.headerCell}>With Lunch</Text>
        <Text style={tableStyles.headerCell}>Midafternoon</Text>
        <Text style={tableStyles.headerCell}>With Dinner</Text>
        <Text style={tableStyles.headerCell}>Before Bed</Text>
      </View>
      {rows}
    </View>
  )
};

export default DownloadRecommendationPDF;