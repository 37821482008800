import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as OCopy } from "../../../../../../Assets/Images/icn/Copy.svg";

const TermsCondition = () => {
  return (
    <>
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">Terms and Conditions</h3>
      </div>
      <div className="TabBody py-3">
        <div className="py-2">
          <ul className="list-unstyled ps-0 mb-0">
            <li className="">
              <Link  to="/terms-conditions" className=" underline">
                View Terms and Conditions
              </Link>
            </li>
            <li className="">
              <Link to="/privacy-policy" className=" underline">
                View Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
