import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

// icon
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";
import { FULL_NAME } from "helpers/common";

const SendPackageInvitePop = ({
  showPopup,
  setShowPopup,
  handleSendPackage,
  packages,
  client
}) => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [dontSendEmail, setDontSendEmail] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);

  const closePopup = () => {
    setSelectedPackage(null);
    setDontSendEmail(false);
    setShowPopup(false);
  };

  const handleClickSendPackage = async () => {
    await handleSendPackage(selectedPackage, dontSendEmail, setSendingInvite);
    closePopup();
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Package Invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg="12" className="my-2">
                <p className="m-0 fw-sbold pb-1">
                  Select a package below to send {FULL_NAME(client.first_name, client.last_name)} an invite to
                  purchase the package
                </p>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedPackage(e.target.value)}
                >
                  <option value="">Please select a Package</option>
                  {packages && packages.length > 0 &&
                    packages.map(pack => {
                      return (
                        <option value={pack.id}>{pack.package_name}</option>
                      )
                    })
                  }
                </Form.Select>
                <p className="m-0 fw-sbold pt-1">
                If you wish to send the package invite yourself then check this box to generate the invite link only.
                </p>
              </Col>
              <Col lg="12" className="my-2">
                <div className="py-2 d-flex align-items-start gap-10">
                  <div className="cstmInputCheckWrp position-relative">
                    <input
                      type="checkbox"
                      className={`position-absolute w-100 h-100`}
                      name={`question`}
                      onChange={(e) => setDontSendEmail(e.target.checked)}
                      checked={dontSendEmail}
                    />
                    <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                      <span className="icn d-none">
                        <OTick />
                      </span>
                    </div>
                  </div>
                  <p className="m-0 fw-sbold text-dark">Do not email the package invite</p>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClickSendPackage}
            disabled={!selectedPackage || sendingInvite}
          >
            { dontSendEmail === true ? 
              "Generate Package Link" : "Send Package"
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendPackageInvitePop;
