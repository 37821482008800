import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { string, object, number } from 'yup';

//imported
// import validation from "./validation";
// import LoadingButton from "components/shared/LoadingButton";

const prioritiesSections = {
  client_current_concerns: "Current Client Concerns",
  client_current_priorities: "Current Priorities",
  client_short_term_goals: "Short Term Goals",
  client_medications: "Medications",
  client_food_allergies: "Food Allergies",
}

const Form_ = ({
  fields,
  submitting,
  handleSubmit,
  togglePrioritesPopup
}) => {
  const initialValues = Object.fromEntries(fields.map((field)=>[field.name, field.value]))

  const SchemaObject = Object.fromEntries(fields.map((field)=>[field.name, field.type]))

  const validationSchema = object().shape(SchemaObject);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off" className="client-priorities-form">
            <p className="my-2 fw-sbold text-dark">
              Edit your client priorities
            </p>
            <Row>
              {prioritiesSections && Object.keys(prioritiesSections).map(key => {
                return (
                  <Col lg="4" sm="3" className="my-2 my-lg-0 pt-4" key={key}>
                    <div className="px-3 py-2 LabelHead d-inline-block mb-3">
                      <p className="m-0 fw-sbold text-dark">
                        {prioritiesSections[key]}
                      </p>
                    </div>
                    {[1, 2, 3, 4, 5].map(index => {
                      let inputKey = `${key}_${index}`;
                      return (
                        <div className="py-2" key={inputKey}>
                          <input
                            type="text"
                            name={inputKey}
                            placeholder={`#${index}`}
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values[inputKey]}
                          />
                          {errors[inputKey] && touched[inputKey] && (
                            <p className="invalid-feedback m-0">{errors[inputKey]}</p>
                          )}
                        </div>
                      )
                    })}
                  </Col>
                )
              })}
              <Col lg="12">
                <div className="FormbtnWrpper justify-content-start d-flex gap-10 my-3">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="d-flex btn text-white align-items-center justify-content-center commonBtn"
                  >
                    { isSubmitting ? "Please wait..." : "Submit" }
                  </Button>
                  <Button
                    onClick={togglePrioritesPopup}
                    className="d-flex align-items-center justify-content-center commonBtn"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Form_;