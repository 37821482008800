import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CreateFormPop = ({ CreateFormPopup, setCreateFormPopup, duplicate, defaultForms, handleDuplicate }) => {
  const [duplicateForm, setDuplicateForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleCreateFormPop = () => {
    setDuplicateForm(false);
    setCreateFormPopup(!CreateFormPopup)
  };

  const handleClickDuplicate = async () => {

    const callback = () => {
      setDuplicateForm(false);
      handleCreateFormPop();
    }
    setButtonDisabled(true);
    await handleDuplicate(selectedForm, callback);
    setButtonDisabled(false);
  }

  return (
    <>
      <Modal
        show={CreateFormPopup}
        onHide={handleCreateFormPop}
        backdrop="static"
        keyboard={false}
        centered
        className="CreateFormPopup FormPop"
      >
        <Modal.Header className="text-center justify-content-center">
          <Modal.Title className="fw-bold">NEW FORM</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Form>
            {duplicateForm ? (
              <>
                <Row>
                  <Col lg="12" className="my-2">
                    <div className="commonContent">
                      <h4 className="m-0 py-2 fw-bold">
                        Which form do you want to duplicate?
                      </h4>
                      <ul className="list-unstyled p-0 mb-0 ">
                        {defaultForms && defaultForms.length > 0 &&
                          defaultForms.map(form => {
                            return (
                              <li className="">
                                <div className="py-2 d-flex align-items-start gap-10">
                                  <div className="cstmInputCheckWrp radio position-relative ">
                                    <input
                                      type="radio"
                                      name="c-ToDo"
                                      className="position-absolute w-100 h-100"
                                      onChange={(e) => setSelectedForm(form.id)}
                                      checked={selectedForm === form.id}
                                    />
                                    <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                                      <span className="icn d-none rounded-circle">
                                        {/* <OTick /> */}
                                      </span>
                                    </div>
                                  </div>
                                  <p className=" m-0 ">
                                    {form.form_name}
                                  </p>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="justify-content-center">
                  <Col lg="10" className="my-2">
                    <Link
                      to="/my-forms/add-new"
                      className="d-flex btn text-white align-items-center justify-content-center commonBtn w-100 my-2 btnSm"
                    >
                      Create a new blank form
                    </Link>
                    <Button
                      onClick={() => setDuplicateForm(!duplicateForm)}
                      className="d-flex align-items-center justify-content-center commonBtn w-100 my-2 btnSm"
                    >
                      Duplicate an existing Form
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end">
          <Button
            onClick={handleCreateFormPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          {duplicateForm ? (
            <>
              <Button
                onClick={handleClickDuplicate}
                className="d-flex align-items-center justify-content-center commonBtn"
                disabled={isButtonDisabled || !selectedForm}
              >
                Duplicate Form
              </Button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFormPop;
