import { decode } from "html-entities";
import {Text, StyleSheet, View } from '@react-pdf/renderer';
const RadioButtons = (props) => {
  console.log("props inside radioButton",props)

  let radioOptions = null;
  try {
    radioOptions = JSON.parse(props.question.other_column_data);
  } catch (error) {
    console.log("Error while parsing radio options", props.question.question);
  }

  return (
    <View className="list-unstyled ps-0 mb-0 py-2">
      {radioOptions &&
        radioOptions.length > 0 &&
        radioOptions.map((option, i) => {
          let optionValue = "";
          if (
            option !== null &&
            typeof option === "object" &&
            !Array.isArray(option) &&
            typeof option.answer !== "undefined"
          ) {
            optionValue = option.answer;
          } else {
            optionValue = option;
          }

          return (
            <View style={{flexDirection:"row", alignItems:"center"}}>
              <Text style={{border:"1px solid #000", width:10, height:10, borderRadius:"50%", marginRight:5, marginTop: 2}}>              
              </Text>
              <Text>{decode(optionValue)}</Text>
            </View>
          );
        })}
    </View>
  );
};

export default RadioButtons;