import { HelpStripePaymentMethod, myClientDetails } from "Routes/paths";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SBComparisionPopup = ({ handleLinkClick, showPopup, handleClose }) => {

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Symptom Burden Comparison Graph</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg="12" className="">
              <p>
                To create a Symptom Burden Comparison Graph Report, go to the{` `}
                <Link
                  onClick={handleLinkClick}
                >
                  {"Data Analysis > NAQ: Symptom Burden Comparison Graph"}
                </Link>{` `}
                tab, select the NAQs you'd like to compare then click "Download Report".
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SBComparisionPopup;
