import { TIME_FORMAT_12, TIME_FORMAT_24 } from "common/constants";
import React, {useState, useEffect} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";

const AddTimePop = ({ timeRowPop, setTimeRowPop, setCurrentDayValue, currentDayValue, submitWorkingHours, hoursFields, handleChangeHours, sending,setErrorTime, errorTime, setHoursFields}) => {
  const handleAddTime = () => {
    setTimeRowPop(!timeRowPop);
    setCurrentDayValue(null)
    setHoursFields({
      start_time: "09:00",
      end_time: "17:00"
    })
  }

  const handleSubmit = () => {
    if(!hoursFields.start_time || !hoursFields.end_time) {
      setErrorTime("Please select a valid time range");
      return;
    }

    submitWorkingHours();
  }


  return (
    <>
      <Modal
        show={timeRowPop}
        onHide={handleAddTime}
        backdrop="static"
        keyboard={false}
        centered
        className="timeRowPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">New Working Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <h4 className=" m-0">Add New Hours for {currentDayValue ? currentDayValue?.day.charAt(0).toUpperCase() + currentDayValue.day?.slice(1) : ''}</h4>
            <Form>
              <Row className="align-items-center my-2">
                <Col lg="3" className="my-2">
                  <div className="labelBox px-3 py-2 border bg-light rounded">
                    <h6 className="m-0 fw-bold">Start Time</h6>
                  </div>
                </Col>
                <Col lg="6" className="my-2">
                  {/* <input
                    type="time"
                    name="start_time"
                    className="form-control"
                    value={hoursFields.start_time}
                    onChange={handleChangeHours("start_time")}
                  /> */}
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment().set("hours", 9).set("minutes", 0)}
                    onChange={(value) => {
                      // console.log("start_time value", moment(value).format(TIME_FORMAT_24), value)
                      handleChangeHours("start_time", moment(value).format(TIME_FORMAT_24));
                    }}
                    format={TIME_FORMAT_12}
                    use12Hours
                    // inputReadOnly
                  />
                </Col>

              </Row>
              <Row className="align-items-center my-2">
                <Col lg="3" className="my-2">
                  <div className="labelBox px-3 py-2 border bg-light rounded">
                    <h6 className="m-0 fw-bold">End Time</h6>
                  </div>
                </Col>
                <Col lg="6" className="my-2">
                  {/* <input type="time" name="end_time" className="form-control"  value={hoursFields.end_time} onChange={handleChangeHours("end_time")} /> */}
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment().set("hours", 17).set("minutes", 0)}
                    onChange={(value) => {
                      // console.log("end_time value", moment(value).format(TIME_FORMAT_24), value)
                      handleChangeHours("end_time", moment(value).format(TIME_FORMAT_24));
                    }}
                    format={TIME_FORMAT_12}
                    use12Hours
                    // inputReadOnly
                  />
                </Col>
              </Row>
              {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}

            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={handleSubmit}
            className="d-flex align-items-center justify-content-center commonBtn"
            disabled={sending ? true: false}
          >
            {sending ? 'Wait...' : 'Add'}
          </Button>
          <Button
            onClick={handleAddTime}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTimePop;
