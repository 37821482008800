import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from 'yup';

// css
import "./RecommendationDataPopup.scss";
import { UC_FIRST } from "helpers/common";

const RecommendationDataPopup = ({ recommendationType, rows, rowIndex, showPopup, handleSubmit, closePopup }) => {
  let rowData = {};
  if(recommendationType && !isNaN(rowIndex)) {
    rowData = rows[recommendationType][rowIndex] || {};
  }

  const handleSaveData = (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      handleSubmit(rowIndex, values, recommendationType);
      closePopup();
    } catch(error) {
      console.log("Error saving data", error);
      setSubmitting(false);
    }
  }
  
  const initialValues = {
    recommendation: rowData.recommendation || "",
    benefit: rowData.benefit || "",
    per_day: rowData.per_day || "",
    upon_waking: rowData.upon_waking || "",
    with_breakfast: rowData.with_breakfast || "",
    midmorning: rowData.midmorning || "",
    with_lunch: rowData.with_lunch || "",
    midafternoon: rowData.midafternoon || "",
    with_dinner: rowData.with_dinner || "",
    before_bed: rowData.before_bed || "",
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop RecommendationDataPopup"
      >
        <Modal.Header className="border-0 pb-0 px-lg-5">
          <Modal.Title className="fw-sbold">
            Recommendation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-5 pt-0">
          <Form_ 
            handleSubmit={handleSaveData}
            closePopup={closePopup}
            rowData={rowData || {}}
            initialValues={initialValues}
            type={recommendationType}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const validation = Yup.object({
  selected_form: Yup.number().required("Please select a form")
});

const Form_ = ({
  handleSubmit,
  closePopup,
  rowData,
  initialValues,
  type,
}) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        // validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  {UC_FIRST(type)} Recommendation
                </label>
                <textarea
                  rows={4}
                  className={`form-control`}
                  name="recommendation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.recommendation}
                ></textarea>
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Benefit / Instructions / Notes
                </label>
                <textarea
                  rows={4}
                  className={`form-control`}
                  name="benefit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.benefit}
                ></textarea>
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Per Day
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="per_day"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.per_day}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Upon Waking
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="upon_waking"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.upon_waking}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  With Breakfast
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="with_breakfast"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.with_breakfast}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Midmorning
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="midmorning"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.midmorning}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  With Lunch
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="with_lunch"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.with_lunch}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Midafternoon
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="midafternoon"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.midafternoon}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  With Dinner
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="with_dinner"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.with_dinner}
                />
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Before Bed
                </label>
                <input
                    type="text"
                    className={`form-control`}
                    name="before_bed"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.before_bed}
                />
              </Col>
              <Col lg="12" className="FormbtnWrpper justify-content-start border-0 d-flex gap-10 mt-2">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="d-flex btn text-white align-items-center justify-content-center commonBtn"
                >
                  { isSubmitting ? "Please wait..." : "Submit"}
                </Button>
                <Button
                  onClick={closePopup}
                  className="d-flex align-items-center justify-content-center commonBtn"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RecommendationDataPopup;
