import { NAQV1_ID } from 'common/constants';
import { saveAs as fileSaverSaveAs } from 'file-saver';
import { isObject } from "lodash";

const moment = require('moment');
const momentTZ = require('moment-timezone');
const defaultTimezone = moment.tz.guess();

export function DATE_FORMAT(inputDate) {
  const formattedDate = moment(inputDate).format('MMM D, YYYY');
  return formattedDate
}
export function DATE_FORMAT_TIME(inputDate) {
  const formattedDate = moment(inputDate).format('MMM D, YYYY h:mm A');
  return formattedDate
}
export function FORMAT_TEXT(text) {
  if (!text) {
    return text;
  }
  const text_ = text.length > 20 ? text?.substring(0, 20) + '...' : text
  return text_
}


export function IS_BASE64(input) {
  try {
    const base64Data = input.split(',')[1];
    return btoa(atob(base64Data)) === base64Data;
  } catch (e) {
    return false;
  }
}

export function UC_FIRST(string) {
  if (!string) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function bytesToMegabytes(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

export function TIMESTAMP_FORMAT_TODATE(timestamp) {
  const date = moment(timestamp);
  const formattedDate = date.format('MMMM DD, YYYY');
  return formattedDate
}

export function FULL_NAME(firstName, lastName) {
  if (!firstName && !lastName) {
    return "";
  }
  return UC_FIRST(`${firstName}${lastName && " " + lastName}`);
}

export function convertTo12HourFormat(time24) {
  const time12 = moment(time24, 'HH:mm').format('hh:mm A');
  return time12;
}
export function utcTimestamp_(utcTimestamp) {
  const momentUtc = moment.utc(utcTimestamp);
  const formattedTime = momentUtc.format('hh:mm A');
  return formattedTime;
}
export function bookingdate(dateString) {
  // const parsedDate = moment(dateString);
  // const formattedDate = parsedDate.format('YYYY-MM-DD HH:mm');
  // return formattedDate;
  const selectedDate = moment(dateString, 'YYYY-MM-DD');

  // Get the current time
  const currentTime = moment().format('hh:mm');

  // Combine the selected date and current time
  const dateTime = selectedDate.format('YYYY-MM-DD') + ' ' + currentTime;
  return dateTime;
}
export function bookingdatetime(dateString) {
  const parsedDate = moment(dateString);
  const formattedDate = parsedDate.format('YYYY-MM-DD');
  return formattedDate;
}

export function convertTimezone(dateString, timezone = defaultTimezone, format = "YYYY-MM-DD HH:mm") {
  const convertedDate = momentTZ.tz(dateString, timezone).format(format);
  return convertedDate
}


export function dateFormatData_(inputDate) {
  const time12 = moment(inputDate, 'YYYY-MM-DD HH:mm').format('dddd MMMM D, YYYY [at] h:mm a');
  return time12;
}

export function currenDay_() {
  const currentDayLowercase = moment().format('dddd').toLowerCase();
  return currentDayLowercase;
}
export function currenDatee_() {
  var currentDate = moment();
  var formattedDate = currentDate.format("YYYY-MM-DD");
  return formattedDate;
}

/**
 * Being used on appointment scheduler
 */
export function formatAppointmentDate(date) {
  return moment(date).format('dddd MMMM DD, YYYY');
}

/**
 * Being used for showing appointment date and times with timezone conversion
 */
export function formatAppointmentDateTimezone(date, timezone, format = 'dddd MMMM DD, YYYY') {
  return momentTZ(date).tz(timezone).format(format);
}

export function currentDayFromMonth(date, timezone = defaultTimezone) {

  // moment.tz.setDefault(timezone);

  const formattedDate = momentTZ(date).tz(timezone).format('dddd MMMM DD, YYYY');

  return formattedDate
}

export function timeZoneFormat_(selectedDate) {
  return momentTZ(selectedDate).tz('Asia/Kolkata').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
}

export function currentDayFromMonth_(selectedDate, timeZoneValue = defaultTimezone) {
  let selectedDate_ = momentTZ(selectedDate).tz(timeZoneValue).format('dddd MMMM DD, YYYY')
  // let selectedDate_ = moment.parseZone(selectedDate).format('dddd MMMM DD, YYYY');
  // {selectedDate ? moment(selectedDate).format('dddd MMMM DD, YYYY') : 'None'}


  // Specify the day you want
  // const dayOfWeek = momentTZ().format('dddd');


  // // Get the current date
  // const currentDate = momentTZ();

  // const dateForDayOfWeek = currentDate.isoWeekday(dayOfWeek);

  // // Format the date with timezone abbreviation
  // const formattedDate = dateForDayOfWeek.format('dddd MMMM DD, YYYY');
  return selectedDate_

}

export function formatDate(date, format) {
  return momentTZ(date).format(format);
}

export function isPastDateTime(dateTime) {
  return !moment().isBefore(dateTime);
}

export function dateTimeRange(startDateTime, endDateTime) {
  let startDate = moment(startDateTime, "DD-MM-YYYY");
  let endDate = moment(endDateTime, "DD-MM-YYYY");
  let output = `${moment(startDateTime).format("ddd MMM DD hh:mma")} to `;
  if (startDate.isSame(endDate)) {
    output += `${moment(endDateTime).format("hh:mma")} `;
  } else {
    output = `${moment(endDateTime).format("ddd MMM DD hh:mma")} `;
  }
  output += `${moment(endDateTime).tz(moment.tz.guess()).format("z")}`;
  return output;
}

export function formatText(text) {
  text = text.replaceAll("_", "-");
  return UC_FIRST(text);
}

export function convertBytes(bytes, sizeToConvert) {
  let output = 0;
  switch (sizeToConvert) {
    case "kb":
      output = bytes / 1024;
      break;
    case "kb":
      output = bytes / (1024 * 1024);
      break;
  }
  return output.toFixed(2);
}

export function truncateText(input, length = 40) {
  return input.length > length ? `${input.substring(0, length)}...` : input;
}

export function isEmpty(value) {
  return (
    !value ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    value == null ||
    value == "NULL"
  )
}

export function uniqId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function isDateValid(date, settings, debug = false) {
  let dateToday = moment().startOf('day');
  let dateToCompare = moment(date, "YYYY-MM-DD").startOf('day');
  if (debug) {
    console.log("Selected date", date);
  }

  /**
   * Validate past dates
   */
  if (!dateToCompare.isSameOrAfter(dateToday)) {
    return false;
  }

  /**
   * Validate same day appointment booking according to scheduler setting
   */
  let sameDayBookingSetting = settings["scheduler_same_day_appointment"];
  if (debug) {
    console.log("sameDayBookingSetting", sameDayBookingSetting);
  }
  switch (sameDayBookingSetting) {
    case "Don't let clients book same day appointments":
      if (dateToCompare.isSame(dateToday)) {
        return false;
      }
      break;

    case "Don't let clients book same or following day appointments":
      if (dateToCompare.isSame(dateToday) || dateToCompare.isSame(moment().add(1, 'days').startOf('day'))) {
        return false;
      }
      break;

    case "Don't let clients book appointments within a week":
      if (dateToCompare.isBefore(moment().add(1, 'weeks'))) {
        return false;
      }
      break;
  }

  /**
   * Validate max scheduling date setting
   */
  let maxSchedDateSetting = settings["scheduler_future_date_booking_allowed"];
  if (!maxSchedDateSetting) {
    return true;
  }
  const allowedMonths = (function () {
    const matches = maxSchedDateSetting.match(/\d+/);
    return matches ? parseInt(matches[0]) : 0;
  })();
  const monthDifference = dateToCompare.diff(dateToday, 'months');
  if (!(allowedMonths && monthDifference >= 0 && monthDifference < allowedMonths)) {
    return false;
  }

  /**
   * Validate blocked date settings
   */
  let calendarBlocks = settings["scheduler_blocked_dates"];
  calendarBlocks = calendarBlocks && JSON.parse(calendarBlocks);
  if (calendarBlocks && calendarBlocks.length > 0) {
    for (let i = 0; i < calendarBlocks.length; i++) {
      let block = calendarBlocks[i];
      let blockTimezone = block.timezone;
      if (!block.full_day_block) {
        continue;
      }

      let startDate = moment(block.from_date, "YYYY-MM-DD");
      let endDate = moment(block.to_date, "YYYY-MM-DD");
      let compareDate = moment(momentTZ(date).tz(blockTimezone, true).format("YYYY-MM-DD"));
      if (debug) {
        console.log("dates", startDate, endDate, compareDate);
      }
      if (compareDate.isBetween(startDate, endDate) || compareDate.isSame(startDate) || compareDate.isSame(endDate)) {
        return false;
      }
    }
  }

  return true;
}

export const isClientSettingAllowed = (clientType, setting) => {
  const settings = [
    "client_show_meal_summary",
    "client_show_naq_burden_graph",
    "client_anytime_logging",
    "category",
  ];


}

export function parseTimeString(time) {
  return moment(time, ['h:mm a', 'H:mm']);
}

export function getStartFinishSteps(formId) {
  
  let output = {
    startStep: false,
    finishStep: false,
    startContent: null,
    finishContent: null,
  }

  switch (formId) {
    case NAQV1_ID:
      output.startStep = true;
      output.finishStep = true;
      output.startContent = `
        <p>
          You can enter historical NAQ data for your client here so that you can view NAQ comparisons on the symptom burden chart.
          Questions follow the standard NAQ order and pages are grouped by section for easier entry.
          We'll save each page as you go along so feel free to take your time or take a break in the middle.
        </p>
      `;
      break;
    
  }

  // console.log(formId, output)
  return output;
}

export const validatePageConditions = (page, questionAnswers) => {
  try {
    let copyQuestionAnswers = [...questionAnswers];
    let showPage = false;

    // if the page does not have any conditional data, no validation is required
    if(!page.page_depend_q_id || !page.page_depend_q_ans || !page.page_depend_q_ans?.length) {
      showPage = true;
    } else {
      let dependQuestionAns = JSON.parse(page.page_depend_q_ans); // the answer upon which the page depends
      let questionIndex = copyQuestionAnswers.findIndex(q => ((q.id == page.page_depend_q_id) && q.visible)); // find the question upon which the page depends
      let foundQuestion = copyQuestionAnswers[questionIndex];
      let dependSourceAnswer = null;
      if(questionIndex !== -1 && foundQuestion) {
        dependSourceAnswer = foundQuestion.answer;
        // if answer is singular and not an array
        if(typeof dependSourceAnswer == "string" && !isEmpty(dependSourceAnswer)) {
          if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
            showPage = true;
          }
        // if answer is an array, check if any of the given answers match the condition
        } else if(Array.isArray(dependSourceAnswer)) {
          let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
          if(findMatch) {
            showPage = true;
          }
        } else if(isEmpty(dependSourceAnswer)) {
          showPage = true;
        }
      }
    }
    if(!showPage) {
      // let copyPages = [...pages];
      // let currentPageIndex = copyPages.findIndex(p => p.form_page_id === page.form_page_id );
      // copyPages[currentPageIndex].visible = showPage;
      // setPages(copyPages);
    }
    return showPage;
  } catch(err) {
    console.log("Error validating page conditions", err);
    return true;
  }
}

export const validateQuestionConditions = (question, questionAnswers) => {
  if(!question) {
    return false;
  }
  let showQuestion = false;
  if(!question.page_depend_q_id || !question.page_depend_q_ans || !question.page_depend_q_ans?.length) {
    showQuestion = true;
  } else {
    let dependQuestionAns = JSON.parse(question.page_depend_q_ans);
    let questionIndex = questionAnswers.findIndex(q => ((q.id == question.page_depend_q_id) && q.visible));
    let foundQuestion = questionAnswers[questionIndex];
    let dependSourceAnswer = null;
    if(questionIndex !== -1 && foundQuestion) {
      dependSourceAnswer = foundQuestion.answer;
      // if answer is singular and not an array
      if(typeof dependSourceAnswer == "string") {
        if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
          showQuestion = true;
        }
      // if answer is an array, check if any of the given answers match the condition
      } else if(Array.isArray(dependSourceAnswer)) {
        let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
        if(findMatch) {
          showQuestion = true;
        }
      }
    }
  }
  return showQuestion;
}

export const hasActivePlan = (user) => {
  if (user?.is_plan_active === 'no' && user?.is_free_account === 'no' && !["paused"].includes(user?.plan_status)) {
    return false;
  } else if (user?.is_free_account === 'yes' && user?.is_plan_active === 'no') {
    let freeAccountExpiryDate = user?.free_account_expiry;
    if (freeAccountExpiryDate) {
      let dateToday = moment().startOf('day');
      let dateToCompare = moment(freeAccountExpiryDate, "YYYY-MM-DD").startOf('day');
      if (dateToCompare.isBefore(dateToday)) {
        // free account has expired, redirect to subscription page
        return false;
      }
    }
  }

  return true;
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function utcToLocal(date, format = "") {
  return moment.utc(date).local().format(format);
}

export function utcFormat(date, format = "dddd MMMM DD, YYYY") {
  return moment.utc(date).format("dddd MMMM DD, YYYY")
}

export function safelyParseJSON(json, defaultValue = null) {
  if(!json) {
    return json
  }
  let parsed = defaultValue;

  try {
    if(isObject(json)) {
      return json;
    }
    parsed = JSON.parse(json);
  } catch(err) {
    console.error("Unable to parse JSON", err);
  }

  return parsed;
}

export function secondsToMinutesAndSeconds(inputSeconds) {
  var minutes = Math.floor(inputSeconds / 60);
  var seconds = inputSeconds % 60;
  return {
    minutes,
    seconds
  };
}

export function transformYupErrors(errors) {
  let validationErrors = {};
  if(Array.isArray(errors.inner)) {
    errors.inner.forEach((error) => {
      if (error.path !== undefined) {
        validationErrors[error.path] = error.errors[0];
      }
    });
  } else if(errors.path) {
    validationErrors[errors.path] = errors.errors[0];
  }
  
  return validationErrors;
}

export function parseTimeIn24Hour(timeString) {
  const timeComponents = timeString.split(' ');
  const time = timeComponents[0];
  const ampm = timeComponents[1];

  const [hours, minutes] = time.split(':').map(Number);

  let adjustedHours = hours;
  if (ampm.toLowerCase() === 'pm' && hours < 12) {
      adjustedHours += 12;
  } else if (ampm.toLowerCase() === 'am' && hours === 12) {
      adjustedHours = 0;
  }

  return {
      hours: adjustedHours,
      minutes
  };
}


export function getAppointmentDateTime(date, timeString, timezone) {
  let parsedTimeObj = parseTimeIn24Hour(timeString);
  let dateTime = momentTZ(date)
        .set("hours", parsedTimeObj.hours)
        .set("minutes", parsedTimeObj.minutes)
        .tz(timezone, true)
        .toDate();

  return dateTime;
}

// Function to convert local date to UTC
export function convertLocalDateToUTC(localDateString, outputFormat = "YYYY-MM-DDTHH:mm:ss[Z]", inputFormat = "YYYY-MM-DD") {
  // Get current local time
  const currentLocalTime = momentTZ();

  // Combine current local date with the provided date string
  const combinedDateTime = momentTZ(localDateString, inputFormat).set({
      hour: currentLocalTime.hour(),
      minute: currentLocalTime.minute(),
      second: currentLocalTime.second(),
      millisecond: currentLocalTime.millisecond()
  });

  const utcDate = combinedDateTime.utc();

  return utcDate.format(outputFormat);
}

export function showDateInUtc(date, format = "dddd, MMM DD YYYY") {
  return moment.utc(date).format(format);
}

export function saveAs(blob, fileName = "file") {
  // check if the function was called inside a React Native Webview
  if(window.ReactNativeWebView) {
    // Convert blob to base64
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64data = reader.result.split(',')[1];
      let message = JSON.stringify({
        fileName,
        data: base64data
      });
      // Send base64 data to React Native
      window.ReactNativeWebView.postMessage(message);
    }
    reader.readAsDataURL(blob);
  } else {
    fileSaverSaveAs(blob, fileName);
  }
}