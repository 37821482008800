import { decode } from "html-entities";
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import moment from "moment";

const Disclaimer = ({
  question,
  className,
  onChange,
  handleBlur,
  value,
  setFieldValue,
  errors,
}) => {
  const [inputFields, setInputFields] = useState({
    agreed: "",
    full_name: "",
    date: moment().format("YYYY-MM-DD"),
  })

  useEffect(() => {
    try {
      let fieldValue = value;
      if(fieldValue && typeof fieldValue !== "object") {
        fieldValue = JSON.parse(value);
      }

      if(fieldValue && typeof fieldValue == "object") {
        setInputFields({
          agreed: fieldValue.agreed || "",
          full_name: fieldValue.full_name || "",
          date: fieldValue.date || "",
        });
      }
    } catch {
      console.log("Error parsing values");
    }
  }, [value]);

  let fields = {};
  try {
    let parsedData = JSON.parse(question.other_column_data);
    if(parsedData) {
      fields = parsedData;
    }
  } catch (error) {
    console.log("Error while parsing disclaimer fields", question.question);
  }

  const handleChange = (fieldName, value) => {
    let fieldValue = {...inputFields};
    if(fieldName) {
      fieldValue[fieldName] = value;
      setInputFields(fieldValue);
      console.log("fieldValue", fieldValue)
      setFieldValue(`question-${question.id}`, fieldValue);
    }
  }

  return (
    <>
      <p className="my-2">{parse(fields.disclaimer_text)}</p>
      <div className="disclaimer-field">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp position-relative">
            <input
              type="checkbox"
              className={`position-absolute w-100 h-100 ${className}`}
              name="agreed"
              onBlur={handleBlur}
              value="yes"
              onChange={(e) => {
                handleChange("agreed", e.target.checked ? "yes" : "")
              }}
              checked={inputFields.agreed == "yes"}
            />
            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
              <span className="icn d-none">
                <OTick />
              </span>
            </div>
          </div>
          <p className="m-0 ">{decode(fields.checkbox_text)}</p>
        </div>
        {errors[`question-${question.id}`] && errors[`question-${question.id}`].agreed &&
          <p className="invalid-feedback">{errors[`question-${question.id}`].agreed}</p>
        }
      </div>
      {fields && fields.require_name_date == "yes" &&
        <div className="py-2">
          <p className="m-0">
            Please type in your full name and today's date to electronically confirm your agreement
          </p>
          <input
            name="full_name"
            placeholder="Your Full Name"
            className={`form-control my-2 ${errors[`question-${question.id}`]?.full_name && "is-invalid"}`}
            onChange={(e) => {
              handleChange("full_name", e.target.value)
            }}
            value={inputFields.full_name}
            type="text"
          />
          {errors[`question-${question.id}`] && errors[`question-${question.id}`].full_name &&
            <p className="invalid-feedback">{errors[`question-${question.id}`].full_name}</p>
          }
          <input
            name="date"
            placeholder="Today's Date"
            className={`form-control my-2 ${errors[`question-${question.id}`]?.date && "is-invalid"}`}
            onChange={(e) => {
              handleChange("date", e.target.value)
            }}
            value={inputFields.date}
            type="date"
            min={inputFields.date}
            max={inputFields.date}
            // disabled={true}
          />
          {errors[`question-${question.id}`] && errors[`question-${question.id}`].date &&
            <p className="invalid-feedback">{errors[`question-${question.id}`].date}</p>
          }
        </div>
      }

    </>
  );
};

export default Disclaimer;