import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RecommendationTempPop from "./Modal/RecommendationTempPop";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getRecommendationTemplateList } from "store/services/clientService";
import * as actions from "store/actions";
import moment from "moment";

const MyTemplate = ({ dispatch, recommendationTemplates }) => {
  const [recommTemplatePopup, setRecommTemplatePopup] = useState({
    show: false,
    template: null,
  });

  useEffect(() => {
    getRecommendationTemplates();
  }, []);

  const openRecommTempPopup = (template) => {
    setRecommTemplatePopup({
      show: true,
      template: template
    });
  };

  const closeRecommPopup = () => {
    setRecommTemplatePopup({
      show: false,
      template: null
    });
  }

  const getRecommendationTemplates = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getRecommendationTemplateList());
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error fetching recommendation templates", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }
  return (
    <>
      <RecommendationTempPop
        showPopup={recommTemplatePopup.show}
        closePopup={closeRecommPopup}
        template={recommTemplatePopup.template}
        getTemplates={getRecommendationTemplates}
      />

      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">My Templates</h3>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Recommendation Templates</h6>
            {recommendationTemplates && recommendationTemplates.length > 0 ?
              <>
                <p>
                  Click on a template to update the name or delete it.
                  To create a new template based off of an existing client's recommendation,
                  open that recommendation and then use the 'Duplicate' button.
                </p>
                {recommendationTemplates.map(template => {
                  return (
                    <ul className="ps-3 mb-0 py-2">
                      <li className="m-0">
                        <p className="m-0">
                          <Button
                            onClick={() => {
                              openRecommTempPopup(template)
                            }}
                            className="border-0 p-0 themeLink me-2 fw-sbold "
                            variant="transparent"
                          >
                            {template.template_name}
                          </Button>
                          created on {moment(template.created_at).format("MMM DD, YYYY H:mm A")}
                        </p>
                      </li>
                    </ul>
                  )
                })}
              </>
              :
              <p className="m-0 text-muted py-1">
                You don't have any Recommendation Templates yet. Create a template
                based off of an existing client's recommendation using the
                'Duplicate' button. Then you can easily use that same
                recommendation for other clients in the future.
              </p>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    recommendationTemplates: state.Client.recommendationTemplates,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyTemplate);