import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Fullscript } from "@fullscript/fullscript-js";
import { FULL_NAME, UC_FIRST } from "helpers/common";
import * as actions from "store/actions";

// icn
import { ReactComponent as FSIcn } from "Assets/Images/icn/fullScript.svg";
import { getIntegrationStatus } from "store/services/accountSettingService";
import { findFullscriptPatient, fetchFullscriptPatientList, getFullscriptSessionGrant, unlinkClient, linkClient } from "store/services/fullscriptService";
import UnlinkClientConfirmPopup from "./Components/Modals/UnlinkClientConfirmPopup";
import CloseFullscriptWidgetPopup from "./Components/Modals/CloseFullscriptWidgetPopup";

const FullscriptWidget = forwardRef(function FullScriptWDGT({
  dispatch,
  client,
  fullscriptConnection,
  onCreateRecommendation,
  fullscriptCountry
}, ref) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullscriptInitialized, setIsFullscriptInitialized] = useState(false);
  const [showFullscriptView, setShowFullscriptView] = useState(false);
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);

  // client linking
  const [fullscriptClientDetails, setFullscriptClientDetails] = useState(null);
  const [fullscriptPatientsList, setFullscriptPatientsList] = useState(null);
  const [showPatientsList, setShowPatientsList] = useState(false);

  // popups
  const [showUnlinkClientPopup, setShowUnlinkClientPopup] = useState(false);

  const { clientId } = useParams();
  const treatmentPlanFeature = useRef();

  useEffect(() => {
    async function init() {
      try {
        if (!client || !client.id) {
          throw "invalid client id";
        }
        await Promise.all([
          getThirdPartyIntegrations(),
          findFullscriptPatientDetails(),
        ]);
        setIsLoaded(true);
      } catch (err) {
        console.log("Error occurred in init", err);
      }
    }

    if (!isLoaded) {
      init();
    }
  }, [client.id]);

  // useEffect(() => {
  //   try {
  //     if(isLoaded && fullscriptConnection) {
  //       initFullscriptWidget();
  //     }
  //   } catch(err) {
  //     console.log("Error mounting fullscript widget", err);
  //   }
  // }, [isLoaded, fullscriptConnection])

  useImperativeHandle(ref, () => {
    return {
      mountWidget() {
        mountFullscriptWidget();
      }
    }

  });

  const findFullscriptPatientDetails = async () => {
    try {
      let data = {
        clientId
      }
      dispatch(actions.persist_store({ loader: true }));
      let res = await findFullscriptPatient(data);
      if (res.success === true) {
        setFullscriptClientDetails(res.body);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log("Failed to fetch fullscript client details", err);
    }
  }

  const getSessionGrant = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getFullscriptSessionGrant();
      dispatch(actions.persist_store({ loader: false }));
      if (response.success !== true) {
        throw new Error("Response was not ok");
      }
      return response.body.secret_token;
      // return "ge4FBUi8aPXV7RjDkziCvn5QXdrc9y7S5p5WRk8pzbeRe3IM";
    } catch (err) {
      console.log("Error getting session grant");
      dispatch(actions.persist_store({ loader: false }));
      throw err;
    }
  }

  const getThirdPartyIntegrations = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getIntegrationStatus());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const mountFullscriptWidget = async () => {
    try {
      if (isFullscriptInitialized && treatmentPlanFeature.current) {
        treatmentPlanFeature.current.unmount();
      }

      if (isLoaded && fullscriptConnection) {
        await initFullscriptWidget();
        setShowFullscriptView(true);
        // treatmentPlanFeature.current.mount("treatment-plan-container");
      }
    } catch (err) {
      console.log("Error mounting fullscript widget", err);
      setShowFullscriptView(false);
    }
  }

  const initFullscriptWidget = async () => {
    try {
      var fullscriptClient = Fullscript({
        publicKey: fullscriptCountry == "us" ? (
          process.env.REACT_APP_FULLSCRIPT_US_PUB_KEY
        ) : (fullscriptCountry == "ca") ? (
          process.env.REACT_APP_FULLSCRIPT_CA_PUB_KEY
        ) : (
          ""
        ),
        env: process.env.REACT_APP_FULLSCRIPT_ENV === "DEV" ? `${fullscriptCountry}-snd` : `${fullscriptCountry}`,
      });

      let sessionToken = await getSessionGrant();

      let patientDetails = {
        id: fullscriptClientDetails && fullscriptClientDetails.id,
        firstName: client.first_name,
        lastName: client.last_name,
        email: client.email,
        dateOfBirth: client.birth_date,
      }

      treatmentPlanFeature.current = fullscriptClient.create("treatmentPlan", {
        secretToken: sessionToken,
        patient: patientDetails,
      });

      treatmentPlanFeature.current.mount("treatment-plan-container");

      treatmentPlanFeature.current.on("patient.selected", handlePatientSelected);
      treatmentPlanFeature.current.on("treatmentPlan.activated", onCreateRecommendation);

      setIsFullscriptInitialized(true);
      console.log("fullscript Initialized");
    } catch (err) {
      console.log("Error loading fullscript widget", err);
      setIsFullscriptInitialized(false);
    }
  }

  const openUnlinkClientModal = () => {
    setShowUnlinkClientPopup(true);
  }

  const unlinkClientFromFullscript = async (setIsUnlinking) => {
    try {
      let data = {
        clientId: client.id
      }
      setIsUnlinking(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await unlinkClient(data);
      setFullscriptClientDetails(null);
      dispatch(actions.persist_store({ loader: false }));
      setIsUnlinking(false);
      setShowUnlinkClientPopup(false);
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      setIsUnlinking(false);
      console.log(err);
    }
  }

  const handlePatientSelected = (event) => {
    let fullscriptPatientId = event?.data?.patient?.id;
    linkClientToFullscript(fullscriptPatientId);
  }

  // const showFullscriptPatientList = () => {
  //   try {

  //   } catch {

  //   }
  // }

  const showFullscriptPatientsList = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await fetchFullscriptPatientList();
      if (res.success === true) {
        setFullscriptPatientsList(res.body);
        setShowPatientsList(true);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const linkClientToFullscript = async (fullscriptPatientId) => {
    try {
      let data = {
        clientId,
        values: {
          fullscriptPatientId
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      let res = await linkClient(data);
      if (res.success === true) {
        setFullscriptClientDetails(res.body);
        setShowPatientsList(false);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const openConfirmClosePopup = () => {
    setShowConfirmClosePopup(true);
  }

  const closeFullscriptWidget = () => {
    if (treatmentPlanFeature && treatmentPlanFeature.current) {
      setShowFullscriptView(false);
      treatmentPlanFeature.current.unmount();
      treatmentPlanFeature.current.off("patient.selected", handlePatientSelected);
      treatmentPlanFeature.current.off("treatmentPlan.activated", onCreateRecommendation);
    }
    setShowConfirmClosePopup(false);
  }

  return (
    <div>
      <UnlinkClientConfirmPopup
        showPopup={showUnlinkClientPopup}
        setShowPopup={setShowUnlinkClientPopup}
        handleUnlink={unlinkClientFromFullscript}
        clientDetails={client}
      />
      <CloseFullscriptWidgetPopup
        showPopup={showConfirmClosePopup}
        setShowPopup={setShowConfirmClosePopup}
        closeWidget={closeFullscriptWidget}
      />
      {isLoaded ?
        <>
          {!showFullscriptView ?
            <>
              <h6 className="m-0">
                <span className="icn me-2">
                  <FSIcn />
                </span>
                Fullscript
              </h6>
              {!fullscriptClientDetails &&
                <p className="m-0 py-1 text-dark">
                  We didn't find an exact match for {FULL_NAME(client.first_name, client.last_name)} in your Fullscript
                  clients.{" "}
                  <Link
                    className="underline"
                    onClick={showFullscriptPatientsList}
                  >
                    Click here to link {FULL_NAME(client.first_name, client.last_name)} to an existing Fullscript client.
                  </Link>
                  {` `}Or if {UC_FIRST(client.first_name)} is a new Fullscript client you can start a new{` `}
                  <Link className="underline" onClick={mountFullscriptWidget}>
                    Recommendation to create a Fullscript client record for them.
                  </Link>
                </p>
              }
              {fullscriptClientDetails &&
                <p className="m-0 py-1 text-dark">
                  <span className="fw-bold">{client.first_name}</span>{` `}
                  is linked to your Fullscript client{` `}
                  <span className="fw-bold">{FULL_NAME(fullscriptClientDetails.first_name, fullscriptClientDetails.last_name)} ({fullscriptClientDetails.email})</span>{` `}
                  <Link className="underline" onClick={openUnlinkClientModal}>
                    Wrong Fullscript Client?
                  </Link>
                </p>
              }
              {showPatientsList &&
                <>
                  <p className="m-0 py-1 text-dark">
                    Which Fullscript client record should {FULL_NAME(client.first_name, client.last_name)} be linked with?
                  </p>
                  <div className="p-3 py-2 bg-light">
                    {fullscriptPatientsList && fullscriptPatientsList.length > 0 ?
                      fullscriptPatientsList.map(patient => {
                        return (
                          <div className="py-1">
                            <Link
                              className="underline"
                              onClick={() => {
                                linkClientToFullscript(patient.id);
                              }}
                            >
                              {FULL_NAME(patient.first_name, patient.last_name)}{` `}
                              ({patient.email})
                            </Link>
                          </div>
                        )
                      })
                      :
                      <p className="m-0 py-1 text-dark">
                        No Fullscript patients were found
                      </p>
                    }
                  </div>
                  <p className="m-0 py-1 text-dark">
                    Is {FULL_NAME(client.first_name, client.last_name)} a new Fullscript client?{` `}
                    <Link className="underline" onClick={mountFullscriptWidget}>
                      Click here to create a new Fullscript client record for {client.first_name}.
                    </Link>
                  </p>
                </>
              }
            </>
            :
            <>
              <div className="d-flex justify-content-end pb-2">
                <Link
                  onClick={openConfirmClosePopup}
                >
                  Close Fullscript View
                </Link>
              </div>
              <div
                style={{ width: "100%", height: "100vh", display: `${showFullscriptView ? "block" : "none"}` }}
                id="treatment-plan-container"
              >
              </div>
            </>
          }
        </>
        :
        <>
          <p>Loading...</p>
        </>
      }
    </div>
  )
})

const mapStateToPros = (state) => {
  let fullscriptConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "full_script");
  let country = fullscriptConnection.connection_response.country;
  return {
    client: state.Client.client.details,
    fullscriptConnection: fullscriptConnection,
    fullscriptCountry: country,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps, null, { forwardRef: true })(FullscriptWidget);