import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getDataAnalysisReportsV1 } from "store/services/clientDataReportsService";
import SelectNAQs from "../../SelectNAQs";
import SymptomBurdonReport from "./Components/SymptomBurdenReport";
import { FULL_NAME } from "helpers/common";
import * as actions from "store/actions";
import DownloadSBComparisonv1Report from "components/shared/PDFComponents/NAQv1DataAnalysis/SBCompReportPDF";

const Naqv1SymptomReport = ({ dispatch, naqList, client }) => {
  const { clientId } = useParams();
  const [symptomBurdernData, setSymptomBurdernData] = useState(null);
  const [selectedNAQs, setSelectedNAQs] = useState([]);

  useEffect(() => {
    let todoIds = naqList && naqList.map(naq => naq.id);
    if (todoIds) {
      getNAQReportsData(todoIds);
    }
  }, [naqList]);

  useEffect(() => {
    if (selectedNAQs && selectedNAQs.length > 1) {
      getNAQReportsData(selectedNAQs);
    }
  }, [selectedNAQs])

  const getNAQReportsData = async (todoIds) => {
    try {
      let data = {
        clientId,
        values: {
          todoIds
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      const response = await getDataAnalysisReportsV1(data);
      dispatch(actions.persist_store({ loader: false }));
      if (response.success === true && response.body.condition && response.body.condition.length > 0) {
        setSymptomBurdernData(response.body);
      }
    } catch (error) {
      console.log("Error getting graph data", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeSelectedNAQ = (selected) => {
    setSelectedNAQs(selected);
  }

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }

  return (
    <>
      <div className="top d-flex align-items-start justify-content-between gap-10">
        <div className="left">
          <h6 className="m-0 fw-bold">Symptom Burden Comparison Report</h6>
          <p className="m-0">
            Compare Symptom Burden Reports from {FULL_NAME(client.first_name, client.last_name)}'s NAQs.
          </p>
        </div>
        <div className="right">
          <DownloadSBComparisonv1Report
            client={client}
            naqList={naqList}
            todoIds={selectedNAQs}
            label={"Download Report"}
            showLoader={showLoader}
            hideLoader={hideLoader}
          />
        </div>
      </div>
      <div className="CardBody p-3 bg-light my-2">
        <SelectNAQs naqList={naqList} onChange={handleChangeSelectedNAQ} />
        <SymptomBurdonReport symptomBurdernData={symptomBurdernData} naqList={naqList} selectedNAQs={selectedNAQs}/>
      </div>
    </>
  );
};

const formatData = (data) => {
  const foundations = data.foundations.map(foundation => foundation.data);
  const bodySystems = data.body_systems.map(bodySystem => bodySystem.data);

  return {
    foundations,
    bodySystems,
  }
}

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Naqv1SymptomReport);