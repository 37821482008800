import { decode } from "html-entities";
import { Text, View } from '@react-pdf/renderer';

const Scale = ({ question }) => {
  let scaleColors = [
    "#6A9FA6",
    "#83AFB5",
    "#9CBFC4",
    "#B5CFD3",
    "#CDDFE1",
    "#DEDEDE",
    "#D5DFD7",
    "#C0D0C3",
    "#ABC0AF",
    "#96B09B",
    "#81A087",
  ];
  let columnData = {
    scale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  };
  try {
    columnData = JSON.parse(question.other_column_data);
    if(columnData && typeof columnData?.minNum === "number" && typeof columnData?.maxNum === "number") {
      let scale = Array.apply(null, {length: columnData?.maxNum + 1 - columnData?.minNum}).map(function(_, idx) {
          return idx + columnData?.minNum;
      });

      if(scale && scale.length > 0) {
        columnData.scale = scale;
      }
    }
  } catch (error) {
    console.log("unable to parse column data for the question", question);
  }
  return (
    <View>
      <View style={{ flexDirection: "row", marginTop: "5px", marginBottom: "5px" }}>
        {columnData?.scale &&
          columnData?.scale?.length > 0 &&
          columnData.scale.map((scaleIndex) => {
            let color = scaleColors[scaleIndex];
            return (
              <Text style={{ 
                marginTop: "2px",
                width: "20px",
                height: "20px",
                color: "#111",
                backgroundColor: `${color}`,
                textAlign: "center",
                verticalAlign: "middle"
              }}>
                {scaleIndex}
              </Text>
            );
          })}
      </View>
      {columnData && columnData?.minTxt && (
        <Text style={{ color: "#333", marginBottom: "5px" }}>{`${columnData?.minNum}: ${columnData?.minTxt}`}</Text>
      )}
      {columnData && columnData?.maxTxt && (
        <Text style={{ color: "#333", marginBottom: "5px" }}>{`${columnData?.maxNum}: ${columnData?.maxTxt}`}</Text>
      )}
    </View>
  );
};

export default Scale;
